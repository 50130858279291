import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpParams } from '@angular/common/http';
import { catchError,map, mergeMap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { BaseserviceService } from '../baseservice.service';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from '../../../environments/environment';
import { TimeZoneService } from '../../firstadmin/services/timezone.service';
@Injectable({
  providedIn: 'root'
})
export class MbService {
  public baseUrl; public baseUrl1;
  public imurl;
  public clicksend;
  public send;
  public canvaapi = new BehaviorSubject<any>(null);
  public fbuser = new BehaviorSubject<any>(null);
  public fbpages = new BehaviorSubject<any>(null);
  public fbgroups = new BehaviorSubject<any>(null);
  public fbevents = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient, 
    private timeZoneService: TimeZoneService,
    public baseService: BaseserviceService) {
    this.baseUrl = this.baseService.baseUrl1 + 'api/sms/';
    this.baseUrl1 = this.baseService.baseUrl1;
    this.imurl = this.baseService.baseUrl1 + 'api/im/';
    this.clicksend = this.baseService.baseUrl1 + 'api/clicksend/';
    this.send = this.baseService.baseUrl1 + 'api/';
    // signIn(providerId: string, opt?: LoginOpt): Promise<SocialUser>;
    // signOut(revoke?: boolean): Promise<any>;
   

  }

  

  getEventData(data, page, from, to) {
    console.log("--getEventData--");
    return this.http.get<any>(this.baseUrl + 'getSchedule', 
    this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  geteventData(data) {
    var calendarUrl; 
  //   if(from && to){ 
  //   calendarUrl = `api/fbAuth/getSchedule?page=` + page + '&from=' + from + '&to=' + to;
  // //  calendarUrl = 'api/fbAuth/getSchedule'+'/'+page+'/'+from+'/'+to;
  //   }else{
  //   calendarUrl = 'api/fbAuth/getSchedule';
  //   }
  
    var calendarUrl; 
    if(data.year != undefined){
      calendarUrl = 'api/fbAuth/getSchedule'+'/'+data.year+'/'+data.month;
    }else{
      calendarUrl = 'api/fbAuth/getSchedule';
    }

    return this.http.get<any>(this.baseUrl1 + calendarUrl, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  
  getCustomerPurchasesDetails(page, from, to) {
    return this.http.get<any>(this.baseUrl + `ds/getDSAllContactPurchaseList?from=` + from + '&to=' + to + '&page=' + page, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  addFBPost(body) {
    // const body = value;
    return this.http.post<any>(this.baseUrl + 'post', body, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }


  

  

  updateFBPost(body) {
    // const body = value;
    return this.http.put<any>(this.baseUrl + 'post', body, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        
      }
      return data;
    }),
    catchError(this.handleError));
  }

  addingFbUsers(value, token) {
    // tslint:disable-next-line: prefer-const
    let body = value;
    const httpOptions = this.httpHeadersOptions();
    if (token) {
      httpOptions.headers = httpOptions.headers.set('x-auth', token);
    }
    return this.http.post<any>(this.baseUrl + 'account', body, httpOptions)
    //return this.http.get<any>(this.baseUrl1 + "api/fbAuth/getSchedule", this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  //
  // addingFbUsers(value, token) {
  //   var body = value;
  //   let httpOptions = this.httpHeadersOptions()
  //   if (token) {
  //     httpOptions.headers = httpOptions.headers.set('x-auth', token);
  //   }
  //   return this.http.post<any>(this.baseUrl + "account", body, httpOptions)
  //     .pipe(catchError(this.handleError));
  // }

  getFbLogin(token) {
    // var body = value;
    let httpOptions = this.httpHeadersOptions()
    if (token) {
      httpOptions.headers = httpOptions.headers.set('x-auth', token);
    }
    return this.http.get<any>(this.baseUrl1 + "api/fbAuth/login", httpOptions)
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  facebookUser(token) {
    let httpOptions = this.httpHeadersOptions()
    if (token) {
      httpOptions.headers = httpOptions.headers.set('x-auth', token);
    }
    return this.http.get<any>(this.baseUrl1 + "api/fbAuth/verifyLoginStatus", httpOptions)
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }


  getMessangerAccount() {
    return this.http.get<any>(this.baseUrl + "check-account")
      .pipe(
      map(data=>{
      return data;
    }),
    catchError(this.handleError));
  }


  // api/fbAuth/login

  // getCampaign() {
  //   var body = {};
  //   return this.http.get<any>(this.baseUrl + "campaign/100/0", this.httpHeadersOptions())
  //     .pipe(catchError(this.handleError));
  // }

  //using
  getPostManagementData(catId, isCorporate) {
    var body = {};
    const httpOptions = this.httpHeadersOptions();
    return this.http.get<any>(this.baseUrl + `postByCategoryId/${catId}/${isCorporate}`, httpOptions)
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getFbPostsByCategories(body) {    
    const httpOptions = this.httpHeadersOptions();   
    return this.http.post<any>(this.baseUrl + `postsByCategories`,body, httpOptions)
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getFbCampaignsByCategories(body) {    
    const httpOptions = this.httpHeadersOptions();
    return this.http.post<any>(this.baseUrl + `campaignsByCategories`,body, httpOptions)
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
 
  addMessageSnippets(body) {
    return this.http.post<any>(this.baseUrl + 'message', body, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  delMessageSnippets(id) {
    return this.http.delete<any>(this.baseUrl + `message/${id}`, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updatemsgSnippets(body) {
    // const body = value;
    return this.http.put<any>(this.baseUrl + 'message', body, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getMessageByCategory() {
    return this.http.get<any>(this.baseUrl + 'getMessageByCategory/msgcategory/100/0/true', this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  // /getMessageByCategory/:msgcategory/:limit/:skip/:iscorporate

  disconnectFb() {
    return this.http.put<any>(this.baseUrl + 'disconnect-account', this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  deleteFbPost(post_id) {
    return this.http.delete<any>(this.baseUrl + 'post/' + post_id, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  postCategoryData() {
    return this.http.get<any>(this.baseUrl + 'postCategoryData', this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getFbPostWithParentPostId(ParentPostId) {      
  let body={parentpostid:ParentPostId}
    return this.http.post<any>(this.baseUrl + `getFbPostWithParentPostId`,body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getFbPostWithMultipleParentPostId(ParentPostIds) {      
    let body={parentpostids:ParentPostIds}
      return this.http.post<any>(this.baseUrl + `getFbPostWithMultipleParentPostId`,body, this.httpHeadersOptions())
        .pipe(catchError(this.handleError));
    }

  getHostData() {
    return this.http.get<any>(this.baseUrl1
      + 'api/ds/getEvents', this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  // using
  // getPostImagetData(Id) {

  //   var body = {};
  //   return this.http.get<any>(this.baseUrl + `postByCategoryId/${Id}`, this.httpHeadersOptions())
  //     .pipe(catchError(this.handleError));
  // }
  // useing
  getCategoryDataIsCorp() {
    return this.http.get<any>(this.baseUrl + 'postCategory/100/0/false', this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getCategoryData(type=1) {

    return this.http.get<any>(this.baseUrl + 'postCategoryByuserIdAdmin/'+type+'/1000/0', this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getFbSnippets(type,isCorporate) {
    return this.http.get<any>(this.baseUrl + 'mbsnippets/1000/0/'+isCorporate+'/'+type, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }



  
  deleteCampaign(id) {
    return this.http.delete<any>(this.baseUrl + 'campaign/' + id, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  addCampaign(body) {
    return this.http.post<any>(this.baseUrl + 'campaign', body, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  editCampaign(body) {
    return this.http.put<any>(this.baseUrl + 'campaign', body, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  postCampaign(value) {
    let body = value;
    return this.http.put<any>(this.baseUrl + 'postCampaign', body, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  addCategory(value) {
    let body = value;
    return this.http.post<any>(this.baseUrl + 'postCategory', body, this.httpHeadersOptions()).pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getCampaign() {
    let body = {};
    return this.http.get<any>(this.baseUrl + 'campaign/100/0', this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  campaignByCategoryId(id) {
    let body = {};
    return this.http.get<any>(this.baseUrl + 'campaignByCategoryId/' + id + '/100/0/false', this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  postCampaignByCategoryId(id) {
    let body = {};
    return this.http.get<any>(this.baseUrl + 'getCampaignByCategoryId/' + id + '/100/0/false', this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  postCampaignByCampaignId(value) {
    let body = value;
    return this.http.get<any>(this.baseUrl + 'postCampaignByCampaignId/' + value + '/1000/0', this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  // api/fb/postByComapanyIdAndCategoryId/:catId
  getPostCampaignByCategoryId(value) {
    let body = value;
    return this.http.get<any>(this.baseUrl + 'postByComapanyIdAndCategoryId/' + value + '/true', this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getEventListForSearchContact(id) {
    return this.http.get<any>(this.baseUrl + "getEventListForSearchContact/" + id, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  
  getEventMorderRecord(id) {
    return this.http.get<any>(this.baseUrl + "getEventMorderRecord/" + id, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
 


  fbtoken(fb_token, token) {
    // tslint:disable-next-line: prefer-const
    let body = { fb_token: fb_token };

    const httpOptions = this.httpHeadersOptions();
    if (token) {
      httpOptions.headers = httpOptions.headers.set('x-auth', token);
    }
    return this.http.post<any>(this.baseUrl1 + 'api/fbAuth/fbUserSave', body, httpOptions)
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getCustomMessage(isCorporate) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `message/50/0/`+isCorporate, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  socialPages(body={}) {
    var body=body;
    return this.http.post<any>(this.baseUrl1 + `api/fbAuth/fetchPages`,body, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  socialGroups(body={}) {
    var body=body;
    return this.http.post<any>(this.baseUrl1 + `api/fbAuth/fetchGroups`,body, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  socialEvents(body={}) {
    var body=body;
    return this.http.post<any>(this.baseUrl1 + `api/fbAuth/fetchEvents`,body, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getCustomData(Id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `post/${Id}`, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  delcampaign(id) {
    return this.http.delete<any>(this.baseUrl + `post/${id}`, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));

  }

  // events(){
  //   var body = {};
  //   return this.http.get<any>(this.baseUrl + ``, this.httpHeadersOptions())
  //     .pipe(catchError(this.handleError));

  // }



  getSelectdCampaigns(data) {
    // tslint:disable-next-line: prefer-const
    let body = data;
    return this.http.post<any>(this.baseUrl + `getFbPostsByMultipleIds`, body, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getPostAddedPersonalization(id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getPostAddedPersonalization/${id}`, this.httpHeadersOptions())
      .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }


  cancelfbscheduledpost(schedule_id) {
    let body = {schedule_id: schedule_id};

    return this.http.post(this.baseUrl + 'cancelfbscheduledpost', body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  schedulePost(val) {
    // tslint:disable-next-line: prefer-const
    let body = val;
    return this.timeZoneService.getTimeZoneByValue(body.time_zone)  //first api call
  .pipe(
    map((data: any, index: number) => {
      if (data.status) {
        body.time_zone = data.data[0].utc[0];
      }
      return body;
    }),
    mergeMap((data: any, index: number) => {
      return this.schedule(body)
    })
  );
   /*  return this.http.post(this.baseUrl + 'fbScheduler', body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError)); */

  }

  schedule(val) {
    // tslint:disable-next-line: prefer-const
    let body = val; 

    return this.http.post(this.baseUrl + 'fbScheduler', body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  reschedule(val) {
    // tslint:disable-next-line: prefer-const
    let body = val;
    return this.http.put(this.baseUrl + 'fbReScheduler', body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  // getFbLogin(data){
  //   var body = {};
  //   return this.http.get<any>(this.baseUrl + `post/${data}`, this.httpHeadersOptions())
  //     .pipe(catchError(this.handleError));
  // }

  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
  // tohandle the error
  private handleError(error: HttpErrorResponse) {

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status},` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
  

  hostEvent() {
    return this.http.get<any>(this.baseUrl1 + 'api/ds/getCotactTypeByName/host', this.httpHeadersOptions())
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));

  }
  HostcontactDtails(value) {
    const body = value;
    return this.http.post<any>(this.baseUrl1 + 'api/ds/getContactListByComapany', body, this.httpHeadersOptions())
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));


  }

  httpService(url, params, body, callback) {

  }


  uploadfile(file, userKey, cb) {
    return ;
    const bucket = new S3({
      // accessKeyId: environment.accessKeyId,
      // secretAccessKey: environment.secretAccessKey,
      // region: environment.region
    });

    // Key: this.FOLDER + this.UUID + '/'+ file.name,
    const params = {
      Bucket: environment.awsBucket,
      // Key: userKey + '/'+ file.name,
      Key: userKey + '/' + Date.now().toString(36) + '.' + file.name.split('.').pop(),
      Body: file
    };

    bucket.upload(params, function (err, data) {
      if (err) {
        cb(err, null);
        return err;
      }
      cb(null, data);
      return data;
    });
    return 'ok';
  }

  getUrlfile(awsKey,bucket_secured=false) {
    return environment.s3URL+awsKey;


   // if(bucket_secured == true) {
    const bucket = new S3({
      // accessKeyId: environment.accessKeyId,
      // secretAccessKey: environment.secretAccessKey,
      // region: environment.region
    });

    // tslint:disable-next-line: prefer-const
    let params = { Bucket: environment.awsBucket, Key: awsKey };
    // tslint:disable-next-line: prefer-const
    let url = bucket.getSignedUrl('getObject', params);
    return url;
  // }
  // else return environment.s3URL+awsKey;
  }
  deletedUrlfile(awsKey) {
   return ;
    let self = this;
    const bucket = new S3({
      // accessKeyId: environment.accessKeyId,
      // secretAccessKey: environment.secretAccessKey,
      // region: environment.region
    });
    // tslint:disable-next-line: prefer-const
    let params = { Bucket: environment.awsBucket, Key: awsKey };
    // tslint:disable-next-line: prefer-const
    bucket.deleteObject(params, function (err, data) {
      if (err) console.log(err, err.stack); // an error occurred
      else {
      }
    });
  }

  

  getCategoryUserData(value) {
    let body = value;
    return this.http.post<any>(this.baseUrl + 'getCategoryUserData', body, this.httpHeadersOptions()).pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }


  delCategoryUserData(id) {
    // let body = value;
    return this.http.delete<any>(this.baseUrl + 'postCategory/'+id, this.httpHeadersOptions()).pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  


  updateCategory(value) {
    let body = value;
    return this.http.post<any>(this.baseUrl + 'updatepostCategory', body, this.httpHeadersOptions()).pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }


  cancelCategory(value){
    let body = value;
    return this.http.post<any>(this.baseUrl + 'cancelCategory', body, this.httpHeadersOptions()).pipe(
      map(data=>{
      if(!data.status)
      {
        
      }
      return data;
    }),
    catchError(this.handleError));

  }

  cancelMessageCategory(value){
    let body = value;
    return this.http.post<any>(this.baseUrl + 'cancelMessageCategory', body, this.httpHeadersOptions()).pipe(
      map(data=>{
      if(!data.status)
      {
        
      }
      return data;
    }),
    catchError(this.handleError));

  }



  




getUnusedCategory(value){
  let body = value;
  return this.http.post<any>(this.baseUrl + 'getUnusedCategory', body, this.httpHeadersOptions()).pipe(
    map(data=>{
    if(!data.status)
    {
      
    }
    return data;
  }),
  catchError(this.handleError));

}


getUnusedMessageCategory(value){
  let body = value;
  return this.http.post<any>(this.baseUrl + 'getUnusedMessageCategory', body, this.httpHeadersOptions()).pipe(
    map(data=>{
    if(!data.status)
    {
      
    }
    return data;
  }),
  catchError(this.handleError));

}

updatepostincategory(value){
  let body = value;
  return this.http.post<any>(this.baseUrl + 'updatepostincategory', body, this.httpHeadersOptions()).pipe(
    map(data=>{
    if(!data.status)
    {
      
    }
    return data;
  }),
  catchError(this.handleError));

}

updateMessageinCategory(value){
  let body = value;
  return this.http.post<any>(this.baseUrl + 'updateMessageinCategory', body, this.httpHeadersOptions()).pipe(
    map(data=>{
    if(!data.status)
    {
      
    }
    return data;
  }),
  catchError(this.handleError));

}

mappingForSeriesPostOLD(obj) {
  let body = JSON.stringify(obj);
  return this.http.post<any>(this.baseUrl + 'insertSeriesSchedulePost', body, this.httpHeadersOptions()).pipe(
    map(data=>{
    return data;
  }),
  catchError(this.handleError));
}

mappingForSeriesPost(obj) {
  let body = JSON.stringify(obj);
  return this.http.post<any>(this.baseUrl + 'mbScheduler', body, this.httpHeadersOptions()).pipe(
    map(data=>{
    return data;
  }),
  catchError(this.handleError));
}

sendWhatsappMessage(obj) {
  let body = JSON.stringify(obj);
  return this.http.post<any>(this.baseUrl + 'mbScheduler', body, this.httpHeadersOptions()).pipe(
    map(data=>{
    return data;
  }),
  catchError(this.handleError));
}

sendInstagramMessage(obj:any) {
  let body = JSON.stringify(obj);
  return this.http.post<any>(this.send + 'instagram/send-message', body, this.httpHeadersOptions()).pipe(
    map(data=>{
    return data;
  }),
  catchError(this.handleError));
}

getFBSeriesDatabyScheduleSeriesId(companyId, schedule_series_id, campaignid) {
  let url = this.baseUrl + 'getScheduleSeriesPost/' + companyId + '/' + schedule_series_id +'/' + campaignid;
  return this.http.get<any>(url, this.httpHeadersOptions()).pipe(
    map(data=>{
    return data;
  }),
  catchError(this.handleError));
}

getFBSeriesSuccessfulPostDatabyScheduleSeriesId(companyId, schedule_series_id, campaignid) {
  let url = this.baseUrl + 'getScheduledSeriesPost/' + companyId + '/' + schedule_series_id +'/' + campaignid;
  return this.http.get<any>(url, this.httpHeadersOptions()).pipe(
    map(data=>{
    return data;
  }),
  catchError(this.handleError));
}

cancelCompleteSeriesPost(obj) {

  let body = JSON.stringify(obj);
  return this.http.post<any>(this.baseUrl + 'CancelScheduleSeriesPost', body, this.httpHeadersOptions()).pipe(
    map(data=>{
    return data;
  }),
  catchError(this.handleError));

}

getFBSinglePostDatabyScheduleId(companyId, seriesid) {
  let url = this.baseUrl + 'getScheduleSinglePost/' + companyId + '/' + seriesid;
  return this.http.get<any>(url, this.httpHeadersOptions()).pipe(
    map(data=>{
    return data;
  }),
  catchError(this.handleError));
}


getRecentSegment() {
  let limit = 10;
  let skip = 0;
  let url = this.baseUrl + 'segment/' + limit + '/' + skip;
  return this.http.get<any>(url, this.httpHeadersOptions()).pipe(
    map(data=>{
    return data;
  }),
  catchError(this.handleError));
}

MessagingList() {
  let body = {};
  return this.http.get<any>(this.baseUrl + 'getContactsScheduled/10/0', this.httpHeadersOptions())
    .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
        console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  catchError(this.handleError));
}

MessagingContactList(id){
  let body = {};
  return this.http.get<any>(this.baseUrl + 'getMessageContacts/'+id, this.httpHeadersOptions())
    .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
        console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  catchError(this.handleError));
}

MessagingClickSendHistory(data:any) {
  return this.http.get<any>(this.baseUrl + 'getMessagePreviewClickSend/'+data, this.httpHeadersOptions())
    .pipe(
    map(data=>{
    if(!data.status)
    {
      if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      {
        console.error(data.message);
        data.message="Something went wrong.Please try again."
      }
    }
    return data;
  }),
  catchError(this.handleError));
}

getMessageReply(obj:any) {
  let body = JSON.stringify(obj);
  return this.http.post<any>(this.baseUrl + 'getMessageReplyList', body, this.httpHeadersOptions()).pipe(
    map(data=>{
    return data;
  }),
  catchError(this.handleError));
}

connectAccount(obj) {
  let body = JSON.stringify(obj);
  return this.http.post<any>(this.baseUrl + 'connect-account', body, this.httpHeadersOptions()).pipe(
    map(data=>{
    return data;
  }),
  catchError(this.handleError));

}


updateSmsSendStatus(id) {
   const body = "";
  return this.http.put<any>(this.baseUrl + 'updateSmsSendStatus/'+ id, body, this.httpHeadersOptions())
    .pipe(
    map(data=>{
    if(!data.status)
    {
      
    }
    return data;
  }),
  catchError(this.handleError));
}

getMessagePreview(id:number) {
  const body = "";
 return this.http.get<any>(this.baseUrl + 'getMessagePreview/'+ id, this.httpHeadersOptions())
   .pipe(
   map(data=>{
   if(!data.status)
   {
     
   }
   return data;
 }),
 catchError(this.handleError));
}

getSMSDSName(obj) {
  let body = JSON.stringify(obj);
  return this.http.post<any>(this.baseUrl + 'getMessageDSName', body, this.httpHeadersOptions()).pipe(
    map(data=>{
    return data;
  }),
  catchError(this.handleError));

}

getRoomsList(limit,skip) {
  return this.http.get<any>(this.imurl + 'room/'+ limit + '/' + skip, this.httpHeadersOptions());
}

getAddContactsList() {
  return this.http.get<any>(this.imurl + 'contacts', this.httpHeadersOptions());
}

addContactToRoom(body) {
  return this.http.post<any>(this.imurl + 'room', body, this.httpHeadersOptions());
}

// sendMessageToRoom(body) {
//   return this.http.post<any>(this.clicksend + 'send-message', body, this.httpHeadersOptions());
// }

sendMessageToRoom(body, sendertype) {
  let URL:any;
  if(body.message_type=='facebook'){
    URL = this.send + 'sms-whatsapp' + '/' + 'send-message';
  }
  else if(body.message_type=='instagram'){
    URL = this.send + 'instagram' + '/' + 'send-message';
  }
  else{
    URL = this.send + sendertype + '/' + 'send-message';
  }
  return this.http.post<any>(URL, body, this.httpHeadersOptions());
}

getMessagesForRoom(id, limit, skip) {
  return this.http.get<any>(this.imurl + 'messages/' + id + '/' + limit + '/' + skip,  this.httpHeadersOptions());
}

deleteRoom(room_id) {
  return this.http.delete<any>(this.imurl + 'room/' + room_id ,  this.httpHeadersOptions());
}

getTemplates() {
  return this.http.get<any>(this.imurl + 'snippets', this.httpHeadersOptions());
}

getSMSContentListing(search, consent) {
  return this.http.get<any>(this.imurl + 'contacts-consent?search=' + search + '&sms_consent=' + consent, this.httpHeadersOptions());
}

// sendConsentMessage(body) {
//   return this.http.post<any>(this.clicksend +'send-consent' , body, this.httpHeadersOptions());
// }

sendConsentMessage(body, senderType) {
  let URL = this.send + senderType +'/' + 'send-consent';
  return this.http.post<any>(URL , body, this.httpHeadersOptions());
}

// getRecentSegment1(params) {
//   return this.http.get<any>(this.baseUrl + "segment":limit:skip/, this.httpHeadersOptions())
//     .pipe(catchError(this.handleError));
// }


}





