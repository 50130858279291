<div class="container h-100 app-content">
  <div class="row h-100 justify-content-center mt--4">
    <div class="col-md-10">
      <div class="text-center mainLogLogo my-1">
        <img
          src="assets/custom/images/Margo-logo-1.png"
          alt="branding logo"
          width="150"
        />
      </div>
      <div class="card thanks-card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="container text-center">
                <div class="card" *ngIf="!isEmailResend">
                  <div class="card-body">
                    <i
                      [ngClass]="
                        !isTokenExpired &&
                        (isAccountVerified || emailAlreadyReg)
                          ? 'fa fa-check-circle text-success'
                          : 'fa fa-times-circle text-danger'
                      "
                      aria-hidden="true"
                    ></i>
                    <h1
                      class="thank-you-header-text"
                      [ngClass]="
                        !isTokenInvalid
                          ? 'thank-you-text'
                          : 'invalid-token-text'
                      "
                    >
                      <b>{{
                        !isTokenInvalid ? "Thank You!" : "Invalid Token"
                      }}</b>
                    </h1>
                    <div
                      class="card-text"
                      *ngIf="!isTokenExpired && isAccountVerified"
                    >
                      <b
                        >marGo CRM Direct Seller Email Verification is
                        Complete.</b
                      ><br /><br />
                      <!-- Your Email is now verified. <br><br> -->
                      Thank you for registering with marGo! We are thrilled to
                      welcome you to our marGo CRM.
                      <!-- <br /><br /> -->
                      <!-- <p class="px-xl-4 pb-2 px-xs-0 px-sm-0">
                        <b
                          >Your Direct Seller Email Verification is now complete
                          and you're all set to explore everything marGo CRM has
                          to offer.

                        </b>
                      </p> -->

                      <h3 class="step-heading mb-2">
                        Here are the next steps you can take
                      </h3>
                      <div class="row thankrow justify-content-center">
                        <div class="col-md-3 mb-1">
                          <div class="thankbox">
                            <h4>Setup your CRM</h4>

                            <ol>
                              <li>Set your Customer Timezone</li>
                              <li>Add Sender Email Profile</li>
                              <li>Update your profile</li>
                              <li>Select Marketing Theme</li>
                              <li>Select e-commerce site color theme</li>
                            </ol>
                          </div>
                        </div>
                        <div class="col-md-3 mb-1">
                          <div class="thankbox">
                            <h4>Setup Data</h4>
                            <ol>
                              <li>Add your Products</li>
                              <li>Add your Contacts</li>
                            </ol>
                          </div>
                        </div>
                        <div class="col-md-3 mb-1">
                          <div class="thankbox">
                            <h4>Start Marketing Campaign</h4>
                            <ol>
                              <li>Quick Messages</li>
                              <li>Recommend Products</li>
                              <li>Invite People to Events</li>
                            </ol>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="row thankrow">
                        <div class="col-md-3 mb-1">
                          <div class="thankbox">
                            <img
                              src="assets/custom/images/icons/002-direction.png"
                              alt=""
                            />
                            <h4>Explore Your Account</h4>
                            <p>
                              Log in to your account to update your profile,
                              manage preferences, and access exclusive member
                              features.
                            </p>
                          </div>
                        </div>
                        <div class="col-md-3 mb-1">
                          <div class="thankbox">
                            <img
                              src="assets/custom/images/icons/001-system-update.png"
                              alt=""
                            />
                            <h4>Update Products</h4>
                            <p>
                              Add your range of products and set the perfect fit
                              for your needs.
                            </p>
                          </div>
                        </div>
                        <div class="col-md-3 mb-1">
                          <div class="thankbox">
                            <img
                              src="assets/custom/images/icons/003-add-user.png"
                              alt=""
                            />
                            <h4>Board Contacts</h4>
                            <p>Add your Contacts list.</p>
                          </div>
                        </div>
                        <div class="col-md-3 mb-1">
                          <div class="thankbox">
                            <img
                              src="assets/custom/images/icons/004-play.png"
                              alt=""
                            />
                            <h4>Start Email Campaign</h4>
                          </div>
                        </div>
                      </div> -->
                      <!-- <b>Explore Your Account:</b> Log in to your account to update your profile, manage preferences, and
                      access exclusive member features.<br><br>
                      <b>Update Products:</b> Add your range of products and set the perfect fit for your needs.<br><br>
                      <b>Board Contacts:</b> Add your Contacts list.<br><br> -->

                      <div class="get-support mt-2">
                        <b>Get Support:</b> If you have any questions or need further assistance, please contact our support team at <a href="mailto:support@margo.me">support@margo.me</a>.
                      </div>
                      <p class="my-2">
                        <b
                          >Once again, thank you for choosing marGo. We're
                          excited to have you onboard!.</b
                        >
                      </p>
                    </div>
                    <p
                      class="card-text"
                      *ngIf="isTokenExpired && !isAccountVerified"
                    >
                      Your token has been expired.
                    </p>
                    <p
                      class="card-text"
                      *ngIf="
                        !isTokenExpired && !isAccountVerified && emailAlreadyReg
                      "
                    >
                      Your account is already verified.
                    </p>
                  </div>
                  <div class="col-md-12 text-center">
                    <button
                      type="submit"
                      class="btn btn-primary btn-margo-action py-1 px-5"
                      *ngIf="
                        !isTokenExpired &&
                        (isAccountVerified || emailAlreadyReg || isTokenInvalid)
                      "
                      routerLink="/login"
                    >
                      Login
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary btn-margo-action py-1 px-5"
                      *ngIf="isTokenExpired && !isAccountVerified"
                      (click)="resendEmail()"
                    >
                      Resend Email
                    </button>
                  </div>
                </div>
                <div class="card" *ngIf="isEmailResend">
                  <div class="card-body">
                    <i
                      class="fa fa-check-circle text-success"
                      aria-hidden="true"
                    ></i>
                    <h1>Thank You!</h1>
                    <h4>EMAIL SENT SUCCESSFULLY.</h4>
                    <p class="card-text">
                      The reset link has been sent to your email.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
