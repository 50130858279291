import { Component, OnInit, Renderer2,ChangeDetectorRef, Inject,AfterContentChecked, OnDestroy } from '@angular/core';
import { ThemeSettingsService } from '../settings/theme-settings.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd, NavigationStart, Event } from '@angular/router';
import { FullLayoutComponent } from '../full-layout/full-layout.component';
import { HelpandtipsService } from 'src/app/services/helpandtips/helpandtips.service';
import {KnowledgeArticleLayoutDetail} from 'src/app/shared/modelClasses/modelClasses';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReskinningService } from 'src/app/_services/reskinning.service';

// import {BaseserviceService} from '../../../app/services/baseservice.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit,AfterContentChecked, OnDestroy {
  rskmodellogoFlag = this.ReskinningService.rskModelLogoFlag;
  rskmodellogo = this.ReskinningService.rskModelLogo;
  public isSupportIconEnable:boolean=false;
  public showFooter: boolean;
  private _unsubscribeAll: Subject<any>;
  private _themeSettingsConfig: any;
  public knowledgeArticleLayoutDetail:KnowledgeArticleLayoutDetail;
  constructor(private renderer: Renderer2,
    private _renderer: Renderer2,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private _themeSettingsService: ThemeSettingsService,
    private helpandtipsService: HelpandtipsService,
    private sanitized: DomSanitizer,
    private modalService: NgbModal,
    private NgxSpinnerService: NgxSpinnerService,
    private ReskinningService: ReskinningService
    // private cdRef : ChangeDetectorRef,
    // private baseService:BaseserviceService
    ) {
    this._unsubscribeAll = new Subject();
    
    this.router.events.subscribe((event: Event) => {
      const footerElement = document.getElementsByClassName('footer');
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }

      if (event instanceof NavigationEnd) {
        if ((this.router.url === '/chats' || this.router.url === '/email') && footerElement.item(0)) {
          this._renderer.removeClass(footerElement.item(0), 'footer-static');
          this.renderer.addClass(footerElement.item(0), 'fixed-bottom');
        } else if (footerElement.item(0)) {
          this._renderer.removeClass(footerElement.item(0), 'fixed-bottom');
          this.renderer.addClass(footerElement.item(0), 'footer-static');
        }
      }
    });
    this.knowledgeArticleLayoutDetail = {
      title: "Test Article 1",
      summary: "Article 1 - Test Summary Hello this is a Test Summary",
      answer: "",
      post: "",
    }
  }

  ngOnInit() {
    if ((this.router.url.indexOf('WithNavbar') >= 0) || (this.router.url.indexOf('Advanced') >= 0) ||
      (this.router.url.indexOf('searchPage') >= 0)) {
      this.showFooter = false;
    } else if (FullLayoutComponent) {
      this.showFooter = true;
    } else {
      this.showFooter = true;
    }
    // Subscribe to config changes
    this._themeSettingsService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this._themeSettingsConfig = config;
      });

    const footerElement = document.getElementsByClassName('footer');
    if (this.router.url === '/chats' || this.router.url === '/email' && footerElement.item(0)) {
      this._renderer.removeClass(footerElement.item(0), 'footer-static');
      this._renderer.addClass(footerElement.item(0), 'fixed-bottom');
    }

  }

  ngAfterContentChecked  () {
    var newval;
    // this.baseService.triggerSupportEvent.subscribe(val => { newval = val });
    if ((this.router.url.indexOf('directseller/home') >= 0)) {
      this.isSupportIconEnable = true;
      // this.cdRef.detectChanges();
    }
    else{
      this.isSupportIconEnable = true;
    }
    // if (newval != this.isSupportIconEnable) { // check if it change, tell CD update view
    //   this.isSupportIconEnable = newval;
    //   this.cdRef.detectChanges();
    // }

  }


   //Retrieve latest knowledge Articles
  //  getRelatedSupportArticle(){
  //   let self = this;
  //   this.helpandtipsService.getKnowledgeArticles().subscribe(data => {
  //     if(data.status)
  //     {
  //       data=data.data;  
  //       self.firstTimeRetrieval=false;
  //       if(data.articles){
  //       self.knowledgeArticles = data.articles.length === 0  ? []: data.articles.slice(0, 1);
  //       }
  //     }
  //     self.NgxSpinnerService.hide();
  //   }, error => {
  //     this.NgxSpinnerService.hide();
  //   });

  // }

   //Retrieve article details
   getKnowledgeArticleById(articleID, modalName) {
    let self = this;
    // if (self.latestArticle == undefined || self.latestArticle != articleID) {
      this.NgxSpinnerService.show();
      this.helpandtipsService.getKnowledgeArticleByID1(articleID).subscribe(data => {
        if(data.status)
        {
        data=data.data;
        self.knowledgeArticleLayoutDetail.title = data.layoutItems.find(item => item.label == 'Title').value;
        self.knowledgeArticleLayoutDetail.summary = data.layoutItems.find(item => item.label == 'Summary').value;
        self.knowledgeArticleLayoutDetail.answer = data.layoutItems.find(item => item.label == 'Answer').value;
       
        // self.latestArticle=articleID;
        this.modalService.open(modalName, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
        }
        this.NgxSpinnerService.hide();
      }, error => {
        //console.log(error);
        this.NgxSpinnerService.hide();
      });
    // }
    // else
    //   this.modalService.open(modalName, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
  }


  //Retrieve article details based on Current Page URL
  getKnowledgeArticleByMargoAppURL(modalName) {
    let self = this;
    // if (self.latestArticle == undefined || self.latestArticle != articleID) {
    let appURL = this.router.url.split('/').join('$');
    this.NgxSpinnerService.show();
    this.helpandtipsService.getKnowledgeArticleByMargoAppURL(appURL).subscribe(data => {
      if (data.status) {
        data = data.data;
        self.knowledgeArticleLayoutDetail.title = data.Title;
        self.knowledgeArticleLayoutDetail.summary = data.Summary;
        self.knowledgeArticleLayoutDetail.answer = data.Answer__c;

        // self.latestArticle=articleID;
        this.modalService.open(modalName, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
      }
      this.NgxSpinnerService.hide();
    }, error => {
      //console.log(error);
      this.NgxSpinnerService.hide();
    });
    // }
    // else
    //   this.modalService.open(modalName, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
  }

  ContactSupport()
  {
    this.modalService.dismissAll();
    this.router.navigate(['/directseller/help/support']);
  }

  ngOnDestroy(): any {
    this.modalService.dismissAll();
  }
 
}
