import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BaseserviceService } from '../baseservice.service';
import * as S3 from 'aws-sdk/clients/s3';

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type': 'application/json'
//   })
// };
@Injectable({
  providedIn: 'root'
})
export class AdminoneService {
  //public baseUrl = 'http://ea35bd06.ngrok.io/api/auth/';
  public typeOfUser: String;
  public baseUrl1;
  public data:any;
  url: any;

  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    // this.baseUrl1 = sessionStorage.get('url') + "api/"
    this.baseUrl1 = baseService.baseUrl1 + "api/";
    this.url = window.location.href;
  }

  // getMenu() {
  //   return this.http.get<any>(this.baseUrl + "menu/1")
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }

  /*
  getMenu() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "menu/1", body)
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
*/
  uploadfile(file, userKey, name, cb) {
    return;

    var key;
    const bucket = new S3({
      // accessKeyId: environment.accessKeyId,
      // secretAccessKey: environment.secretAccessKey,
      // region: environment.region
    });
    if(userKey === 'contacts/working' && name=== 'contacts') {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes();
      key='ContactImportTemplate'+date+time;
    } else {
      key = Date.now().toString(36);
    }
    // Key: this.FOLDER + this.UUID + '/'+ file.name,
    const params = {
      Bucket: environment.awsBucket,
      Key: userKey + '/' + key + '.' + file.name.split('.').pop(),
      Body: file
    };
    bucket.upload(params, function (err, data) {
      if (err) {
        cb(err, null);
        return err;
      }
      cb(null, data);
      return data;
    });
    return "ok";
  }

  uploadBase64Image(img, userKey, filename, cb,mediaType?:any) {
    return;

    let base64Data:any;
    filename=filename.replace(/[^a-zA-Z0-9.]/g, '');
    const bucket = new S3({
      // accessKeyId: environment.accessKeyId,
      // secretAccessKey: environment.secretAccessKey,
      // region: environment.region
    });
    if(mediaType && mediaType=='video'){
       base64Data = new Buffer(img.replace(/^data:video\/\w+;base64,/, ""), 'base64');
    }else {
       base64Data = new Buffer(img.replace(/^data:image\/\w+;base64,/, ""), 'base64');
    }
    // Getting the file type, ie: jpeg, png or gif
    const type = img.split(';')[0].split('/')[1];
    // Key: this.FOLDER + this.UUID + '/'+ file.name,
    const params = {
      Bucket: environment.awsBucket,
      Key: userKey + '/' + Date.now().toString(36) + '.' + filename,
      Body: base64Data,
      ACL: 'public-read',
      ContentEncoding: 'base64', // required
      ContentType: mediaType && mediaType=='video'?`image/${type}`:`video/${type}` // required. Notice the back ticks
    };

    bucket.upload(params, function (err, data) {
      if (err) {
        cb(err, null);
        return err;
      }
      cb(null, data);
      return data;
    });
    return "ok";
  }

  importBulk(url, value) {

    var body = value;
    var finalurl;
    if(value.data && !url.includes('verifyBulkContact'))
    {
      finalurl=this.baseUrl1 + "host/" +  url;
    }
    else if(url.includes('verifyBulkContact')) {
      finalurl=this.baseUrl1 + 'ds/'+  url;
    } else {
      finalurl=this.baseUrl1 + "master/" + url;
    }
    return this.http.post<any>(finalurl, body, this.httpHeadersOptions())
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getUrlfile(awsKey,bucket_secured=false) {

   return environment.s3URL+awsKey;

  //  if(bucket_secured == true) {
    const bucket = new S3({
      // accessKeyId: environment.accessKeyId,
      // secretAccessKey: environment.secretAccessKey,
      // region: environment.region
    });


    var params = { Bucket: environment.awsBucket, Key: awsKey };
    var url = bucket.getSignedUrl('getObject', params);

    url = url.substring(0, (url.indexOf('?')));
    return url;
  // }
  // else return environment.s3URL+awsKey;

  }
  //tohandle the error

  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
