<div class="col-md-12" *ngIf="show">

  <h4 class="font-weight-bold mb-1 subTitleHead_3 mt-2">Wish List:</h4>
  <p *ngIf="contactWishList.length === 0">No result available.</p>
  <div class="scroller">
    <div class="filtMain wishListScl">
      <button class="btn btn-margo-cancel btn-round attributeBtn ml-0" *ngFor="let list of contactWishList"
        (click)="deleteFromWishList(list.id)"><span class="attrbtnTxt">{{list.product_name}}</span> <span
          class="attrClsBtn"> x</span></button>
    </div>
  </div>

  <div *ngIf="categories.length > 0">
    <h4 class="font-weight-bold mb-1 subTitleHead_3 mt-1">Find and select a product</h4>
    <div class="row mr-0 mb-1">
      <div class="col-md-6">
        <input class="form-control" [formControl]="queryField" type="text" placeholder="Enter a Product">
      </div>
    </div>
  </div>

  <h4 class="font-weight-bold mb-1 subTitleHead_3 mt-2" *ngIf="categories.length>0">Categories</h4>
  <h4 class="font-weight-bold mb-1 subTitleHead_3 mt-2" *ngIf="wishListProducts.length>0">Products</h4>

  <div class="card bg-color-f9 mb-0" *ngIf="categories.length>0">
    <div class="card-body">
      <div class="scroller">
        <div class="filtMain" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
          (scrolled)="onProductsScrolled()" [scrollWindow]="false">
          <ul class="attrSearchList" *ngIf="wishListProducts.length > 0">

            <li *ngFor="let prod of wishListProducts">
              <span class="titleHeadAttr">{{prod.keyName}}</span>
              <a *ngFor="let val of prod.values" (click)="addProductToWishlist(val.product_catalog_id)"><span
                  class="attrSubHead">{{val.product_name}}</span></a>
            </li>

          </ul>
          <!-- <p *ngIf="wishListProducts.length === 0">No Result Available.</p> -->
        </div>
      </div>

    </div>
    <!-- <div class="card-footer pb-0">
      <a (click)="showAllproducts()">Show Page View</a>
    </div> -->
  </div>

  <div class="card bg-color-f9 mb-0" *ngIf="categories.length > 0">
    <div class="card-body">
      <div class="scroller">
        <div class="filtMain" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
          (scrolled)="onCategoriesScrolled()" [scrollWindow]="false">
          <ul class="attrSearchList">


            <li *ngFor="let list of categories">
              <span class="titleHeadAttr">{{list.keyName}}</span>
              <a *ngFor="let val of list.values" (click)="getProductsByCategory(val.product_category)"><span
                  class="attrSubHead">{{val.product_category}}</span></a>
            </li>

          </ul>
          <p *ngIf="categories.length === 0">No Results Available.</p>
        </div>
      </div>

    </div>
    <div class="card-footer pb-0" *ngIf="categories.length > 0">
      <a (click)="showAllproducts()">Show Page View</a>
    </div>
  </div>
</div>



<div class="col-md-12" *ngIf="!show">

  <h5 class="mt-1 subTitleHead_3 mb-2">Select Products for Wishlist:</h5>
  <div class="scroller mb-3">
    <div class="filtMain" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      (scrolled)="onFullProdsScrolled()" [scrollWindow]="false">
      <div class="container">
        <div class="row">
          <div class=" col-xl-4 col-sm-6" *ngFor="let prod of products">
            <div class="card border-f0">
              <div class="card-content">

                <div class="each-row" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                  (scrolled)="onFullProdsScrolled()" [scrollWindow]="false">

                  <input type="checkbox" style="display:none" id="myCheckboxone-{{prod.product_id}}"
                    (click)="onProductSelectionChange(prod, $event.target.checked, prod.product_catalog_id)">
                  <label class="fbPostImgHt" for="myCheckboxone-{{prod.product_id}}">
                    <p class="productImgHt">
                      <img src="{{prod.product_image}}">
                    </p>

                  </label>

                </div>




                <div class="card-footer text-center gp-5">
                  <div class="inner-each limitLetter" title="{{prod.product_name}}">{{prod.product_name}}</div>

                </div>
              </div>



            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <button class="btn btn-round btn-margo-action mrgSml-5" (click)="goBack()">Go Back</button>
    <button class="btn btn-round btn-margo-action mrgSml-5" (click)="addSelectedProducts()">Add to Wishlist</button>
  </div>

</div>