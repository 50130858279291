import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ClientService } from "src/app/services/clients/client.service";
import { ReskinningService } from "src/app/services/reskinning/reskinning.service";
import { AlertService } from "src/app/_services/alert.service";
import { BaseComponent } from "../../base.component";
import { environment } from "src/environments/environment";
import { UploadService } from "src/app/services/upload/upload.service";
import { ConfirmationDialogService } from "src/app/_utils/confirmation-dialog/confirmation-dialog.service";

@Component({
  selector: "app-add-reskinning",
  templateUrl: "./add-reskinning.component.html",
  styleUrls: ["./add-reskinning.component.css"],
})
export class AddReskinningComponent extends BaseComponent implements OnInit {
  theme_name;
  theme_description;
  screenshot: String;
  IsActive;
  them_id;
  themObj;
  imageCropData;
  imgButtonText;
  fileData;
  fileUploaded;
  imageSrc;
  them_awskey = "";
  them_company;
  getCompanyobj;
  theme_screenshot;
  status: boolean = true;
  screenshot_type = true;
  headers = "Add";
  companyID;
  correctImage: boolean = true;
  controlEnabled: boolean = true;
  submitted = false;
  themeForm: UntypedFormGroup;

  bodybackgroundcolor = "";
  headerbackgroundcolor = "";
  iconcolor = "";
  bodytextcolor = "";
  dashboardtext = "";
  dsaliasname = "";
  companyLogo = "";
  modelLogo = "";
  emailTemplatelogo = '';

  url = "";
  bodyBackgroundImage = "";
  picture_outline_color = "";

  scroll_color = "";
  contact_heading_text = "";
  contact_heading_text_color = "";
  team_heading_text = "";
  team_heading_text_color = "";
  page_content_font_color = "";
  tab_color = "";
  tab_text_color = "";
  button_color = "";
  button_text_color = "";


minisite_header_bg_color  = "";
minisite_header_text_color = "";
minisite_footer_bg_color  = "";
minisite_footer_text_color = "";
minisite_body_bg_color  = "";
minisite_body_text_color = "";
minisite_button_text_color = "";
minisite_primary_color = "";
minisite_secondary_color = "";


imageDisplay1:any;


  ds_id: any;
  email_template_logo:any;
  cartDisable:boolean=false;
  dsAvailableSenderNumbers: any;
  dsSenderNumber: any;


  constructor(
    private master: ReskinningService,
    private formBuilder: UntypedFormBuilder,
    public toaster: ToastrService,
    private uploadService:UploadService,
    public confirmationdialouge: ConfirmationDialogService,
  ) {
    super();
  }

  ngOnInit() {
    let self = this;
    if (localStorage.getItem("UserData")) {
      var userdata = JSON.parse(localStorage.getItem("UserData"));
      if (userdata) {
        this.companyID = userdata?.user_customer_id;

        this.ds_id = userdata.user_ds_id;

        this.createForm()
      }
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      this.them_id = params.them_id;
      // this.companyID = params.companyID;
    });
    this.getDsSenderNumbers();
    //get item details using id
    if (this.them_id) {
      this.headers = "Edit";
      this.master
        .getReskinningById(this.companyID, this.them_id)
        .subscribe((response) => {
          if (response.status) {
            this.status = false;
            self.themObj = response.data;

            self.dataBinding();
          } else {
          }
          this.ngxSpinnerService.hide();
        });
    } else {
      this.defaultSetting();
    }
  }
  createForm() {
    this.themeForm = this.formBuilder.group({
      company: [this.companyID],
      logo: [""],
      model_logo: [""],
      url: [""],
      backgroundImage: [""],
      bodybackgroundcolor: [""],
      headerbackgroundcolor: [this.headerbackgroundcolor],
      iconcolor: [""],
      bodytextcolor: [""],
      dashboardtext: [""],
      dsaliasname: [""],
      teamMemberLabel: [""],
      picture_outline_color: [""],
      scroll_color: [""],
      contact_heading_text: [""],
      contact_heading_text_color: [""],
      team_heading_text: [""],
      team_heading_text_color: [""],
      page_content_font_color: [""],
      tab_color: [""],
      tab_text_color: [""],
      button_color: [""],
      button_text_color: [""],

      minisite_header_bg_color: [""],
minisite_header_text_color: [""],
minisite_footer_bg_color: [""],
minisite_footer_text_color: [""],
minisite_body_bg_color : [""],
minisite_body_text_color: [""],
minisite_button_text_color: [""],
minisite_primary_color: [""],
minisite_secondary_color: [""],


      ds_id: [this.ds_id],
      isactive: true,
      logostatus: true,
      eventFlag: true,
      profileFlag: true,
      modelLogoFlag: true,
      default: false,
      currency:['$'],
      mini_site_product_list:null,
      mini_site_add_to_cart:null,
      mini_site_display_price:null,
      email_template_logo:[],
      logo_height:[37],
      ds_sender_number:['']
    });
  }
  get f() {
    return this.themeForm.controls;
  }

  // radioChange() {
  //   this.screenshot_type = !this.screenshot_type;
  // }

  public onChangeColor(color: string, inputfield): void {
    let inputfieldData = inputfield;

    if (inputfieldData == "bodybackgroundcolor") {
      this.themeForm.value.bodybackgroundcolor = color;
    } else if (inputfieldData == "headerbackgroundcolor") {
      this.themeForm.value.headerbackgroundcolor = color;
    } else if (inputfieldData == "iconcolor") {
      this.themeForm.value.iconcolor = color;
    } else if (inputfieldData == "bodytextcolor") {
      this.themeForm.value.bodytextcolor = color;
    } else if (inputfieldData == "dashboardtext") {
      this.themeForm.value.dashboardtext = color;
    } else if (inputfieldData == "picture_outline_color") {
      this.themeForm.value.picture_outline_color = color;
    } else if (inputfieldData == "scroll_color") {
      this.themeForm.value.scroll_color = color;
    } else if (inputfieldData == "contact_heading_text_color") {
      this.themeForm.value.contact_heading_text_color = color;
    } else if (inputfieldData == "team_heading_text_color") {
      this.themeForm.value.team_heading_text_color = color;
    } else if (inputfieldData == "page_content_font_color") {
      this.themeForm.value.page_content_font_color = color;
    } else if (inputfieldData == "tab_color") {
      this.themeForm.value.tab_color = color;
    } else if (inputfieldData == "tab_text_color") {
      this.themeForm.value.tab_text_color = color;
    } else if (inputfieldData == "button_color") {
      this.themeForm.value.button_color = color;
    } else if (inputfieldData == "minisite_header_bg_color") {
      this.themeForm.value.minisite_header_bg_color = color;
    }
    else if (inputfieldData == "minisite_header_text_color") {
      this.themeForm.value.minisite_header_text_color = color;
    }
    else if (inputfieldData == "minisite_footer_bg_color") {
      this.themeForm.value.minisite_footer_bg_color = color;
    }
    else if (inputfieldData == "minisite_footer_text_color") {
      this.themeForm.value.minisite_footer_text_color = color;
    }
    else if (inputfieldData == "minisite_body_bg_color") {
      this.themeForm.value.minisite_body_bg_color = color;
    }
    else if (inputfieldData == "minisite_body_text_color") {
      this.themeForm.value.minisite_body_text_color = color;
    }
    else if (inputfieldData == "minisite_button_text_color") {
      this.themeForm.value.minisite_button_text_color = color;
    }
    else if (inputfieldData == "minisite_primary_color") {
      this.themeForm.value.minisite_primary_color = color;
    }
    else if (inputfieldData == "minisite_secondary_color") {
      this.themeForm.value.minisite_secondary_color = color;
    }


    // else if (inputfieldData == 'dsaliasname') {
    //   this.themeForm.value.dsaliasname = color;
    // }
  }

  // defaultSettingOLD(value) {
  //   if (value == true) {
  //     this.bodybackgroundcolor = "#ffffff";
  //     this.headerbackgroundcolor = "#f4f5fa";
  //     this.iconcolor = "#009fae";
  //     this.bodytextcolor = "#666";
  //     this.dashboardtext = "#666";
  //     this.picture_outline_color = "#019dac";
  //     this.scroll_color = "#019dac";
  //     this.bodyBackgroundImage = "assets/custom/images/Background-Image.jpg";
  //     this.dsaliasname = "How can marGo help today?";
  //     this.contact_heading_text_color = "#666";
  //     this.team_heading_text_color = "#666";
  //     this.page_content_font_color = "#666";
  //     this.tab_color = "#1e9ff2";
  //     this.tab_text_color = "#fff";
  //     this.button_color = "#009fae";
  //     this.button_text_color = "#fff";

  //   } else {
  //     this.dataBinding();
  //   }
  // }


  defaultSetting() {
    // Getting default data from API
    this.master.getReskinningDefaultData().subscribe({
      next:(res:any)=>{
      this.bodybackgroundcolor = res[0].body_background_color ? res[0].body_background_color :'#ffffff';
      this.headerbackgroundcolor =  res[0].header_background_color ? res[0].header_background_color :'#ffffff';
      this.companyLogo =  res[0].logo ? res[0].logo :environment.s3URL+'master_images/lvc1zloy.png';
      this.modelLogo =  res[0].model_logo ? res[0].model_logo :environment.s3URL+'master_images/lgkpjasq.png';
      this.bodyBackgroundImage = res[0].background_image ? res[0].background_image : 'assets/custom/images/Background-Image.jpg';
      this.iconcolor = res[0].icon_color ? res[0].icon_color : '#009fae';
      this.picture_outline_color =  res[0].picture_outline_color ? res[0].picture_outline_color :'#019dac';
      this.scroll_color =  res[0].scroll_color ? res[0].scroll_color :'#019dac';
      this.dashboardtext =  res[0].dashboard_text ? res[0].dashboard_text :'#666';
      this.bodytextcolor =  res[0].body_text_color ? res[0].body_text_color :'#666';
      this.contact_heading_text_color =  res[0].contact_heading_text_color ? res[0].contact_heading_text_color :'#666';
      this.team_heading_text_color =  res[0].team_heading_text_color ? res[0].team_heading_text_color :'#666';
      this.page_content_font_color =  res[0].page_content_font_color ? res[0].page_content_font_color :'#666';
      this.tab_color = res[0].tab_color ? res[0].tab_color : '#1e9ff2';
      this.tab_text_color =  res[0].tab_text_color ? res[0].tab_text_color :'#fff';
      this.button_color =  res[0].button_color ? res[0].button_color :'#009fae';
      this.button_text_color = res[0].button_text_color ? res[0].button_text_color : '#fff';

      this.minisite_header_bg_color = res[0].minisite_header_bg_color ? res[0].minisite_header_bg_color : '#fff';
      this.minisite_header_text_color = res[0].minisite_header_text_color ? res[0].minisite_header_text_color : '#000';
      this.minisite_footer_bg_color = res[0].minisite_footer_bg_color ? res[0].minisite_footer_bg_color : '#f4f4f4';
      this.minisite_footer_text_color = res[0].minisite_footer_text_color ? res[0].minisite_footer_text_color : '#000';
      this.minisite_body_bg_color = res[0].minisite_body_bg_color ? res[0].minisite_body_bg_color : '#f4f4f4';
      this.minisite_body_text_color = res[0].minisite_body_text_color ? res[0].minisite_body_text_color : '#000';
      this.minisite_primary_color = res[0].minisite_primary_color ? res[0].minisite_primary_color : '#009FAE';
      this.minisite_secondary_color = res[0].minisite_secondary_color ? res[0].minisite_secondary_color : '#009FAE';
      this.minisite_button_text_color = res[0].minisite_button_text_color ? res[0].minisite_button_text_color : '#fff';



      this.emailTemplatelogo =  res[0].email_template_logo ? res[0].email_template_logo :environment.s3URL+'master_images/lgkpjasq.png';
      this.dsSenderNumber = this.themObj?.ds_sender_number ? this.themObj?.ds_sender_number  : '';

      this.themeForm = this.formBuilder.group({
        company: [this.companyID, Validators.required],
        ds_id: [this.ds_id],
        logo: [],
        model_logo: [],
        email_template_logo:[],
        url: [''],
        backgroundImage: [''],
        bodybackgroundcolor: [this.bodybackgroundcolor],
        headerbackgroundcolor: [this.headerbackgroundcolor],
        iconcolor: [this.iconcolor],
        bodytextcolor: [this.button_text_color],

        dashboardtext: [this.dashboardtext],
        dsaliasname: ['How can marGo help today ?'],
        teamMemberLabel: [''],
        picture_outline_color: [this.picture_outline_color],
        scroll_color: [this.scroll_color],
        contact_heading_text: ['Contact'],
        contact_heading_text_color: [this.contact_heading_text_color],
        team_heading_text: ['Team Member'],
        team_heading_text_color: [this.team_heading_text_color],
        page_content_font_color: [this.page_content_font_color],
        tab_color: [this.tab_color],
        tab_text_color: [this.tab_text_color],
        button_color: [this.button_color],

        minisite_header_bg_color: [this.minisite_header_bg_color],
        minisite_header_text_color: [this.minisite_header_text_color],
        minisite_footer_bg_color: [this.minisite_footer_bg_color],
        minisite_footer_text_color: [this.minisite_footer_text_color],
        minisite_body_bg_color : [this.minisite_body_bg_color],
        minisite_body_text_color: [this.minisite_body_text_color],
        minisite_button_text_color: [this.minisite_button_text_color],
        minisite_primary_color: [this.minisite_primary_color],
        minisite_secondary_color: [this.minisite_secondary_color],





        isactive: true,
        logostatus: true,
        eventFlag: true,
        profileFlag: true,
        modelLogoFlag: true,
        default: false,
        currency:['$'],
        mini_site_product_list:true,
        mini_site_add_to_cart:true,
        mini_site_display_price:true,
        logo_height:[37],
        ds_sender_number:[this.dsSenderNumber]
      });

    },
      error:(err:any)=>{
    this.bodybackgroundcolor = '#ffffff';
    this.headerbackgroundcolor = '#ffffff';
    this.companyLogo = environment.s3URL+'master_images/lvc1zloy.png';
    this.modelLogo = environment.s3URL+'master_images/lgkpjasq.png';
    this.bodyBackgroundImage = 'assets/custom/images/Background-Image.jpg';
    this.emailTemplatelogo = environment.s3URL+'master_images/lgkpjasq.png';
    this.iconcolor = '#009fae';
    this.picture_outline_color = '#019dac';
    this.scroll_color = '#019dac';
    this.dashboardtext = '#666';
    this.bodytextcolor = '#666';
    this.contact_heading_text_color = '#666';
    this.team_heading_text_color = '#666';
    this.page_content_font_color = '#666';
    this.tab_color = '#1e9ff2';
    this.tab_text_color = '#fff';
    this.button_color = '#009fae';
    this.button_text_color = '#fff';
    this.dsSenderNumber = this.themObj?.ds_sender_number ? this.themObj?.ds_sender_number  : '';;

    this.themeForm = this.formBuilder.group({
      company: [this.companyID, Validators.required],
      ds_id: [this.ds_id],
      logo: [],
      model_logo: [],
      email_template_logo:[],
      url: [''],
      backgroundImage: [''],
      bodybackgroundcolor: [this.bodybackgroundcolor],
      headerbackgroundcolor: [this.headerbackgroundcolor],
      iconcolor: [this.iconcolor],
      bodytextcolor: [this.button_text_color],
      dashboardtext: [this.dashboardtext],
      dsaliasname: ['How can marGo help today ?'],
      teamMemberLabel: [''],
      picture_outline_color: [this.picture_outline_color],
      scroll_color: [this.scroll_color],
      contact_heading_text: ['Contact'],
      contact_heading_text_color: [this.contact_heading_text_color],
      team_heading_text: ['Team Member'],
      team_heading_text_color: [this.team_heading_text_color],
      page_content_font_color: [this.page_content_font_color],
      tab_color: [this.tab_color],
      tab_text_color: [this.tab_text_color],
      button_color: [this.button_color],
      button_text_color: [this.button_text_color],
      isactive: true,
      logostatus: true,
      eventFlag: true,
      profileFlag: true,
      modelLogoFlag: true,
      default: false,
      currency:['$'],
      mini_site_product_list:true,
      mini_site_add_to_cart:true,
      mini_site_display_price:true,
      logo_height:[37],
      ds_sender_number:[this.dsSenderNumber]
    });
      }
    })


  }

  clearAllSetting() {
    this.themeForm.reset();
    this.companyLogo = '';
    this.modelLogo = '';
    this.headerbackgroundcolor = '';
    this.iconcolor = '';
    this.bodytextcolor = '';
    this.dashboardtext = '';
    this.picture_outline_color = '';
    this.scroll_color = '';
    this.bodyBackgroundImage = '';
    this.dsaliasname = '';
    this.contact_heading_text_color = '';
    this.team_heading_text_color = '';
    this.page_content_font_color = '';
    this.tab_color = '';
    this.tab_text_color = '';
    this.button_color = '';
    this.button_text_color = '';
    this.minisite_header_bg_color= '';
    this.minisite_header_text_color= '';
    this.minisite_footer_bg_color= '';
    this.minisite_footer_text_color= '';
    this.minisite_body_bg_color = '';
    this.minisite_body_text_color= '';
    this.minisite_button_text_color= '';
    this.minisite_primary_color= '';
    this.minisite_secondary_color= '';


    this.emailTemplatelogo = '';
    this.dsSenderNumber = '';
  }

  addNewReskinning() {
    this.submitted = true;

    if (this.themeForm.invalid) {
      console.log("invalid form", this.themeForm);
      return;
    }

    let logo;
    let reskiningLogo = localStorage.getItem("reskiningLogo");
    if (reskiningLogo) {
      logo = reskiningLogo;
    } else {
      logo = this.companyLogo;
    }


    let reskiningModelLogo = localStorage.getItem('reskiningModelLogo');
    if (reskiningModelLogo) {
      this.modelLogo = reskiningModelLogo;
    } else {
      this.modelLogo = this.modelLogo;
    }

    let reskiningEmailTemplatelogo = localStorage.getItem('reskiningEmailTemplatelogo');
    if (reskiningEmailTemplatelogo) {
      this.emailTemplatelogo = reskiningEmailTemplatelogo;
    } else {
      this.emailTemplatelogo = this.emailTemplatelogo;
    }




    let backgroundImage;
    let reskiningBackgroundImage = localStorage.getItem(
      "reskiningBackgroundImage"
    );
    if (reskiningBackgroundImage) {
      backgroundImage = reskiningBackgroundImage;
    } else {
      backgroundImage = this.bodyBackgroundImage;
    }



    let {
      company,
      bodybackgroundcolor,
      headerbackgroundcolor,
      iconcolor,
      bodytextcolor,
      dashboardtext,
      dsaliasname,
      picture_outline_color,
      scroll_color,
      contact_heading_text,
      contact_heading_text_color,
      team_heading_text,
      team_heading_text_color,
      page_content_font_color,
      tab_color,
      tab_text_color,
      button_color,
      button_text_color,
      minisite_header_bg_color,
minisite_header_text_color,
minisite_footer_bg_color,
minisite_footer_text_color,
minisite_body_bg_color ,
minisite_body_text_color,
minisite_button_text_color,
minisite_primary_color,
minisite_secondary_color,


      ds_id,
      url,
      currency,
      mini_site_product_list,
      mini_site_add_to_cart,
      mini_site_display_price,
      logo_height,
      ds_sender_number
    } = this.themeForm.value;

    let reskinningObj = {
      company: company,
      logo: logo,
      modellogo: this.modelLogo,
      emailTemplatelogo:this.emailTemplatelogo,
      url: url,
      backgroundImage: backgroundImage,
      bodybackgroundcolor: bodybackgroundcolor || this.bodybackgroundcolor,
      headerbackgroundcolor: headerbackgroundcolor || this.headerbackgroundcolor,
      iconcolor: iconcolor || this.iconcolor,
      bodytextcolor: bodytextcolor || this.bodytextcolor,
      dashboardtext: dashboardtext || this.dashboardtext,
      dsaliasname: dsaliasname || "",

      picture_outline_color:
        picture_outline_color || this.picture_outline_color,
      scroll_color: scroll_color || this.scroll_color,
      contact_heading_text: contact_heading_text || this.contact_heading_text,

      contact_heading_text_color:
        contact_heading_text_color || this.contact_heading_text_color,
      team_heading_text: team_heading_text || this.team_heading_text,
      team_heading_text_color:
        team_heading_text_color || this.team_heading_text_color,
      page_content_font_color:
        page_content_font_color || this.page_content_font_color,
      tab_color: tab_color || this.tab_color,
      tab_text_color: tab_text_color || this.tab_text_color,
      button_color: button_color || this.button_color,
      button_text_color: button_text_color || this.button_text_color,

      minisite_header_bg_color: minisite_header_bg_color || this.minisite_header_bg_color,
      minisite_header_text_color: minisite_header_text_color || this.minisite_header_text_color,
      minisite_footer_bg_color: minisite_footer_bg_color || this.minisite_footer_bg_color,
      minisite_footer_text_color: minisite_footer_text_color || this.minisite_footer_text_color,
      minisite_body_bg_color : minisite_body_bg_color  || this.minisite_body_bg_color ,
      minisite_body_text_color: minisite_body_text_color || this.minisite_body_text_color,
      minisite_button_text_color: minisite_button_text_color || this.minisite_button_text_color,
      minisite_primary_color: minisite_primary_color || this.minisite_primary_color,
      minisite_secondary_color: minisite_secondary_color || this.minisite_secondary_color,


      ds_id: ds_id,
      isactive: true,
      logostatus: true,
      eventFlag: true,
      profileFlag: true,
      modelLogoFlag: true,
      currency:currency,
      mini_site_product_list:mini_site_product_list,
      mini_site_add_to_cart:mini_site_add_to_cart,
      mini_site_display_price:mini_site_display_price,
      logo_height:logo_height,
      ds_sender_number:this.dsSenderNumber
    };

    if(reskinningObj.mini_site_display_price==false){
      reskinningObj.mini_site_add_to_cart=false;
    }

    // console.log("reskinningObj", reskinningObj);
    // return;



    this.ngxSpinnerService.show();
    this.master.addingReskinning(reskinningObj).subscribe(
      (data) => {
        if (data.status) {
          this.toastrService.success("Added Successfully");
          localStorage.setItem('reskinningReload','true');
          this.router.navigateByUrl(`/directseller/reskinning`);
        } else {
          this.toastrService.error(data.message);
          this.ngxSpinnerService.hide();
        }
        this.ngxSpinnerService.hide();
      },
      (error) => {
        // console.log(error);
        this.ngxSpinnerService.hide();
      }
    );
  }

  dataBinding() {
    // this.bodybackgroundcolor = '#902828';

    this.companyLogo = this.themObj.logo;
    this.modelLogo = this.themObj.model_logo;
    this.emailTemplatelogo = this.themObj.email_template_logo;

    this.url = this.themObj.url;

    this.bodyBackgroundImage = this.themObj.background_image;
    this.bodybackgroundcolor = this.themObj.body_background_color;
    this.headerbackgroundcolor = this.themObj.header_background_color;
    this.iconcolor = this.themObj.icon_color;
    this.bodytextcolor = this.themObj.body_text_color;
    this.dashboardtext = this.themObj.dashboard_text;
    this.dsaliasname = this.themObj.ds_alias_name;
    this.picture_outline_color = this.themObj.picture_outline_color;

    this.scroll_color = this.themObj.scroll_color;

    this.contact_heading_text_color = this.themObj.contact_heading_text_color;
    this.team_heading_text_color = this.themObj.team_heading_text_color;
    this.page_content_font_color = this.themObj.page_content_font_color;
    this.tab_color = this.themObj.tab_color;
    this.tab_text_color = this.themObj.tab_text_color;
    this.button_color = this.themObj.button_color;
    this.button_text_color = this.themObj.button_text_color;

    this.minisite_header_bg_color = this.themObj.minisite_header_bg_color;
    this.minisite_header_text_color = this.themObj.minisite_header_text_color;
    this.minisite_footer_bg_color = this.themObj.minisite_footer_bg_color;
    this.minisite_footer_text_color = this.themObj.minisite_footer_text_color;
    this.minisite_body_bg_color  = this.themObj.minisite_body_bg_color ;
    this.minisite_body_text_color = this.themObj.minisite_body_text_color;
    this.minisite_button_text_color = this.themObj.minisite_button_text_color;
    this.minisite_primary_color = this.themObj.minisite_primary_color;
    this.minisite_secondary_color = this.themObj.minisite_secondary_color;



    this.dsSenderNumber = this.themObj.ds_sender_number;

    this.themeForm = this.formBuilder.group({
      company: [this.companyID],
      logo: [""],

      model_logo: [],
      email_template_logo:[],

      url: [this.themObj.url],
      backgroundImage: [""],

      bodybackgroundcolor: [""],
      headerbackgroundcolor: [""],
      iconcolor: [""],
      bodytextcolor: [""],
      dashboardtext: [""],
      dsaliasname: [this.themObj.ds_alias_name],

      picture_outline_color: [],
      scroll_color: [],
      contact_heading_text: [this.themObj.contact_heading_text],
      team_heading_text: [this.themObj.team_heading_text],

      contact_heading_text_color: [""],
      team_heading_text_color: [""],
      page_content_font_color: [""],
      tab_color: [""],
      tab_text_color: [""],
      button_color: [""],
      button_text_color: [""],
      minisite_header_bg_color: [""],
minisite_header_text_color: [""],
minisite_footer_bg_color: [""],
minisite_footer_text_color: [""],
minisite_body_bg_color : [""],
minisite_body_text_color: [""],
minisite_button_text_color: [""],
minisite_primary_color: [""],
minisite_secondary_color: [""],

      ds_id: [this.ds_id],

      isactive: true,
      logostatus: this.themObj.logo_status,
      eventFlag: this.themObj.event_flag,

      profileFlag: this.themObj.profile_obj,

      modelLogoFlag: this.themObj.model_logo_flag,

      default: false,
      currency:[this.themObj.currency],
      mini_site_product_list:this.themObj.mini_site_product_list,
      mini_site_add_to_cart:this.themObj.mini_site_add_to_cart,
      mini_site_display_price:this.themObj.mini_site_display_price,
      logo_height:this.themObj.logo_height==null ? 37 : this.themObj.logo_height,
      ds_sender_number:[this.dsSenderNumber]
    });
  }

  updateReskinning() {
    this.submitted = true;
    if(this.themeForm.invalid){
      return console.log(this.themeForm.value);

    }
    let logo;
    let modellogo;
    let emailTemplatelogo;

    let reskiningLogo = localStorage.getItem("reskiningLogo");
    if (reskiningLogo) {
      logo = reskiningLogo;
    } else {
      logo = this.companyLogo;
    }

    let reskiningModelLogo = localStorage.getItem("reskiningModelLogo");
    if (reskiningModelLogo) {
      modellogo = reskiningModelLogo;
    } else {
      modellogo = this.modelLogo;
    }

    let reskiningEmailTemplatelogo = localStorage.getItem('reskiningEmailTemplatelogo');
    if (reskiningEmailTemplatelogo) {
      this.emailTemplatelogo = reskiningEmailTemplatelogo;
    } else {
      this.emailTemplatelogo = this.emailTemplatelogo;
    }

    let backgroundImage;
    let reskiningBackgroundImage = localStorage.getItem(
      "reskiningBackgroundImage"
    );
    if (reskiningBackgroundImage) {
      backgroundImage = reskiningBackgroundImage;
    } else {
      backgroundImage = this.bodyBackgroundImage;
    }

    // if (this.themeForm.invalid || !this.correctImage) {
    //   return;
    // }

    let {
      company,
      bodybackgroundcolor,
      headerbackgroundcolor,
      iconcolor,
      bodytextcolor,
      dashboardtext,
      dsaliasname,
      picture_outline_color,
      scroll_color,
      contact_heading_text,
      contact_heading_text_color,
      team_heading_text,
      team_heading_text_color,
      page_content_font_color,
      tab_color,
      tab_text_color,
      button_color,
      button_text_color,
      minisite_header_bg_color,
minisite_header_text_color,
minisite_footer_bg_color,
minisite_footer_text_color,
minisite_body_bg_color ,
minisite_body_text_color,
minisite_button_text_color,
minisite_primary_color,
minisite_secondary_color,
      logostatus,
      eventFlag,
      modelLogoFlag,
      profileFlag,
      url,

      ds_id,
      currency,
      mini_site_product_list,
      mini_site_add_to_cart,
      mini_site_display_price,
      logo_height,
      ds_sender_number
    } = this.themeForm.value;

    let reskinningObj = {
      id: this.them_id,
      company: company,
      logo: logo,
      modellogo: modellogo,
      emailTemplatelogo:this.emailTemplatelogo,
      url: url,
      backgroundImage: backgroundImage,

      bodybackgroundcolor: bodybackgroundcolor || this.bodybackgroundcolor,
      headerbackgroundcolor:
        headerbackgroundcolor || this.headerbackgroundcolor,
      iconcolor: iconcolor || this.iconcolor,
      bodytextcolor: bodytextcolor || this.bodytextcolor,
      dashboardtext: dashboardtext || this.dashboardtext,
      dsaliasname: dsaliasname || this.dsaliasname,

      picture_outline_color:
        picture_outline_color || this.picture_outline_color,
      scroll_color: scroll_color || this.scroll_color,
      contact_heading_text: contact_heading_text || this.contact_heading_text,

      contact_heading_text_color:
        contact_heading_text_color || this.contact_heading_text_color,
      team_heading_text: team_heading_text || this.team_heading_text,
      team_heading_text_color:
        team_heading_text_color || this.team_heading_text_color,
      page_content_font_color:
        page_content_font_color || this.page_content_font_color,
      tab_color: tab_color || this.tab_color,
      tab_text_color: tab_text_color || this.tab_text_color,
      button_color: button_color || this.button_color,
      button_text_color: button_text_color || this.button_text_color,
      minisite_header_bg_color: minisite_header_bg_color || this.minisite_header_bg_color,
      minisite_header_text_color: minisite_header_text_color || this.minisite_header_text_color,
      minisite_footer_bg_color: minisite_footer_bg_color || this.minisite_footer_bg_color,
      minisite_footer_text_color: minisite_footer_text_color || this.minisite_footer_text_color,
      minisite_body_bg_color : minisite_body_bg_color || this.minisite_body_bg_color ,
      minisite_body_text_color: minisite_body_text_color || this.minisite_body_text_color,
      minisite_button_text_color: minisite_button_text_color || this.minisite_button_text_color,
      minisite_primary_color: minisite_primary_color || this.minisite_primary_color,
      minisite_secondary_color: minisite_secondary_color || this.minisite_secondary_color,








      ds_id: ds_id,

      logostatus: logostatus,
      eventFlag: eventFlag,

      profileFlag: profileFlag,

      modelLogoFlag: modelLogoFlag,
      isactive: true,
      currency:currency,
      mini_site_product_list:mini_site_product_list,
      mini_site_add_to_cart:mini_site_add_to_cart,
      mini_site_display_price:mini_site_display_price,
      logo_height:logo_height,
      ds_sender_number:ds_sender_number
    };

    // return false;
    this.ngxSpinnerService.show();
    this.master.UpdateReskinning(reskinningObj).subscribe(
      (data) => {
        if (data.status) {
          this.authserviceService.updateUserDetails()
          localStorage.removeItem("reskiningLogo");
          localStorage.removeItem("reskiningBackgroundImage");
          this.toastrService.success(" Updated Successfully");
          localStorage.setItem('reskinningReload','true');
          this.router.navigateByUrl(`/directseller/reskinning`);
          this.ngxSpinnerService.hide();
        }
      },
      (error) => {
        console.error(error);
        this.ngxSpinnerService.hide();
      }
    );
  }

  processFile(event: any, imageInput: any, fileType: any) {
    const file: File = imageInput.files[0];
    if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
      event.target.value = null;
      this.toastrService.error(
        "Please select only (png, jpg or jpeg) files"
      );
      this.correctImage = false;
      return false;
    } else {

      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.toUploadFiles(file.name, fileType,fileReader.result);
      };
      fileReader.readAsDataURL(file);
    }
  }

  toUploadFiles(name, fileType,file) {
    let self = this;
    this.ngxSpinnerService.show();
    if (file.size > 50 * 1024 * 1024) {
      this.toastrService.error('Maximum file size must be 50mb');
      return;
    }
    let serviceKey = Date.now().toString(36) + '.' + name.split('.').pop()
    this.uploadService.uploadImage(name,'master_images',file,serviceKey).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide();
        if(res.status){
          if (res.data.Key) {
            this.ngxSpinnerService.hide();
            if (fileType == "logo") {
              localStorage.setItem("reskiningLogo", res.data.Location);
              self.companyLogo = res.data.Location;
            } else if (fileType == "model_logo") {
              localStorage.setItem("reskiningModelLogo", res.data.Location);
              self.modelLogo = res.data.Location;
            }
            else if (fileType == "email_template_logo") {
              localStorage.setItem("reskiningEmailTemplateLogo", res.data.Location);
              self.emailTemplatelogo = res.data.Location;
            }
            else {
              localStorage.setItem("reskiningBackgroundImage", res.data.Location);
              self.bodyBackgroundImage = res.data.Location;
            }
          }
        }
        else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide();
        this.toastrService.error('Something went wrong !')
      }
    })
    // this.master.uploadfile(file, "master_images", (error, params) => {
    //   if (error) {
    //     this.ngxSpinnerService.hide();
    //     return;
    //   }
    //   if (params.Key) {
    //     this.ngxSpinnerService.hide();
    //     if (fileType == "logo") {
    //       localStorage.setItem("reskiningLogo", params.Location);
    //       self.companyLogo = params.Location;
    //     } else if (fileType == "model_logo") {
    //       localStorage.setItem("reskiningModelLogo", params.Location);
    //       self.modelLogo = params.Location;
    //     }
    //     else if (fileType == "email_template_logo") {
    //       localStorage.setItem("reskiningEmailTemplateLogo", params.Location);
    //       self.emailTemplatelogo = params.Location;
    //     }


    //     else {
    //       localStorage.setItem("reskiningBackgroundImage", params.Location);
    //       self.bodyBackgroundImage = params.Location;
    //     }

    //     // self.setThemeForm(params.Key);
    //     // self.toastrService.success(" File Updated Successfully");
    //   }
    // });
  }

  cancelBtb() {
    this.router.navigateByUrl(`/directseller/reskinning`);
  }
  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  onDisableDisplayPrice(){
    this.f.mini_site_add_to_cart.setValue(false);
  }
  getDsSenderNumbers() {
    this.master.getdsSenderNumberList().subscribe(
      (res:any) => {
        if(res.status){
          this.dsAvailableSenderNumbers = res.data;
        }
        else{
          this.dsAvailableSenderNumbers = [];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  deleteLogo(){
    this.confirmationdialouge.confirm('', 'ARE YOU SURE WANT TO DELETE THIS LOGO?', 'YES', 'NO').then((confirmed) => {
      this.ngxSpinnerService.show();
      this.master.deleteLogo().subscribe({
        next:(res:any)=>{
          this.ngxSpinnerService.hide();
          if(res.status){
            this.toaster.success('Logo successfully deleted');
            this.themObj.logo = '';
            this.companyLogo ='';
            localStorage.removeItem("reskiningLogo");
          }else{
            this.ngxSpinnerService.hide();
            this.toaster.error(res?.message || res?.data)
          }
        },error:(err:any)=>{
          this.ngxSpinnerService.hide();
          this.toaster.error(err?.message || err?.error?.message)
        }
      })
    }).
    catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }
}
