import { Component, OnInit, Input, Output,PipeTransform, Pipe, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { KnowledgeArticleLayoutDetail } from 'src/app/shared/modelClasses/modelClasses';
import { BaseComponent } from 'src/app/firstadmin/base.component';

@Component({
  selector: 'article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.css']
})
export class ArticleDetailComponent extends BaseComponent implements OnInit, PipeTransform, OnChanges {
  @Input() articleDetail: KnowledgeArticleLayoutDetail;
  constructor(private sanitized: DomSanitizer) {
    super(); 
    // this.articleDetail1 = this.articleDetail;
  }

  ngOnChanges() {
    this.ngxSpinnerService.show();
    if(this.articleDetail) {
      this.ngxSpinnerService.hide();
    }
  }

  ngOnInit() {
    // this.articleDetail1 = this.articleDetail;
  }
  //Bypass HTML Security
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }

}
