import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root'
  })
  export class BaseService {
    private backButtonEnable=new BehaviorSubject<boolean>(true);
    triggerEvent = this.backButtonEnable.asObservable();
    constructor() { 
      }
      changeValue(val: boolean) {
        this.backButtonEnable.next(val);
      }
  }