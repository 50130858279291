<!--<div class="col-md-12">
  <form class="form-horizontal mt-1" [formGroup]="notesForm">

    <h4 class="mainHeading_1 mb-1">Enter New Note:</h4>
    <textarea class="form-control" appSpace [attr.maxlength]="allowedLength" rows="3" formControlName="note"
              placeholder="Enter Note:"></textarea>
    <div *ngIf="submitted && f.note.errors" class="noteTabRed">
      <div *ngIf="f.note.errors.required">**Note Content is required**</div>
    </div>

    <button type="submit" class="btn btn-round btn-margo-action float-right mt-1" (click)="addNote()">Save</button>

  </form>
  <span class="noteTabRed mt-1 d-block">** Please do not enter any PII or financial information in Notes.</span>
</div>
<div class="col-md-12">
  <h4 class="font-weight-bold mb-1 subTitleHead_3 mt-1">Note History</h4>
  <div class="tblPrt pymntInnerTbl scroller">
    <div class="filtMain">
      <p *ngIf="contactNotes.length === 0">No Records Found</p>
      <table cellpading="0" cellspacing="0" width="100%" style="min-width:610px;" *ngIf="contactNotes.length > 0">
        <tr>
          <th>Date</th>
          <th>Time</th>
          <th>Type</th>
          <th>Note</th>
        </tr>
        <tr *ngFor="let note of contactNotes">
          <td>{{note.date}}</td>
          <td>{{note.time}}</td>
          <td>{{note.user_type}}</td>
          <td>{{note.msg}}</td>
        </tr>

      </table>
    </div>
  </div>
</div> -->


<div class="row1">
  <div class="col-md-12">
    <form class="form-horizontal mt-1" [formGroup]="notesForm">

    <div class="row">
      <h4 class="mainHeading_1 mb-1 col-8">Enter New Note:</h4>
      <!--<span class="col-2"> <h4 class="mainHeading_1 mb-1 pullright">{{showLength}}</h4></span>-->
      <label _ngcontent-ptn-c35="" class="mt-1 col-4 text-right">{{showLength}} of {{allowedLength}}</label>
    </div>

    <textarea class="form-control" appSpace [attr.maxlength]="allowedLength" rows="3" formControlName="note" placeholder="Enter Note:" (keyup)="countLength($event)"></textarea>
    <div *ngIf="submitted && f.note.errors" class="noteTabRed">
      <div *ngIf="f.note.errors.required">**Note Content is required**</div>
    </div>



  </form>
  <div class="row">
    <div class="col-xl-9">
      <span class="noteTabRed mt-1 d-block">** Please do not enter any personal or financial information in notes.</span>
    </div>
    <div class="col-xl-3">
      <button type="submit" class="btn btn-round btn-margo-action float-xl-right mt-1" (click)="addNote()">Save</button>
    </div>
  </div>

  </div>
  <div class="col-md-12">
    
    <div class="row">
      <div class="col-xl-4"><h4 class="font-weight-bold mb-xl-1 mb-0 subTitleHead_3 mt-1">Note History</h4></div>

      <div class="col-xl-8 mt-1">
        <div _ngcontent-odr-c26="" class="noteMsg"><ul _ngcontent-odr-c26=""><li _ngcontent-odr-c26=""><span _ngcontent-odr-c26="" class="denautOrange denaut-orange-bgg">System</span></li><li _ngcontent-odr-c26=""><span _ngcontent-odr-c26="" class="denautOrange denaut-white-bgg">User</span></li></ul></div>
        <!--<span>
          <strong>System</strong>
          <strong>User</strong>
        </span>-->
        <div class="custom-select1 pull-right">
          <select class="form-control ellipseW" [(ngModel)]="sortOders" (change)="sortOder($event)">
            <option value="0">Sort:</option>
            <option value="1">Date Ascending</option>
            <option value="2">Date Descending</option>
            <option value="3">Type Ascending</option>
            <option value="4">Type Descending</option>
          </select>
        </div>
      </div>
    </div>
    <p *ngIf="contactNotes.length === 0">No result available.</p>
    <div class="scroller">
      <div class="filtMain noteSect"
      infiniteScroll
     [infiniteScrollDistance]="1"
     [infiniteScrollThrottle]="15"
     (scrolled)="onListScrolled()"
     [scrollWindow]="false">
        <div *ngFor="let note of contactNotes" >
        <div class="noteRow {{note.user_type!='User'?'noteSectSys':'noteSectUser'}}">
          <div class="NoteMainTxt">
            {{note.msg}}
          </div>
          <div class="row">
            
            <div class="col-12">
              <div class="typeUseSys pull-right">
                <span class="dateNote">
                  {{ dateTimeService.insertDateAt(note.created_at | date:'MMM d, y AT h:mm')}}
                  {{ note.created_at | date : 'a' | lowercase }}
                </span>
                <!-- <span class="dateNote">{{note.created_at|date:'MM-dd-yyyy h:mm a'}}</span> -->
                <!--<span class="timeNote">{{note.time}}</span>-->
              </div>
            </div>
          </div>
        </div>
        </div>
        
      </div>
    </div>

    
    <!-- <div class="tblPrt pymntInnerTbl scroller" *ngIf="contactNotes.length > 0">

      <div class="filtMain"
           infiniteScroll
           [infiniteScrollDistance]="2"
           [infiniteScrollThrottle]="50"
           (scrolled)="onListScrolled()"
           [scrollWindow]="false">
        <table cellpading="0" cellspacing="0" width="100%" style="min-width:100%;">
          <tr>
            <th (click)="sortByDate()">Date
              <i class=" fa fa-sort text-black" aria-hidden="true" style="cursor: pointer"></i>
            </th>
            <th>Time</th>
            <th (click)="sortByType()">Type
              <i class=" fa fa-sort text-black" aria-hidden="true" style="cursor: pointer"></i>
            </th>
            <th width="260">Note</th>
          </tr>
          <tr *ngFor="let note of contactNotes">
            <td>{{note.created_at|date: 'shortDate'}}</td>
            <td>{{note.created_at|date: 'shortTime'}}</td>
            <td>{{note.user_type}}</td>
            <td>{{note.msg}}</td>
          </tr>

        </table>
      </div>
    </div> -->

  </div>
</div>
