import {BaseComponent} from "../../../firstadmin/base.component";
import {Component, Input, OnInit} from "@angular/core";
import {MasterService} from "../../../core/services/master/master.service";
import {ConfirmationDialogService} from "../../../_utils/confirmation-dialog/confirmation-dialog.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import { DateTimeService } from "src/app/services/dateTime/dateTime.service";

@Component({
  selector: 'app-contact-notes',
  templateUrl: './contact-notes.component.html',
  styleUrls: ['contact-notes.component.css']
})
export class ContactNotesComponent extends BaseComponent implements OnInit{

  @Input() contactId;
  contactNotes: any = [];
  allowedLength: number = 0;
  notesForm: UntypedFormGroup;
  limit: number = 15;
  skip: number = 0;
  submitted: boolean = false;
  recordsFetched: number = 0;
  sorting = null;
  sortType = 'DESC';
  sortOders = 0;
  note = "";
  showLength = 0;
  constructor(private master: MasterService, public dateTimeService: DateTimeService,
              private confirmationDialogService: ConfirmationDialogService, private formBuilder: UntypedFormBuilder) {
    super();
  //  this.baseService.changeValue(false);
  }
  ngOnInit() {

    //  ###############  Get Length for Notes  ######################## //

    this.master.getAllowedLengthForNotes().subscribe((data: any) => {

        this.allowedLength = parseInt(data.length);
    });

    //  ###############  Get Notes for Contact  #######################  //

    this.master.getNotesListForContact(this.contactId, this.limit, this.skip, this.sorting, this.sortType).subscribe((data: any) => {
      this.contactNotes = data;
      this.recordsFetched = this.contactNotes.length;
    })

    this.notesForm = this.formBuilder.group({
      note: ['', Validators.required]
    });

  }

  get f() { return this.notesForm.controls; }

  //  #################  Add Notes for Contact  ########################  //

  addNote(){
    this.submitted = true;

    if (this.notesForm.invalid) {
      return;
    }
    let tmpnote = this.notesForm.value.note.trim();
    if ((tmpnote == undefined || tmpnote== null ||tmpnote== '') ) {

      return;
    }
    let obj = { 'contactId': this.contactId, 'message': this.notesForm.value.note}


    this.ngxSpinnerService.show();
    this.master.addNoteForContact(JSON.stringify(obj)).subscribe((data: any) => {
      if (data.status){
        this.submitted = false;
        this.notesForm.reset();
        this.showLength = 0;
        this.toastrService.success('Note Added Successfully');
        this.skip = 0;
        this.sorting = null;
        this.master.getNotesListForContact(this.contactId, this.limit, this.skip, this.sorting, this.sortType).subscribe((data: any) => {
          this.contactNotes = data;
          this.recordsFetched = this.contactNotes.length;
        });
        this.ngxSpinnerService.hide();
      } else {
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
    })

  }

  //  ###############  On Notes List Scrolled  ##########################  //

  onListScrolled(){
    if(this.recordsFetched === this.limit){
      this.skip++;
    }else {
     
      return;
    }
    this.ngxSpinnerService.show();
    this.master.getNotesListForContact(this.contactId, this.limit, this.skip, this.sorting, this.sortType).subscribe((data: any) => {
      if (data){
        this.contactNotes = [...this.contactNotes, ...data];
        this.recordsFetched = data.length;
        this.ngxSpinnerService.hide();
      } else {
        this.ngxSpinnerService.hide();
      }
    })
  }

  //  ##################  Sort Notes by Date  ########################  //

  sortByDate(){
    this.skip = 0;
    this.sorting = 'datetime';
    if (this.sortType === 'ASC'){
      this.sortType = 'DESC';
    } else {
      this.sortType = 'ASC';
    }
    this.ngxSpinnerService.show();
    this.master.getNotesListForContact(this.contactId, this.limit, this.skip, this.sorting, this.sortType).subscribe((data: any) => {
      this.contactNotes = data;
      this.recordsFetched = this.contactNotes.length;
    });
    this.ngxSpinnerService.hide();
  }

  sortOder(event){
    if(this.sortOders==1){
      this.sortType = 'DESC';
      this.sortByDate()
    }else if(this.sortOders==2){
      this.sortType = 'ASC';
      this.sortByDate()
    }else if(this.sortOders==3){
      this.sortType = 'DESC';
      this.sortByType()
    }else if(this.sortOders==4){
      this.sortType = 'ASC';
      this.sortByType()
    }
  }

  countLength(e){
    this.showLength = e.target.value.length;
  }

  //   ##################  Sort Notes by User Type  ################  //

  sortByType(){
    this.skip = 0;
    this.sorting = 'type';
    if (this.sortType === 'ASC'){
      this.sortType = 'DESC';
    } else {
      this.sortType = 'ASC';
    }
    this.ngxSpinnerService.show();
    this.master.getNotesListForContact(this.contactId, this.limit, this.skip, this.sorting, this.sortType).subscribe((data: any) => {
      this.contactNotes = data;
      this.recordsFetched = this.contactNotes.length;
    });
    this.ngxSpinnerService.hide();
  }

}
