import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpaceDirective } from './space.directive';

@NgModule({
  declarations: [SpaceDirective],
  imports: [
    CommonModule,
  ],
  exports: [SpaceDirective]
})
export class SpaceModule { }
