import { PreloadingStrategy, Route } from '@angular/router';

import { Observable, of, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class AppCustomPreloader implements PreloadingStrategy {
  preload(route: Route, load: Function): Observable<any> {
    const loadRoute = (delay) => delay
            ? timer(150).pipe(flatMap(_ => load()))
            : load();
    return route.data && route.data.preload && shouldPreload(route) ? loadRoute(route.data.delay) : of(null);
  } 
}

 export function shouldPreload(route: Route): boolean {
  // Get NetworkInformation object
  const conn = (navigator as any).connection || (navigator as any).mozConnection || (navigator as any).webkitConnection;;
  if (conn) {
    // Save-Data mode
    if (conn.saveData) {
      return false;
    }
    // 'slow-2g', '2g', '3g', or '4g'
    const effectiveType = conn.effectiveType || '';
    // 2G network
    if (effectiveType.includes('2g') || effectiveType.includes('3g')) {
      return false;
    }
  }
  return true;
} 