import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthserviceService } from './authservice.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionAuthGuard implements CanActivateChild {
  constructor(private auth:AuthserviceService, private router:Router){}
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let data=localStorage.getItem('UserData');
    if(data){
      let jsonData=JSON.parse(data);
      if(!jsonData.sub_end_date && !jsonData.sub_start_date && jsonData?.role_id!=77){
        this.router.navigateByUrl('/checkout');
        return;
      }
      if(route.routeConfig.path!='home' && route.routeConfig.path!='sub-checkout' && route.routeConfig.path!='agreed' && route.routeConfig.path!='updatetimezone' && route.routeConfig.path!=''){
        if(jsonData?.role_id!=77){
          if(jsonData?.sub_id && (new Date(jsonData.sub_end_date) < new Date())){
            this.auth.openDialog('SUBSCRIPTION PLAN EXPIRED!','Your subscription plan has been expired.','expired');
            return false
          }else if(!jsonData?.sub_id){
            this.auth.openDialog('SUBSCRIPTION NOT PURCHASED!',`You don't have any subscription plan`,'newPlan');
            return false
          }
        }
      }
    }else{
      return true;
    }
  }

}
