import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.css']
})
export class SsoComponent implements OnInit {

  constructor() { }


  ngOnInit() {

  }

}
