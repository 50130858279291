import { Routes, RouterModule,PreloadAllModules  } from '@angular/router';
import { NgModule } from '@angular/core';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { AuthGuard } from './core/services/auth.guard';
import {SharedModule} from './shared/shared.module';
import { LoginComponent } from './login';
import { ChangelogComponent } from './changelog/changelog.component';
import { ForgotUserComponent } from './auth/forgot-user/forgot-user.component';
import { ForgotPasswrodComponent } from './auth/forgot-passwrod/forgot-passwrod.component';
import { CommonComponent } from './common/common.component';
import { HomeComponent } from './firstadmin/home/home.component';
import { FirstadminComponent } from './firstadmin/firstadmin.component';
import { ResetpasswordComponent } from './auth/resetpassword/resetpassword.component';
import { SetusernameComponent } from './auth/setusername/setusername.component';
import { NotauthorisedComponent } from './notauthorised/notauthorised.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AgrreduserComponent } from './firstadmin/agrreduser/agrreduser.component';
import { BookmarkListComponent } from './firstadmin/prospect/bookmark/bookmarklist.component';
import { IsagreedguardGuard } from './_guards/isagreedguard.guard';
import { UpdateTimeZoneComponent } from './firstadmin/hm/myprofile/updateprofile/updatetimezone.component';
import { SocialSigninComponent } from './social-signin/social-signin.component';
import { AlertComponent } from '../app/_directives/alert.component';
import { HelpComponent } from './firstadmin/help/help.component';
import { AppCustomPreloader } from './app-routing-loader';
import { BlockTemplateComponent } from './_layout/blockui/block-template.component';
import { BlockUIModule } from 'ng-block-ui';
import { NgxPrintModule } from 'ngx-print';
import { RegisterComponent } from './register';
import { HeaderComponent } from './_layout/header/header.component';
import { VerticalComponent as HeaderVerticalComponent } from './_layout/header/vertical/vertical.component';
import { HorizontalComponent as HeaderHorizontalComponent } from './_layout/header/horizontal/horizontal.component';
import { FullLayoutNavbarComponent } from './_layout/header/full-layout-navbar/full-layout-navbar.component';
import { FooterComponent } from './_layout/footer/footer.component';
import { NavigationComponent as AppNavigationComponent } from './_layout/navigation/navigation.component';
import { VerticalnavComponent } from './_layout/navigation/verticalnav/verticalnav.component';
import { HorizontalnavComponent } from './_layout/navigation/horizontalnav/horizontalnav.component';
import { CustomizerComponent } from './_layout/customizer/customizer.component';
import { HorizontalCustomizerComponent } from './_layout/customizer/horizontal-customizer/horizontal-customizer.component';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';


// perfect scroll bar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ModelLogoComponent } from '../app/_directives/model-logo.component';






// sso login
import { SsoComponent } from './sso/sso.component'
import { WebformComponent } from './webform/webform.component'
import { AgreementComponent } from './shared/agreement/agreement.component';
import { MiniUrlComponent } from './firstadmin/mini-url/mini-url.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { SubscriptionAuthGuard } from './core/services/subscription-auth.guard';
import { ReadinessGuardGuard } from './_guards/readiness-guard.guard';




const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const appRoutes: Routes = [
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
    //  { path: 'register', component: RegisterComponent },

      { path: 'login', component: LoginComponent, },
      { path: 'sso', component: SsoComponent, },
      { path: 'webform-submit', component: WebformComponent},
      { path: 'agreement', component: AgreementComponent },
      { path: "login/:support/:token/:customer_company_name/:customer_company_logo", component: LoginComponent, data: {routeData: 'support_user_page_access'} },
      { path: 'forgot-user', component: ForgotUserComponent },
      { path: 'setusername/:token', component: SetusernameComponent , data: {routeData: 'setusername_page'}},
      { path: 'forgot-pwd', component: ForgotPasswrodComponent },
      { path: 'resetpwd/:token', component: ResetpasswordComponent , data: {routeData: 'reset_password'}},
      { path: "guest", loadChildren: () => import('./firstadmin/guest/guest.module').then(m => m.GuestModule) },
      { path: "freeguest", loadChildren: () => import('./firstadmin/free_guest/freeguest.module').then(m => m.FreeGuestModule) },
      { path: "sl/:url/:token", loadChildren: () => import('./sl/sl.module').then(m => m.slModule) },
      { path: "ds", loadChildren: () => import('./directseller-dashboard/ds.module').then(m => m.dsModule) },
      // { path: '', redirectTo:'home', pathMatch:'full' },
      { path: '', component: LoginComponent },
      { path:'t/:code', component:MiniUrlComponent, loadChildren:()=>import('./firstadmin/mini-url/mini-url.module').then(m=>m.MiniUrlModule)},
      { path: "registration", loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule) },
      // { path: "home", loadChildren: () => import('./homeSubscription/home-subscription/home-subscription.module').then(m => m.HomeSubscriptionModule) },
      { path: "checkout", loadChildren: () => import('./homeSubscription/checkout-page/checkout-page.module').then(m => m.CheckoutPageModule) },
      { path: "thankyou", loadChildren: () => import('./homeSubscription/thankyou-sub/thankyou-sub.module').then(m => m.ThankyouSubModule) },
      { path: "verify-email/:token", component: VerifyEmailComponent },
      { path: "contact-us", loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule) },
      { path: "contactus-thankyou", loadChildren: () => import('./contactus-thankyou/contactus-thankyou.module').then(m => m.ContactusThankyouModule) },
      { path: "pricing", loadChildren: () => import('./user-registration/user-registration.module').then(m => m.UserRegistrationModule) },
      { path: "refer-a-friend",canActivate: [AuthGuard], loadChildren: () => import('./firstadmin/refer-a-friend/refer-a-friend.module').then(m => m.ReferAFriendModule) },
    ]
  },
  // Private layout
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      {
        path: 'logout', component: LoginComponent,
        //  canActivate: [AuthGuard]
      },
     // { path: 'changelog', component: ChangelogComponent, },
      { path: 'pagenotfound', component: PageNotFoundComponent,canActivate: [AuthGuard] },
      { path: 'notauthorised', component: NotauthorisedComponent },
      // { path: 'common', component: CommonComponent },
      {
        path: 'directseller', component: FirstadminComponent,
        canActivateChild:[SubscriptionAuthGuard,ReadinessGuardGuard],
        //  ,
        children: [
          { path: "home", component: HomeComponent ,canActivate: [AuthGuard]},
          //{ path: "chat",  loadChildren: () => import('./firstadmin/chat/chat.module').then(m => m.ChatModule) },
          { path: "agreed", component: AgrreduserComponent},
          { path: 'updatetimezone', component: UpdateTimeZoneComponent },
          { path: 'messenger',
            children:[
                // {
                //   path:'',
                //   canActivate:[AuthGuard,IsagreedguardGuard],
                //   loadChildren: () =>import('./firstadmin/chat-user/chat-user.module').then((m) => m.ChatUserModule),
                // },
                 { path: 'ds-messenger',
                   canActivate: [AuthGuard,IsagreedguardGuard],
                   loadChildren: () => import('./firstadmin/chat/chat.module').then(m => m.ChatModule) ,
                },
                { path: 'contact-messenger',
                  canActivate: [AuthGuard,IsagreedguardGuard],
                  loadChildren: () => import('./firstadmin/chat-contact/chat-contact.module').then(m => m.ChatContactModule) ,
                },
            ]
          },
          { path: 'market', loadChildren: () => import('./firstadmin/market/market.module').then(m => m.MarketModule), canActivate: [AuthGuard,IsagreedguardGuard]},
          { path: 'import-contacts', loadChildren: () => import('./import-add-contact/import-add-contact.module').then(m => m.ImportAddContactModule), canActivate: [AuthGuard,IsagreedguardGuard]},

          //get social layout
          { path: "social", loadChildren: () => import('./firstadmin/getsocial/social.module').then(m => m.SocialModule), canActivate: [AuthGuard,IsagreedguardGuard]},
          //quicktext layout
          { path: "quicktext", loadChildren: () => import('./firstadmin/quicktext/quicktext.module').then(m => m.QuickTextModule), canActivate: [AuthGuard,IsagreedguardGuard]},

          { path: "replenishment", loadChildren: () => import('./firstadmin/replunishment/replunishment.module').then(m => m.ReplunishmentModule), canActivate: [AuthGuard,IsagreedguardGuard]},

          { path: "crosssell", loadChildren: () => import('./firstadmin/crosssell/crosssell.module').then(m => m.CrossSellModule), canActivate: [AuthGuard,IsagreedguardGuard] },

          { path: "", loadChildren: () => import('./firstadmin/mydashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard,IsagreedguardGuard]},
          { path: "", loadChildren: () => import('./firstadmin/mynewdashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard,IsagreedguardGuard]},

          { path: "", loadChildren: () => import('./firstadmin/hm/hm.module').then(m => m.HMModule), canActivate: [AuthGuard,IsagreedguardGuard]},
          { path: "bookmarklist", component: BookmarkListComponent,canActivate:[AuthGuard]},
          { path: "shop-live", loadChildren: () => import('./firstadmin/shop-live/shop-live.module').then(m => m.ShopLiveModule), canActivate: [AuthGuard,IsagreedguardGuard]},

          { path: 'import-contacts/mircosoft365', loadChildren: () => import('./import-add-contact/import-add-contact.module').then(m => m.ImportAddContactModule)},
          //get social sms
          { path: "sms", loadChildren: () => import('./firstadmin/getsms/sms.module').then(m => m.SmsModule), canActivate: [AuthGuard,IsagreedguardGuard]},
          { path: "create-segments", loadChildren: () => import('./firstadmin/create-contact-segments/contactsegment.module').then(m => m.ContactSegmentModule), canActivate: [AuthGuard,IsagreedguardGuard]},
          { path: "webinar", loadChildren: () => import('./firstadmin/webinar/webinar.module').then(m => m.WebinarModule), canActivate: [AuthGuard,IsagreedguardGuard]},
          { path: "sub-checkout", loadChildren: () => import('./homeSubscription/home-checkout/home-checkout.module').then(m => m.HomeCheckoutModule), canActivate: [AuthGuard]},
          // { path: "update-subscription", loadChildren: () => import('./homeSubscription/home-checkout/home-checkout.module').then(m => m.HomeCheckoutModule), canActivate: [AuthGuard]},
          { path: "abandoned-cart", loadChildren: () => import('./firstadmin/abandon/abandon.module').then(m => m.AbandonModule), canActivate: [AuthGuard]},
          { path:"setup", loadChildren:()=>import('./firstadmin/hm/readiness/readiness.module').then(m=>m.ReadinessModule)}
        ]
      },
      { path: "host", component: FirstadminComponent, loadChildren: () => import('./firstadmin/host/host.module').then(m => m.HostModule), canActivate: [AuthGuard,IsagreedguardGuard] }
    ],
  },

  // otherwise redirect to home
  { path: '**', redirectTo: 'pagenotfound' },


];

const routingComponents = [
  PublicLayoutComponent,
  PrivateLayoutComponent,
  HeaderComponent,
  FullLayoutNavbarComponent,
  HeaderHorizontalComponent,
  HeaderVerticalComponent,
  FooterComponent,
  AppNavigationComponent,
  VerticalnavComponent,
  HorizontalnavComponent,
  CustomizerComponent,
  HorizontalCustomizerComponent,
  FullLayoutComponent,
  RegisterComponent,
  LoginComponent,
  ChangelogComponent,
  ForgotUserComponent,
  ForgotPasswrodComponent,
  CommonComponent,
  AlertComponent,
  HelpComponent,
  HomeComponent,
  FirstadminComponent,
  ResetpasswordComponent,
  SetusernameComponent,
  NotauthorisedComponent,
  PageNotFoundComponent,
  BookmarkListComponent,
  UpdateTimeZoneComponent,
  AgrreduserComponent,
  BlockTemplateComponent,
  SocialSigninComponent,
  ModelLogoComponent
]

@NgModule({
    imports: [SharedModule,
        BlockUIModule.forRoot({
            template: BlockTemplateComponent
        }),
        NgxPrintModule,
        PerfectScrollbarModule,
        /* RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules,scrollOffset: [0, 0], scrollPositionRestoration: 'top' })],*/
        RouterModule.forRoot(appRoutes, { preloadingStrategy: AppCustomPreloader, scrollOffset: [0, 0], scrollPositionRestoration: 'top', onSameUrlNavigation: 'reload' })],
    exports: [RouterModule, routingComponents],
    declarations: [routingComponents],
    providers: [AppCustomPreloader]
})
export class AppRoutingModule { }
//export const routing = RouterModule.forRoot(appRoutes , );
