//import {ChatService} from "./_services/chat.service";
import {Component, Inject, OnInit, Injectable, OnDestroy, isDevMode} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd, NavigationCancel, NavigationError, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AppConstants } from './_helpers/app.constants';
import { MenuSettingsService } from './_layout/settings/menu-settings.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { FbService } from './services/fbservice/fb.service';
import { MbService } from './services/mbservice/mb.service';
import { environment } from './../environments/environment';
import {UserIdleService} from "angular-user-idle";
import {AuthserviceService} from "./services/auth/authservice.service";
import {ToastrService} from "ngx-toastr";
import { ToggleIconService } from './_services/toggleIcon.service';
import { RoutesConstants } from './_helpers/routes.constants';
import { LogsService } from './services/logs/logs.service';


declare let pendo: any;


@Component({
  selector: 'app-main',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.css']
})
@Injectable()
export class AppComponent implements OnInit,OnDestroy {
  public _menuSettingsConfig: any;
  public _unsubscribeAll: Subject<any>;
  private _unsubscribeAllMenu: Subject<any>;
  showContent = false;
  canvaapi;
  public title;
  private oldUrl = null;
  private currentUrl = null;
  private tmpUrl = '';
  private subs: Subscription;
  private subs2: Subscription;
  private subs3: Subscription;
  isMobile;
  isTablet;
  browserName = '';
  browserVersion = '';
  publicRoute=['/forgot-user','/forgot-pwd','/thankyou'];
  companyId=environment.companyId;
  constructor(
    private spinner: NgxSpinnerService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    public loader: LoadingBarService,
    private deviceService: DeviceDetectorService,
    public _menuSettingsService: MenuSettingsService,
    private titleService: Title,
    private fbService: FbService,
    private mbService: MbService,

    private http: HttpClient,
    private userIdle: UserIdleService,
    private authService: AuthserviceService,
    private toastrService: ToastrService,
    private toggleService: ToggleIconService,
    private logs: LogsService,
    private actroute: ActivatedRoute
  ) {
    this.browserName = this.detectBrowserName();
    this.browserVersion = this.detectBrowserVersion();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this._unsubscribeAll = new Subject();
    this._unsubscribeAllMenu = new Subject();
    this.setTitle();
    if(localStorage.getItem('accesToken')){

    }

  }
  ngOnInit() {
    pendo.initialize({
        visitor: {
            id: '1111222',
        },
        account: {
            id: '22233444',
        }
    });
    let self = this;
    this.rskbodyColor();
    this.subs = this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.actroute.snapshot),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
    )
    .subscribe((data) => {
      // console.log(data['_routerState'].url);
      // console.log('RTDATA', data.data);
      this.captureRouteLogs(data['_routerState'].url, data?.data);
      this.captureDsLogs(data);
    });



    if(navigator.onLine) {
      window['CanvaButton'].initialize({
        apiKey: environment.canvaApiKey.test
      }).then(function (api) {
        self.fbService.canvaapi.next(api);
        self.mbService.canvaapi.next(api);
        self.canvaapi = api;
      }, err => {
        //console.log("Error Canva ");
      });


    }


    this._menuSettingsService.config
      .pipe(takeUntil(this._unsubscribeAllMenu))
      .subscribe((config) => {
        this._menuSettingsConfig = config;
      });
    // page progress bar percentage
    this.subs2 = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // set page progress bar loading to start on NavigationStart event router
        if (this.router.url === '/'){

        } else {
          this.tmpUrl = this.router.url;
        }

        this.loader.start();
      }
      // if (event instanceof RouteConfigLoadStart) {
      //   this.loader.increment(35);
      // }
      // if (event instanceof RouteConfigLoadEnd) {
      //   this.loader.increment(75);
      // }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        // set page progress bar loading to end on NavigationEnd event router
        if (event instanceof NavigationEnd) {
          this.oldUrl = this.tmpUrl;
          this.currentUrl = event.url;
          sessionStorage.setItem('OLDURL', this.oldUrl);
        }

        this.loader.complete();
        this.showContent = true;
        // close menu for mobile view
        if (this.deviceService.isMobile() || window.innerWidth < AppConstants.MOBILE_RESPONSIVE_WIDTH) {
          if (document.body.classList.contains('menu-open')) {
            document.body.classList.remove('menu-open');
            document.body.classList.add('menu-close');
          }
        }
      }

      if (event instanceof NavigationError){
        this.loader.complete();
      }

    });

    this.userIdle.startWatching();

    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {});

      // Start watch when time is up.

      this.userIdle.onTimeout().subscribe(() => {
        let slurl = this.router.url.startsWith('/sl');
        if (this.router.url === '/' || this.router.url === '/login' || slurl){
          this.userIdle.resetTimer();
        } else {
         // this.toastrService.error('Your session has expired. Please login again to continue');
          this.authService.logout();
          this.userIdle.resetTimer();
          this.router.navigate(['/login']);
        }
      });


  }

  setTitle() {
    this.subs3 = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        if (event.url === '/directseller/home') {
          // this.toggleService.homePageNav.next(false);
        } else {
          // this.toggleService.homePageNav.next(true);
        }

        for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
          if (!this._menuSettingsConfig.vertical_menu.items[i].submenu &&
            this._menuSettingsConfig.vertical_menu.items[i].page === this.router.url) {
            this.title = this._menuSettingsConfig.vertical_menu.items[i].title;
            break;
          } else if (this._menuSettingsConfig.vertical_menu.items[i].submenu) {
            // Level 1 menu
            for (let j = 0; j < this._menuSettingsConfig.vertical_menu.items[i].submenu.items.length; j++) {
              if (!this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].submenu &&
                this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].page === this.router.url) {
                this.title = this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].title;
                break;
              } else if (this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].submenu) {
                // Level 2 menu
                for (let k = 0; k < this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].submenu.items.length; k++) {
                  if (this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].submenu.items[k].page === this.router.url) {
                    this.title = this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].submenu.items[k].title;
                  }
                }
              }
            }
          }
        }
        if (this.title && this.router.url !== '/') {
          this.titleService.setTitle(this.title + '- '+environment.projectName);
        } else {
          if (this.router.url === '/') {
            this.title = environment.projectName+' - Login';
            this.titleService.setTitle(this.title);
            this.title = '';
          } else if (this.router.url === '/login') {
            this.titleService.setTitle('Sign In | marGo Direct Selling Software');
            this.authService._updateOgTitle('Sign In | marGo Direct Selling Software');
            this.authService._updateDescription('Sign in to access your account and manage your preferences on marGo.');
            this.authService._updateOgImage(environment.s3URL+'master_images/lfwb5dtn.png');
            this.authService._updateOgUrl(window.location.href)
          } else if (this.router.url === '/forgot-pwd') {
            this.titleService.setTitle('Forgot Password | marGo Direct Selling Software');
            this.authService._updateOgTitle('Forgot Password | marGo Direct Selling Software');
            this.authService._updateDescription('Forgot Password to recover your password and manage your preferences on marGo.');
            this.authService._updateOgImage(environment.s3URL+'master_images/lfwb5dtn.png');
            this.authService._updateOgUrl(window.location.href)
          } else if (this.router.url === '/directseller/help') {
            this.title = environment.projectName;
            this.titleService.setTitle(this.title + ' - Welcome Page');
            this.title = '';
          } else if (this.router.url === '/directseller/home') {
            this.title = environment.projectName;
            this.titleService.setTitle(this.title + ' - Welcome Page');
            this.title = '';
          } else if (this.router.url === '/corporate/home') {
            this.title = environment.projectName;
            this.titleService.setTitle(this.title + ' - Home ');
            this.title = '';
          }
          else if (this.router.url === '/corporate/home') {
            this.title = environment.projectName;
            this.titleService.setTitle(this.title + ' - Home ');
            this.title = '';
          } else if (this.router.url === '/corporate/clients') {
            this.title = environment.projectName;
            this.titleService.setTitle(this.title + ' - Clients');
            this.title = '';
          } else if (this.router.url === '/corporate/users') {
            this.title =environment.projectName;
            this.titleService.setTitle(this.title + ' - Users ');
            this.title = '';
          } else if (this.router.url === '/corporate/roles') {
            this.title = environment.projectName;
            this.titleService.setTitle(this.title + ' - Roles');
            this.title = '';
          } else if (this.router.url === '/corporate/master') {
            this.title = environment.projectName;
            this.titleService.setTitle(this.title + ' - Master Module');
            this.title = '';
          } else if (this.router.url === '/host') {
            this.title = environment.projectName;
            this.titleService.setTitle(this.title + ' - Welcome');
            this.title = '';
          } else if (this.router.url === '/host/viewevent') {
            this.title = environment.projectName;
            this.titleService.setTitle(this.title + ' - Host - View Event');
            this.title = '';
          } else if (this.router.url === '/host/viewguestlist') {
            this.title =environment.projectName;
            this.titleService.setTitle(this.title + ' - Host - View Guest List');
            this.title = '';
          } else if (this.router.url === '/host/addguest') {
            this.title = environment.projectName;
            this.titleService.setTitle(this.title + ' - Host - Add Guests');
            this.title = '';
          }else if(this.router.url === '/registration'){
            this.titleService.setTitle('Sign up | marGo Direct Selling Software');
            this.authService._updateOgTitle('Sign up | marGo Direct Selling Software');
            this.authService._updateDescription('marGo Sign Up empowers direct sellers and network marketers with AI-driven MLM CRM and marketing automation tools.')
          }else if(this.router.url === '/checkout'){
            this.titleService.setTitle('Secure Online Payments | marGo Direct Selling Software');
            this.authService._updateOgTitle('Secure Online Payments | marGo Direct Selling Software');
            this.authService._updateDescription('Make secure online payments quickly and easily with marGo. Our payment platform ensures a safe and hassle-free transaction experience. Sign up now!');
            this.authService._updateOgImage(environment.s3URL+'master_images/lfwb5dtn.png');
            this.authService._updateOgUrl(window.location.href)
          }else if(this.router.url === '/thankyou'){
            this.titleService.setTitle('Thank You for Registering | marGo Direct Selling Software');
            this.authService._updateOgTitle('Thank You for Registering | marGo Direct Selling Software');
            this.authService._updateDescription('Thank you for registering with marGo. Explore our website to discover exclusive member benefits and start your journey today!');
            this.authService._updateOgImage(environment.s3URL+'master_images/lfwb5dtn.png');
            this.authService._updateOgUrl(window.location.href)
          }else if (this.router.url === '/forgot-user') {
            this.titleService.setTitle('Forgot User Name | marGo Direct Selling Software');
            this.authService._updateOgTitle('Forgot User Name | marGo Direct Selling Software');
            this.authService._updateDescription('Forgot User Name to recover your account and manage your preferences on marGo.');
            this.authService._updateOgImage(environment.s3URL+'master_images/lfwb5dtn.png');
            this.authService._updateOgUrl(window.location.href)
          }else if (this.router.url.includes('/pricing')) {
            this.titleService.setTitle('marGo Pricing');
            this.authService._updateOgTitle('marGo Pricing');
            this.authService._updateDescription('marGo Pricing');
            this.authService._updateOgImage(environment.s3URL+'master_images/lfwb5dtn.png');
            this.authService._updateOgUrl(window.location.href)
          }
        }
      }
    });
    window.onstorage = () => {
      // When local storage changes
      if(!localStorage.getItem("accesToken") && !localStorage.getItem("UserData") ) {
        let url = this.router.url;
        if(!localStorage.getItem("accesToken") && !localStorage.getItem("UserData") &&
            url.indexOf('/sl') !== 0 && url.indexOf('/login') !== 0  && url !== '/' ) {
            this.router.navigateByUrl('login');
          }
      }
    };
  }

  rskbodyColor() {


    var backgroundImage;

    if (localStorage.getItem('ReskinningData') && !window.location.href.includes('setusername')) {
      let reskinningObj = JSON.parse(localStorage.getItem('ReskinningData'));
      	backgroundImage = reskinningObj.background_image;

    } else {
      backgroundImage = 'assets/custom/images/Background-Image.jpg';

    }

   document.body.style.backgroundImage = "url(" + backgroundImage + ")";
  }

  captureRouteLogs(url, rtdata) {

    let furl = url;
    if (rtdata && rtdata?.routeData) {
      furl = rtdata?.routeData;
    }
    let det = RoutesConstants.getRouteFields(furl);
    if (!det) return;
    let typ = 'web';
    if (this.isMobile || this.isTablet) {
      typ = this.isMobile ? 'mobile' : 'tablet';
    }
    let body = {
      log_type: typ,
      log_name: det?.log_name,
      log_description: det?.log_description,
      browser_info: this.browserName,
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14
    }

    this.logs.addDSRouteLogsInDB(body).subscribe(data => {
    }, error => {
      console.log(error);
    });
    // console.log('LOG BODY', body);
    // if (!isDevMode()) {
    //   this.logs.addDSRouteLogsInDB(body).subscribe(data => {
    //   }, error => {
    //     console.log(error);
    //   });
    // }
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
        case agent.indexOf('edg') > -1:
            return 'edge';
        case agent.indexOf('opr') > -1 && !!(<any>window).opr:
            return 'opera';
        case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
            return 'chrome';
        case agent.indexOf('trident') > -1:
            return 'ie';
        case agent.indexOf('firefox') > -1:
            return 'firefox';
        case agent.indexOf('safari') > -1:
            return 'safari';
        default:
            return 'other';
    }
}

  detectBrowserVersion() {
    var userAgent = navigator.userAgent, tem,
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
    return matchTest.join(' ');
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.subs2.unsubscribe();
    this.subs3.unsubscribe();
  }
  captureDsLogs(data:any){
    let route=data._routerState?.url;
    if(!this.publicRoute.includes(route)){
      return;
    }
    let body={
      "accessed_url" : route,
      "log_name" :this.document.title,
      "customer_cookies" :(new Date).getTime()
  }
    this.logs.captureWebsiteDsLogs(body,this.companyId).subscribe()
  }
}
