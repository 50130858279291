import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formattimezone'
})
//Will be used for Bypassing Security which are blocked by Angular
export class FormatTimezone implements PipeTransform {

  constructor() { }

  public transform(timezone: any, timezones: any): string {
    if (timezone && timezone.length > 5) {
      var formattimezone = timezones.filter(item=>item.value===timezone || item.text===timezone || item.utc===timezone || item.abbr===timezone);  
      return formattimezone[0]?.abbr;
    }
    else {
      return timezone;
    }
  }

}
