<div class="app-content">
  <div class="content-wrapper">
    <div class="content-header row mb-1">
      <!-- <app-breadcrumb class="col-12" [breadcrumb]="breadcrumb"></app-breadcrumb> -->
    </div>
    <div class="content-body">
      <section id="drag-area ">
        <div class="row">
          <div class="col-md-12" *blockUI="'changelog'; message: 'Loading'">
            <m-card (reloadFunction)="reloadChangelog($event)">
              <!-- <ng-container mCardHeaderTitle>
                  Initial Release
                </ng-container> -->
              <!-- <ng-container mCardBody>
                  <h5 class="my-2">10-12-2019 [V1.0]</h5>
                  <div class="card-text">
                    <ul>
                      <li>Initial release</li>
                    </ul>
                  </div>
                </ng-container> -->
            </m-card>

            <h1
              bindCssVariable="rskscrollbgcolor"
              [bindCssVariableValue]="rskscrollbgcolor"
              class="helpLandingPg"
              [style.color]="rskBodyTextColor + '!important'"
            >
              {{ rskBodyText }}
            </h1>

            <div class="menuIconHidden" *ngIf="!showINMobile"></div>

            <!-- <br/><br/><br/>
            <div class="row col-8" style="text-align: center;">
              <div class="butnInn col-6" *ngIf="rskHomePageButton1">
                <div class="tileAct" style="text-align: center;">
                  <span class="tileActTitle">
                    <a href="{{rskHomePageButton1_url}}" target="_blank"> {{rskHomePageButton1}} </a>
                  </span>
                </div>
              </div>
              <div class="butnInn col-6" *ngIf="rskHomePageButton2">
                <div class="tileAct">
                  <span class="tileActTitle">
                    <a href="{{rskHomePageButton2_url}}" target="_blank">{{rskHomePageButton2}} </a>
                  </span>
                </div>
              </div>
            </div> -->
          </div>
        </div>

        <div
          id="main-menu"
          class="main-menu-dashboard menu-fixed menu-dark menu-accordion menu-shadow pos-relotive dashMenu"
          data-scroll-to-active="true"
        >
          <div
            id="main-menu-content"
            class="main-menu-content ps-container ps-theme-light"
            fxFlex="auto"
          >
            <ul
              class="navigation navigation-main"
              id="main-menu-navigation"
              data-menu="menu-navigation"
            >
              <!-- Menu -->
              <!-- {{child?child.title:''}} -->
              <li
                *ngFor="let child of _menuSettingsConfig?.horizontal_menu.items"
                (click)="child?.homeLink && goToSegment(child)"
                class=""
                routerLinkActive="active"
                [ngClass]="{
                  'has-sub': child.submenu,
                  open: child.isOpen && child.submenu,
                  'nav-item': child.title,
                  'navigation-header': child.section,
                  'menu-collapsed-open': child.isSelected && child.submenu
                }"
              >
                <!-- Section -->

                <span class="menu-title" *ngIf="child.section">{{
                  child.section
                }}</span>
                <i
                  class="la"
                  *ngIf="child.section"
                  [ngClass]="child.icon"
                  data-toggle="tooltip"
                  data-placement="right"
                  data-original-title="Support"
                ></i>
                <!-- <img [src]="child.icon" [alt]="child.icon" height="45px" width="auto"> -->
                <!-- Root Menu -->
                <a
                  *ngIf="
                    child.title &&
                    !child.submenu &&
                    !child.excludeInVertical &&
                    !child.isExternalLink &&
                    !child.issupportExternalLink
                  "
                  routerLink="{{
                    child.page != 'null' ? child.page : router.url
                  }}"
                >
                  <!-- <i class="la" [ngClass]="child.icon"></i> -->
                  <img
                    [src]="child.icon"
                    [alt]="child.icon"
                    height="45px"
                    width="auto"
                  />
                  <span class="menu-title" data-i18n=""
                    >{{ child.title }}
                    <!--<span class="beta_version" *ngIf="child.code=='SMB'">(Beta)</span>-->
                  </span>
                  <span
                    *ngIf="child.badge"
                    class="badge badge-pill float-right"
                    [ngClass]="{
                      'badge-info mr-2': child.badge.type == 'badge-info',
                      'badge-danger': child.badge.type == 'badge-danger'
                    }"
                  >
                    {{ child.badge.value }}
                  </span>
                </a>
                <a
                  *ngIf="
                    !child.title &&
                    !child.submenu &&
                    child.homeLink &&
                    !child.excludeInVertical &&
                    !child.isExternalLink &&
                    !child.issupportExternalLink
                  "
                >
                  <!-- <i class="la" [ngClass]="child.icon"></i> -->
                  <img
                    [src]="child.icon"
                    [alt]="child.icon"
                    height="45px"
                    width="auto"
                  />
                  <span class="menu-title" data-i18n=""
                    >{{ child.title }}
                    <!--<span class="beta_version" *ngIf="child.code=='SMB'">(Beta)</span>-->
                  </span>
                  <span
                    *ngIf="child.badge"
                    class="badge badge-pill float-right"
                    [ngClass]="{
                      'badge-info mr-2': child.badge.type == 'badge-info',
                      'badge-danger': child.badge.type == 'badge-danger'
                    }"
                  >
                    {{ child.badge.value }}
                  </span>
                </a>
                <a
                  *ngIf="
                    child.title &&
                    !child.submenu &&
                    !child.excludeInVertical &&
                    child.isExternalLink
                  "
                  [href]="child.page"
                  (click)="toggleMenu($event, child)"
                >
                  <!-- <i class="la" [ngClass]="child.icon"></i> -->
                  <img
                    [src]="child.icon"
                    [alt]="child.icon"
                    height="45px"
                    width="auto"
                  />
                  <span class="menu-title" data-i18n="">{{ child.title }}</span>
                  <span
                    *ngIf="child.badge"
                    class="badge badge-pill float-right"
                    [ngClass]="{
                      'badge-info mr-2': child.badge.type == 'badge-info',
                      'badge-danger': child.badge.type == 'badge-danger'
                    }"
                  >
                    {{ child.badge.value }}
                  </span>
                </a>

                <!-- Submenu -->
                <a
                  *ngIf="
                    child.title && child.submenu && !child.excludeInVertical
                  "
                  routerLink="{{
                    child.page != 'null' ? child.page : router.url
                  }}"
                >
                  <!-- <i class="la" [ngClass]="child.icon"></i> -->
                  <img
                    [src]="child.icon"
                    [alt]="child.icon"
                    height="45px"
                    width="auto"
                  />
                  <span class="menu-title" data-i18n="">{{ child.title }}</span>
                  <span
                    *ngIf="child.badge"
                    class="badge badge-pill float-right"
                    [ngClass]="{
                      'badge-info mr-2': child.badge.type == 'badge-info',
                      'badge-danger': child.badge.type == 'badge-danger'
                    }"
                  >
                    {{ child.badge.value }}
                  </span>
                </a>
                <ul
                  *ngIf="child.submenu"
                  class="menu-content"
                  [@popOverState]="child.isOpen"
                >
                  <!-- Submenu of Submenu -->
                  <li
                    *ngFor="let subchild of child.submenu.items"
                    class="isShown"
                    [ngClass]="{
                      'has-sub': subchild.submenu,
                      active: subchild.isSelected && !subchild.submenu,
                      open: subchild.isOpen && subchild.submenu
                    }"
                  >
                    <a
                      class="menu-item"
                      *ngIf="!subchild.submenu && !subchild.excludeInVertical"
                      (click)="toggleMenu($event, subchild, true)"
                      routerLink="{{
                        subchild.page != 'null' ? subchild.page : router.url
                      }}"
                      >{{ subchild.title }}</a
                    >
                    <a
                      class="menu-item"
                      *ngIf="subchild.submenu && !subchild.excludeInVertical"
                      (click)="toggleMenu($event, subchild, true)"
                      routerLink="{{
                        subchild.page != 'null' ? subchild.page : router.url
                      }}"
                      >{{ subchild.title }}</a
                    >
                    <ul
                      *ngIf="subchild.submenu && !subchild.excludeInVertical"
                      class="menu-content"
                    >
                      <li
                        *ngFor="let subchild of subchild.submenu.items"
                        [ngClass]="{
                          active: subchild.isSelected && !subchild.submenu
                        }"
                      >
                        <a
                          class="menu-item"
                          *ngIf="!subchild.event"
                          routerLink="{{ subchild.page }}"
                          (click)="toggleMenu($event, subchild, true)"
                          >{{ subchild.title }}</a
                        >
                        <a
                          class="menu-item"
                          *ngIf="subchild.event"
                          (click)="callFunction(subchild.event, subchild)"
                          >{{ subchild.title }}</a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li *ngIf="requestList.length > 0 && !support_user">
                <a routerLink="/directseller/help">
                  <img
                    _ngcontent-tqo-c9=""
                    class="ng-tns-c9-0"
                    src="assets/custom/images/6.png"
                    alt="assets/custom/images/6.png"
                    width="auto"
                    height="45px"
                  />
                  <span class="menu-title"
                    >Support Request({{ requestList.length }})</span
                  >
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="home-menu" *ngIf="userData.role_id !== 77">
          <div class="row mt-1">
            <div class="col-12 home-profile-menu" #home_icons>
              <div class="row">
                <div
                  class="col-6 col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center px-1 py-1 menu-list"
                >
                  <div
                    class="tileAct"
                    [routerLink]="['/directseller/myaccount/myprofile']"
                  >
                    <div class="butnInn">
                      <img src="../../../../assets/images/svg//profile.svg" />
                      <span class="tileActTitle titleFirstColor"
                        >My Account</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center px-1 py-1 menu-list"
                >
                  <div
                    class="tileAct"
                    [routerLink]="['/directseller/setup']"
                  >
                    <div class="butnInn">
                      <img src="../../../../assets/images/svg/setup_help.svg" />
                      <span class="tileActTitle titleFirstColor"
                        >marGo Setup Help</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center px-1 py-1 menu-list"
                >
                  <div
                    class="tileAct"
                    [routerLink]="['/directseller/mycontact']"
                    (click)="setActivePage('Other', 'mycontact')"
                  >
                    <div class="butnInn">
                      <img src="../../../../assets/images/svg//contacts.svg" />
                      <span class="tileActTitle titleFirstColor"
                        >My Contacts</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center px-1 py-1 menu-list"
                >
                  <div
                    class="tileAct"
                    [routerLink]="['/directseller/create-segments/list']"
                    (click)="setActivePage('Other', 'mycontactsegment')"
                  >
                    <div class="butnInn">
                      <img src="../../../../assets/images/svg//segment.svg" />
                      <span class="tileActTitle titleFirstColor"
                        >My Segment</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center px-1 py-1 menu-list"
                  *ngIf="!userData?.is_bu"
                >
                  <div
                    class="tileAct"
                    [routerLink]="['/directseller/myevent']"
                    (click)="setActivePage('Other', 'myevent')"
                  >
                    <div class="butnInn">
                      <img src="../../../../assets/images/svg//events.svg" />
                      <span class="tileActTitle titleFirstColor"
                        >My Events</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center px-1 py-1 menu-list"
                  *ngIf="userData?.is_bu"
                >
                  <div
                    class="tileAct"
                    [routerLink]="['/directseller/event']"
                    (click)="setActivePage('Other', 'event')"
                  >
                    <div class="butnInn">
                      <img src="../../../../assets/images/svg//events.svg" />
                      <span class="tileActTitle titleFirstColor">Events</span>
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center px-1 py-1 menu-list"
                >
                  <div class="tileAct" [routerLink]="['/directseller/myhost']">
                    <div class="butnInn">
                      <img src="../../../../assets/images/svg//host.svg" />
                      <span class="tileActTitle titleFirstColor">My Hosts</span>
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center px-1 py-1 menu-list"
                >
                  <div
                    class="tileAct"
                    [routerLink]="['/directseller/mycampaign']"
                    (click)="setActivePage('Other', 'mycampaign')"
                  >
                    <div class="butnInn">
                      <img src="../../../../assets/images/svg//campaigns.svg" />
                      <span class="tileActTitle titleFirstColor"
                        >My Campaigns</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center px-1 py-1 menu-list"
                >
                  <div
                    class="tileAct"
                    [routerLink]="['/directseller/myjourney']"
                  >
                    <div class="butnInn">
                      <img
                        src="../../../../assets/images/svg//my-journey.svg"
                      />
                      <span class="tileActTitle titleFirstColor"
                        >My Journey</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center px-1 py-1 menu-list"
                >
                  <div
                    class="tileAct"
                    [routerLink]="['/directseller/productCatalogue']"
                  >
                    <div class="butnInn">
                      <img
                        src="../../../../assets/images/svg//product-catalog.svg"
                      />
                      <span class="tileActTitle titleFirstColor"
                        >Product Catalog</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-6 col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center px-1 py-1 menu-list"
                >
                  <div
                    class="tileAct"
                    [routerLink]="['/directseller/mypurchases']"
                    (click)="setActivePage('Other', 'mypurchases')"
                  >
                    <div class="butnInn">
                      <img src="../../../../assets/images/svg//purchases.svg" />
                      <span class="tileActTitle titleFirstColor"
                        >Purchases</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center px-1 py-1 menu-list"
                >
                  <div
                    class="tileAct"
                    [routerLink]="['/directseller/myorders']"
                  >
                    <div class="butnInn">
                      <img
                        src="../../../../assets/images/svg//order-icon.svg"
                      />
                      <span class="tileActTitle titleFirstColor">Orders</span>
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center px-1 py-1 menu-list"
                >
                  <div
                    class="tileAct"
                    [routerLink]="['/directseller/mypayment']"
                  >
                    <div class="butnInn">
                      <img
                        src="../../../../assets/images/svg//payment-info.svg"
                      />
                      <span class="tileActTitle titleFirstColor">Credits</span>
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center px-1 py-1 menu-list"
                >
                  <div class="tileAct" [routerLink]="['/directseller/mylogs']">
                    <div class="butnInn">
                      <img src="../../../../assets/images/svg/logs-icon.svg" />
                      <span class="tileActTitle titleFirstColor"
                        >Activity Logs</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center px-1 py-1 menu-list"
                  *ngIf="userData?.is_independent_ds == true"
                >
                  <div
                    class="tileAct"
                    [routerLink]="['/directseller/reskinning']"
                  >
                    <div class="butnInn">
                      <img
                        src="../../../../assets/images/svg//reskinning.svg"
                      />
                      <span class="tileActTitle titleFirstColor"
                        >Reskinning</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center px-1 py-1 menu-list"
                >
                  <div class="tileAct" [routerLink]="['/directseller/myteam']">
                    <div class="butnInn">
                      <img src="../../../../assets/images/svg//team.svg" />
                      <span class="tileActTitle titleFirstColor">My Team</span>
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 col-xl-3 col-lg-4 col-md-4 col-sm-4 text-center px-1 py-1 menu-list"
                >
                  <div
                    *ngIf="!isBu"
                    class="tileAct"
                    [routerLink]="['/directseller/help/support']"
                  >
                    <div class="butnInn">
                      <img
                        src="../../../../assets/images/svg//question-circle-help.svg"
                      />
                      <span class="tileActTitle titleFirstColor"
                        >Help & Tips</span
                      >
                    </div>
                  </div>
                  <div
                    *ngIf="isBu"
                    class="tileAct"
                    [routerLink]="['/directseller/help']"
                  >
                    <div class="butnInn">
                      <img
                        src="../../../../assets/images/svg//question-circle-help.svg"
                      />
                      <span class="tileActTitle titleFirstColor"
                        >Help & Tips</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
<ng-template #alertPopup let-modal>
  <div class="scroller">
    <div class="wholePop filtMain subs-renewal">
      <div class="modal-header dialHead w-100">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss(0)"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-left">
        <div *ngIf="userData.subscription_type == '14-Days'; else paidData">
          <h4>Your Free Subscription Will Renew in Paid Subscription Soon</h4>
          <p>
            We hope you are enjoying your
            <b>Free Trial - Professional</b> subscription! This is a friendly
            reminder that your subscription will automatically renew on
            <b>{{ renewalDate | date : "MMMM dd, YYYY" }}</b
            >.
          </p>
          <div class="plan-detail">
            <h5 class="text-left">Plan Details:</h5>

            <table align="center" class="subsplan-detail table">
              <tr>
                <th><b>Subscription Plan</b></th>
                <td>:</td>
                <td>{{ professioanlData?.title || "" }}</td>
              </tr>
              <tr>
                <th><b>Renewal Date</b></th>
                <td>:</td>
                <td>{{ renewalDate | date : "MMMM dd, YYYY" }}</td>
              </tr>
              <tr>
                <th><b>Amount</b></th>
                <td>:</td>
                <td>
                  ${{
                    (professioanlData?.price | number : "1.2-2") + "/monthly"
                  }}
                </td>
              </tr>
            </table>
          </div>
          <p class="mt-2">
            If you wish to cancel your subscription, you can do so by visiting
            <a
              href="/directseller/myaccount/update-subscription"
              target="_blank"
              >here</a
            >
            before {{ renewalDate | date : "MMMM dd, YYYY" }}.
          </p>
        </div>
        <ng-template #paidData>
          <h4>
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Upcoming
            Subscription Renewal Notification
          </h4>
          <p>
            We hope you're enjoying your
            <b>{{ professioanlData?.title | titlecase }}</b
            >! This is a friendly reminder that your subscription is require to
            renew on <b>{{ renewalDate | date : "MMMM dd, YYYY" }}</b
            >.
          </p>
          <div class="text-left m-pad-0">
            <h5 class="text-left mb-1">Subscription Details:</h5>
            <table align="center" class="subsplan-detail table">
              <tr>
                <td><b>Subscription:</b></td>
                <td>{{ professioanlData?.title || "" }}</td>
              </tr>
              <tr>
                <td><b>Plan:</b></td>
                <td>{{ userData?.subscription_type | titlecase }}</td>
              </tr>
              <tr>
                <td><b>Renewal Date:</b></td>
                <td>{{ renewalDate | date : "MMMM dd, YYYY" }}</td>
              </tr>
              <tr>
                <td><b>Amount:</b></td>
                <td>
                  ${{
                    userData?.subscription_type === "monthly"
                      ? (professioanlData?.price | number : "1.2-2")
                      : updateYearlyValue(professioanlData)
                  }}
                </td>
              </tr>
            </table>
          </div>
          <p class="mt-2">
            <b class="h5-heading">What to Expect:</b>
            On the renewal date, your account will be charged
            <b
              >${{
                userData?.subscription_type === "monthly"
                  ? (professioanlData?.price | number : "1.2-2")
                  : updateYearlyValue(professioanlData)
              }}</b
            >
            for the next subscription period. No action is required from your
            end to continue enjoying {{ professioanlData?.title }}.
          </p>
          <p>
            If you wish to cancel your subscription, you can do so by visiting
            <a
              href="/directseller/myaccount/update-subscription"
              target="_blank"
              >here</a
            >
            before {{ renewalDate | date : "MMMM dd, YYYY" }}.
          </p>
        </ng-template>
      </div>
      <div class="modal-footer text-center d-block border-0 pt-0">
        <button
          type="button"
          routerLink="/directseller/myaccount/update-subscription"
          (click)="modal.dismiss(0)"
          class="btn btn-margo-action btn-round text-uppercase ml-2"
        >
          {{
            userData.subscription_type === "14-Days"
              ? "Cancel Subscription"
              : "Renew"
          }}
        </button>
        <button
          type="button"
          (click)="modal.dismiss(0)"
          class="btn btn-margo-cancel btn-round"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</ng-template>
