<div class="container pr-0 pl-0 pl-sm-1 pr-sm-1">
  <div class="row">
    <div class="col-12">
      <h4 class="font-weight-bold mainHeading_1 mb-1 float-left" *ngIf="contactByIdData">Contact Information</h4>

    </div>
  </div>
</div>

<!-- Contact Card Start -->


<div class="container pr-0 pl-0 pl-sm-1 pr-sm-1" *ngIf="contactByIdData && contacttypeid !=1">
  <!-- <div class="row">
       <div class="col"><h4 class="pb-0 pb-sm-1 float-sm-left mainHeading_1"></h4></div>
      </div>  -->


  <div class="row">
    <div class="col-xl-7">

      <section class="showMbl mb-2">
        <!-- <div class="d-none d-sm-block">
          <div></div>
          <h5 class="">Map</h5>
          <div class="mapState mt-1" *ngIf="!mapURL.includes('addressNotFound')"><img src="{{mapURL}}">
          </div>
          <div class="mt-1" *ngIf="mapURL.includes('addressNotFound')">
            <div class="alert alert-light" role="alert">
              <i class="fa fa-info"></i> &nbsp; &nbsp;Add home address to view Google Map for Contact.
            </div>
          </div>
        </div> -->
        <div class="card1">
          <div class="card-body1">
            <div class="row">
              <div class="w-100"
                *ngIf="!hostTypeStatus && contactByIdData  && inputdata.page!=='quicktext' && inputdata.page!=='socialhost'">
                <div class="container">
                  <h5 class="mt-2 font-weight-bold" *ngIf="showContact || showTeamMember">Actions</h5>
                  <div class="theme-fa-icon mcb-prst row" *ngIf="inputdata.name==='prospect'">


                    <div class="col-6 col-lg-6 col-xl-6 col-sm-3 px-1 py-1">
                      <div class="tileAct" *ngIf="showContact">
                        <!-- <div class="butnInn" (click)="selectedShop(70,'shop','',optStatus, confirmBox)">
                          <img src="assets/custom/images/shopIcon.png">
                          <span class="tileActTitle">Shop</span>
                        </div> -->

                        <div *ngIf="!isIndependenceDs" class="butnInn" (click)="selectedShop(70,'shop','',optStatus, confirmBox)">
                          <img src="assets/custom/images/shopIcon.png">
                          <span class="tileActTitle">Shop</span>
                        </div>
                        <div *ngIf="isIndependenceDs" class="butnInn" (click)="checkProductQty(confirmMessageBox)">
                          <img src="assets/custom/images/shopIcon.png">
                          <span class="tileActTitle">Shop</span>
                        </div>

                      </div>
                    </div>

                    <div class="col-6 col-lg-6 col-xl-6 col-sm-3 px-1 py-1">
                      <div class="tileAct" *ngIf="showTeamMember">
                        <div class="butnInn" (click)="selectedShop(68,'host','',optStatus, confirmBox)">
                          <img src="assets/custom/images/hostIcon2.png">
                          <span class="tileActTitle">Host</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 col-lg-6 col-xl-6 col-sm-3 px-1 py-1">
                      <div class="tileAct" *ngIf="showContact">
                        <div class="butnInn" (click)="selectedShop(67,'join','',optStatus, confirmBox)">
                          <img src="assets/custom/images/joinIcon.png">
                          <span class="tileActTitle">Join</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 col-lg-6 col-xl-6 col-sm-3 px-1 py-1">
                      <div class="tileAct" *ngIf="showTeamMember">
                        <div class="butnInn" (click)="selectmarketoption(4,'host','Host & Guest')">
                          <img src="assets/custom/images/eventIconNew.png">
                          <span class="tileActTitle">Events</span>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-6" *ngIf="showContact" [ngClass]="{'disabled': !optStatus.optSms || contactByIdData?.sms_consent === 'Rejected'}">
                      <div class="tileAct">
                        <div class="butnInn" (click)="optStatus.optSms && contactByIdData?.sms_consent !== 'Rejected' && goToInstantMessage()">
                          <img src="assets/custom/images/instant-message.png">
                          <span class="tileActTitle">Instant Message</span>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="theme-fa-icon mcb-prst" *ngIf="inputdata.name==='teammember' && isBU">

                  <!--  <div class="row">
                     <div class="col-md-12">
                       <h4 class="font-weight-bold" *ngIf="contactByIdData">What do you want to offer them?</h4>
                     </div>
                   </div> -->

                  <div class="row">

                    <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 py-1 text-center">
                      <div class="tileAct">
                        <div class="butnInn" (click)="selectedShop(124,'encouragement')">
                          <img src="assets/custom/images/encouragIcon.png">
                          <span class="tileActTitle">Encouragement</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 py-1 text-center">
                      <div class="tileAct">
                        <div class="butnInn" (click)="selectedShop(125,'training',true)">
                          <img src="assets/custom/images/trainingIcon.png">
                          <span class="tileActTitle">Training</span>
                        </div>
                      </div>
                    </div>


                  </div>

                </div>


                <div class="theme-fa-icon mcb-prst" *ngIf="showHostJourney  && showTeamMember && showContact">

                  <!--  <div class="row">
                     <div class="col-md-12">
                       <h4 class="font-weight-bold">Will the event be In-Person Or Virtual?</h4>
                     </div>
                   </div> -->
                  <div class="container">
                    <div class="row">

                      <div class="col-xl-6 col-md-12 col-6 mb-2">

                        <div class="tileAct card mb-0">
                          <div class="butnInn" (click)="addCoHostModal(confirmBoxCoHost, 127,'In-Person',true)">
                            <img src="assets/custom/images/inPersonIcon.png">
                            <span class="tileActTitle">In-Person</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-6 col-md-12 col-6 mb-2">

                        <div class="tileAct card mb-0">
                          <div class="butnInn" (click)="addCoHostModal(confirmBoxCoHost, 128,'Virtual',true)">
                            <img src="assets/custom/images/onboardingIconNew.png">
                            <span class="tileActTitle">Virtual</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-6 col-md-12 col-6">

                        <div class="tileAct card mb-0">
                          <div class="butnInn"
                            (click)="addCoHostModal(confirmBoxCoHost, 129,'In-Person & Virtual',true)">
                            <img src="assets/custom/images/personIconVirtual.png">
                            <span class="tileActTitle">In-Person & Virtual</span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <!-- <div class="row">
                    <div class="col-xl-10">
                      <button class="cbtn mt-1" (click)="addCoHostModal(confirmBoxCoHost, 127,'In-Person',true)" class="top cbtn"><i
                        class="fa fa-address-card-o red-dr-icon" aria-hidden="true"></i>In-Person</button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-10">
                      <button class="cbtn mt-1" (click)="addCoHostModal(confirmBoxCoHost, 128,'Virtual',true)" class="top cbtn"><i
                        class="fa fa-tv orange-dr-icon" aria-hidden="true"></i>Virtual</button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-10">
                      <button class="cbtn mt-1" (click)="addCoHostModal(confirmBoxCoHost, 129,'In-Person & Virtual',true)" class="top cbtn"><i
                        class="fa fa-object-group blue-lt-icon" aria-hidden="true"></i>In-Person & Virtual</button>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="d-block d-sm-none">
          <div></div>
          <h5 class="mt-2">Map</h5>
          <div class="mapState mt-1 mb-md-0 mb-2" *ngIf="!mapURL.includes('addressNotFound')"><img src="{{mapURL}}">
          </div>
          <div class="mt-1" *ngIf="mapURL.includes('addressNotFound')">
            <div class="alert alert-light" role="alert">
              <i class="fa fa-info"></i> &nbsp; &nbsp;Add home address to view Google Map for Contact.
            </div>
          </div>
          <div></div>
        </div> -->
      </section>
      <div class="card">
        <div class="card-body">
          <div class="row">

            <div class="col-sm-9 col-8">
              <h5 class="font-weight-bold mb-0 subTitleHead_2" *ngIf="contactByIdData"><span
                  class="float-left pr-1 quick-view-content">{{contactByIdData.contact_fname | titlecase}}
                  {{contactByIdData.contact_lname | titlecase}}</span>
              </h5>
            </div>
            <div class="col-sm-3 col-4 text-right">
              <div class="d-inline-block bookMMain mr-1">


                <a href="javascript:void(0)" (click)="addToBookmark();" class="" *ngIf="!isbookmarked">
                  <img title="Add To Favorite" alt="Add To Favorite" src="assets/custom/images/favritIcon.png">
                </a>
                <a href="javascript:void(0)" (click)="addToBookmark();" class="" *ngIf="isbookmarked">


                  <img title="Contact is Favorited" alt="Favorited" src="assets/custom/images/favritIcon2.jpg">

                </a>



              </div>


              <div class="contEdt d-inline-block editMianIcon" *ngIf="inputdata.name!=='teammember'">
                <a href="#" routerLink="{{editcontacturl}}" class="text-uppercase"
                  *ngIf="!support_user ||  (support_user && contactByIdData.support_user_id)"><img
                    src="assets/custom/images/editIconNew.png"></a>
              </div>


            </div>
          </div>

          <div class="pb-1 clearfix" *ngIf="contacttypename.trim()=='Host & Guest'">Customer / Prospect </div>
          <div class="pb-1 clearfix" *ngIf="contacttypename.trim()!=='Host & Guest'">{{contacttypename}}
            {{agreeToHost ? '/ Host' : ''}}</div>

          <div class="" *ngIf="inputdata.name!=='teammember' && inputdata.page!=='socialhost'"><a href="#"
              routerLink="/directseller/mycontact/report">Activity</a></div>
          <div class="" *ngIf="inputdata.name==='teammember'"><a href="#"
              routerLink="/directseller/myteam/report">Activity History</a></div>
          <!-- <h6 class="font-weight-bold pb-1" *ngselectedShopf = "contacttypeid ===3 ">PROSPECT</h6> -->

          <!-- Tab Start  -->



          <div *ngIf="inputdata.page==='myteam'">
            <div class="row3 mt-1">
              <div class="col-12">
                <div class="row mb-1" *ngIf="contactByIdData.contact_email">
                  <div class="col-xl-4 col-md-3 font-weight-bold">Email:</div>
                  <div class="col-xl-8 col-md-9">{{contactByIdData.contact_email}}</div>
                </div>
                <div class="row  mb-1" *ngIf="contactByIdData.contact_phone">
                  <div class="col-xl-4 col-md-3 font-weight-bold">Primary Contact:</div>
                  <div class="col-xl-8 col-md-9 ">
                    <!-- <div *ngIf="contactByIdData.contact_country && phonecode">+ <b>{{phonecode}}</b></div>  -->
                    {{contactByIdData.contact_phone | newformatPhoneNumber:phonecode}}
                   </div>
                </div>
                <div class="row mb-1" *ngIf="contactByIdData.contact_phone_type">
                  <div class="col-xl-4 col-md-3 font-weight-bold">Contact Type: </div>
                  <div class="col-xl-8 col-md-9">{{contactByIdData.contact_phone_type==1?"Landline":"Mobile"}}</div>
                </div>
                <div class="row mb-1" *ngIf="contactByIdData.contact_phone_other">
                  <div class="col-xl-4 col-md-3 font-weight-bold">Secondary Contact:</div>
                  <div class="col-xl-8 col-md-9">{{contactByIdData.contact_phone_other}}</div>
                </div>

                <div class="row  mb-1"
                  *ngIf="(contactByIdData.contact_address|| contactByIdData.contact_address_2 || contactByIdData.contact_country||contactByIdData.contact_city || contactByIdData.contact_state)">
                  <div class="col-xl-4 col-md-3 font-weight-bold">Address:</div>
                  <div class="col-xl-8 col-md-9" *ngIf="contactByIdData">
                    <div class="row">
                      <div class="col-9 col-sm-6  col-xl-7">
                        <p *ngIf="contactByIdData && contactByIdData.contact_address" class="mb-0">
                          {{contactByIdData.contact_address}} </p>
                        <p *ngIf="contactByIdData && contactByIdData.contact_address_2" class="mb-0">
                          {{contactByIdData.contact_address_2}} </p>
                        <p *ngIf="contactByIdData && contactByIdData.contact_city" class="mb-0">
                          {{contactByIdData.contact_city | titlecase}}</p>
                        <p *ngIf="contactByIdData && contactByIdData.contact_state" class="mb-0">
                          {{contactByIdData.contact_state}}</p>
                        <p *ngIf="contactByIdData && contactByIdData.contact_country && contactByIdData.contact_zip"
                          class="mb-0">{{contactByIdData.contact_country}} - {{contactByIdData.contact_zip}}</p>
                        <p *ngIf="contactByIdData && contactByIdData.contact_country && !contactByIdData.contact_zip"
                          class="mb-0">{{contactByIdData.contact_country}}</p>
                      </div>
                      <div class="col-xl-5 col-sm-6 col-3 text-right pl-0">
                        <a title="Copy to clipboard" (click)="copy(contactByIdData)" class="d-block"><i
                            class="fa fa-clipboard"></i></a>
                        <label class="copiedMsg">{{message}}</label>

                      </div>
                      <div class="col-12 text-right">
                        <div class="locatToLink">
                          <a href="{{gMapUrl}}" target="_blank">Locate to Map</a>
                        </div>
                      </div>

                    </div>


                  </div>

                </div>
                <div class="row mb-1" *ngIf="contactByIdData.customer_type!='' && eventFlag==true && inputdata.page!=='myteam'" >
                  <div class="col-xl-4 col-md-3 font-weight-bold">Customer Type</div>
                  <div class="col-xl-8 col-md-9">{{contactByIdData.customer_type}}</div>
                </div>

                <div class="row mb-1"
                  *ngIf="contactByIdData.contact_birthdate && contactByIdData.contact_birthdate.trim() && contactByIdData.contact_birthdate!='january 1'">
                  <div class="col-xl-4 col-md-3 font-weight-bold">Birth Date</div>
                  <div class="col-xl-8 col-md-9">{{contactByIdData.contact_birthdate| date:'MMM d, y'}}</div>
                </div>
                <!-- <div class="row  mb-1" *ngIf="contactByIdData.contact_age_range">
                <div class="col-12 col-md-3 col-sm-4 font-weight-bold">Age Range</div>
                <div class="col-12 col-md-9 col-sm-8">{{contactByIdData.contact_age_range }}</div>
              </div> -->
                <div class="row mb-1" *ngIf="contactByIdData.contact_sex">
                  <div class="col-xl-4 col-md-3 font-weight-bold">Gender:</div>
                  <div class="col-xl-8 col-md-9">{{contactByIdData.contact_sex}}</div>
                </div>
                <div class="row  mb-1" *ngIf="contactByIdData.loyalty_type_id">
                  <div class="col-xl-4 col-md-3 font-weight-bold">Loyality Type:</div>
                  <div class="col-xl-8 col-md-9">{{contactByIdData.loyalty_type_id }}</div>
                </div>


                <div class="row grey-bg pb-1 mt-2" *ngIf="contactByIdData.facebook_id || contactByIdData.twitter_id || contactByIdData.instagram_id || contactByIdData.linkedin_id || contactByIdData.youtube_id
                || contactByIdData.whatsapp_id || contactByIdData.pinterest_id">


                  <div class="accrdContact">

                    <span class="scilActBtn">Social Accounts</span>

                  </div>
                  <div class="col-md-6" *ngIf="contactByIdData && contactByIdData.facebook_id">
                    <div class="row1 mt-1">
                      <div class="float-left pr-1"><i class="fa fa-facebook-square txt-color-fb socialIconSize"
                          aria-hidden="true"></i></div>
                      <div class="socPad float-left" *ngIf="contactByIdData">
                        <a href="{{contactByIdData.facebook_id }}" target="_blank">{{contactByIdData.facebook_id }}</a>
                      </div>
                    </div>

                  </div>

                  <div class="col-md-6" *ngIf="contactByIdData && contactByIdData.facebook_id">
                    <div class="row1  mt-1">
                      <div class="float-left pr-1"><i class="fa fa-facebook-messenger" aria-hidden="true"></i></div>
                      <div class="socPad float-left" *ngIf="contactByIdData">
                        <a (click)="getFBMessenger(contactByIdData.facebook_id )" target="_blank"
                          title="Facebook Messenger">FB Personal Messenger</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.twitter_id">
                    <div class="row1  mt-1">
                      <div class="float-left pr-1"><i class="fa fa-twitter-square txt-color-twt socialIconSize"
                          aria-hidden="true"></i></div>
                      <div class="socPad float-left" *ngIf="contactByIdData">
                        <a href="{{contactByIdData.twitter_id }}" target="_blank">{{contactByIdData.twitter_id }}</a>
                      </div>
                    </div>

                  </div>

                  <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.instagram_id">
                    <div class="row1  mt-1">
                      <div class="float-left pr-1"><i class="fa fa-instagram txt-color-insta socialIconSize"
                          aria-hidden="true"></i></div>
                      <div class="socPad float-left" *ngIf="contactByIdData">
                        <a href="{{contactByIdData.instagram_id }}/" target="_blank">{{contactByIdData.instagram_id
                          }}</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.linkedin_id">
                    <div class="row1  mt-1">
                      <div class="float-left pr-1"><i class="fa fa-linkedin-square txt-color-twt socialIconSize"
                          aria-hidden="true"></i></div>
                      <div class="socPad float-left" *ngIf="contactByIdData">
                        <a href="{{contactByIdData.linkedin_id }}/" target="_blank">{{contactByIdData.linkedin_id }}</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.youtube_id">
                    <div class="row1 mt-1">
                      <div class="float-left pr-1"><i class="fa fa-youtube txt-color-youtube socialIconSize"
                          aria-hidden="true"></i></div>
                      <div class="socPad float-left"> <a href="{{contactByIdData.youtube_id}}/"
                          target="_blank">{{contactByIdData.youtube_id}}</a></div>
                    </div>
                  </div>
                  <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.whatsapp_id">
                    <div class="row1 mt-1">
                      <div class="float-left pr-1"><i class="fa fa-whatsapp txt-color-whatsaap socialIconSize"
                          aria-hidden="true"></i></div>
                      <div class="socPad float-left">
                        <!-- <a href="https://www.whatsapp.com/{{contactByIdData.whatsapp_id}}/"
                          target="_blank">{{contactByIdData.whatsapp_id}}</a> -->
                        <a href="https://web.whatsapp.com/" target="_blank">{{contactByIdData.whatsapp_id}}</a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.pinterest_id">
                    <div class="row1 mt-1">


                      <div class="float-left pr-1"><i class="fa fa-pinterest-p txt-bg-color-pint socialIconSize"
                          aria-hidden="true"></i></div>
                      <div class="socPad float-left">
                        <a href="{{contactByIdData.pinterest_id}}" target="_blank">{{contactByIdData.pinterest_id}}</a>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.google_my_biz_id">
                    <div class="row1 mt-1">
                      <div class="float-left pr-1"><i
                          class="fa fa-google-plus red-ggl-icon txt-bg-color-pint socialIconSize"
                          aria-hidden="true"></i>
                      </div>
                      <div class="socPad float-left">
                        <a href="https://www.google.com/business/"
                          target="_blank">{{contactByIdData.google_my_biz_id}}</a>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <!-- <a href="javascript:void(0)" *ngIf="backurl" routerLink="{{backurl}}" class="btn btn-round btn-margo-action mrgSml-5 backButtonRight">Back</a> -->


          </div>


          <mat-tab-group *ngIf="contactByIdData && inputdata.page!=='myteam'" style="margin-top: 8px"
            bindCssVariable="tabColor" [bindCssVariableValue]="tabColor" class="custom-tab"  (selectedTabChange)="setData($event)">

            <mat-tab label="Contact">

              <!-- Contact Information Start -->

              <div class="row3 mt-1">
                <div class="col-12">

                  <div class="row mb-1" *ngIf="hostUserActivationLink">
                    <div class="col-xl-5 col-md-3 font-weight-bold">Host Username:</div>
                    <div class="col-xl-7 col-md-9">{{userName}}</div>
                  </div>

                  <div class="row mb-1" *ngIf="hostUserActivationLink==false && userName!=''">
                    <div class="col-xl-5 col-md-3 font-weight-bold">Host Username: </div>
                    <div class="col-xl-7 col-md-9">
                      <a (click)="sendActivationLink()" href="javascript:void(0);">Re-send Link</a>
                    </div>
                  </div>


                  <div class="row mb-1" *ngIf="contactByIdData.username =='' && contactByIdData.token ==''">
                    <div class="col-xl-5 col-md-3 font-weight-bold space-nowrap">DS Website Username: </div>
                    <div class="col-xl-7 col-md-9">
                      <a (click)="sendContactActivationLink('send')" href="javascript:void(0);">Send Invite Link</a>
                    </div>
                  </div>

                  <div class="row mb-1" *ngIf="contactByIdData.username">
                    <div class="col-xl-5 col-md-3 font-weight-bold space-nowrap">DS Website Username: </div>
                    <div class="col-xl-7 col-md-9">{{contactByIdData.username}}</div>
                  </div>

                  <div class="row mb-1" *ngIf="contactByIdData.username =='' && contactByIdData.token !=''">
                    <div class="col-xl-5 col-md-3 font-weight-bold space-nowrap">DS Website Username: </div>
                    <div class="col-xl-7 col-md-9">
                      <a (click)="sendContactActivationLink('resend')" href="javascript:void(0);">Re-send Link</a>
                    </div>
                  </div>

                  <div class="row mb-1" *ngIf="contactByIdData.contact_email">
                    <div class="col-xl-5 col-md-3 font-weight-bold">Email:</div>
                    <div class="col-xl-7 col-md-9">{{contactByIdData.contact_email}}</div>
                  </div>

                  <div class="row  mb-1" *ngIf="hardBounceStatus">
                    <div class="col-12 text-right" >
                      <div class="locatToLink">
                        <a title="{{hardBounce}}" class="d-block">
                          <i class="fa fa-hdd-o fa-lg"></i>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="row  mb-1" *ngIf="contactByIdData.contact_phone">
                    <div class="col-xl-5 col-md-3 font-weight-bold">Primary Contact:</div>
                    <div class="col-xl-7 col-md-9 ">
                      <!-- <div *ngIf="contactByIdData.contact_country && phonecode">+ <b>{{phonecode}}</b></div>  -->
                      {{contactByIdData.contact_phone | newformatPhoneNumber:phonecode}}
                    </div>
                  </div>
                  <div class="row mb-1" *ngIf="contactByIdData.contact_phone_type">
                    <div class="col-xl-5 col-md-3 font-weight-bold">Contact Type: </div>
                    <div class="col-xl-7 col-md-9">{{contactByIdData.contact_phone_type==1?"Landline":"Mobile"}}</div>
                  </div>
                  <div class="row mb-1" *ngIf="contactByIdData.contact_phone_other">
                    <div class="col-xl-5 col-md-3 font-weight-bold">Secondary Contact:</div>
                    <div class="col-xl-7 col-md-9">{{contactByIdData.contact_phone_other}}</div>
                  </div>




                  <div class="row  mb-1"
                    *ngIf="(contactByIdData.contact_address|| contactByIdData.contact_address_2 || contactByIdData.contact_country||contactByIdData.contact_city || contactByIdData.contact_state)">
                    <div class="col-xl-5 col-md-3 font-weight-bold">Address:</div>
                    <div class="col-xl-7 col-md-9" *ngIf="contactByIdData">

                      <div class="row">
                        <div class="col-9 col-sm-6  col-xl-7">
                          <p *ngIf="contactByIdData && contactByIdData.contact_address" class="mb-0">
                            {{contactByIdData.contact_address}} </p>
                          <p *ngIf="contactByIdData && contactByIdData.contact_address_2" class="mb-0">
                            {{contactByIdData.contact_address_2}} </p>
                          <p *ngIf="contactByIdData && contactByIdData.contact_city" class="mb-0">
                            {{contactByIdData.contact_city | titlecase}}</p>
                          <p *ngIf="contactByIdData && contactByIdData.contact_state" class="mb-0">
                            {{contactByIdData.contact_state}}</p>
                          <p *ngIf="contactByIdData && contactByIdData.contact_country && contactByIdData.contact_zip"
                            class="mb-0">{{contactByIdData.contact_country}} - {{contactByIdData.contact_zip}}</p>
                          <p *ngIf="contactByIdData && contactByIdData.contact_country && !contactByIdData.contact_zip"
                            class="mb-0">{{contactByIdData.contact_country}}</p>
                        </div>


                        <div class="col-xl-5 col-sm-6 col-3 text-right pl-0">
                          <a title="Copy to clipboard" (click)="copy(contactByIdData)" class="d-block"><i
                              class="fa fa-clipboard"></i></a>
                          <label class="copiedMsg">{{message}}</label>

                        </div>
                        <div class="col-12 text-right">
                          <div class="locatToLink">
                            <a href="{{gMapUrl}}" target="_blank">Locate to Map</a>
                          </div>
                        </div>



                      </div>


                    </div>

                  </div>

                  <div class="row mb-1" *ngIf="contactByIdData.customer_type!='' && eventFlag==true && inputdata.page!=='myteam'">
                    <div class="col-xl-5 col-md-3 font-weight-bold">Customer Type</div>
                    <div class="col-xl-7 col-md-9">{{contactByIdData.customer_type}}</div>
                  </div>

                  <div class="row mb-1"
                    *ngIf="contactByIdData.contact_birthdate && contactByIdData.contact_birthdate.trim() && contactByIdData.contact_birthdate!='january 1'">
                    <div class="col-xl-5 col-md-3 font-weight-bold">Birth Date</div>
                    <div class="col-xl-7 col-md-9">{{contactByIdData.contact_birthdate| date:'MMM d, y'}}</div>
                  </div>
                  <!-- <div class="row  mb-1" *ngIf="contactByIdData.contact_age_range">
                    <div class="col-12 col-md-3 col-sm-4 font-weight-bold">Age Range</div>
                    <div class="col-12 col-md-9 col-sm-8">{{contactByIdData.contact_age_range }}</div>
                  </div> -->
                  <div class="row mb-1" *ngIf="contactByIdData.contact_sex">
                    <div class="col-xl-5 col-md-3 font-weight-bold">Gender:</div>
                    <div class="col-xl-7 col-md-9">{{contactByIdData.contact_sex}}</div>
                  </div>
                  <div class="row  mb-1" *ngIf="contactByIdData.loyalty_type_id">
                    <div class="col-xl-5 col-md-3 font-weight-bold">Loyality Type:</div>
                    <div class="col-xl-7 col-md-9">{{contactByIdData.loyalty_type_id }}</div>
                  </div>
                  <!-- <div class="row mb-1" *ngIf="contactByIdData.contact_estimated_household_income && contactByIdData.contact_estimated_household_income!=0.00 && contactByIdData.contact_estimated_household_income!=0">
                    <div class="col-12 col-md-3 col-sm-4 font-weight-bold">Estimated Household Income</div>
                    <div class="col-12 col-md-9 col-sm-8" >{{contactByIdData.contact_estimated_household_income}}</div>
                  </div>
                  <div class="row  mb-1" *ngIf="contactByIdData.contact_marital_status">
                    <div class="col-12 col-md-3 col-sm-4 font-weight-bold">Marital Status</div>
                    <div class="col-12 col-md-9 col-sm-8">{{contactByIdData.contact_marital_status }}</div>
                  </div>
                  <div class="row mb-1" *ngIf="contactByIdData.contact_profession">
                    <div class="col-12 col-md-3 col-sm-4 font-weight-bold">Profession</div>
                    <div class="col-12 col-md-9 col-sm-8" >{{contactByIdData.contact_profession}}</div>
                  </div>
                  <div class="row  mb-1" *ngIf="contactByIdData.personal_notes">
                    <div class="col-12 col-md-3 col-sm-4 font-weight-bold">Personal Notes</div>
                    <div class="col-12 col-md-9 col-sm-8">{{contactByIdData.personal_notes }}</div>
                  </div>-->



                  <div class="accrdContact" *ngIf="contactByIdData.facebook_id || contactByIdData.twitter_id || contactByIdData.instagram_id || contactByIdData.linkedin_id || contactByIdData.youtube_id
                                                   || contactByIdData.whatsapp_id || contactByIdData.pinterest_id">

                    <span class="scilActBtn" [ngClass]="!isOpen('1')? '' : 'arrowFlip'"
                      (click)="acc.toggle('ngb-panel-0')">Social Accounts</span>

                  </div>
                  <div class="accrdMainContact one">
                    <ngb-accordion [closeOthers]="true" #acc="ngbAccordion">
                      <ngb-panel id="ngb-panel-0">
                        <ng-template ngbPanelContent>
                          <div class="row grey-bg pb-1">
                            <div class="col-md-6" *ngIf="contactByIdData && contactByIdData.facebook_id">
                              <div class="row1 mt-1">
                                <div class="float-left pr-1"><i
                                    class="fa fa-facebook-square txt-color-fb socialIconSize" aria-hidden="true"></i>
                                </div>
                                <div class="socPad float-left" *ngIf="contactByIdData">
                                  <a href="{{contactByIdData.facebook_id }}"
                                    target="_blank">{{contactByIdData.facebook_id }}</a>
                                </div>
                              </div>

                            </div>

                            <div class="col-md-6" *ngIf="contactByIdData && contactByIdData.facebook_id">
                              <div class="row1  mt-1">
                                <div class="float-left pr-1"><i class="fa fa-facebook-messenger" aria-hidden="true"></i>
                                </div>
                                <div class="socPad float-left" *ngIf="contactByIdData">
                                  <a (click)="getFBMessenger(contactByIdData.facebook_id )" target="_blank"
                                    title="Facebook Messenger">FB Personal Messenger</a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.twitter_id">
                              <div class="row1  mt-1">
                                <div class="float-left pr-1"><i
                                    class="fa fa-twitter-square txt-color-twt socialIconSize" aria-hidden="true"></i>
                                </div>
                                <div class="socPad float-left" *ngIf="contactByIdData">
                                  <a href="{{contactByIdData.twitter_id }}" target="_blank">{{contactByIdData.twitter_id
                                    }}</a>
                                </div>
                              </div>

                            </div>

                            <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.instagram_id">
                              <div class="row1  mt-1">
                                <div class="float-left pr-1"><i class="fa fa-instagram txt-color-insta socialIconSize"
                                    aria-hidden="true"></i></div>
                                <div class="socPad float-left" *ngIf="contactByIdData">
                                  <a href="{{contactByIdData.instagram_id }}/"
                                    target="_blank">{{contactByIdData.instagram_id }}</a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.linkedin_id">
                              <div class="row1  mt-1">
                                <div class="float-left pr-1"><i
                                    class="fa fa-linkedin-square txt-color-twt socialIconSize" aria-hidden="true"></i>
                                </div>
                                <div class="socPad float-left" *ngIf="contactByIdData">
                                  <a href="{{contactByIdData.linkedin_id }}/"
                                    target="_blank">{{contactByIdData.linkedin_id }}</a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.youtube_id">
                              <div class="row1 mt-1">
                                <div class="float-left pr-1"><i class="fa fa-youtube txt-color-youtube socialIconSize"
                                    aria-hidden="true"></i></div>
                                <div class="socPad float-left"> <a href="{{contactByIdData.youtube_id}}/"
                                    target="_blank">{{contactByIdData.youtube_id}}</a></div>
                              </div>
                            </div>
                            <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.whatsapp_id">
                              <div class="row1 mt-1">
                                <div class="float-left pr-1"><i class="fa fa-whatsapp txt-color-whatsaap socialIconSize"
                                    aria-hidden="true"></i></div>
                                <div class="socPad float-left">
                                  <!-- <a href="https://www.whatsapp.com/{{contactByIdData.whatsapp_id}}/"
                                    target="_blank">{{contactByIdData.whatsapp_id}}</a> -->
                                  <a href="https://web.whatsapp.com/"
                                    target="_blank">{{contactByIdData.whatsapp_id}}</a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.pinterest_id">

                              <div class="row1 mt-1">


                                <div class="float-left pr-1"><i
                                    class="fa fa-pinterest-p txt-bg-color-pint socialIconSize" aria-hidden="true"></i>
                                </div>
                                <div class="socPad float-left">
                                  <a href="{{contactByIdData.pinterest_id}}"
                                    target="_blank">{{contactByIdData.pinterest_id}}</a>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.google_my_biz_id">
                              <div class="row1 mt-1">
                                <div class="float-left pr-1"><i
                                    class="fa fa-google-plus red-ggl-icon txt-bg-color-pint socialIconSize"
                                    aria-hidden="true"></i>
                                </div>
                                <div class="socPad float-left">
                                  <a href="https://www.google.com/business/"
                                    target="_blank">{{contactByIdData.google_my_biz_id}}</a>
                                </div>
                              </div>
                            </div>

                          </div>
                        </ng-template>
                      </ngb-panel>
                    </ngb-accordion>
                  </div>


                  <!-- <div class="row grey-bg pb-1 m-0 mt-2 ">
                     <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.instagram_biz_id">
                      <div class="row  mt-1">
                        <div class="col-2"><i class="fa fa-instagram txt-color-insta socialIconSize"
                           aria-hidden="true"></i></div>
                       <div class="col-10">
                         <a href="https://business.instagram.com//in/{{contactByIdData.instagram_biz_id }}/"
                         target="_blank">{{contactByIdData.instagram_biz_id}}</a></div>
                     </div>
                    </div>
                    <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.google_my_biz_id">
                      <div class="row  mt-1">
                        <div class="col-2"><i class="fa fa-google txt-color-google socialIconSize red-yt-icon"
                            aria-hidden="true"></i></div>
                        <div class="col-10">
                          <a href="https://www.google.com/intl/en_in/business/{{contactByIdData.google_my_biz_id }}/"
                            target="_blank">{{contactByIdData.google_my_biz_id}}</a></div>
                      </div>
                    </div>
                    <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.sina_wiebo_id">
                      <div class="row  mt-1">
                        <div class="col-2"><i class="fa fa-snapchat-ghost txt-color-sinawiebo socialIconSize"
                           aria-hidden="true"></i></div>
                       <div class="col-10">
                         <a href="https://www.weibo.com/{{contactByIdData.sina_wiebo_id }}/"
                         target="_blank">{{contactByIdData.sina_wiebo_id}} </a></div>
                     </div>
                    </div>
                    <div class="col-md-6" *ngIf=" contactByIdData  && contactByIdData.we_chat_id">
                      <div class="row  mt-1">
                        <div class="col-2"><i class="fa fa-wechat txt-color-wechat socialIconSize"
                           aria-hidden="true"></i></div>
                       <div class="col-10">
                         <a href="https://www.wechat.com/en{{contactByIdData.we_chat_id }}/"
                         target="_blank">{{contactByIdData.we_chat_id}}</a></div>
                     </div>
                    </div>
                  </div> -->

                  <div class="row card-footer-trans p-0"
                    *ngIf="inputdata.name!=='teammember' && inputdata.page!=='socialhost' ">

                    <div class="col-md-12 mt-1 mb-2">
                      <!-- <label class="custRadioLeft float-md-left mr-tp-5">Agree to Host
                        <input type="radio" name="group"  [value]="true" [(ngModel)]="agreeToHost"  [disabled]="agreeToHostDisabled"
                          >
                        <span class="radiomarkLeft"></span>
                      </label>
                      <label class="custRadioLeft float-md-left ml-0 ml-md-1 mr-1 mr-tp-5">Agree to Join
                        <input type="radio" name="group"  [value]="false" [(ngModel)]="agreeToHost"  [disabled]="agreeToHostDisabled">
                        <span class="radiomarkLeft"></span>
                      </label> -->
                      <label bindCssVariable="tabColor" [bindCssVariableValue]="tabColor"
                        class="custmCheck float-md-left mr-tp-5" title="{{tooltipAgreeToHost}}"
                        (click)="CheckMessage($event)">Agreed to Host
                        <input type="checkbox" name="group" [(ngModel)]="agreeToHost" [checked]="agreeToHost"
                          [disabled]="agreeToHostDisabled">
                        <span class="checkmark"></span>
                      </label>
                      <label class="custmCheck float-md-left ml-0 ml-md-1 mr-1 mr-tp-5">Agreed to Join
                        <input type="checkbox" name="group" [(ngModel)]="agreeToJoin" [checked]="agreeToJoin">
                        <span class="checkmark"></span>
                      </label>
                      <button (click)="saveselection()" class="btn btn-margo-cancel btn-round mt-1 mt-md-0"
                        style="margin-top: 0 !important;">
                        <span>
                          Save Selection
                        </span>
                      </button>
                    </div>
                    <small class="col-12 red-sw-icon" *ngIf="isMessageDefault">{{tooltipAgreeToHost}}</small>
                    <div class="col-md-12 mb-1" *ngIf="latestOptinReuest">
                      {{latestOptinReuest.log_type }} {{ dateTimeService.insertDateAt(latestOptinReuest.createdon | date:'MMM d, y AT h:mm')}} {{
                        latestOptinReuest.createdon | date : 'a' | lowercase }}
                      <a href="javascript:void(0)" (click)="viewMoreRequests(viewMoreRequestsBox)">View More</a>


                      <ng-template #viewMoreRequestsBox let-modal>
                        <div class="wholePop">
                          <div class="modal-header dialHead w-100">
                            <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" />
                            <button type="button" class="close" aria-label="Close"
                              (click)="modal.dismiss('Cross click')">
                              <span aria-hidden="true">&times;</span>
                            </button>

                          </div>

                          <div class="modal-body">
                            <h5 class="mb-1 text-center">ALL OPT IN / OUT REQUESTS</h5>
                            <div class="pt-1">
                              <table *ngIf="allOptinRequests  && allOptinRequests.length>0"
                                class="table table-striped table-borderless" width="100%">
                                <tr>
                                  <th>OPT TYPE</th>
                                  <th>OPT DATE</th>
                                </tr>
                                <tr *ngFor="let result of allOptinRequests; let i = index">
                                  <td>{{result.log_type}} </td>
                                  <td>
                                    {{ dateTimeService.insertDateAt(result.createdon | date:'MMM d, y AT h:mm')}} {{
                                      result.createdon | date : 'a' | lowercase }}
                                  </td>
                                </tr>
                              </table>

                            </div>

                          </div>
                          <div class="modal-footer text-center d-block border-0">
                            <button type="button" class="btn btn-margo-action btn-round"
                              (click)="modal.dismiss('Cross click')">OK</button>
                          </div>
                        </div>
                      </ng-template>


                    </div>
                  </div>
                </div>
              </div>

              <!-- <a href="javascript:void(0)" *ngIf="backurl" routerLink="{{backurl}}" class="btn btn-round btn-margo-action mrgSml-5 backButtonRight">Back</a> -->
              <div class="card-footer text-center pb-0" *ngIf="inputdata.page!=='myteam' ">

                <div class="col-md-12-1" [ngClass]="status ? 'displayblock' : 'displaynone'">

                  <!-- <a href="#" class="btn btn-margo-cancel btn-round mt-1">Add a Co-Host</a>
                  <button *ngIf="!optinStatus && inputdata.name!=='teammember' && inputdata.page!=='socialhost'" class="btn btn-round btn-margo-action"
                  (click)="viewAgreement()">Opt In Agreement</button>
                  <button *ngIf="optinStatus && inputdata.name!=='teammember' && inputdata.page!=='socialhost'" class="btn btn-round btn-margo-action"
                  (click)="optoutAgreement()">Opt Out Agreement</button>
                  -->
                  <button *ngIf="inputdata.name!=='teammember' && inputdata.page!=='socialhost'"
                    class="btn btn-round btn-margo-action mrgSml-5" (click)="viewAgreement(confirmBox)"
                    bindCssVariable="buttonColor" [bindCssVariableValue]="buttonColor"> <span
                      bindCssVariable="buttonTextColor" [bindCssVariableValue]="buttonTextColor">
                      Opt-In
                      Agreement </span></button>

                  <span>
                    <a *ngIf="(this.userData.rep_id !=undefined && this.userData.rep_id !=null) && (this.contactByIdData.bu_customer_id !=undefined && this.contactByIdData.bu_customer_id !=null)"
                      target="_blank" class="btn btn-margo-action btn-round" (click)="navigateByDesignDashboard()">
                      ByDesign Dashboard</a>
                    <a *ngIf="(this.userData.rep_id !=undefined && this.userData.rep_id !=null) && (this.contactByIdData.bu_customer_id !=undefined && this.contactByIdData.bu_customer_id !=null)"
                      target="_blank" class="btn btn-margo-action btn-round"
                      (click)="navigateWinecellar()">Winecellar</a>
                  </span>

                  <a href="javascript:void(0)" routerLink="{{inputdata.navurl}}"
                    class="btn btn-round btn-margo-action mrgSml-5"
                    *ngIf="((contactByIdData && (inputdata.page==='quicktext')))">Select</a>

                </div>
              </div>

              <!--  Contact Information End  -->


            </mat-tab>
            <mat-tab label="Wish List">

              <app-contact-wishlist [contactId]="inputdata.contactid"></app-contact-wishlist>
            </mat-tab>
            <mat-tab label="Attributes">

              <!-- <h4 class="font-weight-bold mb-1 subTitleHead_3 mt-2">Attributes:</h4>
              <p *ngIf="contactAttributes.length === 0">No result available.</p>
              <button class="btn btn-margo-cancel btn-round attributeBtn ml-0" *ngFor="let attr of contactAttributes" (click)="deleteAttribute(attr.id)"><span class="attrbtnTxt">{{attr.attribute_name}}</span> <span class="attrClsBtn"> x</span></button>
              <h4 class="font-weight-bold mb-1 subTitleHead_3 mt-1">Find and select customer attributes</h4>
              <div class="row mr-0">
                <div class="col-md-6">
                  <input class="form-control" [formControl]="queryContactField" type="text" placeholder="Enter an attribute">
                </div>
              </div>
              <h4 class="font-weight-bold mb-1 subTitleHead_3 mt-2">Attributes</h4>
              <div class="card bg-color-f9" >
                <div class="card-body">
                  <div class="scroller">
                    <div class="filtMain"
                      infiniteScroll
                      [infiniteScrollDistance]="2"
                      [infiniteScrollThrottle]="50"
                      (scrolled)="onScrolled()"
                      [scrollWindow]="false">
                      <ul class="attrSearchList" *ngIf="listOfAttributes.length > 0">
                        <li>
                           <span class="titleHeadAttr">M</span>
                           <a href="#"><span class="attrSubHead">Married</span></a>
                           <a href="#"><span class="attrSubHead">Men's Health</span></a>
                         </li>
                         <li>
                           <span class="titleHeadAttr">N</span>
                           <a href="#"><span class="attrSubHead">Natural Products</span></a>
                           <a href="#"><span class="attrSubHead">Nutrition</span></a>
                         </li> -->


              <!-- <li *ngFor="let attr of listOfAttributes">
                          <span class="titleHeadAttr">{{attr.keyName}}</span>
                          <a  *ngFor="let val of attr.values" (click)="addContactAttribute(val.attribute_id)"><span class="attrSubHead" >{{val.attribute_name}}</span></a>
                       </li> -->

              <!--   <li *ngFor="let attr of listOfAttributes">
                          <a  (click)="addContactAttribute(attr.attribute_id)"><span class="attrSubHead" >{{attr.attribute_name}}</span></a>
                        </li>
                      </ul>
                      <p *ngIf="listOfAttributes.length === 0">No Result Available.</p>
                    </div>
                  </div>
                </div>
              </div> -->



              <app-contact-attribute></app-contact-attribute>


            </mat-tab>

            <mat-tab label="Notes" *ngIf="notesVisibility">
              <app-contact-notes [contactId]="inputdata.contactid"></app-contact-notes>

            </mat-tab>

            <mat-tab label="Meta Data" *ngIf="metaVisibility">
              <app-contact-metadata [metadata]="metafields"></app-contact-metadata>

            </mat-tab>


            <mat-tab label="Instant Message" *ngIf="(contactByIdData.contact_phone && contactByIdData.contact_country) || (contactByIdData.username)">

              <!-- <div class="row3 mt-1">
                <div class="col-12 instanttab_body" *ngIf="optStatus.optSms==false">
                  Contact is optout from SMS
                </div>
              </div> -->
            </mat-tab>


            <!-- <div class="col-6" *ngIf="showContact" [ngClass]="{'disabled': !optStatus.optSms || contactByIdData?.sms_consent === 'Rejected'}">
              <div class="tileAct" >
                <div class="butnInn" (click)="optStatus.optSms && contactByIdData?.sms_consent !== 'Rejected' && goToInstantMessage()">
                  <img src="assets/custom/images/instant-message.png">
                  <span class="tileActTitle">Instant Message1</span>
                </div>
              </div>
            </div> -->


          </mat-tab-group>

          <!--  Tab End   -->

        </div>
      </div>

    </div>
    <div class="col-xl-5 mrg-border-left">
      <section class="showWb">
        <!-- <div class="d-none d-sm-block">
          <div></div>
          <h5 class="">Map</h5>
          <div class="mapState mt-1" *ngIf="!mapURL.includes('addressNotFound')"><img src="{{mapURL}}">
          </div>
          <div class="mt-1" *ngIf="mapURL.includes('addressNotFound')">
            <div class="alert alert-light" role="alert">
              <i class="fa fa-info"></i> &nbsp; &nbsp;Add home address to view Google Map for Contact.
            </div>
          </div>
        </div> -->
        <div class="card1">
          <div class="card-body1">
            <div class="row">
              <div class="col-12 text-center"
                *ngIf="!hostTypeStatus && contactByIdData  && inputdata.page!=='quicktext'">
                <h5 class="font-weight-bold mb-1 text-left" *ngIf="showContact || showTeamMember">Actions</h5>
                <div class="tilesInnMain mcb-prst" *ngIf="inputdata.name!=='teammember' && !showHostJourney">
                  <div class="row">
                    <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 py-1 px-1">
                      <div class="tileAct" *ngIf="showContact">
                        <div *ngIf="!isIndependenceDs" class="butnInn" (click)="selectedShop(70,'shop','',optStatus, confirmBox)">
                          <img src="assets/custom/images/shopIcon.png">
                          <span class="tileActTitle">Shop</span>
                        </div>
                        <div *ngIf="isIndependenceDs" class="butnInn" (click)="checkProductQty(confirmMessageBox)">
                          <img src="assets/custom/images/shopIcon.png">
                          <span class="tileActTitle">Shop</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 py-1 px-1">
                      <div class="tileAct" *ngIf="showTeamMember">
                        <div class="butnInn" (click)="selectedShop(68,'host','',optStatus, confirmBox)">

                          <img src="assets/custom/images/hostIcon2.png">
                          <span class="tileActTitle">Host</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 py-1 px-1">
                      <div class="tileAct" *ngIf="showContact">
                        <div class="butnInn" (click)="selectedShop(67,'join', '',optStatus, confirmBox)">
                          <img src="assets/custom/images/joinIcon.png">
                          <span class="tileActTitle">Join</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 py-1 px-1">
                      <div class="tileAct" *ngIf="showTeamMember">
                        <div class="butnInn" (click)="selectmarketoption(4,'host','Host & Guest')">
                          <img src="assets/custom/images/eventIconNew.png">
                          <span class="tileActTitle">Events</span>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-6" *ngIf="showContact" [ngClass]="{'disabled': !optStatus.optSms || contactByIdData?.sms_consent === 'Rejected'}">
                      <div class="tileAct" >
                        <div class="butnInn" (click)="optStatus.optSms && contactByIdData?.sms_consent !== 'Rejected' && goToInstantMessage()">
                          <img src="assets/custom/images/instant-message.png">
                          <span class="tileActTitle">Instant Message</span>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>


                <div class="theme-fa-icon mcb-prst"
                  *ngIf="inputdata.name==='teammember' && showTeamMember && showContact && isBU">

                   <!-- <div class="row">
                 <div class="col-md-12">
                   <h4 class="font-weight-bold" *ngIf="contactByIdData">What do you want to offer them?</h4>
                 </div>
               </div> -->

                  <div class="row">

                    <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 py-1 text-center">
                      <div class="tileAct">
                        <div class="butnInn" (click)="selectedShop(124,'encouragement')">
                          <img src="assets/custom/images/encouragIcon.png">
                          <span class="tileActTitle">Encouragement</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 py-1 text-center">
                      <div class="tileAct">
                        <div class="butnInn" (click)="selectedShop(125,'training',true)">
                          <img src="assets/custom/images/trainingIcon.png">
                          <span class="tileActTitle">Training</span>
                        </div>
                      </div>
                    </div>


                  </div>

                </div>

                <div class="theme-fa-icon mcb-prst" *ngIf="showHostJourney  && showTeamMember && showContact">

                  <!--  <div class="row">
                 <div class="col-md-12">
                   <h4 class="font-weight-bold">Will the event be In-Person Or Virtual?</h4>
                 </div>
               </div> -->

                  <div class="row">

                    <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 py-1">

                      <div class="tileAct card mb-0">
                        <div class="butnInn" (click)="addCoHostModal(confirmBoxCoHost, 127,'In-Person',true)">
                          <img src="assets/custom/images/inPersonIcon.png">
                          <span class="tileActTitle">In-Person</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 py-1">

                      <div class="tileAct card mb-0">
                        <div class="butnInn" (click)="addCoHostModal(confirmBoxCoHost, 128,'Virtual',true)">
                          <img src="assets/custom/images/onboardingIconNew.png">
                          <span class="tileActTitle">Virtual</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 py-1">

                      <div class="tileAct card mb-0">
                        <div class="butnInn" (click)="addCoHostModal(confirmBoxCoHost, 129,'In-Person & Virtual',true)">
                          <img src="assets/custom/images/personIconVirtual.png">
                          <span class="tileActTitle">In-Person & Virtual</span>
                        </div>
                      </div>
                    </div>

                  </div>

                  <!-- <div class="row">
                <div class="col-xl-10">
                  <button class="cbtn mt-1" (click)="addCoHostModal(confirmBoxCoHost, 127,'In-Person',true)" class="top cbtn"><i
                    class="fa fa-address-card-o red-dr-icon" aria-hidden="true"></i>In-Person</button>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-10">
                  <button class="cbtn mt-1" (click)="addCoHostModal(confirmBoxCoHost, 128,'Virtual',true)" class="top cbtn"><i
                    class="fa fa-tv orange-dr-icon" aria-hidden="true"></i>Virtual</button>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-10">
                  <button class="cbtn mt-1" (click)="addCoHostModal(confirmBoxCoHost, 129,'In-Person & Virtual',true)" class="top cbtn"><i
                    class="fa fa-object-group blue-lt-icon" aria-hidden="true"></i>In-Person & Virtual</button>
                </div>
              </div> -->
                </div>



              </div>
            </div>
          </div>
        </div>

        <!-- <div class="d-block d-sm-none">
          <div></div>
          <h5 class="mt-2">Map</h5>
          <div class="mapState mt-1 mb-md-0 mb-2" *ngIf="!mapURL.includes('addressNotFound')"><img src="{{mapURL}}">
          </div>
          <div class="mt-1" *ngIf="mapURL.includes('addressNotFound')">
            <div class="alert alert-light" role="alert">
              <i class="fa fa-info"></i> &nbsp; &nbsp;Add home address to view Google Map for Contact.
            </div>
          </div>
          <div></div>
        </div> -->
      </section>
    </div>

  </div>
  <ng-template #confirmBox let-modal>
    <div class="wholePop">
      <div class="modal-header dialHead w-100">
        <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" />
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>


      </div>

      <div class="modal-body text-center">
        <h5 class="mb-1 text-center">OPT-IN AGREEMENT</h5>
        <ng-container>
          <div>
            YOU AGREE THAT THIS CONTACT HAS GIVEN CONSENT TO RECEIVE COMMUNICATION
          </div>
          <div class="text-left mt-1"><input type="checkbox" [checked]="emailCheck" (change)="checkedEmail($event)">
            AGREED TO RECEIVE EMAILS</div>
          <div class="text-left mt-1"><input type="checkbox" [checked]="smsCheck" [disabled]="disablePhone"
              (change)="smsChecked($event)"> AGREED TO RECEIVE SMS/MMS</div>
          <p class="mt-2"><a target="_blank" [routerLink]="[inputdata.optinurl]" class="card-link"><i
                class="fa fa-file-text blue-dr-icon mrgRgihtHlf" aria-hidden="true"></i> VIEW {{projectName}}
              AGREEMENT</a>
          </p>
          <!-- <div *ngIf="!smsCheck && !emailCheck" class="text-left invalid">You need to select atleast one option otherwise not able to save contact</div> -->
        </ng-container>
      </div>

      <div class="modal-footer text-center d-block border-0 pt-0">
        <button type="button" class="btn btn-margo-action btn-round" (click)="agree()">AGREE</button>
      </div>
    </div>
  </ng-template>

  <ng-template #confirmMessageBox let-modal>
    <div class="wholePop filtMain">
      <div class="modal-header dialHead w-100">
        <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" />
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body text-center">
        <ng-container>
          <div>
            There is no products in your catalogue please add product.
          </div>
        </ng-container>
      </div>

      <div class="modal-footer text-center d-block border-0 pt-0">
        <button type="button" class="btn btn-margo-action btn-round" (click)="navigateProductPage()">Add
          Product</button>
      </div>
    </div>
  </ng-template>

  <ng-template #confirmBoxCoHost let-modal>
    <div class="scroller">
      <div class="wholePop filtMain">
        <div class="modal-header dialHead w-100">
          <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" />
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(0)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body text-center">
          <ng-container>
            <div>
              WOULD YOU LIKE TO ADD CO-HOST?
            </div>
          </ng-container>
        </div>
        <div class="modal-footer text-center d-block border-0 pt-0">
          <button type="button" class="btn btn-margo-action btn-round text-uppercase"
            (click)="modal.close('Yes')">Yes</button>
          <button type="button" class="btn btn-margo-cancel btn-round text-uppercase"
            (click)="modal.close('No')">No</button>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<!-- Contact Card End for Contact  -->

<div class="container" *ngIf="contactByIdData && contacttypeid === 1">
  <!--   <div class="row">
       <div class="col"><h4 class="pb-0 pb-sm-1 float-sm-left mainHeading_1">Contact Information:</h4><div class="mt-1 mb-1 mt-md-0 mb-md-0 float-sm-right"><a href="javascript:void(0)" (click)="addToBookmark();" class=""><i class="fa fa-bookmark" aria-hidden="true"></i> Bookmark</a></div></div>
      </div> -->

  <div class="row">

    <div class="col-md-7">

      <div class="card">
        <div class="card-body">
          <div class="">

            <h5 class="font-weight-bold mb-0 subTitleHead_2" *ngIf="contactByIdData"><span
                class="float-left pr-1">{{contactByIdData.contact_fname | titlecase}}
                {{contactByIdData.contact_lname | titlecase}}</span>


            </h5>
          </div>

          <div class="col-12 text-right">
            <div class="d-inline-block bookMMain">




              <a href="javascript:void(0)" (click)="addToBookmark();" class="" *ngIf="!isbookmarked">
                <img title="Add To Favorite" alt="Add To Favorite" src="assets/custom/images/favritIcon.png">
              </a>
              <a href="javascript:void(0)" (click)="addToBookmark();" class="" *ngIf="isbookmarked">


                <img title="Contact is Favorited" alt="Favorited" src="assets/custom/images/favritIcon2.jpg">

              </a>




            </div>



          </div>

          <div class="pb-1 clearfix" *ngIf="contacttypename.trim()=='Host & Guest'">Customer / Prospect</div>
          <div class="pb-1 clearfix" *ngIf="contacttypename.trim()!=='Host & Guest'">{{contacttypename}}
            {{agreeToHost ? '/ Host' : ''}}</div>

          <div class="" *ngIf="inputdata.name!=='teammember' && inputdata.page!=='socialhost'"><a href="#"
              routerLink="/directseller/mycontact/report">Activity History</a></div>
          <div class="" *ngIf="inputdata.name==='teammember'"><a href="#"
              routerLink="/directseller/myteam/report">Activity History</a></div>
          <!-- <h6 class="font-weight-bold pb-1" *ngselectedShopf = "contacttypeid ===3 ">PROSPECT</h6> -->

          <!-- Contact Information Start -->

          <div class="row mt-1">
            <div class="col-12">
              <div class="row mb-1" *ngIf="contactByIdData.contact_email">
                <div class="col-xl-4 col-md-3 font-weight-bold">Email:</div>
                <div class="col-xl-8 col-md-9">{{contactByIdData.contact_email}}</div>
              </div>
              <div class="row  mb-1" *ngIf="contactByIdData.contact_phone">
                <div class="col-xl-4 col-md-3 font-weight-bold">Primary Contact:</div>
                <div class="col-xl-8 col-md-9 ">
                  <!-- <div *ngIf="contactByIdData.contact_country && phonecode">+ <b>{{phonecode}}</b></div>  -->
                  {{contactByIdData.contact_phone | newformatPhoneNumber:phonecode}}
                </div>
              </div>
              <div class="row mb-1" *ngIf="contactByIdData.contact_phone_type">
                <div class="col-xl-4 col-md-3 font-weight-bold">Contact Type: </div>
                <div class="col-xl-8 col-md-9">{{contactByIdData.contact_phone_type==1?"Landline":"Mobile"}}</div>
              </div>
              <div class="row mb-1" *ngIf="contactByIdData.contact_phone_other">
                <div class="col-xl-4 col-md-3 font-weight-bold">Secondary Contact:</div>
                <div class="col-xl-8 col-md-9">{{contactByIdData.contact_phone_other}}</div>
              </div>

              <div class="row  mb-1"
                *ngIf="(contactByIdData.contact_address|| contactByIdData.contact_address_2 || contactByIdData.contact_country||contactByIdData.contact_city || contactByIdData.contact_state)">
                <div class="col-xl-4 col-md-3 font-weight-bold">Address:</div>
                <div class="col-xl-8 col-md-9" *ngIf="contactByIdData">
                  <div class="row">
                    <div class="col-9 col-sm-6  col-xl-7">
                      <p *ngIf="contactByIdData && contactByIdData.contact_address" class="mb-0">
                        {{contactByIdData.contact_address}} </p>
                      <p *ngIf="contactByIdData && contactByIdData.contact_address_2" class="mb-0">
                        {{contactByIdData.contact_address_2}} </p>
                      <p *ngIf="contactByIdData && contactByIdData.contact_city" class="mb-0">
                        {{contactByIdData.contact_city | titlecase}}</p>
                      <p *ngIf="contactByIdData && contactByIdData.contact_state" class="mb-0">
                        {{contactByIdData.contact_state}}</p>
                      <p *ngIf="contactByIdData && contactByIdData.contact_country && contactByIdData.contact_zip"
                        class="mb-0">{{contactByIdData.contact_country}} - {{contactByIdData.contact_zip}}</p>
                      <p *ngIf="contactByIdData && contactByIdData.contact_country && !contactByIdData.contact_zip"
                        class="mb-0">{{contactByIdData.contact_country}}</p>
                    </div>
                    <div class="col-xl-5 col-sm-6 col-3 text-right pl-0">
                      <a title="Copy to clipboard" (click)="copy(contactByIdData)" class="d-block"><i
                          class="fa fa-clipboard"></i></a>
                      <label class="copiedMsg">{{message}}</label>



                      <div class="locatToLink mb-1">
                        <a href="{{gMapUrl}}" target="_blank">Locate to Map</a>

                      </div>

                    </div>

                  </div>


                </div>

              </div>

              <div class="row mb-1" *ngIf="contactByIdData.customer_type!='' && eventFlag==true && inputdata.page!=='myteam'" >
                <div class="col-xl-4 col-md-3 font-weight-bold">Customer Type</div>
                <div class="col-xl-8 col-md-9">{{contactByIdData.customer_type}}</div>
              </div>

              <div class="row mb-1"
                *ngIf="contactByIdData.contact_birthdate && contactByIdData.contact_birthdate.trim() && contactByIdData.contact_birthdate!='january 1'">
                <div class="col-xl-4 col-md-3 font-weight-bold">Birth Date</div>
                <div class="col-xl-8 col-md-9">{{contactByIdData.contact_birthdate| date:'MMM d, y'}}</div>
              </div>
              <!-- <div class="row  mb-1" *ngIf="contactByIdData.contact_age_range">
                <div class="col-12 col-md-3 col-sm-4 font-weight-bold">Age Range</div>
                <div class="col-12 col-md-9 col-sm-8">{{contactByIdData.contact_age_range }}</div>
              </div> -->
              <div class="row mb-1" *ngIf="contactByIdData.contact_sex">
                <div class="col-xl-4 col-md-3 font-weight-bold">Gender:</div>
                <div class="col-xl-8 col-md-9">{{contactByIdData.contact_sex}}</div>
              </div>
              <div class="row  mb-1" *ngIf="contactByIdData.loyalty_type_id">
                <div class="col-xl-4 col-md-3 font-weight-bold">Loyality Type:</div>
                <div class="col-xl-8 col-md-9">{{contactByIdData.loyalty_type_id }}</div>
              </div>



              <div class="accrdContact" *ngIf="contactByIdData.facebook_id || contactByIdData.twitter_id || contactByIdData.instagram_id || contactByIdData.linkedin_id || contactByIdData.youtube_id
                                               || contactByIdData.whatsapp_id || contactByIdData.pinterest_id">

                <span class="scilActBtn" [ngClass]="!isOpen('1')? '' : 'arrowFlip'"
                  (click)="acc.toggle('ngb-panel-1')">Social
                  Accounts</span>
              </div>
              <div class="accrdMainContact" *ngIf="contactByIdData">
                <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" activeIds="ngb-panel-1">
                  <ngb-panel id="ngb-panel-1">
                    <ng-template ngbPanelContent>
                      <div class="row grey-bg pb-1">
                        <div class="col-md-6" *ngIf="contactByIdData && contactByIdData.facebook_id">
                          <div class="row1 mt-1">
                            <div class="float-left pr-1"><i class="fa fa-facebook-square txt-color-fb socialIconSize"
                                aria-hidden="true"></i></div>
                            <div class="socPad float-left" *ngIf="contactByIdData">
                              <a href="{{contactByIdData.facebook_id }}" target="_blank">{{contactByIdData.facebook_id
                                }}</a>
                            </div>
                          </div>

                        </div>

                        <div class="col-md-6" *ngIf="contactByIdData && contactByIdData.facebook_id">
                          <div class="row1  mt-1">
                            <div class="float-left pr-1"><i class="fa fa-facebook-messenger" aria-hidden="true"></i>
                            </div>
                            <div class="socPad float-left" *ngIf="contactByIdData">
                              <a (click)="getFBMessenger(contactByIdData.facebook_id )" target="_blank"
                                title="Facebook Messenger">FB Personal Messenger</a>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.twitter_id">
                          <div class="row1  mt-1">
                            <div class="float-left pr-1"><i class="fa fa-twitter-square txt-color-twt socialIconSize"
                                aria-hidden="true"></i></div>
                            <div class="socPad float-left" *ngIf="contactByIdData">
                              <a href="{{contactByIdData.twitter_id }}" target="_blank">{{contactByIdData.twitter_id
                                }}</a>
                            </div>
                          </div>

                        </div>

                        <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.instagram_id">
                          <div class="row1  mt-1">
                            <div class="float-left pr-1"><i class="fa fa-instagram txt-color-insta socialIconSize"
                                aria-hidden="true"></i></div>
                            <div class="socPad float-left" *ngIf="contactByIdData">
                              <a href="{{contactByIdData.instagram_id }}/"
                                target="_blank">{{contactByIdData.instagram_id }}</a>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.linkedin_id">
                          <div class="row1  mt-1">
                            <div class="float-left pr-1"><i class="fa fa-linkedin-square txt-color-twt socialIconSize"
                                aria-hidden="true"></i></div>
                            <div class="socPad float-left" *ngIf="contactByIdData">
                              <a href="{{contactByIdData.linkedin_id }}/" target="_blank">{{contactByIdData.linkedin_id
                                }}</a>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.youtube_id">
                          <div class="row1 mt-1">
                            <div class="float-left pr-1"><i class="fa fa-youtube txt-color-youtube socialIconSize"
                                aria-hidden="true"></i></div>
                            <div class="socPad float-left"> <a href="{{contactByIdData.youtube_id}}/"
                                target="_blank">{{contactByIdData.youtube_id}}</a></div>
                          </div>
                        </div>
                        <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.whatsapp_id">
                          <div class="row1 mt-1">
                            <div class="float-left pr-1"><i class="fa fa-whatsapp txt-color-whatsaap socialIconSize"
                                aria-hidden="true"></i></div>
                            <div class="socPad float-left">
                              <!-- <a href="https://www.whatsapp.com/{{contactByIdData.whatsapp_id}}/"
                                target="_blank">{{contactByIdData.whatsapp_id}}</a> -->
                              <a href="https://web.whatsapp.com/" target="_blank">{{contactByIdData.whatsapp_id}}</a>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.pinterest_id">
                          <div class="row1 mt-1">


                            <div class="float-left pr-1"><i class="fa fa-pinterest-p txt-bg-color-pint socialIconSize"
                                aria-hidden="true"></i></div>
                            <div class="socPad float-left">
                              <a href="{{contactByIdData.pinterest_id}}"
                                target="_blank">{{contactByIdData.pinterest_id}}</a>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.google_my_biz_id">
                          <div class="row1 mt-1">
                            <div class="float-left pr-1"><i
                                class="fa fa-google-plus red-ggl-icon txt-bg-color-pint socialIconSize"
                                aria-hidden="true"></i>
                            </div>
                            <div class="socPad float-left">
                              <a href="https://www.google.com/business/"
                                target="_blank">{{contactByIdData.google_my_biz_id}}</a>
                            </div>
                          </div>
                        </div>

                      </div>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </div>

              <!-- <div class="row mb-1" *ngIf="contactByIdData.contact_estimated_household_income && contactByIdData.contact_estimated_household_income!=0.00 && contactByIdData.contact_estimated_household_income!=0">
                <div class="col-12 col-md-3 col-sm-4 font-weight-bold">Estimated Household Income</div>
                <div class="col-12 col-md-9 col-sm-8" >{{contactByIdData.contact_estimated_household_income}}</div>
              </div>
              <div class="row  mb-1" *ngIf="contactByIdData.contact_marital_status">
                <div class="col-12 col-md-3 col-sm-4 font-weight-bold">Marital Status</div>
                <div class="col-12 col-md-9 col-sm-8">{{contactByIdData.contact_marital_status }}</div>
              </div>
              <div class="row mb-1" *ngIf="contactByIdData.contact_profession">
                <div class="col-12 col-md-3 col-sm-4 font-weight-bold">Profession</div>
                <div class="col-12 col-md-9 col-sm-8" >{{contactByIdData.contact_profession}}</div>
              </div>
              <div class="row  mb-1" *ngIf="contactByIdData.personal_notes">
                <div class="col-12 col-md-3 col-sm-4 font-weight-bold">Personal Notes</div>
                <div class="col-12 col-md-9 col-sm-8">{{contactByIdData.personal_notes }}</div>
              </div>-->



              <!--              <div class="row grey-bg pb-1 mt-2">-->
              <!--                <div class="col-md-6" *ngIf="contactByIdData && contactByIdData.facebook_id">-->
              <!--                  <div class="row1 mt-1">-->
              <!--                    <div class="float-left pr-1"><i class="fa fa-facebook-square txt-color-fb socialIconSize"-->
              <!--                                          aria-hidden="true"></i></div>-->
              <!--                    <div class="socPad float-left" *ngIf="contactByIdData">-->
              <!--                      <a href="https://www.facebook.com/{{contactByIdData.facebook_id }}"-->
              <!--                         target="_blank">{{contactByIdData.facebook_id }}</a></div>-->
              <!--                  </div>-->

              <!--                </div>-->

              <!--                <div class="col-md-6" *ngIf="contactByIdData && contactByIdData.facebook_id">-->
              <!--                  <div class="row1  mt-1">-->
              <!--                    <div class="float-left pr-1"><i class="fa fa-facebook-messenger"-->
              <!--                                          aria-hidden="true"></i></div>-->
              <!--                    <div class="socPad float-left" *ngIf="contactByIdData">-->
              <!--                      <a  href="https://www.facebook.com/messages/t/{{contactByIdData.facebook_id }}"-->
              <!--                          target="_blank" title="Facebook Messenger">FB Personal Messenger</a></div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.twitter_id">-->
              <!--                  <div class="row1  mt-1">-->
              <!--                    <div class="float-left pr-1"><i class="fa fa-twitter-square txt-color-twt socialIconSize"-->
              <!--                                          aria-hidden="true"></i></div>-->
              <!--                    <div class="socPad float-left" *ngIf="contactByIdData">-->
              <!--                      <a href="https://twitter.com/{{contactByIdData.twitter_id }}"-->
              <!--                         target="_blank">{{contactByIdData.twitter_id }}</a></div>-->
              <!--                  </div>-->

              <!--                </div>-->

              <!--                <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.instagram_id">-->
              <!--                  <div class="row1  mt-1">-->
              <!--                    <div class="float-left pr-1"><i class="fa fa-instagram txt-color-insta socialIconSize"-->
              <!--                                          aria-hidden="true"></i></div>-->
              <!--                    <div class="socPad float-left" *ngIf="contactByIdData">-->
              <!--                      <a href="https://www.instagram.com/{{contactByIdData.instagram_id }}/"-->
              <!--                         target="_blank">{{contactByIdData.instagram_id }}</a></div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.linkedin_id">-->
              <!--                  <div class="row1  mt-1">-->
              <!--                    <div class="float-left pr-1"><i class="fa fa-linkedin-square txt-color-twt socialIconSize"-->
              <!--                                          aria-hidden="true"></i></div>-->
              <!--                    <div class="socPad float-left" *ngIf="contactByIdData">-->
              <!--                      <a href="https://www.linkedin.com/in/{{contactByIdData.linkedin_id }}/"-->
              <!--                         target="_blank">{{contactByIdData.linkedin_id }}</a></div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.youtube_id">-->
              <!--                  <div class="row1  mt-1">-->
              <!--                    <div class="float-left pr-1"><i class="fa fa-youtube txt-color-youtube socialIconSize"-->
              <!--                                          aria-hidden="true"></i></div>-->
              <!--                    <div class="socPad float-left" > <a href="https://www.youtube.com/channel/{{contactByIdData.youtube_id}}/"-->
              <!--                                             target="_blank">{{contactByIdData.youtube_id}}</a></div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.whatsapp_id">-->
              <!--                  <div class="row1  mt-1">-->
              <!--                    <div class="float-left pr-1"><i class="fa fa-whatsapp txt-color-whatsaap socialIconSize"-->
              <!--                                          aria-hidden="true"></i></div>-->
              <!--                    <div class="socPad float-left">-->
              <!--                      <a href="https://www.whatsapp.com/{{contactByIdData.whatsapp_id}}/"-->
              <!--                         target="_blank">{{contactByIdData.whatsapp_id}}</a></div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.pinterest_id">-->
              <!--                  <div class="row1  mt-1">-->


              <!--                    <div class="float-left pr-1"><i class="fa fa-pinterest-p txt-bg-color-pint socialIconSize"-->
              <!--                                          aria-hidden="true"></i></div>-->
              <!--                    <div class="socPad float-left">-->
              <!--                      <a href="https://www.pinterest.com/{{contactByIdData.pinterest_id}}"-->
              <!--                         target="_blank">{{contactByIdData.pinterest_id}}</a></div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                &lt;!&ndash; <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.instagram_biz_id">-->
              <!--                  <div class="row  mt-1">-->
              <!--                    <div class="col-2"><i class="fa fa-instagram txt-color-insta socialIconSize"-->
              <!--                       aria-hidden="true"></i></div>-->
              <!--                   <div class="col-10">-->
              <!--                     <a href="https://business.instagram.com//in/{{contactByIdData.instagram_biz_id }}/"-->
              <!--                     target="_blank">{{contactByIdData.instagram_biz_id}}</a></div>-->
              <!--                 </div>-->
              <!--                </div>-->

              <!--                <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.google_my_biz_id">-->
              <!--                  <div class="row  mt-1">-->
              <!--                    <div class="col-2"><i class="fa fa-google txt-color-google socialIconSize red-yt-icon"-->
              <!--                        aria-hidden="true"></i></div>-->
              <!--                    <div class="col-10">-->
              <!--                      <a href="https://www.google.com/intl/en_in/business/{{contactByIdData.google_my_biz_id }}/"-->
              <!--                        target="_blank">{{contactByIdData.google_my_biz_id}}</a></div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="col-md-6" *ngIf="contactByIdData  && contactByIdData.sina_wiebo_id">-->
              <!--                  <div class="row  mt-1">-->
              <!--                    <div class="col-2"><i class="fa fa-snapchat-ghost txt-color-sinawiebo socialIconSize"-->
              <!--                       aria-hidden="true"></i></div>-->
              <!--                   <div class="col-10">-->
              <!--                     <a href="https://www.weibo.com/{{contactByIdData.sina_wiebo_id }}/"-->
              <!--                     target="_blank">{{contactByIdData.sina_wiebo_id}} </a></div>-->
              <!--                 </div>-->
              <!--                </div>-->
              <!--                <div class="col-md-6" *ngIf=" contactByIdData  && contactByIdData.we_chat_id">-->
              <!--                  <div class="row  mt-1">-->
              <!--                    <div class="col-2"><i class="fa fa-wechat txt-color-wechat socialIconSize"-->
              <!--                       aria-hidden="true"></i></div>-->
              <!--                   <div class="col-10">-->
              <!--                     <a href="https://www.wechat.com/en{{contactByIdData.we_chat_id }}/"-->
              <!--                     target="_blank">{{contactByIdData.we_chat_id}}</a></div>-->
              <!--                 </div>-->
              <!--                </div> &ndash;&gt;-->



              <!--              </div>-->

              <div class="row card-footer-trans p-0"
                *ngIf="inputdata.name!=='teammember' && inputdata.page!=='socialhost' ">

                <div class="col-md-12 mt-2 mb-2">
                  <!-- <label class="custRadioLeft float-md-left mr-tp-5">Agree to Host
                    <input type="radio" name="group"  [value]="true" [(ngModel)]="agreeToHost"  [disabled]="agreeToHostDisabled"
                      >
                    <span class="radiomarkLeft"></span>
                  </label>
                  <label class="custRadioLeft float-md-left ml-0 ml-md-1 mr-1 mr-tp-5">Agree to Join
                    <input type="radio" name="group"  [value]="false" [(ngModel)]="agreeToHost"  [disabled]="agreeToHostDisabled">
                    <span class="radiomarkLeft"></span>
                  </label> -->
                  <label class="custmCheck float-md-left mr-tp-5" title="{{tooltipAgreeToHost}}"
                    (click)="CheckMessage($event)">Agreed to Host
                    <input type="checkbox" name="group" [(ngModel)]="agreeToHost" [checked]="agreeToHost"
                      [disabled]="agreeToHostDisabled">
                    <span class="checkmark"></span>
                  </label>
                  <label class="custmCheck float-md-left ml-0 ml-md-1 mr-1 mr-tp-5">Agreed to Join
                    <input type="checkbox" name="group" [(ngModel)]="agreeToJoin" [checked]="agreeToJoin">
                    <span class="checkmark"></span>
                  </label>
                  <button (click)="saveselection()" class="btn btn-margo-cancel btn-round mt-1 mt-md-0"
                    style="margin-top: 0 !important;"><span>Save Selection</span></button>
                </div>
                <small class="col-12 red-sw-icon" *ngIf="isMessageDefault">{{tooltipAgreeToHost}}</small>
                <div class="col-md-12 mb-1" *ngIf="latestOptinReuest">
                  {{latestOptinReuest.log_type }} {{ dateTimeService.insertDateAt(latestOptinReuest.createdon | date:'MMM d, y AT h:mm')}} {{
                    latestOptinReuest.createdon | date : 'a' | lowercase }}
                  <a href="javascript:void(0)" (click)="viewMoreRequests(viewMoreRequestsBox)">View More</a>


                  <ng-template #viewMoreRequestsBox let-modal>
                    <div class="wholePop">
                      <div class="modal-header dialHead w-100">
                        <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" />
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>

                      </div>

                      <div class="modal-body">
                        <h5 class="mb-1 text-center">ALL OPT IN / OUT REQUESTS</h5>
                        <table *ngIf="allOptinRequests  && allOptinRequests.length>0" class=" bdrStCustom" width="100%">
                          <tr>
                            <th>OPT TYPE</th>
                            <th>OPT DATE</th>
                          </tr>
                          <tr *ngFor="let result of allOptinRequests; let i = index">
                            <td>{{result.log_type}} </td>
                            <td>
                              {{ dateTimeService.insertDateAt(result.createdon | date:'MMM d, y AT h:mm')}} {{
                                result.createdon | date : 'a' | lowercase }}
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div class="modal-footer text-center d-block border-0">
                        <button type="button" class="btn btn-margo-action btn-round"
                          (click)="modal.dismiss('Cross click')">OK</button>
                      </div>
                    </div>

                  </ng-template>


                </div>
              </div>
            </div>
          </div>

          <!-- <a href="javascript:void(0)" *ngIf="backurl" routerLink="{{backurl}}" class="btn btn-round btn-margo-action mrgSml-5 backButtonRight">Back</a> -->
          <div class="card-footer text-center" *ngIf="inputdata.page!=='myteam' ">



            <div class="col-md-12-1" [ngClass]="status ? 'displayblock' : 'displaynone'">

              <!-- <a href="#" class="btn btn-margo-cancel btn-round mt-1">Add a Co-Host</a>
              <button *ngIf="!optinStatus && inputdata.name!=='teammember' && inputdata.page!=='socialhost'" class="btn btn-round btn-margo-action"
              (click)="viewAgreement()">Opt In Agreement</button>
              <button *ngIf="optinStatus && inputdata.name!=='teammember' && inputdata.page!=='socialhost'" class="btn btn-round btn-margo-action"
              (click)="optoutAgreement()">Opt Out Agreement</button>
              -->
              <button *ngIf="inputdata.name!=='teammember' && inputdata.page!=='socialhost'"
                class="btn btn-round btn-margo-action mrgSml-5" (click)="viewAgreement(confirmBox)">Opt-In
                Agreement</button>


              <a href="javascript:void(0)" routerLink="{{inputdata.navurl}}"
                class="btn btn-round btn-margo-action mrgSml-5"
                *ngIf="contactByIdData && (inputdata.page==='quicktext')">Select</a>

            </div>
          </div>

          <!--  Contact Information End  -->


        </div>
      </div>

    </div>
    <div class="col-xl-5 mrg-border-left mb-2">
      <section>

        <div *ngIf="contactByIdData  && inputdata.page!=='quicktext' && inputdata.page!=='socialhost'">
          <!-- <h5 class="mt-2" *ngIf="showContact || showTeamMember">Actions 2</h5> -->
          <div class="theme-fa-icon mcb-prst" *ngIf="inputdata.name!=='teammember' && !showHostJourney">


            <div class="row">
              <div class="col-6 mb-2">
                <div class="tileAct" *ngIf="showContact">
                  <div class="butnInn" (click)="selectedShop(70,'shop','',optStatus, confirmBox)">
                    <img src="assets/custom/images/shopIcon.png">
                    <span class="tileActTitle">Shop</span>
                  </div>
                </div>
              </div>
              <div class="col-6 mb-2">
                <div class="tileAct" *ngIf="showTeamMember">
                  <div class="butnInn" (click)="selectedShop(68,'host','',optStatus, confirmBox)">
                    <img src="assets/custom/images/hostIcon2.png">
                    <span class="tileActTitle">Host</span>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="tileAct" *ngIf="showContact">
                  <div class="butnInn" (click)="selectedShop(67,'join','',optStatus, confirmBox)">
                    <img src="assets/custom/images/joinIcon.png">
                    <span class="tileActTitle">Join</span>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="tileAct" *ngIf="showTeamMember">
                  <div class="butnInn" (click)="selectmarketoption(4,'host','Host & Guest')">
                    <img src="assets/custom/images/eventIconNew.png">
                    <span class="tileActTitle">Events</span>
                  </div>
                </div>
              </div>
              <!-- <div class="col-6" *ngIf="showContact" [ngClass]="{'disabled': !optStatus.optSms || contactByIdData?.sms_consent === 'Rejected'}">
                <div class="tileAct" >
                  <div class="butnInn" (click)="optStatus.optSms && contactByIdData?.sms_consent !== 'Rejected' && goToInstantMessage()">
                    <img src="assets/custom/images/instant-message.png">
                    <span class="tileActTitle">Instant Message</span>
                  </div>
                </div>
              </div> -->
            </div>

          </div>


          <div class="card1">
            <div class="card-body1">




              <div class="theme-fa-icon mcb-prst"
                *ngIf="inputdata.name==='teammember' && showTeamMember && showContact && isBU">

                <!--  <div class="row">
               <div class="col-md-12">
                 <h4 class="font-weight-bold" *ngIf="contactByIdData">What do you want to offer them?</h4>
               </div>
             </div> -->

                <div class="row">

                  <div class="col-12 col-xl-6 col-lg-12 col-md-12 col-sm-4 py-1 text-center">
                    <div class="tileAct">
                      <div class="butnInn" (click)="selectedShop(124,'encouragement')">
                        <img src="assets/custom/images/encouragIcon.png">
                        <span class="tileActTitle">Encouragement</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-6 col-lg-12 col-md-12 col-sm-4 py-1 text-center">
                    <div class="tileAct">
                      <div class="butnInn" (click)="selectedShop(125,'training',true)">
                        <img src="assets/custom/images/trainingIcon.png">
                        <span class="tileActTitle">Training</span>
                      </div>
                    </div>
                  </div>


                </div>

              </div>
            </div>
          </div>

          <div class="theme-fa-icon mcb-prst" *ngIf="showHostJourney  && showTeamMember && showContact">

            <!--  <div class="row">
               <div class="col-md-12">
                 <h4 class="font-weight-bold">Will the event be In-Person Or Virtual?</h4>
               </div>
             </div> -->

            <div class="row">

              <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 py-1">

                <div class="tileAct card mb-0">
                  <div class="butnInn" (click)="addCoHostModal(confirmBoxCoHost, 127,'In-Person',true)">
                    <img src="assets/custom/images/inPersonIcon.png">
                    <span class="tileActTitle">In-Person</span>
                  </div>
                </div>
              </div>

              <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 py-1">

                <div class="tileAct card mb-0">
                  <div class="butnInn" (click)="addCoHostModal(confirmBoxCoHost, 128,'Virtual',true)">
                    <img src="assets/custom/images/onboardingIconNew.png">
                    <span class="tileActTitle">Virtual</span>
                  </div>
                </div>
              </div>

              <div class="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-4 py-1">

                <div class="tileAct card mb-0">
                  <div class="butnInn" (click)="addCoHostModal(confirmBoxCoHost, 129,'In-Person & Virtual',true)">
                    <img src="assets/custom/images/personIconVirtual.png">
                    <span class="tileActTitle">In-Person & Virtual</span>
                  </div>
                </div>
              </div>

            </div>

            <!-- <div class="row">
              <div class="col-xl-10">
                <button class="cbtn mt-1" (click)="addCoHostModal(confirmBoxCoHost, 127,'In-Person',true)" class="top cbtn"><i
                  class="fa fa-address-card-o red-dr-icon" aria-hidden="true"></i>In-Person</button>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-10">
                <button class="cbtn mt-1" (click)="addCoHostModal(confirmBoxCoHost, 128,'Virtual',true)" class="top cbtn"><i
                  class="fa fa-tv orange-dr-icon" aria-hidden="true"></i>Virtual</button>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-10">
                <button class="cbtn mt-1" (click)="addCoHostModal(confirmBoxCoHost, 129,'In-Person & Virtual',true)" class="top cbtn"><i
                  class="fa fa-object-group blue-lt-icon" aria-hidden="true"></i>In-Person & Virtual</button>
              </div>
            </div> -->
          </div>
        </div>
        <!-- <div class="d-block d-sm-none">
          <div></div>
          <h5 class="mt-2">Map</h5>
          <div class="mapState mt-1" *ngIf="!mapURL.includes('addressNotFound')"><img src="{{mapURL}}">
          </div>
          <div class="mt-1" *ngIf="mapURL.includes('addressNotFound')">
            <div class="alert alert-light" role="alert">
              <i class="fa fa-info"></i> &nbsp; &nbsp;Add home address to view Google Map for Contact.
            </div>
          </div>
          <div></div>
        </div> -->
      </section>
    </div>

  </div>
  <ng-template #confirmBox let-modal>
    <div class="scroller">
      <div class="wholePop filtMain">
        <div class="modal-header dialHead w-100">
          <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" />
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body text-center">
          <h5 class="mb-1 text-center">OPT-IN AGREEMENT</h5>
          <ng-container>
            <div>
              YOU AGREE THAT THIS CONTACT HAS GIVEN CONSENT TO RECEIVE COMMUNICATION
            </div>
            <div class="text-left mt-1"><input type="checkbox" [checked]="emailCheck" (change)="checkedEmail($event)">
              AGREED TO RECEIVE EMAILS</div>
            <div class="text-left mt-1"><input type="checkbox" [checked]="smsCheck" [disabled]="disablePhone"
                (change)="smsChecked($event)"> AGREED TO RECEIVE SMS/MMS</div>
            <p class="mt-2"><a target="_blank" [routerLink]="[inputdata.optinurl]" class="card-link"><i
                  class="fa fa-file-text blue-dr-icon mrgRgihtHlf" aria-hidden="true"></i> VIEW MARGO AGREEMENT</a>
            </p>
          </ng-container>
        </div>

        <div class="modal-footer text-center d-block border-0 pt-0">
          <button type="button" class="btn btn-margo-action btn-round" (click)="agree()">AGREE</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #confirmBoxCoHost let-modal>
    <div class="scroller">
      <div class="wholePop filtMain">
        <div class="modal-header dialHead w-100">
          <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" />
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(0)">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body text-center">
          <ng-container>
            <div>
              WOULD YOU LIKE TO ADD CO-HOST?
            </div>
          </ng-container>
        </div>
        <div class="modal-footer text-center d-block border-0 pt-0">
          <button type="button" class="btn btn-margo-action btn-round text-uppercase"
            (click)="modal.close('Yes')">Yes</button>
          <button type="button" class="btn btn-margo-cancel btn-round text-uppercase"
            (click)="modal.close('No')">No</button>
        </div>
      </div>
    </div>
  </ng-template>


</div>
