import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthserviceService } from 'src/app/services/auth/authservice.service';
import { ToastrService } from 'ngx-toastr';
//import { AlertService } from 'src/app/_services/alert.service';
import { AlertService } from 'src/app/core/services/alert.service';
import {ChatService} from "../../_services/chat.service";
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-forgot-passwrod',
  templateUrl: './forgot-passwrod.component.html',
  styleUrls: ['./forgot-passwrod.component.css']
})
export class ForgotPasswrodComponent implements OnInit {
  forgotForm: UntypedFormGroup;
  submitted = false;
  constructor(private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private authenticationService: AuthserviceService,
    private alertService: AlertService,
    public toastrService: ToastrService,
    private chatService:ChatService,
    private ngxSpinnerService:NgxSpinnerService
  ) { }


  get f() { return this.forgotForm.controls; }

  forgotPassword() {
    this.submitted = true;
    if (this.forgotForm.invalid) {
      return;
    }
    const value = {
      username: this.f.username.value,
    };
    this.ngxSpinnerService.show()
    this.authenticationService.checkForgotPassword(value).subscribe(data => {
      this.ngxSpinnerService.hide();
      if (data.status) {
        this.toastrService.success("Reset Password link has been sent to the registered email successfully.");
        localStorage.removeItem('accesToken');
        localStorage.removeItem('typeofuser');
        this.chatService.close();
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigateByUrl('/login');
      } else {
        this.toastrService.error("Account Does Not Exist");
      }
    }, error => {
      //console.log(error);
    });
  }
  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      username: ['', Validators.required],
    }, {
      // validator: MustMatch('password', 'cpassword')

    });

  }
  gotoLogin(){
    this.router.navigateByUrl('/login')
  }
}
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {

      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}


