<h4 class="font-weight-bold mb-1 subTitleHead_3 mt-2">Attributes:</h4>
<p *ngIf="contactAttributes.length === 0">No result available.</p>

<button class="btn btn-margo-cancel btn-round attributeBtn ml-0" *ngFor="let attr of contactAttributes" (click)="deleteAttribute(attr.id)"><span class="attrbtnTxt">{{attr.attribute_name}}</span> <span class="attrClsBtn"> x</span></button>
<h4 class="font-weight-bold mb-1 subTitleHead_3 mt-1">Find and select customer attributes</h4>
<div class="row mr-0">
  <div class="col-md-6">
    <input class="form-control" [formControl]="queryContactField" type="text" placeholder="Enter an attribute">
  </div>
</div>
<h4 class="font-weight-bold mb-1 subTitleHead_3 mt-2">Attributes</h4>

<div class="card bg-color-f9" >
  <div class="card-body">
    <div class="scroller">
      <div class="filtMain"
           infiniteScroll
           [infiniteScrollDistance]="2"
           [infiniteScrollThrottle]="50"
           (scrolled)="onScrolled()"
           [scrollWindow]="false">
        <ul class="attrSearchList" *ngIf="listOfAttributes.length > 0">

          <!-- <li>
             <span class="titleHeadAttr">M</span>
             <a href="#"><span class="attrSubHead">Married</span></a>
             <a href="#"><span class="attrSubHead">Men's Health</span></a>
           </li>
           <li>
             <span class="titleHeadAttr">N</span>
             <a href="#"><span class="attrSubHead">Natural Products</span></a>
             <a href="#"><span class="attrSubHead">Nutrition</span></a>
           </li> -->

          <li *ngFor="let attr of listOfAttributes">
            <span class="titleHeadAttr">{{attr.keyName}}</span>
            <a  *ngFor="let val of attr.values" (click)="addContactAttribute(val.attribute_id)"><span class="attrSubHead" >{{val.attribute_name}}</span></a>
          </li>

          <!--   <li *ngFor="let attr of listOfAttributes">
               <a  (click)="addContactAttribute(attr.attribute_id)"><span class="attrSubHead" >{{attr.attribute_name}}</span></a>
             </li> -->

        </ul>
        <p *ngIf="listOfAttributes.length === 0">No Result Available.</p>
      </div>
    </div>

  </div>
</div>
