import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webform',
  templateUrl: './webform.component.html',
  styleUrls: ['./webform.component.css']
})
export class WebformComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
