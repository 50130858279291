<div class="container">
  <div class="row">
    <div class="col-md-10 mt-1">
      <div class="card">
        <div class="card-content">
          <div class="card-header">
            <div class="row">
              <div class="col-12">
                <h4 class="float-left mb-0 mainHeading_1">My Agreement+</h4>
                <span class="backBtnPro float-right">
                 <!--  <a href="javascript:void(0)" (click)="goBack()" class="btn btn-round btn-margo-action backButtonRight">
                    Back</a> -->
                </span>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-3 text-center">
                <div class="media d-flex">
                  <div class="media-body additProImg">
                    <img *ngIf="userProfileDetails" src="{{userProfileDetails.user_photo}}" height="100px"
                      (error)="setValue($event)">
                  </div>
                </div>
                <!-- <p class="mt-2"><a [routerLink]="['/directseller/myaccount/add-payment-method']" class="card-link"><i class="fa fa-search blue-dr-icon" aria-hidden="true"></i> View  Agreement</a></p> -->
              </div>
              <div class="col-md-9 mt-1 mt-md-0">

                <h4 class="font-weight-bold ttlColorBlue text-capitalize" *ngIf="userProfileDetails">{{userProfileDetails.user_fname | titlecase}} {{userProfileDetails.user_lname | titlecase}}</h4>
                <i class="text-uppercase" *ngIf="userProfileDetails">{{userProfileDetails.customer_company_name}}</i><br/>
                <i class="text-uppercase" *ngIf="userProfileDetails">{{userProfileDetails.username}}</i>
              

                <div class="row mt-1">
                  <div class="col-12">

                    <div class="form-group">
                     <!--  <h4 class="font-weight-bold mb-1">{{contactdata.contactname | titlecase}}</h4>

                      <div class="row mb-1">
                        <div class="col-md-3 col-sm-5 font-weight-bold">Email ID: </div>
                        <div class="col-md-9 col-sm-7">{{contactdata.contact_email}}</div>
                      </div>

                      <div class="row mb-1">
                        <div class="col-md-3 col-sm-5 font-weight-bold">Contact No.:</div>
                        <div class="col-md-9 col-sm-7">{{contactdata.contact_phone}}</div>
                      </div>

                      <div class="row mb-1" *ngIf="contactdata && contactdata.contact_address">
                        <div class="col-md-3 col-sm-5 font-weight-bold">Address:</div>
                        <div class="col-md-9 col-sm-7">{{contactdata.contact_address}}</div>
                      </div> -->

                      <h6 class="font-weight-bold text-center ">MarketingGo, Inc. dba marGo</h6>
                      <h6 class="font-weight-bold text-center text-uppercase">Terms of Use </h6><br>
                      <h4 class="mb-1 font-weight-bold">Last Updated: {{currentDate | date:'MMMM d, y'}}</h4>
                      <div class="scroller">
                        <div rows="100" class="filtMain ulCustom" id="demo" style="min-height: 500px;">
                          <p>Welcome to the marGo direct selling CRM and Marketing platform. The marGo website and
                            mobile
                            application are collectively referred to as the“<b>Service</b>”.</p>

                          <p>The Service is a copyrighted work belonging to MarketingGo, Inc (“<b>marGo</b>”,
                            “<b>us</b>”, “<b>our</b>”, and
                            “<b>we</b>”). Certain features of the Service may be subject to additional guidelines,
                            terms, or
                            rules, which will be posted on the Service in connection with such features. All such
                            additional terms, guidelines, and rules are incorporated by reference into these Terms.</p>

                          <p class="text-uppercase">
                            THESE TERMS OF USE (THESE “<b>TERMS</b>”) SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS
                            THAT
                            GOVERN YOUR USE OF THE SERVICE. BY ACCESSING OR USING THE SERVICE, YOU ARE ACCEPTING THESE
                            TERMS AND MARGO’S PRIVACY POLICY (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT),
                            AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO
                            THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT). YOU MAY NOT ACCESS OR
                            USE THE SERVICE OR ACCEPT THE TERMS IF YOU ARE NOT AT LEAST THE OLDER OF:(I) 21 YEARS OLD OR
                            (II) THE LEGAL AGE OF MAJORITY IN YOUR JURISIDCTION. IF YOU DO NOT AGREE WITH ALL OF THE
                            PROVISIONS OF THESE TERMS, DO NOT ACCESS AND/OR USE THE SERVICE.
                          </p>
                          <p class="text-uppercase"> THESE TERMS REQUIRE THE USE OF ARBITRATION (SECTION 13.2) ON AN
                            INDIVIDUAL BASIS TO RESOLVE
                            DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO
                            YOU IN THE EVENT OF A DISPUTE.
                          </p>
                          <h5 class="font-weight-bold text-uppercase">1. marGo and Direct Selling Companies</h5>
                          <p>
                            marGo is the first dedicated direct selling CRM and Marketing platform built to help sellers
                            increase productivity, drive sales, boost replenishment and deliver more revenue to their
                            organizations. While we create solutions for direct selling companies and direct sellers,
                            marGo does so as an independent contractor and is not otherwise affiliated with direct
                            selling companies or direct sellers. marGo is not responsible for any promises, obligations,
                            statements, images, text, other forms of content, information, materials, products,
                            policies, acts or omissions of any direct selling company or direct seller, whether in
                            connection with this Service or otherwise.
                          </p>

                          <h5 class="font-weight-bold text-uppercase">2. Access to the Service</h5>
                          <p> 2.1 <b> License.</b> Subject to these Terms, marGo grants you a non-transferable,
                            non-exclusive,
                            revocable, limited license to use and access the Service solely for your own personal,
                            noncommercial use.</p>

                          <p> 2.2 <b> Certain Restrictions.</b> The rights granted to you in these Terms are subject to
                            the following
                            restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute,
                            host, or otherwise commercially exploit the Service, whether in whole or in part, or any
                            content displayed on the Service; (b) you shall not modify, make derivative works of,
                            disassemble, reverse compile or reverse engineer any part of the Service; (c) you shall not
                            access the Service in order to build a similar or competitive website, product, or service;
                            and (d) except as expressly stated herein, no part of the Service may be copied, reproduced,
                            distributed, republished, downloaded, displayed, posted or transmitted in any form or by any
                            means. Unless otherwise indicated, any future release, update, or other addition to
                            functionality of the Service shall be subject to these Terms. All copyright and other
                            proprietary notices on the Service (or on any content displayed on the Service) must be
                            retained on all copies thereof.</p>

                          <p> 2.3 <b> Modification.</b> marGo reserves the right, at any time, to modify, suspend, or
                            discontinue the Service (in whole or in part) with or without notice to you. You agree that
                            marGo will not be liable to you or to any third party for any modification, suspension, or
                            discontinuation of the Service or any part thereof.</p>

                          <p> 2.4 <b> No Support or Maintenance.</b> You acknowledge and agree that marGo will have no
                            obligation to
                            provide you with any support or maintenance in connection with the Service.</p>

                          <p> 2.5 <b> Ownership</b> Excluding any User Content that you may provide (defined below), you
                            acknowledge that all the intellectual property rights, including copyrights, patents,
                            trademarks, and trade secrets, in the Service and its content are owned by marGo At Home.
                            You acknowledge that nothing herein shall be interpreted as restricting marGo’s rights to
                            use your User Content in connection with the Service or to aggregate any User Content with
                            other data for use by the marGo. As between you and the marGo all rights in and to the
                            aggregated data belong to marGo. Neither these Terms (nor your access to the Service)
                            transfers to you or any third party any rights, title or interest in or to such intellectual
                            property rights, except for the limited access rights expressly set forth in Section 2.1.
                            marGo and its suppliers reserve all rights not granted in these Terms. There are no implied
                            licenses granted under these Terms.
                          </p>

                          <h5 class="font-weight-bold text-uppercase">3. Accounts</h5>
                          <p> 3.1 <b> Account Creation.</b>In order to use certain features of the Service, you must
                            register for an account (“<b>Account</b>”) and provide certain information about yourself as
                            prompted by the account registration form (including but not limited to email address and a
                            unique password). You represent and warrant that: (a) all required registration information
                            you submit is truthful and accurate; (b) you will maintain the accuracy of such information;
                            (c) you reside in the United States or, if you reside outside the United States, that your
                            use of the Service will comply with applicable law in your jurisdiction. You may delete your
                            Account at any time, for any reason, by contacting marGo support <a
                              href="mailto:support@margo.me">
                              support@margo.me</a>. marGo may
                            suspend or terminate your Account in accordance with Section 11.</p>
                          <p> 3.2 <b> Account Responsibilities.</b> You are responsible for maintaining the
                            confidentiality of your Account login information and are fully responsible for all
                            activities that occur under your Account. You agree to immediately notify marGo of any
                            unauthorized use, or suspected unauthorized use of your Account or any other breach of
                            security. marGo cannot and will not be liable for any loss or damage arising from your
                            failure to comply with the above requirements.</p>

                          <h5 class="font-weight-bold text-uppercase">4. Accounts</h5>
                          <p> 4.1 <b> User Content. </b> “<b> User Content</b>” means any and all information and
                            content that you or any other user submits to, or uses with, the Service (e.g., content in
                            the user’s profile or postings). Your submission of User Content is governed by this
                            Agreement and the marGo Privacy Policy located at: <a
                              href="https://margo.me/privacy-policy/"
                              target="_blank">https://margo.me/privacy-policy/</a>. By submitting User Content to
                            through the Service, you make the following representations, warranties and agreements:</p>
                          <ol type="A">
                            <li>You are at least 21 years old; </li>
                            <li>You agree that you are solely responsible for and you assume all risks associated with
                              your User Content, including any reliance on its accuracy, completeness or usefulness by
                              others, or any disclosure of your User Content that personally identifies you or any third
                              party; </li>
                            <li>You consent to our use of your personal information as outlined in the Privacy Policy;
                            </li>
                            <li>To the extent that you submit information that personally identifies or is otherwise of
                              or about a third party (“Third Party Information”) through the Service, you represent that
                              all such Third Party Information is of persons who are at least 21 years of age, and that
                              you have validly obtained all consents and provided all notices required by applicable law
                              for the submission, disclosure and use by us of the Third Party Information; </li>
                            <li>All information or material that you submit through the Site is true, accurate and
                              complete, and you will maintain and update such information and materials as needed such
                              that it remains true, accurate and complete; </li>
                            <li>You hereby represent and warrant that your User Content does not violate our Acceptable
                              Use Policy (defined in Section 4.3). </li>
                          </ol>

                          <p>
                            You may not represent or imply to others that your User Content is in any way provided,
                            sponsored or endorsed by marGo. Because you alone are responsible for your User Content, you
                            may expose yourself to liability if, for example, your User Content violates the Acceptable
                            Use Policy. marGo is not obligated to backup any User Content, and your User Content may be
                            deleted at any time without prior notice. You are solely responsible for creating and
                            maintaining your own backup copies of your User Content if you desire.
                          </p>

                          <p> 4.2 <b> License.</b> You hereby grant (and you represent and warrant that you have the
                            right to grant) to marGo an irrevocable, nonexclusive, royalty-free and fully paid,
                            worldwide license to reproduce, distribute, publicly display and perform, prepare derivative
                            works of, incorporate into other works, and otherwise use and exploit your User Content, and
                            to grant sublicenses of the foregoing rights, solely for the purposes of including your User
                            Content in the Service. You hereby irrevocably waive (and agree to cause to be waived) any
                            claims and assertions of moral rights or attribution with respect to your User Content.</p>
                          <p> 4.3 <b> Acceptable Use Policy.</b> The following terms constitute our “<b>Acceptable Use
                              Policy</b>”: </p>
                          <ol type="A">
                            <li>You agree not to use the Service to submit, collect, upload, transmit, display, or
                              distribute any User Content (i) that violates any third-party right, including any
                              copyright, trademark, patent, trade secret, moral right, privacy right, right of
                              publicity, or any other intellectual property or proprietary right; (ii) that is unlawful,
                              harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar,
                              defamatory, false, intentionally misleading, trade libelous, pornographic, obscene,
                              patently offensive, promotes racism, bigotry, hatred, or physical harm of any kind against
                              any group or individual or is otherwise objectionable; (iii) that is harmful to minors in
                              any way; or (iv) that is in violation of any law, regulation, or obligations or
                              restrictions imposed by any third party. </li>
                            <li>In addition, you agree not to: (i) upload, transmit, or distribute to or through the
                              Service any computer viruses, worms, or any software intended to damage or alter a
                              computer system or data; (ii) send through the Service unsolicited or unauthorized
                              advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or
                              any other form of duplicative or unsolicited messages, whether commercial or otherwise;
                              (iii) use the Service to harvest, collect, gather or assemble information or data
                              regarding other users, including e-mail addresses, without their consent; (iv) interfere
                              with, disrupt, or create an undue burden on servers or networks connected to the Service,
                              or violate the regulations, policies or procedures of such networks; (v) attempt to gain
                              unauthorized access to the Service (or to other computer systems or networks connected to
                              or used together with the Service), whether through password mining or any other means;
                              (vi) harass or interfere with any other user’s use and enjoyment of the Service; or (vi)
                              use software or automated agents or scripts to produce multiple accounts on the Service,
                              or to generate automated searches, requests, or queries to (or to strip, scrape, or mine
                              data from) the Service (provided, however, that we conditionally grant to the operators of
                              public search engines revocable permission to use spiders to copy materials from the
                              Service for the sole purpose of and solely to the extent necessary for creating publicly
                              available searchable indices of the materials, but not caches or archives of such
                              materials, subject to the parameters set forth in our robots.txt file). </li>
                          </ol>


                          <p> 4.4 <b> Enforcement.</b> We reserve the right (but have no obligation) to review any User
                            Content, and to investigate and/or take appropriate action against you in our sole
                            discretion if you violate the Acceptable Use Policy or any other provision of these Terms or
                            otherwise create liability for us or any other person. Such action may include removing or
                            modifying your User Content, terminating your Account in accordance with Section 11, and/or
                            reporting you to law enforcement authorities.</p>
                          <p> 4.5 <b> Feedback.</b>If you provide marGo with any feedback or suggestions regarding the
                            Service (“<b>Feedback</b>”), you hereby assign to marGo all rights in such Feedback and
                            agree that marGo shall have the right to use and fully exploit such Feedback and related
                            information in any manner it deems appropriate. marGo will treat any Feedback you provide to
                            marGo as non-confidential and non-proprietary. You agree that you will not submit to marGo
                            any information or ideas that you consider to be confidential or proprietary.</p>
                          <h5 class="font-weight-bold text-uppercase">5. Free content.</h5>
                          <p>
                            Upon creating an account, users will have access to certain features of the service
                            ("<b>Free Features</b>"). marGo may add, remove, modify or otherwise change the free
                            features at any time with or without notice to you.
                          </p>
                          <h5 class="font-weight-bold text-uppercase">6. Subscriptions.</h5>
                          <p>
                            Certain features ("<b>Premium Features</b>") of the service are accessible only through our
                            programs provided by marGo At Home ("Subscription Programs") as described below.
                          </p>

                          <p> 6.1 <span class="text-uppercase"><b> Premium Features:</b> </span></p>
                          <ol type="A">
                            <li>Premium Features are available from your access to the service provided by marGo At
                              Home. Premium Features are paid for, from your eWallet, in advance at the price listed on
                              our website for that particular service. For <b>eWallet Terms</b> see Section 6.2 below.
                            </li>
                          </ol>
                          <p> 6.2 <span class="text-uppercase"><b> eWallet Terms.</b></span>The Services may include
                            eWallet credits (“eWallet Credits”) for use in connection with our Premium Services. With
                            respect to eWallet Credits, you as follows:</p>

                          <p>Regardless of whether the eWallet Credit is purchased or earned by or gifted to an Customer
                            or to an Authorized IDS User, neither Customer nor the Authorized IDS User legally own such
                            eWallet Credit.</p>
                          <p>Instead of ownership, what is actually being purchased, earned or received is a limited
                            personal revocable license to use the eWallet Credit.</p>
                          <p>Once earned, purchased or received, eWallet Credits have no monetary value outside of the
                            marGo platform and can never be exchanged for real money. </p>
                          <p>Any eWallet Credit balance shown on an Authorized IDS User’s account is only a measurement
                            of the extent of such users limited license and does not represent a real-world balance or
                            stored value. </p>
                          <p>eWallet Credits are not refundable and are not transferrable from one Authorized IDS User
                            to another or to any other third party. </p>
                          <p>In the case of eWallet Credits which are purchased, Customer agrees that an order by
                            Customer or any Authorized User to purchase an eWallet Credit is an offer by such person to
                            marGo to obtain a limited license and right to use the eWallet Credit (“Offer”). Customer
                            agrees further that marGo’s acceptance of payment for an eWallet Credit is marGo’s
                            acceptance of such Offer. Accordingly, the limited license and right to use the eWallet
                            Credit begins at the moment marGo accepts payment for the same. </p>
                          <p>If marGo suspends or terminates Customer’s or any Authorized IDS User’s account for any
                            reason permitted hereunder, the account holder will lose any eWallet Credit that it may have
                            and marGo will not be responsible for compensating the account holder from this loss. </p>
                          <p>marGo may revise the value structure for eWallet Credits at any time in its sole discretion
                            and marGo reserves the right to control, regulate, change or remove eWallet Credits without
                            liability to Customer or to any Authorized IDS User. </p>
                          <p> 6.3 <span class="text-uppercase"><b>Price and program changes.</b> </span> We reserve the
                            right, in our sole discretion, to change the pricing, content, availability and any other
                            aspects of our Premium Features at any time. Premium Features are priced based upon the
                            service and purchase is optional. </p>
                          <p> 6.4 <span class="text-uppercase"><b>Premium Features.</b> </span></p>
                          <ol type="A">
                            <li>Premium Features can be purchased within marGo At Home’s provided Subscription. We do
                              not provide refunds for such purchases. </li>
                            <li>If you live in the European Union, we will provide you with a vat invoice where we are
                              required to do so by law or where requested by you. You agree that these invoices may be
                              electronic in format. </li>
                          </ol>

                          <p> 6.5 <span class="text-uppercase"><b>AUTHORIZED IDS USER PURCHASING PREMIUM FEATURES.</b>
                            </span> Our terms of
                            sale govern your purchase of Premium Features through our site and are per Section 6.2
                            above. </p>

                          <h5 class="font-weight-bold text-uppercase">7. Indemnification</h5>
                          <p>
                            You agree to indemnify and hold marGo (and its officers, employees, and agents) harmless,
                            including costs and attorneys’ fees, from any claim or demand made by any third party due to
                            or arising out of (a) your use of the Service, (b) your violation of these Terms, (c) your
                            violation of applicable laws or regulations or (d) your User Content. marGo reserves the
                            right, at your expense, to assume the exclusive defense and control of any matter for which
                            you are required to indemnify us, and you agree to cooperate with our defense of these
                            claims. You agree not to settle any matter without the prior written consent of marGo. marGo
                            will use reasonable efforts to notify you of any such claim, action or proceeding upon
                            becoming aware of it.
                          </p>

                          <h5 class="font-weight-bold text-uppercase">8. Third-Party Links & Ads; Other Users </h5>

                          <p> 8.1 <span class="text-uppercase"><b>Third-Party Links & Ads.</b> </span> The Service may
                            contain links to third-party websites and services, and/or display advertisements for third
                            parties (collectively, “<b>Third-Party Links & Ads</b>”). Such Third-Party Links & Ads are
                            not under the control of marGo, and marGo is not responsible for any Third-Party Links &
                            Ads. marGo provides access to these Third-Party Links & Ads only as a convenience to you,
                            and does not review, approve, monitor, endorse, warrant, or make any representations with
                            respect to Third-Party Links & Ads. You use all Third-Party Links & Ads at your own risk,
                            and should apply a suitable level of caution and discretion in doing so. When you click on
                            any of the Third-Party Links & Ads, the applicable third party’s terms and policies apply,
                            including the third party’s privacy and data gathering practices. You should make whatever
                            investigation you feel necessary or appropriate before proceeding with any transaction in
                            connection with such Third-Party Links & Ads. </p>
                          <p> 8.2 <span class="text-uppercase"><b>Other Users.</b> </span> Each Service user is solely
                            responsible for any and all of its own User Content. Because we do not control User Content,
                            you acknowledge and agree that we are not responsible for any User Content, whether provided
                            by you or by others. We make no guarantees regarding the accuracy, currency, suitability, or
                            quality of any User Content. Your interactions with other Service users are solely between
                            you and such users. You agree that marGo will not be responsible for any loss or damage
                            incurred as the result of any such interactions. If there is a dispute between you and any
                            Service user, we are under no obligation to become involved. </p>
                          <p> 8.3 <span class="text-uppercase"><b>Release.</b> </span> You hereby release and forever
                            discharge the marGo (and our officers, employees, agents, successors, and assigns) from, and
                            hereby waive and relinquish, each and every past, present and future dispute, claim,
                            controversy, demand, right, obligation, liability, action and cause of action of every kind
                            and nature (including personal injuries, death, and property damage), that has arisen or
                            arises directly or indirectly out of, or that relates directly or indirectly to, the Service
                            (including any interactions with, or act or omission of, other Service users or any
                            Third-Party Links & Ads). IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA
                            CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE
                            DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER
                            FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE
                            MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.” </p>

                          <h5 class="font-weight-bold text-uppercase">9. Disclaimers</h5>
                          <p>
                            THE SERVICE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, AND marGo (AND OUR
                            SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER
                            EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
                            FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE
                            (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE SERVICE WILL MEET YOUR REQUIREMENTS, WILL BE
                            AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE,
                            RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. IF APPLICABLE LAW
                            REQUIRES ANY WARRANTIES WITH RESPECT TO THE SERVICE, ALL SUCH WARRANTIES ARE LIMITED IN
                            DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE.

                          </p>
                          <p>
                            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION
                            MAY NOT APPLY TO YOU. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED
                            WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
                          </p>

                          <h5 class="font-weight-bold text-uppercase">10. Limitation on Liability</h5>
                          <p>
                            TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL marGO (OR OUR SUPPLIERS) BE LIABLE
                            TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF
                            SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
                            PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY TO
                            USE, THE SERVICE, EVEN IF MarGO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS
                            TO, AND USE OF, THE SERVICE IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY
                            RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING
                            THEREFROM.
                          </p>
                          <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
                            HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR
                            ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED
                            TO A MAXIMUM OF FIFTY US DOLLARS (U.S. $50). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT
                            ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND ARISING
                            FROM OR RELATING TO THIS AGREEMENT.</p>
                          <p>SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
                            CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. </p>

                          <h5 class="font-weight-bold text-uppercase">11. Term and Termination.</h5>
                          <p>
                            Subject to this Section, these Terms will remain in full force and effect while you use the
                            Service. We may suspend or terminate your rights to use the Service (including your Account)
                            at any time for any reason at our sole discretion, including for any use of the Service in
                            violation of these Terms. Upon termination of your rights under these Terms, your Account
                            and right to access and use the Service will terminate immediately. You understand that any
                            termination of your Account may involve deletion of your User Content associated with your
                            Account from our live databases. marGo will not have any liability whatsoever to you for any
                            termination of your rights under these Terms, including for termination of your Account or
                            deletion of your User Content. Even after your rights under these Terms are terminated, the
                            following provisions of these Terms will remain in effect: Sections 2.2 through 2.5, Section
                            4 and Sections 7 through 13.
                          </p>

                          <h5 class="font-weight-bold text-uppercase">12. Copyright Policy.</h5>
                          <p>
                            marGo respects the intellectual property of others and asks that users of our Service do the
                            same. In connection with our Service, we have adopted and implemented a policy respecting
                            copyright law that provides for the removal of any infringing materials and for the
                            termination, in appropriate circumstances, of users of our online Service who are repeat
                            infringers of intellectual property rights, including copyrights. If you believe that one of
                            our users is, through the use of our Service, unlawfully infringing the copyright(s) in a
                            work, and wish to have the allegedly infringing material removed, the following information
                            in the form of a written notification (pursuant to 17 U.S.C. § 512(c)) must be provided to
                            our designated Copyright Agent: (1) your physical or electronic signature; (2)
                            identification of the copyrighted work(s) that you claim to have been infringed; (3)
                            identification of the material on our services that you claim is infringing and that you
                            request us to remove; (4) sufficient information to permit us to locate such material; (5)
                            your address, telephone number, and e-mail address; (6) a statement that you have a good
                            faith belief that use of the objectionable material is not authorized by the copyright
                            owner, its agent, or under the law; and (7) a statement that the information in the
                            notification is accurate, and under penalty of perjury, that you are either the owner of the
                            copyright that has allegedly been infringed or that you are authorized to act on behalf of
                            the copyright owner.
                          </p>
                          <p class="mt-1">Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of
                            material fact (falsities) in a written notification automatically subjects the complaining
                            party to liability for any damages, costs and attorney’s fees incurred by us in connection
                            with the written notification and allegation of copyright infringement.</p>

                          <p>The designated Copyright Agent for marGo is: <u> Dean Yellets </u><br>

                            Address of Agent: <u> 2000 Greyhawk, Pl., Apex, N.C. 27539 </u><br>

                            Email: <u><a href="mailto:legaldepartment@margo.me"> legaldepartment@margo.me</a> </u>
                          </p><br>

                          <h5 class="font-weight-bold text-uppercase">13. General</h5>

                          <p> 13.1 <span class="text-uppercase"><b>Changes.</b> </span> These Terms are subject to
                            occasional revision, and if we make any substantial changes, we may notify you by sending
                            you an e-mail to the last e-mail address you provided to us (if any), and/or by prominently
                            posting notice of the changes on our Service. You are responsible for providing us with your
                            most current e-mail address. In the event that the last e-mail address that you have
                            provided us is not valid, or for any reason is not capable of delivering to you the notice
                            described above, our dispatch of the e-mail containing such notice will nonetheless
                            constitute effective notice of the changes described in the notice. Any changes to these
                            Terms will be effective upon the earlier of thirty (30) calendar days following our dispatch
                            of an e-mail notice to you (if applicable) or thirty (30) calendar days following our
                            posting of notice of the changes on our Service. These changes will be effective immediately
                            for new users of our Service. Continued use of our Service following notice of such changes
                            shall indicate your acknowledgement of such changes and agreement to be bound by the terms
                            and conditions of such changes.</p>

                          <p>13.2 <span class="text-uppercase"><b>Dispute Resolution. Please read this Arbitration
                                Agreement carefully. It is part of your contract with marGo and affects your rights. It
                                contains procedures for MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.</b>
                            </span></p>
                          <ol type="A">
                            <li><i>Applicability of Arbitration Agreement</i>. All claims and disputes (excluding claims
                              for injunctive or other equitable relief as set forth below) in connection with the Terms
                              or the use of any product or service provided by the marGo that cannot be resolved
                              informally or in small claims court shall be resolved by binding arbitration on an
                              individual basis under the terms of this Arbitration Agreement. Unless otherwise agreed
                              to, all arbitration proceedings shall be held in English. This Arbitration Agreement
                              applies to you and the marGo, and to any subsidiaries, affiliates, agents, employees,
                              predecessors in interest, successors, and assigns, as well as all authorized or
                              unauthorized users or beneficiaries of services or goods provided under the Terms. </li>

                            <li><i>Notice Requirement and Informal Dispute Resolution</i>. Before either party may seek
                              arbitration, the party must first send to the other party a written Notice of Dispute
                              (“<b>Notice</b>”) describing the nature and basis of the claim or dispute, and the
                              requested
                              relief. A Notice to the marGo should be sent to the marGo attn.: Terms of Use Dispute at
                              the address listed in Section 13.8 (or such other address as may be provided by the marGo
                              for this purpose. After the Notice is received, you and the marGo may attempt to resolve
                              the claim or dispute informally. If you and the marGo do not resolve the claim or dispute
                              within thirty (30) days after the Notice is received, either party may begin an
                              arbitration proceeding. The amount of any settlement offer made by any party may not be
                              disclosed to the arbitrator until after the arbitrator has determined the amount of the
                              award, if any, to which either party is entitled. </li>
                            <li><i>Arbitration</i>. You agree that any dispute, claim or controversy arising hereunder
                              or
                              relating in any way to these Terms and not informally resolved shall be settled by binding
                              arbitration in Wake County, North Carolina, in accordance with the commercial arbitration
                              rules of Judicial Arbitration and Mediation Services (“<b>JAMS</b>”). The arbitrator shall
                              issue
                              a written decision specifying the basis for the award made. The party filing a claim or
                              counterclaim in the arbitration proceeding shall pay the deposit(s) determined by JAMS
                              with respect to such claim or counterclaim. All other costs associated with the
                              arbitration and imposed by JAMS shall be paid as determined by the arbitrator(s) and, in
                              absence of such determination, equally by each party to the arbitration. In addition,
                              unless the arbitrator awards payment of reasonable attorney and other fees to a party,
                              each party to the arbitration shall be responsible for its own attorneys' fees and other
                              professional fees incurred in connection with the arbitration. Determinations of the
                              arbitrator will be final and binding upon the parties to the arbitration, and judgment
                              upon the award rendered by the arbitrator may be entered in any court having jurisdiction,
                              or application may be made to such court for a judicial acceptance of the award and an
                              order of enforcement, as the case may be. The arbitrator shall apply the substantive law
                              of the State of North Carolina, without giving effect to its conflict of laws rules. </li>
                            <li><i>Waiver of Jury Trial</i>. THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY
                              RIGHTS
                              TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, instead electing that all
                              claims and disputes shall be resolved by arbitration under this Arbitration Agreement.
                              Arbitration procedures are typically more limited, more efficient and less costly than
                              rules applicable in a court and are subject to very limited review by a court. In the
                              event any litigation should arise between you and the marGo in any state or federal court
                              in a suit to vacate or enforce an arbitration award or otherwise, YOU AND MARGO WAIVE ALL
                              RIGHTS TO A JURY TRIAL, instead electing that the dispute be resolved by a judge. </li>
                            <li><i>Waiver of Class or Consolidated Actions</i>. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE
                              OF
                              THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT
                              ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR
                              LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. </li>
                            <li><i>Confidentiality</i>. All aspects of the arbitration proceeding, including but not
                              limited to
                              the award of the arbitrator and compliance therewith, shall be strictly confidential. The
                              parties agree to maintain confidentiality unless otherwise required by law. This paragraph
                              shall not prevent a party from submitting to a court of law any information necessary to
                              enforce this Agreement, to enforce an arbitration award, or to seek injunctive or
                              equitable relief.</li>
                            <li><i>Severability</i>. If any part or parts of this Arbitration Agreement are found under
                              the law
                              to be invalid or unenforceable by a court of competent jurisdiction, then such specific
                              part or parts shall be of no force and effect and shall be severed and the remainder of
                              the Agreement shall continue in full force and effect. </li>
                            <li><i>Right to Waive</i>. Any or all of the rights and limitations set forth in this
                              Arbitration
                              Agreement may be waived by the party against whom the claim is asserted. Such waiver shall
                              not waive or affect any other portion of this Arbitration Agreement. </li>
                            <li><i>Emergency Equitable Relief</i>. Notwithstanding the foregoing, either party may seek
                              emergency equitable relief before a state or federal court in order to maintain the status
                              quo pending arbitration. A request for interim measures shall not be deemed a waiver of
                              any other rights or obligations under this Arbitration Agreement. </li>
                            <li><i>Claims Not Subject to Arbitration</i>. Notwithstanding the foregoing, claims of
                              defamation,
                              violation of the Computer Fraud and Abuse Act, and infringement or misappropriation of the
                              other party’s patent, copyright, trademark or trade secrets shall not be subject to this
                              Arbitration Agreement. </li>
                            <li><i>Courts</i>. In any circumstances where the foregoing Arbitration Agreement permits
                              the
                              parties to litigate in court, the parties hereby agree to submit to the personal
                              jurisdiction of the courts located within Wake County, North Carolina, for such purpose
                            </li>
                          </ol>

                          <p> 13.3 <span class="text-uppercase"><b>Export.</b> </span> The Service may be subject to
                            U.S. export control laws and may be subject to export or import regulations in other
                            countries. You agree not to export, reexport, or transfer, directly or indirectly, any U.S.
                            technical data acquired from marGo, or any products utilizing such data, in violation of the
                            United States export laws or regulations.</p>
                          <p> 13.4 <span class="text-uppercase"><b>Disclosures.</b> </span> marGo is located at the
                            address in Section 16.9. If you are a California resident, you may report complaints to the
                            Complaint Assistance Unit of the Division of Consumer Product of the California Department
                            of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or
                            by telephone at (800) 952-5210.</p>
                          <p> 13.5 <span class="text-uppercase"><b>Electronic Communications.</b> </span> The
                            communications between you and marGo use electronic means, whether you use the Service or
                            send us emails, or whether marGo posts notices on the Service or communicates with you via
                            email. For contractual purposes, you (a) consent to receive communications from marGo in an
                            electronic form; and (b) agree that all terms and conditions, agreements, notices,
                            disclosures, and other communications that marGo provides to you electronically satisfy any
                            legal requirement that such communications would satisfy if it were be in a hardcopy
                            writing. The foregoing does not affect your non-waivable rights.</p>
                          <p> 13.6 <span class="text-uppercase"><b>Entire Terms.</b> </span>
                            These Terms constitute the entire agreement between you and us regarding the use of the
                            Service. Our failure to exercise or enforce any right or provision of these Terms shall not
                            operate as a waiver of such right or provision. The section titles in these Terms are for
                            convenience only and have no legal or contractual effect. The word “including” means
                            “including without limitation”. If any provision of these Terms is, for any reason, held to
                            be invalid or unenforceable, the other provisions of these Terms will be unimpaired and the
                            invalid or unenforceable provision will be deemed modified so that it is valid and
                            enforceable to the maximum extent permitted by law. Your relationship to marGo is that of an
                            independent contractor, and neither party is an agent or partner of the other. These Terms,
                            and your rights and obligations herein, may not be assigned, subcontracted, delegated, or
                            otherwise transferred by you without marGo’s prior written consent, and any attempted
                            assignment, subcontract, delegation, or transfer in violation of the foregoing will be null
                            and void. marGo may freely assign these Terms. The terms and conditions set forth in these
                            Terms shall be binding upon assignees.
                          </p>
                          <p> 13.7 <span class="text-uppercase"><b>Copyright/Trademark Information.</b> </span>
                            Copyright © 2014 marGo. All rights reserved. All trademarks, logos and service marks
                            (“Marks”) displayed on the Service are our property or the property of other third parties.
                            You are not permitted to use these Marks without our prior written consent or the consent of
                            such third party which may own the Marks.</p>
                          <p> 13.8 <span class="text-uppercase"><b>Additional Terms of Services.</b> </span></p>
                          <p>Apple:</p>
                          <p><a href="https://appstoreconnect.apple.com/WebObjects/iTunesConnect.woa/wa/termsOfService"
                              target="_blank">
                              https://appstoreconnect.apple.com/WebObjects/iTunesConnect.woa/wa/termsOfService
                            </a>

                          </p>
                          <p>Google Play:</p>
                          <p><a href="https://play.google.com/about/play-terms/index.html" target="_blank">
                              https://play.google.com/about/play-terms/index.html
                            </a>

                          </p>

                          <p> 13.7 <span class="text-uppercase"><b>Contact Information:</b></span>
                          </p>

                          <div class="col-sm-4 col-md-2 font-weight-bold">Attn:</div>
                          <div class="col-sm-8 col-md-10">Legal Department<br />
                              MarketingGo, Inc.</div>

                          <div class="col-sm-5 col-md-3 mt-1 font-weight-bold">Address:</div>
                          <div class="col-sm-7 col-md-9">2000 Greyhawk Place</div>

                          <div class="col-sm-4 col-md-2 mt-1 font-weight-bold">Email:</div>
                          <div class="col-sm-8 col-md-10">legaldepartment@margo.me</div>


                        </div>
                      </div>

                    <!-- <p>{{emsg}}</p> -->

                  </div>

                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <button *ngIf="!isMobileDevice" printSectionId="demo" class="btn btn-round btn-margo-action mb-2 mb-md-1 float-left" ngxPrint
                    [printStyle]="{h3 : {'color': '#00AEBC'}, h2 : {'border': 'solid 1px'}}">
                      Print</button>

                      <!-- <a routerLink="../view" class="card-link"><button  class="btn btn-round btn-margo-action mb-2 mb-md-1 float-left"
                        >
                         Close</button></a> -->
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #confirmBox let-modal>
      <div class="modal-header dialHead">
        <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" style="height: 100px;" />
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h3>WOULD YOU LIKE TO OPTIN AGREEMENT?</h3>
      </div>
      <div class="modal-footer text-center d-block border-0">        
        <button type="button" class="btn btn-margo-action btn-round text-uppercase" (click)="modal.dismiss('Cross click')">YES</button>
        <button type="button" class="btn btn-margo-cancel btn-round text-uppercase" (click)="modal.close('Cross click')">NO</button>
      </div>
    </ng-template>
  </div>
</div>