
export class RoutesConstants {

        public static routes = {
            '/directseller/sms/sms-consent': {
                log_type: 'web',
                log_name: 'SMS Consent Page',
                log_description: 'SMS Consent Page Visit',
                module_name: 'Quick Message'
            },
            '/directseller/sms/instant': {
                log_type: 'web',
                log_name: 'SMS Instant Page',
                log_description: 'SMS Instant Page Visit',
                module_name: 'Quick Message'
            },
            '/directseller/sms/dashboard': {
                log_type: 'web',
                log_name: 'SMS Dashboard Page',
                log_description: 'SMS Dashboard Page Visit',
                module_name: 'Quick Message'
            },
            '/directseller/sms': {
                log_type: 'web',
                log_name: 'SMS Campaign Page',
                log_description: 'SMS Campaign Page Visit',
                module_name: 'Quick Message'
            },
            '/directseller/sms/calendar': {
                log_type: 'web',
                log_name: 'SMS Calendar Page',
                log_description: 'SMS Calendar Page Visit',
                module_name: 'Quick Message'
            },
            '/directseller/sms/messaging': {
                log_type: 'web',
                log_name: 'SMS Messaging List Page',
                log_description: 'SMS Messaging List Page Visit',
                module_name: 'Quick Message'
            },
            '/directseller/sms/typeofpost': {
                log_type: 'web',
                log_name: 'SMS Type Of Post Page',
                log_description: 'SMS Type Of Post Page Visit',
                module_name: 'Quick Message'
            },
            '/directseller/sms/smspost': {
                log_type: 'web',
                log_name: 'SMS Message Template Page',
                log_description: 'SMS Message Template Page Visit',
                module_name: 'Quick Message'
            },
            '/directseller/sms/customtemplate': {
                log_type: 'web',
                log_name: 'SMS Message Template Create/Edit Page',
                log_description: 'SMS Message Template Create/Edit Page Visit',
                module_name: 'Quick Message'
            },
            '/directseller/sms/mbevent': {
                log_type: 'web',
                log_name: 'SMS Schedule Campaign Page',
                log_description: 'SMS Schedule Campaign Page Visit',
                module_name: 'Quick Message'
            },
            '/directseller/home': {
                log_type: 'web',
                log_name: 'Home Page',
                log_description: 'Home Page Visit',
                module_name: 'Home'
            },
            '/directseller/myaccount/myprofile': {
                log_type: 'web',
                log_name: 'My Profile Page',
                log_description: 'My Profile Page Visit',
                module_name: 'My Profile'
            },
            '/directseller/myaccount/myprofile/update': {
                log_type: 'web',
                log_name: 'My Profile Update Page',
                log_description: 'My Profile Update Page Visit',
                module_name: 'My Profile'
            },
            '/directseller/create-segments/list': {
                log_type: 'web',
                log_name: 'Contact Segment List Page',
                log_description: 'Contact Segment List Page Visit',
                module_name: 'My Segment'
            },
            '/directseller/create-segments': {
                log_type: 'web',
                log_name: 'Contact Segment Create Page',
                log_description: 'Contact Segment Create Page Visit',
                module_name: 'My Segment'
            },
            '/directseller/mypayment': {
                log_type: 'web',
                log_name: 'My Payment Details Page',
                log_description: 'My Payment Details Page Visit',
                module_name: 'My Payment'
            },
            '/directseller/myaccount/purchase': {
                log_type: 'web',
                log_name: 'Payment History Details Page',
                log_description: 'Payment History Details Page Visit',
                module_name: 'My Payment'
            },
            '/directseller/myaccount/addfunds': {
                log_type: 'web',
                log_name: 'Payment Add Funds Page',
                log_description: 'Payment Add Funds Page Visit',
                module_name: 'My Payment'
            },
            '/directseller/myaccount/addfunds/add-payment-method': {
                log_type: 'web',
                log_name: 'Add Payment Method Page',
                log_description: 'Add Payment Method Page Visit',
                module_name: 'My Payment'
            },
            '/agreement': {
                log_type: 'web',
                log_name: 'marGo Agreement Page',
                log_description: 'marGo Agreement Page Visit',
                module_name: 'marGo Agreement'
            },
            '/directseller/myevent': {
                log_type: 'web',
                log_name: 'Hosted Events Listing Page',
                log_description: 'Hosted Events Listing Page Visit',
                module_name: 'My Event'
            },
            '/directseller/myevent/view': {
                log_type: 'web',
                log_name: 'Hosted Event Details Page',
                log_description: 'Hosted Event Details Page Visit',
                module_name: 'My Event'
            },
            '/directseller/myevent/add': {
                log_type: 'web',
                log_name: 'Add New Event Page',
                log_description: 'Add New Event Page Visit',
                module_name: 'My Event'
            },
            '/directseller/myevent/search': {
                log_type: 'web',
                log_name: 'Event Search Page',
                log_description: 'Event Search Page Visit',
                module_name: 'My Event'
            },
            '/directseller/myevent/optin': {
                log_type: 'web',
                log_name: 'Event Invitees Opt-In Details Page',
                log_description: 'Event Invitees Opt-In Details Page Visit',
                module_name: 'My Event'
            },
            '/directseller/myevent/update': {
                log_type: 'web',
                log_name: 'Event Details Update Page',
                log_description: 'Event Details Update Page Visit',
                module_name: 'My Event'
            },
            '/directseller/myevent/list': {
                log_type: 'web',
                log_name: 'Event Activity History Details Page',
                log_description: 'Event Activity History Details Page Visit',
                module_name: 'My Event'
            },
            '/login': {
                log_type: 'web',
                log_name: 'Login Page',
                log_description: 'Login Page Visit',
                module_name: 'Login'
            },
            'support_user_page_access': {
                log_type: 'web',
                log_name: 'Support User Login Page Access',
                log_description: 'Support User Login Page Visit',
                module_name: 'Login'
            },
            '/forgot-pwd': {
                log_type: 'web',
                log_name: 'Forgot Password Page',
                log_description: 'Forgot Password Page Visit',
                module_name: 'Forgot Password'
            },
            '/forgot-user': {
                log_type: 'web',
                log_name: 'Forgot Username Page',
                log_description: 'Forgot Username Page Visit',
                module_name: 'Forgot Username'
            },
            '/sso': {
                log_type: 'web',
                log_name: 'SSO Page',
                log_description: 'SSO Page Visit',
                module_name: 'SSO'
            },
            'setusername_page': {
                log_type: 'web',
                log_name: 'Set Username Page',
                log_description: 'Set Username Page Visit',
                module_name: 'Set Username'
            },
            'reset_password': {
                log_type: 'web',
                log_name: 'Reset Password Page',
                log_description: 'Reset Password Page Visit',
                module_name: 'Reset Password'
            },
            '/directseller/market': {
                log_type: 'web',
                log_name: 'Market Landing Page',
                log_description: 'Market Landing Page Visit',
                module_name: 'Market'
            },
            '/directseller/mycontact': {
                log_type: 'web',
                log_name: 'My Contact Landing Page',
                log_description: 'My Contact Landing Page Visit',
                module_name: 'Market'
            },
            '/directseller/bookmarklist': {
                log_type: 'web',
                log_name: 'Favorites Bookmark Listing Page',
                log_description: 'Favorites Bookmark Listing Page Visit',
                module_name: 'Market'
            },
            '/directseller/import-contacts': {
                log_type: 'web',
                log_name: 'Add New Contact Landing Page',
                log_description: 'Add New Contact Landing Page Visit',
                module_name: 'Market'
            },
            'imported_contacts': {
                log_type: 'web',
                log_name: 'Imported Contacts Listing Page',
                log_description: 'Imported Contacts Listing Page Visit',
                module_name: 'Market'
            },
            '/directseller/mycontact/add': {
                log_type: 'web',
                log_name: 'New Contact Add(Manual) Page',
                log_description: 'New Contact Add(Manual) Page Visit',
                module_name: 'Market'
            },
            '/directseller/mycontact/view': {
                log_type: 'web',
                log_name: 'Contact Card Page',
                log_description: 'Contact Card Page Visit',
                module_name: 'Market'
            },
            '/directseller/mycontact/report': {
                log_type: 'web',
                log_name: 'Contact Activity & Purchase History Report Page',
                log_description: 'Contact Activity & Purchase History Report Page Visit',
                module_name: 'Market'
            },
            '/directseller/mycontact/update': {
                log_type: 'web',
                log_name: 'Contact Details Update Page',
                log_description: 'Contact Details Update Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/prospect/shop/product': {
                log_type: 'web',
                log_name: 'Products Category List Page',
                log_description: 'Products Category List Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/prospect/shop/productsList': {
                log_type: 'web',
                log_name: 'Products List Page',
                log_description: 'Products List Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/prospect/shop/theme': {
                log_type: 'web',
                log_name: 'Shop Theme Selection Page',
                log_description: 'Shop Theme Selection Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/prospect/shop/personalMessage': {
                log_type: 'web',
                log_name: 'Shop Personal Message Add To Campaign Page',
                log_description: 'Shop Personal Message Add To Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/prospect/shop/confirmCampaign': {
                log_type: 'web',
                log_name: 'Shop Confirm Campaign Page',
                log_description: 'Shop Confirm Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/prospect/host/theme': {
                log_type: 'web',
                log_name: 'Host Theme Selection Page',
                log_description: 'Host Theme Selection Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/prospect/host/personalMessage': {
                log_type: 'web',
                log_name: 'Host Personal Message Add To Campaign Page',
                log_description: 'Host Personal Message Add To Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/prospect/host/confirmCampaign': {
                log_type: 'web',
                log_name: 'Host Confirm Campaign Page',
                log_description: 'Host Confirm Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/prospect/join/theme': {
                log_type: 'web',
                log_name: 'Join Theme Selection Page',
                log_description: 'Join Theme Selection Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/prospect/join/personalMessage': {
                log_type: 'web',
                log_name: 'Join Personal Message Add To Campaign Page',
                log_description: 'Join Personal Message Add To Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/prospect/join/confirmCampaign': {
                log_type: 'web',
                log_name: 'Join Confirm Campaign Page',
                log_description: 'Join Confirm Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/type': {
                log_type: 'web',
                log_name: 'Event Type Selection (In-Personal/Virtual) Page',
                log_description: 'Event Type Selection (In-Personal/Virtual) Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/contact': {
                log_type: 'web',
                log_name: 'Host Selection Page',
                log_description: 'Host Selection Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/addevent': {
                log_type: 'web',
                log_name: 'Add Event Page',
                log_description: 'Add Event Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/subtype': {
                log_type: 'web',
                log_name: 'Event Subtype Selection Page',
                log_description: 'Event Subtype Selection Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/demonstration-and-experience/theme': {
                log_type: 'web',
                log_name: 'Event Theme Selection Page',
                log_description: 'Event Theme Selection Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/coffee-and-brunch/theme': {
                log_type: 'web',
                log_name: 'Event Theme Selection Page',
                log_description: 'Event Theme Selection Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/windandcheese/theme': {
                log_type: 'web',
                log_name: 'Event Theme Selection Page',
                log_description: 'Event Theme Selection Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/fundraisers/theme': {
                log_type: 'web',
                log_name: 'Event Theme Selection Page',
                log_description: 'Event Theme Selection Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/large-events/theme': {
                log_type: 'web',
                log_name: 'Event Theme Selection Page',
                log_description: 'Event Theme Selection Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/demonstration-and-experience/personalMessage': {
                log_type: 'web',
                log_name: 'Event Theme Add Personal Message To Campaign Page',
                log_description: 'Event Theme Add Personal Message To Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/coffee-and-brunch/personalMessage': {
                log_type: 'web',
                log_name: 'Event Theme Add Personal Message To Campaign Page',
                log_description: 'Event Theme Add Personal Message To Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/windandcheese/personalMessage': {
                log_type: 'web',
                log_name: 'Event Theme Add Personal Message To Campaign Page',
                log_description: 'Event Theme Add Personal Message To Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/fundraisers/personalMessage': {
                log_type: 'web',
                log_name: 'Event Theme Add Personal Message To Campaign Page',
                log_description: 'Event Theme Add Personal Message To Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/large-events/personalMessage': {
                log_type: 'web',
                log_name: 'Event Theme Add Personal Message To Campaign Page',
                log_description: 'Event Theme Add Personal Message To Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/demonstration-and-experience/confirmCampaign': {
                log_type: 'web',
                log_name: 'Event Confirm Campaign Page',
                log_description: 'Event Confirm Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/coffee-and-brunch/confirmCampaign': {
                log_type: 'web',
                log_name: 'Event Confirm Campaign Page',
                log_description: 'Event Confirm Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/windandcheese/confirmCampaign': {
                log_type: 'web',
                log_name: 'Event Confirm Campaign Page',
                log_description: 'Event Confirm Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/fundraisers/confirmCampaign': {
                log_type: 'web',
                log_name: 'Event Confirm Campaign Page',
                log_description: 'Event Confirm Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/host/large-events/confirmCampaign': {
                log_type: 'web',
                log_name: 'Event Confirm Campaign Page',
                log_description: 'Event Confirm Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/myteam': {
                log_type: 'web',
                log_name: 'Team Member Listing Page',
                log_description: 'Team Member Listing Page Visit',
                module_name: 'Market'
            },
            '/directseller/myteam/view': {
                log_type: 'web',
                log_name: 'Team Member Contact Card View Page',
                log_description: 'Team Member Contact Card View Page Visit',
                module_name: 'Market'
            },
            '/directseller/myteam/report': {
                log_type: 'web',
                log_name: 'Team Member Activity History Page',
                log_description: 'Team Member Activity History Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/teammember/encouragement/theme': {
                log_type: 'web',
                log_name: 'Team Member Theme Selection Page',
                log_description: 'Team Member Theme Selection Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/teammember/encouragement/personalMessage': {
                log_type: 'web',
                log_name: 'Team Member Add Personal Message To Campaign Page',
                log_description: 'Team Member Add Personal Message To Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/teammember/encouragement/confirmCampaign': {
                log_type: 'web',
                log_name: 'Team Member Confirm Campaign Page',
                log_description: 'Team Member Confirm Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/teammember/subtype': {
                log_type: 'web',
                log_name: 'Team Member Training Subtype Selection Page',
                log_description: 'Team Member Training Subtype Selection Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/teammember/selling/theme': {
                log_type: 'web',
                log_name: 'Team Member Subtype Theme Selection Page',
                log_description: 'Team Member Subtype Theme Selection Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/teammember/hostcoaching/theme': {
                log_type: 'web',
                log_name: 'Team Member Subtype Theme Selection Page',
                log_description: 'Team Member Subtype Theme Selection Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/teammember/product/theme': {
                log_type: 'web',
                log_name: 'Team Member Subtype Theme Selection Page',
                log_description: 'Team Member Subtype Theme Selection Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/teammember/onboarding/theme': {
                log_type: 'web',
                log_name: 'Team Member Subtype Theme Selection Page',
                log_description: 'Team Member Subtype Theme Selection Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/teammember/onboarding/personalMessage': {
                log_type: 'web',
                log_name: 'Team Member Subtype Add Personal Message To Campaign Page',
                log_description: 'Team Member Subtype Add Personal Message To Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/teammember/hostcoaching/personalMessage': {
                log_type: 'web',
                log_name: 'Team Member Subtype Add Personal Message To Campaign Page',
                log_description: 'Team Member Subtype Add Personal Message To Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/teammember/selling/personalMessage': {
                log_type: 'web',
                log_name: 'Team Member Subtype Add Personal Message To Campaign Page',
                log_description: 'Team Member Subtype Add Personal Message To Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/teammember/product/personalMessage': {
                log_type: 'web',
                log_name: 'Team Member Subtype Add Personal Message To Campaign Page',
                log_description: 'Team Member Subtype Add Personal Message To Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/teammember/onboarding/confirmCampaign': {
                log_type: 'web',
                log_name: 'Team Member Subtype Confirm Campaign Page',
                log_description: 'Team Member Subtype Confirm Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/teammember/selling/confirmCampaign': {
                log_type: 'web',
                log_name: 'Team Member Subtype Confirm Campaign Page',
                log_description: 'Team Member Subtype Confirm Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/teammember/hostcoaching/confirmCampaign': {
                log_type: 'web',
                log_name: 'Team Member Subtype Confirm Campaign Page',
                log_description: 'Team Member Subtype Confirm Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/market/teammember/product/confirmCampaign': {
                log_type: 'web',
                log_name: 'Team Member Subtype Confirm Campaign Page',
                log_description: 'Team Member Subtype Confirm Campaign Page Visit',
                module_name: 'Market'
            },
            '/directseller/myhost': {
                log_type: 'web',
                log_name: 'Host Listing Page',
                log_description: 'Host Listing Page Visit',
                module_name: 'Market'
            },
            '/directseller/myhost/add': {
                log_type: 'web',
                log_name: 'Host New Contact Add Page',
                log_description: 'Host New Contact Add Page Visit',
                module_name: 'Market'
            },
            '/directseller/myhost/view': {
                log_type: 'web',
                log_name: 'Host Contact Card View Page',
                log_description: 'Host Contact Card View Page Visit',
                module_name: 'Market'
            },
            '/directseller/myhost/updatecontact': {
                log_type: 'web',
                log_name: 'Host Contact Update Details Page',
                log_description: 'Host Contact Update Details Page Visit',
                module_name: 'Market'
            },
            '/directseller/mycampaign': {
                log_type: 'web',
                log_name: 'Campaign Listing Page',
                log_description: 'Campaign Listing Page Visit',
                module_name: 'Market'
            },
            '/directseller/mycampaign/view': {
                log_type: 'web',
                log_name: 'Campaign Information Page',
                log_description: 'Campaign Information Page Visit',
                module_name: 'Market'
            },
            '/directseller/mycampaign/list': {
                log_type: 'web',
                log_name: 'Campaign Details Page',
                log_description: 'Campaign Details Page Visit',
                module_name: 'Market'
            },
            '/directseller/mypurchases': {
                log_type: 'web',
                log_name: 'Purchases Details Page',
                log_description: 'Purchases Details Page Visit',
                module_name: 'Market'
            },
            '/directseller/help': {
                log_type: 'web',
                log_name: 'Help and Tips Landing Page',
                log_description: 'Help and Tips Landing Page Visit',
                module_name: 'Help & Tips'
            },
            '/directseller/help/tips': {
                log_type: 'web',
                log_name: 'Tips Listing Page',
                log_description: 'Tips Listing Page Visit',
                module_name: 'Help & Tips'
            },
            '/directseller/help/support': {
                log_type: 'web',
                log_name: 'Support Team Contact Page',
                log_description: 'Support Team Contact Page Visit',
                module_name: 'Help & Tips'
            },
            '/directseller/productCatalogue': {
                log_type: 'web',
                log_name: 'Product Catalogue List Page',
                log_description: 'Product Catalogue List Page Visit',
                module_name: 'Product Catalogue'
            },
            '/directseller/productCatalogue/import-product': {
                log_type: 'web',
                log_name: 'Product Catalogue Import Product Landing Page',
                log_description: 'Product Catalogue Import Product Landing Page Visit',
                module_name: 'Product Catalogue'
            },
            '/directseller/productCatalogue/import-product/woocommerce': {
                log_type: 'web',
                log_name: 'Product Catalogue WooCommerce Package Credential Page',
                log_description: 'Product Catalogue WooCommerce Package Credential Page Visit',
                module_name: 'Product Catalogue'
            },
            '/directseller/productCatalogue/import-product/woocommerce/update?path=Add': {
                log_type: 'web',
                log_name: 'Product Catalogue WooCommerce Package Credential Update Page',
                log_description: 'Product Catalogue WooCommerce Package Credential Update Page Visit',
                module_name: 'Product Catalogue'
            },
            '/directseller/addProductCatalogue': {
                log_type: 'web',
                log_name: 'Product Catalogue Add Page',
                log_description: 'Product Catalogue Add Page Visit',
                module_name: 'Product Catalogue'
            },
            '/directseller/reskinning': {
                log_type: 'web',
                log_name: 'Reskinning List Page',
                log_description: 'Reskinning List Page Visit',
                module_name: 'Reskinning'
            },
            '/directseller/addReskinning': {
                log_type: 'web',
                log_name: 'Reskinning Add Page',
                log_description: 'Reskinning Add Page Visit',
                module_name: 'Reskinning'
            },
            '/directseller/social': {
                log_type: 'web',
                log_name: 'Get Social Landing Page',
                log_description: 'Get Social Landing Page Visit',
                module_name: 'Get Social'
            },
            '/directseller/social/calendar': {
                log_type: 'web',
                log_name: 'Get Social Calendar Page',
                log_description: 'Get Social Calendar Page Visit',
                module_name: 'Get Social'
            },
            '/directseller/social/socialpost': {
                log_type: 'web',
                log_name: 'Get Social Facebook Page/Group Selection Page',
                log_description: 'Get Social Facebook Page/Group Selection Visit',
                module_name: 'Get Social'
            },
            '/directseller/social/eventhost': {
                log_type: 'web',
                log_name: 'Get Social Post For Event Selection Page',
                log_description: 'Get Social Post For Event Selection Page Visit',
                module_name: 'Get Social'
            },
            '/directseller/social/searchhost': {
                log_type: 'web',
                log_name: 'Get Social Host Selection For Event Page',
                log_description: 'Get Social Host Selection For Event Page Visit',
                module_name: 'Get Social'
            },
            '/directseller/social/hostedevent': {
                log_type: 'web',
                log_name: 'Get Social Hosted Event Selection Page',
                log_description: 'Get Social Hosted Selection Page Visit',
                module_name: 'Get Social'
            },
            '/directseller/social/typeofpost': {
                log_type: 'web',
                log_name: 'Get Social Type Of Post Selection Page',
                log_description: 'Get Social Type Of Post Selection Page Visit',
                module_name: 'Get Social'
            },
            '/directseller/social/facebookpost': {
                log_type: 'web',
                log_name: 'Get Social Select Facebook Post Type Page',
                log_description: 'Get Social Select Facebook Post Type Page Visit',
                module_name: 'Get Social'
            },
            '/directseller/social/customfbpost': {
                log_type: 'web',
                log_name: 'Get Social Create/Edit Facebook Post Page',
                log_description: 'Get Social Create/Edit Facebook Post Page Visit',
                module_name: 'Get Social'
            },
            '/directseller/social/fbevent': {
                log_type: 'web',
                log_name: 'Get Social Schedule/Post-Now Facebook Post Page',
                log_description: 'Get Social Schedule/Post-Now Facebook Post Page Visit',
                module_name: 'Get Social'
            },
            '/directseller/social/fbcampselect': {
                log_type: 'web',
                log_name: 'Get Social Facebook Campaign Select Page',
                log_description: 'Get Social Facebook Campaign Select Page Visit',
                module_name: 'Get Social'
            },
            '/directseller/social/addcampaign': {
                log_type: 'web',
                log_name: 'Get Social Add New Facebook Campaign Page',
                log_description: 'Get Social Add New Facebook Campaign Page Visit',
                module_name: 'Get Social'
            },
            '/directseller/social/fbcampaignname': {
                log_type: 'web',
                log_name: 'Get Social Edit/Save/Schedule Facebook Campaign Page',
                log_description: 'Get Social Edit/Save/Schedule Facebook Campaign Page Visit',
                module_name: 'Get Social'
            },
            'update_single_post': {
                log_type: 'web',
                log_name: 'Get Social Update Single Post Page',
                log_description: 'Get Social Update Single Post Page Visit',
                module_name: 'Get Social'
            },
            'update_series_post': {
                log_type: 'web',
                log_name: 'Get Social Update Series Post Page',
                log_description: 'Get Social Update Series Post Page Visit',
                module_name: 'Get Social'
            },
            '/directseller/mydashboard': {
                log_type: 'web',
                log_name: 'Dashboard Page',
                log_description: 'Dashboard Page Visit',
                module_name: 'Dashboard'
            },
            '/directseller/messenger': {
                log_type: 'web',
                log_name: 'Messenger Page',
                log_description: 'Messenger Page Visit',
                module_name: 'Messenger'
            },
            '/directseller/shop-live': {
                log_type: 'web',
                log_name: 'Shop Live Events Listing Page',
                log_description: 'Shop Live Events Listing Page Visit',
                module_name: 'Shop Live'
            },
            '/directseller/shop-live/shop-media': {
                log_type: 'web',
                log_name: 'Shop Live Media Listing Page',
                log_description: 'Shop Live Media Listing Page Visit',
                module_name: 'Shop Live'
            },
            '/directseller/shop-live/shop-media-add?path=shop-media': {
                log_type: 'web',
                log_name: 'Shop Live Personal Media Add Page',
                log_description: 'Shop Live Personal Media Add Page Visit',
                module_name: 'Shop Live'
            },
            '/directseller/shop-live/shop-media-add': {
                log_type: 'web',
                log_name: 'Shop Live Personal Media Add Page',
                log_description: 'Shop Live Personal Media Add Page Visit',
                module_name: 'Shop Live'
            },
            '/directseller/shop-live/shop-media-setup': {
                log_type: 'web',
                log_name: 'Shop Live Event Media Setup Page',
                log_description: 'Shop Live Event Media Setup Page Visit',
                module_name: 'Shop Live'
            },
            'sl_dashboard': {
                log_type: 'web',
                log_name: 'Shop Live Dashboard Page',
                log_description: 'Shop Live Dashboard Page Visit',
                module_name: 'Shop Live'
            },
            'sl_event_validation': {
                log_type: 'web',
                log_name: 'Shop Live Event User Validation Page',
                log_description: 'Shop Live Event User Validation Page Visit',
                module_name: 'Shop Live'
            },
            'sl_interactive': {
                log_type: 'web',
                log_name: 'Shop Live Interactive Demo Conference Page',
                log_description: 'Shop Live Interactive Demo Conference Page Visit',
                module_name: 'Shop Live'
            },
            'sl_webinar': {
                log_type: 'web',
                log_name: 'Shop Live Webinar Demo Conference Page',
                log_description: 'Shop Live Webinar Demo Conference Page Visit',
                module_name: 'Shop Live'
            },
            '/directseller/replenishment': {
                log_type: 'web',
                log_name: 'Replenishment Campaign Landing Page',
                log_description: 'Replenishment Campaign Landing Page Visit',
                module_name: 'Replenishment'
            },
            '/directseller/replenishment/cart': {
                log_type: 'web',
                log_name: 'Replenishment Campaign Confirm Order Page',
                log_description: 'Replenishment Campaign Confirm Order Page Visit',
                module_name: 'Replenishment'
            },
            '/directseller/crosssell': {
                log_type: 'web',
                log_name: 'Cross Sell Campaign Landing Page',
                log_description: 'Cross Sell Campaign Landing Page Visit',
                module_name: 'Cross Sell'
            },
            '/directseller/crosssell/cart': {
                log_type: 'web',
                log_name: 'Cross Sell Campaign Confirm Order Page',
                log_description: 'Cross Sell Campaign Confirm Order Page Visit',
                module_name: 'Cross Sell'
            },
            '/guest/addinviteeguest': {
                log_type: 'web',
                log_name: 'Guest User Add Invitee Page',
                log_description: 'Guest User Add Invitee Page Visit',
                module_name: 'Guest'
            },
            '/guest/optinagreement': {
                log_type: 'web',
                log_name: 'Guest User Agreement Page',
                log_description: 'Guest User Agreement Page Visit',
                module_name: 'Guest'
            },
            '/guest/thanks': {
                log_type: 'web',
                log_name: 'Guest User Thank You Page',
                log_description: 'Guest User Thank You Page Visit',
                module_name: 'Guest'
            },
            'guest_rsvp': {
                log_type: 'web',
                log_name: 'Guest User RSVP Page',
                log_description: 'Guest User RSVP Page Visit',
                module_name: 'Guest'
            },
            '/freeguest/addinviteeguest': {
                log_type: 'web',
                log_name: 'Free Guest User Add Invitee Page',
                log_description: 'Free Guest User Add Invitee Page Visit',
                module_name: 'Free Guest'
            },
            '/freeguest/optinagreement': {
                log_type: 'web',
                log_name: 'Free Guest User Agreement Page',
                log_description: 'Free Guest User Agreement Page Visit',
                module_name: 'Free Guest'
            },
            '/freeguest/thanks': {
                log_type: 'web',
                log_name: 'Free Guest User Thank You Page',
                log_description: 'Free Guest User Thank You Page Visit',
                module_name: 'Free Guest'
            },
            'freeguest_rsvp': {
                log_type: 'web',
                log_name: 'Guest User RSVP Page',
                log_description: 'Guest User RSVP Page Visit',
                module_name: 'Guest'
            },
            '/host': {
                log_type: 'web',
                log_name: 'Host User Home Page',
                log_description: 'Host User Home Page Visit',
                module_name: 'Host'
            },
            '/host/addguest': {
                log_type: 'web',
                log_name: 'Host User Add Guest Page',
                log_description: 'Host User Add Guest Page Visit',
                module_name: 'Host'
            },
            '/host/optinagreement': {
                log_type: 'web',
                log_name: 'Host User Agreement Page',
                log_description: 'Host User Agreement Page Visit',
                module_name: 'Host'
            },
            '/host/viewguestlist': {
                log_type: 'web',
                log_name: 'Host User Invited Guests Listing Page',
                log_description: 'Host User Invited Guests Listing Page Visit',
                module_name: 'Host'
            },
            '/host/viewevent': {
                log_type: 'web',
                log_name: 'Host User Hosted Event Details Page',
                log_description: 'Host User Hosted Event Details Page Visit',
                module_name: 'Host'
            },
            'dashboard_profile_component': {
                log_type: 'web',
                log_name: 'Guest User Dashboard Page',
                log_description: 'Guest User Dashboard Page Visit',
                module_name: 'Guset Dashboard'
            },
        }

        public static getRouteFields(val) {
            return this.routes[val];
        }
}