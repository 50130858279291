import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BaseserviceService } from '../baseservice.service';

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  public baseUrl;
  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.baseUrl1;
  }

  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
  addNewLogs(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "api/viewLogs/directSeller", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  // addDSRouteLogsInDB(data) {
  //   var body = data;
  //   return this.http.post<any>(this.baseUrl + "api/viewLogs/directSeller/175", body, this.httpHeadersOptions())
  //      .pipe(
  //     map(data=>{
  //     if(!data.status)
  //     {
  //       if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
  //       {
  //         console.error(data.message);
  //         data.message="Something went wrong.Please try again."
  //       }
  //     }
  //     return data;
  //   }),
  //   catchError(this.handleError));
  // }


  addDSRouteLogsInDB(data) {
    var body = data;
    return this.http.post<any>(this.baseUrl + "api/viewLogs/directSeller/175", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  captureWebsiteDsLogs(data:any,companyId:any) {
    var body = data;
    return this.http.post<any>(this.baseUrl + `api/ds/capture/log/${companyId}`, body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  viewDSRouteLogs(company_id:any,dsId:any,limit:any,offset:any,search='') {
    return this.http.get<any>(this.baseUrl + `api/ds-log/${company_id}/${dsId}/${limit}/${offset}?search=${search}`,this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  viewReferralLogs(body:any){
    return this.http.post<any>(this.baseUrl + `api/dslog/referral_log-list`,body,this.httpHeadersOptions())
    .pipe(
   map(data=>{
   if(!data.status)
   {
     if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
     {
       console.error(data.message);
       data.message="Something went wrong.Please try again."
     }
   }
   return data;
 }),
 catchError(this.handleError));
  }
  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  };

}
