import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  public globalMessageCount=0;
  socket: any;
  favouriteUserList: any = new Subject<any>();
  chatHistoryList: any = new Subject<any>();
  chatApiUrl=environment.chatDSURI;
  unreadTextMessage: any = new Subject();
  constructor() {}


  setupSocketConnection() {

    this.socket = io.connect(this.chatApiUrl, {

      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 999,
      transports: ['websocket'],
      upgrade: false,
      query:{
        token:localStorage.getItem('accesToken')
      }
    }
    );
    this.socket.on('connect', () => {});
    // setInterval(()=>{
    //   this.setFavouriteList();
    // },10000)
    this.setFavouriteList();
  }
  setFavouriteList(){
    this.socket.emit('contactList');
  }
  chatHistory(data: any) {
    this.socket.emit('chatHistory', data);
  }
  getOnlineUserFavouriteList() {
    let observable = new Observable((observer:any) => {
      this.socket.on('onlineUser', (data: any) => {
        observer.next(data);
      });
    });
    return observable;
  }
  getAllSendMessage() {
    let observable = new Observable((observer:any) => {
      this.socket.on('sendMessage', (res: any) => {
        observer.next(res);
      });
    });
    return observable;
  }
  sendMessageToUser(data:any){
    this.socket.emit('sendMessage', data);
  }
  callUnreadMessage(data:any){
    this.socket.emit('unReadCount',data)
  }
  getUnreadMessage(){
    let observable = new Observable((observer:any) => {
      this.socket.on('msgCount', (res: any) => {
        observer.next(res);
      });
    });
    return observable;
  }
  updateReadCount(data:any){
    this.socket.emit('updateReadCount',data)
  }
  notifyOnchatclick(val:any){
    this.unreadTextMessage.next(val)
  }
  textmsgobserver(){
    return this.unreadTextMessage.asObservable();
  }
}
