<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/directseller/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/directseller/productCatalogue">Product Catalog</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">
        {{ headers }} Catalog
      </li>
    </ol>
  </nav>
</div>

<div class="container">
  <form class="form-horizontal mt-1" [formGroup]="themeForm">
    <div class="row">
      <div class="col-md-10 col-xl-12">
        <div class="card mt-1">
          <div class="card-body">
            <!-- <div>
              <label for="exampleForm2">Company Name</label>
              <select
                class="form-control formstyle"
              >
                <option
                  selected
                  disabled
                  [value]="currentUser.user_customer_id"
                >
                  {{ currentUser.customer_company_name }}
                </option>
              </select>
            </div> -->
            <div>
              <label for="exampleForm2">Business Domains <span style="color: red">*</span></label>
              <select formControlName="business_domain_id" class="form-control form-control-md"
                [ngClass]="{ 'is-invalid': submitted && f.business_domain_id.errors }" required>
                <!-- <option value="" selected hidden></option> -->
                <option [value]="domain?.id" *ngFor="let domain of domainList">{{domain?.domain_name}}</option>
              </select>
              <div *ngIf="submitted && f.business_domain_id.errors" class="invalid-feedback">
                <div *ngIf="f.business_domain_id.errors.required">
                  Business Domain Name is required
                </div>
              </div>
            </div>
            <div class="mt-1">
              <label for="exampleForm2">Product Name <span style="color: red">*</span></label>
              <input type="text" formControlName="productname" class="form-control form-control-md"
                [ngClass]="{ 'is-invalid': submitted && f.productname.errors }" required maxlength="150" appSpace />
              <div *ngIf="submitted && f.productname.errors" class="invalid-feedback">
                <div *ngIf="
                                f.productname.errors.required ||
                                f.productname.hasError('whitespace')
                              ">
                  Product Name is required
                </div>

                <div *ngIf="f.productname.errors?.maxlength">
                  You can enter only 150 characters
                </div>
              </div>
            </div>
            <div class="mt-1">
              <label for="exampleForm2"
                >Product Description
                <!-- <span style="color:red">*</span> -->
              </label>

              <textarea
                rows="3"
                formControlName="productdescription"
                class="form-control form-control-md textarea_height"
                maxlength="1990"
                [ngClass]="{
                  'is-invalid': submitted && f.productdescription.errors
                }"
              ></textarea>
            </div>

            <div class="mt-1">
              <label for="exampleForm2"
                >Product SKU
                <span style="color: red">*</span>
              </label>
              <input
                type="text"
                formControlName="productsku"
                class="form-control form-control-md"
                maxlength="100"
                [ngClass]="{ 'is-invalid': submitted && f.productsku.errors }"
              />
              <div
                *ngIf="submitted && f.productsku.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.productsku.errors.required">
                  Product Sku is required
                </div>
              </div>
            </div>
            <div class='mt-1' *ngIf="(headers == 'Add') || (headers == 'Edit' && !themObj?.stock_quantity )">
              <label for="exampleForm2"
                >Stock Quantity<span style="color: red">*</span></label
              >
              <input type="number" formControlName="stock_quantity" class="form-control form-control-md" name="stock" id="stock" #stockQuantity (input)="_checkStockInput(stockQuantity)"
              [ngClass]="{ 'is-invalid': submitted && f.stock_quantity.errors }">
              <div
              *ngIf="submitted && f.stock_quantity.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.stock_quantity.errors.required">
                Stock quantity is required
              </div>
            </div>
            </div>
            <div class="mt-1">
              <label for="exampleForm2"
                >Product Category <span style="color: red">*</span></label
              >
              <div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="customRadio12"
                    formControlName="selected_category"
                    [value]="true"
                    (change)="radioChange2('other')"
                  />
                  <label class="custom-control-label" for="customRadio12"
                    >Available Category</label
                  >
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="customRadio23"
                    formControlName="selected_category"
                    [value]="false"
                    (change)="radioChange2('other')"
                  />
                  <label class="custom-control-label" for="customRadio23"
                    >Others</label
                  >
                </div>
              </div>
              <div>
                <select *ngIf='selectedCategory' name="productcategory" formControlName='productcategory'
                class="form-control formstyle" [ngClass]="{
                 'is-invalid': submitted && f.productcategory.errors
               }">
               <option value="" selected disabled hidden> --Select Category-- </option>
               <option *ngFor="let category of productCategory" [value]="category.product_category | lowercase">{{category.product_category}}</option>
             </select>
             <input
             *ngIf="!selectedCategory"
             type="text"
             formControlName="productcategory"
             class="form-control form-control-md"
             maxlength="100"
             [ngClass]="{
               'is-invalid': submitted && f.productcategory.errors
             }"
           />
              <div
                *ngIf="submitted && f.productcategory.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.productcategory.errors.required">
                  Product category is required
                </div>
              </div>
              </div>
            </div>

            <div class="mt-1">
              <label for="exampleForm2"
                >Product Price </label
              >
              <input
                type="text"
                formControlName="productprice"
                class="form-control form-control-md"
                maxlength="10"
                [ngClass]="{ 'is-invalid': submitted && f.productprice.errors }"
              />

              <div
                *ngIf="submitted && f.productprice.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.productprice.errors.required">
                  Product Price is required
                </div>

                <div *ngIf="f.productprice.errors?.min">
                  Price should be greater then 0
                </div>
                <div *ngIf="f.productprice.errors?.pattern">
                  Please enter a valid price for the product e.g. 00.00 
                </div>
              </div>
            </div>

            <div class="mt-1">
              <label for="exampleForm2">Product Link <span style="color: red">*</span></label>
              <div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" id="online" value='online' formControlName='product_type' name='product_type'/>
                  <label class="custom-control-label" for="online">Online</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" class="custom-control-input" value='offline' id="offline" formControlName='product_type' (change)="radioChange2('product_link')"
                    name='product_type' />
                  <label class="custom-control-label" for="offline">Offline</label>
                </div>
              </div>
              <div class='mt-1' *ngIf="f.product_type.value=='online'">
                <input type="text" formControlName="productlink" class="form-control form-control-md" maxlength="150"
                  [ngClass]="{ 'is-invalid': submitted && f.productlink.errors }" />
                  <div *ngIf="submitted && f.productlink.hasError('validUrl')" class="invalid-feedback">
                    <div *ngIf="f.productlink.hasError('validUrl')">
                      Please enter valid url
                    </div>
                  </div>
                  <div *ngIf="submitted && f.productlink.errors" class="invalid-feedback">
                    <div *ngIf="f.productlink.errors.required">
                      Product Link is required
                    </div>
                  </div>
              </div>
              <div class='mt-1' *ngIf="f.product_type.value=='offline'">
                <div *ngIf="f.productlink?.value && getFileExtension(f.productlink?.value) === 'pdf'" class='mb-2'>
                  <a [href]='f.productlink?.value'>
                    <img
                    src="/assets/custom/images/pdf.png"
                    alt="marGo"
                  />
                  </a>
                </div>
                <input multiple #imageInput type="file" accept=".pdf"
                  (change)="processFile($event, imageInput, 'product_link')" />
                <div style="color: red; margin-left: 10px">
                  Allowed file Type (.pdf)
                </div>
              </div>
            </div>

            <div class="mt-1">
              <label for="exampleForm2"
                >Product Image<span style="color: red">*</span></label
              >
              <div *ngIf="theme_screenshot">
                <img
                  src="{{ theme_screenshot }}"
                  alt="marGo"
                  style="width: 200px"
                  (error)="setValue($event)"
                />
              </div>
              <div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="customRadio1"
                    formControlName="firstActive"
                    [value]="true"
                    (change)="radioChange('image1')"
                  />
                  <label class="custom-control-label" for="customRadio1"
                    >Url</label
                  >
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="customRadio2"
                    formControlName="firstActive"
                    [value]="false"
                    (change)="radioChange('image1')"
                  />
                  <label class="custom-control-label" for="customRadio2"
                    >Upload a file</label
                  >
                </div>
              </div>
              <div *ngIf="screenshot_type" class="mt-1">
                <input
                  type="text"
                  formControlName="productPicture"
                  class="form-control form-control-md mt-1"
                  placeholder="https://example.com/image(.jpg,.jpeg,.png)"
                  [ngClass]="{
                    'is-invalid': submitted && f.productPicture.errors
                  }"
                  maxlength="200"
                />
                <div
                  *ngIf="submitted && f.productPicture.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.productPicture.errors.required">
                    Product Url is required
                  </div>
                </div>

                <!-- <div *ngIf="submitted && f.productPicture.hasError('validUrl')"
                                  class="invalid-feedback">
                                  <div *ngIf="f.productPicture.hasError('validUrl')">
                                      Please enter valid url</div>
                              </div> -->
              </div>
              <div
                *ngIf="!screenshot_type && headers == 'Add'"
                class="mt-1"
                [ngClass]="status ? 'displayblock' : 'displaynone'"
              >
                <input
                  multiple
                  #imageInput
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  (change)="processFile($event, imageInput, 'image1')"
                />
                <div style="color: red; margin-left: 10px">
                  Allowed file Types(.png, .jpg, .jpeg, .gif)
                </div>
              </div>

              <div *ngIf="!screenshot_type && headers == 'Edit'" class="mt-1">
                <input
                  multiple
                  #imageInput
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  (change)="processFile($event, imageInput,'image1')"
                />
                <div style="color: red; margin-left: 10px">
                  Allowed file Types(.png, .jpg, .jpeg, .gif)
                </div>
              </div>
            </div>
            <!--============PRODUCT IMAGE 2===============-->
            <div class="mt-1">
              <label for="exampleForm2"
                >Product Image 2</label
              >
              <div *ngIf="theme_screenshot2" class='d-flex'>
                <img
                  src="{{ theme_screenshot2 }}"
                  alt="marGo"
                  style="width: 200px"
                  (error)="setValue($event)"
                /><span class="fa fa-trash ml-2" *ngIf="headers=='Edit' && !isImageUpload2"
                placement="bottom" title='Delete Image' (click)="deleteImage('product_image2','is_image2')"></span>
              </div>
              <div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="customRadio3"
                    name='secondActive'
                    [value]="true"
                    (change)="radioChange('image2')"
                    formControlName="secondActive"
                  />
                  <label class="custom-control-label" for="customRadio3"
                    >Url</label
                  >
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="customRadio4"
                    name='secondActive'
                    [value]="false"
                    (change)="radioChange('image2')"
                    formControlName="secondActive"
                  />
                  <label class="custom-control-label" for="customRadio4"
                    >Upload a file</label
                  >
                </div>
              </div>
              <div *ngIf="screenshot_type2" class="mt-1">
                <input
                  type="text"
                  formControlName="productPicture2"
                  class="form-control form-control-md mt-1"
                  placeholder="https://example.com/image(.jpg,.jpeg,.png)"
                  [ngClass]="{
                    'is-invalid': submitted && f.productPicture2.errors
                  }"
                  maxlength="200"
                />
                <!-- <div
                  *ngIf="submitted && f.productPicture2.errors"
                  class="invalid-feedback"
                >
                  <div *ngIf="f.productPicture.errors.required">
                    Product Url is required
                  </div>
                </div> -->
              </div>
              <div
                *ngIf="!screenshot_type2 && headers == 'Add'"
                class="mt-1"
                [ngClass]="status ? 'displayblock' : 'displaynone'"
              >
                <input
                  multiple
                  #imageInput
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  (change)="processFile($event, imageInput, 'image2')"
                />
                <div style="color: red; margin-left: 10px">
                  Allowed file Types(.png, .jpg, .jpeg, .gif)
                </div>
              </div>

              <div *ngIf="!screenshot_type2 && headers == 'Edit'" class="mt-1">
                <input
                  multiple
                  #imageInput
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  (change)="processFile($event, imageInput,'image2')"
                />
                <div style="color: red; margin-left: 10px">
                  Allowed file Types(.png, .jpg, .jpeg, .gif)
                </div>
              </div>
            </div>
            <!--============PRODUCT IMAGE 3===============-->
            <div class="mt-1">
              <label for="exampleForm2"
                >Product Image 3</label
              >
              <div *ngIf="theme_screenshot3" class='d-flex'>
                <img
                  src="{{ theme_screenshot3 }}"
                  alt="marGo"
                  style="width: 200px"
                  (error)="setValue($event)"
                />
                <span class="fa fa-trash ml-2" *ngIf="headers=='Edit' && !isImageUpload3"
                placement="bottom" title='Delete Image' (click)="deleteImage('product_image3','is_image3')"></span>
              </div>
              <div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="customRadio5"
                    name='thirdActive'
                    [value]="true"
                    (change)="radioChange('image3')"
                    formControlName="thirdActive"
                  />
                  <label class="custom-control-label" for="customRadio5"
                    >Url</label
                  >
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="customRadio6"
                    name='thirdActive'
                    [value]="false"
                    (change)="radioChange('image3')"
                    formControlName="thirdActive"
                  />
                  <label class="custom-control-label" for="customRadio6"
                    >Upload a file</label
                  >
                </div>
              </div>
              <div *ngIf="screenshot_type3" class="mt-1">
                <input
                  type="text"
                  formControlName="productPicture3"
                  class="form-control form-control-md mt-1"
                  placeholder="https://example.com/image(.jpg,.jpeg,.png)"
                  [ngClass]="{
                    'is-invalid': submitted && f.productPicture3.errors
                  }"
                  maxlength="200"
                />
              </div>
              <div
                *ngIf="!screenshot_type3 && headers == 'Add'"
                class="mt-1"
                [ngClass]="status ? 'displayblock' : 'displaynone'"
              >
                <input
                  multiple
                  #imageInput
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  (change)="processFile($event, imageInput, 'image3')"
                />
                <div style="color: red; margin-left: 10px">
                  Allowed file Types(.png, .jpg, .jpeg, .gif)
                </div>
              </div>

              <div *ngIf="!screenshot_type3 && headers == 'Edit'" class="mt-1">
                <input
                  multiple
                  #imageInput
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  (change)="processFile($event, imageInput,'image3')"
                />
                <div style="color: red; margin-left: 10px">
                  Allowed file Types(.png, .jpg, .jpeg, .gif)
                </div>
              </div>
            </div>
            <!--============PRODUCT IMAGE 4===============-->
            <div class="mt-1">
              <label for="exampleForm2"
                >Product Image 4</label
              >
              <div *ngIf="theme_screenshot4" class='d-flex'>
                <img
                  src="{{ theme_screenshot4 }}"
                  alt="marGo"
                  style="width: 200px"
                  (error)="setValue($event)"
                />
                <span class="fa fa-trash ml-2" *ngIf="headers=='Edit' && !isImageUpload4"
                placement="bottom" title='Delete Image' (click)="deleteImage('product_image4','is_image4')"></span>
              </div>
              <div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="customRadio7"
                    name='fourthActive'
                    [value]="true"
                    (change)="radioChange('image4')"
                    formControlName="fourthActive"
                  />
                  <label class="custom-control-label" for="customRadio7"
                    >Url</label
                  >
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="customRadio8"
                    name='fourthActive'
                    [value]="false"
                    (change)="radioChange('image4')"
                    formControlName="fourthActive"
                  />
                  <label class="custom-control-label" for="customRadio8"
                    >Upload a file</label
                  >
                </div>
              </div>
              <div *ngIf="screenshot_type4" class="mt-1">
                <input
                  type="text"
                  formControlName="productPicture4"
                  class="form-control form-control-md mt-1"
                  placeholder="https://example.com/image(.jpg,.jpeg,.png)"
                  [ngClass]="{
                    'is-invalid': submitted && f.productPicture4.errors
                  }"
                  maxlength="200"
                />
              </div>
              <div
                *ngIf="!screenshot_type4 && headers == 'Add'"
                class="mt-1"
                [ngClass]="status ? 'displayblock' : 'displaynone'"
              >
                <input
                  multiple
                  #imageInput
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  (change)="processFile($event, imageInput, 'image4')"
                />
                <div style="color: red; margin-left: 10px">
                  Allowed file Types(.png, .jpg, .jpeg, .gif)
                </div>
              </div>

              <div *ngIf="!screenshot_type4 && headers == 'Edit'" class="mt-1">
                <input
                  multiple
                  #imageInput
                  type="file"
                  accept=".png, .jpg, .jpeg, .gif"
                  (change)="processFile($event, imageInput,'image4')"
                />
                <div style="color: red; margin-left: 10px">
                  Allowed file Types(.png, .jpg, .jpeg, .gif)
                </div>
              </div>
            </div>
            <!--=============Product Video 1==============-->
            <div class="mt-1">
              <label for="exampleForm2"
                >Product Video</label
              >
              <div *ngIf="theme_screenshot5 && !isValidYouTubeUrl(theme_screenshot5)" class='d-flex'>
                <!-- <img
                  src="{{ theme_screenshot5 }}"
                  alt="marGo"
                  style="width: 200px"
                  (error)="setValue($event)"
                /> -->
                <video width="320" height="240" controls src="{{ theme_screenshot5 }}"></video>
              </div>
              <div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="customRadio9"
                    name='fifthActive'
                    [value]="true"
                    (change)="radioChange('video')"
                    formControlName="fifthActive"
                  />
                  <label class="custom-control-label" for="customRadio9"
                    >Youtube Url</label
                  >
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    class="custom-control-input"
                    id="customRadio10"
                    name='fifthActive'
                    [value]="false"
                    (change)="radioChange('video')"
                    formControlName="fifthActive"
                  />
                  <label class="custom-control-label" for="customRadio10"
                    >Upload a video</label
                  >
                </div>
              </div>
              <div *ngIf="screenshot_type5" class="mt-1">
                <input
                  type="text"
                  formControlName="product_video"
                  class="form-control form-control-md mt-1"
                  placeholder="https://www.youtube.com/watch?v=yourVideoID"
                  [ngClass]="{
                    'is-invalid': submitted && f.product_video.errors
                  }"
                  maxlength="200"
                />
                <div *ngIf='f.product_video.value && !isValidYouTubeUrl(f.product_video.value)' style="color: red; margin-left: 10px">
                  Please enter correct youtube url
                </div>
              </div>
              <div
                *ngIf="!screenshot_type5 && headers == 'Add'"
                class="mt-1"
                [ngClass]="status ? 'displayblock' : 'displaynone'"
              >
                <input
                  #imageInput
                  type="file"
                  accept=".mp4"
                  (change)="processVideoFile($event, imageInput, 'video')"
                />
                <div style="color: red; margin-left: 10px">
                  Allowed file Types(.mp4)
                </div>
              </div>

              <div *ngIf="!screenshot_type5 && headers == 'Edit'" class="mt-1">
                <input
                  #imageInput
                  type="file"
                  accept=".mp4"
                  (change)="processVideoFile($event, imageInput,'video')"
                />
                <div style="color: red; margin-left: 10px">
                  Allowed file Types(.mp4)
                </div>
              </div>
            </div>
            <!-- <div class='mt-1'>
              <label for="exampleForm2"
                >Features</label
              >
              <textarea
              rows='3'
                formControlName="product_metadata1"
                class="form-control form-control-md"
              ></textarea>
            </div> -->
            <!-- <div class='mt-1'>
              <label for="exampleForm2"
                >Benefits</label
              >
              <textarea
              rows='3'
                formControlName="product_metadata2"
                class="form-control form-control-md"
              ></textarea>
            </div> -->
            <!-- <div class='mt-1'>
              <label for="exampleForm2"
                >How to Use </label
              >
              <textarea
              rows='3'
                type="text"
                formControlName="product_metadata3"
                class="form-control form-control-md"
              ></textarea>
            </div> -->
            <!-- <div class='mt-1'>
              <label for="exampleForm2"
                >Ingredients</label
              >
              <textarea
                rows='3'
                formControlName="product_metadata4"
                class="form-control form-control-md"
              ></textarea>
            </div> -->
            <div class="mt-1">
              <label for="exampleForm2">External Website Product ID </label>
              <input type="text" formControlName="website_product_id"
                  class="form-control form-control-md">
          </div>
            <div class='mt-1' *ngIf="headers == 'Edit'">
              <label for="exampleForm2">Status</label>
              <mat-slide-toggle class="my-slider ml-2" [checked]="selectedProduct?.isactive" formControlName="isactive"
              ngbTooltip="Update Status">
            </mat-slide-toggle>
            </div>
            <div class="mt-1">
              <label for="exampleForm2"
                >Is Bestseller</label
              >
              <mat-slide-toggle class="my-slider ml-2" [checked]="themObj?.best_seller" formControlName="best_seller"
              ngbTooltip="Update best seller status">
            </mat-slide-toggle>
              <div
                *ngIf="submitted && f.best_seller.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.best_seller.errors.required">
                  best seller update is required
                </div>

              </div>
            </div>

            <!-- <div class="mt-1">
              <label for="exampleForm2">No of days for Cross-sell</label>
              <input
                type="number"
                formControlName="no_of_days_cross"
                class="form-control form-control-md"
                [ngClass]="{
                  'is-invalid': submitted && f.no_of_days_cross.errors
                }"
              />

              <div
                *ngIf="submitted && f.no_of_days_cross.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.no_of_days_cross.errors?.min">
                  Please enter valid number
                </div>
              </div>
            </div>

            <div class="mt-1">
              <label for="exampleForm2">No of days for replenishment </label>
              <input
                type="number"
                formControlName="no_of_days_replenishment"
                class="form-control form-control-md"
                [ngClass]="{
                  'is-invalid': submitted && f.no_of_days_replenishment.errors
                }"
              />

              <div
                *ngIf="submitted && f.no_of_days_replenishment.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.no_of_days_replenishment.errors?.min">
                  Please enter valid number
                </div>
              </div>
            </div> -->

            <!-- <div class="mt-1">
                          <label for="exampleForm2">Product Fancy Image</label>
                          <div [ngClass]="productImage2 ? 'displayblock' :'displaynone' ">
                              <img src="{{productImage2}}" alt="Margo" style="
                      width: 200px;" (error)="setValue($event)">
                          </div>
                          <div>
                              <div class="custom-control custom-radio custom-control-inline">
                                  <input type="radio" class="custom-control-input" id="customRadio3"
                                      formControlName="firstActive2" [value]="true" (change)='radioChange("image2")'>
                                  <label class="custom-control-label" for="customRadio3">Url</label>
                              </div>
                              <div class="custom-control custom-radio custom-control-inline">
                                  <input type="radio" class="custom-control-input" id="customRadio4"
                                      formControlName="firstActive2" [value]="false" (change)='radioChange("image2")'>
                                  <label class="custom-control-label" for="customRadio4">Upload a file</label>
                              </div>
                          </div>
                          <div *ngIf="screenshot_type2" class="mt-1">
                              <input type="text" formControlName="productPicture2"
                                  class="form-control form-control-md mt-1"
                                  placeholder="https://example.com/image(.jpg,.jpeg,.png)">

                          </div>
                          <div *ngIf="!screenshot_type2" class="mt-1">
                              <input #imageInput2 type="file" accept=".png, .jpg, .jpeg"
                                  (change)="processFile($event,imageInput2, 'image2')">
                              <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg)</div>
                          </div>
                      </div>

                      <div class="mt-1">
                          <label for="exampleForm2">Product Fancy Image-2</label>
                          <div [ngClass]="productImage3 ? 'displayblock' :'displaynone' ">
                              <img src="{{productImage3}}" alt="Margo" style="width: 200px;"
                                  (error)="setValue($event)">
                          </div>

                          <div>
                              <div class="custom-control custom-radio custom-control-inline">
                                  <input type="radio" class="custom-control-input" id="customRadio5"
                                      formControlName="firstActive3" [value]="true" (change)='radioChange("image3")'>
                                  <label class="custom-control-label" for="customRadio5">Url</label>
                              </div>
                              <div class="custom-control custom-radio custom-control-inline">
                                  <input type="radio" class="custom-control-input" id="customRadio6"
                                      formControlName="firstActive3" [value]="false" (change)='radioChange("image3")'>
                                  <label class="custom-control-label" for="customRadio6">Upload a file</label>
                              </div>
                          </div>
                          <div *ngIf="screenshot_type3" class="mt-1">
                              <input type="text" formControlName="productPicture3"
                                  class="form-control form-control-md mt-1"
                                  placeholder="https://example.com/image(.jpg,.jpeg,.png)">

                          </div>
                          <div *ngIf="!screenshot_type3" class="mt-1">
                              <input #imageInput3 type="file" accept=".png, .jpg, .jpeg"
                                  (change)="processFile($event,imageInput3, 'image3')">
                              <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg)</div>
                          </div>
                      </div>

                      <div class="mt-1">
                          <label for="exampleForm2">Product Banner Image</label>
                          <div [ngClass]="productImage4 ? 'displayblock' :'displaynone' ">
                              <img src="{{productImage4}}" alt="Margo" style="
                      width: 200px;" (error)="setValue($event)">
                          </div>

                          <div>
                              <div class="custom-control custom-radio custom-control-inline">
                                  <input type="radio" class="custom-control-input" id="customRadio7"
                                      formControlName="firstActive4" [value]="true" (change)='radioChange("image4")'>
                                  <label class="custom-control-label" for="customRadio7">Url</label>
                              </div>
                              <div class="custom-control custom-radio custom-control-inline">
                                  <input type="radio" class="custom-control-input" id="customRadio8"
                                      formControlName="firstActive4" [value]="false" (change)='radioChange("image4")'>
                                  <label class="custom-control-label" for="customRadio8">Upload a file</label>
                              </div>
                          </div>
                          <div *ngIf="screenshot_type4" class="mt-1">
                              <input type="text" formControlName="productPicture4"
                                  class="form-control form-control-md mt-1"
                                  placeholder="https://example.com/image(.jpg,.jpeg,.png)">

                          </div>
                          <div *ngIf="!screenshot_type4" class="mt-1">
                              <input #imageInput4 type="file" accept=".png, .jpg, .jpeg"
                                  (change)="processFile($event,imageInput4, 'image4')">
                              <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg)</div>
                          </div>
                      </div>

                      <div class="mt-1">
                          <label for="exampleForm2">Product Thumb Image</label>
                          <div [ngClass]="productImage5 ? 'displayblock' :'displaynone' ">
                              <img src="{{productImage5}}" alt="Margo" style="
                      width: 200px;" (error)="setValue($event)">
                          </div>

                          <div>
                              <div class="custom-control custom-radio custom-control-inline">
                                  <input type="radio" class="custom-control-input" id="customRadio9"
                                      formControlName="firstActive5" [value]="true" (change)='radioChange("image5")'>
                                  <label class="custom-control-label" for="customRadio9">Url</label>
                              </div>
                              <div class="custom-control custom-radio custom-control-inline">
                                  <input type="radio" class="custom-control-input" id="customRadio10"
                                      formControlName="firstActive5" [value]="false" (change)='radioChange("image5")'>
                                  <label class="custom-control-label" for="customRadio10">Upload a file</label>
                              </div>
                          </div>
                          <div *ngIf="screenshot_type5" class="mt-1">
                              <input type="text" formControlName="productPicture5"
                                  class="form-control form-control-md mt-1"
                                  placeholder="https://example.com/image(.jpg,.jpeg,.png)">

                          </div>
                          <div *ngIf="!screenshot_type5" class="mt-1">
                              <input #imageInput5 type="file" accept=".png, .jpg, .jpeg"
                                  (change)="processFile($event,imageInput5, 'image5')">
                              <div style="color: red;margin-left:10px ;">Allowed file Types(.png, .jpg, .jpeg)</div>
                          </div>
                      </div> -->
          </div>

          <div class="card-footer text-center mt-1">
            <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
              <button type="submit" class="btn btn-round btn-margo-action" (click)="addNewProduct()">
                Submit
              </button>
              <button type="button" class="btn btn-margo-cancel btn-round" (click)="goBack()" >Cancel</button>
            </div>
            <div class="my_class text-center" [ngClass]="status ? 'displaynone' : 'displayblock'">
              <button type="button" class="btn btn-round btn-margo-action" (click)="updateProduct()">
                Update
              </button>
              <button type="button" class="btn btn-margo-cancel btn-round" (click)="goBack()" >Cancel</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </form>
</div>
