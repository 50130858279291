<div class="container mt-1 mt-md-0">
  <div class="row">
    <div class="col-md-12 mb-1 mt-1 mt-md-0">
      <h4 class="font-weight-bold" *ngIf="isAllowed">Search for your Prospect/Customer/Host:</h4>
      <h4 class="font-weight-bold" *ngIf="!isAllowed"> Not allowed to post in web Browser</h4>
    </div>
  </div>
</div>

<div class="container"  *ngIf="isAllowed">
<div class="row">
  <div class="col-md-6">
    <div class="card">
      <div class="card-header">
        <div class="d-inline-block w-100">
          <a *ngIf="contacttypename!=='teammember'  && !hosttype" [routerLink]="inputdata.addurl" >{{addtext | uppercase}}</a>
       
    <!--   <a  class="float-right" (click)="getContactsBookMarkList()">BOOKMARK</a> -->
    </div>       
             
      <div class="card-content mt-1">
        <section class="srchMain">
          <div class="srcInpt">
            <input class="form-control" [formControl]="queryContactField" type="text"  placeholder="Enter contact name"
              autofocus />        
          </div>        
        </section>
      </div>
    </div>    
      
    </div>



  </div>
  <div class="col-md-6 mrg-border-left">
    
    <div *ngIf = "!showContactDetail && contacttypename!=='teammember' && contacttypename!=='host'">
     
      <h4 class="font-weight-bold mb-1">Recent contacts:</h4>   
      <span *ngIf="recentOrderContacts && recentOrderContacts.length==0">No Record Found.</span>  
              <a href="#" *ngFor="let result of recentOrderContacts ; let i = index" class="artist-name" [ngClass]="{'active': selectedContact==result.contactid}" (click)="selectContact(result.contactid,i,'recent')" >
                {{result.contactname}}<br /></a>             
          
        <ng-template #rt let-r="result" let-t="query">
          <li class="mw-100">{{ r}}</li>
        </ng-template>
        
    </div>
    
    <div *ngIf = "!showContactDetail && bookmarkresults  ">
     
      <h4 class="font-weight-bold mb-1">Bookmark contacts:</h4>   
      <span *ngIf="bookmarkresults && bookmarkresults.length==0">No Record Found.</span>  
              <a href="#" class="clearfix mt-1" *ngFor="let result of bookmarkresults ; let i = index" class="artist-name" [ngClass]="{'active': selectedContact==result.contactid}" (click)="selectContact(result.contactid,i,'bookmark')" >
                {{result.contactname}}<br /></a> 
                <a *ngIf="bookmarkresults && bookmarkresults.length!==0" href="javascript:void(0)" routerLink="/directseller/bookmarklist">View All Bookmark</a>
        
    </div>
    
  </div> 



</div>

<div class="row">
  <div class="col-12">
    <h5 class="font-weight-bold mb-1">Contacts</h5>


        <div class="scroller">
        <span *ngIf="results && results.length==0">No results available.</span>
        
        <!-- <ul class="filtMain">
          <li *ngFor="let result of results; let i = index"  class="filter-select-list" (click)="selectContact(result.contactid,i)" [ngClass]="{'active': prospectid===result.contactid}" >
            <a style="cursor: pointer;"  class="artist-name" >
              {{result.contactname}}</a>
              </li>          
        </ul> -->
        <div class="tblPrt">
        <table *ngIf="results  && results.length>0" class=" bdrStCustom" width="100%">
          <tr>
            <th>Contact Name</th>
            <th>Contact Email</th>
           <!--  <th>Contact Phone</th> -->
            <th>Contact Type</th>           
          </tr>
          <tr *ngFor="let result of results; let i = index" >
            <td> <a  href="javascript:void(0)" (click)="selectContact(result.contactid,result.contacttypeid)">{{result.contactname}}</a></td>
            <td>{{result.contact_email}}</td>
            
           <!--  <td>{{result.contact_phone}}</td> -->
            <td *ngIf="result.contacttypeid===3">
              Prospect <ng-container *ngIf="result.agreetohost">/ Host</ng-container></td>      
            <td *ngIf="result.contacttypeid===2">Customer
              <ng-container *ngIf="result.agreetohost">/ Host</ng-container>
            </td>  
            <td *ngIf="result.contacttypeid===1">Team Member </td>  
          </tr>
        </table>
      </div>
      </div>
        
     <!--  <ng-template #rt let-r="result" let-t="query">
        <li class="mw-100" ng-class="{ 'active': active=='one'}">{{ r }}</li>
      </ng-template> -->
      
      </div>

</div>

</div>