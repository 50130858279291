import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ReskinningService } from "src/app/_services/reskinning.service";

@Component({
  selector: "app-balance-modal",
  standalone: true,
  imports: [CommonModule],
  template: `
  <div class="scroller">
  <div class="wholePop filtMain">
      <div class="modal-header dialHead w-100">

          <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" style="height: 100px;" />

          <button type="button" class="close" aria-label="Close" (click)="modal.close('Cross click')">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body text-center">
          <h4 class="text-center font-weight-bold">You don't have enough credits.</h4>
          <p>Available Points: {{dsBalance | number : '1.0-0'}}</p>

      </div>
      <div class="modal-footer text-center d-block border-0">
      <button type="button" class="btn btn-margo-action btn-round text-uppercase mr-1" (click)="navigateTopUp()">Add Top-Up</button>
      <button class="btn btn-round btn-margo-cancel" (click)="modal.close('Cross click')">Close</button>
      </div>
  </div>
</div>
  `,
})
export class balanceModalComponent {
  rskmodellogo = this.ReskinningService.rskModelLogo;
  rskmodellogoFlag = this.ReskinningService.rskModelLogoFlag;
  @Input() dsBalance: any;


  constructor(
    public modal: NgbActiveModal,
    private ReskinningService: ReskinningService,
    private router: Router
  ) {}
  navigateTopUp(){
    this.router.navigateByUrl('/directseller/myaccount/addfunds');
    this.modal.dismiss();
  }
}
