import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseserviceService {
  public baseUrl1;
  public sliveURL;
  public sliveURL1tomany;
  public manyToManyInstanceId;
  public oneToManyInstanceId;
  private supportIconEnable=new BehaviorSubject<boolean>(true);
  triggerSupportEvent = this.supportIconEnable.asObservable();
  constructor(public http: HttpClient) {
    let self = this;
    self.baseUrl1 =environment.apiUrl;
    self.sliveURL = environment.sliveURL;
    self.sliveURL1tomany = environment.sliveURLOneToMany;
    self.manyToManyInstanceId = environment.manyToManyInstanceId;
    self.oneToManyInstanceId = environment.oneToManyInstanceId;
   
  }
  public httpHeadersOptions() {
    let token = localStorage.getItem("accesToken");
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    if (token) {
      httpOptions.headers = httpOptions.headers.set('x-auth', token);
    }
    return httpOptions;
  }

  toggleSupportIcon(val: boolean) {
    this.supportIconEnable.next(val);
  }

}
