import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../firstadmin/base.component';

@Component({
  selector: 'app-notauthorised',
  templateUrl: './notauthorised.component.html',
  styles: []
})
export class NotauthorisedComponent extends BaseComponent implements OnInit {

  constructor() { 
    super();
    if(this.loginUserData)
    {
    var name = "Restricted Page View by " +  this.loginUserData.user_fname + " " + this.loginUserData.user_lname;
    this.addLogData("Restricted Page View",name);
    }
  }
  goBack() {
    this.location.back();
  }  
  ngOnInit() {
  }

}
