import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError, map, mergeMap, flatMap, toArray } from 'rxjs/operators';
import { throwError, forkJoin, from } from 'rxjs';
import { BaseserviceService } from '../baseservice.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { balanceModalComponent } from '../../shared/dynamicModal/balance-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  public baseUrl;
  // @ViewChild('balanceModalComponent',{static:true}) balanceModalComponent:ElementRef;
  constructor(private http: HttpClient, public baseService: BaseserviceService,
    private modalService:NgbModal) {
    // this.baseUrl = baseService.baseUrl1 + "api/"
    this.baseUrl = baseService.baseUrl1 + "api/"
  }
  // getClient() {
  //   var body = {};
  //   return this.http.get<any>(this.baseUrl + "company/30/0", this.httpHeadersOptions())
  //     .pipe(catchError(this.handleError));
  // }
  // addingCompany(value) {
  //   var body = value;
  //   return this.http.post<any>(this.baseUrl + "company", body, this.httpHeadersOptions())
  //     .pipe(catchError(this.handleError));
  // }
  saveCardDetails(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "ds/addCardDetails", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getCardDetails() {
    var body = { };
    return this.http.get<any>(this.baseUrl + `ds/getPaymentDetails`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  /* code added by Raj*/

  getCompanyProductList(id) {
    var body = { };
    return this.http.get<any>(this.baseUrl + `productCatalogueProductCategoryByCompanyId/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  };

  /**method for create tranaction data */

  createTransactionRequest(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "ds/createTransactionRequest", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  };

  /**method for get tranaction data */

  getTransactionListForCustomer() {
    var body = { };
    return this.http.get<any>(this.baseUrl + `ds/getTransactionListForCustomer`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  /**method for delete transaction data */

  deleteCustomerPaymentProfile(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "ds/deleteCustomerPaymentProfile", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  };


  /**method for get customer payment profile */

  getCustomerPaymentProfile(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + `ds/getCustomerPaymentProfile`, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  /** update payment profile */

  updateCustomerPaymentProfile(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "ds/updateCustomerPaymentProfile", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getMyPurchasesDetails(limit, offset) {
    return this.http.get<any>(this.baseUrl + `ds/getDSPurchaseList/${limit}/${offset}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }




  getCustomerPurchasesDetails(search,page, from, to, limit, offset) {
    return this.http.get<any>(this.baseUrl + `ds/getDSAllContactPurchaseList?from=` + from + '&to=' + to + '&page=' + page + '&limit=' + limit + '&offset=' + offset+ '&search=' + search, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }



  getWalletBalanceDetails(companyId, userId) {
    return this.http.get<any>(this.baseUrl + `ewallet/dswalletbalance/${companyId}/${userId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  getCompanyDetails(companyId) {

    return this.http.get<any>(this.baseUrl + `company/${companyId}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  addFundsRequest(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "ewallet/addfund", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  };


  walletPlaceOrderRequest(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "ewallet/placeorder", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  };

  getTranscationHistory(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "ewallet/transcationhistory", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  };

  getPointTranscationHistory(limit:any,offset:any,serach:any,fromDate:any,toDate:any,transactionType:any,status:any) {
    return this.http.get<any>(this.baseUrl + `ds/subscription/transaction-point/${limit}/${offset}?search=${serach}&fromDate=${fromDate}&toDate=${toDate}&transactionType=${transactionType}&status=${status}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  };
  getDsTransactionPointForChart(fromDate:any,toDate:any) {
    return this.http.get<any>(this.baseUrl + `ds/subscription/getDsTransactionPointForChart?fromDate=${fromDate}&toDate=${toDate}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  };
  getDsTransactionTotalExpense(fromDate:any,toDate:any) {
    return this.http.get<any>(this.baseUrl + `ds/subscription/getDsTransactionTotalExpense?fromDate=${fromDate}&toDate=${toDate}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  };
  getTranscationHistoryNew(limit:any,offset:any) {
    return this.http.get<any>(this.baseUrl + `ds/subscription/transaction/${limit}/${offset}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  };
  getDsBalance() {
    return this.http.get<any>(this.baseUrl + `ds/point/balance`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  };
  getCardDetailsNew() {
    var body = { };
    return this.http.get<any>(this.baseUrl + `ds/subscription/customerProfileId`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addTopupBalance(body:any) {

    return this.http.post<any>(this.baseUrl + `ds/subscription/topup`,body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addPaymentMethodDs(body:any) {
    return this.http.post<any>(this.baseUrl + `ds/subscription/addDsCard`,body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  deleteSavedCardForDs(customer_profileid:any,customer_payment_profileid:any) {
    return this.http.delete<any>(this.baseUrl + `ds/subscription/delete-save-card/${customer_profileid}/${customer_payment_profileid}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  //Import purchase data
  importPurchaseData(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "import-purchasedata/csv-data", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  };
  //Get Required Field
  getRequiredFieldsDropdrown() {
    return this.http.get<any>(this.baseUrl + "import-purchasedata/purchase-required-data", this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  };
    //Save map data
    saveMappedPurchaseData(value) {
      var body = value;
      return this.http.post<any>(this.baseUrl + "import-purchasedata/save", body, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    };
    //All Logs Detail
    getLogsDetails(value) {
      var body = value;
      body.log_type = "Web";
      return this.http.post<any>(this.baseUrl + "dslog/log-list", body, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    };
    //All Events Logs Detail
    getEventLogsDetails(value:any) {
        var body = value;
        return this.http.post<any>(this.baseUrl + "dslog/event-log-list", body, this.httpHeadersOptions())
          .pipe(
            map(data => {
              if (!data.status) {
                if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                  console.error(data.message);
                  data.message = "Something went wrong.Please try again."
                }
              }
              return data;
            }),
            catchError(this.handleError));
    };
    getPurchaseLogs(value:any) {
      var body = value;
      return this.http.post<any>(this.baseUrl + "import-purchasedata/log-list", body, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
  };

  getOrders(value:any) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "order/list", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
};
  updateOrderStatus(value: any) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "order/update-status", body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  };
  getPointsDeduction(body:any) {
    return this.http.post<any>(this.baseUrl + `ds/point/expenses`,body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  };
  replenishmentCrossellOrderRequest(body:any) {
    return this.http.post<any>(this.baseUrl + `ewallet/place-order`,body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  subscriptionEnableDisable(body:any) {
    return this.http.post<any>(this.baseUrl + `ds/subscription/update-subscription-status`,body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  openBalancePoput(balance:any){
    const modalRef = this.modalService.open(balanceModalComponent,{
      windowClass: 'action-modal',
      backdrop: 'static',
      keyboard: false,
    })
    modalRef.componentInstance.dsBalance = balance;
    return modalRef.result;
  }
}


