import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule, NgbCarouselConfig, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { BreadcrumbModule } from '../_layout/breadcrumb/breadcrumb.module';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// spinner
import { NgxSpinnerModule } from 'ngx-spinner';
import {CoreModule} from '../core/core.module';
import { PartialsModule } from '../content/partials/partials.module';
import { NgxPaginationModule } from "ngx-pagination";
import { OrderModule } from "ngx-order-pipe";
import { MatSelectSearchModule } from './mat-select-search/mat-select-search.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxDataTableModule } from 'projects/ngx-data-table/src/public_api';
import { SafeHTMLtagsPipe } from './pipes/safe-htmltags-pipe.pipe';
import { FormatPhoneNumber } from './pipes/formatPhoneNumber.pipe';
import { GroupByPipe } from './pipes/group-by.pipe';
import { FilterUSStatesPipe } from './pipes/us-states-filter.pipe';
import { ErrorInterceptor } from '../_interceptors/error.interceptor';
import { ArticleDetailComponent } from './article-detail/article-detail.component';
import { CreateContactComponent } from './Contact/create-contact/create-contact.component';
import { SearchContactComponent } from './Contact/search-contact/search-contact.component';
import { SearchAllContactComponent } from './Contact/search-contact/searchall-contact.component';
import { ViewcontactComponent } from './Contact/viewcontact/viewcontact.component';
import { UpdatecontactComponent } from './Contact/updatecontact/updatecontact.component';
import { OptAgreementComponent } from './Contact/opt-agreement/opt-agreement.component';
import { DatePipe } from '@angular/common';
import { SortByKeysPipe } from './pipes/sortByKeys.pipe';
import { FormatDateWithTimezone } from './pipes/formatDateWithTimezone.pipe';
import { FormatTimezone } from './pipes/formatTimezone.pipe';
import {ExcerptPipe} from "./pipes/excerpt.pipe";
import {GetValueByKeyPipe} from "./pipes/get-value-by-key.pipe";
import {RelativeTimePipe} from "./pipes/relative-time.pipe";
import { NgxPrintModule } from 'ngx-print';
import {MatLegacyTabsModule as MatTabsModule} from "@angular/material/legacy-tabs";


import { ContactWishlistComponent } from './Contact/contact-wishlist/contact-wishlist.component';
import { ContactAttributeComponent } from './Contact/contact-attribute/contact-attribute.component';

import {ContactNotesComponent} from "./Contact/contact-notes/contact-notes.component";
import {SpaceModule} from "../_directives/space.module";
import {ThrottleClickDirective} from "../_directives/throttle-click.directive";

import { BindCssVariableDirective } from '../_directives/bind-css-variable.directive';
import { ContactMetaDataComponent } from './Contact/contact-metadata/contactmetadata.component';
import { StripHtmlPipe } from './pipes/stripHtml.pipe';
import { ClickOutsideDirective } from '../_directives/click-outside.directive';
import { NewFormatPhoneNumber } from './pipes/newFormatPhoneNumber.pipe';
@NgModule({
  imports: [CoreModule, ReactiveFormsModule, HttpClientModule, NgbModule,
    FormsModule, NgxSpinnerModule, CommonModule, MatIconModule, RouterModule,
    MatButtonModule,
    MatCardModule,
    PartialsModule,
    NgxPaginationModule,
    NgxDataTableModule,
    OrderModule,
    Ng2SearchPipeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BreadcrumbModule,
    ToastrModule.forRoot(),
    MatSelectSearchModule,
    InfiniteScrollModule,
    NgxPrintModule,
    MatTabsModule, SpaceModule,
    
  ],



   declarations:[
      SafeHTMLtagsPipe,
      FormatPhoneNumber,
      GroupByPipe,
      FilterUSStatesPipe,
     ExcerptPipe,
     GetValueByKeyPipe,
     RelativeTimePipe,
      SortByKeysPipe,
      ArticleDetailComponent,
      CreateContactComponent,
      UpdatecontactComponent,
      SearchContactComponent,
      SearchAllContactComponent,
      ViewcontactComponent,
      OptAgreementComponent,
      FormatDateWithTimezone,
      FormatTimezone,
      ContactWishlistComponent,

      ContactAttributeComponent,
      ContactNotesComponent,
      ThrottleClickDirective,
      BindCssVariableDirective,
      ContactMetaDataComponent,
      StripHtmlPipe,
     ClickOutsideDirective,
     NewFormatPhoneNumber
   ],
   providers: [
      NgbCarouselConfig,
      DatePipe,
       NgbModalConfig,
       { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
   ],
   exports:[
      CoreModule,
      CommonModule,
      ReactiveFormsModule,
      BreadcrumbModule,
      HttpClientModule,
      NgbModule,
      FormsModule,
      NgxSpinnerModule,
      MatIconModule,
      MatButtonModule,
      MatCardModule,
      PartialsModule,
      NgxPaginationModule,
      NgxDataTableModule,
      OrderModule,
      Ng2SearchPipeModule,
      RouterModule,
      SafeHTMLtagsPipe,
     FilterUSStatesPipe,
     ExcerptPipe,
     GetValueByKeyPipe,
     RelativeTimePipe,
      ArticleDetailComponent,
      CreateContactComponent,
      UpdatecontactComponent,
      SearchContactComponent,
      SearchAllContactComponent,
      ViewcontactComponent,
      OptAgreementComponent,
      DatePipe,
      FormatPhoneNumber,
      MatNativeDateModule,
      MatDatepickerModule,
      ToastrModule,
      MatSelectSearchModule,
      GroupByPipe,
      FilterUSStatesPipe,
      SortByKeysPipe,
      FormatDateWithTimezone,
      FormatTimezone,
     InfiniteScrollModule,
     ThrottleClickDirective,
     BindCssVariableDirective,
     StripHtmlPipe,
     ClickOutsideDirective,
     NewFormatPhoneNumber
      ]
})
export class SharedModule { }
