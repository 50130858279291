import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminoneService } from 'src/app/services/adminone/adminone.service';
import { ToastrService } from 'ngx-toastr';
//import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReskinningService } from 'src/app/_services/reskinning.service';
import { UploadService } from 'src/app/services/upload/upload.service';


type AOA = any[][];

@Component({
  selector: 'app-import-files-dialog',
  templateUrl: './import-files-dialog.component.html',
  styleUrls: ['./import-files-dialog.component.css']
})
export class ImportFilesDialogComponent implements OnInit {
  rskmodellogoFlag = this.ReskinningService.rskModelLogoFlag;
  rskmodellogo = this.ReskinningService.rskModelLogo;
  @Input() title: string;
  @Input() message: string;
  @Input() url: string;
  importData;
  csvContent: string;
  parsedCsv=[];
  file: File;
  isValid: boolean = true;
  data: AOA = [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  constructor(private activeModal: NgbActiveModal,
    // private masterService: MasterService,
    public spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    public adminone: AdminoneService,
    private ReskinningService:ReskinningService,
    private modalService: NgbModal,
    private uploadService : UploadService
    ) {
      this.importData = localStorage.getItem('setRoute');
     }
  value = 0;
  width = "0%";
  fileName;
  status = true;
  display = "none";

  ngOnInit() {
  }

  onFileContactsLoad(fileLoadedEvent) {
      const csvSeparator = ',';
      const textFromFileLoaded = fileLoadedEvent.target.result;
      this.csvContent = textFromFileLoaded;
      const csv = [];
      const lines = this.csvContent.split('\n');
      lines.forEach(element => {
        if(element) {
          const cols: string[] = element.split(csvSeparator);
          csv.push(cols);
        }
      });
      this.parsedCsv = csv;
      let arr = [];
      for(let i=1;i<this.parsedCsv.length;i++) {

        if(this.parsedCsv[i].length > 0) {
          if(this.parsedCsv[i][0] == '' || this.parsedCsv[i][1] == '' || this.parsedCsv[i][2] == '') {
            this.isValid = false;
            this.toastrService.error('Please enter all required fields i.e. First Name, Last Name and Email at row '+i);
            this.modalService.dismissAll();
            break;
          }
          if(!AppConstants.regex.email.test(this.parsedCsv[i][2])) {
            this.isValid = false;
            this.toastrService.error('Please check and enter valid email to proceed at row '+i);
            this.modalService.dismissAll(); 
            break;
          }
          if (arr?.length === 0 ) {
            arr.push(this.parsedCsv[i][2]);
          } else {
            if (arr.indexOf(this.parsedCsv[i][2]) !== -1) {
              this.isValid = false;
            this.toastrService.error('Please check for duplicate Email ID`s in uploaded CSV ');
            this.modalService.dismissAll(); 
            break;
            } else {
              arr.push(this.parsedCsv[i][2]);
            }
          }
          if(!AppConstants.regex.csvFullName.test(this.parsedCsv[i][0]) || !AppConstants.regex.csvFullName.test(this.parsedCsv[i][1])) {
            this.isValid = false;
            this.toastrService.error('Please check firstname and lastname at row'+i+'. It should accept only alphabets with or without allowed characters');
            this.modalService.dismissAll();
            break;
          }
          // if(this.parsedCsv[i][5] == 'name@example.com') {
          //   this.isValid = false;
          //   this.toastrService.error('Please remove the dummy email from csv file and then proceed at row '+i);
          //   this.modalService.dismissAll();
          //   break;
          // }
        }
      }

      if(this.isValid) {
        this.toUploadFiles(this.file, 'contacts');
      }
  }

  onFileGuestsLoad(fileLoadedEvent) {
    this.parsedCsv = [];
    const csvSeparator = ',';
    const textFromFileLoaded = fileLoadedEvent.target.result;
    this.csvContent = textFromFileLoaded;
    const csv = [];
    const lines = this.csvContent.split('\n');
    lines.forEach(element => {
      if(element) {
        const cols: string[] = element.split(csvSeparator);
        csv.push(cols);
      }
    });
    for(let i=1;i<csv.length-1;i++) {
      if(csv[i][2] !== csv[i+1][2]) {
        this.parsedCsv.push(csv[i])
      } else {
        this.isValid = false;
        //this.toastrService.error('Please remove the duplicate rows at row '+i);
        this.toastrService.error('Email ID should be unique, Please remove duplicate email ID from CSV');
        this.modalService.dismissAll();
        break;
      }
    };
    if(this.isValid) {
      this.parsedCsv.push(csv[csv.length-1]);
      if(this.parsedCsv.length >= 102){
        this.isValid = false;
        this.toastrService.error("Can't upload more than 100 records");
        this.modalService.dismissAll();
        return;
      }
      this.parsedCsv = csv;
      for(let i=1;i<this.parsedCsv.length;i++) {
        if(this.parsedCsv[i].length > 0) {
          if(this.parsedCsv[i][0] == '' || this.parsedCsv[i][1] == '' || this.parsedCsv[i][2] == '') {
            this.isValid = false;
            this.toastrService.error('Please enter all required fields i.e. First Name, Last Name and Email at row '+i);
            this.modalService.dismissAll();
            break;
          }
          if(!AppConstants.regex.email.test(this.parsedCsv[i][2])) {
            this.isValid = false;
            this.toastrService.error('Please check and enter valid email to proceed at row '+i);
            this.modalService.dismissAll();
            break;
          }
          if(!AppConstants.regex.csvFullName.test(this.parsedCsv[i][0]) || !AppConstants.regex.csvFullName.test(this.parsedCsv[i][1])) {
            this.isValid = false;
            this.toastrService.error('Please check firstname and lastname at row'+i+'. It should accept only alphabets with or without allowed characters');
            this.modalService.dismissAll();
            break;
          }
          // if(this.parsedCsv[i][2] == 'name@example.com') {
          //   this.isValid = false;
          //   this.toastrService.error('Please remove the dummy email from csv file and then proceed at row '+i);
          //   this.modalService.dismissAll();
          //   break;
          // }
        }
      }
    }
    if(this.isValid) {
      this.toUploadFiles(this.file, 'guests');
    }
}

 processFile(event: any, files: any) {
   /* wire up file reader */
   var content = this.csvContent;
   this.file = files.files[0];
   const target: DataTransfer = <DataTransfer>(event.target);
   if (target.files.length !== 1) {
    this.toastrService.error('Cannot use multiple files');
    return;
  }
  if (/\.(csv)$/i.test(this.file.name) === false) {
    this.toastrService.error("Please Follow same format i.e .csv");
    return
  }
   const reader: FileReader = new FileReader();
       reader.onload = (e: any) => {
         /* read workbook */
         const bstr: string = e.target.result;
         const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

         /* grab first sheet */
         const wsname: string = wb.SheetNames[0];

         const ws: XLSX.WorkSheet = wb.Sheets[wsname];
         /* save data */


         if((ws.A1 && ws.B1 && ws.C1)
         && (ws.A1.v == 'FirstName' && ws.B1.v == 'LastName' && ws.C1.v == 'Email')) {
           this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
           // this.displayBox(this.data)
            const reader = new FileReader();
            reader.onload =  this.onFileGuestsLoad.bind(this);
            event.target.value = null;
            reader.readAsText(this.file, "UTF-8");
            return;
            // reader.readAsDataURL(file);
         } else {
           this.toastrService.error('Please upload csv file with the same column names as the sample');
           event.target.value = null;
           return;
         }

       };
      reader.readAsBinaryString(target.files[0]);
  }

  showConfirm(modalName) {
    let self = this
    self.modalService.open(modalName,{ariaLabelledBy: 'modal-basic-title',backdrop:'static',keyboard:false }).result.then((result) => {
      }, (reason) => {
        if(reason && reason!=0){

        }
    });
  }


  toUploadFiles(file, name) {
    let self = this;
    var path;
    if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
      path = 'contacts/working';
    } else {
      path = 'master_images';
    }
    // this.adminone.uploadfile(file, path, name, function (error, params) {
    //   if (error) {
    //     return;
    //   }
    //   if (params.Key) {
    //     let { key } = params;
    //     self.display = "flex";
    //     let id = setInterval(() => {
    //       self.value += 1;
    //       if (self.value <= 90) {
    //         self.width = `${self.value}%`;
    //       }
    //     }, 100);
    //     if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
    //        let filename = key.split('/');
    //       // var today = new Date();
    //       // var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    //       // var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    //       localStorage.setItem('fileName', filename[2])

    //     }


    //     var body;
    //     if(self.adminone.data)
    //     {
    //       body={ "key": key,data: self.adminone.data}
    //     }
    //     else
    //     {
    //       body={ "key": key}
    //     }
    //     self.adminone.importBulk(self.url, body).subscribe(data => {
    //       if (data.status) {
    //         clearInterval(id);
    //         self.width = `100%`;
    //         self.toastrService.success(data.message);
    //         self.displayBox(data.status);
    //       } else {
    //         clearInterval(id);
    //         self.toastrService.error(data.message);
    //         self.width = `100%`;
    //         self.status = false
    //         self.displayBox(data.status);
    //       }
    //     }, err => {
    //       clearInterval(id);
    //       self.status = false
    //       self.width = `0%`;
    //       self.displayBox(false);
    //       console.error(err);
    //     })
    //   }
    // });
    let fileReader = new FileReader();
    fileReader.onload = ()=>{
      let serviceKey = file.name.split('.').pop()
      this.uploadService.uploadImage2(name,path,fileReader.result,serviceKey).subscribe({
        next:(res:any)=>{
          if (res.data.Key) {
            let { key } = res.data;
            self.display = "flex";
            let id = setInterval(() => {
              self.value += 1;
              if (self.value <= 90) {
                self.width = `${self.value}%`;
              }
            }, 100);
            if (/\.(jpe?g|png|jpg)$/i.test(file.name) === false) {
               let filename = key.split('/');
              localStorage.setItem('fileName', filename[2])
            }
    
    
            var body;
            if(self.adminone.data)
            {
              body={ "key": key,data: self.adminone.data}
            }
            else
            {
              body={ "key": key}
            }
            self.adminone.importBulk(self.url, body).subscribe(data => {
              if (data.status) {
                clearInterval(id);
                self.width = `100%`;
                self.toastrService.success(data.message);
                self.displayBox(data.status);
              } else {
                clearInterval(id);
                self.toastrService.error(data.message);
                self.width = `100%`;
                self.status = false
                self.displayBox(data.status);
              }
            }, err => {
              clearInterval(id);
              self.status = false
              self.width = `0%`;
              self.displayBox(false);
              console.error(err);
            })
          }
          else{
            this.toastrService.error('Something went wrong !')
          }
        },
        error:(err:any)=>{
          this.toastrService.error('Something went wrong !')
        }
      })
    }
    fileReader.readAsDataURL(file)

  }

  /* Read the file from excel */
  readFile(evt: any, files: any) {
    /* wire up file reader */
    this.file = files.files[0];
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) {
      this.toastrService.error('Cannot use multiple files');
      return;
    }
    if (/\.(csv)$/i.test(this.file.name) === false) {
      this.toastrService.error("Please Follow same format i.e .csv");
      return
    }
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];

        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        /* save data */
        if((ws.A1 && ws.B1 && ws.C1 && ws.D1 && ws.E1 && ws.F1 && ws.G1 && ws.H1 && ws.I1 && ws.J1 && ws.K1 && ws.L1
         )
        && (ws.A1.v == 'FirstName' && ws.B1.v == 'LastName' && ws.C1.v == 'Email' && ws.D1.v == 'PrimaryMobile' &&
        ws.E1.v == 'SecondaryContact'  && ws.F1.v == 'Gender' && ws.G1.v == 'Address' &&
        ws.H1.v == 'Address2' && ws.I1.v == 'Country' && ws.J1.v == 'State' && ws.K1.v == 'City' &&
        ws.L1.v == 'Zipcode' )) {

          this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
          // this.displayBox(this.data)
            reader.onload =  this.onFileContactsLoad.bind(this);
            evt.target.value = null;
            reader.readAsText(this.file, "UTF-8");
            return;
        } else {
          this.toastrService.error('Please upload csv file with the same column names as the sample');
          evt.target.value = null;
          return;
        }

      };
      reader.readAsBinaryString(target.files[0]);

  }

  displayBox(value) {
    let self = this;
      setTimeout(() => {
        self.activeModal.close(value)
      }, 1000);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }


}

