import { UntypedFormGroup } from '@angular/forms';
import {  parseIncompletePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js/max';

export class PhoneValidator {

  static validCountryPhone =
    (phoneControlName: string,phonecodeControlName:string) => {
      return (formGroup: UntypedFormGroup) => {
        var phoneCodeControl = formGroup.controls[phonecodeControlName];
        var phoneControl = formGroup.controls[phoneControlName];
        if (phoneControl.value !== "" && phoneControl.value.trim().length > 1) {
          try {
            let phone = "+" + phoneCodeControl.value + parseIncompletePhoneNumber(phoneControl.value);
            var isValidNumber = parsePhoneNumberFromString(phone).isValid();
            if (isValidNumber) {
              return phoneControl.setErrors(null);;
            }
          } catch (e) {
            return phoneControl.setErrors({ validCountryPhone: true });
          }
          return phoneControl.setErrors({ validCountryPhone: true });
        }
        else {
          return null;
        }
      };
    };
}