import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
export class DateTimeService {

    constructor(private datePipe: DatePipe) {

    }
    
    getTransformedDate(date, dateFormat) {
        return this.datePipe.transform(date, dateFormat);
    }

    insertDateAt(date: string): string {
        return date.replace('AT', 'at');
      }
}