export class Contact {
    contact_id: number =0;
    userid : number;
	contact_type_id : number;
	contact_fname : string="";
	contact_lname : string="";
	contact_email : string="";
	contact_phone : string="";
	contact_birthdate : string="";
	contact_age_range : number=0;
	contact_sex : number=0;
	contact_photo : string="";
	contact_address : string="";
	contact_city : string="";
	contact_state : string="";
	contact_zip : string="";
	contact_region : string="";
    contact_country : string="";
    contact_estimated_household_income : number;
	contact_marital_status : number=0;
	contact_profession : number=0;
	contact_ds_id : number;
	contact_status : string="";
	loyalty_type_id : number=0;
	facebook_id : string="";
	twitter_id : string="";
	linkedin_id : string="";
	instagram_id : string="";
	instagram_biz_id : string="";
	youtube_id : string="";
	google_my_biz_id : string="";
	sina_wiebo_id : string="";
	we_chat_id : string="";
	whatsapp_id : string="";	
	pinterest_id : string="";	

	createdby : number;
	customerid : number;
	personal_notes:string="";
	contact_phone_other : string="";
	contact_phone_type: number=0;
	contact_address_2: string="";
	agreetohost:boolean=false;
	optin_email:boolean=false;
	optin_sms:boolean=false;
	/* Below property added for setting true for Event Flow Contact Addition (ON-446) */
	is_trans_optin:boolean=false;
	custType:any;
	contact_type: any;
	old_email:string;
     /*phone_code property added for independent phone code for phone number*/
	phone_code:string; 
}

export enum ContactType{
    Prospect = 3,
    Customer =2,
    TeamMember=1,
    Host =4
  }

  interface ContactTypeSpec {
    id: number,
	name: string,
	route: string,	
}
export function getContactTypeSpec(contactType: ContactType): ContactTypeSpec {
	switch (contactType) {
		case ContactType.Prospect:
			return { id: ContactType.Prospect, name: "Prospect", route: "prospect" };
		case ContactType.Customer:
			return { id: ContactType.Customer, name: "Customer", route: "customer" };
		case ContactType.TeamMember:
			return { id: ContactType.Prospect, name: "Team Member", route: "teammember" };
		case ContactType.Host:
			return { id: ContactType.Prospect, name: "Prospect", route: "host" };
		default: return { id: ContactType.Prospect, name: "Prospect", route: "prospect" };
	}
}