import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterUSStates' })
export class FilterUSStatesPipe implements PipeTransform {
    states: string[];
    transform(collection: Array<any>, property: string): Array<any> {
        // prevents the application from breaking if the array of objects doesn't exist yet
        if (!collection) {
            return null;
        }

        this.states2Remove();
        collection = collection.filter(itm => this.states.indexOf(itm.name.toLowerCase()) == -1);
        return collection;
    }

    states2Remove() {
        this.states = ['Byram',
            'Cokato',
            'District of Columbia',
            'Lowa',
            'Medfield',
            'New Jersy',
            'Ontario',
            'Ramey',
            'Sublimity',
            'Trimble'].map(x => x.toLowerCase())

    }
}
