import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appSpace]'
})
export class SpaceDirective {

  constructor(public el: ElementRef) { }

  @HostListener('keydown', ['$event'])

  onkeydown(e: any) {
    if ((e.which === 32 || e.which === 13) && this.el.nativeElement.value.length == 0){
       e.preventDefault();
    } 
  }

}
