<div class="container pb-5">
  <div class="row justify-content-center">
    <div class="col-md-2"></div>
    <div class="col-md-10 mt-1">
      <div class="card">
        <div class="card-content">
          <div class="card-header">
            <div class="row">
              <div class="col-12">
                <h4 class="float-left mb-0 mainHeading_1">My Agreement</h4>
                <span class="backBtnPro float-right">
                </span>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-3 text-center">
                <div class="media d-flex">
                  <div class="media-body additProImg">
                    <img *ngIf="userProfileDetails" src="{{userProfileDetails?.user_photo}}" height="100px"
                      (error)="setValue($event)">
                  </div>
                </div>
                </div>

                <div class="col-md-9 mt-1 mt-md-0">

                  <h4 class="font-weight-bold ttlColorBlue text-capitalize" *ngIf="userProfileDetails">
                    {{userProfileDetails?.user_fname | titlecase}} {{userProfileDetails?.user_lname | titlecase}}</h4>
                  <i class="text-uppercase"
                    *ngIf="userProfileDetails">{{userProfileDetails?.customer_company_name}}</i><br />
                  <i class="text-uppercase" *ngIf="userProfileDetails">{{userProfileDetails?.username}}</i>

                  <div class="row mt-1">
                    <div class="col-12">
                      <div class="form-group">
                        <h6 class="font-weight-bold text-center ">{{contents.agreement_title }}</h6>
                        <h6 class="font-weight-bold text-center text-uppercase">{{contents.agreement_subtitle}}</h6><br>
                        <h4 class="mb-1 font-weight-bold">Last Updated: {{contents.last_revised_date | date:'MMMM d, y'}}</h4>

                        <div class="scroller">
                          <div  rows="100" class="filtMain ulCustom" id="demo" style="min-height: 500px;" 
                          [innerHTML]="contents.agreement_text">
                          </div>

                        </div>
                      </div>
                    </div>


                           <div class="form-group d-inline-block float-sm-right mt-2 mt-md-0"
                           *ngIf="userProfileDetails && !userProfileDetails.is_terms_agreed">
                           <button class="btn btn-round btn-margo-action"
                             (click)="agreedTerms(userProfileDetails.user_ds_id)">
                             Agree & Accept</button>
                         </div>


                  
                  </div>
                
              </div>
            </div>
          </div>
          <div class="card-footer text-center agree-btn">
            <button type="button" class="btn btn-round btn-margo-action" (click)="onback()">close</button>
          </div>
        </div>
      </div>

      <ng-template #confirmBox let-modal>
        <div class="modal-header dialHead">
          <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" style="height: 100px;" />
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h3>WOULD YOU LIKE TO OPTIN AGREEMENT?</h3>
        </div>
        <div class="modal-footer text-center d-block border-0">
          <button type="button" class="btn btn-margo-action btn-round text-uppercase"
            (click)="modal.dismiss('Cross click')">YES</button>
          <button type="button" class="btn btn-margo-cancel btn-round text-uppercase"
            (click)="modal.close('Cross click')">NO</button>
        </div>
      </ng-template>
    </div>
  </div>
</div> 