import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors, UntypedFormControl, UntypedFormArray } from '@angular/forms';

import { MasterService } from 'src/app/core/services/master/master.service';
import csc from 'country-state-city';
import { BaseComponent } from 'src/app/firstadmin/base.component';
import { Contact, getContactTypeSpec } from 'src/app/firstadmin/Models/Contact';
import { forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';
import { parseIncompletePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
import { PhoneValidator } from 'src/app/shared/validators/phone.validator';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { MetaService } from 'src/app/services/meta/meta.service';
import { ReskinningService } from 'src/app/_services/reskinning.service';

@Component({
  selector: 'app-updatecontact',
  templateUrl: './updatecontact.component.html',
  styleUrls: ['./updatecontact.component.css']
})
export class UpdatecontactComponent extends BaseComponent implements OnInit, OnDestroy {
  contactForm: UntypedFormGroup;
  submitted = false;
  storedEmail;
  //contactData:Contact=new Contact();
  contact_id;
  contacttypeid;
  contacttypename;
  contactByIdData: Contact = new Contact();
  countriesData:any=[];
  stateData:any=[];
  cityData:any=[];
  status = true;
  agerange;
  maritalStatus;
  profession;
  sex:any=[];
  loyality;
  //phonecode;
  maxdate;
  mindate = new Date('Jan 1, 1920');
  contact_phone_type;
  customer_type;
  contact_phone_other;
  contact_address_2;
  selectedCountryObj: any = [{ "phonecode": "1", "id": "US", "name": "United States" }];
  @Input() public inputdata: any;
  heading: any;
  hosttype: any;
  contactListByPhoneNo: any=[];
  errmsg: any;
  contactListByEmail:any=[];
  smsCheck: boolean = false;
  emailCheck: boolean = true;
  disablePhone: boolean = false;
  showMessage: boolean;
  pphone: any;
  justLandedEmail = true;
  justLandedPhone = true;
  projectName=environment.projectName;
  userProfileDetails: any;
  metaVisibility: boolean = false;
  metafields = [];
  metaForm: UntypedFormGroup;
  uid: any;
  rskmodellogoFlag = this.ReskinningService.rskModelLogoFlag;
  rskmodellogo = this.ReskinningService.rskModelLogo;
  phoneCodeList: any;
  isAgreeClick=false;
  // @Output() messageToEmit = new EventEmitter<any>();
  constructor(private formBuilder: UntypedFormBuilder,
    private master: MasterService,
    private datePipe: DatePipe,
    private modalService: NgbModal, private cdr: ChangeDetectorRef,
    private metaService: MetaService,
    private ReskinningService: ReskinningService
  ) {
    super();
  }


  ngOnInit() {
    this.userProfileDetails = Object.assign({}, this.loginUserData);
    this.uid = parseInt(sessionStorage.getItem('contactid'), 10);
    this.metaForm = this.formBuilder.group({
      metaFields: new UntypedFormArray([])
    });
    const phoneHome = '([0-9]{10})*/?';
    if(this.inputdata) {
      this.contacttypeid = this.inputdata.id;
      this.contacttypename = this.inputdata.name;
      this.heading = this.inputdata.text;
      this.hosttype = this.inputdata.hosttype;
    }

    this.contactByIdData.contact_country = 'US';
    //this.selectedCountryObj=csc.getCountryById(this.contactByIdData.contact_country);
    // this.getCountryById(this.contactByIdData.contact_country);
    this.contactForm = this.formBuilder.group({

      fname: ['', [Validators.required, Validators.pattern("^[a-zA-Z!@# \$%\^\&?\'\*\)\(+=._-]+$")]],
      lname: ['', [Validators.required, Validators.pattern("^[a-zA-Z!@# \$%\^\&?\'\*\)\(+=._-]+$")]],

      pemail: ['', [Validators.required, Validators.pattern(AppConstants.regex.email)]],
      mphone: [''],
      address: [''],
      birthdate: [''],
      city: [''],
      state: [''],
      country: [],
      zipcode: [''],
      personalnotes: [''],
      fblink: ['', [Validators.pattern(AppConstants.regex.fbLink)]],
      Twitterlink: ['', [Validators.pattern(AppConstants.regex.twitter)]],
      instagramlink: ['', [Validators.pattern(AppConstants.regex.instagram)]],
      linkedInlink: ['', [Validators.pattern(AppConstants.regex.linkedIn)]],
      instagrambiz: ['', [Validators.pattern(AppConstants.regex.instagram)]],
      youtubelink: ['', [Validators.pattern(AppConstants.regex.youtube)]],

      googlelink: ['', [Validators.pattern(AppConstants.regex.googleBizLink)]],
     // Sinalink: ['', [Validators.pattern(AppConstants.regex.sinaWeibo)]],
     // wechatlink: ['', [Validators.pattern(AppConstants.regex.number)]],

      pinterestLink: ['', [Validators.pattern(AppConstants.regex.pinterest)]],
      whatsapplink: ['', [Validators.minLength(6), Validators.maxLength(14), Validators.pattern(AppConstants.regex.number)]],
      phonecode: [],
      contact_phone_other: [''],
      // contact_phone_other: ['', [Validators.minLength(10),Validators.maxLength(14),Validators.pattern(AppConstants.regex.number)]],
      contact_phone_type: [],
      customer_type:[],
      contact_address_2: [],
    }, { validator: [ PhoneValidator.validCountryPhone('mphone', 'phonecode'), PhoneValidator.validCountryPhone('contact_phone_other', 'phonecode')] });
    let self = this;
    var year = new Date().getFullYear() - 21;
    this.maxdate = this.datePipe.transform(new Date(new Date(new Date().setFullYear(year)).setHours(0, 0, 0, 0)), 'yyyy-MM-dd');
    self.contactForm.get('birthdate').valueChanges.subscribe(value => {
      if (value) {
        this.contactForm.get('birthdate').setValidators(this.dateValidator())
      } else {
        this.contactForm.get('birthdate').clearValidators();
      }
      //this.contactForm.updateValueAndValidity();
    }
    );
    self.contact_id = sessionStorage.getItem("contactid");
    var objprop = [];
    /* objprop.push({contactnameprop:"agerange"});
    objprop.push({contactnameprop:"maritalStatus"});
    objprop.push({contactnameprop:"profession"}); */
    objprop.push({ contactnameprop: "sex" });
    objprop.push({ contactnameprop: "loyality" });
    var obj = [
      /*  this.master.getAgerange(),
       this.master.getMaritalStatus(),
       this.master.getProfession(), */
      this.master.getSex(),
      this.master.getLoyalityType()
    ];
    this.ngxSpinnerService.show();
    forkJoin(obj).subscribe((result) => {
      result.forEach((data: any, index: any) => {
        if (data) {
          this[objprop[index].contactnameprop] = data;
          this.ngxSpinnerService.hide();
        }
      });
    });

    this.metaService.getMetaFieldsForUpdate(this.userProfileDetails.user_customer_id, 1, this.uid).subscribe(data => {
      if (data.status) {
        this.metaVisibility = true;
       // this.metaVisibility = false;
        this.metafields = data.data;
        this.createMetaForm();
      }
      else {
        this.metaVisibility = false;
        this.metafields = [];
      }
    }, error => {
      this.metaVisibility = false;
      this.metafields = [];
      console.log(error);
    });

    //this.countriesData = csc.getAllCountries();
    this.getAllCountries();
    // this.getPhoneCode()
    if (self.contact_id) {

      this.master.getContactListById(this.contact_id).subscribe(response => {
        this.contactByIdData = response.data;
        this.cdr.detectChanges();
        this.storedEmail = this.contactByIdData.contact_email;
        this.pphone = this.contactByIdData.contact_phone;

        if(this.contactByIdData.contact_city){
          // this.contactByIdData.contact_city = this.contactByIdData.contact_city.toLocaleLowerCase();
        }
        if(this.contactByIdData.contact_city == 'NaN') {

        } else {
          this.getCityById(this.contactByIdData.contact_city);
        }

        if (this.contactByIdData.contact_country) {
          this.getCountryById(this.contactByIdData.contact_country);


          // this.phonecode = this.selectedCountryObj.phonecode;

          // if (this.contactByIdData.contact_country.length == 2) {
          //   this.getStatesOfCountry(this.contactByIdData.contact_country);
          //   this.getCountryById(this.contactByIdData.contact_country);
          // }

          if (this.contactByIdData.contact_state) {
            //this.getCitiesOfState(this.contactByIdData.contact_state, this.contactByIdData.contact_country);

            // if (this.cityData.length === 0) {
            //   this.cityData = this.getStateById(this.contactByIdData.contact_state);
            //   //this.contactByIdData.contact_city = this.contactByIdData.contact_state;
            // }

            if (this.contactByIdData.contact_state.length == 2) {
              //this.getStateById(this.contactByIdData.contact_state);

            //   this.getCitiesOfState(this.contactByIdData.contact_state, this.contactByIdData.contact_country);
            }
          }
          else {
            this.contactByIdData.contact_state = '';
            this.contactByIdData.contact_city = '';
          }
        }
        else {
          this.contactByIdData.contact_country = 'US';
          this.selectedCountryObj.phonecode = 1;
          //this.stateData = csc.getStatesOfCountry(this.contactByIdData.contact_country);
          this.getStatesOfCountry(this.contactByIdData.contact_country);
          //  this.phonecode = this.selectedCountryObj.phonecode;
          this.contactByIdData.contact_state = '';
          this.contactByIdData.contact_city = '';
        }

        if (this.agerange) {
          this.ngxSpinnerService.hide();
        }
      });
    }
    this.phone.valueChanges.pipe(
      map((query) => query),
      debounceTime(1000),
      distinctUntilChanged()
    )
      .subscribe(data => {
        this.contactListByPhoneNo = [];
        if (this.f.mphone.errors === null) {
          //this.ngxSpinnerService.show();
          let self = this;
          var body = {
            contact_phone: parseIncompletePhoneNumber(data),
            contact_id: this.contact_id
          }

          if(!this.justLandedPhone){
            this.ngxSpinnerService.show();
          this.master
            .getContactListByPhoneNo(body)
            .subscribe(function (data) {

              if (data.status) {
                self.contactListByPhoneNo = data.data.filter(itm => {return itm.contact_id != sessionStorage.getItem('contactid')});
              }
              else {
                self.contactListByPhoneNo = [];
              }
              self.ngxSpinnerService.hide();
            });
          } else {
            this.justLandedPhone = false;
          }
        }
      });

      this.email.valueChanges.pipe(
        map((query) =>query),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(data => {
        this.contactListByEmail = [];
        if(this.f.pemail.errors===null) {
          //this.ngxSpinnerService.show();
          let self=this;
          var body={
           contact_email:data
          }
          if(!this.justLandedEmail){
            this.ngxSpinnerService.show();
           this.master
               .getContactListByEmail(body)
               .subscribe(function(data){
                 if(data.status)
                 {
                   self.contactListByEmail=data.data.filter(itm => {return itm.contact_id != sessionStorage.getItem('contactid')});
                 }
                 else
                 {
                   self.contactListByEmail=[];
                 }
                 self.ngxSpinnerService.hide();
               });
              } else {
                this.justLandedEmail = false;
              }
        }
        // if((this.inputdata.page == 'addinvitee' || this.inputdata.page == 'add-contact') && this.email.value) {
        //   this.phone.clearValidators();
        //   //this.phone.updateValueAndValidity();
        // }
      })
  }

  checkSpaces(event, field) {
    this.contactForm.get(field).setValue(event.target.value.replace(/\s+/g, ' ').trim(), { emitEvent: false });
  }

  goToContactInformationForm(contactid, contacttypeid) {
    sessionStorage.setItem("contactid", contactid);
    contacttypeid = contacttypeid === 0 ? 3 : contacttypeid;
    var contacttyperoute = getContactTypeSpec(contacttypeid).route + "/";
    this.inputdata.navurl = this.inputdata.navurl.replace('customer/', contacttyperoute).replace('prospect/', contacttyperoute);
    this.router.navigateByUrl(this.inputdata.navurl);
  }

  dateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dateStr = control.value;

      if (dateStr) {
        var year = new Date().getFullYear() - 16;
        const now = new Date(new Date(new Date().setFullYear(year)).setHours(0, 0, 0, 0));
        const invalidObj = { 'date': true };
        const date = new Date(new Date(dateStr).setHours(0, 0, 0, 0));
        if (date > now) {
          return invalidObj;
        }
        else {
          return null;
        }
      }
      return null;
    };
  }

  get f() { return this.contactForm.controls; }
  get phone() {
    return this.contactForm.controls.mphone;
  }
  get email() {
    return this.contactForm.controls.pemail;
  }

  get g() { return this.metaForm.controls; }
  get t() { return this.g.metaFields as UntypedFormArray; }

  //  ##############  META FORM #####################  //

  createMetaForm() {
    if (this.metafields?.length > 0) {
      for (let mt of this.metafields) {
        let vl = mt.field_value;
        if (vl === null || vl === undefined) {
          vl = '';
        }

        if (mt.field_type === 'INT') {

          this.t.push(this.formBuilder.group({
            name: [mt.field_name],
            metafname: [vl, [Validators.pattern(/^[0-9]\d*$/), Validators.maxLength(mt.field_size)]],
            lname: [mt.label_name],
            ftyp: 'Integer'
          }));
        } else {

          if (mt.field_type === 'NUMERIC') {
            this.t.push(this.formBuilder.group({
              name: [mt.field_name],
              metafname: [vl, [Validators.pattern(/^[0-9]\d*[.][0-9]\d*$/), Validators.maxLength(mt.field_size)]],
              lname: [mt.label_name],
              ftyp: 'Float'
            }));
          } else {
            if (mt.field_type === 'BOOLEAN') {
              let v = '';
              if (vl === true) {
                v = 'true';
              }
              if (vl === false) {
                v = 'false';
              }
              this.t.push(this.formBuilder.group({
                name: [mt.field_name],
                metafname: [v,  this.checkBool],
                lname: [mt.label_name],
                ftyp: 'Boolean'
              }));
            } else {
              if ( mt.field_type === 'timestamp') {
                this.t.push(this.formBuilder.group({
                  name: [mt.field_name],
                  metafname: [vl, [Validators.pattern(/^[1-9]([0-9]{3,3})[-][0-1][0-9]-[0-3][0-9][T][0-2][0-9][:][0-5][0-9][:][0-5][0-9][.]([0-9]{3,3})([Z])$/), Validators.maxLength(mt.field_size)]],
                  lname: [mt.label_name],
                  ftyp: 'Tmstamp'
                }));
              } else {
                this.t.push(this.formBuilder.group({
                  name: [mt.field_name],
                  metafname: [vl, Validators.maxLength(mt.field_size)],
                  lname: [mt.label_name],
                  ftyp: 'String'
                }));
              }
            }
          }
        }
      }
    }
  }

  /* Check Boolean value Validator */

  checkBool(control: UntypedFormControl) {
    let val = control.value;
    if (val === 'true' || val === 'false' || val === '') {
      return null;
    }
    return {
      boolCheck: {
        val: false
      }
    }
  }

  states() {
    //this.stateData = csc.getStatesOfCountry(this.contactByIdData.contact_country);
    this.getStatesOfCountry(this.contactByIdData.contact_country);
    this.contactByIdData.contact_state = '';
    this.contactByIdData.contact_city = '';
    this.cityData = [];
    //this.selectedCountryObj=csc.getCountryById(this.contactByIdData.contact_country);
    this.getCountryById(this.contactByIdData.contact_country);
    //this.phonecode = this.selectedCountryObj.phonecode;
  }

  getCities() {
    // this.cityData = csc.getCitiesOfState(this.contactByIdData.contact_state);
    this.getCitiesOfState(this.contactByIdData.contact_state, this.contactByIdData.contact_country);
    // if(this.cityData.length>0)
    // {
    //   this.contactByIdData.contact_city = '';
    // }
    // else
    // {
    //   this.cityData = this.getStateById(this.contactByIdData.contact_state, this.contactByIdData.contact_country);
    // }

  }
  addNewContact() {
     this.submitted = true;
     if(this.storedEmail.toLowerCase() !== this.contactByIdData.contact_email.toLowerCase() && this.contactListByEmail.length > 0) {
      return;
     }
     if (this.contactForm.invalid) {
       return;
     }


    this.contactByIdData.contact_phone = parseIncompletePhoneNumber(this.contactByIdData.contact_phone);
    let phone = "+" + this.selectedCountryObj.phonecode + this.contactByIdData.contact_phone;
    if(this.selectedCountryObj.phonecode && this.contactByIdData.contact_phone) {
      var isValidNumber = parsePhoneNumberFromString(phone).isValid();
    }
    if (!isValidNumber && this.selectedCountryObj.phonecode && this.contactByIdData.contact_phone) {
      return this.f.mphone.setErrors({ validCountryPhone: true });
    }
    this.ngxSpinnerService.show();
    this.contactByIdData.optin_sms = this.smsCheck;
    this.contactByIdData.optin_email = this.emailCheck;
    this.contactByIdData.old_email = sessionStorage.getItem('contact_old_email');
    if(sessionStorage.getItem('isHostContact')) {
      let isHost = sessionStorage.getItem('isHostContact');
      this.contactByIdData.contact_type = isHost == 'true' ? 'host': '';
    }
    /* ON-710 => FOR DOB ISSUE FIX */

    let finobj = Object.assign({is_agree:this.isAgreeClick}, this.contactByIdData);
   if (finobj.contact_birthdate) {
      let tzOffset = new Date().getTimezoneOffset();
      finobj.contact_birthdate = new Date(new Date(finobj.contact_birthdate).getTime() - tzOffset*60*1000).toISOString();

    }

    // this.master.updateContact(this.contactByIdData).subscribe(data => {
      /* END 710 */
      this.master.updateContact(finobj).subscribe(data => {

      if (data.status) {
        this.router.navigateByUrl(this.inputdata.navurl);
        this.toastrService.success("Contact information updated successfully");
      }
      else {
        if (data.message && data.message.indexOf("Email already exists") >= 0) {
          this.contactListByEmail = data.data;
        }
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.ngxSpinnerService.hide();
      this.toastrService.error(error.message);
      console.log(error);
    });
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  requiredIfValidator(predicate) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    })
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
 }

  /* opt in popup */
  showConfirm(modalName) {
    if(this.f.fblink.value) {
      this.isUrlValid(this.f.fblink.value)
    }
    if(this.phone.value) {
      this.disablePhone = true;
      this.smsCheck = true;
    } else if(!this.phone.value) {
      this.disablePhone = false;
      this.smsCheck = false;
    }
    this.submitted = true;

    if (this.contactForm.invalid) {
      return;
    }
    if (this.pphone === this.contactByIdData.contact_phone && this.storedEmail === this.contactByIdData.contact_email) {
      this.addNewContact();
    }
    else {
      this.modalService.open(modalName).result.then((result) => {
      }, (reason) => {
        console.log("reason", reason);
        if (reason == 'open') {
          this.addNewContact();
        }
      });
    }
  }

  checkedEmail(e) {

    this.emailCheck = e.target.checked;
  }

  smsChecked(e) {
    this.smsCheck = e.target.checked;
    if(this.phone.value && e.target.checked) {
      this.disablePhone = true;
    } else if(!this.phone.value){
      this.disablePhone = false;
    }
  }

  emailChecked(e) {
    this.emailCheck = e.target.checked;
  }

  agree() {
    if(!this.emailCheck) {
      this.showMessage = true;
    } else {
      this.modalService.dismissAll('open');
      this.isAgreeClick=true;
      this.showMessage = false;
    }
  }

  /******Location Functions******/
  getCitiesOfState(stateId: string, countryId: string) {
    if(countryId && stateId) {
      this.master.getCitiesOfState(countryId.trim(), stateId.trim()).subscribe((objArr: any[]) => {
        this.cityData = objArr;

      });
    }

  }

  getStateById(country: string, state: string) {
    if(state && country) {
      this.master.getStateByCode(country.trim(), state.trim()).subscribe((objArr: any) => {

        this.contactByIdData.contact_state = objArr.name;
        this.getCitiesOfState(this.contactByIdData.contact_state, this.selectedCountryObj.id);
      });
    }

  }

  getCountryById(countryId: string) {
    if(countryId) {
      this.master.getCountryByCode(countryId).subscribe((objArr: any) => {

        if(objArr[0].id != '')
          this.selectedCountryObj = objArr[0];
        else
          this.selectedCountryObj = { "phonecode": "1", "id": "US", "name": "United States" };

        if(countryId.length > 2)
          this.contactByIdData.contact_country = this.selectedCountryObj.id;

        //this.phonecode = this.selectedCountryObj.phonecode;
        this.getStatesOfCountry(this.selectedCountryObj.id);

        if(!!this.contactByIdData.contact_state) {
          if(this.contactByIdData.contact_state.length == 2){
            this.getStateById(this.selectedCountryObj.id, this.contactByIdData.contact_state);
          } else {
            this.getCitiesOfState(this.contactByIdData.contact_state, this.selectedCountryObj.id);
          }

        }
      });
    }

  }

  getStatesOfCountry(countryId: any) {
    if (!isNaN(countryId)) {
      // if number
      countryId = this.getCountryISOfromNPMPack(countryId);
    }
    this.master.getStatesOfCountry(countryId).subscribe(objArr => {
      this.stateData = objArr;
    });
  }

  getCountryISOfromNPMPack(countryId) {
    let cObj = csc.getCountryById(countryId);
    return cObj.sortname;
  }

  getAllCountries() {
    this.master.getAllCountries().subscribe(objArr => {
      this.countriesData = objArr;
    });
  }

  getCityById(geonameId: any) {

    if(geonameId != null && !isNaN(geonameId) && geonameId != '') {
      this.master.getCityById(geonameId).subscribe((objArr: any) => {

        this.contactByIdData.contact_city = objArr.name;
      });
    }
  }
  /*** ***Location Functions - END******/

  updateMetaFields() {
    if (this.metaForm.invalid) {
      this.toastrService.error('Please provide correct Meta Field Values');
      return;
    }
    this.ngxSpinnerService.show();
    let fnarr = [];
    for (let fd of this.t.controls) {
      let fn = fd['controls'].name.value;
      let mfnm =  fd['controls'].metafname.value;
      if (fd['controls'].metafname.value !== null || fd['controls'].metafname.value !== undefined) {
        if (typeof mfnm === 'string') {
          mfnm = mfnm.trim();
        }

      }
      let obj = {
        field_name: fn,
        field_value: mfnm
      }
      fnarr.push(obj);
    }
    this.ngxSpinnerService.hide();
    this.metaService.updateProfileMetaFields(this.userProfileDetails.user_customer_id, 1, this.uid, fnarr).subscribe(data => {
      if (data.status) {
        this.toastrService.success('Meta Fields Updated Successfully');
        this.ngxSpinnerService.hide();
        this.router.navigateByUrl(this.inputdata.navurl);
      }
      else {
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }
    }, error => {
      this.toastrService.error('Error while saving Meta Fields');
      this.ngxSpinnerService.hide();
      console.log(error);
    });
  }
  getPhoneCode(){
    this.master.getCoutryPhoneCode().subscribe(objArr => {
      this.phoneCodeList = objArr?.data;
    });
  }
  rmvPlus(str:any){
    return str.replace('+','')
  }
  ngOnDestroy(): any {
    this.modalService.dismissAll();
  }
}
