<div class="scroller">
  <div class="wholePop filtMain">


<div class="modal-header w-100 dialHead">
  <!-- <h4 class="modal-title">{{ title }}</h4> -->
  <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" style="height: 100px;"/>
  <button type="button" class="close float-right" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>


  <div class="clearfix"></div>
  <!-- <h5 class="text-capitalize mb-0 float-left font-weight-bold">{{ title }}</h5> -->
</div>
<div class="modal-body">
  <h5 class="mb-2 text-center w-100">{{ title | uppercase}}</h5>
  <div class="form-group row">
    <label for="" class="col-xl-2 col-md-3 col-sm-4 col-form-label">TIMER TYPE</label>
    <div class="col-xl-6 col-md-8 col-sm-8">
      <select (change)="changed($event)" [(ngModel)]='timer_type' class="form-control">
        <option *ngFor="let currentData of selectDateAndTime" [value]="currentData.id">
          {{currentData.name}}
        </option>
      </select>
    </div>
  </div>

  <div class="form-group row">
    <label for="" class="col-xl-2 col-md-3 col-sm-4 col-form-label">INTERVAL</label>
    <div class="col-xl-6 col-md-8 col-sm-8" *ngIf="timer_type === 'day'">

      <input type="number" maxlength="3" (keydown)="isNumber(event)" [(ngModel)]='interval' placeholder="day" class="form-control">
    </div>
    <div class="col-xl-6 col-md-8 col-sm-8" *ngIf="timer_type === 'hour'">
      <input type="number" maxlength="3"  (keydown)="isNumber(event)" [(ngModel)]='interval' placeholder="hour" class="form-control">
    </div>
    <div class="col-xl-6 col-md-8 col-sm-8" *ngIf="timer_type === 'minute'">
      <input type="number"  maxlength="3" (keydown)="isNumber(event)" [(ngModel)]='interval' placeholder="minute" class="form-control">

    </div>
  </div>


</div>

<div class="modal-footer d-block text-center border-0 pt-0">
  <button type="button" class="btn btn-margo-action btn-round" (click)="accept()">{{ btnOkText | uppercase}}</button>
  <button type="button" class="btn btn-margo-action btn-round" (click)="decline()">{{ btnCancelText | uppercase}}</button>
</div>

</div>
</div>
