import {Component, OnInit, ChangeDetectorRef, AfterViewChecked, OnDestroy} from '@angular/core';
import {Location} from '@angular/common';
import {BaseService} from './services/base.service';
import {ChatService} from "../_services/chat.service";
import {ToastrService} from "ngx-toastr";
import {Router, NavigationStart} from "@angular/router";
import { filter } from 'rxjs/operators';
import {environment} from "../../environments/environment";
import { SocketioService } from '../_services/socketio.service';


@Component({
  selector: 'app-firstadmin',
  templateUrl: './firstadmin.component.html',
  styleUrls: ['./firstadmin.component.css']
})
export class FirstadminComponent implements OnInit,AfterViewChecked,OnDestroy  {
  protected isBackButtonEnable;
  messageNotification$:any;
  status$:any;
 // subject$:any;
  disconnect$:any;
  currentURL: any;
  hideBackButton: boolean = false;
  subscription: boolean = false;
  connectionMessage$: any;
  contactsList: any;
  totalunreadtextmsg: number=0;;

  constructor(private location: Location,
              private baseService:BaseService,
              private cdRef : ChangeDetectorRef,
              private chatService:ChatService,
              private toastrService:ToastrService,
              private router:Router,
              public textMessageService:SocketioService
              ) {


      //var chat_notification =   localStorage.getItem('chat_notification');

    this.connectionMessage$ = this.chatService.connection.subscribe(message => {
      if (!this.subscription){

          this.preventSocketTimeout();

          this.status$ = chatService.status.subscribe((value) => {
            if (localStorage.getItem('chat_notification') == '1' && (value.status_name == 'Available' || value.status_name == 'Offline')) this.toastrService.success(value.user_name + ' is ' + value.status_name);
          });

          this.disconnect$ = this.chatService.disconnect.subscribe(() => {
            this.unsubscribe();
          })
          this.messageNotification$ = chatService.messageNotification.subscribe((value) => {

            if (localStorage.getItem('chat_notification') == '1') {
              this.toastrService.success((value.user_name + ': ' + (this.removeTags(value.content.toString()).length > 50
                ? this.removeTags(value.content).substring(0, 47).concat('...')
                : this.removeTags(value.content))), '', {
                enableHtml: true
              });
            }
          });
          this.subscription = true;
      }
    });


      // this.subject$ = this.chatService.init().subscribe((value) => {
      //   this.preventSocketTimeout();
      //
      //   //var chat_notification =   localStorage.getItem('chat_notification');
      //
      //   this.status$ = chatService.status.subscribe((value) => {
      //     if (localStorage.getItem('chat_notification') == '1' && (value.status_name == 'Available' || value.status_name == 'Offline')) this.toastrService.success(value.user_name + ' is ' + value.status_name);
      //   });
      //
      //   this.disconnect$ = this.chatService.disconnect.subscribe(() => {
      //     this.unsubscribe();
      //   })
      //   this.messageNotification$ = chatService.messageNotification.subscribe((value) => {
      //
      //     if (localStorage.getItem('chat_notification') == '1') {
      //       this.toastrService.success((value.user_name + ': ' + (this.removeTags(value.content.toString()).length > 50
      //         ? this.removeTags(value.content).substring(0, 47).concat('...')
      //         : this.removeTags(value.content))), '', {
      //         enableHtml: true
      //       });
      //
      //     }
      //   });
      //
      //
      // });

  }


  ngOnInit() {
    this.chatService.init();
    this.chatService.subscribe();
    this.hideBackButton = true;
    // localStorage.removeItem('fromLogin');
    // localStorage.removeItem('HISTORY-SESSIONS');
    this.textMessageService.setupSocketConnection();
    this._getFavouriteList();
    this._getChatHistoryList();
    this.router.events
    .pipe(
      filter(event => event instanceof NavigationStart)
    )
    .subscribe((val:any) => {
      if(localStorage.getItem('fromLogin')) {
        this.hideBackButton = true;
        localStorage.removeItem('fromLogin');
        localStorage.removeItem('HISTORY-SESSIONS');
      } else {
        this.hideBackButton = false;
        var obj;
        if(localStorage.getItem('HISTORY-SESSIONS')) {
          obj = JSON.parse(localStorage.getItem('HISTORY-SESSIONS'));
        } else {
          obj = Object.create( {} );
        }

        if(this.currentURL != undefined) {
          obj[this.currentURL] = Object.keys(sessionStorage).reduce((obj, key) => {
            obj[key] = sessionStorage.getItem(key);
            return obj;
          }, {});

          localStorage.setItem('HISTORY-SESSIONS', JSON.stringify(obj));
        }

        this.currentURL = val.url.replace (/\//g, "_");
      }
    });

    this.currentURL = this.router.url.replace (/\//g, "_");
  }

  ngAfterViewChecked() {
   var newval;
   this.baseService.triggerEvent.subscribe(val=>{newval=val});
    if (newval != this.isBackButtonEnable) { // check if it change, tell CD update view
      this.isBackButtonEnable= newval;
      this.cdRef.detectChanges();
    }

  }
  goBack() {
    this.location.back();
  }
  ngOnDestroy(): void {
         this.unsubscribe();
  }
  unsubscribe(){
    if (this.subscription){
      this.messageNotification$.unsubscribe();
      this.status$.unsubscribe();
      this.disconnect$.unsubscribe();
    }
    // this.messageNotification$.unsubscribe();
    // this.status$.unsubscribe();
    // this.subject$.unsubscribe();
    // this.disconnect$.unsubscribe();

   // this.connectionMessage.unsubscribe();
   // this.connectionMessage$.unsubscribe();
    this.chatService.close();
  }

  removeTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/ig, '');
  }

  preventSocketTimeout(){

   /* setTimeout(()=>{
          this.chatService.preventTimeout();
           this.preventSocketTimeout();
    },environment.socketTimeout);*/
  }
  _getFavouriteList() {
    this.textMessageService.getOnlineUserFavouriteList().subscribe((data: any) => {
      this.contactsList = data;
      // console.log('this.contactsList~~~',this.contactsList);

      this.totalunreadtextmsg = 0
      this.contactsList.map((e)=>{
        this.totalunreadtextmsg += parseInt(e.unread)
      })

     this.textMessageService.notifyOnchatclick(this.totalunreadtextmsg)
    });


  }
  _getChatHistoryList(){
    this.textMessageService.getAllSendMessage().subscribe((data: any) => {
      data.reverse();

      for(let d of data){
          for(let cont of this.contactsList){
            if(d.is_read==false && d.created_by==='contact' && parseInt(d.contact_id) == parseInt(cont.contact_id)){
              cont.unread = parseInt(cont.unread) + 1;
              this.totalunreadtextmsg += 1;              
            }
           }
         }
        this.textMessageService.notifyOnchatclick(this.totalunreadtextmsg);
        {{this.totalunreadtextmsg}}
        
    });

  }
}
