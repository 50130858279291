import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from "../../../firstadmin/base.component";
import { MasterService } from "../../../core/services/master/master.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BookmarkService } from "../../../firstadmin/services/bookmark.service";
import { ConfirmationDialogService } from "../../../_utils/confirmation-dialog/confirmation-dialog.service";
import { UntypedFormControl } from "@angular/forms";
import { ClientService } from "../../../services/clients/client.service";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";

@Component({
  selector: 'app-contact-wishlist',
  templateUrl: './contact-wishlist.component.html',
  styleUrls: ['./contact-wishlist.component.css']
})
export class ContactWishlistComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() contactId;
  contactWishList: any = [];
  products: any = [];
  categories: any = [];
  wishListProducts: any = [];
  productsByCategory: any = [];
  limit: number = 10;
  skip: number = 0;

  allProdsLimit: number = 10;

  allProdsSkip: number = 0;
  queryField: UntypedFormControl = new UntypedFormControl();
  show: boolean;
  selectedProducts: any = [];
  productsFetched: number = 0;
  categoriesFetched: number = 0;
  view: string;
  categoryName: string = '';
  allProdsFetched: number = 0;
  isIndependenceDs: boolean = false;
  companyId: number;

  constructor(
    private clientsService: ClientService,
    private master: MasterService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    super();
  }

  ngOnInit() {


    if (localStorage.getItem('UserData')) {
      let UserData = JSON.parse(localStorage.getItem('UserData'));
      this.isIndependenceDs = UserData.is_independent_ds;
      this.companyId = UserData.user_customer_id;
    }


    this.show = true;
    //  ####################  Get all  Wishlist Data for Contact  ##############  //

    this.master.getContactWishlist(this.contactId, this.limit, this.skip).subscribe((data: any) => {
      this.contactWishList = data;
    });

    //    ###################  Get Products List on Query #######################  //

    this.queryField.valueChanges
      .pipe(
        map((query) => query),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(query => {
        this.products = [];
        this.skip = 0;
        this.categories = 0;
        this.categoriesFetched = 0;
        if (query.length > 0) {
          this.view = 'Products';
          let obj = {
            name: query,
            categoryName: ''
          }
          this.searchProducts(obj);
        }
        else {
          this.view = 'Categories';
          this.productsFetched = 0;
          this.wishListProducts = [];
          this.companyProductList();
        }
      });


    if (this.isIndependenceDs) {
      this.clientsService.getCompanyProductList(this.companyId).subscribe(data => {
        this.view = 'Categories';
        if (data.status) {
          this.categoriesFetched = data.data.length;
          this.getCategoryGrouping(data.data);
        } else {
          this.categories = [];
        }
      }, error => {
        console.log(error);
      });
    } else {
      this.master.getCategoriesList(this.contactId, this.limit, this.skip).subscribe(data => {
        this.view = 'Categories';
        if (data.status) {
          this.categoriesFetched = data.data.length;
          this.getCategoryGrouping(data.data);
        } else {
          this.categories = [];
        }
      });
    }

    this.ngxSpinnerService.hide();






  }

  //  ####################  Search Product  ####################################  //

  searchProducts(obj) {
    this.ngxSpinnerService.show();
    if (this.isIndependenceDs) {
      let body = {
        prodCateg: '',
        prodCustId: this.companyId,
        limit: 12,
        skip: 0,
        search: ""
      };

      this.master.getFilteredProducts(body).subscribe(result => {
        this.ngxSpinnerService.hide();
        if (result.status) {
          this.products = result.data;
          this.allProdsFetched = result.data.length;
          this.show = false;
        } else {
          this.products = [];
          this.allProdsFetched = 0;
          this.toastrService.error('No Products to add from Selected Category');
        }
        this.ngxSpinnerService.hide();
      })

    } else {this.master.searchProductForWishlist(JSON.stringify(obj), this.contactId, this.limit, this.skip).subscribe(result => {

      this.ngxSpinnerService.hide();
      if (result.status) {
        this.products = result.data;
        this.allProdsFetched = result.data.length;
        this.show = false;
      } else {
        this.products = [];
        this.allProdsFetched = 0;
        this.toastrService.error('No Products to add from Selected Category');
      }
      this.ngxSpinnerService.hide();
    });
  }
  }

  //  ####################  Get Company Product List ###########################  //

  companyProductList() {
    this.ngxSpinnerService.show();
    this.wishListProducts = [];

    if (this.isIndependenceDs) {
      this.clientsService.getCompanyProductList(this.companyId).subscribe(data => {
        this.view = 'Categories';
        this.ngxSpinnerService.hide();
        if (data.status) {
          this.categoriesFetched = data.data.length;
          this.getCategoryGrouping(data.data);
        } else {
          this.categories = [];
        }
      }, error => {
        console.log(error);
      });
    } else {
      this.master.getCategoriesList(this.contactId, this.limit, this.skip).subscribe(data => {
        this.view = 'Categories';
        this.ngxSpinnerService.hide();
        if (data.status) {
          this.categoriesFetched = data.data.length;
          this.getCategoryGrouping(data.data);
        } else {
          this.categories = [];
        }
      });
    }
  }

  /*  //  ####################  Get Categories List     ##########################  //
    getCategories(obj){
  
      this.ngxSpinnerService.show();
      this.master.searchProductForWishlist(JSON.stringify(obj), this.contactId).subscribe(data => {
        if(data.status)
        {
          this.products =data.data;
        } else {
          this.products = [];
        }
        this.ngxSpinnerService.hide();
      });
  
    } */

  //  #####################  Delete from Wish List  ##########################  //

  deleteFromWishList(id) {
    this.confirmationDialogService.confirm('ARE YOU SURE', 'YOU WANT TO REMOVE FROM WISHLIST?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.deleteProductFromWishlist(id).subscribe((data) => {
            if (data.status) {



              this.contactWishList = [];
              this.toastrService.success("Deleted from Wishlist successfully");



              if (this.view === 'Products') {
                this.skip = 0;
                this.wishListProducts = [];
                this.productsFetched = 0;
                let obj = {
                  name: this.queryField.value,
                  categoryName: ''
                }
                this.searchProducts(obj);
              } else {
                this.skip = 0;
                this.categories = [];
                this.categoriesFetched = 0;
                this.companyProductList();
              }

              this.master.getContactWishlist(this.contactId, this.limit, this.skip).subscribe((data2: any) => {
                this.contactWishList = data2;
              });
              this.ngxSpinnerService.hide();
            }
            else {
              this.toastrService.error(data.message);
              this.ngxSpinnerService.hide();
            }
          })
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  //  ###########################  Add Product to Wishlist #############################  //

  addProductToWishlist(pid) {
    let obj = {
      productId: [pid],
      contactId: this.contactId
    }

    this.ngxSpinnerService.show();
    this.master.addProductToWishlist(JSON.stringify(obj)).subscribe(data => {
      if (data.status) {
        this.ngxSpinnerService.hide();
        if (this.view === 'Products') {
          this.skip = 0;
          this.wishListProducts = [];
          this.productsFetched = 0;
          let obj = {
            name: this.queryField.value,
            categoryName: ''
          }
          this.searchProducts(obj);
        } else {
          this.skip = 0;
          this.categories = [];
          this.categoriesFetched = 0;
          this.companyProductList();
        }

        this.master.getContactWishlist(this.contactId, this.limit, this.skip).subscribe((data: any) => {
          this.contactWishList = data;
        });
        this.toastrService.success('Product added To Wishlist');
      } else {
        this.ngxSpinnerService.hide();
        this.toastrService.error(data.message);
      }
    });

  }

  
  getProductsByCategory(name) {
    this.products = [];
    this.categoryName = name;
    this.selectedProducts = [];
    let obj = {
      name: '',
      categoryName: name
    }
    this.ngxSpinnerService.show();
    if (this.isIndependenceDs) {
      let body = {
        prodCateg: name,
        prodCustId: this.companyId,
        limit: 12,
        skip: 0,
        search: ""
      };

      this.master.getFilteredProducts(body).subscribe(result => {
        this.ngxSpinnerService.hide();
        if (result.status) {
          this.products = result.data;
          this.allProdsFetched = result.data.length;
          this.show = false;
        } else {
          this.products = [];
          this.allProdsFetched = 0;
          this.toastrService.error('No Products to add from Selected Category');
        }
        this.ngxSpinnerService.hide();
      })

    } else {
      this.master.searchProductForWishlist(JSON.stringify(obj), this.contactId, this.allProdsLimit, this.allProdsSkip).subscribe(result => {
        this.ngxSpinnerService.hide();
        if (result.status) {
          console.log("result.data", result.data);
          this.products = result.data;
          this.allProdsFetched = result.data.length;
          this.show = false;
        } else {
          this.products = [];
          this.allProdsFetched = 0;
          this.toastrService.error('No Products to add from Selected Category');
        }
        this.ngxSpinnerService.hide();

      })

    }


  }

  //  ######################  On Select/Deselect of Product from Checkbox ########  //

  onProductSelectionChange(prod, event, catid) {

    if (event) {
      this.selectedProducts.push(catid);
    } else {
      let idx = this.selectedProducts.indexOf(catid);
      this.selectedProducts.splice(idx, 1);
    }

  }

  //  ###################### Add Selected Multiple Products    ###################  //

  addSelectedProducts() {
    if (this.selectedProducts.length === 0) {
      this.toastrService.error('Select at least 1 Product to Add');
    } else {
      let obj = {
        contactId: this.contactId,
        productId: this.selectedProducts
      }
      this.addMultipleProds(obj);
    }
  }

  //  ###################### Add Multiple Selected Products to Wishlist  #########  //

  addMultipleProds(obj) {
    let body = JSON.stringify(obj);
    this.ngxSpinnerService.show();
    this.master.addProductToWishlist(JSON.stringify(obj)).subscribe(data => {
      if (data.status) {
        this.ngxSpinnerService.hide();
        this.skip = 0;
        this.master.getContactWishlist(this.contactId, this.limit, this.skip).subscribe((data: any) => {
          this.contactWishList = data;
        });
        this.toastrService.success('Products added To Wishlist');
        this.selectedProducts = [];
        if (this.view === 'Products') {
          this.skip = 0;
          this.wishListProducts = [];
          this.productsFetched = 0;
          let obj = {
            name: this.queryField.value,
            categoryName: ''
          }
          this.searchProducts(obj);
        } else {
          this.skip = 0;
          this.categories = [];
          this.categoriesFetched = 0;
          this.companyProductList();
        }
        this.show = true;
        this.allProdsFetched = 0;
        this.allProdsSkip = 0;
      } else {
        this.ngxSpinnerService.hide();
        this.toastrService.error(data.message);
      }
    })

  }

  //  ###################### Go Back  ############################################  //

  goBack() {
    this.selectedProducts = [];
    this.show = true;
    this.categoryName = '';
    this.allProdsSkip = 0;
    this.allProdsFetched = 0;
    this.queryField.patchValue('');
  }

  //  ###################### Show All Products on Show Page View Selection #######  //

  showAllproducts() {
    this.products = [];
    this.selectedProducts = [];
    this.categoryName = '';
    this.ngxSpinnerService.show();
    let obj = {
      name: '',
      categoryName: ''
    }

    if (this.isIndependenceDs) {
      let body = {
        prodCustId: this.companyId,
        limit: 12,
        skip: 0,
        search: ""
      };

      this.master.getFilteredProducts(body).subscribe(result => {
        if (result.status) {
          this.products = result.data;
          this.show = false;
          this.allProdsFetched = result.data.length;
          this.ngxSpinnerService.hide();
        } else {
          this.toastrService.error(result.message);
          this.ngxSpinnerService.hide();
        }
      });

    } else {
      this.master.searchProductForWishlist(JSON.stringify(obj), this.contactId, this.allProdsLimit, this.allProdsSkip).subscribe(result => {
        if (result.status) {
          this.products = result.data;
          this.show = false;
          this.allProdsFetched = result.data.length;
          this.ngxSpinnerService.hide();
        } else {
          this.toastrService.error(result.message);
          this.ngxSpinnerService.hide();
        }
      });
    }



  }

  //  ##############  On Products List Scrolled  #########################  //

  onProductsScrolled() {
    if (this.productsFetched === this.limit) {
      this.skip++;
    } else {

      return;
    }
    let obj = {
      name: this.queryField.value,
      categoryName: ''
    }
    this.searchProducts(obj);
  }

  //  #####################  On Categories Scrolled  #############################  //

  onCategoriesScrolled() {
    if (this.categoriesFetched === this.limit) {
      this.skip++;
    } else {

      return;
    }
    this.companyProductList();
  }

  //  ##################### On Scrolling on Image wise Listed Products  #########   //

  onFullProdsScrolled() {
    if (this.allProdsFetched === this.allProdsLimit) {
      this.allProdsSkip++;
    } else {

      return;
    }

    let obj = {
      name: '',
      categoryName: this.categoryName
    }

    this.ngxSpinnerService.show();


    if (this.isIndependenceDs) {
      let body = {
        prodCustId: this.companyId,
        limit: 12,
        skip: 0,
        search: ""
      };

      this.master.getFilteredProducts(body).subscribe(result => {
        if (result.status) {
          this.allProdsFetched = result.data.length;
          this.products = [...this.products, ...result.data];
          this.ngxSpinnerService.hide();
        } else {
          if (this.allProdsFetched === this.allProdsLimit) {

          } else {
            this.products = [];
          }
          this.ngxSpinnerService.hide();
        }
      });

    } else {
      this.master.searchProductForWishlist(JSON.stringify(obj), this.contactId, this.allProdsLimit, this.allProdsSkip).subscribe(result => {
        if (result.status) {
          this.allProdsFetched = result.data.length;
          this.products = [...this.products, ...result.data];
          this.ngxSpinnerService.hide();
        } else {
          if (this.allProdsFetched === this.allProdsLimit) {

          } else {
            this.products = [];
          }
          this.ngxSpinnerService.hide();
        }
      });
    }

  }


  //  ###################### Get Grouping for Category Lists   ###################  //

  getCategoryGrouping(data) {

    if (this.categories.length > 0) {
      var tmp = [];
      for (let cats of this.categories) {
        for (let cat of cats.values) {
          tmp.push(cat);
        }
      }
      data = [...tmp, ...data];
    }

    let wishMap = new Map();
    for (let val of data) {
      let str: string = val.product_category;
      let key = str.slice(0, 1).toUpperCase();
      if (wishMap.has(key)) {
        let arrOld = wishMap.get(key);
        let arrNew = [...arrOld, val];
        wishMap.set(key, arrNew);
      } else {
        wishMap.set(key, [val]);
      }
    }
    let finalArr = [];
    wishMap.forEach(function (value, key) {
      let tmpArr = [];

      for (let val of value) {
        tmpArr.push(val);
      }
      let obj = {
        keyName: key,
        values: tmpArr
      }
      finalArr.push(obj);
    });
    this.categories = finalArr;
  }



  //  ##############  Create Products Grouping ###########################  //

  createProductsGrouping(data) {
    // this.wishListProducts = [];
    if (this.wishListProducts.length > 0) {

      var tmp = [];
      for (let prods of this.wishListProducts) {
        for (let prod of prods.values) {
          tmp.push(prod);
        }
      }
      data = [...tmp, ...data];
    }

    let wishMap = new Map();
    for (let val of data) {
      let str: string = val.product_name;
      let key = str.slice(0, 1).toUpperCase();
      if (wishMap.has(key)) {
        let arrOld = wishMap.get(key);
        let arrNew = [...arrOld, val];
        wishMap.set(key, arrNew);
      } else {
        wishMap.set(key, [val]);
      }
    }
    let finalArr = [];
    wishMap.forEach(function (value, key) {
      let tmpArr = [];

      for (let val of value) {
        tmpArr.push(val);
      }
      let obj = {
        keyName: key,
        values: tmpArr
      }
      finalArr.push(obj);
    });
    this.wishListProducts = finalArr;

  }

  ngOnDestroy(): any {
    this.confirmationDialogService.close();
  }

}
