import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from 'ngx-toastr';
import { ReskinningService } from 'src/app/_services/reskinning.service';


@Component({
  selector: 'app-editposttime',
  templateUrl: './editposttime.component.html',
  styleUrls: ['./editposttime.component.css']
})
export class EditposttimeComponent implements OnInit {

  rskmodellogoFlag = this.ReskinningService.rskModelLogoFlag;
  rskmodellogo = this.ReskinningService.rskModelLogo;
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() itimer_type: string;
  @Input() iinterval: string;
 


  constructor(private activeModal: NgbActiveModal,private toastrService: ToastrService,private ReskinningService:ReskinningService) { }
  timer_type = 'day';
  interval;
  selectDateAndTime = [{
    name: 'Day',
    id: 'day'
  }, {
    name: 'Hour',
    id: 'hour'
  }, {
    name: 'Minute',
    id: 'minute'
  }]
  ngOnInit() {
    this.timer_type=this.itimer_type;
    this.interval=this.iinterval;
  }
  public decline() {
    this.activeModal.dismiss();
    this.activeModal.close(false);
  }

  public accept() {  
    if(this.interval && this.interval>999) {
      this.toastrService.error("Enter number smaller than 999");
       return false;

    }



    if((typeof this.interval)===null  || (typeof this.interval)===undefined || this.interval===null || this.interval<1) {

      this.toastrService.error("Enter only numeric value");
      return false;

     }

    if((this.interval%1)!=0) {
      this.toastrService.error("Enter only numeric value");
     return false;

    }
    if (this.interval && this.interval !== "undefined" && this.interval !== "null") {
      this.activeModal.close({ 'timer_type': this.timer_type, 'interval': this.interval });
    }
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  changed(e) {
    this.timer_type = e.target.value;
    this.interval = 0;
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true; }
}
