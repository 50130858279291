import { Pipe, PipeTransform } from '@angular/core';
import {   parseIncompletePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js/max';

@Pipe({
  name: 'formatPhoneNumber'
})
//Will be used for Bypassing Security which are blocked by Angular
export class FormatPhoneNumber implements PipeTransform {

  constructor() {}
 
 public transform(phonenumber: any,phonecode:any):string {
   if(phonenumber && phonenumber.length>1) {	
      let phone="+"+phonecode+parseIncompletePhoneNumber(phonenumber); 
      var formatno;
      try {
      let parsedno=parsePhoneNumberFromString(phone);
      formatno=parsedno.formatNational();
      } catch (error) {
        formatno=phonenumber;
      }
      return formatno;
   } else {
     return phonenumber;
   }
  }

}
