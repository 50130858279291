<div class="scroller">
  <div class="wholePop filtMain">
<div class="modal-header d-block dialHead">
  <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" style="height: 100px;"/>  
  <button type="button" class="close float-right" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="clearfix"></div>
</div>


<div class="modal-body" *ngIf="message !== null">
  <h4 class="modal-title">{{ title }}</h4>
  <!-- {{ message }} -->
  You can download the template <a style="color: red;" href="{{message}}" download >HERE.  </a>
  <ul class="ml-0 p-1">

    <li style="text-align: left;">Required Fields include: First Name, Last Name and Email</li>
    <li style="text-align: left;">Do not delete column headers, first row shows example formats</li>

  </ul>
  <div class="uploaded" [style.display]="display">
    <i class="far fa-file-pdf"></i>
    <div class="file">
      <div class="progress">
        <div class="progress-bar progress-bar-striped progress-bar-animated"
          [ngClass]="status ? 'bg-success' :'bg-danger'" [style.width]="width">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-body" *ngIf="message == null">
  <h4 class="modal-title">{{ title }}</h4>
  <!-- {{ message }} -->

  <div class="uploaded" [style.display]="display">
    <i class="far fa-file-pdf"></i>
    <div class="file">
      <div class="progress">
        <div class="progress-bar progress-bar-striped progress-bar-animated"
          [ngClass]="status ? 'bg-success' :'bg-danger'" [style.width]="width">
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <hr> -->
<div class="modal-footer" *ngIf="message !== null">
  <div class="row">
    <div class="col">
      <div class="upload-btn-wrapper" *ngIf="!importData">
          <button class="custbtn">UPLOAD A FILE</button>
          <input #imageInput type="file" accept=".csv" (change)="processFile($event,imageInput)" multiple="false" class="">
          <i class="fa fa-upload"></i>
          <!-- <div class="upload-btn-wrapper" *ngIf="parsedCsv?.length > 0">
            <button type="button" class="btn btn-margo-action btn-round"  (click)="showConfirm(confirmBox)">View Records</button>
          </div> -->
        </div>
        <div class="upload-btn-wrapper" *ngIf="importData">
          <button class="custbtn">UPLOAD A FILE</button>
          <input #imageInput type="file" accept=".csv" (change)="readFile($event, imageInput)" multiple="false" class="">
          <i class="fa fa-upload"></i>
        </div>

    </div>
  </div>
</div>
</div>
</div>

<ng-template #confirmBox let-modal>

  <div class="modal-header dialHead w-100 pb-1 text-center d-block">
    <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" style="height: 100px;"/>    
    <button type="button" class="close float-right" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
 </div>

<div class="modal-body ">
<h5 class="mt-2 text-center">VIEW CSV FILE RECORDS</h5>

<div class="card" >
  <div class="card-body">
    <div class="row1">
      <!-- <div class="dashTblMbl"> -->
        <div class="tableScroll">
        <table cellpadding="0" cellspacing="0" border="0" class="bdrStCustom" width="100%" style="min-width:520px;">
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Mobile</th>
          </tr>
          <tr [ngClass]="!isValid?'rowInvalid':'rowValid'" *ngFor="let item of parsedCsv;let i=index">
            <td *ngIf="i !== 0">{{item[0]?item[0]:'N/A'}}</td>
            <td *ngIf="i !== 0">{{item[1]?item[1]:'N/A'}}</td>
            <td *ngIf="i !== 0">{{item[2]?item[2]:'N/A'}}</td>
            <td *ngIf="i !== 0">{{item[3]?item[3]:'N/A'}}</td>
          </tr>
          <tr *ngIf="!isValid && parsedCsv?.length == 0">No Record Found</tr>
        </table>
    </div>
      <!-- </div> -->
    </div>

  </div>
</div>

</div>
  <div class="modal-footer text-center d-block border-0">
    <button type="button" class="btn btn-margo-action btn-round"  (click)="modal.close('Cross click')">OK</button>
  </div>
</ng-template>
