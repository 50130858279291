import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BaseserviceService } from '../../services/baseservice.service';
@Injectable({
  providedIn: 'root'
})
export class TimeZoneService {
  public baseUrl;
  public baseUrl2;
  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.baseUrl1 + 'api/';
    this.baseUrl2 = baseService.baseUrl1 ;
  }

  getMomentsTimeZones() {
    return this.http.get<any>(this.baseUrl + `getMomentsTimeZones`, this.baseService.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  getMomentsTimeZoneByCountry(country) {
    var body={"country":country}
    return this.http.post<any>(this.baseUrl + `getMomentsTimeZoneByCountry`,body, this.baseService.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  getTimeZones() {
    return this.http.get<any>(this.baseUrl2 + `getTimeZones`, this.baseService.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  getTimeZone(value) {
    var body={"value":value}
    return this.http.post<any>(this.baseUrl + `getTimeZone`,body, this.baseService.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  getTimeZoneByAbbr(abbr) {
    var body={"abbr":abbr}
    return this.http.post<any>(this.baseUrl + `getTimeZoneByAbbr`,body, this.baseService.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  getTimeZoneByValue(value) {
    var body={"value":value}
    return this.http.post<any>(this.baseUrl + `getTimeZoneByValue`,body, this.baseService.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  getTimeZoneByText(text) {
    var body={"text":text}
    return this.http.post<any>(this.baseUrl + `getTimeZoneByText`,body, this.baseService.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  getTimeZoneByUtc(utc) {
    var body={"utc":utc}
    return this.http.post<any>(this.baseUrl + `getTimeZoneByUtc`,body, this.baseService.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  };
}