<div class="container mob-pad-bottom">
  <div class="row">
    <div class="col-md-10 mt-1">
      <div class="card">
        <div class="card-content">
          <div class="card-header">
            <div class="row">
              <div class="col-12">
                <h4 class="float-left mb-0 mainHeading_1">My Agreement</h4>
                <span class="backBtnPro float-right">
                </span>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-3 text-center">
                <div class="media d-flex">
                  <div class="media-body additProImg">
                    <div class="imgProUp">
                      <img *ngIf="userProfileDetails" src="{{userProfileDetails.user_photo}}" height="100px"
                        (error)="setValue($event)">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-9 mt-1 mt-md-0">

                <h4 class="font-weight-bold ttlColorBlue text-capitalize" *ngIf="userProfileDetails">
                  {{userProfileDetails.user_fname | titlecase}} {{userProfileDetails.user_lname | titlecase}}</h4>
                <i class="text-uppercase"
                  *ngIf="userProfileDetails">{{userProfileDetails.customer_company_name}}</i><br />
                <i class="text-uppercase" *ngIf="userProfileDetails">{{userProfileDetails.username}}</i>


                <div class="row mt-1">
                  <div class="col-12">
                    <div class="form-group">
                      <h6 class="font-weight-bold text-center ">{{contents.agreement_title }}</h6>
                      <h6 class="font-weight-bold text-center text-uppercase">{{contents.agreement_subtitle}}</h6><br>
                      <h4 class="mb-1 font-weight-bold">Last Updated: {{contents.last_revised_date | date:'MMMM d, y'}}</h4>

                      <div class="scroller">
                        <div  rows="100" class="filtMain ulCustom" id="demo" style="min-height: 500px;"
                        [innerHTML]="contents.agreement_text">
                        </div>
                      </div>

                    </div>

                  </div>
                  <div class="row mt-1">
                    <div class="col-12 agree-btn">
                      <button *ngIf="!isMobileDevice" printSectionId="demo"
                        class="btn btn-round btn-margo-action mb-2 mb-md-1 float-left" ngxPrint
                        [printStyle]="{h3 : {'color': '#00AEBC'}, h2 : {'border': 'solid 1px'}}">
                        Print</button>
                      <!-- <a [routerLink]="['/directseller/myaccount/myprofile']" class="card-link"><button  class="btn btn-round btn-margo-action mb-2 mb-md-1 float-left" ngxPrint -->
                      <a (click)="backButtenClick();captureDsLogs('Agreement Decline')" class="card-link"><button
                          class="btn btn-round btn-margo-action mb-2 mb-md-1 float-left">
                          Close</button></a>
                      <div class="form-group d-inline-block float-sm-right mt-md-0"
                        *ngIf="userProfileDetails && !userProfileDetails.is_terms_agreed">
                        <button class="btn btn-round btn-margo-action agree-button"
                          (click)="agreedTerms(userProfileDetails.user_ds_id);captureDsLogs('Agreement Accepted')">
                          Agree & Accept</button>
                        <!-- {{updateAgreement}} -->
                      </div>
                      <!-- <p>{{emsg}}</p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-template #confirmBox let-modal>
        <div class="modal-header dialHead">
          <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" style="height: 100px;" />
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h3>WOULD YOU LIKE TO OPTIN AGREEMENT?</h3>
        </div>
        <div class="modal-footer text-center d-block border-0">
          <button type="button" class="btn btn-margo-action btn-round text-uppercase"
            (click)="modal.dismiss('Cross click')">YES</button>
          <button type="button" class="btn btn-margo-cancel btn-round text-uppercase"
            (click)="modal.close('Cross click')">NO</button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
