import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReskinningService } from 'src/app/_services/reskinning.service';
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {
  rskmodellogoFlag = this.ReskinningService.rskModelLogoFlag;
  rskmodellogo = this.ReskinningService.rskModelLogo;
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;

  rskoutlinecolor = this.ReskinningService.rskscrollbgcolor;
  constructor(private activeModal: NgbActiveModal,private ReskinningService:ReskinningService) { }
  timerType;
  interval;

  ngOnInit() {
  }
  public decline() {
    this.activeModal.dismiss();
    this.activeModal.close(false);
  }

  public accept() {
    //this.activeModal.close(true);
    this.activeModal.close({ 'timerType': this.timerType, 'interval': this.interval });
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}
