import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ContactService {
  public baseUrl1;
  public contactserachtext=new BehaviorSubject("");
  constructor(public http: HttpClient) {
   
  }
  public httpHeadersOptions() {
    let token = localStorage.getItem("accesToken");
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    if (token) {
      httpOptions.headers = httpOptions.headers.set('x-auth', token);
    }
    return httpOptions;
  }

}
