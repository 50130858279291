<div class="container mt-1 mt-md-0">
    <div class="row">
      <div class="col-12 ">
        <div class="card">
          <div class="card-content">
            <div class="card-header">
              <h4 class="mb-0  mainHeading_1">Contact Meta Data:</h4>
            </div>
            
            <div class="card-body">

                <div class="row" *ngIf="metadata.length === 0">
                    <p>No Meta Data present for Contact</p>
                </div>

                <div class="row" *ngIf="metadata.length > 0">
                    <div class="col-md-12">

                        <div class="row mt-1">
                            <div class="col-12">

                                <div class="row mb-1" *ngFor="let meta of metadata">
                                    <div class="col-12 col-md-6 col-sm-6 font-weight-bold" *ngIf="meta.field_value && meta.field_value !== null">{{meta.label_name}}</div>
                                    <div class="col-12 col-md-6 col-sm-6" *ngIf="meta.field_value && meta.field_value !== null">{{meta.field_value}}</div>
                                </div>
                              
                            </div>
                          </div>

                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
