import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../core/services/auth.service';
import { AuthserviceService } from '../services/auth/authservice.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { CookieService } from 'ngx-cookie-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReskinningService } from 'src/app/_services/reskinning.service';
import { environment } from 'src/environments/environment';

//import {ChatService} from "../_services/chat.service";
@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  emsg
  status: boolean = false;
  isagreeduser: any;
  isRemember: boolean = false;
  rskmodellogo = this.ReskinningService.rskModelLogo;
  rskmodellogoFlag = this.ReskinningService.rskModelLogoFlag;
  @ViewChild('resendEmail',{static:false}) resendEmail:any;
  dsData:any
  backgroundImage = 'assets/custom/images/Background-Image.jpg';
  companyId=environment.companyId;
  isEmailResend=false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    public authService: AuthService,
    private authenticationService: AuthserviceService,
    public toastrService: ToastrService,
    private ngxSpinnerService: NgxSpinnerService,
    public cookieService: CookieService,
    public modalService: NgbModal,
    private ReskinningService: ReskinningService,
  ) {
    document.body.style.backgroundImage = "url(" + this.backgroundImage + ")";

  }

  ngOnInit() {
    if (localStorage.getItem("accesToken") && localStorage.getItem("UserData")) {
      this.router.navigateByUrl('directseller/home');
    }
    this.returnUrl = this.route.snapshot?.queryParams['returnUrl'] || '';
    let token = this.route.snapshot.params['token'];
    let support = this.route.snapshot.params['support'];
    let customer_company_logo = this.route.snapshot.params['customer_company_logo'];
    let customer_company_name = this.route.snapshot.params['customer_company_name'];



    if(support)
    {
      let self = this;
      localStorage.setItem("accesToken", token);
      this.authenticationService.getUserDetails().subscribe(data => {
        if (data.status) {


        if (this.cookieService.get('isRemember')) {
          this.cookieService.set('username', this.f.email.value)
        }
        var message = data.message;
        var cmylogo = data.customer_company_logo;
        var cmyname = data.customer_company_name;

          this.status = true;
          data.data.customer_company_logo = cmylogo;
          data.data.customer_company_name = cmyname;

          data.data.support_user = false;

          data.data.username = "zxwers";

          this.authenticationService.UserData.next(data.data);
          localStorage.setItem("UserData", JSON.stringify(data.data));

          if(data.reskinningObj){
            this.rskbodyBackgroundImage(data.reskinningObj);
            localStorage.setItem("ReskinningData", JSON.stringify(data.reskinningObj));
          }

         // this.authenticationService.UserData.next(data.data[0]);
          //localStorage.setItem("UserData", JSON.stringify(data.data[0]));


          if (data.data.role_id === 77) {
            setTimeout(() => {
              this.toastrService.success("Success");
            }, 500);
            this.router.navigate(['/host']);
          } else if (data.data.is_terms_agreed) {
            this.isagreeduser = data.data.is_terms_agreed
            setTimeout(() => {
              this.toastrService.success("Success");
            }, 500);

            this.router.navigateByUrl('directseller/home');
          }
          else {
            setTimeout(() => {
              this.toastrService.success("Success");
            }, 500);

            this.router.navigateByUrl('directseller/agreed');
          }
        }

        else {
          setTimeout(function () {
            self.status = false;
          }, 3000);
          self.status = true;
          self.emsg = "Invalid User";
        }
        this.ngxSpinnerService.hide();
      }, error => {
        this.ngxSpinnerService.hide();
      });


    }
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
    /* if (localStorage.getItem('UserData')) {
        this.authService.doLogout();
    } */
    if (this.cookieService.get('isRemember') == 'true' ? true : false) {
      this.f.email.setValue(this.cookieService.get('username'));
      this.isRemember = this.cookieService.get('isRemember') == 'true' ? true : false;
    }
    /* if (localStorage.getItem('accesToken')) {
        this.router.navigateByUrl('directseller/home');
    } */
  }

  rememberUsername(e) {
    this.cookieService.set('isRemember', e.target.checked);
  }
  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  tryLogin() {
    let self = this;
    // if (this.f.email.value === "undefined" && this.f.password.value === "undefined") {
    //   setTimeout(() => {
    //     this.toastrService.error("please Enter Username and Password")
    //   }, 1000);
    // }
    // this.submitted = true;
    // if (this.loginForm.invalid) {
    //   return;
    // }
    const value = {
      userName: this.f.email.value,
      password: this.f.password.value
    };

    // localStorage.setItem('typeofuser', this.f.email.value);
    this.ngxSpinnerService.show();
    if (localStorage.getItem("accesToken") && localStorage.getItem("UserData")) {
      this.authenticationService.logout();
    }
    this.authenticationService.checkLogin(value).subscribe(data => {
      if (data.status) {
        localStorage.setItem("accesToken", data.token);
        if (this.cookieService.get('isRemember')) {
          this.cookieService.set('username', this.f.email.value)
        }
        var message = data.message;
        var cmylogo = data.customer_company_logo;
        var cmyname = data.customer_company_name;
        this.authenticationService.getUserDetails().subscribe(data => {
          if (data.status) {
            this.status = true;
            data.data.customer_company_logo = cmylogo;
            data.data.customer_company_name = cmyname;
            data.data.support_user = false;
            //data.data.userid=data.data.user_ds_id;
            //data.data.parent_user_id=data.data.parent_ds_id;
            data.data.username = this.f.email.value;
            //data.data.full_name=data.data.user_fname+' '+data.data.user_lname
            this.authenticationService.UserData.next(data.data);
            localStorage.setItem("UserData", JSON.stringify(data.data));
            localStorage.setItem('isLoginPage','true');
            if(data.reskinningObj){
              this.rskbodyBackgroundImage(data.reskinningObj);
              localStorage.setItem("ReskinningData", JSON.stringify(data.reskinningObj));
            }

            // localStorage.setItem("ReskinningData",'testdata');



            localStorage.setItem("fromLogin", "1");
            localStorage.setItem("chat_notification", data.data.is_notification);
            if(!data.data?.sub_start_date && !data.data?.sub_end_date && data.data?.role_id!=77){
              localStorage.setItem('dsId',data.data.user_ds_id);
              this.getSubscriptionList(data.data)
              return;
            }
            if (data.data.role_id === 77) {
              setTimeout(() => {
                this.toastrService.success(message);
              }, 500);
              this.router.navigate(['/host']);
            } else if (data.data.is_terms_agreed) {
              this.isagreeduser = data.data.is_terms_agreed
              setTimeout(() => {
                this.toastrService.success(message)
              }, 500);
              if (data.data.is_terms_agreed && data.data.user_time_zone && data.data.user_time_zone!=="" && data.data.readyness_status==false) {
                this.router.navigateByUrl('/directseller/setup').then(() => {
                  window.location.reload();
                });
              }else{
                this.router.navigateByUrl(this.returnUrl?this.returnUrl:'directseller/home').then(() => {
                  window.location.reload();
                });
              }
            }
            else {
              setTimeout(() => {
                this.toastrService.success(message)
              }, 500);

              this.router.navigateByUrl('directseller/agreed');
            }
          }

          else {
            setTimeout(function () {
              self.status = false;
            }, 3000);
            self.status = true;
            self.emsg = "Invalid User";
          }
          this.ngxSpinnerService.hide();
        }, error => {
          this.ngxSpinnerService.hide();
        });



      } else {
        setTimeout(function () {
          self.status = false;
        }, 3000);
        self.status = true;
        self.emsg = data.message;
        this.ngxSpinnerService.hide();
      }
    }, error => {
      this.ngxSpinnerService.hide();
      setTimeout(() => {
        this.toastrService.error(error);
      }, 1000);
    });

  }


  rskbodyBackgroundImage(data) {
    var backgroundImage = '';
    backgroundImage = data.background_image;
    document.body.style.backgroundImage = "url(" + backgroundImage + ")";
  }
  checkUserVerifyData() {
    if (this.f.email.value === "undefined" && this.f.password.value === "undefined") {
      setTimeout(() => {
        this.toastrService.error("please Enter Username and Password")
      }, 1000);
    }
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const value = {
      userName: this.f.email.value,
      password: this.f.password.value
    };
    this.ngxSpinnerService.show();
    this.authenticationService
      .checkUserVerify(value)
      .subscribe(
        (res) => {
          this.dsData=res.data;
          this.ngxSpinnerService.hide();
          if (res.status) {
            if(res.verify_status){
              this.tryLogin()
            }
          } else {
            if(!res.verify_status && res.message=='Not verified'){
              this.modalOpen()
            }else{
              setTimeout(() => {
                this.toastrService.error(res.message)
              }, 1000);
            }
          }
        },
        (error) => {
          setTimeout(() => {
            this.toastrService.error(error);
          }, 1000);
          this.ngxSpinnerService.hide();
        }
      );
  }
  modalOpen() {
    let self = this;
    self.modalService.open(this.resendEmail, {  windowClass: 'view-agreement' }).result.then((result) => {
    }, (reason) => {
    });
  }
  resendVerifyEmail(){
    let body = {
      id: this.dsData.userid,
    };
    this.isEmailResend=false;
    this.ngxSpinnerService.show();
    this.authenticationService.resendVerify(body,this.companyId).subscribe({
      next: (res: any) => {
        this.ngxSpinnerService.hide();
        if (res.status == true) {
          this.isEmailResend=true;
        } else {
          this.isEmailResend=false;
        }
      },
      error: (error: any) => {
        this.isEmailResend=false;
        this.ngxSpinnerService.hide();
      },
    });
  }
  getSubscriptionList(userData:any) {
    this.ngxSpinnerService.show();
    this.authenticationService
      .getHomeSubscriptionListNew(this.companyId)
      .subscribe(
        (res) => {
          this.ngxSpinnerService.hide();
          if (res.status) {
            let subData = res.data.rows;
            if (subData && subData?.length > 0) {
              let subscriptionData = subData.find((sub: any) => sub.id==userData.sub_id);
              if(userData.subscription_type=='yearly'){
                let afterDiscountPrice=0;
                subscriptionData.subscription_type='yearly';
                afterDiscountPrice =Number(+subscriptionData.price * 12)-(Number(+subscriptionData.price * 12) / 100) * Number(subscriptionData.yearly_discount);
                subscriptionData.original_price=subscriptionData.price;
                subscriptionData.price=afterDiscountPrice;
              }
              localStorage.setItem('selectedSub',JSON.stringify({
                'selectedSub':subscriptionData,
                'selectedType':userData.subscription_type,
                'user_name': userData.full_name,
                'email':userData.user_email_primary,
                'phone':userData.user_home_phone || ''
                }))
              this.router.navigateByUrl('/checkout')
            }
          }
        },
        (error) => {
          this.ngxSpinnerService.hide();
        }
      );

  }
}
