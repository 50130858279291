<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>
<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/directseller/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Product Catalogue</li>
    </ol>
  </nav>
</div>
<div class="container mt-2 themeTblReso">
  <div class="row">
    <div class="col-sm-6">
        <section class="srchMain">
          <div class="srcInpt searchIcon">
            <!-- placeholder="Enter {{placeholder | lowercase}} name {{placeholder1 | lowercase}}" -->
            <input class="form-control"  #searchText type="text" placeholder="Search"
              autofocus />
          </div>
        </section>
    </div>

    <div class="col-sm-6">
      <div class="add-new-wrapper">
        <input type="button" value="Add New" class="btn btn-round btn-margo-action" [routerLink]="['import-product']">
      </div>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-md-6 mt-2">
      <div>

        <select name="company" class="form-control formstyle">
            <option selected disabled value=''>
              {{currentUser.customer_company_name}}
            </option>
        </select>

      </div>

    </div>
  </div> -->
</div>
<div class="container">
<div class="tblPrt">
  <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
    <tr>
      <th>Image</th>
      <!-- <th (click)="sorting('product_sku')" class="pointer"> SKU <i class=" fa fa-sort text-black"
          aria-hidden="true"></i> </th> -->
      <th (click)="sorting('product_name')" class="pointer">
        Name/ SKU/ Price <i class=" fa fa-sort text-black" aria-hidden="true"></i>
      </th>
      <th>Business Domain</th>
      <th (click)="sorting('product_category')" class="pointer"> Category <i class=" fa fa-sort text-black"
          aria-hidden="true"></i>
      </th>
      <th class='text-right'>Stock </th>
      <th (click)="sorting('modifiedon')" class="pointer"> ModifiedOn <i class=" fa fa-sort text-black"
          aria-hidden="true"></i>
      </th>
      <!-- <th> Cross-sell </th> -->
      <th>Actions </th>
    </tr>

    <tr *ngFor="let obj of productObj">

      <td *ngIf="obj.product_image!=null">
        <img [src]="obj.product_image" class="product-preview-img" ></td>
      <td *ngIf="obj.product_image==null">
        <img [src]="defaultPicture" class="product-preview-img"></td>
      <td>
        <p>Name: {{obj.product_name}}</p>
        <p>SKU: {{obj.product_sku}}</p>
        <p>Price: {{selectedCurrency}}{{obj.product_price}}</p>
        <p><span *ngIf="obj?.best_seller" class="badge badge-pill badge-info">Best Seller <i class="fa fa-star-o" aria-hidden="true"></i></span></p>
      </td>
      <!-- <td>{{obj.product_name}}</td> -->
      <!-- <td>{{ (obj.product_description.length>15)? (obj.product_description | slice:0:15)+'..':(obj.product_description)
        }}</td> -->
      <td>{{ obj?.domain_name }}</td>
      <td class="no-whitespace"> {{obj.product_category}} </td>
      <td class='pointer text-right' (click)="_stockLogsModal(stockLogsModal,obj)" *ngIf='isIndependentUser'> {{obj.stock_count?obj.stock_count:0}} </td>
      <td class='pointer text-right' (click)="_stockLogsModal(stockLogsModal,obj)" *ngIf='!isIndependentUser'> {{obj.stock_quantity?obj.stock_quantity:0}} </td>

      <td class="no-whitespace">{{obj.modifiedon | date:'MMM d, y, h:mm a' }}</td>
      <!-- <td> <i class="fa fa-edit mr-1" (click)="addUpdateCrosssell(obj.product_catalog_id)" placement="bottom"
          ngbTooltip="Edit"></i> </td> -->
      <td>
        <div class="catalogue-actionbtn">
          <ng-container *ngIf="!isIndependentUser">
            <i class="fa fa-edit mr-1" (click)="editCatalog(obj.product_catalog_id)" placement="bottom"
            ngbTooltip="Edit"></i>
          <span class="fa fa-trash mr-1"  (click)="checkProductExist($event,obj.product_catalog_id)"
            placement="bottom" ngbTooltip="Delete"></span>
          <mat-slide-toggle color='primary' class="my-slider" [checked]="obj.isactive" ngbTooltip="Update Status"
            (click)="updateStatus($event,obj.product_catalog_id)">
          </mat-slide-toggle>
          </ng-container>
          <i class="fa fa-plus" aria-hidden="true" ngbTooltip="Add Stock" (click)="_stockModal(addStockQuantity,obj)"></i>
        </div>
      </td>
    </tr>

  </table>
  <!-- <pagination-controls class="my-pagination" (pageChange)="cp=$event"></pagination-controls> -->
  <div class="text-center" *ngIf="totalCount == 0">
    <p>No Result Available</p>
  </div>

  <div class="mt-2 text-center difPagination" *ngIf="totalCount > 10">
    <ngb-pagination [collectionSize]="totalCount" [(page)]="cp" [maxSize]="5" [rotate]="true"
      (pageChange)="!this.isIndependentUser?getIndependentProductCatalogue((cp=$event)):getProductCatalogue((cp=$event))" aria-label="Custom pagination">
      <ng-template ngbPaginationPrevious>Prev</ng-template>
      <ng-template ngbPaginationNext>Next</ng-template>
    </ngb-pagination>
  </div>

  <!-- <pagination-controls *ngIf="(productObj | filter:text).length !== 0 && (productObj | filter:text).length !== 1" class="my-pagination" (pageChange)="cp=$event">
    </pagination-controls> -->
</div>
</div>
<ng-template #productCatalogue let-modal>
  <div class="scroller">
      <div class="wholePop filtMain">
          <div class="modal-header dialHead w-100">
              <h4 class="modal-title mb-0 float-left">Message</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
              </button>
              <div class="clearfix"></div>
          </div>
          <div class="modal-body">
                  <div class="row ">
                    <div class="col-md-12">
                      <h4 class='text-center'>You can't delete this product because campaign is already running.</h4>
                    </div>
                  </div>
          </div>
          <div class="d-block text-center modal-footer border-0">
              <button type="button" class="btn btn-margo-cancel btn-round text-uppercase"
                  (click)="modal.close('Cross click')">Ok</button>
          </div>
      </div>
  </div>
</ng-template>
<ng-template #addStockQuantity let-modal>
  <div class="scroller">
      <div class="wholePop filtMain">
          <div class="modal-header dialHead w-100">
              <h4 class="modal-title mb-0 float-left">Stock Quantity</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');isSubmitted=false;">
                  <span aria-hidden="true">&times;</span>
              </button>
              <div class="clearfix"></div>
          </div>
          <div class="modal-body">
            <form action="" [formGroup]="popupForm">
              <div class="row ">
                <div class="col-md-12">
                  <label for="stock">Add Stock Quantity <span style="color: red">*</span></label>
                  <input type="number" class="form-control" name="stock" id="stock" #stockQuantity
                    (input)="_checkStockInput(stockQuantity)" formControlName="stock_quantity">
                  <div *ngIf="f.stock_quantity.errors && isSubmitted">
                    <small *ngIf="f.stock_quantity.errors.required" class='text-danger'>*Stock quantity is required</small>
                    <small *ngIf="f.stock_quantity.errors.max" class='text-danger'>*Quantity should not more than 1000</small>
                  </div>
                </div>
                <ng-container *ngIf="!isIndependentUser">
                <div class="col-md-12 mt-1">
                  <label for="business_domain">Business Domains <span style="color: red">*</span></label>
                  <select formControlName="business_domain_id" class="form-control form-control-md"
                    [ngClass]="{ 'is-invalid': isSubmitted && f.business_domain_id.errors }" id="business_domain" required>
                    <option [value]="domain?.id" *ngFor="let domain of domainList">{{domain?.domain_name}}</option>
                  </select>
                  <div *ngIf="isSubmitted && f.business_domain_id.errors" class="invalid-feedback">
                    <div *ngIf="f.business_domain_id.errors.required">
                      Business Domain Name is required
                    </div>
                  </div>
                </div>
                  <div class="col-md-12 mt-1">
                    <label for="">Status</label>
                    <mat-slide-toggle color='primary' class="my-slider ml-2" [checked]="selectedProduct?.isactive" formControlName="status"
                      ngbTooltip="Update Status">
                    </mat-slide-toggle>
                  </div>
                </ng-container>
              </div>
            </form>
          </div>
          <div class="d-block text-center modal-footer border-0">
            <button type="button" class="btn btn-margo-cancel btn-round text-uppercase mr-2"
                  (click)="!isIndependentUser?_updateIndependentProductChange(stockQuantity):_updateProductChange(stockQuantity)">Submit</button>
              <button type="button" class="btn btn-margo-cancel btn-round text-uppercase"
                  (click)="modal.close('Cross click');isSubmitted=false;">Close</button>
          </div>
      </div>
  </div>
</ng-template>
<ng-template #stockLogsModal let-modal>
  <div class="scroller">
      <div class="wholePop filtMain">
          <div class="modal-header dialHead w-100">
              <h4 class="modal-title mb-0 float-left">Stock Logs</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
              </button>
              <div class="clearfix"></div>
          </div>
          <div class="modal-body">
            <div class="tblPrt">
              <table class="mt-1 bdrStCustom" width="100%" cellpadding="0" cellspacing="0">
                <tr>
                  <th>Stock Quantity</th>
                  <th>Created Date</th>
                </tr>

                <tr *ngFor="let obj of stockLogsData">
                    <td>{{obj?.product_quantity=='sell'?'-':'+'}}{{obj?.stock_quantity}}</td>
                    <td>{{obj.created_dt | date:'dd-MMM-y, h:mm a'}}</td>
                </tr>
                <tr *ngIf='stockLogsData && stockLogsData?.length>0'>
                  <td><b>Total Stock</b>: {{!isIndependentUser?selectedProduct?.stock_quantity:selectedProduct?.stock_count}}</td>
                </tr>
              </table>
              <div class="text-center" *ngIf="totalStockCount == 0">
                <p>No Result Available</p>
              </div>

              <div class="mt-2 text-center difPagination" *ngIf="totalStockCount > 10">
                <ngb-pagination [collectionSize]="totalStockCount" [(page)]="stockCp" [maxSize]="5" [rotate]="true"
                  (pageChange)="_getStockLogs((stockCp=$event))" aria-label="Custom pagination">
                  <ng-template ngbPaginationPrevious>Prev</ng-template>
                  <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
          <div class="d-block text-center modal-footer border-0">
              <button type="button" class="btn btn-margo-cancel btn-round text-uppercase"
                  (click)="modal.close('Cross click')">Close</button>
          </div>
      </div>
  </div>
</ng-template>
