<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/directseller/home">Home</a></li>
      <li class="breadcrumb-item">
        <a routerLink="/directseller/reskinning">Reskinning</a>
      </li>
      <li class="breadcrumb-item active" aria-current="page">{{ headers }}</li>
    </ol>
  </nav>
</div>

<form [formGroup]="themeForm">
  <div class="container">
    <h5 class="mb-1 font-weight-bold form_heading">Common</h5>
    <div class="card">
      <div class="card-body">
        <div class="row">

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2"
              >Logo
              <span class="label_tooltrip"
                >(Dimension should be 120 X 50)
              </span>
              <!-- <span style="color: red">*</span> -->
            </label>
            <input
              formControlName="logo"
              #imageInput1
              type="file"
              accept=".png, .jpg, .jpeg"
              (change)="processFile($event, imageInput1, 'logo')"
              [ngClass]="{ 'is-invalid': submitted && f.logo.errors }"
            />
            <div style="color: rgb(216, 114, 114); margin-left: 10px">
              Allowed file Types(.png, .jpg, .jpeg)
            </div>

            <div *ngIf="submitted && f.logo.errors" class="invalid-feedback">
              <div *ngIf="f.logo.errors.required">Logo is required</div>
            </div>

            <div class='d-flex mt-1'>
              <img src="{{ companyLogo }}" style="width: 200px" />
              <span class="fa fa-trash ml-2" *ngIf="companyLogo && headers=='Edit'" (click)='deleteLogo()'
                placement="bottom" title='Delete Logo'></span>
            </div>
          </div>

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2"
              >Background Image
              <span class="label_tooltrip"
                >(Dimension should be 1920 X 1080)
              </span></label
            >
            <input
              formControlName="backgroundImage"
              #imageInput2
              type="file"
              accept=".png, .jpg, .jpeg"
              (change)="processFile($event, imageInput2, 'backgroundImage')"
              [ngClass]="{ 'is-invalid': submitted && f.logo.errors }"
            />
            <div style="color: rgb(216, 114, 114); margin-left: 10px">
              Allowed file Types(.png, .jpg, .jpeg)
            </div>

            <!-- <div *ngIf="submitted && f.logo.errors" class="invalid-feedback">
                          <div *ngIf="f.logo.errors.required">
                              Logo Name is required
                          </div>
                      </div> -->

            <div>
              <img src="{{ bodyBackgroundImage }}" style="width: 200px" />
            </div>
          </div>
          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2">Logo Max Height (px)</label>
            <input maxlength="20" formControlName="logo_height" pattern="^[0-9]*$" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.logo_height.errors }" />
            <div *ngIf="submitted && f.logo_height?.errors?.pattern" style="color: rgb(216, 114, 114); margin-left: 10px">
             Please enter number value
            </div>
          </div>
          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2"> marGo Logo Status </label>
            <div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="customRadiolg1"
                  formControlName="logostatus"
                  [value]="true"
                  aria-selected="true"
                />
                <label class="custom-control-label" for="customRadiolg1"
                  >Active</label
                >
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="customRadiolg2"
                  formControlName="logostatus"
                  [value]="false"
                />
                <label class="custom-control-label" for="customRadiolg2"
                  >In Active</label
                >
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2"> Header Background Color </label>
            <input
              readonly
              maxlength="7"
              formControlName="headerbackgroundcolor"
              [style.background]="headerbackgroundcolor+'!important'"

              [(colorPicker)]="headerbackgroundcolor"
              class="form-control"
              (colorPickerChange)="
                onChangeColor($event, 'headerbackgroundcolor')
              "
              [value]="headerbackgroundcolor"
            />
          </div>

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2">Header Icon Color </label>
            <input
              readonly
              maxlength="7"
              formControlName="iconcolor"
              [style.background]="iconcolor +'!important'"
              [(colorPicker)]="iconcolor"
              class="form-control"
              (colorPickerChange)="onChangeColor($event, 'iconcolor')"
              [value]="iconcolor"
            />
          </div>

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2">Profile picture outline color </label>

            <input
              readonly
              maxlength="7"
              formControlName="picture_outline_color"
              [style.background]="picture_outline_color +'!important'"
              [(colorPicker)]="picture_outline_color"
              class="form-control"
              (colorPickerChange)="
                onChangeColor($event, 'picture_outline_color')
              "
              [value]="picture_outline_color"
            />
          </div>

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2"> Scroll bar color </label>

            <input
              readonly
              maxlength="7"
              formControlName="scroll_color"
              [style.background]="scroll_color +'!important'"
              [(colorPicker)]="scroll_color"
              class="form-control"
              (colorPickerChange)="onChangeColor($event, 'scroll_color')"
              [value]="scroll_color"
            />
          </div>

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2"> Url </label>
            <input maxlength="100" formControlName="url" class="form-control" />
          </div>

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2">
              Event button ( Edit / Cancel / Delete )
            </label>
            <div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="customRadiolg3"
                  formControlName="eventFlag"
                  [value]="true"
                  aria-selected="true"
                />
                <label class="custom-control-label" for="customRadiolg3"
                  >Active</label
                >
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="customRadiolg4"
                  formControlName="eventFlag"
                  [value]="false"
                />
                <label class="custom-control-label" for="customRadiolg4"
                  >In Active</label
                >
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2"> Model Logo Status </label>
            <div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="customRadiolgg3"
                  formControlName="modelLogoFlag"
                  [value]="true"
                  aria-selected="true"
                />
                <label class="custom-control-label" for="customRadiolgg3"
                  >Active</label
                >
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="customRadiolgg4"
                  formControlName="modelLogoFlag"
                  [value]="false"
                />
                <label class="custom-control-label" for="customRadiolgg4"
                  >In Active</label
                >
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2"
              >Model Logo
              <span class="label_tooltrip"
                >(Dimension should be 190 X 165)
              </span>
              <span style="color: red">*</span>
            </label>
            <input
              formControlName="model_logo"
              #imageInput3
              type="file"
              accept=".png, .jpg, .jpeg"
              (change)="processFile($event, imageInput3, 'model_logo')"
            />
            <div style="color: rgb(216, 114, 114); margin-left: 10px">
              Allowed file Types(.png, .jpg, .jpeg)
            </div>

            <!-- <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                              <div *ngIf="f.company.errors.required">Company Name is required</div>
                          </div> -->

            <!-- <div *ngIf="submitted && f.model_logo.errors" class="invalid-feedback">
                              <div *ngIf="f.model_logo.errors.required">
                                  Model Logo is required
                              </div>
                          </div> -->

            <div>
              <img src="{{ modelLogo }}" style="width: 200px" />
            </div>
          </div>


          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2"
              >Email Template Logo
              <span class="label_tooltrip"
                >(Dimension should be 115 X 35)
              </span>

            </label>
            <input
              formControlName="email_template_logo"
              #imageInput4
              type="file"
              accept=".png, .jpg, .jpeg"
              (change)="processFile($event, imageInput4, 'email_template_logo')"
            />
            <div style="color: rgb(216, 114, 114); margin-left: 10px">
              Allowed file Types(.png, .jpg, .jpeg)
            </div>

            <!-- <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                              <div *ngIf="f.company.errors.required">Company Name is required</div>
                          </div> -->

            <!-- <div *ngIf="submitted && f.model_logo.errors" class="invalid-feedback">
                              <div *ngIf="f.model_logo.errors.required">
                                  Model Logo is required
                              </div>
                          </div> -->

            <div>
              <img src="{{ emailTemplatelogo }}" style="width: 115px" />
            </div>
          </div>

          <!-- <div class="col-md-6 col-sm-6 formfield">
                      <label for="exampleForm2">Body Text Color
                      </label>

                      <input [style.background]="bodybackgroundcolor" [(colorPicker)]="bodybackgroundcolor"
                          class="form-control" (colorPickerChange)="onChangeColor($event,'bodybackgroundcolor')"
                          [value]="bodybackgroundcolor" />
                  </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <h5 class="mb-1 font-weight-bold form_heading formfield">Landing Page</h5>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2">
              Landing page text
              <span class="label_tooltrip">(Maximum Allowed length : 50) </span>
            </label>
            <input
              maxlength="50"
              type="text"
              class="form-control"
              formControlName="dsaliasname"
            />
          </div>

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2"> Landing page text color </label>
            <input
              readonly
              maxlength="7"
              type="text"
              class="form-control"
              [style.background]="dashboardtext +'!important'"
              [(colorPicker)]="dashboardtext"
              formControlName="dashboardtext"
              (colorPickerChange)="onChangeColor($event, 'dashboardtext')"
              [value]="dashboardtext"
            />
          </div>

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2">
              Hide/Show option for Password and change profile picture (Profile
              Page)
            </label>
            <div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="profileRadiol1"
                  formControlName="profileFlag"
                  [value]="true"
                  aria-selected="true"
                />
                <label class="custom-control-label" for="profileRadiol1"
                  >Active</label
                >
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="profileRadiol2"
                  formControlName="profileFlag"
                  [value]="false"
                />
                <label class="custom-control-label" for="profileRadiol2"
                  >In Active</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <h5 class="mb-1 font-weight-bold form_heading formfield">Market</h5>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2">
              Contact heading text
              <span class="label_tooltrip">(Maximum Allowed length : 20) </span>
            </label>

            <input
              maxlength="20"
              type="text"
              class="form-control"
              formControlName="contact_heading_text"
            />
          </div>

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2"> Contact heading text color </label>

            <input
              readonly
              maxlength="7"
              type="text"
              class="form-control"
              [style.background]="contact_heading_text_color +'!important'"
              [(colorPicker)]="contact_heading_text_color"
              formControlName="contact_heading_text_color"
              (colorPickerChange)="
                onChangeColor($event, 'contact_heading_text_color')
              "
              [value]="contact_heading_text_color"
            />
          </div>

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2">
              Team member heading text
              <span class="label_tooltrip">(Maximum Allowed length : 20) </span>
            </label>
            <input
              maxlength="20"
              type="text"
              class="form-control"
              formControlName="team_heading_text"
            />
          </div>

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2"> Team member heading text color </label>

            <input
              readonly
              maxlength="7"
              type="text"
              class="form-control"
              [style.background]="team_heading_text_color +'!important'"
              [(colorPicker)]="team_heading_text_color"
              formControlName="team_heading_text_color"
              (colorPickerChange)="
                onChangeColor($event, 'team_heading_text_color')
              "
              [value]="team_heading_text_color"
            />
          </div>

          <!-- <div class="col-md-6 col-sm-6 formfield">
                      <label for="exampleForm2">
                          Page content color
                      </label>
                      <input maxlength="7" type="text" class="form-control" [style.background]="page_content_font_color"
                          [(colorPicker)]="page_content_font_color" formControlName="page_content_font_color"
                          (colorPickerChange)="onChangeColor($event,'page_content_font_color')"
                          [value]="page_content_font_color">

                  </div> -->

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2"> Tab color </label>
            <input
              readonly
              maxlength="7"
              type="text"
              class="form-control"
              [style.background]="tab_color +'!important'"
              [(colorPicker)]="tab_color"
              formControlName="tab_color"
              (colorPickerChange)="onChangeColor($event, 'tab_color')"
              [value]="tab_color"
            />
          </div>

          <!-- <div class="col-md-6 col-sm-6 formfield">
                      <label for="exampleForm2">
                          Tab text color
                      </label>


                      <input readonly maxlength="7" type="text" class="form-control"
                          [style.background]="tab_text_color" [(colorPicker)]="tab_text_color"
                          formControlName="tab_text_color"

                          (colorPickerChange)="onChangeColor($event,'tab_text_color')" [value]="tab_text_color">
                  </div> -->

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2"> Button color </label>
            <input
              readonly
              maxlength="7"
              type="text"
              class="form-control"
              [style.background]="button_color +'!important'"
              [(colorPicker)]="button_color"
              formControlName="button_color"
              (colorPickerChange)="onChangeColor($event, 'button_color')"
              [value]="button_color"
            />
          </div>

          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2"> Button text color </label>
            <input
              readonly
              maxlength="7"
              type="text"
              class="form-control"
              [style.background]="button_text_color +'!important'"
              [(colorPicker)]="button_text_color"
              formControlName="button_text_color"
              (colorPickerChange)="onChangeColor($event, 'button_text_color')"
              [value]="button_text_color"
            />
          </div>


          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2">Currency</label>
            <select name="company" class="form-control formstyle" formControlName="currency">
                <option value="$" selected>$</option>
                <option value="A$">A$</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <h5 class="mb-1 font-weight-bold form_heading formfield">Mini Site</h5>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2">
              Product List
            </label>
            <div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="productList1"
                  formControlName="mini_site_product_list"
                  [value]="true"
                  aria-selected="true"
                />
                <label class="custom-control-label" for="productList1"
                  >Enable</label
                >
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="productList2"
                  formControlName="mini_site_product_list"
                  [value]="false"
                />
                <label class="custom-control-label" for="productList2"
                  >Disable</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2">
              Display Price
            </label>
            <div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="displayPrice1"
                  formControlName="mini_site_display_price"
                  [value]="true"
                  aria-selected="true"
                />
                <label class="custom-control-label" for="displayPrice1"
                  >Enable</label
                >
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="displayPrice2"
                  (change)="onDisableDisplayPrice()"
                  formControlName="mini_site_display_price"
                  [value]="false"
                />
                <label class="custom-control-label" for="displayPrice2"
                  >Disable</label
                >
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2">
              Add To Cart
            </label>
            <div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="addCart1"
                  formControlName="mini_site_add_to_cart"
                  [value]="true"
                  aria-selected="true"
                />
                <label class="custom-control-label" for="addCart1"
                  >Enable</label
                >
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="addCart2"
                  formControlName="mini_site_add_to_cart"
                  [value]="false"
                />
                <label class="custom-control-label" for="addCart2"
                  >Disable</label
                >
              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-6 formfield">
            <label for="minisite_header_bg_color"> Header BG Color </label>
            <input
              readonly
              maxlength="7"
              type="text"
              class="form-control"
              [style.background]="minisite_header_bg_color +'!important'"
              [(colorPicker)]="minisite_header_bg_color"
              formControlName="minisite_header_bg_color"
              (colorPickerChange)="onChangeColor($event, 'minisite_header_bg_color')"
              [value]="minisite_header_bg_color"
            />
          </div>

          <div class="col-md-6 col-sm-6 formfield">
            <label for="minisite_header_text_color"> Header Text Color </label>
            <input
              readonly
              maxlength="7"
              type="text"
              class="form-control"
              [style.background]="minisite_header_text_color +'!important'"
              [(colorPicker)]="minisite_header_text_color"
              formControlName="minisite_header_text_color"
              (colorPickerChange)="onChangeColor($event, 'minisite_header_text_color')"
              [value]="minisite_header_text_color"
            />
          </div>
          <div class="col-md-6 col-sm-6 formfield">
            <label for="minisite_footer_bg_color"> Footer BG Color </label>
            <input
              readonly
              maxlength="7"
              type="text"
              class="form-control"
              [style.background]="minisite_footer_bg_color +'!important'"
              [(colorPicker)]="minisite_footer_bg_color"
              formControlName="minisite_footer_bg_color"
              (colorPickerChange)="onChangeColor($event, 'minisite_footer_bg_color')"
              [value]="minisite_footer_bg_color"
            />
          </div>
          <div class="col-md-6 col-sm-6 formfield">
            <label for="minisite_footer_text_color"> Footer Text Color </label>
            <input
              readonly
              maxlength="7"
              type="text"
              class="form-control"
              [style.background]="minisite_footer_text_color +'!important'"
              [(colorPicker)]="minisite_footer_text_color"
              formControlName="minisite_footer_text_color"
              (colorPickerChange)="onChangeColor($event, 'minisite_footer_text_color')"
              [value]="minisite_footer_text_color"
            />
          </div>
          <div class="col-md-6 col-sm-6 formfield">
            <label for="minisite_body_bg_color"> Body BG Color </label>
            <input
              readonly
              maxlength="7"
              type="text"
              class="form-control"
              [style.background]="minisite_body_bg_color  +'!important'"
              [(colorPicker)]="minisite_body_bg_color"
              formControlName="minisite_body_bg_color"
              (colorPickerChange)="onChangeColor($event, 'minisite_body_bg_color')"
              [value]="minisite_body_bg_color"
            />
          </div>
          <div class="col-md-6 col-sm-6 formfield">
            <label for="minisite_body_text_color"> Body Text Color </label>
            <input
              readonly
              maxlength="7"
              type="text"
              class="form-control"
              [style.background]="minisite_body_text_color +'!important'"
              [(colorPicker)]="minisite_body_text_color"
              formControlName="minisite_body_text_color"
              (colorPickerChange)="onChangeColor($event, 'minisite_body_text_color')"
              [value]="minisite_body_text_color"
            />
          </div>
          <div class="col-md-6 col-sm-6 formfield">
            <label for="minisite_button_text_color"> Button Text Color </label>
            <input
              readonly
              maxlength="7"
              type="text"
              class="form-control"
              [style.background]="minisite_button_text_color +'!important'"
              [(colorPicker)]="minisite_button_text_color"
              formControlName="minisite_button_text_color"
              (colorPickerChange)="onChangeColor($event, 'minisite_button_text_color')"
              [value]="minisite_button_text_color"
            />
          </div>
          <div class="col-md-6 col-sm-6 formfield">
            <label for="minisite_primary_color">Primary Color </label>
            <input
              readonly
              maxlength="7"
              type="text"
              class="form-control"
              [style.background]="minisite_primary_color +'!important'"
              [(colorPicker)]="minisite_primary_color"
              formControlName="minisite_primary_color"
              (colorPickerChange)="onChangeColor($event, 'minisite_primary_color')"
              [value]="minisite_primary_color"
            />
          </div>
          <div class="col-md-6 col-sm-6 formfield">
            <label for="minisite_secondary_color">Secondary Color </label>
            <input
              readonly
              maxlength="7"
              type="text"
              class="form-control"
              [style.background]="minisite_secondary_color +'!important'"
              [(colorPicker)]="minisite_secondary_color"
              formControlName="minisite_secondary_color"
              (colorPickerChange)="onChangeColor($event, 'minisite_secondary_color')"
              [value]="minisite_secondary_color"
            />
          </div>
          <div class="col-md-10 col-xl-12">
            <div class="card-footer reskinningfooter text-center mt-1">
              <div
                class="my_class text-center"
                [ngClass]="status ? 'displayblock' : 'displaynone'"
              >
                <button
                  type="submit"
                  class="btn btn-round btn-margo-action"
                  *ngIf="status"
                  (click)="addNewReskinning()"
                >
                  Submit
                </button>
                <button
                  type="submit"
                  class="btn btn-round btn-margo-action"
                  *ngIf="status"
                  (click)="defaultSetting()"
                >
                  Reset
                </button>
                <button
                  type="submit"
                  class="btn btn-round btn-margo-action"
                  *ngIf="status"
                  (click)="clearAllSetting()"
                >
                  Clear
                </button>
                <button
                  type="submit"
                  class="btn btn-round btn-margo-action"
                  *ngIf="status"
                  (click)="cancelBtb()"
                >
                  Cancel
                </button>
              </div>
              <div
                class="my_class text-center"
                [ngClass]="status ? 'displaynone' : 'displayblock'"
              >
                <button
                  type="button"
                  class="btn btn-round btn-margo-action"
                  *ngIf="!status"
                  (click)="updateReskinning()"
                >
                  Update
                </button>
                <button
                  type="submit"
                  class="btn btn-round btn-margo-action"
                  *ngIf="!status"
                  (click)="defaultSetting()"
                >
                  Reset
                </button>
                <button
                  type="submit"
                  class="btn btn-round btn-margo-action"
                  *ngIf="!status"
                  (click)="clearAllSetting()"
                >
                  Clear
                </button>
                <button
                  type="submit"
                  class="btn btn-round btn-margo-action"
                  *ngIf="!status"
                  (click)="cancelBtb()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container">
    <h5 class="mb-1 font-weight-bold form_heading formfield">Quick Message Settings</h5>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 col-sm-6 formfield">
            <label for="exampleForm2">
              SMS / MMS Sender Virtual Number
            </label>

            <select name="ds_sender_number" id="ds_sender_number"  class="form-control" formControlName="ds_sender_number" >
              <option selected value="">Select</option>
                 <option [value]="num?.phone_no" *ngFor="let num of dsAvailableSenderNumbers">+{{num?.phone_no}}</option>
            </select>
            <div *ngIf="submitted && f.ds_sender_number.errors" class="error-text">
              <div *ngIf="f.ds_sender_number.errors.required">SMS / MMS Sender Virtual Number is required</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div> -->
</form>
