<app-header></app-header>
<app-verticalnav></app-verticalnav>


<!-- <div (window:resize)="onResize($event)"> -->
	<!-- <ng-container *ngIf="layout === 'vertical' || isMobile == true">
	</ng-container> -->


	<!-- <ng-container *ngIf="layout === 'horizontal' && isMobile == false">
		<app-horizontalnav></app-horizontalnav>
	</ng-container> -->
<!-- </div> -->
