<div class="container mt-1 mt-md-0">
  <div class="row">
    <div class="col-md-12 mb-1 mt-1 mt-md-0">
      <h4 class="font-weight-bold mainHeading_1" *ngIf="isAllowed">{{heading}}:</h4>
      <h4 class="font-weight-bold mainHeading_1" *ngIf="!isAllowed"> Not allowed to post in web Browser</h4>
    </div>
  </div>
</div>

<div class="container" *ngIf="isAllowed">
  <div class="row">
    <div class="col-md-7">

      <div class="searchHeader">

        <div class="row" *ngIf="segmentContactFlag">
          <div class="searchDropNw tablCl flex-fill px-1" *ngIf="inputdata.page == 'mycontact'">
            <select autocomplete="off" class="custom-select border-trns" [(ngModel)]="contactValue"
              (change)="selectCustomerType($event.target.value,'dropdownChange')">
              <option selected value="0">Select All</option>
              <option value="2">Customer</option>
              <option value="3">Prospect</option>
              <option value="4">Customer/Host</option>
              <option value="5">Prospect/Host</option>
            </select>

          </div>
          <div class="searchComRg tablCl flex-fill px-1">
            <section class="srchMain">
              <div class="srcInpt searchIcon">
                <input class="form-control" [formControl]="queryContactField" [(ngModel)]="contactSearch" type="text"
                  placeholder="Search" autofocus />
              </div>
            </section>
          </div>

        </div>

        <div class="row" *ngIf="btnFlag">
          <div class="searchDropNw tablCl flex-fill col-5 mt-1 px-1" *ngIf="inputdata.page == 'mycontact'">
            <select autocomplete="off" class="custom-select border-trns" [(ngModel)]="smsConsentStatus">
              <option value="Pending">Pending</option>
              <option selected value="Requested">Requested</option>
              <option value="Accepted">Accepted</option>
              <option value="Rejected">Rejected</option>
            </select>
          </div>
        </div>



        <div class="d-inline-block w-100 mt-2 mb-1">
          <div class="row">
            <div class="col-12 text-center">
              <div class="addContB addContBx text-center"
                *ngIf="contacttypename!=='teammember' && inputdata.page!=='socialhost' && !hosttype  && !btnFlag"
                [routerLink]="inputdata.addurl">
                <!-- <i class="fa fa-address-book" aria-hidden="true"></i> -->
                <img src="assets/custom/images/addConImg.png">
                <a class="linkHead_5">Add New </a>
              </div>


              <div *ngIf="inputdata?.prevRoute != 'add-guest'" class="bookMNew addContBx text-center"
                (click)="getContactsBookMarkList()">

                <!-- <i class="fa fa-bookmark-o" aria-hidden="true"></i> -->
                <img src="assets/custom/images/favritIcon.png">
                <a class="linkHead_5">FAVORITES</a>
              </div>

              <!-- <div class="addContB addContBx text-center"
                *ngIf="contacttypename!=='teammember' && inputdata.page!=='socialhost' && !hosttype  && !btnFlag"
                routerLink="/directseller/create-segments">
                <img src="assets/custom/images/addConImg.png">
                <a class="linkHead_5">Add New Segment</a>
              </div> -->

            </div>
          </div>
        </div>



      </div>






      <div class="row">
        <div class="col-6 ">
          <h4 class="font-weight-bold mb-1 subTitleHead_3">{{contacttypename == 'teammember'?'Team Members':'Contacts'}} 
          </h4>
        </div>
        <div class="col-6 text-right" *ngIf="!btnFlag"><strong>Total : </strong>{{totalRows}}</div>
      </div>

      <!-- <div class="col-md-4 col-sm-6" *ngIf="inputdata.page == 'mycontact'">
    
      <select  autocomplete="off" 
      (change)="selectCustomerType($event.target.value)">
          <option value="0">Select All</option>
          <option value="2">Customer</option>
          <option value="3">Prospect</option>
          <option value="4">Customer/host</option>
          <option value="5">Prospect/ host</option>
      </select>
     
  </div> -->
      <div class="card mb-2 mb-md-0">
        <div class="card-header p-1">


          <div class="scroller">

            <span *ngIf="results == null || results?.length == 0">No results available.</span>
            <div class="filtMain searchAcr accordBtn" *ngIf="results?.length>0" infiniteScroll
              [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false"
              bindCssVariable="rskscrollbgcolor" [bindCssVariableValue]="rskscrollbgcolor">
              <ngb-accordion [closeOthers]="true" activeIds="static-1">
                <ng-container *ngFor="let result of results; let i = index">
                  <ngb-panel *ngIf="checkForSMSConsent(result)">
                    <ng-template ngbPanelTitle>
                      <div class="row">
  
  
  
                        <div *ngIf="inputdata?.prevRoute != 'add-guest'"
                          class="col-10 col-xl-11 clrLink text-left accrdPad c-accrdpad" appThrottleClick [throttleTime]="7000"
                          (throttledClick)="selectContact(result.contactid,i);">
  
  
                          <a
                            [ngClass]="{disable: (eventInviteeList.indexOf(result.contactid) != -1) || (selectedCohost.indexOf(result.contactid) != -1) || (disableContactsWithoutNumber && result.contact_phone.trim() == ''), currentSelectedCohost: currentSelectedCohost.indexOf(result.contactid) != -1}">{{result.contactname}}</a>
                          <div class="optin-icon" *ngIf="inputdata?.page!=='myteam'">
                            <div class="opt-icons opt-email">
                              <img *ngIf="result.optin" src="../assets/images/gmailgreen.png" alt="OPT IN EMAIL" title="OPT IN EMAIL">
                              <img *ngIf="!result.optin" src="../assets/images/gmailgrey.png" alt="OPT OUT EMAIL" title="OPT OUT EMAIL">
                            </div>
                            <div class="opt-icons opt-email">
                              <img *ngIf="result.optin_sms" src="../assets/images/phone-call-green.png" alt="OPT IN SMS" title="OPT IN SMS">
                              <img *ngIf="!result.optin_sms" src="../assets/images/phone-call-grey.png" alt="OPT OUT SMS" title="OPT OUT SMS">

                            </div>
                          </div>
  
                        </div>
  
  
                       
                        <div *ngIf="inputdata?.prevRoute === 'add-guest'"
                          class="col-10 col-xl-11 clrLink text-left accrdPad" appThrottleClick [throttleTime]="7000"
                          (throttledClick)="goToContactDetails(result.contactid,i);">
                          <span *ngIf="inputdata?.prevRoute === 'add-guest'  && !btnFlag" class="cont-checkbox">
                            <input type="checkbox"   [checked]="checkInGuestList(result)"
                              (change)="selectInvitees($event, result)" (click)="stopProp($event)"
                              [disabled]="(( (eventInviteeList.indexOf(result.contactid) != -1) || (selectedCohost.indexOf(result.contactid) != -1) || (selectedHost && selectedHost==result.contactid))) || (!result.support_user_id && this.support_user) ">
                          </span>
  
                          <span *ngIf="inputdata?.prevRoute === 'add-guest' && btnFlag" class="cont-checkbox">
                            <input type="checkbox"  [checked]="checkInGuestList(result)"
                              (change)="selectInvitees($event, result)" (click)="stopProp($event)"
                              [disabled]="(( (eventInviteeList.indexOf(result.contactid) != -1) || (selectedCohost.indexOf(result.contactid) != -1) || (selectedHost && selectedHost==result.contactid))) || (!result.support_user_id && this.support_user) ||  !result.contact_phone  || segmentSelected || !result.optin_sms || result.sms_consent !== 'Accepted' || (typeofpost=='facebook' && result.psid == null) || (typeofpost=='instagram' && result.ipsid == null) ">
                          </span>
  
  
                          <a
                            [ngClass]="{disable: (selectedCohost.indexOf(result.contactid) != -1) || (disableContactsWithoutNumber && result.contact_phone.trim() == ''), currentSelectedCohost: currentSelectedCohost.indexOf(result.contactid) != -1}">{{result.contactname ? result.contactname : result.contact_fname}}</a>
                        </div>
  
  
  
                        <div class="col-2 col-xl-1 text-right accrdPad">
                          <i class="arrow down"></i>
                        </div>
                      </div>
  
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="prRow">
                        <div class="prsHstHead" *ngIf="!result.agreetohost">
                          {{getContactTypeById(result.contacttypeid)}}
                        </div>
                        <div class="prsHstHead" *ngIf="result.agreetohost">
                          {{getContactTypeById(result.contacttypeid)}} / Host
                        </div>
                      </div>
                      <div class="prRow" *ngIf="result.contact_email?.trim()">
                        <div class="prHead">
                          <strong>Email</strong>
                        </div>
                        <div class="prDet">
                          {{result.contact_email}}
                        </div>
                      </div>
                      <div class="prRow" *ngIf="result.contact_phone?.trim()">
                        <div class="prHead">
                          <strong>Primary Contact</strong>
                        </div>
                        <div class="prDet">
                          {{result.phone_code}}{{result.contact_phone}}
                        </div>
                      </div>
                    </ng-template>
                  </ngb-panel>
                </ng-container>
                

              </ngb-accordion>

            </div>
            <div class="modal-footer text-center d-block border-0 pb-0"
              *ngIf="inputdata?.prevRoute === 'add-guest' && results?.length > 0">
              <button *ngIf="!btnFlag && !shopliveId" type="button" [ngClass]="guestsList?.length == 0?'disable':''"
                [disabled]="guestsList?.length === 0" (click)="addGuest()" class="btn btn-margo-action">Add
                Guest</button>
              <!-- <button *ngIf="btnFlag" type="button" [ngClass]="guestsList?.length == 0?'disable':''"
                [disabled]="guestsList?.length === 0" (click)="viewSegmentation(segmentationmodel)"
                class="btn btn-margo-action">Add Contact</button> -->

                <button *ngIf="btnFlag" type="button" [ngClass]="guestsList?.length == 0?'disable':''"
                [disabled]="guestsList?.length === 0" (click)="viewSegmentation(segmentationmodel,'schedule')"
                class="btn btn-margo-action mb-1">Schedule</button>
              
                <button *ngIf="btnFlag" type="button" [ngClass]="guestsList?.length == 0?'disable':''"
                [disabled]="guestsList?.length === 0" (click)="viewSegmentation(segmentationmodel,'send now')"
                class="btn btn-margo-action mb-1">Send Now</button>

                <button *ngIf="btnFlag" type="button" (click)="reset()" class="btn btn-margo-action mb-1">RESET</button>


               

                
            </div>
          </div>



          <ng-template #rt let-r="result" let-t="query">
            <li class="mw-100" ng-class="{ 'active': active=='one'}">{{ r }}</li>
          </ng-template>

        </div>

        <div class="card-footer pt-1 pb-1 shwPgfoot"
          *ngIf="((inputdata.page ==='market' && contacttypeid!==1 ) || inputdata.page === 'myhost') && results && results?.length==0 && queryContactField.value?.length>0">

          <a style="cursor: pointer;" class="artist-name1" [routerLink]="inputdata.searchallcontacturl">
            Global Search</a>

        </div>
      </div>


    </div>

    <div class="col-md-5 mrg-border-left">


      
      <!-- <div *ngIf = "contacttypename!=='teammember' && contacttypename!=='host'> -->
      <div *ngIf="contacttypename!=='teammember' && contacttypename!=='host' && !btnFlag " class="mb-1 recent-bg">
        <h4 class="font-weight-bold mb-1 subTitleHead_3">Recently Contacted:</h4>
        <span *ngIf="recentOrderContacts && recentOrderContacts.length==0">No results available.</span>

        <span *ngIf="inputdata?.prevRoute === 'add-guest'">
          <a *ngFor="let result of recentOrderContacts ; let i = index" class="artist-name"
            [ngClass]="{'active': selectedContact==result.contactid}">
            {{result.contactname}}<br /></a>

        </span>
        <span *ngIf="inputdata?.prevRoute != 'add-guest'">
          <a href="#" *ngFor="let result of recentOrderContacts ; let i = index" class="artist-name"
            [ngClass]="{'active': selectedContact==result.contactid}"
            (click)="selectContact(result.contactid,i,'recent')">
            {{result.contactname}}<br /></a>

        </span>




        <ng-template #rt let-r="result" let-t="query">
          <li class="mw-100">{{ r}}</li>
        </ng-template>

      </div>

      <div *ngIf="contacttypename!=='teammember' && contacttypename!=='host' && btnFlag && segmentContactFlag" class="mb-1 recent-bg">
        <h4 class="font-weight-bold mb-1 subTitleHead_3">Recent Segment:</h4>
        <span *ngIf="recentSegment && recentSegment.length==0">No results available.</span>

        <span *ngIf="inputdata?.prevRoute === 'add-guest'">
          <label *ngFor="let result of recentSegment" class="artist-name"
            [ngClass]="{'active': selectedContact==result.segment_id}" >
            <input [(ngModel)]="selectedValue" type="radio"   [value]="result.segment_id"  [disabled]='segmentFlag' (click)="confirmSegmentModel(result.segment_id)">
              {{result.segment_name}}
              <a (click)="openContactsConsentModal($event, result.segment_id)">({{result?.contacts.length}})</a>
          </label>
        </span>
        
        <ng-template #rt let-r="result" let-t="query">
          <li class="mw-100">{{ r}}</li>
        </ng-template>
      </div>

      <div *ngIf="bookmarkresults">

        <h4 class="font-weight-bold mb-1 subTitleHead_3">Favorites:</h4>
        <span *ngIf="bookmarkresults && bookmarkresults.length==0">No results available.</span>



        <h5 class="bookMTitle"
          *ngIf="inputdata.hosttype !='addcohost' && inputdata.hosttype !='host' && inputdata.hosttype !='cohost' && inputdata.page !='socialhost' && inputdata?.page != 'quicktext'">
          <a *ngIf="bookmarkresults && bookmarkresults.length!==0" href="javascript:void(0)"
            routerLink="/directseller/bookmarklist">View/Manage Favorites</a></h5>

        <a class="clearfix mt-1" *ngFor="let result of bookmarkresults ; let i = index" class="artist-name"
          [ngClass]="{disable: (selectedCohost.indexOf(result.contactid) != -1) || (disableContactsWithoutNumber && result.contact_phone.trim() == ''), currentSelectedCohost: currentSelectedCohost.indexOf(result.contactid) != -1}"
          (click)="selectContact(result.contactid,i,'bookmark')">
          {{result.contactname}}<br /></a>








      </div>


    </div>

  </div>

</div>



<ng-template #segmentationmodel let-modal role="dialog" style="z-index:999999" data-keyboard="false"
  data-backdrop="static">
  <div class="scroller">
    <div class="wholePop filtMain">
      <div class="modal-header dialHead card-header">
        <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" />
        <button type="button" class="close" aria-label="Close" (click)="closeSegmentationModel('categorydata')">
          <span aria-hidden="true">&times;</span>
        </button>


      </div>
      <div class="profile-b text-center p-1">
        <div class="d-block">
          <h5 class="text-uppercase">Add New Segment </h5>
        </div>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <!-- <div class="card">
              <div class="card-body">
                <div class="form-group">
                  <label>  Do You want to add the contact as segment</label>
                  <br/>
                  Yes <input name="segment" type="radio" value="Yes" (click)="getRadioValue($event)">
                  No <input name="segment" type="radio" value="No" (click)="getRadioValue($event)">
                </div>
              </div>
            </div> -->
            <div class="card">
              <div class="card-body">
                <form class="form-horizontal" [formGroup]="categForm">
                  
                  <div class="form-group">
                    <label for="categname" class="required col-8">SEGMENT NAME:</label>
                    <input type="text" placeholder="Enter Segment Name" appSpace class="form-control" name='categname' formControlName="categname"  
                    [ngClass]="{ 'is-invalid': submitted && f.categname.errors }" maxLength="50" 
                    >
                    <div *ngIf="submitted && f.categname.errors" class="invalid-feedback">
                      <div *ngIf="f.categname.errors.required"> Segment name is required</div>
                    </div>
                  </div>

                  <div class="text-center">
                    <button type="submit" class="btn btn-round btn-margo-action" (click)="saveSegmentName()">SAVE</button>
                    <button type="button" class="btn btn-round btn-margo-action" (click)="closeSegmentationModel('categorydata')">CANCEL</button>
                    
                  </div>
                </form>
              </div>
            </div>
          </div>

          <!-- <app-mbcategory class="w-100" [CategoryTypeID]="categoryTypeId" [CategoryType]="category"
              [categoryData]="categoryData" (messageToEmit)="getSelectedCategory($event)"></app-mbcategory> -->
        </div>
      </div>

    </div>
  </div>
</ng-template>

<ng-template #segmentContactCheckModal let-modal role="dialog" style="z-index:999999" data-keyboard="false"
  data-backdrop="static">
  <div class="scroller">
    <div class="wholePop filtMain">
      <div class="modal-header dialHead card-header">
        <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" />
        <button type="button" class="close" aria-label="Close" (click)="cancelSegmentSelection()">
          <span aria-hidden="true">&times;</span>
        </button>


      </div>
      <div class="profile-b text-center p-1">
        <div class="d-block">
          <h5 class="text-uppercase">Segment Contacts List Consent</h5>
        </div>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                  
                <div class="tblPrt ">
                  <table *ngIf="tempSegmentContacts  && tempSegmentContacts.length>0" class=" bdrStCustom" width="100%">
                    <tr>
                      <th>Contact Name</th>
                      <th>Contact Email</th>
                      <th>Contact Phone</th>
                      <th>Consent Status</th>
                    </tr>
                    <tr *ngFor="let contact of tempSegmentContacts; let i = index" >
                      <td>{{contact?.contact_fname}} {{contact?.contact_lname}}</td>
                      <td> {{contact?.contact_email}}</td>
                      <td>{{contact?.contact_phone}}</td>
                      <td>{{contact?.sms_consent}}</td>
                    </tr>
                  </table>
                  <p *ngIf="tempSegmentContacts  && tempSegmentContacts.length === 0">No Contacts Found !!</p>
                </div>

                  <div class="text-center">
                    <!-- <button type="submit" class="btn btn-round btn-margo-action" (click)="modal.close('Cross click'); clearTempArr()" *ngIf="tempSegmentContacts  && tempSegmentContacts.length > 0">CONTINUE</button> -->
                    <button type="button" class="btn btn-round btn-margo-action" (click)="cancelSegmentSelection()">CLOSE</button>
                    
                  </div>
                
              </div>
            </div>
          </div>

          <!-- <app-mbcategory class="w-100" [CategoryTypeID]="categoryTypeId" [CategoryType]="category"
              [categoryData]="categoryData" (messageToEmit)="getSelectedCategory($event)"></app-mbcategory> -->
        </div>
      </div>

    </div>
  </div>
</ng-template>