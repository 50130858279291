import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MiniurlService } from 'src/app/services/miniurl.service';

@Component({
  selector: 'app-mini-url',
  templateUrl: './mini-url.component.html',
  styleUrls: ['./mini-url.component.css']
})
export class MiniUrlComponent implements OnInit {

  constructor(private route:ActivatedRoute,
    private miniService:MiniurlService,
    private router:Router) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe( (paramMap:any) => {
      if(paramMap.params?.code){
        let code =paramMap.params?.code;
        this._getMiniUrl(code);
      }
  })
  }
  _getMiniUrl(code:any){
    this.miniService.getMiniUrlByCode(code).subscribe((res:any)=>{
      if(res.status==true){
        if(res.data && res.data?.url){
          window.location.href = res.data.url;
        }else{
          this.router.navigate(['/directseller/home'])
        }
      }else{
        this.router.navigate(['/directseller/home'])
      }
    },(error:any)=>{

    })
  }
}
