import { Component, OnDestroy, OnInit } from '@angular/core';
import { MasterService } from 'src/app/core/services/master/master.service';
import { BaseComponent } from 'src/app/firstadmin/base.component';
import { UntypedFormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { ConfirmationDialogService } from "../../../_utils/confirmation-dialog/confirmation-dialog.service";


@Component({
  selector: 'app-contact-attribute',
  templateUrl: './contact-attribute.component.html',
  styleUrls: ['./contact-attribute.component.css']
})
export class ContactAttributeComponent extends BaseComponent implements OnInit, OnDestroy {

  queryContactField: UntypedFormControl = new UntypedFormControl();
  contactAttributes: any = [];
  listOfAttributes: any = [];
  tmpListOfAttributes: any = [];
  contactid = sessionStorage.getItem("contactid");
  limit: number = 20;
  skip: number = 0;
  attrsRecordFetched: number = 0;

  constructor(private master: MasterService,
              private confirmationDialogService: ConfirmationDialogService) {
    super();
    this.baseService.changeValue(false);
  }

  ngOnInit() {

    //   ################  Get Added Attributes for Contact #################  //


    this.master.getContactAttributes(this.contactid).subscribe((data: any) => {
      this.contactAttributes = data;
    });

    //  ################# Search attributes ###########################  //

    this.queryContactField.valueChanges
      .pipe(
        map((query) => query),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(query => {
        this.skip = 0;
        this.tmpListOfAttributes = [];
        this.attrsRecordFetched = 0;
        this.getAttributes(query);
      });

    //  ###############  Get Attributes on Load #######################  //

    this.getAttributes('');
  }

  //  ###########  Search for Attributes to Add for Contact  ########################  //

  getAttributes(query) {
    this.ngxSpinnerService.show();
    this.master.searchContactAttributes(this.contactid, query, this.limit, this.skip).subscribe((data: any) => {
      if (data.status){
        this.attrsRecordFetched = data.data.length;
        this.tmpListOfAttributes = [...this.tmpListOfAttributes, ...data.data];
        this.createGroup(this.tmpListOfAttributes);
      } else {
        if (this.attrsRecordFetched === this.limit){

        } else {
          this.tmpListOfAttributes = [];
          this.listOfAttributes = [];
        }
      }
      this.ngxSpinnerService.hide();
    });
  }

  //  ##############  Add Attribute for Contact  ############  //

  addContactAttribute(attrid) {
    let obj = {attributeId: attrid, contactId: this.contactid}
    let body = JSON.stringify(obj);
    this.ngxSpinnerService.show();
    this.master.addContactAttributes(body).subscribe((data: any) => {
      if (data.status){
        this.tmpListOfAttributes = [];
        this.skip = 0;
        if (this.queryContactField.value === null){
          this.getAttributes('');
        } else {
          this.getAttributes(this.queryContactField.value);
        }

        //  this.listOfAttributes = [];
        this.master.getContactAttributes(this.contactid).subscribe((data: any) => {
          this.contactAttributes = data;
        });
        this.ngxSpinnerService.hide();
        this.toastrService.success('Successfully Added Attribute for Contact');

      }else {
        this.toastrService.error(data.message);
        this.ngxSpinnerService.hide();
      }

    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  //  ##############  Delete Attribute for Contact ###########  //

  deleteAttribute(id) {
    this.confirmationDialogService.confirm('ARE YOU SURE', 'YOU WANT TO REMOVE THE ATTRIBUTE?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.master.deleteContactAttributes(id).subscribe((data) => {
            if (data.status) {
              this.tmpListOfAttributes = [];
              this.skip = 0;
              if (this.queryContactField.value === null){
                this.getAttributes('');
              } else {
                this.getAttributes(this.queryContactField.value);
              }



              this.contactAttributes = [];
              this.toastrService.success("Attribute deleted successfully");



              this.master.getContactAttributes(this.contactid).subscribe((data2: any) => {
                this.contactAttributes = data2;
              });
              this.ngxSpinnerService.hide();
            }
            else {
              this.toastrService.error(data.message);
              this.ngxSpinnerService.hide();
            }
          })
        }
      }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  //  #############  On Table scrolled  #########################  //

  onScrolled(){
    if(this.attrsRecordFetched < this.limit){
     
      return;
    } else {
      this.skip++;
    }
    // this.skip++;
    if (this.queryContactField.value === null){
      this.getAttributes('');
    } else {
      this.getAttributes(this.queryContactField.value);
    }
  }

  //  #############   Create Grouping for Attributes   ##########  //

  createGroup(data){
    let atrMap = new Map();
    for (let val of data){
      let str: string = val.attribute_name;
      let key = str.slice(0, 1).toUpperCase();
      if (atrMap.has(key)){
        let arrOld = atrMap.get(key);
        let arrNew = [...arrOld, val];
        atrMap.set(key, arrNew);
      } else {
        atrMap.set(key, [val]);
      }
    }
    let finalArr = [];
    atrMap.forEach(function (value, key) {
      let tmpArr = [];

      for (let val of value){
        tmpArr.push(val);
      }
      let obj = {
        keyName: key,
        values: tmpArr
      }
      finalArr.push(obj);
    });

    this.listOfAttributes = finalArr;
  }

  ngOnDestroy(): any {
    this.confirmationDialogService.close();
  }
 
}
