import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BaseComponent } from "../../base.component";
import {
  UntypedFormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  AbstractControl,
  UntypedFormArray,
} from "@angular/forms";
import { ProductCatalogueService } from "src/app/services/product-catalogue/product-catalogue.service";
import { AlertService } from "src/app/_services/alert.service";
import { RegisterService } from "src/app/services/users/register.service";
import { UploadService } from "src/app/services/upload/upload.service";
import { ConfirmationDialogService } from "src/app/_utils/confirmation-dialog/confirmation-dialog.service";
@Component({
  selector: "app-add-product-catalogue",
  templateUrl: "./add-product-catalogue.component.html",
  styleUrls: ["./add-product-catalogue.component.css"],
})
export class AddProductCatalogueComponent
  extends BaseComponent
  implements OnInit
{
  productname;
  defaultImage = "assedefault_product.JPG";
  theme_description;
  screenshot: String;
  IsActive;
  product_id;
  themObj;
  imageCropData;
  imgButtonText;
  fileData;
  fileUploaded;
  imageSrc;
  them_awskey = "";
  them_awskey2 = "";
  them_awskey3 = "";
  them_awskey4 = "";
  them_awskey5 = "";
  them_company;
  getCompanyobj;
  theme_screenshot;
  theme_screenshot2;
  theme_screenshot3;
  theme_screenshot4;
  theme_screenshot5;
  productImage1;
  productImage2;
  productImage3;
  productImage4;
  productImage5;
  productImage6;
  status: boolean = true;
  screenshot_type = true;
  screenshot_type2 = true;
  screenshot_type3 = true;
  screenshot_type4 = true;
  screenshot_type5 = true;

  myFiles: string[] = [];

  headers = "Add";
  companyID;
  correctImage: boolean = true;
  controlEnabled: boolean = true;
  submitted = false;
  themeForm: UntypedFormGroup;
  myNewFiles: string[] = [];
  productSku = "";
  metaVisibility: boolean = false;
  metafields = [];
  metaForm: UntypedFormGroup;
  userProfileDetails: any;
  uid: any;
  productCataloguePath = "Products/dev";
  currentUser: any;
  productCategory: any;
  selectedCategory: boolean=true;
  domainList: any = [];
  productImages:any[]=['image1','image2','image3','image4','image5','video']
  isImageUpload2=false;
  isImageUpload3=false;
  isImageUpload4=false;
  isImageUpload5=false;
  constructor(
    private master: ProductCatalogueService,
    // private clientService: ClientService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    public toaster: ToastrService,
    private registerService:RegisterService,
    private uploadService: UploadService,
    private confirmationDialogService: ConfirmationDialogService,
  ) {
    super();
  }

  ngOnInit() {
    if (localStorage.getItem("UserData")) {
      var userdata = JSON.parse(localStorage.getItem("UserData"));
      this.currentUser = userdata;
    }
    this.getDomains(this.currentUser?.user_customer_id)
    let self = this;
    this.activatedRoute.queryParams.subscribe((params) => {
      this.product_id = params.id;
      this.companyID = params.companyID;
    });

    //get item details using id

    if (this.product_id) {
      this.ngxSpinnerService.show();
      this.headers = "Edit";
      this.master

        .getProductCatalogById(this.companyID, this.currentUser.user_ds_id,this.product_id)

        .subscribe((response) => {
          //console.log('response111', response.data[0]);
          this.ngxSpinnerService.hide();
          if (response.status) {
            this.status = false;
            // console.log("product data",response.data);
            // console.log("product data",response.data.rows[0] );
            self.themObj = response.data.rows[0];
            // console.log(self.themObj)
            self.dataBinding();
          } else {
          }
        });
    }
    this.themeForm = this.formBuilder.group({
      business_domain_id:['',[Validators.required]],
      company: [this.currentUser?.user_customer_id, Validators.required],
      productname: [
        "",
        [
          Validators.required,
          Validators.maxLength(200),
          this.noWhitespaceValidator,
        ],
      ],
      productdescription: ["", [Validators.maxLength(2000)]],
      productsku: ["", [Validators.required]],
      productcategory: ["", [Validators.required]],
      productprice: [""],
      productlink: ["", [Validators.required, this.ValidateUrl]],
      firstActive: [true],
      secondActive: [true],
      thirdActive: [true],
      fourthActive: [true],
      fifthActive:[true],
      productPicture: ["", Validators.required],
      productPicture2: [""],
      productPicture3: [""],
      productPicture4: [""],
      product_video:[""],
      no_of_days_cross: [0],
      no_of_days_replenishment: [0],

      ds_id:[this.currentUser.user_ds_id],
      selected_category:[true],
      product_metadata1:[''],
      product_metadata2:[''],
      product_metadata3:[''],
      product_metadata4:[''],
      stock_quantity:['',Validators.required],
      isactive:[],
      website_product_id:[],
      product_type:['online'],
      best_seller:[false]
    });
    this.getProductCategoryByCompanyID(this.currentUser?.user_customer_id)

  }

  get g() {
    return this.metaForm.controls;
  }
  get t() {
    return this.g.metaFields as UntypedFormArray;
  }

  /* Check for Boolean Validator Input */

  checkBool(control: UntypedFormControl) {
    let val = control.value;
    if (val === "true" || val === "false" || val === "") {
      return null;
    }
    return {
      boolCheck: {
        val: false,
      },
    };
  }

  get f() {
    return this.themeForm.controls;
  }
  radioChange(type) {
    if (type == "image1") {
      this.screenshot_type = !this.screenshot_type;
    }else if(type == "image2"){
      this.screenshot_type2 = !this.screenshot_type2;
    }else if(type == "image3"){
      this.screenshot_type3 = !this.screenshot_type3;
    }else if(type == "image4"){
      this.screenshot_type4 = !this.screenshot_type4;
    }else if(type == "video"){
      this.screenshot_type5 = !this.screenshot_type5;
    }
  }
  radioChange2(type) {
    if(type=='other'){
      this.themeForm.controls.productcategory.setValue('');
      this.selectedCategory=!this.selectedCategory
    }
  }
  addNewProduct() {
    this.submitted = true;
    if (this.themeForm.invalid) {
      console.log(this.themeForm);
      return;
    }
    let {
      company,
      productPicture,
      productPicture2,
      productPicture3,
      productPicture4,
      product_video,
      productname,
      productdescription,
      productsku,
      productcategory,
      productprice,
      productlink,
      no_of_days_cross,
      no_of_days_replenishment,
      business_domain_id,
      ds_id,
      product_metadata1,
      product_metadata2,
      product_metadata3,
      product_metadata4,
      firstActive,
      secondActive,
      thirdActive,
      fourthActive,
      fifthActive,
      stock_quantity,
      website_product_id,
      product_type,
      best_seller
    } = this.themeForm.value;
    // productPicture = this.them_awskey;

    let catalogObj = {
      name: productname,
      description: productdescription,
      sku: productsku,
      category: productcategory,
      price: productprice,
      link: productlink,
      isactive: true,
      company: company,
      image: productPicture,
      product_image2: productPicture2,
      product_image3: productPicture3,
      product_image4: productPicture4,
      product_video:product_video,
      no_of_days_cross: no_of_days_cross,
      no_of_days_replenishment: no_of_days_replenishment,
      business_domain_id:business_domain_id,
      ds_id:ds_id,
      product_metadata1:product_metadata1 ||'',
      product_metadata2:product_metadata2 || '',
      product_metadata3:product_metadata3 || '',
      product_metadata4:product_metadata4 || '',
      is_image:firstActive==true?0:1,
      is_image2:secondActive==true?0:1,
      is_image3:thirdActive==true?0:1,
      is_imag4:fourthActive==true?0:1,
      is_video:fifthActive==true?0:1,
      stock_quantity:stock_quantity,
      website_product_id:website_product_id,
      product_type:product_type,
      best_seller:best_seller
    };
    this.ngxSpinnerService.show();
    this.master.addingCatalog(catalogObj).subscribe(
      (data) => {
        if (data.status) {
          this.toastrService.success("Added Successfully");
          this.router.navigateByUrl(`/directseller/productCatalogue`);
        } else {
          this.toastrService.error(data.message);
          this.ngxSpinnerService.hide();
        }
        this.ngxSpinnerService.hide();
      },
      (error) => {
        console.log(error);
        this.ngxSpinnerService.hide();
      }
    );
  }

  dataBinding() {
    this.screenshot_type=this.themObj.is_image==0?true:false
    this.screenshot_type2=this.themObj.is_image2==0?true:false
    this.screenshot_type3=this.themObj.is_image3==0?true:false
    this.screenshot_type4=this.themObj.is_image4==0?true:false
    this.screenshot_type5=this.themObj.is_video==0?true:false
    if (this.isUrlValid(this.themObj.product_image)) {
      this.theme_screenshot = this.isUrlValid(this.themObj.product_image)
        ? this.themObj.product_image
        : this.themObj.product_image;
    }
    if (this.themObj.product_image2  && this.isUrlValid(this.themObj.product_image2)) {
      this.theme_screenshot2 = this.isUrlValid(this.themObj.product_image2)
        ? this.themObj.product_image2
        : '';
    }
    if (this.themObj.product_image3  &&  this.isUrlValid(this.themObj.product_image3)) {
      this.theme_screenshot3 = this.isUrlValid(this.themObj.product_image3)
        ? this.themObj.product_image3
        : '';
    }
    if (this.themObj.product_image4 && this.isUrlValid(this.themObj.product_image4)) {
      this.theme_screenshot4 = this.isUrlValid(this.themObj.product_image4)
        ? this.themObj.product_image4
        : '';
    }
    if (this.themObj.product_video && this.isUrlValid(this.themObj.product_video)) {
      this.theme_screenshot5 = this.isUrlValid(this.themObj.product_video)
        ? this.themObj.product_video
        : '';
    }
    this.themeForm = this.formBuilder.group({
      business_domain_id:[this.themObj.business_domain_id],
      company: [this.themObj.customer_id],
      productname: [this.themObj.product_name],
      productdescription: [this.themObj.product_description],
      productsku: [this.themObj.product_sku, [Validators.required]],
      productcategory: [this.themObj.product_category, [Validators.required]],
      productprice: [
        this.themObj.product_price,
        [],
      ],
      productlink: [
        this.themObj.product_link,
        [Validators.required, this.ValidateUrl],
      ],
      firstActive: [this.themObj.is_image==0?true:false],
      secondActive: [this.themObj.is_image2==0?true:false],
      thirdActive: [this.themObj.is_image3==0?true:false],
      fourthActive: [this.themObj.is_image4==0?true:false],
      fifthActive: [this.themObj.is_video==0?true:false],
      selected_category:[true],
      no_of_days_cross: [
        this.themObj.number_of_days_crosssell,
        [Validators.min(0)],
      ],
      no_of_days_replenishment: [
        this.themObj.number_of_days_replenishment,
        [Validators.min(0)],
      ],
      productPicture: [this.themObj.product_image],
      productPicture2: [this.themObj.product_image2],
      productPicture3: [this.themObj.product_image3],
      productPicture4: [this.themObj.product_image4],
      product_video: [this.themObj.product_video],
      product_metadata1:[this.themObj.product_metadata1],
      product_metadata2:[this.themObj.product_metadata2],
      product_metadata3:[this.themObj.product_metadata3],
      product_metadata4:[this.themObj.product_metadata4],
      stock_quantity:[this.themObj?.stock_quantity],
      isactive:[this.themObj?.isactive],
      website_product_id:[this.themObj?.website_product_id],
      product_type:[this.themObj?.product_type],
      best_seller:[this.themObj?.best_seller]
    });
  }

  updateProduct() {
    this.submitted = true;

    if (this.themeForm.invalid || !this.correctImage) {
      return;
    }

    let {
      company,
      productPicture,
      productPicture2,
      productPicture3,
      productPicture4,
      product_video,
      productname,
      productdescription,
      productsku,
      productcategory,
      productprice,
      productlink,
      no_of_days_cross,
      no_of_days_replenishment,
      business_domain_id,
      ds_id,
      product_metadata1,
      product_metadata2,
      product_metadata3,
      product_metadata4,
      firstActive,
      secondActive,
      thirdActive,
      fourthActive,
      fifthActive,
      stock_quantity,
      isactive,
      website_product_id,
      product_type,
      best_seller
    } = this.themeForm.value;

    // if (this.isUrlValid(this.them_awskey)) {
    //   productPicture = this.them_awskey;
    // } else {
    //   productPicture = this.themObj.product_image;
    // }
    // productPicture = productPicture ? productPicture : this.them_awskey;

    let catalogObj = {
      business_domain_id:business_domain_id,
      product_catalog_id: this.product_id,
      company: company,
      name: productname,
      description: productdescription,
      sku: productsku,
      category: productcategory,
      price: productprice,
      link: productlink,
      image: productPicture,
      product_image2: productPicture2,
      product_image3: productPicture3,
      product_image4: productPicture4,
      product_video:product_video,
      no_of_days_cross: no_of_days_cross,
      no_of_days_replenishment: no_of_days_replenishment,

      ds_id:ds_id,
      product_metadata1:product_metadata1,
      product_metadata2:product_metadata2,
      product_metadata3:product_metadata3,
      product_metadata4:product_metadata4,
      is_image:firstActive==true?0:1,
      is_image2:secondActive==true?0:1,
      is_image3:thirdActive==true?0:1,
      is_image4:fourthActive==true?0:1,
      is_video:fifthActive==true?0:1,
      stock_quantity:stock_quantity,
      isactive:isactive,
      website_product_id:website_product_id,
      product_type:product_type,
      best_seller:best_seller
    };

    // console.log("---CatalogObj---", catalogObj);
    // return false;

    this.ngxSpinnerService.show();
    this.master.UpdateCatalog(catalogObj).subscribe(
      (data) => {
        if (data.status) {
          this.toastrService.success(" Updated Successfully");
          this.router.navigateByUrl(`/directseller/productCatalogue`);
          this.ngxSpinnerService.hide();
        } else {
          this.ngxSpinnerService.hide();
          this.toastrService.error(data.message);
        }
      },
      (error) => {
        console.error(error);
        this.ngxSpinnerService.hide();
      }
    );
  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length);
      if (!this.isUrlValid(url)) {
        let awsUrl = self.master.getUrlfile(url);
        self.them_awskey = url;
        event.target.src = self.isUrlValid(awsUrl)
          ? awsUrl
          : "../../../../assets/custom/images/broken.png";
      } else {
        event.target.src = "../../";
      }
    } else {
      event.target.src = "../../../assets/custom/images/broken.png";
    }
    event.target.onerror = null;
  }
  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(
      userInput
    );
  }
  ValidateUrl(control: AbstractControl) {
    if (control.value != "") {
      return /(https|http):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(
        control.value
      )
        ? null
        : { validUrl: true };
    }
  }

  processFile(event: any, imageInput: any, productImageType) {
    const file: File = imageInput.files[0];
    if(file && /\.(pdf)$/i.test(file.name) === false && productImageType=='product_link'){
      this.toastrService.error(
        "Please select only (pdf) file"
      );
      event.target.value = null;
      return false;
    }else if (file && /\.(jpe?g|png|jpg|gif)$/i.test(file.name) === false && this.productImages.includes(productImageType)) {
      this.toastrService.error(
        "Please select only (png, jpg, gif or jpeg) files"
      );
      this.correctImage = false;
      event.target.value = null;
      return false;
    } else {
      let productSku;
      let newFileName;
      if (this.themeForm.value.productsku != "") {
        productSku = this.themeForm.value.productsku;
        newFileName = this.productImages.includes(productImageType)?productSku + "." + file.name.split(".").pop():productSku +'-link'+ "." + file.name.split(".").pop();
      } else {
        this.toastrService.error("Before that please enter product sku");
        event.target.value = null;
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.toUploadFiles(file.name, productImageType,fileReader.result);
      };
      fileReader.readAsDataURL(file);
      this.correctImage = true;
    }
  }
  processVideoFile(event: any, imageInput: any, productImageType:any) {
    const file: File = imageInput.files[0];
    if (file && /\.(mp4)$/i.test(file.name) === false) {
      this.toastrService.error(
        "Please select only (mp4) files"
      );
      this.correctImage = false;
      event.target.value = null;
      return false;
    } else {
      let productSku;
      let newFileName;
      if (this.themeForm.value.productsku != "") {
        productSku = this.themeForm.value.productsku;
        newFileName = productSku + "." + file.name.split(".").pop();
      } else {
        this.toastrService.error("Before that please enter product sku");
        event.target.value = null;
        return;
      }

      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.toUploadFiles(file.name, productImageType,fileReader.result);
      };
      fileReader.readAsDataURL(file);
      this.correctImage = true;
    }
  }
  toUploadFiles(newFileName, productImageType,file ) {
    this.ngxSpinnerService.show();
    let self = this;
    if(this.productImages.includes(productImageType)){
      let file = newFileName.split('.')
      let imageName=file[0]+'-'+productImageType;
      let imageExtension=file[1];
      newFileName=imageName+'.'+imageExtension
    }
    let serviceKey = Date.now().toString(36) + '.' + newFileName.split('.').pop()
    this.uploadService.uploadImage(newFileName,this.productCataloguePath,file,serviceKey).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide();
        if(res.status){
          if (res.data.Key) {
            self.them_awskey = res.data.Location;
            if(productImageType=='product_link'){
              this.themeForm.controls.productlink.patchValue(self.them_awskey);
            }else{
              if(productImageType=='image1'){
                this.theme_screenshot=`${self.them_awskey}?timestamp=${new Date().getTime()}`;
                this.themeForm.controls.productPicture.patchValue(self.them_awskey);
              }else if(productImageType=='image2'){
                this.isImageUpload2=true;
                this.theme_screenshot2=`${self.them_awskey}?timestamp=${new Date().getTime()}`;
                this.themeForm.controls.productPicture2.patchValue(self.them_awskey);
              }else if(productImageType=='image3'){
                this.isImageUpload3=true;
                this.theme_screenshot3=`${self.them_awskey}?timestamp=${new Date().getTime()}`;
                this.themeForm.controls.productPicture3.patchValue(self.them_awskey);
              }else if(productImageType=='image4'){
                this.isImageUpload4=true;
                this.theme_screenshot4=`${self.them_awskey}?timestamp=${new Date().getTime()}`;
                this.themeForm.controls.productPicture4.patchValue(self.them_awskey);
              }else if(productImageType=='video'){
                this.isImageUpload5=true;
                this.theme_screenshot5=`${self.them_awskey}?timestamp=${new Date().getTime()}`;
                this.themeForm.controls.product_video.patchValue(self.them_awskey);
              }
            }
          }
        }
        else{
          this.toastrService.error('Something went wrong !')
        }
      },
      error:(err:any)=>{
        this.ngxSpinnerService.hide();
        this.toastrService.error('Something went wrong !')
      }
    })

    // this.master.uploadfileProductCatalog(
    //   newFileName,
    //   file,
    //   this.productCataloguePath,
    //   (error, params) => {
    //     if (error) {
    //       return;
    //     }

    //     if (params.Key) {
    //       self.ngxSpinnerService.hide();
    //       self.them_awskey = params.Location;
    //       if(productImageType=='product_link'){
    //         this.themeForm.controls.productlink.patchValue(self.them_awskey);
    //       }else{
    //         this.theme_screenshot=`${self.them_awskey}?timestamp=${new Date().getTime()}`;
    //         this.themeForm.controls.productPicture.patchValue(self.them_awskey);
    //       }
    //     }
    //   }
    // );

  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  setThemeForm(key) {
    this.them_awskey = key;
  }
  getProductCategoryByCompanyID(id:any){
    this.master.getProductCatalogCategoryByCompanyId(id).subscribe(
      (data) => {
        if (data.status) {
          this.productCategory=data.data;
        } else {
          this.productCategory=[]
        }
      },
      (error) => {
        this.productCategory=[]
        console.error(error);
      }
    )
  }
  _checkStockInput(stockQuantity:any){
    if (stockQuantity.value && stockQuantity.value <= 0) {
      this.f.stock_quantity.setValue(1)
    }
  }
  getDomains(id:any){
    this.registerService.getUserDomains().subscribe(
      (data) => {
        if (data.status) {
          this.domainList=data.data;
        } else {
          this.domainList=[]
        }
      },
      (error) => {
        this.domainList=[]
        console.error(error);
      }
    )
  }
  goBack(){
    this.router.navigate(['/directseller/productCatalogue']);
  }
  getFileExtension(filename: string): string {
    return filename.split('.').pop().toLowerCase();
  }
  deleteImage(column_name:any,column_type:any){
    this.confirmationDialogService
    .confirm(
      "Please confirm",
      "ARE YOU SURE YOU WANT TO DELETE ?",
      "YES",
      "NO"
    )
    .then((confirmed) => {
      if (confirmed) {
        let body={
          "product_catalog_id":this.product_id,
          "column_name": column_name,
          "column_image" : column_type
        }
        this.ngxSpinnerService.show()
        this.master.deleteProductImage(body).subscribe({
          next:(res:any)=>{
            if(res.status){
              this.theme_screenshot='';
              this.theme_screenshot2='';
              this.theme_screenshot3='';
              this.theme_screenshot4='';
              this.isImageUpload2=false;
              this.isImageUpload3=false;
              this.isImageUpload4=false;
                this.master.getProductCatalogById(this.companyID, this.currentUser.user_ds_id,this.product_id)
                  .subscribe((response) => {
                    this.ngxSpinnerService.hide();
                    if (response.status) {
                      this.themObj = response.data.rows[0];
                      this.dataBinding();
                    }
                  });
            }else{
              this.toastrService.error(res.message);
              this.ngxSpinnerService.hide();
            }
          },error:(error:any)=>{
            this.toastrService.error(error?.message);
            this.ngxSpinnerService.hide()

          }
        })
      } else {
        this.toastrService.error(" error : update catalogue");
      }
    })
    .catch((err) => {});
  }
  isValidYouTubeUrl(url) {
    const youtubeUrlPattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return youtubeUrlPattern.test(url);
  }
}
