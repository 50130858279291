import { NgModule, Injector } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgbModule, NgbCarouselConfig, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { TokenInterceptorService } from './_interceptors/token.service';
//import {SharedModule} from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertService } from './_services/alert.service';
import { AuthService } from './_services/auth.service';
import { NgxPaginationModule } from "ngx-pagination";
import { OrderModule } from "ngx-order-pipe";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AppInjector } from './core/services/app-injector.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// Routing
//import { routing,routingComponents } from './app.routing';
import { AppRoutingModule } from './app.routing';
// Components
import { AppComponent } from './app.component';

import { SettingsModule } from './_layout/settings/settings.module';
import { ThemeSettingsConfig } from './_layout/settings/theme-settings.config';
import { MenuSettingsConfig } from './_layout/settings/menu-settings.config';
import { NavbarService } from './_services/navbar.service';

// spinner
import { NgxSpinnerModule } from 'ngx-spinner';

import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { DeviceDetectorModule } from 'ngx-device-detector';
import { RouterModule } from '@angular/router';
import { PartialsModule } from './content/partials/partials.module';

import { DataApiService } from './_services/data.api';

import { MatchHeightModule } from './content/partials/general/match-height/match-height.module';


import { ConfirmationDialogComponent } from './_utils/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './_utils/confirmation-dialog/confirmation-dialog.service'

import {  MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ImportFilesDialogComponent } from './_utils/import-files-dialog/import-files-dialog.component';
import { ImportFilesDialogService } from './_utils/import-files-dialog/import-files-dialog.service';
import { ImportICloudDialogComponent } from './_utils/import-icloud-dialog/import-icloud-dialog.component';
import { ImportICloudDialogService } from './_utils/import-icloud-dialog/import-icloud-dialog.service';
import { TreeModule } from 'angular-tree-component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SocialLoginModule, AuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';

import { CookieService } from 'ngx-cookie-service';
import { EditposttimeService } from './_utils/editposttime/editposttime.service';
import { EditposttimeComponent } from './_utils/editposttime/editposttime.component';
import { ChatService } from "./_services/chat.service";
import { UserIdleModule } from "angular-user-idle";
import { ReskinningService } from "./_services/reskinning.service";
import { AgreementComponent } from './shared/agreement/agreement.component';

import { NgxPrintModule } from 'ngx-print';
import { ReskinningComponent } from './firstadmin/reskinning/reskinning.component';
import { AddReskinningComponent } from './firstadmin/reskinning/add-reskinning/add-reskinning.component'
import { ColorPickerModule } from 'ngx-color-picker';
import { ProductCatalogComponent } from './firstadmin/product-catalog/product-catalog.component';
import { AddProductCatalogueComponent } from './firstadmin/product-catalog/add-product-catalogue/add-product-catalogue.component';;
import { MiniUrlComponent } from './firstadmin/mini-url/mini-url.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';;

const config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('246222385503381')
  }
]);

export function provideConfig() {
  return config;
}



@NgModule({
    imports: [
        BrowserModule,
        PartialsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatchHeightModule,
        NgbModule,
        FormsModule,
        AppRoutingModule,
        SettingsModule.forRoot(ThemeSettingsConfig, MenuSettingsConfig),
        NgxSpinnerModule,
        NgxPaginationModule,
        OrderModule,
        DeviceDetectorModule.forRoot(),
        LoadingBarRouterModule,
        DeviceDetectorModule.forRoot(),
        LoadingBarRouterModule,
        Ng2SearchPipeModule,
        MatSlideToggleModule,
        SocialLoginModule,
        BrowserAnimationsModule,
        InfiniteScrollModule,
        NgxPrintModule,
        TreeModule.forRoot(),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        UserIdleModule.forRoot({ idle: 36000, timeout: 1, ping: 120 }),
        ColorPickerModule,
      
    ],
    declarations: [
        AppComponent,
        ConfirmationDialogComponent,
        ImportFilesDialogComponent,
        EditposttimeComponent,
        ImportICloudDialogComponent,
        AgreementComponent,
        ProductCatalogComponent,
        AddProductCatalogueComponent,
        ReskinningComponent,
        AddReskinningComponent,
        MiniUrlComponent,
        VerifyEmailComponent
    ],
    providers: [
        AlertService,
        NavbarService,
        DataApiService,
        AuthService,
        CookieService,
        ChatService,
        ReskinningService,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
        {
            provide: AuthServiceConfig,
            useFactory: provideConfig
        },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfig
        },
        NgbCarouselConfig,
        NgbModalConfig,
        ConfirmationDialogService,
        ImportFilesDialogService,
        EditposttimeService,
        ImportICloudDialogService
    ],
    bootstrap: [AppComponent],
    exports: [RouterModule]
})

export class AppModule {
  constructor(private injector: Injector) {
    AppInjector.setInjector(this.injector);
  }

}
