import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthserviceService } from 'src/app/core/services/authservice.service';
import { switchMap } from 'rxjs/operators';
import { AlertService } from 'src/app/core/services/alert.service';
import { ToastrService } from 'ngx-toastr';
import { ChatService } from "../../_services/chat.service";
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  Resetform: UntypedFormGroup;
  submitted = false;
  isTokenValid = false;
  isloading = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private authenticationService: AuthserviceService,
    private alertService: AlertService,
    private toaster: ToastrService,
    private chatService: ChatService,
    private ngxSpinnerService: NgxSpinnerService
  ) { }
  get f() { return this.Resetform.controls; }


  ngOnInit() {
    this.checkToken();

    this.Resetform = this.formBuilder.group({
      password: ['', Validators.required],
      cpassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'cpassword')

    });
  }


  resetPassword() {
    const value = {
      password: this.f.password.value,
    };
    this.submitted = true;
    if (this.Resetform.invalid) {
      return;
    }
    this.route.params.subscribe(dt => {
      var token = dt.token;
      this.authenticationService.resetPasswordService(token, value).subscribe(data => {
        if (data.status) {
          this.toaster.success("Changed Successfully. Please login with new password ");
          localStorage.removeItem('accesToken');
          localStorage.removeItem('typeofuser');
          this.chatService.close();
          localStorage.clear();
          sessionStorage.clear();
          this.router.navigateByUrl('/login');
        } else {
          this.toaster.error(data.message);
        }
      }, error => {
        this.alertService.error(error);
      })
    })
  }
  checkToken() {
    let token = ``;
    this.route.params.subscribe((pr: any) => {
      token = pr?.token;
    })
    this.isloading = true;
    this.ngxSpinnerService.show();
    this.authenticationService.verifyToken(token).subscribe({
      next: (res: any) => {
        if (res.status == true) {
          this.isTokenValid = true;
        }
        else {
          this.isTokenValid = false;
        }
        this.ngxSpinnerService.hide();
        this.isloading = false;
      },
      error: (err: any) => {
        this.ngxSpinnerService.hide();
        this.isloading = false;
      }
    })
  }
  gotoLogin() {
    this.router.navigateByUrl('/login')
  }
  gotopassreset() {
    this.router.navigateByUrl('/forgot-pwd')
  }
}
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {

      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}

