import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ImportICloudDialogComponent } from './import-icloud-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ImportICloudDialogService {

  constructor(private modalService: NgbModal) { }

  public iCloudCredentialsModal(smsCheck: boolean = false, emailCheck: boolean = false,name:string,
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ImportICloudDialogComponent, { size: dialogSize });
    modalRef.componentInstance.securityCode = false;
    modalRef.componentInstance.smsCheck = smsCheck;
    modalRef.componentInstance.emailCheck = emailCheck;
    modalRef.componentInstance.name = name;
    return modalRef.result;
  }

  public iCloudSecurityCodeModal(
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ImportICloudDialogComponent, { size: dialogSize });
    modalRef.componentInstance.securityCode = true;
    return modalRef.result;
  }

}
