import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseserviceService } from "../baseservice.service";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";


@Injectable({
    providedIn: 'root'
  })
  export class ContactSegmentService {

    public baseUrl;
    public baseUrl2;
    public baseUrl3;
    public baseUrl4
    constructor(private http: HttpClient, public baseService: BaseserviceService) {
      this.baseUrl = baseService.baseUrl1 + "api/contact-segment/";
      this.baseUrl2 = baseService.baseUrl1 + "api/contact-segment/segment/";
      this.baseUrl3 = baseService.baseUrl1 + "api/resource/";
      this.baseUrl4 = baseService.baseUrl1 + "api/ds/referral/"
    }

    getOperators() {
        return this.http.get<any>(this.baseUrl + "segment-operators")
            .pipe(catchError(this.handleError));
    }

    getSegmentFields() {
        return this.http.get<any>(this.baseUrl + "segment-fields")
            .pipe(catchError(this.handleError));
    }

    addSegment(body) {
        return this.http.post<any>(this.baseUrl + "segment", body);

    }

    getContactSegmentListing(limit, skip){
      return this.http.get<any>(this.baseUrl + 'segment' + '/' + limit +'/' + skip)
            .pipe(catchError(this.handleError));
    }

    getSegmentDetailsByID(id: any, limit, skip, srch, flt) {
      return this.http.get<any>(this.baseUrl + 'getContactsBySegmentId/' + id + '/' + limit +
                                 '/' + skip +'?search=' + srch + '&entry_source=' + flt)
            .pipe(catchError(this.handleError));
    }

    getSegmentFiltersList() {
      return this.http.get<any>(this.baseUrl + 'getEntrySource' )
            .pipe(catchError(this.handleError));
    }

    getContactSegmentAttributesListing() {
      return this.http.get<any>(this.baseUrl + 'getAllAttributes' )
            .pipe(catchError(this.handleError));
    }

    getAttributeSegmentDetailsByID(id: any, limit, skip, srch, flt) {
      return this.http.get<any>(this.baseUrl + 'getContactsByAttributeId/' + id + '/' + limit +
                                 '/' + skip +'?search=' + srch + '&entry_source=' + flt)
            .pipe(catchError(this.handleError));
    }

    getContactSegmentWishlistListing() {
      return this.http.get<any>(this.baseUrl + 'getAllProducts' )
            .pipe(catchError(this.handleError));
    }

    getWishlistSegmentDetailsByID(id: any, limit, skip, srch, flt) {
      return this.http.get<any>(this.baseUrl + 'getContactsByProductId/' + id + '/' + limit +
                                 '/' + skip +'?search=' + srch + '&entry_source=' + flt)
            .pipe(catchError(this.handleError));
    }
    getAbandonCartSegmentDetailsByID(id: any, limit, skip, srch, flt) {
      return this.http.get<any>(this.baseUrl + 'abandonedcart-contactview/' + id+'?search=' + srch + '&entry_source=' + flt)
            .pipe(catchError(this.handleError));
    }


    getContactAutoSegmentListing(){
      return this.http.get<any>(this.baseUrl + 'getAutoSegments')
            .pipe(catchError(this.handleError));
    }

    getContactsByTypeId(typeId: any, limit, skip,srch, flt) {
      return this.http.get<any>(this.baseUrl + 'getContactsByTypeId/' + typeId + '/' + limit +'/' + skip +'?search=' + srch + '&entry_source=' + flt)
            .pipe(catchError(this.handleError));
    }

    editSegment(id, body) {
      return this.http.put<any>(this.baseUrl + 'segment/' + id, body);
    }

    deleteSegment(id) {
      return this.http.delete<any>(this.baseUrl + 'segment/' + id);
    }
    refreshSegment(id) {
      return this.http.get<any>(this.baseUrl + 'segment-refresh/' + id);
    }

    exportContactsInCsv(type, segid, t) {
      let URL = this.baseUrl + 'exportContactCsv/' + type + '?search&entry_source&id=' + segid +'&filename=' + t;
      return this.http.get<any>(URL);
    }

    addInviteesToEvent(body) {
      let URL = this.baseUrl + 'addInvitees';
      return this.http.post<any>(URL, body);
    }
    getResourceImageList(limit:any, skip:any,srch:any){
      return this.http.get<any>(this.baseUrl3 + 'image-list' + '/' + limit +'/' + skip +'?search=' + srch)
            .pipe(catchError(this.handleError));
    }
    getResourceImageByCategory(id:any,limit:any, skip:any){
      return this.http.get<any>(this.baseUrl3 + 'resourcelist-bycateid' + '/' + id +'/' + limit +'/' + skip)
            .pipe(catchError(this.handleError));
    }
    getResourceCategorylist(limit:any,skip:any,filter?:any){
      return this.http.get<any>(this.baseUrl3 + 'category-list' + '/' + limit +'/' + skip + '?imageFilter=' + filter)
            .pipe(catchError(this.handleError));
    }
    addImageResource(body) {
      let URL = this.baseUrl3 + 'add-image';
      return this.http.post<any>(URL, body);
    }
    addResourceCategory(body) {
      let URL = this.baseUrl3 + 'add-category';
      return this.http.post<any>(URL, body);
    }
    updateResourceCategory(body) {
      let URL = this.baseUrl3 + 'update-category';
      return this.http.put<any>(URL, body);
    }
    updateResourceCategoryStatus(body) {
      let URL = this.baseUrl3 + 'update-category-status';
      return this.http.put<any>(URL, body);
    }
    referContacts(body){
      let URL = this.baseUrl4 + 'share-exist-contact';
      return this.http.post<any>(URL, body);
    }
    getSegmentProducts() {
      return this.http.get<any>(this.baseUrl + "segment-products")
          .pipe(catchError(this.handleError));
    }
  getAbandadoncart(){
    return this.http.get<any>(this.baseUrl + "abandoned-cart").pipe(catchError(this.handleError));
  }
    //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status},` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

}
