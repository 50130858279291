<div class="container mt-1  mt-md-0">
  <div class="row">
    <div class="col-xl-10">
      <h4 class="font-weight-bold mb-2 mainHeading_1">{{heading}}</h4>

      <form class="form-horizontal mt-1" [formGroup]="contactForm">
        <h5 class="mb-1 subTitleHead_3">
          Personal Information
        </h5>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="required">First Name: </label>
                  <input type="text" autocomplete="off" class="form-control" formControlName="fname"
                    [(ngModel)]="objContact.contact_fname" [ngClass]="{ 'is-invalid': submitted && f.fname?.errors }"
                    id="fname" appSpace (blur)="checkSpaces($event, 'fname')" maxlength="32" />
                  <div *ngIf="submitted && f.fname?.errors" class="invalid-feedback">
                    <div *ngIf="f.fname?.errors.required || f.fname.hasError('whitespace')">First Name is required</div>

                    <div *ngIf="f.fname.errors.pattern">First Name is not valid.</div>

                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="required">Last Name: </label>
                  <input type="text" autocomplete="off" class="form-control" formControlName="lname"
                    [(ngModel)]="objContact.contact_lname" [ngClass]="{ 'is-invalid': submitted && f.lname.errors }"
                    id="lname" appSpace (blur)="checkSpaces($event, 'lname')" maxlength="32"/>
                  <div *ngIf="submitted && f.lname.errors" class="invalid-feedback">
                    <div *ngIf="f.lname.errors.required">Last Name is required</div>
                    <div *ngIf="f.lname.errors.pattern">Last Name is not valid.</div>
                  </div>
                </div>
              </div>
            </div>



            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="required">Email: </label>
                  <input type="email" autocomplete="off" class="form-control" formControlName="pemail"
                    [(ngModel)]="objContact.contact_email" [ngClass]="{ 'is-invalid': submitted && f.pemail.errors }" />
                  <div *ngIf="submitted && f.pemail.errors" class="invalid-feedback">
                    <div *ngIf="f.pemail.errors.required">Email is required</div>
                    <div *ngIf="f.pemail.errors.pattern">Email must be a valid email address</div>
                  </div>
                </div>
                <div *ngIf="contactListByEmail && contactListByEmail.length>0 && userProfileDetails.role_id!=77 && guestList.length == 0" class="exists-main">
                  <span class="contact-exists-error">Email already exists:-</span> <br />
                  <ng-container *ngFor="let result of contactListByEmail ; let i = index">
                    <a href="javascript:void(0)"
                      (click)="goToContactInformationForm(result.contact_id,result.contact_type_id)"
                      class="red-color">{{result.message.replace("Contact","Email")}}</a>
                    <br />
                  </ng-container>
                </div>
                <div *ngIf="guestList && guestList.length>0" class="exists-main"> You already registered with this event <br />
                  <!-- <ng-container *ngFor="let result of contactListByEmail ; let i = index">
                    <a href="javascript:void(0)"
                      (click)="goToContactInformationForm(result.contact_id,result.contact_type_id)"
                      class="contact-exists">{{result.message.replace("Contact","Email")}}</a>
                    <br />
                  </ng-container> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Birth Date: </label>
                  <!-- <input type="date" class="form-control" formControlName="birthdate"  [max]="maxdate"
             [(ngModel)]="objContact.contact_birthdate" [ngClass]="{ 'is-invalid': submitted && f.birthdate.errors }" > -->
                  <input class="form-control" matInput [matDatepicker]="picker" readonly formControlName="birthdate"
                    [max]="maxdate" [min]="mindate" [(ngModel)]="objContact.contact_birthdate"
                    [ngClass]="{ 'is-invalid': submitted && f.birthdate.errors }">

                  <mat-datepicker-toggle matSuffix [for]="picker" class="cldrIcon"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <div *ngIf="submitted && f.birthdate.errors" class="invalid-feedback">
                    <div *ngIf="f.birthdate.errors.date">Your age should be greater than 16 years</div>
                  </div>
                </div>

              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Gender: </label>
                  <select class="form-control" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="objContact.contact_sex">
                    <option value="0">--Select Gender--</option>
                    <option *ngFor="let Obj of sex" [ngValue]="Obj.sexid">
                      {{Obj.sex_name}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6">

              </div>

              <!-- <div class="col-sm-6">
                <div class="form-group">
                  <label>Customer Type: </label>
                  <select class="form-control"  formControlName="custType"
                  [(ngModel)]="objContact.custType">
                    <option value="">--Select Type--</option>
                    <option *ngFor="let Obj of customerType" [ngValue]="Obj">
                      {{Obj}}
                    </option>
                  </select>
                </div>
              </div> -->
              <!-- <div class="col-sm-4">
          <div class="form-group">
            <label>Loyality Type:</label>
            <select class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="objContact.loyalty_type_id">
              <option value="0" >--Select Loyality--</option>
              <option *ngFor="let Obj of loyality" [ngValue]="Obj.get_master_loyality_type.loyalty_type_id">
                {{Obj.get_master_loyality_type.loyalty_type_name}}
              </option>
            </select>
          </div>
        </div> -->
            </div>

          </div>
        </div>


        <h5 class="mb-1 subTitleHead_3">
          Address
        </h5>
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Address: </label>
                  <input type="text" autocomplete="off" class="form-control" [(ngModel)]="objContact.contact_address"
                    [ngModelOptions]="{standalone: true}">
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label>Address 2: </label>
                  <input type="text" autocomplete="off" class="form-control" name="contact_address_2"
                    [(ngModel)]="objContact.contact_address_2" [ngModelOptions]="{standalone: true}" />

                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Country: </label>
                  <select formControlName="country" class="form-control" [(ngModel)]="objContact.contact_country"
                    placeholder="event country" (change)="states()">
                    <!-- <option selected value="">--Select Country-- </option> -->
                    <option *ngFor="let Obj of countriesData" [ngValue]="Obj.id">
                      {{Obj.name}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">

                  <label>State: </label>

                  <select formcontrolName="state" [ngModelOptions]="{standalone: true}" class="form-control formstyle"
                    [(ngModel)]="objContact.contact_state" (change)="getCities()" placeholder="State">
                    <option selected value="">--Select State-- </option>
                    <option *ngFor="let Obj of stateData|filterUSStates" [ngValue]="Obj.id">
                      {{Obj.name}}
                    </option>
                  </select>

                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>City: </label>
                  <select class="form-control" formcontrolName="city" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="objContact.contact_city" placeholder="City">
                    <option selected value="">--Select City-- </option>
                    <option *ngFor="let Obj of cityData" [ngValue]="Obj.id">
                      {{Obj.name}}
                    </option>
                  </select>

                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label>Zip Code: </label>
                  <input type="text" autocomplete="off" class="form-control" placeholder="Enter your Zip code"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="objContact.contact_zip">

                </div>
              </div>

            </div>

          </div>
        </div>

        <h5 class="mb-1 subTitleHead_3">
         Phone/Mobile
        </h5>
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-md-6">
                <div class="form-group ">
                  <label>Primary Mobile: </label>
                  <!-- <div class="d-flex">
                    <select name="phone_code" id="" class="phListFieldPre" formControlName="phonecode" [(ngModel)]="phonecode" >
                      <option [value]="rmvPlus(code?.dial_code)" *ngFor="let code of phoneCodeList">
                        {{code?.dial_code}}
                      </option>
                    </select>
                    <input type="phone" autocomplete="off" [(ngModel)]="objContact.contact_phone" maxlength="14"
                      [value]="contactForm.get('mphone').value | formatPhoneNumber:phonecode" class="form-control phField"
                      formControlName="mphone" [ngClass]="{ 'is-invalid': submitted && f.mphone.errors }" />
                  </div> -->


                  <div *ngIf="objContact.contact_country" class="phFieldPre">+ <b>{{phonecode}}</b></div>
                  <input type="phone" autocomplete="off" [(ngModel)]="objContact.contact_phone" maxlength="14"
                    [value]="contactForm.get('mphone').value | formatPhoneNumber:phonecode" class="form-control phField"
                    formControlName="mphone" [ngClass]="{ 'is-invalid': submitted && f.mphone.errors }" />


                    <input *ngIf="objContact.contact_country" type="hidden" class="form-control"
                    formControlName="phonecode" [(ngModel)]="selectedCountryObj.phonecode">

                  <div *ngIf="submitted && f.mphone.errors" class="invalid-feedback">
                    <!-- <div *ngIf="f.mphone.errors.required">Primary mobile number is required</div> -->
                    <div *ngIf="f.mphone.errors.validCountryPhone">Primary mobile is not in valid format</div>
                    <div *ngIf="f.mphone.errors.minlength">Primary mobile must be 10 characters</div>
                  </div>
                </div>
                <div *ngIf="contactListByPhoneNo && contactListByPhoneNo.length>0" class="exists-main">
                  <span class="contact-exists-error">Mobile already exists:-</span>
                   <br />
                  <ng-container *ngFor="let result of contactListByPhoneNo ; let i = index">
                    <a href="javascript:void(0)"
                      (click)="goToContactInformationForm(result.contact_id,result.contact_type_id)"
                      class="contact-exists">{{result.message.replace("Contact","Mobile")}}</a>
                    <br />
                  </ng-container>
                </div>
              </div>

              <div class="col-md-6">

              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Secondary Contact Type: </label>
                  <select name="contact_phone_type" formControlName='contact_phone_type'
                    [(ngModel)]="objContact.contact_phone_type" class="form-control formstyle">
                    <option value="0"> --Select Type-- </option>
                    <option value="1">Landline</option>
                    <option value="2">Mobile</option>
                  </select>
                </div>
              </div>
              <!-- <div class="col-md-6">
                <div class="form-group">
                  <label>Secondary Contact: </label>
                  <div *ngIf="objContact.contact_country" class="phFieldPre">+ <b>{{phonecode}}</b></div>
                  <input type="text" autocomplete="off" class="form-control phField" maxlength="14"
                    name="contact_phone_other" [(ngModel)]="objContact.contact_phone_other"
                    formControlName="contact_phone_other"
                    [ngClass]="{ 'is-invalid': submitted && f.contact_phone_other.errors }" />
                  <div *ngIf="submitted && f.contact_phone_other.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_phone_other.errors.pattern || f.contact_phone_other.errors.minlength">
                      Secondary contact is not in valid format</div>
                  </div>
                </div>
              </div> -->
              <div class="col-md-6">
                <div class="form-group">
                  <label>Secondary Contact: </label>
                  <!-- <div *ngIf="phonecode" class="phFieldPre"><b>+{{phonecode}}</b></div> -->
                  <div *ngIf="objContact.contact_country" class="phFieldPre">+ <b>{{phonecode}}</b></div>

                  <input type="phone" autocomplete="off" [(ngModel)]="objContact.contact_phone_other" maxlength="14"
                    [value]="contactForm.get('contact_phone_other').value | formatPhoneNumber:phonecode" class="form-control phField"
                    formControlName="contact_phone_other" [ngClass]="{ 'is-invalid': submitted && f.contact_phone_other.errors }" />
                  <!-- <input *ngIf="objContact.contact_country" type="hidden" class="form-control"
                    formControlName="phonecode" [(ngModel)]="objContact.phonecode"> -->
                    <input *ngIf="objContact.contact_country" type="hidden" class="form-control"
                    formControlName="phonecode" [(ngModel)]="selectedCountryObj.phonecode">

                  <div *ngIf="submitted && f.contact_phone_other.errors" class="invalid-feedback">
                    <div *ngIf="f.contact_phone_other.errors.validCountryPhone">Secondary Contact is not in valid format</div>
                    <div *ngIf="f.contact_phone_other.errors.minlength">Secondary Contact must be 10 characters</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>




        <h5 class="mb-1 subTitleHead_3">
          Social Accounts
        </h5>
        <div class="card socialAcntUpd2">
          <div class="card-body">

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Facebook Username: </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text input-group-margo input-group-facebook"></div>
                    </div>
                    <input rows="5" autocomplete="off" class="form-control" name="facebookURL"

                      placeholder="https://www.facebook.com/username" maxlength="100" formControlName="fblink" [(ngModel)]="objContact.facebook_id"

                      [ngClass]="{ 'is-invalid': submitted && f.fblink.errors }" />
                    <div *ngIf="submitted && f.fblink.errors" class="invalid-feedback">
                      <div *ngIf="f.fblink.errors.pattern">Username must match pattern e.g. "https://www.facebook.com/username"</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Twitter Username: </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text input-group-margo input-group-twitter"></div>
                    </div>

                    <input rows="5" autocomplete="off" class="form-control" placeholder="https://x.com/username" name="twitterURL"

                      formControlName="Twitterlink" maxlength="100" [(ngModel)]="objContact.twitter_id"
                      [ngClass]="{ 'is-invalid': submitted && f.Twitterlink.errors }" />
                    <div *ngIf="submitted && f.Twitterlink.errors" class="invalid-feedback">
                      <div *ngIf="f.Twitterlink.errors.pattern">Username must match pattern e.g. "https://x.com/username"</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Instagram Username: </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text input-group-margo input-group-instagram"></div>
                    </div>

                    <input rows="5" autocomplete="off" class="form-control" placeholder="https://www.instagram.com/username"

                      name="instagramURL" maxlength="100" formControlName="instagramlink" [(ngModel)]="objContact.instagram_id"
                      [ngClass]="{ 'is-invalid': submitted && f.instagramlink.errors }" />
                    <div *ngIf="submitted && f.instagramlink.errors" class="invalid-feedback">
                      <div *ngIf="f.instagramlink.errors.pattern">Username must match pattern e.g. "https://www.instagram.com/username"</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>LinkedIn Username: </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text input-group-margo input-group-linkedin"></div>
                    </div>
                    <input rows="5" autocomplete="off" class="form-control" name="linkedInURL"

                      formControlName="linkedInlink" maxlength="100" placeholder="https://www.linkedin.com/in/username" [(ngModel)]="objContact.linkedin_id"

                      [ngClass]="{ 'is-invalid': submitted && f.linkedInlink.errors }" />
                    <div *ngIf="submitted && f.linkedInlink.errors" class="invalid-feedback">
                      <div *ngIf="f.linkedInlink.errors.pattern">Username must match pattern e.g. "https://www.linkedin.com/in/username"</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>YouTube Channel: </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text input-group-margo input-group-youtube"></div>
                    </div>

                    <input rows="5" autocomplete="off" class="form-control" placeholder="https://www.youtube.com/channel/channelname" name="youtubeURL"
                      formControlName="youtubelink" maxlength="130" [(ngModel)]="objContact.youtube_id"
                      [ngClass]="{ 'is-invalid': submitted && f.youtubelink.errors }" />
                    <div *ngIf="submitted && f.youtubelink.errors" class="invalid-feedback">
                      <div *ngIf="f.youtubelink.errors.pattern">Please Enter valid channel name e.g. "https://www.youtube.com/channel/channelname"</div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>WhatsApp: </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text input-group-margo input-group-whatsapp"></div>
                    </div>
                    <input rows="5" autocomplete="off" placeholder="WhatsApp number" class="form-control"
                      name="whatsappURL" formControlName="whatsapplink" [(ngModel)]="objContact.whatsapp_id"
                      maxlength="14" [ngClass]="{ 'is-invalid': submitted && f.whatsapplink.errors }" />
                    <div *ngIf="submitted && f.whatsapplink.errors" class="invalid-feedback">
                      <div *ngIf="f.whatsapplink.errors.minlength">Please enter atleast 6 digits</div>
                      <div *ngIf="f.whatsapplink.errors.pattern">Digits are only allowed</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Google Biz: </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text input-group-margo input-group-google"></div>
                    </div>
                    <input rows="5" autocomplete="off" class="form-control" placeholder="https://www.google.com/business/name" name="googleURL"
                      formControlName="googlelink" [(ngModel)]="objContact.google_my_biz_id"
                      [ngClass]="{ 'is-invalid': submitted && f.googlelink.errors }" />
                      <div *ngIf="submitted && f.googlelink.errors" class="invalid-feedback">
                      <div *ngIf="f.googlelink.errors.pattern">Username must match pattern e.g. :"https://www.google.com/business/name"</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-6">
                <div class="form-group">
                  <label>Sina Wiebo User ID: </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text input-group-margo input-group-Sina"></div>
                    </div>
                    <input rows="5" autocomplete="off" class="form-control" placeholder="weibo.com/1978462634/AC1klvp6U"
                      name="Sina" formControlName="Sinalink" [(ngModel)]="objContact.sina_wiebo_id"
                      [ngClass]="{ 'is-invalid': submitted && f.Sinalink.errors }" />
                    <div *ngIf="submitted && f.Sinalink.errors" class="invalid-feedback">
                      <div *ngIf="f.Sinalink.errors.pattern">User Id must match pattern</div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="col-md-6">
                <div class="form-group">
                  <label>Pinterest: </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text input-group-margo input-group-pinterest"></div>
                    </div>

                    <input rows="5" autocomplete="off" class="form-control" placeholder="https://www.pinterest.com/username" name="pinterestURL"

                      formControlName="pinterestLink" maxlength="100" [(ngModel)]="objContact.pinterest_id"
                      [ngClass]="{ 'is-invalid': submitted && f.pinterestLink.errors }" />
                    <div *ngIf="submitted && f.pinterestLink.errors" class="invalid-feedback">
                      <div *ngIf="f.pinterestLink.errors.pattern">Username must match pattern e.g. "https://www.pinterest.com/username"</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <!-- <div class="col-md-6">
                <div class="form-group">
                  <label>WeChat: </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text input-group-margo input-group-wechat"></div>
                    </div>
                    <input rows="5" autocomplete="off" class="form-control" placeholder="WeChat Number" name="wechatURL"
                      formControlName="wechatlink" [(ngModel)]="objContact.we_chat_id"
                      [ngClass]="{ 'is-invalid': submitted && f.wechatlink.errors }" />
                    <div *ngIf="submitted && f.wechatlink.errors" class="invalid-feedback">
                      <div *ngIf="f.wechatlink.errors.pattern">Digits are only allowed</div>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="col-md-6">
                <div class="form-group">
                  <label>Instagram Biz: </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text input-group-margo input-group-instagram"></div>
                    </div>

                    <input rows="5" autocomplete="off" placeholder="https://www.instagram.com/username" class="form-control"

                      name="instagrambiz" maxlength="100" formControlName="instagrambiz" [(ngModel)]="objContact.instagram_biz_id"
                      [ngClass]="{ 'is-invalid': submitted && f.instagrambiz.errors }" />
                    <div *ngIf="submitted && f.instagrambiz.errors" class="invalid-feedback">
                      <div *ngIf="f.instagrambiz.errors.pattern">Username must match pattern e.g. "https://www.instagram.com/username"</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Pinterest: </label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text input-group-margo input-group-pinterest"></div>
                    </div>
                    <input rows="5" autocomplete="off" class="form-control" placeholder="stuartjohn" name="pinterestURL"
                      formControlName="pinterestLink" maxlength="100" [(ngModel)]="objContact.pinterest_id"
                      [ngClass]="{ 'is-invalid': submitted && f.pinterestLink.errors }" />
                    <div *ngIf="submitted && f.pinterestLink.errors" class="invalid-feedback">
                      <div *ngIf="f.pinterestLink.errors.pattern">Username must match pattern e.g. "https://www.pinterest.com/username"</div>
                    </div>
                  </div>
                </div>
              </div>

            </div> -->


          </div>
        </div>



        <!-- <h5 class="mb-1">
    Personal Notes
  </h5>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <textarea rows="5" class="custmtextArea" formControlName="personalnotes" [(ngModel)]="objContact.personal_notes"></textarea>
        </div>
      </div>
    </div>
  </div> -->


      </form>

      <form class="form-horizontal mt-1" [formGroup]="metaForm" *ngIf="metaVisibility && t.length >0">
        <h5 class="mb-1 font-weight-bold subTitleHead_3">
          Meta Fields
        </h5>

        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-md-6" *ngFor="let fd of t.controls; let i = index">
                <div class="form-group" [formGroup]="fd">
                  <label >{{fd.controls.lname.value}}</label>

                  <input type="text" formControlName="metafname" class="form-control" [ngClass]="{ 'is-invalid': submitted && fd.controls.metafname.errors }" />
                  <div *ngIf="fd.controls.metafname.errors" >
                    <div *ngIf="fd.controls.metafname.errors.maxlength">
                      <span style="color: red;" >
                        <small> *Maximum allowed length reached for the field</small>
                      </span>
                    </div>
                    <div *ngIf="fd.controls.metafname.errors.pattern && fd.controls.ftyp.value === 'Integer'">
                      <span style="color: red;" >
                        <small> *Only Integers 0-9 are Allowed</small>
                      </span>
                    </div>
                    <div *ngIf="fd.controls.metafname.errors.pattern && fd.controls.ftyp.value === 'Float'">
                      <span style="color: red;" >
                        <small> *Only Floating values are Allowed e.g: 123.32</small>
                      </span>
                    </div>
                    <div *ngIf="fd.controls.metafname.errors.boolCheck && fd.controls.ftyp.value === 'Boolean'">
                      <span style="color: red;" >
                        <small> *Only true or false values are Allowed</small>
                      </span>
                    </div>
                    <div *ngIf="fd.controls.metafname.errors.pattern && fd.controls.ftyp.value === 'Tmstamp'">
                      <span style="color: red;" >
                        <small> *Timestamp values should be like 2020-11-15T21:11:11.000Z</small>
                      </span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </form>

      <div class="form-group text-center">
        <button class="btn btn-round btn-margo-action" (click)="showConfirm(confirmBox)">Update</button>
        <button  type="button" class="btn btn-margo-cancel btn-round" (click)='location.back()'>Cancel</button>
      </div>

    </div>


  </div>
  <ng-template #confirmBox let-modal>
    <div class="scroller">
      <div class="wholePop filtMain">
        <div class="modal-header dialHead w-100">
          <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" />
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>


        </div>

        <div class="modal-body text-center">
          <h5 class="mb-1 text-center">OPT-IN AGREEMENT</h5>
          <ng-container>
            <div>
              YOU AGREE THAT THIS CONTACT HAS GIVEN CONSENT TO RECEIVE COMMUNICATION
            </div>
            <div class="text-left mt-1"><input type="checkbox" [checked]="emailCheck" (change)="checkedEmail($event)">
              AGREED TO RECEIVE EMAILS</div>
            <div class="text-left mt-1"><input type="checkbox" [checked]="smsCheck" [disabled]="!disablePhone"
                (change)="smsChecked($event)"> AGREED TO RECEIVE SMS/MMS</div>
            <!-- <div *ngIf="!(emailCheck || smsCheck)" class="text-left invalid text-uppercase text-red mt-1">Please select one option
            </div> -->

            <!-- /host/optinagreement -->
            <p class="mt-2"><a target="_blank" [routerLink]="['/agreement']"
              class="card-link"><i class="fa fa-file-text blue-dr-icon mrgRgihtHlf" aria-hidden="true"></i> VIEW {{projectName}}
              AGREEMENT</a>
          </p>



          </ng-container>
        </div>

        <div class="modal-footer text-center d-block border-0 pt-0">
          <button type="button" class="btn btn-margo-action btn-round" (click)="agree()">AGREE</button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
