import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, CanActivateChild } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class IsagreedguardGuard implements CanActivate,CanActivateChild {
  constructor(
      private router: Router,
     private toaster : ToastrService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const firsttimeuser =JSON.parse(localStorage.getItem("UserData")) 
      if (firsttimeuser.is_terms_agreed && firsttimeuser.user_time_zone && firsttimeuser.user_time_zone!=="") {
          return true;
      }
      else if(!firsttimeuser.is_terms_agreed)
      {
        this.router.navigate(['directseller/agreed'], { queryParams: { returnUrl: state.url } });
      //  this.toaster.info("Please Accept Terms & Conditions")
        return false;
      }
      else{
        this.router.navigate(['directseller/updatetimezone'], { queryParams: { returnUrl: state.url } });
        this.toaster.info("Please set your time zone.")
        return false;
      }

      // not logged in so redirect to login page with the return url
    
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        return this.canActivate(route,state);
    }
}
