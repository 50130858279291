import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseserviceService } from "../baseservice.service";
import { throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import * as S3 from "aws-sdk/clients/s3";
import { catchError, map } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class ReskinningService {
  public baseUrl;
  vonageUrl:any;
  constructor(
    private http: HttpClient,
    public baseService: BaseserviceService
  ) {
    this.vonageUrl = baseService.baseUrl1+ "api/vonage/"
    this.baseUrl = baseService.baseUrl1 + "api/master/";
  }


  getReskinnings(id,ds_id, limit, skip) {
    return this.http
      .post<any>(
        this.baseUrl +
          `getCompanyReskinning/${id}/${ds_id}/${limit}/${skip}`,

        this.httpHeadersOptions()
      )
      .pipe(
        map((data: any) => {
          if (!data.status) {
            if (
              data.message &&
              (data.message.indexOf("Error acquiring client") >= 0 ||
                data.message.indexOf("Error executing query") >= 0)
            ) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again.";
            }
          }
          return data;
        }),
        catchError(this.handleError)
      );
  }
  getReskinningById(companyID, theme_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getReskinningById/${companyID}/${theme_id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
    //add resckining apis
    addingReskinning(value) {
      var body = value;
      return this.http.post<any>(this.baseUrl + "addingReskinning", body, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    UpdateReskinning(value) {
      var body = value;
      console.log("Body", body);

      return this.http.put<any>(this.baseUrl + "UpdateReskinning", body, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    //Default setting for Reskining
    getReskinningDefaultData() {
      var body = {};
      return this.http.get<any>(this.baseUrl + `getMasterReskinningData`, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
    deleteLogo(){
      var body = {};
      return this.http.put(this.baseUrl+'del-logo',body,this.httpHeadersOptions())
      .pipe(
        map((data:any) => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
    }
  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
  uploadfile(file, userKey, cb, type = 1) {
    return;
    const bucket = new S3({
      // accessKeyId: environment.accessKeyId,
      // secretAccessKey:environment.secretAccessKey,
      // region:environment.region
    });

    // Key: this.FOLDER + this.UUID + '/'+ file.name,
    const params = {
      Bucket:environment.awsBucket,
      Key: userKey + '/' + Date.now().toString(36) + '.' + file.name.split('.').pop(),
      Body: file
    };

    bucket.upload(params, function (err, data) {
      // console.log(err, data)
      if (err) {
        cb(err, null);
        // console.log('There was an error uploading your file: ', err);
        return err;
      }
      cb(null, data);
      return data;
    });
    return "ok";
  }
  getdsSenderNumberList() {
    return this.http.get<any>(this.vonageUrl + `ds-sender-number`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
