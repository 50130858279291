import { Component, OnInit } from '@angular/core';
import { ReskinningService } from 'src/app/_services/reskinning.service';

@Component({
    selector: 'app-model-logo',
    templateUrl: 'model-logo.component.html'
})


export class ModelLogoComponent implements OnInit {
    rskmodellogoFlag = this.ReskinningService.rskModelLogoFlag;
    rskmodellogo = this.ReskinningService.rskModelLogo;
    constructor(private ReskinningService: ReskinningService) { }

    ngOnInit() {


    }

}


