import { Component, Input, OnInit } from "@angular/core";



@Component({
    selector: 'app-contact-metadata',
    templateUrl: './contactmetadata.component.html',
    styleUrls: ['./contactmetadata.component.css']
})
export class ContactMetaDataComponent implements OnInit{

    @Input() metadata;

    ngOnInit() {
    }
}