import {Injectable} from "@angular/core";

import {BehaviorSubject, Subject} from "rxjs";



@Injectable({
  providedIn: 'root'
})
export class ToggleIconService {

   iconStatus = new BehaviorSubject(false);

   currentStatus = this.iconStatus.asObservable();

   homePageNav = new BehaviorSubject(false);

   homeNav = this.homePageNav.asObservable();

}
