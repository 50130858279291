import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpParams } from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BaseserviceService } from '../baseservice.service';
import { environment } from '../../../environments/environment';
import * as S3 from 'aws-sdk/clients/s3';
import { Observable, of } from 'rxjs';

// const this.httpHeadersOptions() = {
//   headers: new HttpHeaders({
//     'Content-Type': 'application/json',
//     'x-auth': localStorage.getItem("accesToken")
//   })
// };
let httpParams = new HttpParams();

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  public baseUrl;
  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.baseUrl1 + 'api/master/';
  }

  uploadfile(file, userKey, cb) {
    return ;

    const bucket = new S3({
      // accessKeyId: environment.accessKeyId,
      // secretAccessKey: environment.secretAccessKey,
      // region: environment.region
    });

    // Key: this.FOLDER + this.UUID + '/'+ file.name,
    const params = {
      Bucket: environment.awsBucket,
      Key: userKey + '/' + Date.now().toString(36) +'.'+ file.name.split('.').pop(),
      Body: file
    };

    bucket.upload(params, function (err, data) {
      if (err) {
        cb(err, null);
        return err;
      }
      cb(null, data);
      return data;
    });
    return "ok";
  }

  getUrlfile(awsKey,bucket_secured=false) {
    return environment.s3URL+awsKey;

    // if(bucket_secured == true) {
    const bucket = new S3({
      // accessKeyId: environment.accessKeyId,
      // secretAccessKey: environment.secretAccessKey,
      // region: environment.region
    });

    // Key: this.FOLDER + this.UUID + '/'+ file.name,
    // const params = {

    //   Key: userKey + '/' + file.name,
    //   Body: file
    // };
    var params = {Bucket: environment.awsBucket, Key: awsKey};
    var url = bucket.getSignedUrl('getObject', params);
    // bucket.getSignedUrl('getObject',params, function (err, data) {
    //   if (err) {
    //     cb(err, null);
    //     return err;
    //   }
    //   cb(null, data);
    //   return data;
    // });
    return url;
  // }
  // else return environment.s3URL+awsKey;
  }

  //theme apis
  addingTheme(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingTheme", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getTheme() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getTheme/100/0", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getCompanyThemes(id) {
    var body = {company_id:id};
    return this.http.post<any>(this.baseUrl + `getCompanyTheme/${id}/100/0`, body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getSelectCompanyTheme(id) {
    var body = {};
    return this.http.post<any>(this.baseUrl + `getCompanyTheme/${id}/100/0`, body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getThemeById(companyID,theme_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getThemeById/${companyID}/${theme_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  UpdateTheme(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "UpdateTheme", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  delTheme(company,id) {
    return this.http.delete<any>(this.baseUrl +`deleteTheme/${company}/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }


  //journey apis
  addingJourney(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingJourney", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getJourney() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getJourney/100/0", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getCompanyJourney(id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getJourney/${id}/100/0`,  this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getJourneyId(company_id,journey_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getJourneyById/${company_id}/${journey_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getLoyalityById(loyality_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getLoyalityTypeById/${loyality_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updateJourney(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + `updateJourney`, body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  delJourney(id,company_id) {

    return this.http.delete<any>(this.baseUrl + `deleteJourney/${company_id}/${id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  //adding training type
  addingTrainingType(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingTtraining", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getTraining() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getTraining/100/0", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  delTrainingType(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "deleteTtraining/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  //Event Type
  addNewEvent(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingEvent", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  delEventType(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "deleteEvent/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getEventType() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getEvent/100/0", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getCountryById(country_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getCountryById/${country_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  //Country Service
  addingCountry(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingCountry", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updateCountry(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "updateCountry", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  addingMenu(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingMenu", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  addingCurrency(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingCurrency", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  //Adding Training Type
  addingProfession(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingProfession", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  addingLoyality(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingLoyalityType", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getCountry() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getCountry/100/0", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getMenuById(menu_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getMenuById/${menu_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updateMenu(munuBody) {
    var body = munuBody;
    return this.http.put<any>(this.baseUrl + "updateMenu", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  addingProspect(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingProspect", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));

  }
  getMenu() {
    var body = {};
    // return this.http.get<any>(this.baseUrl + "getMenuById/1",  this.httpHeadersOptions())
    return this.http.post<any>(this.baseUrl + "getMenu/100/0", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));

  }
  getCurrency() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getCurrency/100/0", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getCurrencyById(currency_id) {
    var body = {};
    return this.http.post<any>(this.baseUrl + `getCurrency/${currency_id}`, body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updateCurrency(currency_body) {
    var body = currency_body;
    return this.http.put<any>(this.baseUrl + "updateCurrency", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getTrainingType() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getTraining", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getTrainingTypeById(trainingType_id) {
    var body = {};
    return this.http.post<any>(this.baseUrl + `getTraining/${trainingType_id}`, body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updateTrainingType() {
    var body = {};
    return this.http.put<any>(this.baseUrl + "updateTtraining", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getProfession() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getProfession/100/0", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getLoyality() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getLoyalityType/100/0", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getProspect() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getProspect/100/0", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getProfessionById(profession_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getProfessionById/${profession_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updateProfession(professionBody) {
    var body = professionBody;
    return this.http.put<any>(this.baseUrl + "updateProfession", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getIndustry() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getIndustry/100/0", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getCompanyType() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getCompany/100/0", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  addingCompany(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingCompany", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));

  }
  addNewIndustry(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingIndustry", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  delMenu(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "deleteMenu/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));

  }
  delCountry(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "deleteCountry/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updateLoyality(loyality_body) {
    var body = loyality_body;
    return this.http.put<any>(this.baseUrl + "updateLoyalityType", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  delCurrency(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "deleteCurrency/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));

  }
  delIndustry(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "deleteIndustry/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  delCompanyType(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "deleteCompany/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getProspectById(prospect_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getProspectById/${prospect_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //onsole.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updateProspect(prospect_body) {
    var body = prospect_body;
    return this.http.put<any>(this.baseUrl + `updateProspect`, body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  delProfession(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "deleteProfession/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  delProspect(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "deleteProspect/" + id.id, this.httpHeadersOptions())
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  delLoyality(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "deleteLoyalityType/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getEventTypeById(event_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getEventById/${event_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updateEventType(event_body) {
    var body = event_body;
    return this.http.put<any>(this.baseUrl + "updateEvent", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getTrainingId(training_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getTtrainingById/${training_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  UpdateTraining(training_body) {
    var body = training_body;
    return this.http.put<any>(this.baseUrl + "updateTraining", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getIndustryId(industry_id) {
    var body = industry_id;
    return this.http.get<any>(this.baseUrl + `getIndustryById/${industry_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updateIndustry(industry_body) {
    var body = industry_body;
    return this.http.put<any>(this.baseUrl + "updateIndustry", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updateCompany(company_body) {
    var body = company_body;
    return this.http.put<any>(this.baseUrl + "updateCompany", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getCompanyById(company_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getCompanyById/${company_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getCurrencyId(currency_id) {
    var body = currency_id;
    return this.http.get<any>(this.baseUrl + `getCurrencyById/${currency_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  UpdateCurrency(industry_body) {
    var body = industry_body;
    return this.http.put<any>(this.baseUrl + "updateCurrency", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getPermissions() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getPermissions/100/0", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  delpermission(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "deletePermissions/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  addingNewPermission(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingPermissions", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getPermissionById(per_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getPermissionsById/${per_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updatePermission(permission_body) {
    var body = permission_body;
    return this.http.put<any>(this.baseUrl + "updatePermissions", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getRole(value) {
    var body = value || {};
    return this.http.post<any>(this.baseUrl + "getRole", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  // getRoledropdown() {
  //   var body = {};
  //   return this.http.post<any>(this.baseUrl + "getMasterRole", body, this.httpHeadersOptions())
      //  .pipe(
      // map(data=>{
      //   if(!data.status)
      //   {
      //     if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
      //     {
      //       console.error(data.message);
      //       data.message="Something went wrong.Please try again."
      //     }
      //   }
      //   return data;
      // }),
      // catchError(this.handleError));
  // }
  delRole(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "deleteRole/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  addingRole(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingRole", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getRoleById(role_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getRoleById/${role_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updateRole(permission_body) {
    var body = permission_body;
    return this.http.put<any>(this.baseUrl + "updateRole", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getModule(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "getModule", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getAllModules(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "getAllModules", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  delModule(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "deleteModule/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  addingModule(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingModule", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getModuleById(module_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getModuleById/${module_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updateModule(Module_body) {
    var body = Module_body;
    return this.http.put<any>(this.baseUrl + "updateModule", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  importBulk(url, value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + url, body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  exportBulk(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "getBulkData", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getModuleItems() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getModuleItems/500/0", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  delModuleItems(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "deleteModuleItems/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  addingModuleItems(value) {

    var body = value;
    return this.http.post<any>(this.baseUrl + "addingModuleItems", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getModuleItemsById(moduleitems_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getModuleItemsById/${moduleitems_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updateModuleItems(moduleitems_body) {
    var body = moduleitems_body;
    return this.http.put<any>(this.baseUrl + "updateModuleItems", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getRoledropdown() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getMasterRole", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getAllRoles() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getAllRoles", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getModuledropdown() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getMasterModule", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }



  //Theme Category  apis
  addingThemeCategory(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingThemeCategory", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getThemeCategory() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getThemeCategory", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getThemeCategoryById(theme_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getThemeCategoryById/${theme_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  UpdateThemeCategory(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "UpdateThemeCategory", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  updateThemeCategoryStatus(themobj: { isactive: any; theme_id: any; }) {
    var body = themobj;
    return this.http.put<any>(this.baseUrl + "updateThemeCategoryStatus", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  delThemeCategory(value) {
    let id = value;
    return this.http.delete<any>(this.baseUrl + "deleteThemeCategory/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

    //Contact Type  apis
    addingContacttype(value) {
      var body = value;
      return this.http.post<any>(this.baseUrl + "addingContacttype", body, this.httpHeadersOptions())
         .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
    }
    getContacttype() {
      var body = {};
      return this.http.post<any>(this.baseUrl + "getContacttype", body, this.httpHeadersOptions())
         .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
    }
    getContacttypeById(contact_type_id) {
      var body = {};
      return this.http.get<any>(this.baseUrl + `getContacttypeById/${contact_type_id}`, this.httpHeadersOptions())
         .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
    }
    UpdateContacttype(value) {
      var body = value;
      return this.http.put<any>(this.baseUrl + "UpdateContacttype", body, this.httpHeadersOptions())
         .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          ///console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
    }

    updateContacttypeStatus(contacttypeobj: { isactive: any; contact_type_id: any; }) {
      var body = contacttypeobj;
      return this.http.put<any>(this.baseUrl + "updateContacttypeStatus", body, this.httpHeadersOptions())
         .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
    }

    delContacttype(value) {
      let id = value;
      return this.http.delete<any>(this.baseUrl + "deleteContacttype/" + id.id, this.httpHeadersOptions())
         .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
    }

     //Journey Type  apis
  addingJourneytype(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingJourneytype", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getJourneytype() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getJourneytype", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getJourneytypeById(journey_type_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getJourneytypeById/${journey_type_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  UpdateJourneytype(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "UpdateJourneytype", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  updateJourneytypeStatus(themobj: { isactive: any; journey_type_id: any; }) {
    var body = themobj;
    return this.http.put<any>(this.baseUrl + "updateJourneytypeStatus", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  delJourneytype(value) {
    let id = value;
    return this.http.delete<any>(this.baseUrl + "deleteJourneytype/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  //asset  apis
  addingAsset(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingAsset", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getAsset() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getAsset", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getAssetById(assestsid) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getAssetById/${assestsid}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  UpdateAsset(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "UpdateAsset", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  updateAssetStatus(themobj: { isactive: any; journey_type_id: any; }) {
    var body = themobj;
    return this.http.put<any>(this.baseUrl + "updateAssetStatus", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  delAsset(value) {
    let id = value;
    return this.http.delete<any>(this.baseUrl + "deleteAsset/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  //Gender  apis
  addingGender(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingGender", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getGender() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getGender", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getGenderById(sexid) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getGenderById/${sexid}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  UpdateGender(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "UpdateGender", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  updateGenderStatus(genderobj: { isactive: any; sexid: any; }) {
    var body = genderobj;
    return this.http.put<any>(this.baseUrl + "updateGenderStatus", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  delGender(value) {
    let id = value;
    return this.http.delete<any>(this.baseUrl + "deleteGender/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  //Age Range  apis
  addingAgerange(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingAgerange", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getAgerange() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getAgerange", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getAgerangeById(marid) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getAgerangeById/${marid}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  UpdateAgerange(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "UpdateAgerange", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  updateAgerangeStatus(agerangeobj: { isactive: any; marid: any; }) {
    var body = agerangeobj;
    return this.http.put<any>(this.baseUrl + "updateAgerangeStatus", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  delAgerange(value) {
    let id = value;
    return this.http.delete<any>(this.baseUrl + "deleteAgerange/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          ///console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  //Message Library  apis
  addingMessagelib(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingMessagelib", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getMessagelib() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getMessagelib", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getMessagelibById(help_message_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getMessagelibById/${help_message_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  UpdateMessagelib(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "UpdateMessagelib", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  updateMessagelibStatus(messagelibobj: { isactive: any; help_message_id: any; }) {
    var body = messagelibobj;
    return this.http.put<any>(this.baseUrl + "updateMessagelibStatus", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  delMessagelib(value) {
    let id = value;
    return this.http.delete<any>(this.baseUrl + "deleteMessagelib/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  //Subject Library  apis
  addingSubjectlib(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingSubjectlib", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getSubjectlib() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getSubjectlib", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getSubjectlibById(help_subject_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getSubjectlibById/${help_subject_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  UpdateSubjectlib(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "UpdateSubjectlib", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  updateSubjectlibStatus(subjectlibobj: { isactive: any; help_subject_id: any; }) {
    var body = subjectlibobj;
    return this.http.put<any>(this.baseUrl + "updateSubjectlibStatus", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  delSubjectlib(value) {
    let id = value;
    return this.http.delete<any>(this.baseUrl + "deleteSubjectlib/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  //Subscription  apis
  addingSubscription(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingSubscription", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getSubscription() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "getSubscription", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getSubscriptionById(subscription_id) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `getSubscriptionById/${subscription_id}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  UpdateSubscription(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "UpdateSubscription", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  updateSubscriptionStatus(subscriptionobj: { isactive: any; subscription_id: any; }) {
    var body = subscriptionobj;
    return this.http.put<any>(this.baseUrl + "updateSubscriptionStatus", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  delSubscription(value) {
    let id = value;
    return this.http.delete<any>(this.baseUrl + "deleteSubscription/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getTermConditions() {
    return this.http.get<any>(this.baseUrl + `agreement` , this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  //// Journey api calls//
  getTemplateByJourneyDomain(category:any, journey:any, limit:any, skip:any) {
    return this.http.get<any>(this.baseUrl + `getTheme/${category}/${journey}/${limit}/${skip}` , this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getFlowByJourneyDomainTemplate(journey:any,template:any, limit:any, skip:any) {
    return this.http.get<any>(this.baseUrl + `getJourney/${journey}/${template}/${limit}/${skip}` , this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getJourneyForTemplateList(template:any, limit:any, skip:any) {
    return this.http.get<any>(this.baseUrl + `themeJourney/${template}/${limit}/${skip}` , this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addJourneyForTemplateList(body:any) {
    return this.http.post<any>(this.baseUrl + `addJourney` ,body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getTemplateSectionFieldAttribute(id) {
    return this.http.get<any>(this.baseUrl + `getTemplateSectionFieldAttribute/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getCBThemeTemplateFieldValue(id) {
    return this.http.get<any>(this.baseUrl + `getCBThemeTemplateFieldValue/${id}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updateDynamicForm(body) {
    return this.http.put<any>(this.baseUrl + `updateThemeFieldValue`,body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getNewTemplateArrival(journey:any, category:any,limit:any, skip:any) {
    return this.http.get<any>(this.baseUrl + `new-arrival-theme/${journey}/${category}/${limit}/${skip}` , this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  addNewTemplate(template_id:any){
    return this.http.get<any>(this.baseUrl + `associate-theme/${template_id}` , this.httpHeadersOptions())
    .pipe(
      map(data => {
        if (!data.status) {
          if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
            console.error(data.message);
            data.message = "Something went wrong.Please try again."
          }
        }
        return data;
      }),
      catchError(this.handleError));
  }
  updateNewTemplate(body:any){
    return this.http.put<any>(this.baseUrl + `update-journey`, body,this.httpHeadersOptions())
    .pipe(
      map(data => {
        if (!data.status) {
          if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
            console.error(data.message);
            data.message = "Something went wrong.Please try again."
          }
        }
        return data;
      }),
      catchError(this.handleError));
  }
  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status},` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
