import { Component, OnInit, Input } from '@angular/core';
import { MasterService } from 'src/app/core/services/master/master.service';
import { ContactService } from '../../../services/contact.service';
import { UntypedFormControl } from '@angular/forms';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BaseComponent } from '../../../firstadmin/base.component';
import { ContactType,getContactTypeSpec } from 'src/app/firstadmin/Models/Contact';
import { forkJoin } from 'rxjs';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-searchall-contact',
  templateUrl: './searchall-contact.component.html',
  styleUrls: ['./searchall-contact.component.css']
})


export class SearchAllContactComponent extends BaseComponent implements OnInit {
  heading: any;
  placeholder: any;
  addurl: any;
  addtext: any;
  isAllowed: boolean;

  constructor(private master: MasterService,private contactService:ContactService,private deviceService: DeviceDetectorService) { 
      super();
    }

  public model: string;
  public selectedProspect: boolean;
  results: any[] = [];
  queryContactField: UntypedFormControl = new UntypedFormControl();
  userDetails: any = [];
  prospectname;
  prospectid;
  contacttypeid;
  prospectdata;
  contacttypename;
  contacttypetext;
  phonecode;
  recentOrderContacts: any[] = [];
  showContactDetail: boolean = false;
  hosttype;
  bookmarkresults;
  cohost: any = [];
  @Input() public inputdata: any;
  ngOnInit() {
    let self = this;
    

    if (this.inputdata.page === 'quicktext') {
      //this.isAllowed = window.innerWidth < AppConstants.MOBILE_RESPONSIVE_WIDTH_HORIZONTAL;

      const isMobile = this.deviceService.isMobile();
      const isTablet = this.deviceService.isTablet();
      let deviceOs = this.deviceService.os;  
      if(isMobile || isTablet || deviceOs=='Mac') {
        this.isAllowed = true;

      }

/*
      if (navigator.appVersion.indexOf("Mac") != -1) {
        this.isAllowed = true;
      }
*/

    }
    else {
      this.isAllowed = true;
    }

    if (this.isAllowed) {
     
      this.addurl = this.inputdata.addurl;
      this.addtext = this.inputdata.addtext;
      this.queryContactField.setValue(this.contactService.contactserachtext.value);

     
      sessionStorage.setItem("contacttypeid", '3');
      if (this.inputdata.hosttype) {
        this.hosttype = this.inputdata.hosttype;
      }
     
      if (sessionStorage.getItem("cohostdata")) {
        this.cohost = JSON.parse(sessionStorage.getItem("cohostdata"));
      }
      var params = {
        contactTypeIDs: this.contacttypeid
      }
      this.loaddata(this.queryContactField.value);
     
      self.queryContactField.valueChanges
        .pipe(
          map((query) => query),
          debounceTime(1000),
          distinctUntilChanged()
        )
        .subscribe(query => {
          this.SearchContact(query);
        });
    }
  }

  loaddata(name) {
    if(name.length>0)
    {
    var params = {
      contactTypeIDs: this.contacttypeid
    }
    this.ngxSpinnerService.show();
    var body={
      name: name, params: params, journeyId: 68, limit: 10, skip: 0
    }
    this.getAllContactListByName(body);
    /* switch (this.inputdata.name) {
      case 'teammember':
        this.getAllSubDsListByName(body);
        break;
      case 'host':
        if (this.hosttype && (this.hosttype === 'cohost' || this.hosttype === 'addcohost')) {
          params = {
            contactTypeIDs: 3
          }
          body.params=params;
          this.getAllContactListByName(body);
        }
        else {
          params = {
            contactTypeIDs: "2,3",
          }
          body.params=params;
          body.journeyId=68;
          this.getContactListForHostByName(body);
        }
        break;     
      default:
        this.getAllContactListByName(body);
        break;
    } */
  }
  else
  {
    this.results = [];
    this.ngxSpinnerService.hide();
  }
  }

 /*  getAllSubDsListByName(body) {
    this.results = [];
    this.master.getAllSubDsListByName(body)
    .subscribe(result => {
      if (result.length>0) {
        this.results = result;
      }
      else {
        this.results = [];
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  getContactListForHostByName(body) {
    this.results = [];
    this.master.getContactListForHostByName(body)
    .subscribe(result => {
      if (result.status) {
        this.results = result.data;
      }
      else {
        this.results = [];
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });
  } */

  getAllContactListByName(body) {
   // this.master.getContactListForQuicktextByName(body)
  //forkJoin(this.master.getContactListForQuicktextByName(body), this.master.getAllSubDsListByName(body))
  this.master.getContactListForQuicktextByName(body)  
  .subscribe(result => {
      if (result.status) {
        this.results = result.data;
      }      
      else {
        this.results = [];
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  

  selectContact(contactid, contacttypeid) {
    sessionStorage.setItem("contactid", contactid);   
    var contacttype=getContactTypeSpec(contacttypeid)
    var obj={"id":contacttypeid,"name":contacttype.route,"text":contacttype.name}
   if(this.inputdata.page==="market")
   {
     sessionStorage.setItem("market",JSON.stringify(obj));
    this.inputdata.moreinfourl="/directseller/market/"+contacttype.route+"/contactInformation";
   }
    
    this.router.navigateByUrl(this.inputdata.moreinfourl);
    
  }  
  
  SearchContact(query) {
    this.results = [];
    this.ngxSpinnerService.show();
    this.loaddata(query);
  }
}
