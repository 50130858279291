<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1">
    </div>
    <div class="content-body">
      <section class="flexbox-container">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-md-4 col-10 box-shadow-0 p-0">
            <div class="text-center col-12">
              <img class="col-sm-12" src="assets/custom/images/margologo250.png" alt="branding logo">
            </div>

            <span>
              Below you will create your username for the marGo portal.  Enter the username you would like to use and select verify to see if it is available.  If the username is already taken it will let you know, if it is available select submit and continue with your account creation.
            </span>
            <form class="form-horizontal text-center mt-1" [formGroup]="forgotForm">
              <div [ngClass]="status ? 'displaynone' :'displayblock' ">
                <input type="text" formControlName="username" class="form-control formstyle"
                  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" id="username"
                  placeholder="Enter Your Username">
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                  <div *ngIf="f.username.errors.required">Username is Required</div>
                </div>
              </div>
              <div [ngClass]="status ? 'displayblock' : 'displaynone'">
                <span [innerHTML]='username_verify' style="font-weight: 900;"></span>
              </div>
              {{message}}
              <div class="my_class text-center" [ngClass]="status ? 'displayblock' : 'displaynone'">
                <button type="submit" class="custbtn mt-2 mr-1" (click)="submitUserName()"> Submit </button>
                <button type="button" class="custbtn mt-2 mr-1" (click)="oncancel()"> Cancel </button>
              </div>
              <div class="my_class text-center" [ngClass]="status ? 'displaynone' :'displayblock' ">
                <button type="button" class="custbtn mt-2 mr-1" (click)="verifyUserName()"> Verify </button>
                <button type="button" class="custbtn mt-2 mr-1" (click)="oncancel()"> Cancel </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>