import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { BaseComponent } from 'src/app/firstadmin/base.component';

@Component({
  selector: 'app-forgot-user',
  templateUrl: './forgot-user.component.html',
  styleUrls: ['./forgot-user.component.css']
})
export class ForgotUserComponent extends BaseComponent implements OnInit {
  forgotForm: UntypedFormGroup;
  submitted = false;
  constructor(private formBuilder: UntypedFormBuilder,
    public router: Router,
    public toastrService: ToastrService) { 
      super();
    }


  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(AppConstants.regex.email)]],
    });
  }
  get f() { return this.forgotForm.controls; }
  forgotUserName() {
    this.submitted = true;
    if (this.forgotForm.invalid) {
      return;
    }

    let body = {
      "userEmail": this.f.email.value
    }
    this.ngxSpinnerService.show();
    this.authserviceService.forgotUserNames(body).subscribe(data => {
      this.ngxSpinnerService.hide();
      if (data.status) {
        this.toastrService.success("You username has been sent to your registered email address successfully.");
        this.router.navigateByUrl('/login');
      } else {
        this.toastrService.error("Please enter valid email");
      }
    }, err => {
      console.error(err);

    })
  }
  gotoLogin(){
    this.router.navigateByUrl('/login')
  }
}
