
<!-- <div class="chatBx puple-border"  *ngIf="isSupportIconEnable">
    <a class="chatIcn" href="javascript:void(0)" (click)="getKnowledgeArticleByMargoAppURL(articleDetailModal)">
      <img src="/assets/images/icons/customer-service.png" alt="supportIcon">
    </a>
  </div>   -->

  <ng-template #articleDetailModal let-modal>
    <div class="modal-header dialHead">
      <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}"/>
      <h4 class="modal-title">ARTICLE DETAIL</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <div class="col-md-12">
          <fieldset class="scheduler-border">
            <div class="row article-detail-container">
              <article-detail [articleDetail]=knowledgeArticleLayoutDetail></article-detail>
              <div style="text-align: center;">
                <button class="btn btn-margo-action fa fa-support blue-lt-icon" (click)="ContactSupport()">
                  CONTACT SUPPORT
                </button>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  <!-- <div class="modal-footer">
    <button class="btn btn-margo-action fa fa-support blue-lt-icon" (click)="ContactSupport()">
      Contact Support 
    </button>
  </div> -->
</ng-template>