import { Injectable } from '@angular/core';
import {EditposttimeComponent} from "../editposttime/editposttime.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: 'root'
})
export class EditposttimeService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'CANCEL',    
    dialogSize: 'sm' | 'lg' = 'sm',
    timer_type ,
    interval): Promise<Object> {
    const modalRef = this.modalService.open(EditposttimeComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.itimer_type = timer_type;
    modalRef.componentInstance.iinterval = interval;
    return modalRef.result;
  }
}
