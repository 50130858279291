import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { catchError,map, mergeMap } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { BaseserviceService } from '../baseservice.service';

@Injectable({
  providedIn: 'root'
})
export class HelpandtipsService {
  public baseUrl;
  constructor(private http: HttpClient, public baseService: BaseserviceService) {
    this.baseUrl = baseService.baseUrl1;
  }

  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  };


  //KNowledge articles  apis
  getKnowledgeArticles(){
    var body = {};
    return this.http.get<any>(this.baseUrl + "help/getKnowledgeArticles", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getKnowledgeArticleByID(articleID){
    var body = {};
    return this.http.get<any>(this.baseUrl + `help/getKnowledgeArticleByID/${articleID}`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getKnowledgeArticleByQuery(query,articleType) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `help/getKnowledgeArticleBySearchQuery/${query}/${articleType}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getKnowledgeArticleByMargoAppURL(appurl) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `help/getKnowledgeArticleByMargoAppURL/${appurl}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getKnowledgeArticleByArticleType(articleType) {
    var body = {};
    return this.http.get<any>(this.baseUrl + `help/getKnowledgeArticleByArticleType/${articleType}`, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getRecentOpenedArticles(){
    var body = {};
    return this.http.get<any>(this.baseUrl + "help/getRecentOpenedArticles", this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  addOpenedArticle(articleInfo:any){
    var body = articleInfo;
    return this.http.post<any>(this.baseUrl + "help/AddOpenedArticle", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getKnowledgeArticleByID1(id): Observable<any> {
    return this.getKnowledgeArticleByID(id) //First Get Article Information
      .pipe(
        map((knowledgeArticleLayoutDetail: any, index: number) => {
          return knowledgeArticleLayoutDetail;
        }),
        mergeMap((knowledgeArticleLayoutDetail: any, index: number) => {
          if (knowledgeArticleLayoutDetail.status) {
            let articleInfo = {
              KnowledgeArticleId: id,
              title: knowledgeArticleLayoutDetail.data.layoutItems.find(item => item.label == 'Title').value
            };
            return this.addOpenedArticle(articleInfo) // Add/Update article to the recent accessed data
              .pipe(
                map((openedArticleStatus: any, index: number) => {
                  knowledgeArticleLayoutDetail.data.articleAddStatus=openedArticleStatus;
                  return knowledgeArticleLayoutDetail;
                })
              );
          }
          else
          {
            return knowledgeArticleLayoutDetail;
          }
        })
      );
  }


  //Support apis
  submitCase(supportCase:any){
    var body = supportCase;
    return this.http.post<any>(this.baseUrl + "support/submitCase", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

//Message Library  apis
addingMessagelib(value) {
  var body = value;
  return this.http.post<any>(this.baseUrl + "api/master/addingMessagelib", body, this.httpHeadersOptions())
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
}
getMessagelib(messageFilter) {
  var body = messageFilter;
  return this.http.post<any>(this.baseUrl + "api/master/getMessagelib", body, this.httpHeadersOptions())
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
}
getMessagelibForCurrentUser(helpType) {
  return this.http.get<any>(this.baseUrl + "api/master/getMessagelibForCurrentUser/"+helpType, this.httpHeadersOptions())
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
}

getMessageByUser(helpType) {
  return this.http.get<any>(this.baseUrl + "api/master/getMessagelibBytUser/"+helpType, this.httpHeadersOptions())
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
}
getMessageSnippetByType(type) {

  return this.http.get<any>(this.baseUrl + `api/master/getMessageSnippetByType/${type}`, this.httpHeadersOptions())
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
}
getMessagelibById(body) {
  //var body = {};
  return this.http.post<any>(this.baseUrl + `getMessagelibById`,body, this.httpHeadersOptions())
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
}
UpdateMessagelib(value) {
  var body = value;
  return this.http.put<any>(this.baseUrl + "api/master/UpdateMessagelib", body, this.httpHeadersOptions())
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
}

updateMessagelibStatus(messagelibobj: { isactive: any; help_message_id: any; }) {
  var body = messagelibobj;
  return this.http.put<any>(this.baseUrl + "updateMessagelibStatus", body, this.httpHeadersOptions())
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
}

delMessagelib(value) {
  let id = value;

  return this.http.delete<any>(this.baseUrl + "api/master/deleteMessagelib/" + id.id, this.httpHeadersOptions())
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
}
  //Subject Library  apis
  addingSubjectlib(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "api/master/addingSubjectlib", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getSubjectlib() {
    var body = {};
    return this.http.post<any>(this.baseUrl + "api/master/getSubjectlib", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getSubjectlibById(body) {

    return this.http.post<any>(this.baseUrl + `getSubjectlibById/`,body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  UpdateSubjectlib(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "api/master/UpdateSubjectlib", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  updateSubjectlibStatus(subjectlibobj: { isactive: any; help_subject_id: any; }) {
    var body = subjectlibobj;
    return this.http.put<any>(this.baseUrl + "updateSubjectlibStatus", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  delSubjectlib(value) {
    let id = value;

    return this.http.delete<any>(this.baseUrl + "api/master/deleteSubjectlib/" + id.id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getSupportRequest() {

    return this.http.get<any>(this.baseUrl + `user/getRequestListSeller`, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }


  allowDenyAccess(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "user/allowDenyAccess", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getSupportData(body:any) {
    return this.http.post<any>(this.baseUrl + "support/listTickets",body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  createSupportTicket(body:any) {
    return this.http.post<any>(this.baseUrl + "support/createTicket", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getSupportDataById(id:any) {
    return this.http.get<any>(this.baseUrl + "support/listTicketsById/"+id, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  sendComments(body:any) {
    return this.http.post<any>(this.baseUrl + "support/createComment", body, this.httpHeadersOptions())
       .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
}
