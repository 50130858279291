// WARNING - Values under "firebase" and value of "googleApiKey" needs to be replaced from your own accounts
// If left as is, it firbase and google map related functionality will not work on LIVE instance.
export const environment = {
  production: false,


  //chatURI: 'https://margo-chat-qa.herokuapp.com/',

 //chatURI:'https://chat.margo.me/',



// apiUrl:'https://dev-backend.margo.me/',
//  apiUrl:'https://club-backend.margo.me/',


  apiUrl:'https://dev-backend.margo.me/',
  //apiUrl:'http://localhost:8082/',

directSellerURL : 'https://dev-chain.margo.me/',
 chatURI:'https://dev-chat.margo.me/',
 chatDSURI:'https://dev-chat-ds-api.margo.me/',

//chatDSURI:'http://localhost:5000/',


 sliveURL: 'https://shoplive2.supportadita.in/',
 sliveURLOneToMany: 'https://shoplive2.supportadita.in/',
 manyToManyInstanceId: '06b3b2619a5b32c43',
 oneToManyInstanceId: 'i-086abf5bce297e0bf',


  s3URL:'https://margous-me-2.s3.amazonaws.com/',
  googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU',

accessKeyId: 'AKIA3AFAXT7OT4APBLFU',
secretAccessKey: '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',
// baseSchedule: 'https://sfmccontacts.us-e1.cloudhub.io/margo/importContacts',
baseSchedule: 'https://sfmccontacts.us-e1.cloudhub.io/margo/importContacts',

region: 'us-east-1',
companyId:175,
canvaApiKey: {


    prod: 'MCCsww7ZMo0lmtkxT0SPiE7P',
    test: 'y9r0HVKnOjmZhK97phQA2-1t'
  },
  scrollLimit: 12,
  awsBucket: 'margous-me-2',
  projectName: 'marGo',
};
