import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { MasterService } from 'src/app/core/services/master/master.service';
import { BaseComponent } from 'src/app/firstadmin/base.component';
import { getContactTypeSpec } from 'src/app/firstadmin/Models/Contact';
import { forkJoin } from 'rxjs';
import { BookmarkService } from 'src/app/firstadmin/services/bookmark.service';
import { NgbAccordion, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { CommonMessages } from "../../messages/messages.enum";
import { ConfirmationDialogService } from "../../../_utils/confirmation-dialog/confirmation-dialog.service";
import { query } from "@angular/animations";
import { environment } from '../../../../environments/environment';

import { ReskinningService } from 'src/app/_services/reskinning.service';
import { MetaService } from 'src/app/services/meta/meta.service';
import { ProductCatalogueService } from "src/app/services/product-catalogue/product-catalogue.service";
import { DateTimeService } from 'src/app/services/dateTime/dateTime.service';
import { MbService } from 'src/app/services/mbservice/mb.service';



@Component({
  selector: 'app-viewcontact',
  templateUrl: './viewcontact.component.html',
  styleUrls: ['./viewcontact.component.css']
})
export class ViewcontactComponent extends BaseComponent implements OnInit, OnDestroy {
  tabColor = this.ReskinningService.rskTabColor;
  buttonColor = this.ReskinningService.rskButtonColor;
  buttonTextColor = this.ReskinningService.rskButtonTextColor;
  eventFlag = this.ReskinningService.rskEventFlag;
  rskmodellogoFlag = this.ReskinningService.rskModelLogoFlag;
  rskmodellogo = this.ReskinningService.rskModelLogo;
  hostUserActivationLink: any;
  userName = '';
  userId: any;
  contactData;
  contact_id;
  contactByIdData;
  status = true;
  contacttypeid;
  contacttypename;
  eventtype;
  eventid = 0;
  agreeToJoin = false;
  agreeToHost;
  agreeToHostDisabled = false;
  mapURL: string = "";
  optinStatus;
  optinValue = "";
  phonecode: any;
  selectedCountryObj: any;
  editcontacturl = "";
  backurl = "";
  message = '';
  tooltipAgreeToHost = "";
  isMessageDisplay = true;
  isMessageDefault = false;
  navurl = "";
  @Input() public inputdata: any;
  allOptinRequests: any = [];
  latestOptinReuest: any;
  emailCheck: boolean = true;
  smsCheck: boolean = true;
  disablePhone: boolean = false;
  showMessage: boolean;
  showEdit: boolean;
  cityFlag = false;
  stateFlag = false;
  countryFlag = false;
  support_user;
  maskData;

  hosttype;
  cohost: any = [];
  showHostJourney: boolean = false;
  body: any;

  notesVisibility: boolean = false;
  metaVisibility: boolean = false;
  metafields = [];

  showContact: boolean = false;
  showTeamMember: boolean = false;

  gMapUrl = 'https://www.google.com/maps/search/?api=1&query=';
  selectedCohost: any = [];
  currentSelectedCohost: number[] = [];
  data: any;
  disableSelect: boolean = false;
  optStatus = {
    optIn: false,
    optSms: false,
  }
  isMobile: boolean = false;
  CountryCodes: any;

  @ViewChild('acc', { static: false }) accordion: NgbAccordion;
  isbookmarked;
  hostTypeStatus;
  projectName = environment.projectName;
  userData: any;
  hardBounce: any;
  hardBounceStatus: boolean = false;
  productTotalCount: number = 0;
  isIndependenceDs:boolean=false;
  senderType: any;
  dsSenderNumber:any;
  dsSenderWhatsappNumber:any;
  dsSenderMessengerNumber:any;
  isBU:boolean = false;
  constructor(
    private master: MasterService,
    public modalService: NgbModal,
    private bookmarkService: BookmarkService,
    private confirmationDialogService: ConfirmationDialogService,
    private ReskinningService: ReskinningService,
    private metaService: MetaService,
    private productCatalogue: ProductCatalogueService,
    public dateTimeService: DateTimeService,
    public mb: MbService
  ) {
    super();
    this.baseService.changeValue(false);
  }

  ngOnInit() {
    console.log("ffffffffff",sessionStorage.getItem("morderid"))

    localStorage.removeItem("sendTo");
    localStorage.removeItem("sendName");
    this.senderType = this.ReskinningService.rskSmsService;
    this.dsSenderNumber = this.ReskinningService.rskDsSenderNumber;
    this.dsSenderWhatsappNumber = this.ReskinningService.rskDsSenderWhatsappNumber;
    this.dsSenderMessengerNumber = this.ReskinningService.rskDsSenderMessengerNumber;

    if(localStorage.getItem('UserData')){
      let UserData = JSON.parse(localStorage.getItem('UserData'));

      this.isIndependenceDs = !UserData.is_bu
      this.isBU = UserData?.is_bu;

    }

    this.ngxSpinnerService.show();
    sessionStorage.removeItem("type");
    sessionStorage.removeItem("parentjourney");
    sessionStorage.removeItem("themes_list_data");
    
    this.userData = JSON.parse(localStorage.getItem('UserData'));
    this.getProductCatalogue();

    let self = this;
    this.contact_id = this.inputdata.contactid;
    if (this.inputdata.name === undefined || this.inputdata.name === null) {
      let id = parseInt(sessionStorage.getItem('contacttypeid'), 10);
      if (id === 1) {
        this.inputdata.name = 'teammember';
      }
    }
    if (!this.contact_id) {
      this.toastrService.info('No contact selected. Please select contact first');
      this.router.navigate(['/directseller/mycontact']);
      this.ngxSpinnerService.hide();
    } else {
      this.contacttypename = this.inputdata.name;
      this.editcontacturl = this.inputdata.editcontacturl;
      this.backurl = this.inputdata.backurl;
      this.support_user = this.loginUserData.support_user;
      this.showEdit = sessionStorage.getItem('RelatedSourcePage') === 'mycontact' ? false : true;

      if (sessionStorage.getItem('RelatedSourcePage').split('/')[0] == 'myhost') this.showHostJourney = true;
      if (sessionStorage.getItem('RelatedSourcePage').split('/')[1] == 'viewhost') this.showHostJourney = true;




      if (self.contact_id) {
        let body = {
          contact_id: this.contact_id
        }


        var showContact = false;
        var showTeam = false;
        var userdata = JSON.parse(localStorage.getItem("UserData"));
        var company = userdata.user_customer_id;

        this.metaService.getMetaFields(company, 1, this.contact_id).subscribe(data => {
          if (data.status) {
            this.metaVisibility = true;
            this.metafields = data.data;
          }
          else {
            this.metaVisibility = false;
            this.metafields = [];
          }
        }, error => {
          this.metaVisibility = false;
          this.metafields = [];
          console.log(error);
        });

        if (userdata.bundle_name) {



          Object.keys(userdata.user_bundle.nav).forEach(function (key) {

            if (key == 'MSL' && userdata.user_bundle.nav[key] == 1) {


              showContact = true;
              //showTeam = false;
            }
            if (key == 'MEL' && userdata.user_bundle.nav[key] == 1) {

              showTeam = true;
              // showContact = false;
            }
            if (key == 'MRL' && userdata.user_bundle.nav[key] == 1) {

              showContact = true;
              showTeam = true;
            }
          });
          this.showContact = showContact;
          this.showTeamMember = showTeam;

        }
        else {
          this.showContact = true;
          this.showTeamMember = true;
        }

        if (this.inputdata.id === undefined) {
          this.inputdata.id = parseInt(sessionStorage.getItem('contacttypeid'), 10);
        }
        if (this.inputdata.id === 1) {
          this.contacttypeid = this.inputdata.id;
          this.contacttypename = getContactTypeSpec(this.contacttypeid).name;
          this.master.getTeamMemberByIdWithMap(this.contact_id).subscribe(response => {
            this.contactByIdData = response.data;

            this.maskData = response.maskData;

            this.isbookmarked = this.contactByIdData.isbookmarked;



            this.contactByIdData.contact_fname = this.contactByIdData.user_fname;
            this.contactByIdData.contact_lname = this.contactByIdData.user_lname;
            this.contactByIdData.contact_phone = this.contactByIdData.user_mobile_phone;
            if (this.contactByIdData.contact_phone) {
              this.disablePhone = false;
            } else if (!this.contactByIdData.contact_phone) {
              this.disablePhone = true;
            }
            this.contactByIdData.contact_address = this.contactByIdData.user_address1;
            this.contactByIdData.contact_city = this.contactByIdData.user_city;
            this.contactByIdData.contact_state = this.contactByIdData.user_state;
            this.contactByIdData.contact_country = this.contactByIdData.user_country;
            this.contactByIdData.contact_zip = this.contactByIdData.user_zip;
            this.contactByIdData.contact_phone_other = this.contactByIdData.contact_phone_other;
            this.contactByIdData.contact_address2 = this.contactByIdData.contact_address2;
            //this.contactByIdData.contacttypename=  getContactTypeSpec(this.contactByIdData.contacttypeid).name;
            this.contactByIdData.contactname = this.contactByIdData.user_fname + " " + this.contactByIdData.user_lname;
            if (this.contactByIdData.contact_country) {
              this.getCountryById(this.contactByIdData.contact_country);
            }
            this.contactByIdData.contact_phone_type = this.contactByIdData.contact_phone_type;
            //sessionStorage.setItem('contactdata', JSON.stringify(this.contactByIdData ));

            this.phonecode = 1;
            if (this.support_user) {
              sessionStorage.setItem('contactdata', JSON.stringify(this.maskData));
            }
            else {
              sessionStorage.setItem('contactdata', JSON.stringify(this.contactByIdData));
            }





            //sessionStorage.setItem('contactdata', JSON.stringify(this.contactByIdData ));
            //this.contacttypeid =parseInt(this.contactByIdData.contact_type_id);
            //this.contacttypename  =getContactTypeSpec(this.contacttypeid).name;
            //this.emailCheck=this.contactByIdData.optin?true:false;
            //this.smsCheck=this.contactByIdData.optin_sms?true:false;
            this.agreeToJoin = this.contactByIdData.agreetojoin;
            this.mapURL = response.mapData.mapURL;
            this.phonecode = this.contactByIdData.phonecode;

            this.updateCountryStateCity();

            var obj = [], objprop = [];
            if (this.contactByIdData.contact_age_range && this.contactByIdData.contact_age_range != 0) {
              obj.push(this.master.getAgerangeById(this.contactByIdData.contact_age_range));
              objprop.push({ contactnameprop: "contact_age_range", resultnameprop: "agerange" });
            }
            if (this.contactByIdData.contact_profession && this.contactByIdData.contact_profession != 0) {
              obj.push(this.master.getProfessionById(this.contactByIdData.contact_profession));
              objprop.push({ contactnameprop: "contact_profession", resultnameprop: "profession_name" });
            }
            if (this.contactByIdData.contact_marital_status && this.contactByIdData.contact_marital_status != 0) {
              obj.push(this.master.getMaritalStatusById(this.contactByIdData.contact_marital_status));
              objprop.push({ contactnameprop: "contact_marital_status", resultnameprop: "marital_name" });
            }
            if (this.contactByIdData.contact_sex && this.contactByIdData.contact_sex != 0) {
              obj.push(this.master.getSexById(this.contactByIdData.contact_sex));
              objprop.push({ contactnameprop: "contact_sex", resultnameprop: "sex_name" });
            }
            if (this.contactByIdData.loyalty_type_id && this.contactByIdData.loyalty_type_id != 0) {
              obj.push(this.master.getLoyalityTypeById(this.contactByIdData.loyalty_type_id));
              objprop.push({ contactnameprop: "loyalty_type_id", resultnameprop: "loyalty_type_name" });
            }
            if (obj.length > 0) {
              forkJoin(obj).subscribe((result) => {

                result.forEach((data: any, index: any) => {
                  if (data.status) {
                    this.contactByIdData[objprop[index].contactnameprop] = data.data[objprop[index].resultnameprop];
                  }
                  else {
                    this.contactByIdData[objprop[index].contactnameprop] = '';
                  }
                });
                this.ngxSpinnerService.hide();
              }, error => {
                console.log(error);
                this.ngxSpinnerService.hide();
              });
            }
            else {
              this.ngxSpinnerService.hide();
            }
          }, error => {
            console.log(error);
            this.ngxSpinnerService.hide();
          });


          this.ngxSpinnerService.hide();

        }
        else {

          this.master.getDsHostEventContactRelByContactId(body).subscribe(data => {
            if (data.status) {
              this.agreeToHostDisabled = true;
              this.tooltipAgreeToHost = "This host is already associated with event.So you can not uncheck it";
            }

          });
          this.getUserOptinStatus(this.contact_id);

          this.master.getContactListByIdWithMap(this.contact_id).subscribe(response => {



            this.contactByIdData = response.data;
            console.log('CONTACT BY ID DATA', this.contactByIdData);
            sessionStorage.setItem('contact_old_email', this.contactByIdData.contact_email);

            this.checkHostUserRegistration();
            this.getHardBounceDetail();


            this.optStatus.optIn = response.data.optin;
            this.optStatus.optSms = response.data.optin_sms;
            if (response.data.contact_phone != '') {
              this.isMobile = true;
            }



            if (this.contactByIdData) {

              this.isbookmarked = this.contactByIdData.isbookmarked;
              if (this.contactByIdData.contact_address && this.contactByIdData.contact_address.trim()) {
                let add = this.contactByIdData.contact_address.trim();
                add = add.replace(/ /g, "+");
                this.gMapUrl += add + ',';
              }
              if (this.contactByIdData.contact_city && this.contactByIdData.contact_city.trim()) {
                let ct = this.contactByIdData.contact_city.trim();
                ct = ct.replace(/ /g, "+");
                this.gMapUrl += ct + ',';
              }
              if (this.contactByIdData.contact_state && this.contactByIdData.contact_state.trim()) {
                let stat = this.contactByIdData.contact_state.trim();
                stat = stat.replace(/ /g, "+");
                this.gMapUrl += stat + ',';
              }
              if (this.contactByIdData.contact_country && this.contactByIdData.contact_country.trim()) {
                let cntry = this.contactByIdData.contact_country.trim();
                cntry = cntry.replace(/ /g, "+");
                this.gMapUrl += cntry;
              }
            }

            this.maskData = response.maskData;
            if (this.support_user) {
              sessionStorage.setItem('contactdata', JSON.stringify(this.maskData));
            }
            else {
              sessionStorage.setItem('contactdata', JSON.stringify(this.contactByIdData));
            }
            this.contacttypeid = parseInt(this.contactByIdData.contact_type_id);
            this.contacttypename = getContactTypeSpec(this.contacttypeid).name;
            if (this.contactByIdData.contact_country) {
              this.getCountryById(this.contactByIdData.contact_country);
            }
            this.emailCheck = this.contactByIdData.optin ? true : false;
            this.smsCheck = this.contactByIdData.optin_sms ? true : false;
            if (this.contactByIdData.contact_phone) {
              this.disablePhone = false;
            } else if (!this.contactByIdData.contact_phone) {
              this.disablePhone = true;
            }
            if (this.contactByIdData.agreetohost) {

              //   this.contacttypename += " / Host";
              this.contacttypename += " ";
            }
            this.agreeToHost = this.contactByIdData.agreetohost;
            if (!this.agreeToHost) {
              this.agreeToHostDisabled = false;
              this.tooltipAgreeToHost = "";
            }
            this.agreeToJoin = this.contactByIdData.agreetojoin;
            this.mapURL = response.mapData.mapURL;
            this.phonecode = this.contactByIdData.phonecode;

            this.updateCountryStateCity();

            var obj = [], objprop = [];
            if (this.contactByIdData.contact_age_range && this.contactByIdData.contact_age_range != 0) {
              obj.push(this.master.getAgerangeById(this.contactByIdData.contact_age_range));
              objprop.push({ contactnameprop: "contact_age_range", resultnameprop: "agerange" });
            }
            if (this.contactByIdData.contact_profession && this.contactByIdData.contact_profession != 0) {
              obj.push(this.master.getProfessionById(this.contactByIdData.contact_profession));
              objprop.push({ contactnameprop: "contact_profession", resultnameprop: "profession_name" });
            }
            if (this.contactByIdData.contact_marital_status && this.contactByIdData.contact_marital_status != 0) {
              obj.push(this.master.getMaritalStatusById(this.contactByIdData.contact_marital_status));
              objprop.push({ contactnameprop: "contact_marital_status", resultnameprop: "marital_name" });
            }
            if (this.contactByIdData.contact_sex && this.contactByIdData.contact_sex != 0) {
              obj.push(this.master.getSexById(this.contactByIdData.contact_sex));
              objprop.push({ contactnameprop: "contact_sex", resultnameprop: "sex_name" });
            }
            if (this.contactByIdData.loyalty_type_id && this.contactByIdData.loyalty_type_id != 0) {
              obj.push(this.master.getLoyalityTypeById(this.contactByIdData.loyalty_type_id));
              objprop.push({ contactnameprop: "loyalty_type_id", resultnameprop: "loyalty_type_name" });
            }
            if (obj.length > 0) {
              forkJoin(obj).subscribe((result) => {

                result.forEach((data: any, index: any) => {
                  if (data.status) {
                    this.contactByIdData[objprop[index].contactnameprop] = data.data[objprop[index].resultnameprop];
                  }
                  else {
                    this.contactByIdData[objprop[index].contactnameprop] = '';
                  }
                });
                this.ngxSpinnerService.hide();
              }, error => {
                console.log(error);
                this.ngxSpinnerService.hide();
              });
            }
            else {
              this.ngxSpinnerService.hide();
            }
          }, error => {
            console.log(error);
            this.ngxSpinnerService.hide();
          });

        }


        // For navigation on Select

        if (sessionStorage.getItem('RelatedSourcePage') == 'market/host/contact') {
          if (this.agreeToHost) {
            this.inputdata.selecturl = '/directseller/market/host/type';
            sessionStorage.setItem("contacttypeid", '4');
            sessionStorage.setItem('market', JSON.stringify({ "id": 4, "name": "host", "text": "Customer/Prospect" }))

          } else if (this.inputdata.contact_type_id == 2) { //For Customer
            this.inputdata.selecturl = '/directseller/market/customer/type';

            sessionStorage.setItem("contacttypeid", '2');
            sessionStorage.setItem('market', JSON.stringify({ "id": 2, "name": "customer", "text": "Customer" }))
          } else if (this.inputdata.contact_type_id == 3) { // For Prospects
            this.inputdata.selecturl = '/directseller/market/prospect/type';
            sessionStorage.setItem("contacttypeid", '3');
            sessionStorage.setItem('market', JSON.stringify({ "id": 3, "name": "prospect", "text": "Prospect" }))
          }
        }

      }
      // For navigation on Select - ENd

    }







    //  ##############   Get Notes Visibility for Notes  #################  //

    this.master.getAllowedLengthForNotes().subscribe((data: any) => {


      this.notesVisibility = data.status;
    });







  }
  /* selectContact(id) {
    this.router.navigate(['/directseller/mycontact/report']);
  } */
  getCountryById(countryId: string) {
    this.master.getCountryByCode(countryId).subscribe((objArr: any) => {

      this.selectedCountryObj = objArr[0];
      if (this.selectedCountryObj) {
        this.phonecode = this.selectedCountryObj.phonecode;
      }
    });
  }

  checkHostUserRegistration() {
    let data = {
      email: this.contactByIdData.contact_email,
      customerId: this.contactByIdData.customer_id,
      page: 'contact',
    }
    this.master.checkHostUserRegistration(data).subscribe(data => {
      if (data.status) {
        this.userName = data.data[0].username;
        this.hostUserActivationLink = data.data[0].account;
        this.userId = data.data[0].userid;

      } else {
        this.hostUserActivationLink = false;
      }
      sessionStorage.setItem('isHostContact', data.status);
    });
  }

  /* copy address */

  copy(data) {

    var str = '';

    if (data.contact_address) {
      str = str + data.contact_address + ',';
    }
    if (data.contact_address_2) {
      str = str + data.contact_address_2 + ',';
    }
    if (data.contact_city) {
      str = str + data.contact_city + ',';
    }
    if (data.contact_state) {
      str = str + data.contact_state;
      if (!data.contact_country) {
        if (data.contact_zip) {
          str = str + '-';
        }
      } else {
        str = str + ',';
      }
    }
    if (data.contact_country) {
      str = str + data.contact_country;
      if (data.contact_zip) {
        str = str + '-';
      }
    }
    if (data.contact_zip) {
      str = str + data.contact_zip;
    }
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    //    this.fallbackCopyTextToClipboard(str);
    //    return;
    //  }
    //  navigator.clipboard.writeText(str).then(function() {
    //  }, function(err) {
    //    console.error('Async: Could not copy text: ', err);
    //  });
    setTimeout(() => {
      this.message = "";
    }, 2000);
    this.message = 'Copied to Clipboard'
  }

  //  fallbackCopyTextToClipboard(text) {
  //    try {
  //      var successful = document.execCommand('copy');
  //      var msg = successful ? 'successful' : 'unsuccessful';
  //    } catch (err) {
  //      console.error('Fallback: Oops, unable to copy', err);
  //    }
  //  }

  addToBookmark() {
    if (this.inputdata.id == undefined) {
      this.body = {
        contact_id: this.contact_id,
        contact_type_id: this.inputdata.contact_type_id
      }
    } else {
      this.body = {
        contact_id: this.contact_id,
        contact_type_id: this.inputdata.id
      }

    }




    this.ngxSpinnerService.show();
    this.bookmarkService.addingContactToBookmark(this.body).subscribe(response => {
      if (response.status) {
        this.isbookmarked = true
        this.toastrService.success("Added to Favorites");
      }
      else {
        this.toastrService.error("Already Added to Favorites");
      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.ngxSpinnerService.hide();
      this.toastrService.error("Something went wrong");
      console.log(error);
    });
  }


  saveselection() {

    var body = {
      contactid: this.contactByIdData.contactid,
      agreetojoin: this.agreeToJoin
    }
    if ((!this.agreeToHost && !this.contactByIdData.agreetohost) && (!this.agreeToJoin && !this.contactByIdData.agreetojoin)) {
      this.toastrService.error("Please select atleast one Option.");
    }
    else {
      this.ngxSpinnerService.show();
      body["agreetohost"] = this.agreeToHost;
      this.updateContactAgreeToHostJoinStatus(body);
    }

    // For navigation on Select
    if (sessionStorage.getItem('RelatedSourcePage') == 'market/host/contactInformation') {
      if (this.agreeToHost) {
        this.inputdata.selecturl = '/directseller/market/host/type';
        sessionStorage.setItem("contacttypeid", '4');
        //   sessionStorage.setItem('market', JSON.stringify({"id":4,"name":"host","text":"Host & Guest"}))
        sessionStorage.setItem('market', JSON.stringify({ "id": 4, "name": "host", "text": "Customer/Prospect" }))

      } else if (this.inputdata.contact_type_id == 2) { //For Customer
        this.inputdata.selecturl = '/directseller/market/customer/type';
        sessionStorage.setItem("contacttypeid", '2');
        sessionStorage.setItem('market', JSON.stringify({ "id": 2, "name": "customer", "text": "Customer" }))
      } else if (this.inputdata.contact_type_id == 3) { // For Prospects
        this.inputdata.selecturl = '/directseller/market/prospect/type';
        sessionStorage.setItem("contacttypeid", '3');
        sessionStorage.setItem('market', JSON.stringify({ "id": 3, "name": "prospect", "text": "Prospect" }))
      }
    }
    // For navigation on Select - ENd

    // else

    // {
    //   this.updateContactAgreeToHostJoinStatus(body);
    // }
    /* else if (this.agreeToHost && !this.agreeToHostDisabled) {
      this.ngxSpinnerService.show();
      this.master.createHost(this.contactByIdData).subscribe(data => {
        if (data.status) {
          this.agreeToHostDisabled = true;
          this.toastrService.success("Host created successfully");
        } else {
          this.toastrService.error(data.message);
        }
        this.ngxSpinnerService.hide();
      }, error => {
        this.toastrService.error("Something went wrong");
        this.ngxSpinnerService.hide();
      });
    }
    else {
      this.toastrService.error("Host already exists");
    }*/
  }

  selectmarketoption(id, name, text) {
    var obj = { "id": id, "name": name, "text": text }
    this.navurl = this.basedsurl + "market/" + name + "/type";
    sessionStorage.setItem("market", JSON.stringify(obj));
    sessionStorage.setItem("contacttypeid", id);
    // if((!this.agreeToHost && !this.contactByIdData.agreetohost) || !this.contactByIdData.agreetohost)
    // {
    // this.agreeToHost=true;
    // this.isMessageDisplay=false;
    // this.saveselection();
    // }
    // else
    // {
    this.router.navigate([this.navurl]);
    //  }
  }

  updateContactAgreeToHostJoinStatus(body) {
    this.master.updateContactAgreeToHostJoinStatus(body).subscribe(data => {
      if (data.status) {
        this.contactByIdData.agreetohost = this.agreeToHost;
        this.contactByIdData.agreetojoin = this.agreeToJoin;
        if (this.agreeToHost) {
          //  this.contacttypename += " / Host";
          this.contacttypename += " ";
        }
        else {
          this.contacttypename = this.contacttypename.replace(" / Host", "")
        }
        if (this.isMessageDisplay) {
          this.toastrService.success("Record updated successfully");
        }
        else {
          this.router.navigate([this.navurl]);
        }
      } else {
        this.toastrService.error(data.message);

      }
      this.ngxSpinnerService.hide();
    }, error => {
      this.toastrService.error("Something went wrong");
      this.ngxSpinnerService.hide();
    });
  }

  getProductCatalogue() {
    let self = this;
    this.productCatalogue.getProductCatalog(this.userData.user_customer_id,
      this.userData.user_ds_id, 10, 0, '', '', ''
    ).subscribe(
      (response) => {
        if (response.status) {
          self.productTotalCount = response.data.total;
        }
      },
      (error) => {
        console.log("error in product catalogue", error);
      }
    );
  }

  checkProductQty(modelName){
      if(this.productTotalCount==0){
          this.viewValidationModel(modelName);
          return ;
      }else{
        this.selectedShop(70,'shop','','optStatus', 'confirmBox');
      }
  }

  navigateProductPage(){
    this.router.navigate(['/directseller/productCatalogue/import-product']);
  }

  selectedShop(id, name, isparent, optStatus, modelName) {
    if (optStatus != undefined) {
      if ((optStatus.optIn === false) && (optStatus.optSms === false)) {
        this.viewAgreement(modelName, id, name);
        return;
      }

      /*
      if (this.isMobile) {
        if ((optStatus.optIn === true) && (optStatus.optSms === false)) {
          this.viewAgreement(modelName, id, name);
          return;
        }
        if ((optStatus.optIn === false) && (optStatus.optSms === true)) {
          this.viewAgreement(modelName, id, name);
          return;
        }

      }
      */


    }


    var obj = { "id": id, "name": name }
    var contacttype;
    if (this.inputdata.page === 'myhost') {
      contacttype = getContactTypeSpec(this.inputdata.id);
    }
    else {
      contacttype = getContactTypeSpec(this.contacttypeid);
    }

    sessionStorage.setItem("market", JSON.stringify({ "id": this.contacttypeid, "name": contacttype.route.toLocaleLowerCase(), "text": contacttype.name }));

    sessionStorage.setItem("contactid", this.contact_id);
    sessionStorage.removeItem("product_data");

    let urlRouteSnip = contacttype.route == 'host' ? 'prospect' : contacttype.route;
    var url = this.basedsurl + "market/" + urlRouteSnip + "/";

    if (name === 'shop') {
      url = url + name + "/product";
    }
    else if (isparent) {
      this.eventtype = name;
      this.eventid = id;
      sessionStorage.setItem("parentjourney", JSON.stringify({ eventid: this.eventid, eventtype: this.eventtype }));
      if (this.inputdata.name === 'host') {
        this.router.navigate([url + "/addevent"]);
      }
      else {
        this.router.navigate([url + "/subtype"]);
      }
    }
    else {
      url = url + name + "/theme";
    }

    if (!isparent) {
      sessionStorage.setItem("type", JSON.stringify(obj));
      this.router.navigate([url]);
    }

  }
  optoutAgreement() {
    var body = {
      contactid: this.contact_id,
      optin: false,
      log_type: 'OPT OUT',
      log_name: 'OPT OUT Option Selected',
      log_description: "OPT OUT Option Selected by " + this.loginUserData.user_fname + " " + this.loginUserData.user_lname
    }
    this.ngxSpinnerService.show();
    this.master.updateUserOptinStatus(body).subscribe(response => {
      this.getUserOptinStatus(this.contact_id);
    });
  }
  getUserOptinStatus(id) {
    this.master.getAllUserOptinRequest(id).subscribe(response => {
      //this.ngxSpinnerService.hide();
      if (response.status) {
        this.latestOptinReuest = response.data[0];
        if (this.latestOptinReuest.log_type === 'OPT IN') {
          this.optinStatus = true;
        }
        else {
          this.optinStatus = false;
        }
        this.allOptinRequests = response.data;
        //this.ngxSpinnerService.hide();
      }
    });
  }
  viewMoreRequests(modalName) {
    let self = this;
    self.modalService.open(modalName, { centered: true, windowClass: 'viewMoreRequestsBox' }).result.then((result) => {
    }, (reason) => {
      console.log("reason", reason);
    });
  }
  CheckMessage() {

    if (this.tooltipAgreeToHost) {
      this.isMessageDefault = true;
    }
    else {
      this.isMessageDefault = false;
    }
  }

  viewAgreement(modalName, id, name) {
    // this.router.navigateByUrl(this.inputdata.optinurl);
    this.modalService.open(modalName, { windowClass: 'view-agreement', centered: true }).result.then((result) => {
    }, (reason) => {
      if (reason == 'open') {
        // if (this.contactByIdData.optin == this.emailCheck || this.contactByIdData.optin_sms == this.smsCheck) {
        this.ngxSpinnerService.show();

        var body:any;
        if(this.contactByIdData.contact_phone){
          body = {
            contactid: sessionStorage.getItem("contactid"),
            optin: this.emailCheck,
            optin_sms: this.smsCheck
          }
        }else{
          body = {
            contactid: sessionStorage.getItem("contactid"),
            optin: this.emailCheck,
          }
        }

        this.master.updateUserOptinStatus(body).subscribe(response => {
          this.ngxSpinnerService.hide();
          this.contactByIdData.optin = this.emailCheck;
          this.contactByIdData.optin_sms = this.smsCheck;
          this.getUserOptinStatus(this.contact_id);
          this.getUpdatedStatus(id, name);

        });
        // }
      }
    })
  }

  viewValidationModel(modalName) {
    console.log("modalName", modalName);
    this.modalService.open(modalName,
      { windowClass: 'view-agreement', centered: true }).result.then((result) => {
    }, (reason) => {
      // if (reason == 'open') {
      //    this.ngxSpinnerService.show();
      // }
    })
  }





  getUpdatedStatus(id, name) {

    this.master.getContactListByIdWithMap(this.contact_id).subscribe(response => {
      this.optStatus.optIn = response.data.optin;
      this.optStatus.optSms = response.data.optin_sms;

      this.emailCheck = response.data.optin;
      this.smsCheck = response.data.optin_sms;

      var obj = { "id": id, "name": name }
      sessionStorage.setItem("type", JSON.stringify(obj));
      var contacttype;
      if (this.inputdata.page === 'myhost') {
        contacttype = getContactTypeSpec(this.inputdata.id);
      }
      else {
        contacttype = getContactTypeSpec(this.contacttypeid);
      }

      sessionStorage.setItem("market", JSON.stringify({ "id": this.contacttypeid, "name": contacttype.route.toLocaleLowerCase(), "text": contacttype.name }));

      sessionStorage.setItem("contactid", this.contact_id);
      sessionStorage.removeItem("product_data");


      /* if (this.isMobile) {
         if ((name != undefined) && ((this.optStatus.optIn === true) && (this.optStatus.optSms === true))) {
           if (name === 'shop') {
             this.router.navigate(['/directseller/market/prospect/shop/product']);
           } else if (name === 'host') {
             this.router.navigate(['/directseller/market/prospect/host/theme']);
           } else if (name === 'join') {
             this.router.navigate(['/directseller/market/prospect/join/theme']);
           }
         }

       } */

      if ((name != undefined) && ((this.optStatus.optIn === true) || (this.optStatus.optSms === true))) {
        if (name === 'shop') {
          this.router.navigate(['/directseller/market/prospect/shop/product']);
        } else if (name === 'host') {
          this.router.navigate(['/directseller/market/prospect/host/theme']);
        } else if (name === 'join') {
          this.router.navigate(['/directseller/market/prospect/join/theme']);
        }
      }


    });
  }



  checkedEmail(e) {

    this.emailCheck = e.target.checked;
  }

  smsChecked(e) {
    this.smsCheck = e.target.checked;
  }

  agree() {
    this.modalService.dismissAll('open');
    this.showMessage = false;
  }

  addCoHostModal(modalName, id, name, isparent) {

    var self = this;
    self.modalService.open(modalName).result.then((result) => {

      var obj = { "id": 4, "name": 'host', "text": 'Customer/Prospect' }
      sessionStorage.setItem("market", JSON.stringify(obj));
      sessionStorage.setItem("contacttypeid", '4');
      sessionStorage.setItem("hosttype", JSON.stringify({ "hosttype": "addcohost", "index": 0 }));

      if (result == 'Yes') {
        //   var obj = { "id": 4, "name": 'host', "text": 'Host & Guest' }
        this.router.navigate(["/directseller/market/host/contact"]);
      } else {
        sessionStorage.setItem("parentjourney", JSON.stringify({ eventid: id, eventtype: name }));
        this.router.navigate(["/directseller/market/host/addevent"]);
        //this.selectedShop(id, name, isparent);
      }
    }, (reason) => {
      console.log(reason);
    });
  }


  /******* Geo Locations Functions *********/

  updateCountryStateCity() {
    if (this.contactByIdData.contact_state && this.contactByIdData.contact_state.length == 2) {
      this.getStateByCode(this.contactByIdData.contact_country, this.contactByIdData.contact_state);
    }

    if (!isNaN(this.contactByIdData.contact_city)) {
      this.getCityById(this.contactByIdData.contact_city);
    }
  }


  getCountryByCode(countryId: string) {

    this.master.getCountryByCode(countryId).subscribe((objArr: any) => {

      this.contactByIdData.contact_country = objArr[0].name;
      this.countryFlag = true;

      if (this.cityFlag && this.stateFlag && this.countryFlag) {
        let contactAddresDetails = {
          address: this.contactByIdData.contact_address,
          city: this.contactByIdData.contact_city,
          state: this.contactByIdData.contact_state,
          country: this.contactByIdData.contact_country
        }

        let completeAddr = this.contactByIdData.contact_address + ', ' + this.contactByIdData.contact_city + ', ' + this.contactByIdData.contact_state + ', ' + this.contactByIdData.contact_country;

        this.mapURL = "../../../../assets/images/addressNotFound.jpg";

        this.master.getMapImageForAddress(contactAddresDetails) // Now Get MAP for the contact address
          .subscribe(mapData => {
            this.mapURL = mapData.mapURL;
          });
      }
    });
  }

  getStateByCode(countryId: string, stateCode: string) {

    this.master.getStateByCode(countryId, stateCode).subscribe((objArr: any) => {

      this.contactByIdData.contact_state = objArr.name;
      this.stateFlag = true;
      this.getCountryByCode(this.contactByIdData.contact_country);
    });
  }

  getCityById(geonameId: any) {

    if (geonameId != null && !isNaN(geonameId) && geonameId != '' && geonameId != '') {
      this.master.getCityById(geonameId).subscribe((objArr: any) => {

        //this.selectedCountryObj = objArr[0];
        this.contactByIdData.contact_city = objArr.name;
        this.cityFlag = true;
      });
    }
  }
  /******* Geo Locations Functions - End *********/

  isOpen(i): boolean {
    let x = parseInt(i, 10);
    return this.accordion && this.accordion.activeIds.includes(x.toFixed());
  }

  //  #############  Get FB Messenger Link ########################   //

  getFBMessenger(fid) {
    let arr = fid.split('/');
    let url = 'https://www.facebook.com/messages/t/' + arr[3];
    window.open(
      url,
      '_blank'
    );

  }

  navigateByDesignDashboard() {
    let url = `https://tools.securefreedom.com/WineShop/Party/I${this.userData.rep_id}?MarketShow=4&customerid=${this.contactByIdData.bu_customer_id}`;
    window.open(url, '_blank');
  }

  navigateWinecellar() {
    let url = `https://winecellar.wineshopathome.com/I${this.userData.rep_id}?MarketShow=4&customerid=${this.contactByIdData.bu_customer_id}`;
    window.open(url, '_blank');
  }



  //  ##############  On Component Destroy  ########################  //

  ngOnDestroy(): any {
    this.modalService.dismissAll();
  }


  sendActivationLink() {
    let data = {
      email: this.contactByIdData.contact_email,
      user_fname: this.contactByIdData.contactname,
      id: this.userId,
      parentUserFullName: this.userData.user_fname + ' ' + this.userData.user_lname,

    }
    // return false;
    this.master.sendActivationLink(data).subscribe(data => {
      if (data.status) {
        this.toastrService.success("Resend Link sent successfully");
      }
    });
  }

  sendContactActivationLink(status:any) {
    let data = {
      contact_email: this.contactByIdData.contact_email,
      ds_id: this.contactByIdData.userid,
      contact_id: this.contactByIdData.contact_id,
      contact_fname:this.contactByIdData.contact_fname,
      contact_lname:this.contactByIdData.contact_lname
    }
    // return false;
    this.master.sendContactActivationLink(data,this.contactByIdData.customer_id).subscribe(data => {
      if (data.status) {
        this.toastrService.success(`${status=='resend'?'Resend link':'Link'} sent successfully`);
      }
    });
  }

  getHardBounceDetail() {
    var email = this.contactByIdData.contact_email;
    // email = '0@YAHOO.COM';
    this.master.hardBounceDetail(email).subscribe(data => {
      if (data.status) {
        this.hardBounceStatus = true;
        this.hardBounce = data.data[0].bounce_reason;
      }
    });
  }

  goToInstantMessage() {





    if (this.contactByIdData.sms_consent === 'Accepted') {
        this.navigateAsPerSmsConsentStatus();
    }

    if (this.contactByIdData.sms_consent === 'Pending') {

      if (!this.contactByIdData?.contact_phone) {
        this.toastrService.error('Contact doesn`t have a valid phone number');
        return;
      }

       let obj = [];
       let con = this.contactByIdData;
       if(con?.contact_country==''){
            return false;
       }
       obj.push(con);
       let body = {
        contacts: obj,
        ds_sender_number: this.dsSenderNumber,
        ds_sender_whatsapp_number : this.dsSenderWhatsappNumber,
        ds_sender_messenger_number : this.dsSenderMessengerNumber,
        sms_service: this.senderType,

       }
       console.log("body", body);
       this.mb.sendConsentMessage(body, this.senderType).subscribe(data => {
        if (data.status) {
          this.router.navigateByUrl('directseller/sms/sms-consent');
        } else {
          this.toastrService.error(data.message);
        }
      }, err => {
        this.toastrService.error('Something went Wrong !!');
      })
    }

    if (this.contactByIdData.sms_consent === 'Requested') {
      this.router.navigateByUrl('directseller/sms/sms-consent');
    }
  }

  setData(event:any) {




    if(event.tab.textLabel==='Instant Message'){


        if(this.contactByIdData?.sms_consent !== 'Rejected' || this.contactByIdData?.username !== ''  ){
          //this.goToInstantMessage();
          sessionStorage.setItem('chatcontact',this.contactByIdData.contact_id)


          this.navigateAsPerSmsConsentStatus();
        }
    }
  }

  async getCountryCode(code: any): Promise<string> {
    try {
      const data = await this.ReskinningService.getCountryCodes().toPromise();
      this.CountryCodes = data;
      const d = this.CountryCodes.find(el => el.code === code);
      const countrySortCode = d?.dial_code || "+1";
      return countrySortCode;
    } catch (error) {
      console.error("Error fetching country code:", error);
      return "+1";
    }
  }

  async navigateAsPerSmsConsentStatus() {
    // let concode = '+1';
    let concode = await this.getCountryCode(this.contactByIdData?.contact_country);
    // if (this.contactByIdData?.contact_country === 'IN') concode = '+91';

    let nm = this.contactByIdData.contact_fname + ' ' + this.contactByIdData.contact_lname;
    let ph = concode + this.contactByIdData.contact_phone;
    localStorage.setItem("sendTo", ph);
    localStorage.setItem("sendName", nm);

    this.router.navigateByUrl('directseller/messenger/contact-messenger');
  }


}
