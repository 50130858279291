import { Component, OnInit, Input, Host } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../core/services/master/master.service';
import { Router } from '@angular/router';
import { ImportICloudUpdateLogsService } from './import-icloud-update-logs.service';
import { LogsService } from 'src/app/services/logs/logs.service';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { ReskinningService } from 'src/app/_services/reskinning.service';

@Component({
  selector: 'app-import-icloud-dialog',
  templateUrl: './import-icloud-dialog.component.html',
  styleUrls: ['./import-icloud-dialog.component.css']
})
export class ImportICloudDialogComponent implements OnInit {

  @Input() securityCode: boolean;
  @Input() smsCheck: boolean;
  @Input() emailCheck: boolean;
  rskmodellogoFlag = this.ReskinningService.rskModelLogoFlag;
  rskmodellogo = this.ReskinningService.rskModelLogo;
  appleId: string;
  password: string;
  securityCodeVal: number;

  value = 0;
  width = "0%";
  status = true;
  display = "none";
  importData;
  userData: any;
  

  constructor(
    private activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private mService: MasterService,
    private iCloudService: ImportICloudUpdateLogsService,
    private logs: LogsService,
    private ngxSpinnerService: NgxSpinnerService,
    public datepipe: DatePipe,
    private router: Router,
    private ReskinningService:ReskinningService) { }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('UserData'));
  }

  fetchICloudContacts() {
    let dataObj = {
      userId: this.appleId,
      password: this.password,
      customer_id: this.userData.user_customer_id,
      customer_name: this.userData.username,
      mulestatus: false,
      contact_type_id: sessionStorage.getItem('contacttypeid'),
      import_type: "2",
      import_status: true,
      isactive: true,
      smsCheck: this.smsCheck,
      emailCheck: this.emailCheck,
      ds_id: this.userData.user_ds_id,
      filename:""
    };
    this.ngxSpinnerService.show();
    let date=new Date();
    let latest_date =this.datepipe.transform(date, 'yyyy-MM-ddHH:mm');
    dataObj.filename = 'iCloudImportTemplate'+latest_date+'.csv';
   // return true;
     // dataObj.ip = '160.202.39.224';
      this.mService.iCloudCreds(dataObj).subscribe((data) => {
        if (data.status) {
          // Login Successful
          if (data.reqSecCode) {
            // Required security code / 2 factor authentication
            this.securityCode = true;
            this.toastrService.success(data.message);
          } else {
            // security code not required, data received
            this.toastrService.success(data.message);
            this.addLogData();
            setTimeout(() => {
              this.iCloudService.sendImportedLogFlag('1');
            }, 2000);
            this.activeModal.dismiss();
            //this.router.navigate(['/directseller', 'import-contacts']);
          }
          this.ngxSpinnerService.hide();
        } else {
          this.toastrService.error(data.message);
          this.ngxSpinnerService.hide();
        }
      });
  
  }

  addLogData() {
    let value = {
      log_type: "iCloud Import DS Contact",
      log_name: "iCloud Import DS Contact",
      log_description: "iCloud Import DS Contact",
      moduleid: 1,
      moduleitemid: 7,
      per_id: 14,
    }
    this.logs.addNewLogs(value).subscribe(data => {
    }, error => {
      //console.log(error);
    });
  }

  iCloudSecurityCode() {
    let dataObj = { 
      securityCode: this.securityCodeVal,
      userId: this.appleId,
      password: this.password,
      customer_id: this.userData.user_customer_id,
      customer_name: this.userData.username,
      mulestatus: false,
      contact_type_id: sessionStorage.getItem('contacttypeid'),
      import_type: "2",
      import_status: true,
      isactive: true,
      smsCheck: this.smsCheck,
      emailCheck: this.emailCheck,
      ds_id: this.userData.user_ds_id,
      filename:""
    };
    this.ngxSpinnerService.show();
    let date=new Date();
    let latest_date =this.datepipe.transform(date, 'yyyy-MM-ddHH:mm');
    dataObj.filename = 'iCloudImportTemplate'+latest_date+'.csv';
    this.activeModal.dismiss();
    this.securityCode = false;
      this.mService.iCloudSecurityCode(dataObj).subscribe((data) => {
    

        if (data.status) {
          this.toastrService.success(data.message);
          setTimeout(() => {
            this.iCloudService.sendImportedLogFlag('1');
          }, 2000);

          // this.activeModal.dismiss();
          //this.router.navigate(['/directseller', 'import-contacts']);
        } else {
          this.toastrService.error(data.message);
        }

        this.addLogData();
        this.ngxSpinnerService.hide();
      });
 
  }

  displayBox(value) {
    let self = this;
    setTimeout(() => {
      self.activeModal.close(value)
    }, 1000);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}

