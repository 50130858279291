<div (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)" id="main-menu"
  class="main-menu-left menu-fixed menu-dark menu-accordion menu-shadow pos-relotive" data-scroll-to-active="true">
  <div id="main-menu-content" class="main-menu-content ps-container ps-theme-light" fxFlex="auto"
    [perfectScrollbar]="config">
    <ul class="navigation navigation-main leftPanMob" id="main-menu-navigation" data-menu="menu-navigation">
      <!-- Menu -->
      {{child?child.title:''}}
      <li *ngFor="let child of _menuSettingsConfig?.vertical_menu?.items" class="" routerLinkActive="active" [ngClass]="{
            'has-sub': child.submenu,
            'open': child.isOpen && child.submenu,
            'nav-item': child.title,
            'navigation-header':child.section,
            'menu-collapsed-open': child.isSelected && child.submenu}">
        <!-- Section -->

        <span class="menu-title" *ngIf="child.section">{{child.section}}</span>
        <i class="la" *ngIf="child.section" [ngClass]="child.icon" data-toggle="tooltip" data-placement="right"
          data-original-title="Support"></i>
        <!-- <img [src]="child.icon" [alt]="child.icon" height="45px" width="auto"> -->
        <!-- Root Menu -->
        <a *ngIf="child.title && !child.submenu && !child.excludeInVertical && !child.isExternalLink && !child.issupportExternalLink"
          routerLink="{{child.page != 'null'?child.page:router.url}}" (click)="toggleMenu($event, child)">
          <!-- <i class="la" [ngClass]="child.icon"></i> -->
          <div class="vertiLeftIcon"><img [src]="child.icon" [alt]="child.icon" height="45px" width="auto"></div>
          <div class="menu-title" data-i18n="">{{child.title}}
            <!--<span class="beta_version" *ngIf="child.code=='SMB'">(Beta)</span>-->
          
          </div>
          <div *ngIf="child.badge && tempSessionCount" class="badge badge-pill"
            [ngClass]="{'badge-info mr-2': child.badge.type=='badge-info' , 'badge-danger':child.badge.type=='badge-danger'}">
            {{tempSessionCount}}
          </div>
          <div *ngIf="child.badge2 && textMessageCount" class="badge badge-pill quickmsg"
            [ngClass]="{'badge-info mr-2': child.badge2.type=='badge-info' , 'badge-danger':child.badge2.type=='badge-danger'}">
            {{textMessageCount}}
          </div>
        </a>
        <a *ngIf="child.title && !child.submenu && !child.excludeInVertical && child.isExternalLink" [href]="child.page"
          (click)="toggleMenu($event, child)">
          <!-- <i class="la" [ngClass]="child.icon"></i> -->
          <div class="vertiLeftIcon"><img [src]="child.icon" [alt]="child.icon" height="45px" width="auto"></div>
          <span class="menu-title" data-i18n="">{{child.title}}</span>
          <span *ngIf="child.badge" class="badge badge-pill float-right"
            [ngClass]="{'badge-info mr-2': child.badge.type=='badge-info' , 'badge-danger':child.badge.type=='badge-danger'}">
            {{child.badge.value}}
          </span>
        </a>


        <!-- Submenu -->
        <a *ngIf="child.title && child.submenu && !child.excludeInVertical"
          routerLink="{{child.page != 'null'?child.page:router.url}}" (click)="toggleMenu($event, child)">
          <!-- <i class="la" [ngClass]="child.icon"></i> -->
          <img [src]="child.icon" [alt]="child.icon" height="45px" width="auto">
          <span class="menu-title" data-i18n="">{{child.title}}</span>
          <span *ngIf="child.badge" class="badge badge-pill float-right"
            [ngClass]="{'badge-info mr-2': child.badge.type=='badge-info' , 'badge-danger':child.badge.type=='badge-danger'}">
            {{child.badge.value}}
          </span>
        </a>
        <ul *ngIf="child.submenu" class="menu-content" [@popOverState]="child.isOpen">
          <!-- Submenu of Submenu -->
          <li *ngFor="let subchild of child.submenu.items" class="isShown"
            [ngClass]="{'has-sub':(subchild.submenu),'active': subchild.isSelected && !subchild.submenu, 'open': subchild.isOpen && subchild.submenu}">
            <a class="menu-item" *ngIf="!subchild.submenu && !subchild.excludeInVertical"
              (click)="toggleMenu($event, subchild, true)"
              routerLink="{{subchild.page != 'null'?subchild.page:router.url}}">{{subchild.title}}</a>
            <a class="menu-item" *ngIf="subchild.submenu && !subchild.excludeInVertical"
              (click)="toggleMenu($event, subchild, true)"
              routerLink="{{subchild.page != 'null'?subchild.page:router.url}}">{{subchild.title}}</a>
            <ul *ngIf="subchild.submenu && !subchild.excludeInVertical" class="menu-content">
              <li *ngFor="let subchild of subchild.submenu.items"
                [ngClass]="{'active': subchild.isSelected && !subchild.submenu}">
                <a class="menu-item" *ngIf="!subchild.event" routerLink="{{subchild.page}}"
                  (click)="toggleMenu($event, subchild, true)">{{subchild.title}}</a>
                <a class="menu-item" *ngIf="subchild.event"
                  (click)="callFunction(subchild.event, subchild)">{{subchild.title}}</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li *ngIf="requestList.length>0 && !support_user"><a routerLink="/directseller/help">
          <div class="vertiLeftIcon"><img src="assets/custom/images/supportReq.svg" alt="Support Request" height="45px"
              width="auto"></div>
          <!-- <img _ngcontent-tqo-c9="" class="ng-tns-c9-0" src="assets/custom/images/6_1.png" alt="assets/custom/images/6_1.png" width="auto" height="45px"> -->
          <span class="menu-title">Support Request({{requestList.length}})</span>
        </a></li>
    </ul>
  </div>
  <!-- <div class="secLogo" *ngIf="customercompanylogo">
    <img [src]="customercompanylogo">
  </div> -->
</div>
