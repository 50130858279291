import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatdatewithtimezone'
})
//Will be used for Bypassing Security which are blocked by Angular
export class FormatDateWithTimezone implements PipeTransform {

  constructor() { }

  public transform(date: any, timezone: any): string {
    if (date && date.length > 1) {
      var formatdate = new Date(date).toLocaleString("en-US", { timeZone: timezone });
      return formatdate;
    }
    else {
      return date;
    }
  }

}
