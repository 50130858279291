import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthserviceService } from "../core/services/authservice.service";

@Injectable({
  providedIn: "root",
})
export class ReadinessGuardGuard implements CanActivateChild {
  constructor(private auth: AuthserviceService, private router: Router) {}
  activeRoutes: any[] = [
    "/directseller/updatetimezone",
    "/directseller/myaccount/myprofile/sender-profile",
    "/directseller/myaccount/myprofile/update",
    "/directseller/productCatalogue/import-product",
    "/directseller/mycontact/add",
    "/directseller/import-contacts",
    "/directseller/addReskinning",
    "/directseller/setup",
    "/directseller/home",
    "/directseller/addProductCatalogue",
    "/directseller/agreed",
    "/",
  ];
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let data = localStorage.getItem("UserData");
    if (data) {
      let jsonData = JSON.parse(data);
      if (
        this.activeRoutes.includes(state.url.split('?')[0]) == false &&
        jsonData.readyness_status == false
      ) {
        this.router.navigateByUrl("/directseller/setup");
      }
      return true;
    } else {
      return true;
    }
  }
}
