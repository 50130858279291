import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseserviceService } from './baseservice.service';

@Injectable({
  providedIn: 'root'
})
export class MiniurlService {

  public baseUrl;
  public baseUrl2;
  constructor(
    private http: HttpClient,
    public baseService: BaseserviceService
  ) {
    this.baseUrl = baseService.baseUrl1 + "api/miniurl/";
    this.baseUrl2 = baseService.baseUrl1 + "api/master/";
  }

  getMiniUrlByCode(code:any) {
    return this.http
      .get<any>(
        this.baseUrl2 +
          `getMiniUrlByCode/${code}`,

        this.httpHeadersOptions()
      )
      .pipe(
        map((data: any) => {
          if (!data.status) {
            if (
              data.message &&
              (data.message.indexOf("Error acquiring client") >= 0 ||
                data.message.indexOf("Error executing query") >= 0)
            ) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again.";
            }
          }
          return data;
        }),
        catchError(this.handleError)
      );
  }
    //tohandle the error
    private handleError(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error("An error occurred:", error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` + `body was: ${error.error}`
        );
      }
      // return an observable with a user-facing error message
      return throwError("Something bad happened; please try again later.");
    }
    private httpHeadersOptions() {
      return this.baseService.httpHeadersOptions();
    }
}
