import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { BaseserviceService } from "../baseservice.service";
import { throwError } from "rxjs";
import { environment } from '../../../environments/environment';
import * as S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: "root",
})
export class ProductCatalogueService {
  public baseUrl;
  public baseUrl2: string;
  constructor(
    private http: HttpClient,
    public baseService: BaseserviceService
  ) {
    this.baseUrl = baseService.baseUrl1 + "api/master/";
    this.baseUrl2 = baseService.baseUrl1 + "api/";
  }

  //  ##################  Get Product Catalog list  ##################  //

  getProductCatalog(id,ds_id, limit, skip, searchText, colname, order) {
    return this.http
      .get<any>(
        this.baseUrl +
          `getProductList/${id}/${ds_id}?limit=${limit}&skip=${skip}&sort=${colname}&order=${order}&search=${searchText}`,

        this.httpHeadersOptions()
      )
      .pipe(
        map((data: any) => {
          if (!data.status) {
            if (
              data.message &&
              (data.message.indexOf("Error acquiring client") >= 0 ||
                data.message.indexOf("Error executing query") >= 0)
            ) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again.";
            }
          }
          return data;
        }),
        catchError(this.handleError)
      );
  }
  //add product catalog
  addingCatalog(value) {
    var body = value;
    return this.http
      .post<any>(
        this.baseUrl + "addingProductCatelogue",
        body,
        this.httpHeadersOptions()
      )
      .pipe(
        map((data) => {
          if (!data.status) {
            if (
              data.message &&
              (data.message.indexOf("Error acquiring client") >= 0 ||
                data.message.indexOf("Error executing query") >= 0)
            ) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again.";
            }
          }
          return data;
        }),
        catchError(this.handleError)
      );
  }

  getProductCatalogById(id,ds_id, productId) {
    // var body = {company_id:id, type:type};
    return this.http
      .get<any>(
        this.baseUrl + `getProductList/${id}/${ds_id}/${productId}`,

        this.httpHeadersOptions()
      )
      .pipe(
        map((data) => {
          if (!data.status) {
            if (
              data.message &&
              (data.message.indexOf("Error acquiring client") >= 0 ||
                data.message.indexOf("Error executing query") >= 0)
            ) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again.";
            }
          }
          return data;
        }),
        catchError(this.handleError)
      );
  }
  //update product catalog
  UpdateCatalog(value) {
    var body = value;
    return this.http
      .put<any>(
        this.baseUrl + "updateProductCatelogue",
        body,
        this.httpHeadersOptions()
      )
      .pipe(
        map((data) => {
          if (!data.status) {
            if (
              data.message &&
              (data.message.indexOf("Error acquiring client") >= 0 ||
                data.message.indexOf("Error executing query") >= 0)
            ) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again.";
            }
          }
          return data;
        }),
        catchError(this.handleError)
      );
  }
    //update product catalog status
    UpdateCatalogStatus(value) {
      var body = value;
      return this.http.put<any>(this.baseUrl + `updatproductCateloguestatus`, body, this.httpHeadersOptions())
        .pipe(
          map(data => {
            if (!data.status) {
              if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
    }
  getProductCatalogCategoryByCompanyId(companyID) {
      // var body = {company_id:id, type:type};
      return this.http
        .get<any>(
          this.baseUrl2 + `productCatalogueProductCategoryByCompanyId/${companyID}`,

          this.httpHeadersOptions()
        )
        .pipe(
          map((data) => {
            if (!data.status) {
              if (
                data.message &&
                (data.message.indexOf("Error acquiring client") >= 0 ||
                  data.message.indexOf("Error executing query") >= 0)
              ) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again.";
              }
            }
            return data;
          }),
          catchError(this.handleError)
        );
    }
    checkProductExist(id,companyID) {
      // var body = {company_id:id, type:type};
      return this.http
        .get<any>(
          this.baseUrl + `checkProductExists/${companyID}/${id}`,

          this.httpHeadersOptions()
        )
        .pipe(
          map((data) => {
            if (!data.status) {
              if (
                data.message &&
                (data.message.indexOf("Error acquiring client") >= 0 ||
                  data.message.indexOf("Error executing query") >= 0)
              ) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again.";
              }
            }
            return data;
          }),
          catchError(this.handleError)
        );
    }
  // Get product listing for selected/Unselected
  getProductCatalogSelectedList(id,ds_id, limit, skip, searchText, colname, order,isSelected) {
    return this.http
      .get<any>(
        this.baseUrl +
          `getbuProductList/${id}/${ds_id}?limit=${limit}&skip=${skip}&sort=${colname}&order=${order}&search=${searchText}&is_selected=${isSelected}`,

        this.httpHeadersOptions()
      )
      .pipe(
        map((data: any) => {
          if (!data.status) {
            if (
              data.message &&
              (data.message.indexOf("Error acquiring client") >= 0 ||
                data.message.indexOf("Error executing query") >= 0)
            ) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again.";
            }
          }
          return data;
        }),
        catchError(this.handleError)
      );
  }
  // Api for select/Unselect
  updateBuProductCatalog(id:any,body:any) {
      return this.http
        .post<any>(
          this.baseUrl +
            `addbuProduct/${id}`,body,

          this.httpHeadersOptions()
        )
        .pipe(
          map((data: any) => {
            if (!data.status) {
              if (
                data.message &&
                (data.message.indexOf("Error acquiring client") >= 0 ||
                  data.message.indexOf("Error executing query") >= 0)
              ) {
                console.error(data.message);
                data.message = "Something went wrong.Please try again.";
              }
            }
            return data;
          }),
          catchError(this.handleError)
        );
  }
  // Api for select/Unselect
  addStockQuantity(id:any,body:any) {
          return this.http
            .put<any>(
              this.baseUrl +
                `updateQuantity/${id}`,body,

              this.httpHeadersOptions()
            )
            .pipe(
              map((data: any) => {
                if (!data.status) {
                  if (
                    data.message &&
                    (data.message.indexOf("Error acquiring client") >= 0 ||
                      data.message.indexOf("Error executing query") >= 0)
                  ) {
                    console.error(data.message);
                    data.message = "Something went wrong.Please try again.";
                  }
                }
                return data;
              }),
              catchError(this.handleError)
            );
        }
  getStockLogs(companyId:any,dsId:any,productId:any,limit:any,skip:any){
    return this.http
    .get<any>(
      this.baseUrl +
        `stock-details/${companyId}/${dsId}/${productId}?limit=${limit}&skip=${skip}`,

      this.httpHeadersOptions()
    )
    .pipe(
      map((data: any) => {
        if (!data.status) {
          if (
            data.message &&
            (data.message.indexOf("Error acquiring client") >= 0 ||
              data.message.indexOf("Error executing query") >= 0)
          ) {
            console.error(data.message);
            data.message = "Something went wrong.Please try again.";
          }
        }
        return data;
      }),
      catchError(this.handleError)
    );
  }
  getIndependentProductCatalogSelectedList(id,ds_id, limit, skip, searchText, colname, order,isSelected) {
    return this.http
      .get<any>(
        this.baseUrl +
          `getIndepedentProductList/${id}/${ds_id}?limit=${limit}&skip=${skip}&sort=${colname}&order=${order}&search=${searchText}&is_selected=${isSelected}`,

        this.httpHeadersOptions()
      )
      .pipe(
        map((data: any) => {
          if (!data.status) {
            if (
              data.message &&
              (data.message.indexOf("Error acquiring client") >= 0 ||
                data.message.indexOf("Error executing query") >= 0)
            ) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again.";
            }
          }
          return data;
        }),
        catchError(this.handleError)
      );
  }
  addIndependentStockQuantity(id:any,body:any) {
    return this.http
      .post<any>(
        this.baseUrl +
          `add-independent-stock-quantity/${id}`,body,

        this.httpHeadersOptions()
      )
      .pipe(
        map((data: any) => {
          if (!data.status) {
            if (
              data.message &&
              (data.message.indexOf("Error acquiring client") >= 0 ||
                data.message.indexOf("Error executing query") >= 0)
            ) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again.";
            }
          }
          return data;
        }),
        catchError(this.handleError)
      );
  }
  checkCampaignDays(productId:any,contactId:any,journeyId:any) {
    return this.http
      .get<any>(
        this.baseUrl2 +
          `ds/checkCampaignDays/${productId}/${contactId}/${journeyId}`,

        this.httpHeadersOptions()
      )
      .pipe(
        map((data: any) => {
          if (!data.status) {
            if (
              data.message &&
              (data.message.indexOf("Error acquiring client") >= 0 ||
                data.message.indexOf("Error executing query") >= 0)
            ) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again.";
            }
          }
          return data;
        }),
        catchError(this.handleError)
      );
  }
  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }
  uploadfileProductCatalog(newFileName, file, userKey, cb, type = 1) {
    return;
    const bucket = new S3({
      // accessKeyId: environment.accessKeyId,
      // secretAccessKey: environment.secretAccessKey,
      // region: environment.region
    });

    const params = {
      Bucket: environment.awsBucket,
      Key: userKey + '/' + newFileName,
      Body: file
    };

    bucket.upload(params, function (err, data) {
      // console.log(err, data)
      if (err) {
        cb(err, null);
        // console.log('There was an error uploading your file: ', err);
        return err;
      }
      cb(null, data);
      return data;
    });
    return "ok";
  }
  getUrlfile(awsKey,bucket_secured=false) {
    return environment.s3URL+awsKey;

    // if(bucket_secured == true) {
    const bucket = new S3({
      // accessKeyId: environment.accessKeyId,
      // secretAccessKey: environment.secretAccessKey,
      // region: environment.region
    });

    var params = { Bucket: environment.awsBucket, Key: awsKey };
    var url = bucket.getSignedUrl('getObject', params);

    return url;
  // }
  // else return environment.s3URL+awsKey;
  }

  uploadfile(file, userKey, cb, type = 1) {
    return;
    const bucket = new S3({
      // accessKeyId: environment.accessKeyId,
      // secretAccessKey: environment.secretAccessKey,
      // region: environment.region
    });

    const params = {
      Bucket: environment.awsBucket,
      Key: userKey + '/' + Date.now().toString(36) + '.' + file.name.split('.').pop(),
      Body: file
    };

    bucket.upload(params, function (err, data) {
      // console.log(err, data)
      if (err) {
        cb(err, null);
        // console.log('There was an error uploading your file: ', err);
        return err;
      }
      cb(null, data);
      return data;
    });
    return "ok";
  }

  importBulk(url, value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + url, body, this.httpHeadersOptions())
      .pipe(
        map(data => {
          if (!data.status) {
            if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  deleteProductImage(body:any) {
    return this.http
      .put<any>(this.baseUrl2+`master/delete-image`,body,this.httpHeadersOptions())
      .pipe(
        map((data: any) => {
          if (!data.status) {
            if (
              data.message &&
              (data.message.indexOf("Error acquiring client") >= 0 ||
                data.message.indexOf("Error executing query") >= 0)
            ) {
              console.error(data.message);
              data.message = "Something went wrong.Please try again.";
            }
          }
          return data;
        }),
        catchError(this.handleError)
      );
  }


}
