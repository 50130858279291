import { Component, OnInit,Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookmarkService } from '../../services/bookmark.service';
import { BaseComponent } from '../../base.component';
import { ConfirmationDialogService } from 'src/app/_utils/confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'app-bookmarklist',
  templateUrl: './bookmarklist.component.html',
  styleUrls: ['./bookmarklist.component.css']
})
export class BookmarkListComponent extends BaseComponent implements OnInit {
  cp=1;
  bookmarkresults;
  morderdata;
  contact_id;
  contactByIdData;
  query_contact_id;
  selectThemeData;
  theme_id;
  customerID;
  contacttypeid;
  contacttypename;
  contacttypetext;
  type;
  product_id;
  eventtype;
  prospectdata;


  constructor(
    private modalService: NgbModal,
    private bookmarkService: BookmarkService,
    private confirmationDialogService: ConfirmationDialogService,
  ) {
    super();
    this.addLogData("Bookmark Page","Bookmark page view");
  }

  ngOnInit() {
    if (sessionStorage.getItem("contacttypeid")) {
      this.contacttypeid = parseInt(sessionStorage.getItem("contacttypeid"));
      this.getBookmarkList();
    }

  }

  getBookmarkList() {
    let self = this;
    let body = {
      contact_type_id: this.contacttypeid,
      limit:100,
      skip:0
    }

    
    this.ngxSpinnerService.show();
    self.bookmarkService.getContactsBookmarkList(body).subscribe(data => {
      if (data.status) {
        self.bookmarkresults = data.data;
      } else {
        self.bookmarkresults = [];
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });
  }
  deleteContactFromBookMarkById(bookmarkid) {
    this.confirmationDialogService.confirm('', 'WOULD YOU LIKE TO DELETE?', 'YES', 'NO')
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.bookmarkService.deleteContactFromBookMarkById(bookmarkid).subscribe(data => {

            if (data.status) {
              this.toastrService.success("Contact removed from bookmark list successfully.");
              this.getBookmarkList();
            } else {
              this.toastrService.error("something went wrong.");
              this.ngxSpinnerService.hide();
            }
            
          }, error => {
            console.log(error);
            this.ngxSpinnerService.hide();
          });
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

  }

  selectContact(id, index) {

    this.prospectdata = this.bookmarkresults[index];
    sessionStorage.setItem("contactid", id);
    sessionStorage.setItem("contactdata", JSON.stringify(this.prospectdata));
    //this.router.navigate([this.basedsurl+"market/prospect/contactInformation"]);

   

    if(this.contacttypeid==4)


     // this.router.navigate([this.basedsurl+"social/viewhost"]);
     this.router.navigate([this.basedsurl+"myhost/view"]); 


    else
    this.router.navigate([this.basedsurl+"market/prospect/contactInformation"]);

    


  }


}