import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';
import { AuthserviceService } from '../services/auth/authservice.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthserviceService,
        public toastr: ToastrService,
        public route: Router,
        public ngxSpinnerService: NgxSpinnerService
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ([401, 403].indexOf(err.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                this.authenticationService.logout();
                this.route.navigate(['/login']);
               // location.reload(true);
            }
                const error = err.error.message || err.statusText;
                this.handleError(error, error.error);
                return throwError(error);
        }))
    }

    handleError(error: any, data: any) {
        if (error.status == 0) {
          if (!navigator.onLine) {
            this.ngxSpinnerService.hide();
            this.toastr.info('No internet connection.');
          }
        }
      }
    
}