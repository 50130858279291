import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  UntypedFormControl,
  FormControl,
} from "@angular/forms";
import { BaseComponent } from "src/app/firstadmin/base.component";
import { ViewChild } from "@angular/core";
import { Subject, ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MasterService } from "src/app/core/services/master/master.service";
import { TimeZoneService } from "src/app/firstadmin/services/timezone.service";
import { MatLegacySelect as MatSelect } from "@angular/material/legacy-select";
import { AuthserviceService } from "src/app/services/auth/authservice.service";
import { environment } from "src/environments/environment";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { AdminoneService } from "src/app/services/adminone/adminone.service";
import { AppConstants } from "src/app/_helpers/app.constants";
import { required } from "@rxweb/reactive-form-validators";
import { ReskinningService } from "src/app/services/reskinning/reskinning.service";

@Component({
  selector: "app-updatetimezone",
  templateUrl: "./updatetimezone.component.html",
  styleUrls: ["./updatetimezone.component.css"],
})
export class UpdateTimeZoneComponent extends BaseComponent implements OnInit {
  verifyNextStep: any = [
    "Go to the Sender Profile page.",
    "Click on the CSV icon on the right-hand side to download the DNS information.",
    "The DNS details will be provided in an Excel sheet.",
  ];

  verifyActionRqrd: any = [
    "Share the DNS information (MX and TXT records) with your IT administrator.",
    "Ask your IT admin to add these records to your email hosting platform to complete the authentication process.",
  ];

  selectedOption: any = "false";
  public userProfileDetails;
  status = false;
  profileForm: UntypedFormGroup;
  submitted = false;
  user_time_zone = "";
  timezone;
  timezones = [];
  submitButton: boolean = false;
  businessDomains: any;
  @ViewChild("singleSelect", { static: false }) singleSelect: MatSelect;
  public timezoneFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredTimezones: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _onDestroy = new Subject<void>();
  businessDomainData: any;
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "domain_name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
  };
  selected_business_domain = [];
  user: any;
  dsAvailableSenderNumbers: any;
  restrictEmail = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "icloud.com",
    "aol.com",
    "protonmail.com",
    "mail.com",
    "yandex.com",
    "zoho.com",
    "msn.com",
    "proton.com",
  ];
  margoDomain: any[] = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private timeZoneService: TimeZoneService,
    private master: MasterService,
    private authService: AuthserviceService,
    public adminone: AdminoneService,
    private reskinningService: ReskinningService
  ) {
    super();
    //this.baseService.changeValue(false);
    this.createForm();
    var name =
      "MyProfile Update Time Zone Page View by " +
      this.loginUserData.user_fname +
      " " +
      this.loginUserData.user_lname;
    this.addLogData("MyProfile Update Time Zone Page View", name);
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("UserData") || "{}");
    this.timezoneFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTimeZones();
      });
    this.userProfileDetails = this.loginUserData;
    this.getBussinessDomain();
    this.getDsSenderNumbers();
    this.fetctRestrictEmail();
    this.fetchMargoDomain();
  }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  removeSpaces(input: string): string {
    return input.replace(/\s+/g, "").toLowerCase();
  }

  private filterTimeZones() {
    if (!this.timezones) {
      return;
    }
    // get the search keyword
    let search = this.timezoneFilterCtrl.value;
    if (!search) {
      this.filteredTimezones.next(this.timezones.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the timezone
    this.filteredTimezones.next(
      this.timezones.filter(
        (timezone) => timezone.text.toLowerCase().indexOf(search) > -1
      )
    );
    /* this.filteredTimezones.next(
      this.timezones.filter(name => name.toLowerCase().indexOf(search) > -1)
    ); */
  }
  get f() {
    return this.profileForm.controls;
  }

  /* Create profile form */

  createForm() {
    this.profileForm = this.formBuilder.group({
      user_time_zone: ["", Validators.required],
      // business_domain_arr:[[], Validators.required],
      sender_name: ["", Validators.pattern(AppConstants.regex.name)],
      sender_email_domain_name: [""],
      sender_email_address: [
        "",
        [
          Validators.pattern(AppConstants.regex.email),
          this.checkEmail.bind(this),
        ],
      ],
      ds_sender_number: [""],
      sender_whatsapp_number: [""],
      fb_auth_connection_process: [""],
      insta_auth_connection_process: [""],
      is_margo_domain: [""],
    });
    this.ngxSpinnerService.show();
    this.timeZoneService.getTimeZones().subscribe(
      (data) => {
        if (data.status) {
          this.filteredTimezones.next(data.data.slice());
          this.timezones = data.data;
        }
        this.ngxSpinnerService.hide();
      },
      (error) => {
        this.ngxSpinnerService.hide();
      }
    );
  }

  OnsenderInputBlur(event: any) {
    if (event.target.value) {
      this.f.sender_name.setValidators([
        Validators.pattern(AppConstants.regex.name),
        Validators.required,
      ]);
      this.f.sender_name.updateValueAndValidity();
      // this.f.sender_email_domain_name.setValidators(Validators.required);
      // this.f.sender_email_domain_name.updateValueAndValidity();
      if (this.f.is_margo_domain.value == "true") {
        this.f.sender_email_address.setValidators([
          Validators.required,
          this.checkEmail.bind(this),
        ]);
      } else {
        this.f.sender_email_address.setValidators([
          Validators.pattern(AppConstants.regex.email),
          Validators.required,
          this.checkEmail.bind(this),
        ]);
      }
      this.f.sender_email_address.updateValueAndValidity();
    } else {
      // if(!this.profileForm.value.sender_email_domain_name && !this.profileForm.value.sender_email_address && !this.profileForm.value.sender_name ){
      if (
        !this.profileForm.value.sender_email_address &&
        !this.profileForm.value.sender_name
      ) {
        this.f.sender_name.setErrors(null);
        // this.f.sender_email_domain_name.setErrors(null);
        this.f.sender_email_address.setErrors(null);
      }
    }
  }
  async updateProfile() {
    let self = this;
    this.submitted = true;
    if (this.profileForm.invalid) {
      return;
    }
    if (
      this.selected_business_domain.length < 1 &&
      this.userProfileDetails?.role_id !== 77
    ) {
      return;
    }
    this.ngxSpinnerService.show();
    this.submitButton = true;
    this.userProfileDetails.user_time_zone =
      this.profileForm.value.user_time_zone;
    // this.userProfileDetails.business_domain_arr = this.profileForm.value.business_domain_arr;
    this.userProfileDetails.business_domain_arr = this.selected_business_domain;
    (this.userProfileDetails.sender_name = this.profileForm.value.sender_name),
      // this.userProfileDetails.sender_email_domain_name=this.profileForm.value.sender_email_domain_name,
      (this.userProfileDetails.sender_email_domain_name =
        this.profileForm.value.sender_email_address.split("@")[1]),
      (this.userProfileDetails.sender_email_address =
        this.profileForm.value.sender_email_address),
      (this.userProfileDetails.ds_sender_number =
        this.profileForm.value.ds_sender_number),
      (this.userProfileDetails.sender_whatsapp_number =
        this.profileForm.value.sender_whatsapp_number),
      (this.userProfileDetails.fb_auth_connection_process =
        this.profileForm.value.fb_auth_connection_process),
      (this.userProfileDetails.insta_auth_connection_process =
        this.profileForm.value.insta_auth_connection_process),
      (this.userProfileDetails.action = "timezoneAdd");
    this.userProfileDetails.is_margo_domain =
      this.profileForm.value.is_margo_domain == "true" &&
      this.profileForm.value.sender_name
        ? true
        : false;
    this.userProfileDetails.update_timezone = true;
    this.authserviceService
      .updateDetails(this.userProfileDetails)
      .subscribe((result) => {
        this.submitButton = false;
        this.ngxSpinnerService.hide();
        if (result.status) {
          localStorage.setItem(
            "UserData",
            JSON.stringify(this.userProfileDetails)
          );
          this.toastrService.success(
            "Time zone and business domain updated successfully"
          );
          this.authserviceService.setUserdata(this.userProfileDetails);
          setTimeout(() => {
            // this.router.navigateByUrl(this.basedsurl + "home");
            this.router.navigateByUrl(this.basedsurl + "setup");
          }, 1000);
        }
      });
  }

  getBussinessDomain() {
    this.authService
      .getBusinessDomain(this.user?.user_customer_id, this.user?.user_ds_id)
      .subscribe(
        (data) => {
          this.businessDomainData = data.data.rows.filter((domain: any) => {
            return domain.is_active == true;
          });
          let firstele = this.businessDomainData[0];
          if (firstele && firstele?.is_company) {
            this.selected_business_domain.push(firstele.id);
          } else {
            this.businessDomainData.forEach((e: any) => {
              if (e.is_selected == true) {
                this.selected_business_domain.push(e.id);
              }
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  getDsSenderNumbers() {
    this.reskinningService.getdsSenderNumberList().subscribe(
      (res: any) => {
        if (res.status) {
          this.dsAvailableSenderNumbers = res.data;
        } else {
          this.dsAvailableSenderNumbers = [];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  onDomainchange(id, event) {
    if (event.target.checked) {
      this.selected_business_domain.push(id);
    } else {
      let index = this.selected_business_domain.indexOf(id);
      if (index != -1) {
        this.selected_business_domain.splice(index, 1);
      }
    }
  }
  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length);
      if (!this.isUrlValid(url)) {
        let awsUrl = self.adminone.getUrlfile(url);
        event.target.src = awsUrl;
        console.log(awsUrl);
      } else {
        event.target.src = "assets/custom/images/profile.png";
      }
    } else {
      event.target.src = "assets/custom/images/profile.png";
    }
    // event.target.src = 'assets/custom/images/profile.png';
    event.target.onerror = null;
  }

  isUrlValid(userInput) {
    return /^(https?):\/\/+(www\.)?[a-z0-9\-\.]{3,}\.[a-z]{3}$/.test(userInput);
  }
  insertDateAt(date: string): string {
    if (date) {
      return date.replace("AT", "at");
    } else {
      return "";
    }
  }
  checkselection(event: any) {
    if (this.selected_business_domain.includes(event)) {
      return true;
    } else {
      return false;
    }
  }
  checkEmail(control: AbstractControl): { [key: string]: boolean } | null {
    if (
      control.value &&
      /^[a-zA-Z0-9._.+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(control.value)
    ) {
      let emailData = control.value.split("@")[1];
      if (this.restrictEmail.includes(emailData)) {
        return { restrictEmail: true };
      }
      return null;
    } else {
      return null;
    }
  }
  fetctRestrictEmail() {
    this.authService.fetchRestrictEmail(this.user?.user_customer_id).subscribe({
      next: (res: any) => {
        if (res.status) {
          if (res.data.rows && res.data.rows?.length > 0) {
            this.restrictEmail = res.data.rows.map((email: any) => email.domin);
          }
        }
      },
    });
  }
  fetchMargoDomain() {
    this.authService.fetchMargoDomain().subscribe({
      next: (res: any) => {
        if (res.status) {
          if (res.data.rows && res.data.rows?.length > 0) {
            this.margoDomain = res.data.rows;
          }
        }
      },
    });
  }
  resetDomainValue(value: any) {
    this.f.sender_email_domain_name.setValue("");
    this.f.sender_email_address.setValue("");
    this.f.sender_name.setValue("");
  }
  changeEmailDomain() {
    if (this.f.sender_name.value) {
      let data = this.f.sender_name.value.replace(/ +/g, "").toLowerCase();
      this.f.sender_email_address.setValue(
        data + "@" + this.f.sender_email_domain_name.value
      );
      return;
    }
    this.f.sender_email_address.setValue("");
  }
  updateSenderEmail(event: any) {
    if (this.f.is_margo_domain.value == "true") {
      let data = event.target.value;
      if (event.target.value) {
        data = data.replace(/ +/g, "").toLowerCase();
        this.f.sender_email_address.setValue(
          data + "@" + this.f.sender_email_domain_name.value
        );
      } else {
        this.f.sender_email_address.setValue("");
      }
    }
  }
}
