import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ReskinningService } from "src/app/_services/reskinning.service";

@Component({
  selector: "app-dialog",
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="scroller">
      <div class="wholePop filtMain">
        <div class="modal-header dialHead w-100">
          <img *ngIf="rskmodellogoFlag" src="{{ rskmodellogo }}" />
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss(0)"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          <ng-container>
            <div>
              <h2>{{ title }}</h2>
              <p>{{ message }}</p>
            </div>
          </ng-container>
        </div>
        <div class="modal-footer text-center d-block border-0 pt-0">
          <button
            *ngIf="status == 'expired'"
            type="button"
            class="btn btn-margo-action btn-round text-uppercase"
            (click)="navigateToPay()"
          >
            Renew
          </button>
          <button
            *ngIf="status == 'newPlan'"
            type="button"
            class="btn btn-margo-action btn-round text-uppercase"
            (click)="navigateToPay()"
          >
            Continue
          </button>
          <button
            type="button"
            (click)="modal.dismiss(0)"
            class="btn btn-margo-action btn-round text-uppercase"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  `,
})
export class DialogComponent {
  rskmodellogo = this.ReskinningService.rskModelLogo;
  rskmodellogoFlag = this.ReskinningService.rskModelLogoFlag;
  @Input() title: string;
  @Input() message: string;
  @Input() status: string;

  constructor(
    public modal: NgbActiveModal,
    private ReskinningService: ReskinningService,
    private router: Router
  ) {}
  navigateToPay() {
    this.router.navigate(["/directseller/sub-checkout"]);
    this.modal.dismiss(0);
  }
}
