import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/core/services/alert.service';
import { ToastrService } from 'ngx-toastr';
import { RegisterService } from 'src/app/services/users/register.service';
import { AuthserviceService } from 'src/app/core/services/authservice.service';

@Component({
  selector: 'app-setusername',
  templateUrl: './setusername.component.html',
  styleUrls: ['./setusername.component.css']
})
export class SetusernameComponent implements OnInit {
  forgotForm: UntypedFormGroup;
  submitted = false;
  status: boolean = false;
  message;
  username_verify;
  constructor(private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private registerService: RegisterService,
    private authserviceService: AuthserviceService,
    private alertService: AlertService,
    public toastrService: ToastrService) { }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      username: ['', Validators.required]
    });
  }
  get f() { return this.forgotForm.controls; }
  setUserName() {

  }
  submitUserName() {
    let userName = {
      "userName": this.f.username.value
    }
    this.route.params.subscribe(url => {
      let token = url.token;
      this.authserviceService.setUserNameService(userName, token).subscribe(data => {
        if (data.status) {
          this.status = data.status;
          // this.toastrService.success("Changed Successfully. Please login with new username")
          this.router.navigateByUrl(`/resetpwd/${data.token}`);
        } else {
          this.toastrService.error(data.message);
        }
      }, err => {
        //console.log(err);
      });
    })


  };
  verifyUserName() {
    let self = this;
    let userName = {
      "userName": self.f.username.value
    }
    this.submitted = true;

    this.route.params.subscribe(url => {
      let token = url.token;
      self.authserviceService.verifyUserName(userName, token).subscribe(data => {
        if (data.status) {
          self.status = data.status;
          self.message = data.message;
          self.username_verify = self.f.username.value
        } else {
          self.submitted = !data.status;
          self.message = data.message;
        }
      }, err => {
        //console.log(err);
      });
    });
  }
  oncancel(){
    this.router.navigateByUrl('/login')
  }
}
