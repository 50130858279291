import { Component, Inject, OnInit, Renderer2, NgZone, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavbarService } from '../../../core/services/navbar.service';
import { ThemeSettingsService } from '../../settings/theme-settings.service';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent,
  PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';
import { MenuSettingsService } from '../../settings/menu-settings.service';
import { isArray } from 'util';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { AdminoneService } from 'src/app/services/adminone/adminone.service';
import { AuthserviceService } from 'src/app/services/auth/authservice.service';
import { HelpandtipsService } from '../../../services/helpandtips/helpandtips.service'
import { ChatService } from "../../../_services/chat.service";
import { SocketioService } from 'src/app/_services/socketio.service';

@Component({

  selector: 'app-verticalnav',
  templateUrl: './verticalnav.component.html',
  styleUrls: ['./verticalnav.component.css'],
  animations: [
    trigger('popOverState', [
      state('show', style({
        opacity: '1',
      })),
      state('hide', style({
        opacity: '0',
        height: '*',
      })),
      transition('show => hide', animate('200ms ease-in-out')),
      transition('hide => show', animate('200ms ease-in-out'))
    ])
  ]
})
export class VerticalnavComponent implements OnInit {
  child: any;
  insideTm: any;
  outsideTm: any;
  requestList: any = [];
  support_user;
  updateNotification$;
  disconnect$;
  private _themeSettingsConfig: any;
  public _menuSettingsConfig: any;
  private _unsubscribeAll: Subject<any>;
  private _unsubscribeAllMenu: Subject<any>;
  public customercompanylogo;


  public tempSessionCount = 0;
  // public instantMessageCount:number = 0;
  public textMessageCount:number = 0;
  // public totalMessageCount:number = 0;
  public config: PerfectScrollbarConfigInterface = { wheelPropagation: true };
  newMessage: any;
  public insMessageRoomClick: any;
  textcontactsList:any;
  textMessageClick:Subscription
  @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: true }) directiveRef?: PerfectScrollbarDirective;
  isBu:boolean=false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private navbarService: NavbarService,
    private _themeSettingsService: ThemeSettingsService,
    private _menuSettingsService: MenuSettingsService,
    private _renderer: Renderer2,
    private router: Router,
    private adminone: AdminoneService,
    public AuthserviceService: AuthserviceService,
    private ngZone: NgZone,
    private helpService: HelpandtipsService,
    public chatService: ChatService,
    public textMessageService:SocketioService
  ) {
    this._unsubscribeAll = new Subject();
    this._unsubscribeAllMenu = new Subject();

  }

  ngOnInit() {
    if(this.router.url!='/pagenotfound'){
        this.chatService.getNotification();
    }


    this.updateNotification$ = this.chatService.notification.subscribe((value) => {

      if (value.type === 1) {
        this.tempSessionCount = value.notification > 0 ? value.notification : 0;
      }
      else if (value.type === 3 && this.tempSessionCount > 0) {
        this.tempSessionCount -= 1;
      }
      else if (value.type === 2) {
        this.tempSessionCount += 1;
      }

    })

    this.newMessage = this.chatService.newInstantMessage.subscribe((data: any) => {
      // this.instantMessageCount++;
      // this.totalMessageCount = this.instantMessageCount + this.textMessageCount;
    });
    this.insMessageRoomClick = this.chatService.newInstantMessageClicked.subscribe((data: any) => {
      // this.instantMessageCount = 0;
      // this.totalMessageCount = this.instantMessageCount + this.textMessageCount;

      // if (data > this.instantMessageCount) {
      //   this.instantMessageCount = 0;
      // } else {
      //   this.instantMessageCount = this.instantMessageCount - data;
      // }
    });
    this.textMessageClick = this.textMessageService.textmsgobserver().subscribe((count:any) => {
          this.textMessageCount = count;
          this.textMessageService.globalMessageCount=count;
          // this.totalMessageCount = this.instantMessageCount + this.textMessageCount;
    });
    this.disconnect$ = this.chatService.disconnect.subscribe(() => {
      this.unsubscribe();
    })

    if (this.router.url.indexOf('/guest/') < 0 && this.router.url.indexOf('/notauthorised') < 0) {
      this.helpService.getSupportRequest().subscribe((data) => {
        if (data.status == true) {
          this.requestList = data.data;
        }
      });
    }
    // Subscribe to config changes
    this._themeSettingsService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this._themeSettingsConfig = config;
        this.refreshView();
      });
    var userdata = JSON.parse(localStorage.getItem("UserData"));
    this._menuSettingsService.config
      .pipe(takeUntil(this._unsubscribeAllMenu))
      .subscribe((config) => {
        this._menuSettingsConfig = {
          horizontal_menu: {
          },
          vertical_menu: {
            items: []
          }
        };
      });

    if (userdata) {
      this.support_user = userdata.support_user;
      this.customercompanylogo = userdata.customer_company_logo;
      this.isBu = userdata?.is_bu;
      if (userdata.role_id !== 77) {
        this._menuSettingsService.config
          .pipe(takeUntil(this._unsubscribeAllMenu))
          .subscribe((config) => {
            this._menuSettingsConfig = {
              horizontal_menu: {

              },
              vertical_menu: {
                items: [
                  {
                    title: 'Home',
                    icon: 'assets/images/svg/home.svg',
                    page: '/directseller/home',
                    code: 'MRL',
                  },
                  {
                    title: 'Market',
                    icon: 'assets/custom/images/marketIcon.svg',
                    page: '/directseller/market',
                    code: 'MRL',
                  },
                  {
                    title: 'Get Social',
                    icon: 'assets/custom/images/getSocialIcon.svg',
                    page: '/directseller/social',
                    code: 'SOL',
                  },
                  {
                    title: 'Quick Message',
                    icon: 'assets/custom/images/mb.png',
                    page: '/directseller/sms/dashboard',
                    badge2: {
                      type: 'badge-danger',
                      value: this.textMessageCount
                    },
                    code: 'SMB',
                  },
                 /* {
                    title: 'Quick text',
                    icon: 'assets/custom/images/quickTextIcon.svg',
                    page: '/directseller/quicktext',
                    code: 'QTL',

                  },

                  */
                  {
                    title: 'Replenishment',
                    icon: 'assets/custom/images/replenishmentIcon.svg',
                    page: '/directseller/replenishment',
                    code: 'PRL',

                  },
                  {
                    title: 'Cross-sell',
                    icon: 'assets/custom/images/corssSellIcon.svg',
                    page: '/directseller/crosssell',
                    code: 'PRL',

                  },
                  {
                    title: 'My Dashboard',
                    icon: 'assets/custom/images/myDashIcon.svg',
                    page: '/directseller/mydashboard',
                    code: 'DSL',


                  },
                  {

                    title: 'Messenger',
                    icon: 'assets/custom/images/communityIcon.svg',
                    page: '/directseller/messenger/ds-messenger',
                    badge: {
                      type: 'badge-danger',
                      value: this.tempSessionCount
                    },
                    code: 'MGL',

                  },
                  {
                    title: 'Shop Live',
                    icon: 'assets/custom/images/shop_icon.svg',
                    page: '/directseller/shop-live',
                    code: 'SVL',
                  },
                  {
                    title: 'Webinar',
                    icon: 'assets/images/svg/home.svg',
                    page: '/directseller/webinar/dashboard',
                    code: 'WBR',
                  },

                ]
              }

            };
          });
      }
      else {
        this._menuSettingsService.config
          .pipe(takeUntil(this._unsubscribeAllMenu))
          .subscribe((config) => {
            this._menuSettingsConfig = {
              horizontal_menu: {
              },
              vertical_menu: {
                items: [
                  {
                    title: 'Hosted Event',
                    icon: 'assets/custom/images/marketIcon.svg',
                    page: '/host',
                    code: 'MEL',
                  }

                ]
              }

            };
          });
      }



      if (userdata.bundle_name) {
        var newArray = [];
        this._menuSettingsConfig.vertical_menu.items.forEach(element => {
          Object.keys(userdata.user_bundle.nav).forEach(function (key) {

            if (((key == 'MSL' || key == 'MEL') && userdata.user_bundle.nav[key] == 1) && element.code == 'MRL') {
              const matches = newArray.filter(obj => obj.title === element.title);
              if (!matches.length) newArray.push(element);
            }
            else if ((key == 'SID' && userdata.user_bundle.nav[key]) && element.code == 'SOL') {
              newArray.push(element);
            }
            else if (element.code == key && userdata.user_bundle.nav[key] == 1) {
              newArray.push(element);
            }

          });

          ///newArray.filter((a, b) => newArray.indexOf(a.title) === b)
        });
        this._menuSettingsConfig.vertical_menu.items = newArray;
    
        



      }

      if(!this.isBu){
        this._menuSettingsConfig.vertical_menu.items = this._menuSettingsConfig.vertical_menu.items.filter((ele:any)=>{
        return  ele.code != 'PRL';
        })
      }

    }

    // TODO Patch to reset menu after login
    this.resetMainMenu();
    this.setActiveRouteInNavbar();
    this.startTextMessageCountCapture();
  }

  startTextMessageCountCapture() {
    this.textMessageService.getOnlineUserFavouriteList().subscribe((data: any) => {
      this.textcontactsList = data;
      // this.totalMessageCount=0;
      let count:number = 0;
      this.textcontactsList.map((e)=>{
        count += parseInt(e.unread)

      })
      this.textMessageCount = count;
      // this.totalMessageCount = this.instantMessageCount + this.textMessageCount;
      this.textMessageService.notifyOnchatclick(this.textMessageCount)
    });
  }


  resetMainMenu() {
    const nodes = this.document.getElementById('main-menu-navigation').childNodes;
    for (let i = 0; i < nodes.length; i++) {
      this.resetCollapseMenu(nodes[i]);
    }
    for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
      this._menuSettingsConfig.vertical_menu.items[i]['isSelected'] = false;
      this._menuSettingsConfig.vertical_menu.items[i]['hover'] = false;
      this._menuSettingsConfig.vertical_menu.items[i]['isOpen'] = false;
      this.resetSubmenuItems(this._menuSettingsConfig.vertical_menu.items[i]);
    }
  }

  resetCollapseMenu(element) {
    if (element.classList && element.classList.contains('has-sub') && element.classList.contains('open')) {
      element.classList.remove('hover');
      element.classList.remove('menu-collapsed-open');
    }
  }

  resetSubmenuItems(parentItem) {
    if (parentItem['submenu'] &&
      parentItem['submenu']['items'] &&
      parentItem['submenu']['items'].length > 0) {
      parentItem['isOpen'] = false;
      for (let j = 0; j < parentItem['submenu']['items'].length; j++) {
        parentItem['submenu']['items'][j]['isSelected'] = false;
        this.resetSubmenuItems(parentItem['submenu']['items'][j]);
      }
    }
  }

  refreshView() {
    const mainMenuElement = document.getElementsByClassName('main-menu');
    if (mainMenuElement && mainMenuElement.length > 0) {
      if (this._themeSettingsConfig.colorTheme === 'semi-light' || this._themeSettingsConfig.colorTheme === 'light') {
        this._renderer.removeClass(mainMenuElement.item(0), 'menu-dark');
        this._renderer.addClass(mainMenuElement.item(0), 'menu-light');
      } else if (this._themeSettingsConfig.colorTheme === 'semi-dark' || this._themeSettingsConfig.colorTheme === 'dark') {
        this._renderer.addClass(mainMenuElement.item(0), 'menu-dark');
        this._renderer.removeClass(mainMenuElement.item(0), 'menu-light');
      }
      if (this._themeSettingsConfig.layout.pattern === 'static') {
        this._renderer.removeClass(mainMenuElement.item(0), 'menu-fixed');
        this._renderer.addClass(mainMenuElement.item(0), 'menu-static');
      } else if (this._themeSettingsConfig.layout.pattern === 'fixed') {
        this._renderer.removeClass(mainMenuElement.item(0), 'menu-static');
        this._renderer.addClass(mainMenuElement.item(0), 'menu-fixed');
      }
    }
  }

  setActiveRouteInNavbar() {
    for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
      if (!this._menuSettingsConfig.vertical_menu.items[i].submenu &&
        this._menuSettingsConfig.vertical_menu.items[i].page === this.router.url) {
        this._menuSettingsConfig.vertical_menu.items[i]['isSelected'] = true;
        break;
      } else if (this._menuSettingsConfig.vertical_menu.items[i].submenu) {
        // Level 1 menu
        for (let j = 0; j < this._menuSettingsConfig.vertical_menu.items[i].submenu.items.length; j++) {
          if (!this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].submenu &&
            this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].page === this.router.url) {
            this._menuSettingsConfig.vertical_menu.items[i]['isSelected'] = true;
            this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j]['isSelected'] = true;
            this._menuSettingsConfig.vertical_menu.items[i].isOpen = true;
            break;
          } else if (this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].submenu) {
            // Level 2 menu
            for (let k = 0; k < this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].submenu.items.length; k++) {
              if (this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].submenu.items[k].page === this.router.url) {
                this._menuSettingsConfig.vertical_menu.items[i]['isSelected'] = true;
                this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j]['isSelected'] = true;
                this._menuSettingsConfig.vertical_menu.items[i].isOpen = true;

                this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j]['isSelected'] = true;
                this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].submenu.items[k]['isSelected'] = true;
                this._menuSettingsConfig.vertical_menu.items[i].submenu.items[j].isOpen = true;
              }
            }
          }
        }
      }
    }
  }

  resetOpenMenu() {
    for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
      const menu = this._menuSettingsConfig.vertical_menu.items[i];
      if (!menu.submenu) {
        menu['isOpen'] = false;
        menu['isActive'] = false;
        menu['hover'] = false;
      } else if (menu.submenu) {
        for (let j = 0; j < menu.submenu.items.length; j++) {
          menu['isOpen'] = false;
          menu['isActive'] = false;
          menu['hover'] = false;
          menu.submenu.items[j]['isOpen'] = false;
        }
      }
    }
  }

  setOpenInNavbar(value) {
    for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
      const menu = this._menuSettingsConfig.vertical_menu.items[i];
      if (!menu.submenu &&
        menu.page === this.router.url) {
        menu['isOpen'] = value;
        menu['isActive'] = value;
      } else if (menu.submenu) {
        for (let j = 0; j < menu.submenu.items.length; j++) {
          if (menu.submenu.items[j].page === this.router.url) {
            menu['isOpen'] = value;
            menu['isActive'] = value;
            menu.submenu.items[j]['isOpen'] = value;
            menu.submenu.items[j]['isActive'] = value;
            break;
          }
        }
      }
    }
  }

  callFunction(event, child, isSubmenuOfSubmenu) {
    const methodName = event.methodName;
    if (this[methodName]) {
      // method exists on the component
      const param = event.methodParam;
      if (!isArray(param)) {
        this[methodName](param); // call it
      } else {
        this[methodName](param[0], param[1]); // call it
      }
    }
    this.resetOtherActiveMenu(child, isSubmenuOfSubmenu);
    child['isSelected'] = true;
  }

  setTheme(theme) {
    this._themeSettingsService.config = {
      colorTheme: theme, // semi-light, semi-dark
    };
  }

  setLayout(layout) {
    this._themeSettingsService.config = {
      layout: {
        pattern: layout
      }
    };
  }

  fixComponent(component, value) {
    if (component === 'header') {
      this._themeSettingsService.config = {
        header: value
      };
    } else if (component === 'footer') {
      this._themeSettingsService.config = {
        footer: value
      };
    } else {
      this._themeSettingsService.config = {
        header: value,
        footer: value
      };
    }
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseEnter(e) {
    if (this.navbarService.isFixedMenu()) {
      return;
    }
    this.navbarService.setMouseInRegion(true);
    const navBar = this.document.getElementById('navbar-header');
    const mainMenu = this.document.getElementById('main-menu');

    // check if the left aside menu is fixed
    if (!navBar.classList.contains('expanded')) {
      this._renderer.addClass(navBar, 'expanded');
      this._renderer.addClass(mainMenu, 'expanded');
      this.resetOpenMenu();
      this.setOpenInNavbar(true);
    }
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseLeave(event) {
    if (this.navbarService.isFixedMenu()) {
      return;
    }
    const _self = this;
    const navBar = this.document.getElementById('navbar-header');
    const mainMenu = this.document.getElementById('main-menu');
    if (navBar && navBar.classList.contains('expanded')) {
      this.insideTm = setTimeout(() => {
        if (!_self.navbarService.isMouseInRegion()) {
          this._renderer.removeClass(navBar, 'expanded');
          this._renderer.removeClass(mainMenu, 'expanded');
          this.resetOpenMenu();
          this.setOpenInNavbar(false);
        }
      }, 100);
    }
    this.navbarService.setMouseInRegion(false);
  }

  resetOtherActiveMenu(selectedChild, isSubmenuOfSubmenu) {
    for (let i = 0; i < this._menuSettingsConfig.vertical_menu.items.length; i++) {
      this._menuSettingsConfig.vertical_menu.items[i]['isSelected'] = false;
      this._menuSettingsConfig.vertical_menu.items[i]['hover'] = false;
      this.handleSubmenuItems(this._menuSettingsConfig.vertical_menu.items[i], selectedChild, isSubmenuOfSubmenu);
    }
  }

  handleSubmenuItems(parentItem, selectedChild, isSubmenuOfSubmenu) {
    if (selectedChild['title'] === 'Horizontal') {
      localStorage.setItem('currentLayoutStyle', AppConstants.LAYOUT_STYLE_HORIZONTAL);
      window.location.reload();
    } else if (selectedChild['title'] === 'Vertical') {
      localStorage.setItem('currentLayoutStyle', AppConstants.LAYOUT_STYLE_VERTICAL);
      window.location.reload();
    } else if (parentItem['submenu'] &&
      parentItem['submenu']['items'] &&
      parentItem['submenu']['items'].length > 0) {
      if (parentItem.title !== selectedChild.title && parentItem['isOpen'] === true && !isSubmenuOfSubmenu &&
        this._themeSettingsConfig.navigation === AppConstants.NAVIGATION_TYPE_COLLAPSIBLE) {
        parentItem['isOpen'] = false;
      }
      for (let j = 0; j < parentItem['submenu']['items'].length; j++) {
        if (selectedChild.page !== 'null') {
          parentItem['submenu']['items'][j]['isSelected'] = false;
        }
        this.handleSubmenuItems(parentItem['submenu']['items'][j], selectedChild, isSubmenuOfSubmenu);
      }
    } else if (parentItem.title !== selectedChild.title && !selectedChild.submenu
      && this._themeSettingsConfig.navigation === AppConstants.NAVIGATION_TYPE_COLLAPSIBLE
      && parentItem['isOpen'] === true) {
      parentItem['isOpen'] = false;
    }
  }

  toggleMenu(event, child, isSubmenuOfSubmenu) {

    const toggle = document.getElementById('sidenav-overlay');
    const target = event.target || event.srcElement || event.currentTarget;
    this.resetOtherActiveMenu(child, isSubmenuOfSubmenu);
    if (child['isSelected'] === true) {
      child['isSelected'] = false;
    } else {
      child['isSelected'] = true;
    }

    if (child['hover'] === true) {
      child['hover'] = false;
    } else {
      child['hover'] = true;
    }

    if (child['isOpen'] === true) {
      child['isOpen'] = false;
    } else {
      child['isOpen'] = true;
    }

    if (this.router.url !== '') {
      this._renderer.addClass(toggle, 'd-none');
      this._renderer.removeClass(toggle, 'd-block');
    }
    /**call help service for update if any new request */
    this.helpService.getSupportRequest().subscribe((data) => {
      if (data.status == true) {
        this.requestList = data.data;

      }

    });

  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
  unsubscribe() {
    this.updateNotification$.unsubscribe();
    this.disconnect$.unsubscribe();
    this.newMessage.unsubscribe();
    this.insMessageRoomClick.unsubscribe();
  }

}
