import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ImportICloudUpdateLogsService {

  private importLogsFlag = new Subject<any>();
  unsubscribe: any;

  constructor() { }

  public updateImportedLogs(){
    return this.importLogsFlag.asObservable();
  }

  sendImportedLogFlag(val: any) {
    this.importLogsFlag.next({ showUpdatedImportLogs: val });
  }

}
