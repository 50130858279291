<div class="container h-100 app-content">
    <div class="row h-100 justify-content-center align-items-center mt--4">
        <div class="col-md-4 col-sm-8 col-11">
            <div class="text-center mainLogLogo">
                <img src="assets/custom/images/margologo250.png" alt="branding logo" width="180" height="180">
            </div>
            <div style="font-size:15px;color:red;text-align:center;font-weight: bold;"
                [ngClass]="status ? 'displayblock' : 'displaynone'">
                {{emsg}}
            </div>
            <form class="form-horizontal text-center mt-2" [formGroup]="loginForm" (ngSubmit)="checkUserVerifyData()">
                <!-- <fieldset class="form-group position-relative has-icon-left"> -->
                <input type="text" formControlName="email" class="form-control formstyle mt2"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="user-name"
                    placeholder="Username" required>

                <div style="text-align:left" *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Username is required</div>
                </div>
                <input type="password" placeholder="Password" formControlName="password"
                    class="form-control formstyle mt-2"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div style="text-align:left" *ngIf="submitted && f.password.errors"
                    class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
                <div class="text-left mt-1">
                    <label >
                    <input type="checkbox" [checked]="isRemember" (change)="rememberUsername($event)"> Remember me?
                    </label>
                </div>
                <div class="forgt mt-1">
                    <a [routerLink]="['/forgot-user']" class="card-link">Forgot Username?</a>
                </div>
                <div class="forgt mt-2">
                    <a [routerLink]="['/forgot-pwd']" class="card-link">Forgot Password?</a>
                </div>
                <div>
                  <button type="submit" class="custbtn mt-2 mb-2">
                    Login
                </button>
                </div>
                  <!-- <button type="button" class="custbtn mb-2" routerLink="/registration">
                    Registration
                  </button> -->
            </form>
            <div class="text-center">
              Not a Member? <a [routerLink]="['/pricing']" class="card-link">Sign Up</a>
            </div>
        </div>
    </div>
</div>


<ng-template #resendEmail let-modal>
  <div class="scroller">
    <div class="wholePop filtMain">
      <div class="modal-header dialHead w-100">
        <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}" />
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss(0)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body text-center">
        <ng-container>
          <div *ngIf="!isEmailResend">
            <h2>EMAIL NOT VERIFIED</h2>
            <p>Your email address is not verified. Please resend the verification email.</p>
          </div>
          <div *ngIf="isEmailResend">
            <h2>EMAIL SENT SUCCESSFULLY.</h2>
            <p>Email sent successfully, please check your email for the verification.</p>
          </div>
        </ng-container>
      </div>
      <div class="modal-footer text-center d-block border-0 pt-0">
        <button type="button" class="btn btn-margo-cancel btn-round text-uppercase"
        (click)="modal.dismiss(0)" *ngIf="!isEmailResend">Cancel</button>
        <button type="button" class="btn btn-margo-action btn-round text-uppercase"
          (click)="resendVerifyEmail()" *ngIf="!isEmailResend">Resend Email</button>
      </div>
    </div>
  </div>
</ng-template>
