<style>
  .my-pagination /deep/ .ngx-pagination {
    text-align: center;
  }
</style>

<div class="container">
  <nav aria-label="breadcrumb" class="breadCust">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/directseller/home">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">Reskinning</li>
    </ol>
  </nav>
</div>

<div
  class="tblPrt"
  class="col-md-12"
>
  <table
  *ngIf="themeObj != undefined && themeObj != ''"
    class="mt-1 bdrStCustom"
    width="100%"
    cellpadding="0"
    cellspacing="0"
    style="background-color: #fff"
  >
    <tr>
      <td colspan="3" style="text-align: right" *ngIf="themeObj != undefined && themeObj != ''">
        <input
          type="button"
          value="Edit"
          class="btn btn-round btn-margo-action"
          (click)="edit(themeObj.reskininig_id)"
        />
      </td>
    </tr>
      <tr>
        <td colspan="3" class="main_heading">Common</td>
      </tr>

      <tr>
        <td class="srno">1.</td>
        <td class="heading">Header Background Color</td>
        <td>
          <span
            [ngStyle]="{ 'background-color': themeObj.header_background_color }"
            class="colorbox"
          >
            {{ themeObj.header_background_color }}
          </span>
        </td>
      </tr>

      <tr>
        <td class="srno">2.</td>
        <td class="heading">Header Right Side Icon Color</td>
        <td>
          <span
            [ngStyle]="{ 'background-color': themeObj.icon_color }"
            class="colorbox"
          >
            {{ themeObj.icon_color }}
          </span>
        </td>
      </tr>

      <tr>
        <td class="srno">3.</td>
        <td class="heading">Logo</td>
        <td><img src="{{ themeObj.logo }}" style="width: 100px" /></td>
      </tr>

      <tr>
        <td class="srno">4.</td>
        <td class="heading">Background Image</td>
        <td>
          <img src="{{ themeObj.background_image }}" style="width: 100px" />
        </td>
      </tr>
      <tr>
        <td class="srno">5.</td>
        <td class="heading">Logo Max Height</td>
        <td>
          <ng-container *ngIf="themeObj.logo_height">{{themeObj?.logo_height}} px</ng-container>
        </td>
      </tr>

      <tr>
        <td class="srno">6.</td>
        <td class="heading">marGo Logo Status</td>
        <td *ngIf="themeObj.logo_status == true">Active</td>
        <td *ngIf="themeObj.logo_status != true">In-Active</td>
      </tr>

      <tr>
        <td class="srno">7.</td>
        <td class="heading">OutLine Color</td>
        <td>
          <span
            [ngStyle]="{ 'background-color': themeObj.picture_outline_color }"
            class="colorbox"
          >
            {{ themeObj.picture_outline_color }}
          </span>
        </td>
      </tr>

      <tr>
        <td class="srno">8.</td>
        <td class="heading">Scroll bar Color</td>
        <td>
          <span
            [ngStyle]="{ 'background-color': themeObj.scroll_color }"
            class="colorbox"
          >
            {{ themeObj.scroll_color }}
          </span>
        </td>
      </tr>

      <tr>
        <td class="srno">9.</td>
        <td class="heading">Model Logo Status</td>
        <td *ngIf="themeObj.model_logo_flag == true">Active</td>
        <td *ngIf="themeObj.model_logo_flag != true">In-Active</td>
      </tr>

      <tr>
        <td class="srno">10.</td>
        <td class="heading">Model Icon</td>
        <td><img src="{{ themeObj.model_logo }}" style="width: 100px" /></td>
      </tr>

      <tr>
        <td class="srno">11.</td>
        <td class="heading">Email Template Logo</td>
        <td><img src="{{ themeObj.email_template_logo }}" style="width: 100px" /></td>
      </tr>

      <!-- <tr>
    <td class="srno"> 7. </td>
    <td class="heading"> Body text Color </td>
    <td>
      <span [ngStyle]="{'background-color': themeObj.body_background_color}" class="colorbox">
        {{themeObj.body_background_color}}
      </span>
    </td>
  </tr>  -->

      <tr>
        <td colspan="3" class="main_heading">Landing Page</td>
      </tr>

      <tr>
        <td class="srno">1.</td>
        <td class="heading">Landing Page Text</td>
        <td>{{ themeObj.ds_alias_name }}</td>
      </tr>

      <tr>
        <td class="srno">2.</td>
        <td class="heading">Landing Page Text Color</td>
        <td>
          <span
            [ngStyle]="{ 'background-color': themeObj.dashboard_text }"
            class="colorbox"
          >
            {{ themeObj.dashboard_text }}
          </span>
        </td>
      </tr>

      <tr>
        <td class="srno">3.</td>
        <td class="heading">
          Hide/Show option for Password and change profile picture (Profile
          Page)
        </td>
        <td *ngIf="themeObj.profile_obj == true">Active</td>
        <td *ngIf="themeObj.profile_obj != true">In-Active</td>
      </tr>

      <tr>
        <td colspan="3" class="main_heading">Market</td>
      </tr>

      <tr>
        <td class="srno">1.</td>
        <td class="heading">Contact heading text</td>
        <td>{{ themeObj.contact_heading_text }}</td>
      </tr>

      <tr>
        <td class="srno">2.</td>
        <td class="heading">Contact heading text color</td>
        <td>
          <span
            [ngStyle]="{
              'background-color': themeObj.contact_heading_text_color
            }"
            class="colorbox"
          >
            {{ themeObj.contact_heading_text_color }}
          </span>
        </td>
      </tr>

      <tr>
        <td class="srno">3.</td>
        <td class="heading">Team member heading text</td>
        <td>{{ themeObj.team_heading_text }}</td>
      </tr>

      <tr>
        <td class="srno">4.</td>
        <td class="heading">Team member heading text color</td>
        <td>
          <span
            [ngStyle]="{ 'background-color': themeObj.team_heading_text_color }"
            class="colorbox"
          >
            {{ themeObj.team_heading_text_color }}
          </span>
        </td>
      </tr>

      <!-- <tr>
    <td class="srno"> 5. </td>
    <td class="heading"> Page content color </td>
    <td><span [ngStyle]="{'background-color': themeObj.page_content_font_color}" class="colorbox">
      {{themeObj.page_content_font_color}}
    </span>
  </td>
  </tr> -->

      <tr>
        <td class="srno">5.</td>
        <td class="heading">Tab color</td>
        <td>
          <span
            [ngStyle]="{ 'background-color': themeObj.tab_color }"
            class="colorbox"
          >
            {{ themeObj.tab_color }}
          </span>
        </td>
      </tr>

      <!-- <tr>
    <td class="srno"> 6. </td>
    <td class="heading"> Tab text color </td>
    <td><span [ngStyle]="{'background-color': themeObj.tab_text_color}" class="colorbox">
      {{themeObj.tab_text_color}}
    </span>
  </td>
  </tr> -->

      <tr>
        <td class="srno">6.</td>
        <td class="heading">Button color</td>
        <td>
          <span
            [ngStyle]="{ 'background-color': themeObj.button_color }"
            class="colorbox"
          >
            {{ themeObj.button_color }}
          </span>
        </td>
      </tr>

      <tr>
        <td class="srno">7.</td>
        <td class="heading">Button text color</td>
        <td>
          <span
            [ngStyle]="{ 'background-color': themeObj.button_text_color }"
            class="colorbox"
          >
            {{ themeObj.button_text_color }}
          </span>
        </td>
      </tr>
      <tr>
        <td class="srno">8.</td>
        <td class="heading">Currency</td>
        <td>
            {{ themeObj.currency }}
        </td>
      </tr>
      <tr>
        <td colspan="3" class="main_heading">Mini Site</td>
      </tr>
      <tr>
        <td class="srno">1.</td>
        <td class="heading">Product List</td>
        <td>
            {{ themeObj?.mini_site_product_list?'Enable':'Disable' }}
        </td>
      </tr>
      <tr>
        <td class="srno">2.</td>
        <td class="heading">Display Price</td>
        <td>
            {{ themeObj?.mini_site_display_price?'Enable':'Disable' }}
        </td>
      </tr>
      <tr>
        <td class="srno">3.</td>
        <td class="heading">Add To Cart</td>
        <td>
            {{ themeObj?.mini_site_add_to_cart?'Enable':'Disable' }}
        </td>
      </tr>

      <tr>
        <td class="srno">4.</td>
        <td class="heading">Header BG Color</td>
        <td *ngIf="themeObj?.minisite_header_bg_color">

          <span
          [ngStyle]="{ 'background-color': themeObj.minisite_header_bg_color }"
          class="colorbox"
        >
        {{ themeObj?.minisite_header_bg_color }}
        </span>

            
        </td>
      </tr>
      <tr>
        <td class="srno">5.</td>
        <td class="heading">Header Text Color</td>
        <td *ngIf="themeObj?.minisite_header_text_color">
          <span
          [ngStyle]="{ 'background-color': themeObj.minisite_header_text_color }"
          class="colorbox"
        >
        {{ themeObj?.minisite_header_text_color }}
        </span>
     
        </td>
      </tr>
      <tr>
        <td class="srno">6.</td>
        <td class="heading">Footer BG Color</td>
        <td *ngIf="themeObj?.minisite_footer_bg_color">
          <span
          [ngStyle]="{ 'background-color': themeObj.minisite_footer_bg_color }"
          class="colorbox"
        >
        {{ themeObj?.minisite_footer_bg_color }}
        </span>
        
        </td>
      </tr>
      <tr>
        <td class="srno">7.</td>
        <td class="heading">Footer Text Color</td>
        <td *ngIf="themeObj?.minisite_footer_text_color">
          <span
          [ngStyle]="{ 'background-color': themeObj.minisite_footer_text_color }"
          class="colorbox"
        >
        {{ themeObj?.minisite_footer_text_color }}
        </span>
           
        </td>
      </tr>
      <tr>
        <td class="srno">8.</td>
        <td class="heading">Body BG Color</td>
        <td *ngIf="themeObj?.minisite_body_bg_color">
          <span
          [ngStyle]="{ 'background-color': themeObj.minisite_body_bg_color }"
          class="colorbox"
        >
        {{ themeObj?.minisite_body_bg_color }}
        </span>
          
        </td>
      </tr>
      <tr>
        <td class="srno">9.</td>
        <td class="heading">Body Text Color</td>
        <td *ngIf="themeObj?.minisite_body_text_color">
          <span
          [ngStyle]="{ 'background-color': themeObj.minisite_body_text_color }"
          class="colorbox"
        >
        {{ themeObj?.minisite_body_text_color }}
        </span>
         
        </td>
      </tr>
      <tr>
        <td class="srno">10.</td>
        <td class="heading">Button Text Color</td>
        <td *ngIf="themeObj?.minisite_button_text_color">
          <span
          [ngStyle]="{ 'background-color': themeObj.minisite_button_text_color }"
          class="colorbox"
        >
        {{ themeObj?.minisite_button_text_color }}
        </span>
       
        </td>
      </tr>
      <tr>
        <td class="srno">11.</td>
        <td class="heading">Primary Color</td>
        <td *ngIf="themeObj?.minisite_primary_color">
          <span
          [ngStyle]="{ 'background-color': themeObj.minisite_primary_color }"
          class="colorbox"
        >
        {{ themeObj?.minisite_primary_color }}
        </span>
        
        </td>
      </tr>
      <tr>
        <td class="srno">11.</td>
        <td class="heading">Secondary Color</td>
        <td *ngIf="themeObj?.minisite_secondary_color">
          <span
          [ngStyle]="{ 'background-color': themeObj.minisite_secondary_color }"
          class="colorbox"
        >
        {{ themeObj?.minisite_secondary_color }}
        </span>
           
        </td>
      </tr>

      <!-- <tr>
        <td colspan="3" class="main_heading">Quick Message Settings</td>
      </tr>
     
      <tr>
        <td class="srno">1.</td>
        <td class="heading">SMS / MMS Sender Virtual Number</td>
        <td *ngIf="themeObj?.ds_sender_number">
            +{{ themeObj?.ds_sender_number }}
        </td>
      </tr> -->

    <!--
    <tr>
      <td class="srno"> 18. </td>
      <td class="heading"> Action </td>
      <td>
        <div class="actBtn">
          <i class="fa fa-edit mr-1" (click)="edit(themeObj.reskininig_id)" placement="bottom" ngbTooltip="Edit"></i>
        </div>
      </td>
    </tr> -->
  </table>
</div>

<div class="tblPrt" *ngIf="themeObj == undefined" class="col-md-12">
  <table
    class="mt-1 bdrStCustom"
    width="100%"
    cellpadding="0"
    cellspacing="0"
    style="background-color: #fff"
  >
    <tr>
      <td style="text-align: center">
        No Result Available
        <input
          type="button"
          value="Add New"
          class="btn btn-round btn-margo-action"
          routerLink="/directseller/addReskinning"
        />
      </td>
    </tr>
  </table>
</div>
