import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseserviceService } from '../baseservice.service';
import { Meta } from '@angular/platform-browser';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
let httpParams = new HttpParams();

@Injectable({
  providedIn: 'root'
})
export class AuthserviceService {

  public baseUrl;
  public baseUrl1;
  public baseUrl2;
  public UserData = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient, public baseService: BaseserviceService, private meta:Meta) {
    this.baseUrl1 = baseService.baseUrl1;
    this.baseUrl = this.baseUrl1 + 'auth/ds/';
    this.baseUrl2 = this.baseUrl1 + 'api/ds/';
    var userdata = JSON.parse(localStorage.getItem("UserData"));
      this.UserData.next(userdata);
  }
  checkLogin2() {
    return this.http.get<any>(this.baseUrl + "get-events-list")
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  setUserdata(data) {
    this.UserData.next(data);
  }

  checkLogin(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "login", body, httpOptions)
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  checkForgotPassword(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "forgotpassword", body, httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  checkCompanyRegistration(){
    return this.http.get<any>(this.baseService.baseUrl1 + "api/company/checkCompanyRegistration", this.baseService.httpHeadersOptions())
   .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getUserDetails() {
    return this.http.get<any>(this.baseService.baseUrl1 + "ds/user/getUserDetails", this.baseService.httpHeadersOptions())
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getUserprofileDetails() {
    return this.http.get<any>(this.baseUrl1 + "ds/user/getUserDetails", this.baseService.httpHeadersOptions())
      .pipe(
        map(data=>
          {
            if(!data.status)
            {
              if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
              {
                console.error(data.message);
                data.message="Something went wrong.Please try again."
              }
            }
            var userdata=data;
          userdata.data.customer_company_logo= this.UserData.value.customer_company_logo,
          userdata.data.customer_company_name= this.UserData.value.customer_company_name,
          userdata.data.username= this.UserData.value.username

          return data;
          }),
        catchError(this.handleError)
        );
  }

  updateDetails(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl1 + "api/ds/directSeller", body, this.baseService.httpHeadersOptions())
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  resetPasswordService(value, body) {
    // var body = value;
    return this.http.post<any>(`${this.baseUrl}resetPassword/${value}`, body, httpOptions)
    .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  forgotUserNames(value) {
    let body = value;
    return this.http.post<any>(`${this.baseUrl}forgotUserName`, body, httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  setUserNameService(value, token) {
    let body = value;
    return this.http.post<any>(`${this.baseUrl}resetUserName/${token}`, body, httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  verifyUserName(value, token) {
    let body = value;
    if (token) {
      httpOptions.headers = httpOptions.headers.set('x-auth', token);
    }
    return this.http.post<any>(`${this.baseService.baseUrl1}api/ds/checkUserNameExists`, body, httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  logout() {
    localStorage.removeItem("accesToken");
    localStorage.clear();
    sessionStorage.clear();
  }
  getBUCompanies(){
    return this.http.get<any>(`${this.baseUrl2}company/bucompaniesList`, httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getAllBundles(companyId:any){
    return this.http.get<any>(`${this.baseUrl2}allbundles/${companyId}`, httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getBusinessDomain(companyId:any,dsId:any){
    return this.http.get<any>(`${this.baseUrl2}getBusinessDomain/${companyId}/10/0?dsid=${dsId}`, httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  createUser(value:any){
    var body = value;
    return this.http.post<any>(`${this.baseUrl2}create`, body,httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  registrationDs(value:any){
    var body = value;
    return this.http.post<any>(`${this.baseUrl2}subscription/directSeller`, body,httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getHomeSubscriptionList(companyId:any,limit:any,page:any){
    return this.http.get<any>(`${this.baseUrl2}subscription/subscription/${companyId}/${limit}/${page}`, httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getHomeSubscriptionListNew(companyId:any){

    return this.http.get<any>(`${this.baseUrl2}subscription/feature/${companyId}`, httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  subscriptionCheckoutPay(body:any){
    return this.http.post<any>(`${this.baseUrl2}subscription/checkout`, body,httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    // catchError(this.handleError)
    );
  }
  checkUserNameExist(body:any){
    return this.http.post<any>(`${this.baseUrl2}validateUsername`, body,httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  checkEmailExist(body:any){
    return this.http.post<any>(`${this.baseUrl2}validateEmail`, body,httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  checkTokenVerify(body:any,companyId:any){
    return this.http.post<any>(`${this.baseUrl2}subscription/verify-email/${companyId}`, body,httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  resendVerify(body:any,companyId:any){
    return this.http.post<any>(`${this.baseUrl2}subscription/resend-email/${companyId}`, body,httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  checkUserVerify(body:any){
    return this.http.post<any>(`${this.baseUrl}email-verification-status`, body,httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  getSubscriptionMediaList(companyId:any,limit:any,page:any){
    return this.http.get<any>(`${this.baseUrl2}point/media-type/${companyId}/${limit}/${page}`, httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  subscriptionRenew(body:any){
    return this.http.post<any>(`${this.baseUrl2}subscription/renew`, body,httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    // catchError(this.handleError)
    );
  }
  getUserSenderProfile() {
    return this.http.get<any>(this.baseUrl1 + "ds/user/getSenderProfile/10/0", this.baseService.httpHeadersOptions())
      .pipe(
        map(data=>
          {
            if(!data.status)
            {
              if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
              {
                console.error(data.message);
                data.message="Something went wrong.Please try again."
              }
            }
            return data;
          }),
        catchError(this.handleError)
        );
  }
  updateUserSenderProfile(body:any) {
    return this.http.post<any>(this.baseUrl1 + "ds/user/addSenderProfile",body, this.baseService.httpHeadersOptions())
      .pipe(
        map(data=>
          {
            if(!data.status)
            {
              if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
              {
                console.error(data.message);
                data.message="Something went wrong.Please try again."
              }
            }
          }),
        catchError(this.handleError)
        );
  }
  getUserSenderProfileLog() {
    return this.http.get<any>(this.baseUrl1 + "ds/user/getSenderProfileLog/50/0", this.baseService.httpHeadersOptions())
      .pipe(
        map(data=>
          {
            if(!data.status)
            {
              if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
              {
                console.error(data.message);
                data.message="Something went wrong.Please try again."
              }
            }
            return data;
          }),
        catchError(this.handleError)
        );
  }
  subscriptionUpdate(body:any){
    return this.http.post<any>(`${this.baseUrl2}subscription/upgrade`, body,httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    // catchError(this.handleError)
    );
  }
  fetchRestrictEmail(companyId:any){
    return this.http.get<any>(`${this.baseUrl2}subscription/restrict-domain/${companyId}`,httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    // catchError(this.handleError)
    );
  }
  fetchMargoDomain(){
    return this.http.get<any>(`${this.baseUrl1}ds/user/margo-domain`,httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    // catchError(this.handleError)
    );
  }
  fetchUserDetailsByUsername(username:any,companyId:any){
    return this.http.get<any>(`${this.baseUrl2}referral/check-username/${username}/${companyId}`,httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    // catchError(this.handleError)
    );
  }
  _updateOgUrl(url: string) {
    this.meta.updateTag({  property:'og:url',content: url });
    this.meta.updateTag({ property:"twitter:url" ,content: url});
  }
  _updateOgTitle(title:string){
    this.meta.updateTag({ property:"og:title" ,content:title});
    this.meta.updateTag({ name:'twitter:title' ,content:title});
  }
  _updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc }),
    this.meta.updateTag({ property:"og:description", content:desc}),
    this.meta.updateTag({ name:'twitter:description', content:desc});
  }
  _updateOgImage(image:any){
    this.meta.updateTag({ property:"og:image", content:image}),
    this.meta.updateTag({ name:'twitter:image', content:image})
  }

  updateMxRecordStatus() {
    return this.http.get<any>(this.baseUrl1 + "ds/user/updateMxRecordsStatus", this.baseService.httpHeadersOptions())
      .pipe(
        map(data=>
          {
            if(!data.status)
            {
              if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
              {
                console.error(data.message);
                data.message="Something went wrong.Please try again."
              }
            }
            return data;
          }),
        catchError(this.handleError)
        );
  }

  freeTrialSubscription(body:any){
    return this.http.post<any>(`${this.baseUrl2}subscription/freeTrialCheckout`, body,httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    );
  }
  contactForm(value:any){
    var body = value;
    return this.http.post<any>(`${this.baseUrl2}contact/add`, body,httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  agreementLogs(status:any){
    let body={
      "is_agreement" : status
    }
    this.http.post<any>(`${this.baseUrl2}accept-term-condition`, body,httpOptions).subscribe()
  }
  referralLogs(comapnyId:any,referral_code:any,dsId:any,page_title:any,mode:any){
    let body={
       'company':comapnyId,
       "referral_code" : referral_code,
       "ds_id" : dsId || null,
       "page_title" :page_title,
       "mode":mode
    }
    this.http.post<any>(`${this.baseUrl2}referral/referral-log`, body,httpOptions).subscribe()
  }
  getCompanyDomainList(companyId:any,limit:any,page:any,is_company=true){
    return this.http.get<any>(`${this.baseUrl2}getCompany-BusinessDomain/${companyId}/${limit}/${page}/${is_company}`, httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  sendDsReferral(value:any,company:any){
    var body = value;
    return this.http.post<any>(`${this.baseUrl2}referral/share-referral/${company}`, body,httpOptions)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  updateUserDetails() {
    this.getUserDetails().subscribe(
      (data) => {
        if (data.status) {
          localStorage.setItem("UserData", JSON.stringify(data.data));
        }
      }
    );
  }
  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}

