import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { BaseserviceService } from "../baseservice.service";



@Injectable({
    providedIn: 'root'
})
export class MetaService {

    public baseUrl;
  constructor(private http: HttpClient, public baseService: BaseserviceService) {

    this.baseUrl = baseService.baseUrl1 + "api/meta/"
  }

  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }

  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  };

  getMetaFields(cid, typ, contact_id) {
    return this.http.get<any>(this.baseUrl + 'metadetailbyid/' + cid + '/' + typ + '/' + contact_id, this.httpHeadersOptions())
    .pipe(
      map(data => {
        if (!data.status) {
          if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
            //console.error(data.message);
            data.message = "Something went wrong.Please try again."
          }
        }
        return data;
      }),
      catchError(this.handleError));
  }

  getMetaFieldsForUpdate(cid, typ, contact_id) {
    return this.http.get<any>(this.baseUrl + 'metafields/' + cid + '/' + typ + '/' + contact_id, this.httpHeadersOptions())
    .pipe(
      map(data => {
        if (!data.status) {
          if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
            //console.error(data.message);
            data.message = "Something went wrong.Please try again."
          }
        }
        return data;
      }),
      catchError(this.handleError));
  }

  updateProfileMetaFields(cid, typ, contact_id, obj) {
    let url = this.baseUrl + 'addupdatemetafields/' + cid + '/' + typ + '/' + contact_id;
    let body = JSON.stringify(obj);
    return this.http.post<any>(url, body, this.httpHeadersOptions())
    .pipe(
      map(data => {
        if (!data.status) {
          if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
            //console.error(data.message);
            data.message = "Something went wrong.Please try again."
          }
        }
        return data;
      }),
      catchError(this.handleError));
  }
}