import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { fromEvent } from "rxjs";
import { ProductCatalogueService } from "src/app/services/product-catalogue/product-catalogue.service";
import { ConfirmationDialogService } from "src/app/_utils/confirmation-dialog/confirmation-dialog.service";
import { BaseComponent } from "../base.component";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ReskinningService } from 'src/app/_services/reskinning.service';
import { RegisterService } from "src/app/services/users/register.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
@Component({
  selector: "app-product-catalog",
  templateUrl: "./product-catalog.component.html",
  styleUrls: ["./product-catalog.component.css"],
})
export class ProductCatalogComponent extends BaseComponent implements OnInit {
  public productObj: any = [];
  defaultPicture = "assets/custom/images/broken.png";
  cp = 1;
  limit = 10;
  stockLimit=10;
  stockSkip=0;
  stockCp=1;
  skip = 0;
  totalCount = 0;
  ord = "asc";
  colname = "product_name";
  checked = false;
  getCompanyobj;
  currentUser;
  companyID = 0;
  disCompId: any = 0;
  isSelected=true;
  isSubmitted=false;
  @ViewChild("searchText", { static: true }) searchText: ElementRef;
  selectedProduct:any
  today: number = Date.now();
  @ViewChild('productCatalogue') productCatalogue: any;
  selectedCurrency=this.ReskinningService.dsCurrency;
  isIndependentUser: any;
  stockLogsData:any=[];
  totalStockCount: any=0;
  domainList = [];
  popupForm:UntypedFormGroup
  constructor(
    private master: ProductCatalogueService,
    private confirmationDialogService: ConfirmationDialogService,
    private modalService: NgbModal,
    private ReskinningService: ReskinningService,
    private registerService:RegisterService,
    private formBuilder: UntypedFormBuilder
  ) {
    super();
  }
  ngOnInit() {
    if (localStorage.getItem("UserData")) {
      var userdata = JSON.parse(localStorage.getItem("UserData"));
      this.currentUser = userdata;
      this.isIndependentUser=this.currentUser.is_bu;
      if (this.currentUser.user_customer_id) {
        this.companyID = this.currentUser.user_customer_id;
        this.getDomains(this.currentUser?.user_customer_id);
        if(!this.isIndependentUser){
          this.getIndependentProductCatalogue()
        }else{
          this.getProductCatalogue();
        }
      }

    }
    this.popupForm = this.formBuilder.group({
      stock_quantity:[0,[Validators.required,Validators.max(1000)]],
      business_domain_id:[0,[Validators.required]],
      status:[false]
    });
  }
  order: string = "get_master_theme.theme_name";
  reverse: boolean = false;

  sorting(col: string) {
    if (this.companyID != 0) {
      this.colname = col;
      if (this.ord == "asc") {
        this.ord = "desc";
      } else {
        this.ord = "asc";
      }
      if(!this.isIndependentUser){
        this.getIndependentProductCatalogue();
      }else{
        this.getProductCatalogue();
      }
    } else {
      this.toastrService.error("Please select company");
    }
  }

  getProductCatalogue() {
    if (this.companyID == 0) {
      return;
    }
    let self = this;
    this.skip = (this.cp - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.master
      .getProductCatalogSelectedList(
        this.companyID,

        this.currentUser.user_ds_id,

        this.limit,
        this.skip,
        this.searchText.nativeElement.value,
        this.colname,
        this.ord,
        this.isSelected
      )
      .subscribe(
        (response) => {
          this.ngxSpinnerService.hide();
          if (response.status) {
            self.productObj = response.data.rows;
            self.totalCount = response.data.total;
          }
        },
        (error) => {
          this.ngxSpinnerService.hide();
        }
      );
  }
  getIndependentProductCatalogue() {
    if (this.companyID == 0) {
      return;
    }
    let self = this;
    this.skip = (this.cp - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.master
      .getIndependentProductCatalogSelectedList(
        this.companyID,

        this.currentUser.user_ds_id,

        this.limit,
        this.skip,
        this.searchText.nativeElement.value,
        this.colname,
        this.ord,
        this.isSelected
      )
      .subscribe(
        (response) => {
          this.ngxSpinnerService.hide();
          if (response.status) {
            self.productObj = response.data.rows;
            self.totalCount = response.data.total;
          }
        },
        (error) => {
          this.ngxSpinnerService.hide();
        }
      );
  }
  editCatalog(id) {
    this.router.navigate(["/directseller/addProductCatalogue"], {
      queryParams: { companyID: this.companyID, id: id },
    });
  }

  deleteCatalog(event, id) {
    event.preventDefault();
    let self = this;
    let obj = this.productObj.find((element) => {
      if (element.product_catalog_id === id) {
        return true;
      }
    });

    this.confirmationDialogService
      .confirm(
        "Please confirm",
        "ARE YOU SURE YOU WANT TO DELETE ?",
        "YES",
        "NO"
      )
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          let body = {
            company: this.companyID,
            p_cid: obj.product_catalog_id,
            isdelete: !obj.isdelete,
          };
          obj.isdelete = !obj.isdelete;
          obj.company = this.companyID;
          this.master.UpdateCatalogStatus(body).subscribe((data) => {
            self.getProductCatalog(this.companyID);
            this.toastrService.success(
              "Product Catalogue delete Successfully "
            );
            this.ngxSpinnerService.hide();
          });
        } else {
          this.toastrService.error(" error : update catalogue");
        }
      })
      .catch((err) => {});
  }

  updateStatus(event, id) {
    event.preventDefault();
    let obj = this.productObj.find((element) => {
      if (element.product_catalog_id === id) {
        return true;
      }
    });

    this.confirmationDialogService
      .confirm(
        "Please confirm",
        "Are you sure you want to change the status?",
        "YES",
        "NO"
      )
      .then((confirmed) => {
        if (confirmed) {
          this.ngxSpinnerService.show();
          let body = {
            company: this.companyID,
            p_cid: obj.product_catalog_id,
            isactive: !obj.isactive,
          };
          obj.isactive = !obj.isactive;
          obj.company = this.companyID;
          this.master.UpdateCatalogStatus(body).subscribe((data) => {
            this.toastrService.success(
              "Product Catalogue Status Changed Successfully "
            );
            this.ngxSpinnerService.hide();
          });
        } else {
          this.toastrService.error(" error : update catalogue");
        }
      })
      .catch((err) => {
        this.toastrService.error(err);
      });
  }
  getProductCatalog(id) {
    // this.ngxSpinnerService.show();
    if (id != 0) {
      this.companyID = id;
      this.getIndependentProductCatalogue();
    } else {
      this.productObj = [];
      this.totalCount = 0;
      this.cp = 1;
      // this.ngxSpinnerService.hide();
    }
  }
  ngAfterViewInit() {
    // this.searchText.nativeElement.value = '';
    fromEvent(this.searchText.nativeElement, "keyup")
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.skip = 0;
          this.cp = 1;
          this.ngxSpinnerService.show();
        }),
        switchMap((data:any) =>{
          if(!this.isIndependentUser){
            return this.master.getIndependentProductCatalogSelectedList(
              this.companyID,

              this.currentUser.user_ds_id,

              this.limit,
              this.skip,
              this.searchText.nativeElement.value,
              this.colname,
              this.ord,
              this.isSelected
            )
          }else{
            return this.master.getProductCatalogSelectedList(
              this.companyID,
              this.currentUser.user_ds_id,
              this.limit,
              this.skip,
              this.searchText.nativeElement.value,
              this.colname,
              this.ord,
              this.isSelected
            )
          }
        }

        )
      )
      .subscribe((res) => {
        if (res.status) {
          if (res.data.rows == null) {
            this.productObj = [];
          } else {
            this.productObj = res.data.rows;
          }
          this.totalCount = res.data.total;
        } else {
          this.productObj = [];
          this.totalCount = 0;
        }
        this.ngxSpinnerService.hide();
      });
  }

  checkProductExist(event:any,id:any){
    this.ngxSpinnerService.show();
    this.master.checkProductExist(id,this.companyID).subscribe((res)=>{
      this.ngxSpinnerService.hide();
      if(res.status==true){
        if(res.data[0].total==0){
          this.deleteCatalog(event,id)
        }else{
          this.modalService.open(this.productCatalogue, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          }, (reason) => {
          });
        }
      }
    })
  }
  _stockModal(modal:any,product:any){
    this.selectedProduct=product;
    this.popupForm.patchValue({
      stock_quantity: !this.isIndependentUser ? (this.selectedProduct?.stock_quantity) : (this.selectedProduct?.stock_count),
      business_domain_id: this.selectedProduct?.business_domain_id,
      status:this.selectedProduct?.isactive
    });
    this.modalService.open(modal,{
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      keyboard: false,
     })
  }
  _checkStockInput(stockQuantity:any){
    if (stockQuantity.value && stockQuantity.value <= 0) {
      stockQuantity.value=1
    }
  }
  _updateProductChange(stockQuant?:any){
    this.isSubmitted=true;
    if(!stockQuant?.value || stockQuant?.value==0){
      return
    }
    let body={
      "ds_id":this.currentUser.user_ds_id,
      "product_id":this.selectedProduct.product_catalog_id,
      "stock_count":stockQuant?.value || 0,
    }
    this.ngxSpinnerService.show();
    this.master.addStockQuantity(this.companyID,body).subscribe((res:any)=>{
      this.ngxSpinnerService.hide();
      this.modalService.dismissAll();
      this.isSubmitted=false;
      if(res.status){
        this.getProductCatalogue();
        this.toastrService.success(`Stock updated successfully`)
      }else{
        this.toastrService.error(res.message)
      }
    },(err:any)=>{
      this.isSubmitted=false;
      this.ngxSpinnerService.hide();
    })
  }
  _stockLogsModal(modal:any,product:any){
    this.selectedProduct=product;
    this.modalService.open(modal,{
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      keyboard: false,
     })
     this._getStockLogs(0);
  }
  _getStockLogs(page:any){
    this.stockLogsData=[];
    this.stockSkip = (this.stockCp - 1) * this.stockLimit;
    this.ngxSpinnerService.show();
    this.master.getStockLogs(this.companyID, this.currentUser.user_ds_id,this.selectedProduct.product_catalog_id,this.stockLimit,this.stockSkip).subscribe((res:any)=>{
      this.ngxSpinnerService.hide();
      if(res.status){
        this.stockLogsData=res.data.rows;
        this.totalStockCount=res.data.total;
      }else{
        this.totalStockCount=0
      }
    },(err:any)=>{
      this.stockLogsData=[];
      this.totalStockCount=0
      this.ngxSpinnerService.hide();
    })
  }
  _updateIndependentProductChange(stockQuant?:any){
    this.isSubmitted=true;
    if(!stockQuant?.value || stockQuant?.value==0){
      return
    }
    if(this.popupForm.invalid){return}
    let body={
      "ds_id":this.currentUser.user_ds_id,
      "product_id":this.selectedProduct.product_catalog_id,
      "business_domain_id": this.popupForm.value.business_domain_id,
      "stock_quantity":this.popupForm.value.stock_quantity,
      "is_active":this.popupForm.value.status
    }
    this.ngxSpinnerService.show();
    this.master.addIndependentStockQuantity(this.companyID,body).subscribe((res:any)=>{
      this.ngxSpinnerService.hide();
      this.modalService.dismissAll();
      this.isSubmitted=false;
      if(res.status){
        this.getIndependentProductCatalogue();
        this.toastrService.success(`Stock updated successfully`)
      }else{
        this.toastrService.error(res.message)
      }
    },(err:any)=>{
      this.isSubmitted=false;
      this.ngxSpinnerService.hide();
    })
  }
  get f() {
    return this.popupForm.controls;
  }
  getDomains(id:any){
    this.registerService.getUserDomains().subscribe(
      (data) => {
        if (data.status) {
          this.domainList=data.data;
        } else {
          this.domainList=[]
        }
      },
      (error) => {
        this.domainList=[]
        console.error(error);
      }
    )
  }
}
