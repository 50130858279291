import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { throwError, Observable, of, forkJoin, observable } from 'rxjs';
import { BaseserviceService } from '../../../services/baseservice.service';
import csc from 'country-state-city';
import { TimeZoneService } from 'src/app/firstadmin/services/timezone.service';

import countryTelephoneCode, { countries } from "country-telephone-code";

let httpParams = new HttpParams();
@Injectable({
  providedIn: 'root'
})
export class MasterService {
  public baseUrl;
  public baseUr2;
  ipAddress: string = null;


  constructor(private http: HttpClient,
    public baseService: BaseserviceService,
    private timeZoneService: TimeZoneService) {

    this.baseUr2 = baseService.baseUrl1
    this.baseUrl = baseService.baseUrl1 + 'api/ds/';

    /* if ( == null) {
      this.http.get<{ ip: string }>('https://jsonip.com')
        .subscribe(data => {
           = data.ip;
        })
    } */
  }



  uploadCanvaImage(data) {
    var body = data;
    return this.http.post<any>(this.baseUr2 + "api/aws/uploadCanvaImage", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  addingContact(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addcontact", body, this.httpHeadersOptions())
      .pipe(map(data => {
        if (!data.status) {
          if (data.message && (data.message.indexOf("Error acquiring client") >= 0 || data.message.indexOf("Error executing query") >= 0)) {
            console.error(data.message);
            data.message = "Something went wrong.Please try again."
          }
        }
        return data;
      }),
      catchError(this.handleError));
  }

  updateContact(value) {
    if(typeof(value.contact_ds_id)==='string'){
        value.contact_ds_id = 0;
    }
    var body = value;
    return this.http.put<any>(this.baseUrl + "updatecontact", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getContact() {
    return this.http.get<any>(this.baseUrl + "contactList", this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getFilteredContact(params) {
    return this.http.post<any>(this.baseUrl + "filteredContactList", params, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getContactListByPhoneNo(params) {
    return this.http.post<any>(this.baseUrl + "getContactListByPhoneNo", params, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getContactListByEmail(params) {
    return this.http.post<any>(this.baseUrl + "getContactListByEmail", params, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  contactListByArray(params) {
    return this.http.post<any>(this.baseUrl + "contactListByArray", params, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  recentContactList(params) {
    return this.http.post<any>(this.baseUrl + "recentContactList", params, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  addRecentContactList(params) {
    return this.http.post<any>(this.baseUrl + "addRecentContactList", params, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getRecentProductList(params) {
    return this.http.post<any>(this.baseUr2 + "api/recentProductList", params, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getDSInformationById(ds_id) {
    return this.http.get<any>(this.baseUrl + `getDSInformationById/${ds_id}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getDSById(ds_id) {
    return this.http.get<any>(this.baseUrl + `getDSById/${ds_id}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getContactListById(contact_id) {
    return this.http.get<any>(this.baseUrl + `contactList/${contact_id}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getTeamMemberById(contact_id) {
    return this.http.get<any>(this.baseUrl + `getDSInformationById/${contact_id}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getContactListByIdWithMap(contactID): Observable<any> {
    return this.getContactListById(contactID) //First Get Contact Information
      .pipe(
        map((contactData: any, index: number) => {
          contactData.data.contacttypeid = contactData.data.contact_type_id;
          return contactData;
        }),
        mergeMap((contactData: any, index: number) => {
          if (contactData) {

            if (contactData.data.contact_country && !isNaN(contactData.data.contact_country)) {
              var selectedCountryObj = csc.getCountryById(contactData.data.contact_country);
              contactData.data.contact_country = selectedCountryObj.name;
              contactData.data.phonecode = selectedCountryObj.phonecode;
              if (contactData.data.contact_state && !isNaN(contactData.data.contact_state)) {
                contactData.data.contact_state = csc.getStateById(contactData.data.contact_state).name;
                if (contactData.data.contact_city && !isNaN(contactData.data.contact_city)) {
                  if (contactData.data.contact_state === contactData.data.contact_city) {
                    contactData.data.contact_city = csc.getStateById(contactData.data.contact_state).name;
                  } else {
                    contactData.data.contact_city = csc.getCityById(contactData.data.contact_city).name;

                  }
                }
              }
            }
            let contactAddresDetails = {
              address: contactData.data.contact_address || '',
              city: contactData.data.contact_city || '',
              state: contactData.data.contact_state || '',
              country: contactData.data.contact_country || ''
            }
            //Check if address in Valid or not
            var completeAddr = contactAddresDetails.address + contactAddresDetails.city + contactAddresDetails.state + contactAddresDetails.country;

            if (completeAddr == null || completeAddr == ',' || completeAddr == '') {
              let mapData: any = {};
              mapData.status = true;
              mapData.mapURL = "../../../../assets/images/addressNotFound.jpg";//Default image for Invalid address
              contactData.mapData = mapData;
              return new Promise((resolve, reject) => resolve(contactData));
            } else {
              return this.getMapImageForAddress(contactAddresDetails) // Now Get MAP for the contact address
                .pipe(
                  map((mapData: any, index: number) => {
                    contactData.mapData = mapData;    //Append Map Data to Main Data object
                    return contactData;
                  })
                );
            }

          }
        })
      );
  }

  getTeamMemberByIdWithMap(contactID): Observable<any> {
    return this.getTeamMemberById(contactID) //First Get Contact Information
      .pipe(
        map((contactData: any, index: number) => {
          contactData.data.contacttypeid = contactData.data.contact_type_id;
          return contactData;
        }),
        mergeMap((contactData: any, index: number) => {
          if (contactData) {

            if (contactData.data.user_country && !isNaN(contactData.data.user_country)) {
              var selectedCountryObj = csc.getCountryById(contactData.data.user_country);
              contactData.data.user_country = selectedCountryObj.name;
              contactData.data.phonecode = selectedCountryObj.phonecode;
              if (contactData.data.user_state && !isNaN(contactData.data.user_state)) {
                contactData.data.user_state = csc.getStateById(contactData.data.user_state).name;
                if (contactData.data.user_city && !isNaN(contactData.data.user_city)) {
                  if (contactData.data.user_state === contactData.data.user_city) {
                    contactData.data.user_city = csc.getStateById(contactData.data.user_state).name;
                  } else {
                    contactData.data.user_city = csc.getCityById(contactData.data.user_city).name;

                  }
                }
              }
            }

            let contactAddresDetails = {
              address: contactData.data.contact_address || contactData.data.user_address1 + ',' + contactData.data.user_address2 || '',
              city: contactData.data.contact_city || contactData.data.user_city || '',
              state: contactData.data.contact_state || contactData.data.user_state || '',
              country: contactData.data.contact_country || contactData.data.user_country || ''
            }
            //Check if address in Valid or not
            var completeAddr = contactAddresDetails.address + contactAddresDetails.city + contactAddresDetails.state + contactAddresDetails.country;

            if (completeAddr == null || completeAddr == ',' || completeAddr == '') {
              let mapData: any = {};
              mapData.status = true;
              mapData.mapURL = "../../../../assets/images/addressNotFound.jpg";//Default image for Invalid address
              contactData.mapData = mapData;
              return new Promise((resolve, reject) => resolve(contactData));
            } else {
              return this.getMapImageForAddress(contactAddresDetails) // Now Get MAP for the contact address
                .pipe(
                  map((mapData: any, index: number) => {
                    contactData.mapData = mapData;    //Append Map Data to Main Data object
                    return contactData;
                  })
                );
            }

          }
        })
      );
  }


  addingContactByName(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "getcontactListByName", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getMaritalStatus() {
    var body = {};
    return this.http.post<any>(this.baseUr2 + "api/ds/master/getMaritalStatus/500/0", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  getMaritalStatusById(id) {
    return this.http.get<any>(this.baseUr2 + "api/ds/master/getMaritalStatusById/" + id, this.httpHeadersOptions())

      .pipe(catchError(this.handleError));
  }

  getSex() {
    var body = {};
    return this.http.post<any>(this.baseUr2 + "api/ds/master/getGender", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getSexById(id) {


    return this.http.get<any>(this.baseUr2 + "api/ds/master/getSexById/" + id, this.httpHeadersOptions())


      .pipe(catchError(this.handleError));
  }

  getAgerange() {
    var body = {};
    return this.http.post<any>(this.baseUr2 + "api/ds/master/getAgerange", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  getAgerangeById(id) {
    return this.http.get<any>(this.baseUr2 + "api/ds/master/getAgerangeById/" + id, this.httpHeadersOptions())

      .pipe(catchError(this.handleError));
  }

  getLoyalityType() {
    var body = {};
    return this.http.post<any>(this.baseUr2 + "api/ds/master/getLoyalityType/500/0", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  getLoyalityTypeById(id) {
    return this.http.get<any>(this.baseUr2 + "api/ds/master/getLoyalityTypeById/" + id, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  getProfession() {
    var body = {};
    return this.http.post<any>(this.baseUr2 + "api/ds/master/getProfession/500/0", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  getProfessionById(id) {
    return this.http.get<any>(this.baseUr2 + "api/ds/master/getProfessionById/" + id, this.httpHeadersOptions())

      .pipe(catchError(this.handleError));
  }

  getContactListByName(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "getcontactListByName", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getContactListForHostByName(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "getcontactListFotHostByName", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getDsHostContactRelByContactId(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "getDsHostContactRelByContactId", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getDsHostEventContactRelByContactId(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "getDsHostEventContactRelByContactId", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getContactListForQuicktextByName(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "getcontactListFotQuicktextByName", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  searchProductWithThemeByName(value) {
    var body = value;
    return this.http.post<any>(this.baseUr2 + "api/productCatalogueByName", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  searchProductByName(value) {
    var body = value;
    return this.http.post<any>(this.baseUr2 + "api/productListByName", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  // /api/product/5
  getProducts() {
    return this.http.get<any>(this.baseUr2 + "api/productCatalogue/30/0", this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getProductCatalogueByType(type,limit,offset) {
    return this.http.get<any>(this.baseUr2 + "api/productCatalogueByType/"+type+"/"+limit+"/"+offset, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getProductCatalogueById(id) {
    return this.http.get<any>(this.baseUr2 + "api/productCatalogue/" + id, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getFilteredProducts(param) {
    return this.http.post<any>(this.baseUr2 + "api/filteredProductCatalogue", param, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getAllSubDsListByName(param) {
    return this.http.post<any>(this.baseUrl + "getAllSubDsListByName", param, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getAllSubDsListByNameCount(param) {
    return this.http.post<any>(this.baseUrl + "getAllSubDsListByNameCount", param, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getProductsById(prod_id) {
    return this.http.get<any>(this.baseUr2 + `api/productCatalogue/${prod_id}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  getCompanyProductThemes(pid, cid) {
    return this.http.get<any>(this.baseUr2 + "api/ds/master/getCompanyProductThemes/" + pid + "/" + cid, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getProductList(cid) {
    return this.http.get<any>(this.baseUr2 + "api/getProductList/" + cid, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  getThemes(pid, jid, limit = 30, skip = 0, colname: any ='createdon', ord = 'desc',searchText = '&',domain='',product_id=[]) {
  let search  = encodeURIComponent(searchText)


    return this.http.get<any>(this.baseUr2 + `api/ds/master/getThemeAssosciationBycidpid/${pid}/${jid}/${limit}/${skip}?sort=${colname}:${ord}&search=${search}&domain=${domain}&product_id=${product_id}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getRecentThemes(pid, jid, searchText= '',domain='') {
    let search  = encodeURIComponent(searchText)
    return this.http.get<any>(this.baseUr2 + `api/ds/master/getThemeAssosciationBycidpidRecent/${pid}/${jid}?search=${search}&domain=${domain}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }






  marketOrder(value) {
    var body = value;
    return this.http.post<any>(this.baseUr2 + "api/marketOrder", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getContainerPersoanlMessage(id) {


    return this.http.get<any>(this.baseUr2 + "api/getContainerPersoanlMessage/" + id, this.httpHeadersOptions())

      .pipe(catchError(this.handleError));
  }

  updateMarketOrderThemeById(value) {
    return this.http.put<any>(this.baseUr2 + "api/updateMarketOrderThemeById/", value, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  createUser(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "directSeller", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  createHostUser(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addingHost", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }



  sendActivationLink(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "sendActivationLinkForDs", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  checkHostUserRegistration(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "checkHostUserRegistration", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  /* added by Raj*/

  getEvents() {
    return this.http.get<any>(this.baseUrl + "getEvents", this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  getDsEvents() {
    return this.http.get<any>(this.baseUrl + "getDsEvents", this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getEventByID(id) {
    return this.http.get<any>(this.baseUrl + "getEventByID/" + id, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));

  }

  addEventHostCohostRel(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addEventHostCohostRel", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  addDsHostContactRel(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addDsHostContactRel", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getRecentOrderContactList(params) {
    return this.http.post<any>(this.baseUrl + "recentOrderContactList", params, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  changeExistingCohost(hostCohostRelId, newContactId) {
    var body = {
      'hostCohostRelId': hostCohostRelId,
      'newContactId': newContactId
    };
    return this.http.patch<any>(this.baseUrl + "changeExistingCohost", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  addEventCohost(body) {
    return this.http.post<any>(this.baseUrl + "addEventCohost", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  changeEventHost(eventId, newHostId, marketOrderId) {
    var body = {
      'eventId': eventId,
      'newHostId': newHostId,
      'marketOrderId': marketOrderId
    };
    return this.http.patch<any>(this.baseUrl + "changeEventHost", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  // Fetch host and cohost of an event
  getEventHostCohost(eventId) {
    return this.http.get<any>(this.baseUrl + "getEventHostCohost/" + eventId, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  syncEvent(eventObj) {
    return this.http.post<any>(this.baseUrl + "syncEvent", { "event_id": eventObj.id }, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  addNewEvent(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addEvent", body, this.httpHeadersOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  addShopLiveEvent(value:any) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "addShopLiveEvent", body, this.httpHeadersOptions())
      .pipe(
        catchError(this.handleError)
      );
  }

  checkEventIdExists(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "checkEventIdExists", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  updateevent(EventData): Observable<any> {
    return this.timeZoneService.getTimeZoneByValue(EventData.event_time_zone)  //first api call
      .pipe(
        map((data: any, index: number) => {
          if (data.status) {
            EventData.selectedTimezone = data.data[0].utc[0];
          }
          return EventData;
        }),
        mergeMap((data: any, index: number) => {
          return this.editEvent(EventData)
        })
      );
  }

  editEvent(value) {
    var body = value;
    return this.http.put<any>(this.baseUrl + "updateEvent", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  delEvent(value) {
    var id = value;
    return this.http.delete<any>(this.baseUrl + "delEvent/" + id, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  /*added by raj*/

  getTeamMemberEngagmemnt(id, type) {
    return this.http.get<any>(this.baseUrl + "getTeamMemberEngagmemnt/" + id + "/" + type, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));

  }

  getContactCampaigns(id,limit,skip) {
    return this.http.get<any>(this.baseUrl +`getContactCampaigns/${id}?limit=${limit}&skip=${skip}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getContactReplenishment(id,limit,skip) {
    return this.http.get<any>(this.baseUrl + `getContactReplenishment/${id}?limit=${limit}&skip=${skip}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getContactCrossSell(id,limit,skip) {
    return this.http.get<any>(this.baseUrl + `getContactCrossSell/${id}?limit=${limit}&skip=${skip}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getContactReplenishmentDS() {
    return this.http.get<any>(this.baseUrl + "getContactReplenishmentDS/", this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getContactCrossSellDS() {
    return this.http.get<any>(this.baseUrl + "getContactCrossSellDS/", this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getCompaignListForSearch(body) {
    return this.http.post<any>(this.baseUrl + "getCompaignListForSearch/", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getCustomerByMarketOrderID(id) {
    return this.http.get<any>(this.baseUrl + "getCustomerByMarketOrderID/" + id, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  recentCampaignList(body) {
    return this.http.post<any>(this.baseUrl + "recentCampaignList/", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getCompaignView(id) {
    return this.http.get<any>(this.baseUrl + "getCompaignView/" + id, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getEventListForSearchOld(id) {
    var body = { name: id }
    return this.http.post<any>(this.baseUrl + "getEventListForSearch", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getEventListForSearch(body) {
    // var body = { name: id }
    return this.http.post<any>(this.baseUrl + "getEventListForSearch", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getEventView(id) {
    return this.http.get<any>(this.baseUrl + "getEventView/" + id, this.httpHeadersOptions())
      .pipe(
        mergeMap((eventData: any) => {
          if (eventData.status) {
            if (eventData.data[0].event_time_zone.length > 5) {
              return this.timeZoneService.getTimeZoneByValue(eventData.data[0].event_time_zone)
                .pipe(
                  map((data: any) => {
                    if (data.status) {
                      eventData.data[0].event_time_zone = data.data[0].abbr;
                    }
                    return eventData;
                  }));
            } else {
              return of(eventData);
            }
          } else {
            return of(eventData);
          }
        }),
        catchError(this.handleError));
  }

  getEventViewWithMap(id): Observable<any> {
    return this.getEventView(id) //First Get Event Information
      .pipe(
        map((eventData: any, index: number) => {
          return eventData;
        }),
        mergeMap((eventData: any) => {
          if (eventData.status) {
            if (eventData.data[0].event_time_zone.length > 5) {
              return this.timeZoneService.getTimeZoneByValue(eventData.data[0].event_time_zone)
                .pipe(
                  map((data: any) => {
                    if (data.status) {
                      eventData.data[0].event_time_zone = data.data[0].abbr;
                    }
                    return eventData;
                  }));
            } else {
              return of(eventData);
            }
          } else {
            return of(eventData);
          }
        }),
        mergeMap((eventData: any, index: number) => {
          if (eventData) {
            let eventAddresDetails = {
              address: eventData.data[0].event_address1 || '' + ',' + (eventData.data[0].event_address2 || ''),
              city: eventData.data[0].event_city || '',
              state: eventData.data[0].event_state || '',
              country: eventData.data[0].event_country || ''
            }
            //Check if address in Valid or not
            var completeAddr = eventAddresDetails.address + eventAddresDetails.city + eventAddresDetails.state + eventAddresDetails.country;

            if (completeAddr == null || completeAddr == ',' || completeAddr == '') {
              let mapData: any = {};
              mapData.status = true;
              mapData.mapURL = "../../../../assets/images/addressNotFound.jpg";//Default image for Invalid address
              eventData.mapData = mapData;
              return new Promise((resolve, reject) => resolve(eventData));
            } else {
              return this.getMapImageForAddress(eventAddresDetails) // Now Get MAP for the Event address
                .pipe(
                  map((mapData: any, index: number) => {
                    eventData.mapData = mapData;    //Append Map Data to Main Data object
                    return eventData;
                  })
                );
            }
          }
        })
      );
  }

  getMapViewFromAddress(data) {
    if (data) {
      let eventAddresDetails = {
        address: data.event_address1 || '' + ',' + (data.event_address2 || ''),
        city: data.event_city || '',
        state: data.event_state || '',
        country: data.event_country || ''
      }
      //Check if address in Valid or not
      var completeAddr = eventAddresDetails.address + eventAddresDetails.city + eventAddresDetails.state + eventAddresDetails.country;
      //  if(!eventAddresDetails.address || !eventAddresDetails.city || !eventAddresDetails.country) {
      //   let mapData: any = {};
      //   mapData.status = true;
      //   mapData.mapURL = "../../../../assets/images/addressNotFound.jpg";//Default image for Invalid address
      //   return new Observable((observer) => {
      //     observer.next(mapData)
      //   })
      //  }

      if (completeAddr == null || completeAddr == ',' || completeAddr == '') {
        let mapData: any = {};
        mapData.status = true;
        mapData.mapURL = "../../../../assets/images/addressNotFound.jpg";//Default image for Invalid address
        return new Observable((observer) => {
          observer.next(mapData)
        })
      } else {
        return this.getMapImageForAddress(eventAddresDetails) // Now Get MAP for the Event address
          .pipe(
            map((mapData: any, index: number) => {
              return mapData;
            })
          );
      }
    }
  }


  getSellerAllCompaignTeamMembers() {
    return this.http.get<any>(this.baseUrl + "getSellerAllCompaignTeamMembers/", this.httpHeadersOptions())

      .pipe(catchError(this.handleError));

  }



  getEventListForSearchContact(contactid,limit,skip) {
    var body = { contactid: contactid, limit: limit, skip : skip }
    return this.http.post<any>(this.baseUrl + "getEventListForSearchContact", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getRecentEventList(params) {
    return this.http.post<any>(this.baseUrl + "getRecentEventList", params, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  getEventGusetList(body) {
    return this.http.post<any>(this.baseUrl + "getEventGuestList", body, this.baseService.httpHeadersOptions())
      .pipe(catchError(this.handleError));

  }

  getMapImageForAddress(contactData) {
    return this.http.post<any>(this.baseUr2 + "maps/getMapImage", contactData, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  /* added by raj*/

  addingMarketCampaignOrder(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "marketCampaignOrder", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));

  }

  getUserOptinStatus(id) {
    return this.http.get<any>(this.baseUrl + "getUserOptinStatus/" + id, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getAllUserOptinRequest(id) {
    return this.http.get<any>(this.baseUrl + "getAllUserOptinRequest/" + id, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  updateUserOptinStatus(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "updateUserOptinStatus", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  updateContactAgreeToHostJoinStatus(body) {
    return this.http.post<any>(this.baseUrl + "updateContactAgreeToHostJoinStatus", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));

  }

  getCompaignProfileByID(id) {
    return this.http.get<any>(this.baseUrl + "getCompaignProfileByID/" + id, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getDashboardMyContact() {
    return this.http.get<any>(this.baseUrl + "getDashboardMyContact", this.httpHeadersOptions())


      .pipe(catchError(this.handleError));
  }



  getCampaignList(id,tz) {
    return this.http.get<any>(this.baseUr2 + "api/ds/saveEmailDataById/" + id+'?tz='+tz, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  getCampaignListForIndependent(id,tz) {
    return this.http.get<any>(this.baseUr2 + "api/ds/saveEmailDataById2/" + id+'?tz='+tz, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getSmsList(id:any) {
    // id = 14858;
    return this.http.get<any>(this.baseUr2 + "api/im/messeges/" + id, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getCampaignListOLD(id) {
    return this.http.get<any>(this.baseUr2 + "api/marketOrderCampaign/" + id, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getEventDetailList(value) {
      var body = value;
    return this.http.post<any>(this.baseUr2 + "api/marketOrderCampaign", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  createHost(contactdata): Observable<any> {
    const loggedinUserObj =JSON.parse(localStorage.getItem("UserData"));
    let firstName = loggedinUserObj.user_fname;
    let lastName = loggedinUserObj.user_lname;
    let body = {
      contact_id: contactdata.contact_id
    }
    return this.getDsHostContactRelByContactId(body)
      .pipe(
        map((data: any, index: number) => {
          return data;
        }),
        mergeMap((data: any, index: number) => {
          if (!data.status) {
            let addUser = {
              username: Date.now().toString(36),
              email: contactdata.contact_email,
              user_fname: contactdata.contact_fname,
              user_lname: contactdata.contact_lname,
              profile_image: '',
              tbl_squence: '0',
              user_type: 2,
              user_id: 0,
              isactive: true,
              userid_unique: Date.now().toString(36),
              role_id: 77,
              // contactdata:contactdata,
              ParentUserName : firstName +' '+ lastName,

            }
            return this.createHostUser(addUser)
          } else {
            return of({ status: false, message: "Host already exists." });
          }


        }), mergeMap((data: any, index: number) => {
          if (data && data.status) {
            let body = {
              contact_id: contactdata.contact_id,
              host_ds_id: data.id,
              isActive: true
            }
            return this.addDsHostContactRel(body)
          } else {
            var output;
            if (data.message) {
              output = { status: false, message: data.message }
            } else {
              output = { status: false, message: "Something went wrong" }
            }
            return of(output);
          }
        })
      );
  }


  addingMarketCrossSellCampaignOrder(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "marketCrossSellCampaignOrder", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));

  }

  cancelMarketOrder(id) {
    return this.http.get<any>(this.baseUrl + "updateMarketOrder/" + id, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  rescheduleMarketOrder(id) {

    return this.http.get<any>(this.baseUrl + "rescheduleMarketOrder/" + id, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  cancelEvent(morder_id, event_id): Observable<any> {
    return forkJoin(this.cancelMarketOrder(morder_id), this.cancelEventById(event_id));
  }

  cancelEventById(id) {
    return this.http.get<any>(this.baseUrl + "cancelEvent/" + id, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  private httpHeadersOptions() {
    return this.baseService.httpHeadersOptions();
  }

  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status},` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

  iCloudCreds(dataObj) {
    dataObj.ip = '160.202.39.224';
    return this.http.post<any>(this.baseUrl + "fetchICloudContacts", dataObj, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  iCloudSecurityCode(secCodeObj) {
    secCodeObj.ip = '160.202.39.224';
    return this.http.post<any>(this.baseUrl + "getICloudSecurityCode", secCodeObj, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  /**sync event_info_tbl after event created */
  syncNewEvent(value) {
    var body = value;
    return this.http.post<any>(this.baseUrl + "syncEvent", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  /*** Country state city API call  ***/
  getAllCountries() {
    return this.http.get<any>(this.baseUr2 + "geolocation/getAllCountries", this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  /*** Country state city API call  ***/
  getCurrentCountrie() {

    return this.http.get<any>(this.baseUr2 + "api/ds/getdatabylocation", this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getStatesOfCountry(countryCode: string) {
    return this.http.get<any>(this.baseUr2 + "geolocation/getStatesOfCountry/" + countryCode, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getCitiesOfState(countryCode: string, stateCode: string) {
    return this.http.get<any>(this.baseUr2 + "geolocation/getCitiesOfState/" + countryCode + "/" + stateCode, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  getCountryByCode(countryCode: string) {
    return this.http.get<any>(this.baseUr2 + "geolocation/getCountryByCode/" + countryCode, this.httpHeadersOptions())
      .pipe(
        map((data: any) => {
          data.phonecode = countryTelephoneCode(data.country_iso_code);
          return data;
        }))

  }

  getStateByCode(countryCode: string, stateCode: string) {
    return this.http.get<any>(this.baseUr2 + "geolocation/getStateByCode/" + countryCode + "/" + stateCode, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getCityById(geonameId: any) {
    return this.http.get<any>(this.baseUr2 + "geolocation/getCityById/" + geonameId, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  /*** Country state city API call - END ***/
  recentHostList(body) {
    return this.http.post<any>(this.baseUrl + "recentHostList/", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));


  }

  getTotalcontact(body) {
    return this.http.post<any>(this.baseUrl + "getTotalcontact/", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  //  ###############  Get Attributes for Contact as Per contact ID #####################  //

  getContactAttributes(cid) {
    let URL = this.baseUrl + 'getAttributesByCustomer/' + cid + '/' + 0 + '/' + 10;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  //  ##############  Search for Attributes to be Added for Customer  ########################  //

  searchContactAttributes(cid, name, limit, skip) {
    let URL = this.baseUrl + 'searchAttributes/' + cid + '/' + limit + '/' + limit * skip + '?name=' + name;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  //  ##############  Add Attributes for Customer   #############################  //

  addContactAttributes(body) {
    let URL = this.baseUrl + 'addingAttributeByCustomer';
    return this.http.post<any>(URL, body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  //  ##############  Delete Attribute for Customer  ###########################  //

  deleteContactAttributes(id) {
    let URL = this.baseUrl + 'deleteAttributeByCustomer/' + id;
    return this.http.delete<any>(URL, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  //  ##############  Get Contact Wish List ###################################  //

  getContactWishlist(cid, limit, offset) {
    let URL = this.baseUrl + 'getWishlist/' + cid + '/' + limit + '/' + offset;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));


  }


  //  ###############  Delete Product from Wish List  #########################  //

  deleteProductFromWishlist(id) {
    let URL = this.baseUrl + 'deleteWishlist/' + id;
    return this.http.delete<any>(URL, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  //  ############### Search for Wislist  ####################################  //

  searchProductForWishlist(body, cid, limit, skip) {
    let URL = this.baseUrl + 'searchProduct/' + cid + '/' + limit + '/' + limit * skip;
    return this.http.post<any>(URL, body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));

  }

  //  ############### Add Product to Wishlist  ##############################  //

  addProductToWishlist(body) {
    let URL = this.baseUrl + 'addingWishlist';

    return this.http.post<any>(URL, body, this.httpHeadersOptions())

      .pipe(catchError(this.handleError));
  }



  //  #################  Get Categories List  #############################  //

  getCategoriesList(catid, limit, skip) {
    let URL = this.baseUrl + 'getCategory' + '/' + catid + '/' + limit + '/' + limit * skip;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }



  //  ################  Get Aloowed Length for Notes  #####################  //

  getAllowedLengthForNotes() {
    let URL = this.baseUrl + 'getContactCardlength';

    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  //   ###############  Add Note for Contact  ################  //

  addNoteForContact(body) {
    let URL = this.baseUrl + 'addingContactCard';

    return this.http.post<any>(URL, body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  //  ################  Get Notes for Contact  ##############  //

  getNotesListForContact(cid, limit, skip, sorting, type) {
    if (sorting === null) {
      var URL = this.baseUrl + 'getContactCard' + '/' + cid + '/' + limit + '/' + limit * skip;
    } else {
      var URL = this.baseUrl + 'getContactCard' + '/' + cid + '/' + limit + '/' + limit * skip + '?'
        + sorting + '=' + type;
    }

    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  getContactEventGuestList(id,limit,skip) {
    let URL = this.baseUrl + `getContactEventGuestList/${id}?limit=${limit}&skip=${skip}`;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }


  getPurchaseListById(contactid, limit, skip) {
    let URL = this.baseUrl + 'getContactPurchaseList/' + contactid + '/' + limit + '/' + skip;
    return this.http.get<any>(URL, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  gettingJourneyByContactType(type,id)
  {
    return this.http.get<any>(this.baseUr2 + "api/master/gettingJourneyByContactType/"+type+"/"+id, this.baseService.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  getExistingInvitee(body) {
    return this.http.post<any>(this.baseUrl + "checkInviteeByEmailId", body, this.baseService.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  hardBounceDetail(email)
  {
    return this.http.get<any>(this.baseUrl + "getHardBoundDetail/"+email, this.baseService.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  setSocLinksShareLogs(obj) {
    let URL = this.baseUrl + 'addSocialLinkLog';
    return this.http.post<any>(URL, obj, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }

  sendContactActivationLink(value:any,company:any) {
    var body = value;
    return this.http.post<any>(this.baseUrl + `sendEmailLink/${company}`, body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  sendWelcomeMessage(contact:any) {
    return this.http.get<any>(this.baseUrl + `sendWellcomeMsg/${contact}`,this.httpHeadersOptions())
    .pipe(catchError(this.handleError));
  }
  //Get Media Type Points
  mediaTypePoints() {
    return this.http.get<any>(this.baseUrl + `point/mediatype`,this.httpHeadersOptions())
    .pipe(catchError(this.handleError));
  }
  inviteForWebinar(value) {
    var body = value;
    return this.http.post<any>(this.baseUr2 + "api/webinar/send-contact-invitation", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  getCoutryPhoneCode() {
    return this.http.get<any>(this.baseUr2 + "geolocation/getDialCode", this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  previewThemeTemplate(body:any) {
    return this.http.post<any>(this.baseUrl + "master/previewTemplate",body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  getCorporateEvent(body) {
    // var body = { name: id }
    return this.http.post<any>(this.baseUrl + "getCorporateEvents", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  sendReferral(body){
    return this.http.post<any>(this.baseUrl + "referral/share-referral-code", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  referralDetail(body){
    return this.http.post<any>(this.baseUrl + "referral/total-referral", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  referralLogs(body){
    return this.http.post<any>(this.baseUrl + "referral/ds-referral", body, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  referralByDsData(){
    return this.http.get(this.baseUrl + "referral/refered-by",this.httpHeadersOptions())
    .pipe(catchError(this.handleError));
  }

  smsContactConsent(contact_id:any) {
    return this.http.get<any>(this.baseUr2 + `api/contact-consent/${contact_id}`, this.httpHeadersOptions())
      .pipe(catchError(this.handleError));
  }
  getCampaignActivity(body:any){
    return this.http.post<any>(this.baseUr2 + `api/getCapmaignJourney`, body,this.httpHeadersOptions())
  }
  cancelCampaignUpdate(id:any){
    return this.http.get(this.baseUr2+`api/ds/updateCancelCampaing/${id}`,this.httpHeadersOptions())
  }
  getReadinessActivity(){
    return this.http.get<any>(this.baseUr2 + `api/ds/getReadyness`,this.httpHeadersOptions())
  }
}
