import { Injectable } from '@angular/core';
import {  HttpErrorResponse, HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BaseserviceService } from '../../../services/baseservice.service';
@Injectable({
    providedIn: 'root'
  })
  export class HostService {
    public baseUrl;
    
    constructor(private http: HttpClient, public baseService: BaseserviceService) {       
        this.baseUrl = baseService.baseUrl1 + 'api/host/';
      }

      getHostEventList()
      {
        var body = {};
        return this.http.get<any>(this.baseUrl + "getHostEventList", this.baseService.httpHeadersOptions())
          .pipe(catchError(this.handleError));
      }
      getGusetList(body)
      {        
        return this.http.post<any>(this.baseUrl + "getGusetList",body, this.baseService.httpHeadersOptions())
          .pipe(catchError(this.handleError));
      }
      getGusetByEmailId(body)
      {        
        return this.http.post<any>(this.baseUrl + "getGusetByEmailId",body, this.baseService.httpHeadersOptions())
          .pipe(catchError(this.handleError));
      }
      addingInvitee(value) {
        var body = value;
        return this.http.post<any>(this.baseUrl + "addingInvitee", body, this.baseService.httpHeadersOptions())
          .pipe(catchError(this.handleError));
      }
      deleteInviteeById(value) {
        var body = value;
        return this.http.post<any>(this.baseUrl + "deleteInviteeById", body, this.baseService.httpHeadersOptions())
          .pipe(catchError(this.handleError));
      }

      exportBulk(value) {

        
        var body = value;
        return this.http.post<any>(this.baseUrl + "getBulkData", body, this.baseService.httpHeadersOptions())
          .pipe(catchError(this.handleError));
      }

      updateInviteeStatusById(value) {
        var body = value;
        return this.http.post<any>(this.baseUrl + "updateInviteeStatus", body, this.baseService.httpHeadersOptions())
          .pipe(catchError(this.handleError));
      }

      getDSIdByHostId(customerid, hostid) {
        return this.http.get<any>(this.baseUrl + "dsIdbyHost/" + customerid + '/' + hostid, this.baseService.httpHeadersOptions())
          .pipe(catchError(this.handleError));
      }
    
       //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  };
  }