import { MasterService } from './../../services/master1/master.service';
import { Component, OnInit } from '@angular/core';
import { AuthserviceService } from 'src/app/services/auth/authservice.service';
import { AdminoneService } from 'src/app/services/adminone/adminone.service';
import { BaseComponent } from 'src/app/firstadmin/base.component';
import { Location } from '@angular/common';
import { isMobile } from 'mobile-device-detect';
import { environment } from '../../../environments/environment';
import { LogsService } from 'src/app/services/logs/logs.service';


@Component({
  selector: 'app-agrreduser',
  templateUrl: './agrreduser.component.html',
  styleUrls: ['./agrreduser.component.css']
})
export class AgrreduserComponent extends BaseComponent implements OnInit {

  public userProfileDetails;
  status = false;
  agreed;
  isactive = true;
  selectid;
  image_awskey;
  emsg: "You Already Accepected Terms & Conditions";
  updateAgreement: string;
  isMobileDevice = false;

  contents: any = {};
  projectName = environment.projectName;

  constructor(
    public AuthserviceService: AuthserviceService,
    private adminone: AdminoneService,
    private _location: Location,
    private masterService: MasterService,
    private logs:LogsService
  ) {
    super();
    this.baseService.changeValue(false);
    var name = "Agreement Page View by " + this.loginUserData.user_fname + " " + this.loginUserData.user_lname;
    this.addLogData("Agreement Page View", name);
  }

  ngOnInit() {
    this.userProfileDetails = this.AuthserviceService.UserData.value;
    this.status = true;
    if (isMobile) {
      this.isMobileDevice = true;
    } else {
      this.isMobileDevice = false;
    }

    this.getTermConditions();
    this.captureDsLogs('Agreement Page')
  }

  userDetails() {
    this.AuthserviceService.getUserprofileDetails().subscribe(data => {
      this.status = true;
      this.userProfileDetails = data.data;
    }, error => { });
  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url)) {
        let awsUrl = self.adminone.getUrlfile(url);
        self.image_awskey = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : 'assets/custom/images/profile.png';
      } else {
        event.target.src = 'assets/custom/images/profile.png';
      }
    } else {
      event.target.src = 'assets/custom/images/profile.png';
    }
    event.target.onerror = null;
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  getTermConditions() {
    this.ngxSpinnerService.show();
    this.masterService.getTermConditions().subscribe((data) => {
      if (data.status) {
        this.contents = data.data;
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
    });
  }

  agreedTerms(id) {
    this.selectid = id;
    this.userProfileDetails.is_terms_agreed = true;
    this.userProfileDetails.update_timezone=false;
    this.AuthserviceService.updateDetails(this.userProfileDetails).subscribe(result => {
      if (result.status) {
        this.toastrService.success('Thank you for accepting the Agreement');
        localStorage.setItem("UserData", JSON.stringify(this.userProfileDetails));
        this.AuthserviceService.agreementLogs(true)
        this.router.navigateByUrl("directseller/updatetimezone");
      }
      // if(obj.is_terms_agreed) {
      //  this.updateAgreement = "You Already Accpected Agreed Terms & Conditions"
      // }
    })
  }

  backButtenClick() {
    this._location.back();
    this.AuthserviceService.agreementLogs(false);
  }
  captureDsLogs(title:any){
    let body={
      "accessed_url" : this.router.url,
      "log_name" :title,
      "customer_cookies" :(new Date).getTime(),
      "user_id":this.userProfileDetails.ds_id
    }
    this.logs.captureWebsiteDsLogs(body,this.userProfileDetails?.client_id).subscribe()
  }
}
