import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ReskinningService } from 'src/app/services/reskinning/reskinning.service';
import { ConfirmationDialogService } from 'src/app/_utils/confirmation-dialog/confirmation-dialog.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-reskinning',
  templateUrl: './reskinning.component.html',
  styleUrls: ['./reskinning.component.css']
})
export class ReskinningComponent extends BaseComponent  implements OnInit {

  public themeObj: any = [];
  defaultPicture = "assets/custom/images/broken.png";
  cp = 1;
  limit = 10;
  skip = 0;
  totalCount = 0;
  ord = "asc";
  colname = "product_name";
  checked = false;
  getCompanyobj;
  currentUser;
  companyID = 0;
  disCompId: any = 0;
  @ViewChild("searchText", { static: true }) searchText: ElementRef;

  today: number = Date.now();
  constructor(
    private master: ReskinningService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    super();
  }
  ngOnInit() {
    if (localStorage.getItem("UserData")) {
      var userdata = JSON.parse(localStorage.getItem("UserData"));
      this.currentUser = userdata;
      if (this.currentUser.user_customer_id) {
        this.companyID = this.currentUser.user_customer_id;
        this.getReskinningList();
      }
    }
  }
  order: string = "get_master_theme.theme_name";
  reverse: boolean = false;

  sorting(col: string) {
    if (this.companyID != 0) {
      this.colname = col;
      if (this.ord == "asc") {
        this.ord = "desc";
      } else {
        this.ord = "asc";
      }
      // this.getReskinningList();
    } else {
      this.toastrService.error("Please select company");
    }
  }

  getReskinningList() {
    if (this.companyID == 0) {
      return;
    }
    let self = this;
    this.skip = (this.cp - 1) * this.limit;
    this.ngxSpinnerService.show();
    this.master
      .getReskinnings(
        this.companyID,

        this.currentUser.user_ds_id,

        this.limit,
        this.skip
      )
      .subscribe(
        (response) => {
          this.ngxSpinnerService.hide();
          self.themeObj = response[0]
          if(self.themeObj){
            localStorage.setItem("ReskinningData", JSON.stringify(self.themeObj));
          }
          if(localStorage.getItem('reskinningReload')=='true'){
            localStorage.removeItem('reskinningReload');
            window.location.reload();
          }
        },
        (error) => {
          self.themeObj=undefined;
          this.ngxSpinnerService.hide();
        }
      );
  }

  edit(id) {
    this.router.navigate(['/directseller/addReskinning'], { queryParams: { companyID: this.companyID, them_id: id } });
  }

  // deleteCatalog(event, id) {
  //   event.preventDefault();
  //   let self = this;
  //   let obj = this.themeObj.find((element) => {
  //     if (element.product_catalog_id === id) {
  //       return true;
  //     }
  //   });

  //   this.confirmationDialogService
  //     .confirm(
  //       "Please confirm",
  //       "ARE YOU SURE YOU WANT TO DELETE ?",
  //       "YES",
  //       "NO"
  //     )
  //     .then((confirmed) => {
  //       if (confirmed) {
  //         this.ngxSpinnerService.show();
  //         let body = {
  //           company: this.companyID,
  //           p_cid: obj.product_catalog_id,
  //           isdelete: !obj.isdelete,
  //         };
  //         obj.isdelete = !obj.isdelete;
  //         obj.company = this.companyID;
  //         this.master.UpdateCatalogStatus(body).subscribe((data) => {
  //           self.getProductCatalog(this.companyID);
  //           this.toastrService.success(
  //             "Product Catalogue delete Successfully "
  //           );
  //           this.ngxSpinnerService.hide();
  //         });
  //       } else {
  //         this.toastrService.error(" error : update catalogue");
  //       }
  //     })
  //     .catch((err) => {});
  // }

  // updateStatus(event, id) {
  //   event.preventDefault();
  //   let obj = this.themeObj.find((element) => {
  //     if (element.product_catalog_id === id) {
  //       return true;
  //     }
  //   });

  //   this.confirmationDialogService
  //     .confirm(
  //       "Please confirm",
  //       "Are you sure you want to change the status?",
  //       "YES",
  //       "NO"
  //     )
  //     .then((confirmed) => {
  //       if (confirmed) {
  //         this.ngxSpinnerService.show();
  //         let body = {
  //           company: this.companyID,
  //           p_cid: obj.product_catalog_id,
  //           isactive: !obj.isactive,
  //         };
  //         obj.isactive = !obj.isactive;
  //         obj.company = this.companyID;
  //         this.master.UpdateCatalogStatus(body).subscribe((data) => {
  //           this.toastrService.success(
  //             "Product Catalogue Status Changed Successfully "
  //           );
  //           this.ngxSpinnerService.hide();
  //         });
  //       } else {
  //         this.toastrService.error(" error : update catalogue");
  //       }
  //     })
  //     .catch((err) => {
  //       this.toastrService.error(err);
  //     });
  // }
  getProductCatalog(id) {
    // this.ngxSpinnerService.show();
    if (id != 0) {
      this.companyID = id;
      // this.getReskinningList();
    } else {
      this.themeObj = [];
      this.totalCount = 0;
      this.cp = 1;
      // this.ngxSpinnerService.hide();
    }
  }

}
