<style type="text/css">
  .error-main{
  background-color: #fff;
  box-shadow: 0px 10px 10px -10px #5D6572;
}
.error-main h1{
  font-weight: bold;
  color: #444444;
  font-size: 100px;
  text-shadow: 2px 4px 5px #6E6E6E;
}
.error-main h6{
  color: #42494F;
}
.error-main p{
  color: #9897A0;
  font-size: 14px; 
}  
@media only screen and (max-width: 640px) {
    .error-main h1 {
    font-size: 50px;}
}
</style>
<div class="container h-100">
    <div class="row h-100 justify-content-center align-items-center text-center">
      <div class="col-lg-6 col-sm-8 offset-lg-4 col-9 p-3 error-main">
        <div class="row">
          <div class="col-lg-8 col-12 col-sm-10 offset-lg-2 offset-sm-1">
            <h1 class="mb-2 text-responsive">403</h1>
            <h4 class="text-info font-weight-bold text-uppercase">Not Authorised</h4>
            <p>Sorry, looks like we sent you the wrong way. Let us guide you back.</p>

            <div class="rtnBack">
              <p>
                Return back to <a href="#"  [routerLink]="['/directseller/home']"><span><</span>Home<span>></span></a> or <a href="javascript:void(0)" (click)="goBack()"><span><</span>Previous page<span>></span></a>
              </p>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>

