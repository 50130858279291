import { Injectable } from '@angular/core';
import {  HttpErrorResponse, HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError,BehaviorSubject } from 'rxjs';
import { BaseserviceService } from '../../services/baseservice.service';
@Injectable({
    providedIn: 'root'
  })
  export class BookmarkService {
    public baseUrl;
   
    constructor(private http: HttpClient, public baseService: BaseserviceService) {       
        this.baseUrl = baseService.baseUrl1 + 'api/bookmark/';
      }
      getContactsBookmarkList(body)
      {        
        return this.http.post<any>(this.baseUrl + "getContactsBookmarkList",body, this.baseService.httpHeadersOptions())
          .pipe(
            map(data=>{
            if(!data.status)
            {
              if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
              {
                console.error(data.message);
                data.message="Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
      }
      
      addingContactToBookmark(value) {
        var body = value;
        return this.http.post<any>(this.baseUrl + "addingContactToBookmark/", body, this.baseService.httpHeadersOptions())
          .pipe( map(data=>{
            if(!data.status)
            {
              if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
              {
                console.error(data.message);
                data.message="Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
      }
     
deleteContactFromBookMarkById(bookmarkid)
{
  var body ={"bookmarkid":bookmarkid} ;
        return this.http.post<any>(this.baseUrl + "deleteContactFromBookMarkById/", body, this.baseService.httpHeadersOptions())
          .pipe( map(data=>{
            if(!data.status)
            {
              if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
              {
                console.error(data.message);
                data.message="Something went wrong.Please try again."
              }
            }
            return data;
          }),
          catchError(this.handleError));
}
       //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  };
  }