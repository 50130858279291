<div class="container">
  <form [formGroup]="profileForm" (ngSubmit)="updateProfile()">
    <div class="row">
      <div class="col-xl-10">
        <h4 class="mb-1 font-weight-bold float-left">Basic Information</h4>
      </div>
    </div>

    <div class="clearfix"></div>

    <div class="row">
      <div class="col-xl-10">
        <div class="card">
          <div class="card-body">
            <div class="row" *ngIf="userProfileDetails">
              <div class="col-12 mt-1 mt-md-0">
                <div class="row mt-1">
                  <div class="col-sm-3 font-weight-bold">Name :</div>
                  <div class="col-sm-9">
                    {{
                      userProfileDetails.user_fname +
                        " " +
                        userProfileDetails.user_lname | titlecase
                    }}
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-sm-3 font-weight-bold">Username :</div>
                  <div class="col-sm-9">{{ userProfileDetails?.username }}</div>
                </div>
                <div class="row mt-1">
                  <div class="col-sm-3 font-weight-bold">Email Address :</div>
                  <div class="col-sm-9">
                    {{ userProfileDetails?.user_email_primary }}
                  </div>
                </div>
                <div
                  class="row mt-1"
                  *ngIf="userProfileDetails?.role_id !== 77"
                >
                  <div class="col-sm-3 font-weight-bold">
                    Subscription Type :
                  </div>
                  <div
                    *ngIf="
                      userProfileDetails?.subscriptionname &&
                      userProfileDetails?.sub_end_date
                    "
                    class="col-sm-9"
                  >
                    {{ userProfileDetails?.subscriptionname }} ({{
                      insertDateAt(
                        userProfileDetails?.sub_end_date
                          | date : "MMM d, y AT h:mm"
                      )
                    }}
                    {{
                      userProfileDetails?.sub_end_date | date : "a" | lowercase
                    }})
                  </div>
                  <div
                    *ngIf="
                      !userProfileDetails?.subscriptionname ||
                      !userProfileDetails?.sub_end_date
                    "
                    class="col-sm-9"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- DS Profile Preferences -->
    <h4 class="mb-1 font-weight-bold" *ngIf="userProfileDetails">
      DS Profile Preferences
    </h4>
    <div class="row" *ngIf="userProfileDetails">
      <div class="col-xl-10">
        <div class="card">
          <div class="card-body">
            <div class="row col-12">
              <div class="col-xl-6 col-md-6 mt-1">
                <div class="form-group">
                  <label class="required">Target Audience Time Zone:</label>
                  <br />
                  <mat-form-field class="max_width">
                    <mat-select
                      class="form-control"
                      formControlName="user_time_zone"
                      [(ngModel)]="timezone"
                      #singleSelect
                      [ngClass]="{
                        'is-invalid': submitted && f.user_time_zone.errors
                      }"
                    >
                      <mat-select-search
                        [formControl]="timezoneFilterCtrl"
                        placeholderLabel="Search...."
                        noEntriesFoundLabel="No Such TimeZone Found"
                      >
                      </mat-select-search>
                      <mat-option
                        *ngFor="let timezonedata of filteredTimezones | async"
                        [value]="timezonedata?.value"
                      >
                        {{ timezonedata?.text }}
                      </mat-option>
                    </mat-select>
                    <div
                      *ngIf="submitted && f.user_time_zone.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.user_time_zone.errors.required">
                        Time zone is required
                      </div>
                    </div>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Business Domain Categories -->
    <h4
      class="mb-1 font-weight-bold required"
      *ngIf="userProfileDetails && userProfileDetails?.role_id !== 77"
    >
      Assets Type
    </h4>
    <div
      class="row"
      *ngIf="userProfileDetails && userProfileDetails?.role_id !== 77"
    >
      <div class="col-xl-10">
        <div class="">
          <div class="">
            <div class="row journey-cards">
              <ng-container>
                <!-- Domain List -->
                <div class="col-xl-12 col-md-12 domain-cards">
                  <!-- <label class="required">Your Business Categories:</label> -->
                  <div
                    *ngIf="submitted && selected_business_domain.length < 1"
                    class="error-text"
                  >
                    <div>Campaign assets is required</div>
                  </div>
                  <div class="col-sm-12 jc-card-wrap p-0 mb-3">
                    <label
                      for="{{ domain.id }}"
                      *ngFor="let domain of businessDomainData"
                      class="card custom-checkbox"
                      [ngClass]="{ active: checkselection(domain.id) }"
                    >
                      <div class="form-check">
                        <input
                          (change)="onDomainchange(domain.id, $event)"
                          class="form-check-input"
                          type="checkbox"
                          [name]="domain.domain_name"
                          [id]="domain?.id"
                          [value]="domain.id"
                          [checked]="checkselection(domain.id)"
                        />
                        <span class="checkmark"></span>
                      </div>
                      <img
                        class="card-icon"
                        [src]="domain?.domain_image"
                        alt="{{ domain.domain_name }}"
                        (error)="setValue($event)"
                      />
                      <p class="card-intro">{{ domain.domain_name }}</p>
                    </label>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Sender Profile Settings -->
    <h4
      class="mb-1 font-weight-bold"
      *ngIf="userProfileDetails && userProfileDetails?.role_id !== 77"
    >
      Sender Profile Settings
    </h4>
    <div
      class="row"
      *ngIf="userProfileDetails && userProfileDetails?.role_id !== 77"
    >
      <div class="col-xl-10">
        <div class="card">
          <div class="card-body">
            <div class="row col-12">
              <div class="col-12 mb-1">
                <h4>Select Sender Email</h4>
              </div>
              <div class="col-12">
                <label class="custRadioLeft d-inline-block ml-1">
                  <input
                    [(ngModel)]="selectedOption"
                    type="radio"
                    name="is_margo_domain"
                    formControlName="is_margo_domain"
                    (click)="resetDomainValue('false')"
                    value="false"
                  />
                  My Work Email
                  <i
                    class="fa fa-question-circle text-info"
                    aria-hidden="true"
                    title="Please enter a valid work email address. Personal email providers like Gmail, Yahoo, and similar domains are not supported."
                  ></i>
                  <span class="radiomarkLeft"></span>
                </label>

                <label class="custRadioLeft d-inline-block ml-1">
                  <input
                    [(ngModel)]="selectedOption"
                    type="radio"
                    name="is_margo_domain"
                    formControlName="is_margo_domain"
                    (click)="resetDomainValue('true')"
                    value="true"
                  />
                  marGo Email
                  <span class="radiomarkLeft"></span>
                </label>
              </div>
            </div>
            <div class="row col-12" *ngIf="f.is_margo_domain.value == 'true'">
              <div class="col-xl-6 col-md-6 mt-1">
                <div class="form-group">
                  <label>Select marGo Email</label>

                  <select
                    name="sender_email_domain_name"
                    id="sender_email_domain_name"
                    class="form-control"
                    formControlName="sender_email_domain_name"
                    (change)="changeEmailDomain(); OnsenderInputBlur($event)"
                  >
                    <option selected disabled value="">Select</option>
                    <option
                      [value]="domain?.emaildomain"
                      *ngFor="let domain of margoDomain"
                    >
                      {{ domain?.emaildomain }}
                    </option>
                  </select>
                  <div
                    *ngIf="submitted && f.sender_email_domain_name.errors"
                    class="error-text"
                  >
                    <div *ngIf="f.sender_email_domain_name.errors.required">
                      marGo domain is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ng-container
              *ngIf="
                f.is_margo_domain.value == 'false' ||
                (f.is_margo_domain.value == 'true' &&
                  f.sender_email_domain_name.value)
              "
            >
              <div class="row col-12">
                <div class="col-xl-6 col-md-6 mt-1">
                  <div class="form-group">
                    <label>Sender Name:</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="sender_name"
                      (keyup)="
                        OnsenderInputBlur($event); updateSenderEmail($event)
                      "
                    />
                    <div
                      *ngIf="submitted && f.sender_name.errors"
                      class="error-text"
                    >
                      <div *ngIf="f.sender_name.errors.pattern">
                        Sender name must be a valid name
                      </div>
                      <div *ngIf="f.sender_name.errors.required">
                        Sender name is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="row col-12">
                <div class="col-xl-6 col-md-6 mt-1">
                  <div class="form-group" *ngIf="f.is_margo_domain.value=='false'">
                    <label>Email Domain Name:</label>
                    <input type="text" class="form-control" formControlName="sender_email_domain_name"
                      (keyup)="OnsenderInputBlur($event)">
                    <div *ngIf="submitted && f.sender_email_domain_name.errors" class="error-text">
                      <div *ngIf="f.sender_email_domain_name.errors.required">Email domain name is required</div>
                    </div>
                  </div>
                  <div *ngIf="f.is_margo_domain.value=='true'">
                    <label>Email Domain</label>
                    <input type="email" autocomplete="off" class="form-control"
                        [value]="f.sender_email_domain_name.value" readonly/>
                </div>
                </div>
              </div> -->
              <div class="row col-12">
                <div class="col-xl-6 col-md-6 mt-1">
                  <div class="form-group">
                    <label>Email Address:</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="sender_email_address"
                      (keyup)="OnsenderInputBlur($event)"
                      [readonly]="f.is_margo_domain.value == 'true'"
                    />
                    <div
                      *ngIf="submitted && f.sender_email_address.errors"
                      class="error-text"
                    >
                      <div *ngIf="f.sender_email_address.errors.pattern">
                        Email must be a valid email address
                      </div>
                      <div *ngIf="f.sender_email_address.errors.required">
                        Email address is required
                      </div>
                      <div *ngIf="f.sender_email_address.errors.restrictEmail">
                        Please use a professional email address, as personal
                        emails are not permitted.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-10">
        <div class="verify-steps">
          <h2>
            We need to verify that you have the authority to use the email
            address provided.
          </h2>
          <h3>Next Steps:</h3>
          <ul>
            <li
              [ngClass]="'step-' + (i + 1)"
              *ngFor="let step of verifyNextStep; let i = index"
            >
              <span [ngClass]="'step-' + (i + 1)">0{{ i + 1 }}</span>
              {{ step }}
            </li>
          </ul>
          <h3>Action Required:</h3>
          <ul>
            <li
              [ngClass]="'step-' + (i + 4)"
              *ngFor="let actionStep of verifyActionRqrd; let i = index"
            >
              <span [ngClass]="'step-' + (i + 4)">0{{ i + 1 }}</span>
              {{ actionStep }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Quick Message Settings -->
    <!-- <h4 class="mb-1 font-weight-bold" *ngIf="userProfileDetails && userProfileDetails?.role_id!==77" >Quick Message Settings</h4> -->
    <!-- <div class="row" *ngIf="userProfileDetails && userProfileDetails?.role_id!==77" >
      <div class="col-xl-10">
        <div class="card">
          <div class="card-body">
            <div class="row col-12">
              <div class="col-xl-6 col-md-6 mt-1">
                <div class="form-group">
                  <label>SMS / MMS Sender Virtual Number:</label>
                  <select name="ds_sender_number" id="ds_sender_number"  class="form-control" formControlName="ds_sender_number" >
                       <option selected disabled value="">Select</option>
                       <option [value]="num?.phone_no" *ngFor="let num of dsAvailableSenderNumbers">+{{num?.phone_no}}</option>
                  </select>
                  <div *ngIf="submitted && f.ds_sender_number.errors" class="error-text">
                    <div *ngIf="f.ds_sender_number.errors.required">SMS / MMS Sender Virtual Number is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row col-12">
              <div class="col-xl-6 col-md-6 mt-1">
                <div class="form-group">
                  <label>WhatsApp Sender Number:</label>
                  <select name="sender_whatsapp_number" id="sender_whatsapp_number"  class="form-control" formControlName="sender_whatsapp_number" >
                    <option selected value="">+13312597525</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row col-12">
              <div class="col-xl-6 col-md-6 mt-1">
                <div class="form-group">
                  <label>Facebook / Instagram ID:</label>
                  <select name="fb_auth_connection_process" id="fb_auth_connection_process"  class="form-control" formControlName="fb_auth_connection_process" >
                    <option selected value="">107506095749859</option>
                  </select>
                </div>
              </div>
            </div> -->

    <!-- Instagram Messenger ID:  -->
    <!-- <div class="col-xl-12 col-md-12 mt-1">
                            <h6 class="font-weight-bold">Instagram Messenger ID:</h6>
                          </div>
                          <div class="col-xl-6 col-md-6 mt-1">
                            <div class="form-group">
                              <label>Instagram Auth Connection Process:</label>
                              <input type="text" class="form-control" formControlName="insta_auth_connection_process">
                            </div>
                          </div> -->

    <!-- </div>
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-xl-10">
        <div class="form-group text-center mt-1">
          <button
            type="submit"
            class="btn btn-round btn-margo-action"
            [disabled]="submitButton"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
