import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class ReskinningService {
  rskHeaderBackgroundColor: string;
  rskIconColor: string;
  rskLogo: string;
  rskUrl: string;
  rskMargoLogoStatus: boolean;
  rskPictureOutlineColor: string;
  rskscrollbgcolor: string;
  teamMember: string;
  contact_heading_text: string;
  body_text_color: string;
  dashboard_text_color :string;
  rskBodyText: string;
  rskTabColor: string;
  rskTabTextColor: string;
  rskButtonColor: string;
  rskButtonTextColor: string;
  rskPageContentColor:string;
  rskHomePageButton1:string;
  rskHomePageButton1_url:string;
  rskHomePageButton2:string;
  rskHomePageButton2_url:string;
  rskEventFlag:boolean=true;
  rskProfile:boolean=false;
  rskModelLogoFlag:boolean=true;
  rskModelLogo:string;
  rskSmsService:string;
  rskDsSenderNumber:any;
  rskDsClickSendNumber:any;
  rskDsSenderWhatsappNumber:any;
  rskDsSenderMessengerNumber:any;
  rskLogoHeight:string;
  dsCurrency:any;



  constructor(private http: HttpClient) {
    // Reskinning css from local storage
    var reskinningObj = JSON.parse(localStorage.getItem('ReskinningData'));
    if (reskinningObj != null) {
      // let reskinningObj = JSON.parse(localStorage.getItem('ReskinningData'));


      this.rskscrollbgcolor = reskinningObj.scroll_color || '#019dac';
      this.teamMember = reskinningObj.team_heading_text || 'Team Member';
      this.contact_heading_text = reskinningObj.contact_heading_text || 'Contact';
      this.body_text_color = reskinningObj.body_text_color || '#393636';
      this.rskHeaderBackgroundColor = reskinningObj.header_background_color || '#fff';
      this.rskIconColor = reskinningObj.icon_color || '#009fae';
      this.rskLogo = reskinningObj.logo || '';
      this.rskUrl = reskinningObj.url || '';
      this.rskMargoLogoStatus = reskinningObj.logo_status;
      this.rskPictureOutlineColor = reskinningObj.picture_outline_color || '#019dac';
      this.rskBodyText = reskinningObj.ds_alias_name || 'How can marGo help today ?';
      this.rskTabColor = reskinningObj.tab_color || '#019dac';
      this.rskTabTextColor = reskinningObj.tab_text_color || '#fff';
      this.rskButtonColor = reskinningObj.button_color || '009fae';
      this.rskButtonTextColor = reskinningObj.button_text_color || '#fff';
      this.dashboard_text_color = reskinningObj.dashboard_text || '#000';
      this.rskPageContentColor = reskinningObj.page_content_font_color || '#006da7';
      this.rskHomePageButton1 = reskinningObj.home_button_1 || '';
      this.rskHomePageButton2 = reskinningObj.home_button_2 || '';
      this.rskHomePageButton1_url = reskinningObj.home_button_url_1 || '';
      this.rskHomePageButton2_url = reskinningObj.home_button_url_2 || '';
      this.rskEventFlag = reskinningObj.event_flag;
      this.rskProfile = reskinningObj.profile_obj;
      this.rskModelLogoFlag = reskinningObj.model_logo_flag;
      this.rskModelLogo = reskinningObj.model_logo;
      this.rskSmsService = reskinningObj.sms_service || 'vonage';
      this.rskDsSenderNumber = reskinningObj.ds_sender_number || '';
      this.rskDsClickSendNumber = reskinningObj.ds_clicksend_number || '';    

      this.rskDsSenderWhatsappNumber = reskinningObj.ds_sender_whatsapp_number || '';
      this.rskDsSenderMessengerNumber = reskinningObj.ds_sender_messenger_number || '';

      this.rskLogoHeight = reskinningObj.logo_height;

      this.dsCurrency=reskinningObj.currency || '$'

    } else {

      this.rskscrollbgcolor = '#019dac';
      this.teamMember = 'Team Member';
      this.contact_heading_text = 'Contact';
      this.body_text_color = '#393636';
      this.rskHeaderBackgroundColor = '#fff';
      this.rskIconColor = '#009fae';
      this.rskLogo = environment.s3URL+'master_images/lfwb5dtn.png';
      this.rskUrl = '';
      this.rskMargoLogoStatus = true;
      this.rskPictureOutlineColor = '#019dac';
      this.rskBodyText = 'How can marGo help today ?';
      this.rskTabColor = '#019dac';
      this.rskTabTextColor = '#fff';
      this.rskButtonColor = '009fae';
      this.rskButtonTextColor = '#fff';
      this.dashboard_text_color = '#000';
      this.rskPageContentColor = '#006da7';
      this.rskHomePageButton1 = '';
      this.rskHomePageButton2 = '';
      this.rskHomePageButton1_url = '';
      this.rskHomePageButton2_url = '';
      this.rskEventFlag = false;
      this.rskProfile = false;
      this.rskModelLogoFlag = true;
      this.rskModelLogo = environment.s3URL+'master_images/l1ykjyml.png';
      this.rskSmsService = 'vonage';
      this.rskDsSenderNumber = '';
      this.rskDsClickSendNumber = '';
      this.rskDsSenderWhatsappNumber = '';
      this.rskDsSenderMessengerNumber = '';
      this.dsCurrency='$'
    }
  }


  getCountryCodes(): Observable<any> {
    return this.http.get('assets/CountryCodes.json');
  }



}
