<div *ngIf="!securityCode; then creds else secCode"></div>
<ng-template #creds let-modal>
  <form name="frmAppCreds" (submit)="fetchICloudContacts()">
    <div class="modal-header dialHead d-block">
      <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}"/>
      <button type="button" class="close float-right" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="clearfix"></div>
    </div>
    <div class="modal-body pt-1">
      <h5 class="modal-title w-100 text-center">iCloud CREDENTIALS</h5>
      <div class="form-group">
        <label class="required">APPLE ID</label>
        <input type="text"  autocomplete="off" placeholder="Apple ID" required ngbAutofocus class="form-control"  [(ngModel)]="appleId" name="appleId" [ngModelOptions]="{standalone: true}" />
      </div>
      <div class="form-group">
        <label class="required">PASSWORD</label>
        <input type="password"  autocomplete="off" required placeholder="Password" class="form-control"  [(ngModel)]="password" name="password" [ngModelOptions]="{standalone: true}" />
      </div>
    </div>
    <div class="modal-footer text-center d-block border-0">
      <button type="submit" class="btn btn-margo-action btn-round text-uppercase">SUBMIT</button>&nbsp;
      <button type="button" class="btn btn-margo-cancel btn-round text-uppercase" (click)="dismiss()">CLOSE</button>
      
    </div>
  </form>
</ng-template>

<ng-template #secCode let-modal>
  <div class="modal-header dialHead d-block">
    <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}"/>
    <button type="button" class="close float-right" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="clearfix"></div>
  </div>
  <form name="frmAppCreds" (submit)="iCloudSecurityCode()">
    <div class="modal-body">
      <h4 class="modal-title">ICLOUD SECURITY CODE</h4>
      <div class="form-group">
        <label class="required">SECURITY CODE</label>
        <input type="text"  autocomplete="off" placeholder="Security Code" ngbAutofocus required class="form-control"  [(ngModel)]="securityCodeVal" name="securityCode" [ngModelOptions]="{standalone: true}" />
      </div>
    </div>
    <div class="modal-footer text-center d-block border-0">
      
      <button type="submit" class="btn btn-margo-action btn-round text-uppercase">SUBMIT</button>&nbsp;
      <button type="button" class="btn btn-margo-cancel btn-round text-uppercase" (click)="dismiss()">CLOSE</button>
    </div>
  </form>
</ng-template>