import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/firstadmin/base.component';
import { isMobile } from 'mobile-device-detect';
import { environment } from 'src/environments/environment';
import { AuthserviceService } from 'src/app/services/auth/authservice.service';
import { AdminoneService } from 'src/app/services/adminone/adminone.service';
import { MasterService } from 'src/app/services/master1/master.service';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.css']
})
export class AgreementComponent extends BaseComponent implements OnInit {
  isMobileDevice = false;
  userProfileDetails:any;
  image_awskey:any;
  selectid:any;
  contents: any = {};
  currentDate;

  constructor(
    public AuthserviceService: AuthserviceService,
    private adminone: AdminoneService,
    private masterService: MasterService) {
    super();
    this.baseService.changeValue(false);
  }
  projectName = environment.projectName;


  ngOnInit() {
    this.userProfileDetails = this.AuthserviceService.UserData.value;
    if (isMobile) {
      this.isMobileDevice = true;
    }
    else {
      this.isMobileDevice = false;
    }
    this.getTermConditions();
  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url) ) {
        let awsUrl = self.adminone.getUrlfile(url);
        self.image_awskey = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : 'assets/custom/images/profile.png';
      } else {
        event.target.src = 'assets/custom/images/profile.png';
      }
    } else {
      event.target.src = 'assets/custom/images/profile.png';
    }
    // event.target.src = 'assets/custom/images/profile.png';
    event.target.onerror = null;
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  getTermConditions() {
    this.ngxSpinnerService.show();
    this.masterService.getTermConditions().subscribe((data) => {
      if (data.status) {
        this.contents = data.data;

        this.currentDate = this.contents.last_revised_date;
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
    });
  }

  agreedTerms(id) {
    this.selectid = id;
    this.userProfileDetails.is_terms_agreed=true;
    this.userProfileDetails.update_timezone=false;
    this.AuthserviceService.updateDetails(this.userProfileDetails).subscribe(result => {
      if (result.status) {
        this.toastrService.success('Agreed Terms & Conditions');
        this.router.navigateByUrl("directseller/home");
        localStorage.setItem("UserData", JSON.stringify(this.userProfileDetails));
      }
    })
  }
  onback(){
    this.router.navigateByUrl('/');
  }
}
