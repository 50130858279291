import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import jwt_decode from "jwt-decode";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthserviceService } from "../services/auth/authservice.service";
import { environment } from "src/environments/environment";
import { LogsService } from "../services/logs/logs.service";

@Component({
  selector: "app-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.css"],
})
export class VerifyEmailComponent implements OnInit {
  token: any;
  companyId=environment.companyId;
  isTokenExpired=false;
  isAccountVerified=false;
  emailAlreadyReg=false;
  dsId: any;
  isEmailResend=false;
  isTokenInvalid=false;
  constructor(
    private route: ActivatedRoute,
    private ngxSpinner: NgxSpinnerService,
    private clientService: AuthserviceService,
    private router:Router,
    private logs:LogsService
  ) {}
  ngOnInit() {
    this.token = this.route.snapshot.params["token"];
    this._checkTokenAndVerify(this.token);
  }
  _checkTokenAndVerify(token: any) {
    if (token) {
      if (this.isJWT(token)) {
        try {
          let decoded: any = jwt_decode(token);
          this.dsId=decoded.id;
          this.captureDsLogs()
          if (!this._tokenExpired(decoded.exp)) {
            if (decoded.id) {
              this._verifyToken(decoded.id);
            }
          } else {
            this.isTokenExpired=true
          }
        } catch (error) {
          if(error && error.name=='InvalidTokenError'){
            this.isTokenInvalid=true;
          }
        }
      }else{
        this.isTokenInvalid=true;
      }
    }
  }
  isJWT(jwt: any) {
    return String(jwt).match(
      /eyJ[A-Za-z0-9-_]+\.eyJ[A-Za-z0-9-_]+\.[A-Za-z0-9-_.+/]*/g
    );
  }
  private _tokenExpired(expiration: number): boolean {
    return Math.floor(new Date().getTime() / 1000) >= expiration;
  }
  _verifyToken(dsId: any) {
    let body = {
      id: dsId,
    };
    this.ngxSpinner.show();
    this.clientService.checkTokenVerify(body,this.companyId).subscribe({
      next: (res: any) => {
        this.ngxSpinner.hide();
        if (res.status == true) {
          this.isTokenExpired=false;
          this.isAccountVerified=true;

        } else {
          this.isAccountVerified=false;
          this.isTokenExpired=true;
          if(res.message==='Email id already verified'){
            this.emailAlreadyReg = true;
            this.isTokenExpired=false;
            this.isAccountVerified=false;
          }
        }
      },
      error: (error: any) => {
        this.isAccountVerified=false;
        this.ngxSpinner.hide();
      },
    });
  }
  resendEmail(){
    let body = {
      id: this.dsId,
    };
    this.ngxSpinner.show();
    this.clientService.resendVerify(body,this.companyId).subscribe({
      next: (res: any) => {
        this.ngxSpinner.hide();
        if (res.status == true) {
          this.isEmailResend=true;
        } else {
          this.isEmailResend=false;
        }
      },
      error: (error: any) => {
        this.isEmailResend=false;
        this.ngxSpinner.hide();
      },
    });
  }
  captureDsLogs(){
    let body={
      "accessed_url" : this.router.url,
      "log_name" :'Verify Email',
      "customer_cookies" :(new Date).getTime(),
      "user_id":this.dsId
  }
    this.logs.captureWebsiteDsLogs(body,this.companyId).subscribe()
  }
}
