<!-- <div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4> 
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h4 class="modal-title">{{ title }}</h4>
  <div> </div>
  <label>
    Timer Type
  </label>
  <select [(ngModel)]='timerType'>
    <option value="date">Date</option>
    <option value="hour">Hour</option>
    <option value="minute">Minute</option>
  </select>
  <label>
    Interval
  </label>
  <input type="text" [(ngModel)]='interval' placeholder="10">
  <div></div>



</div>
<hr>
<div class="modal-footer">
  <button type="button" class="btn vl" (click)="accept()">{{ btnOkText }}</button>
  <button type="button" class="btn vl" (click)="decline()">{{ btnCancelText }}</button>
</div>
 -->
 <div class="scroller">
  <div class="wholePop filtMain">
    <div class="modal-header dialHead text-center d-block mb-1">
    <app-model-logo></app-model-logo>
    <!-- <app-model-logo></app-model-logo> -->
    <!-- <img *ngIf="rskmodellogoFlag" src="{{rskmodellogo}}"/> -->
  <!-- <button type="button" class="close" aria-label="Close"  (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button> -->
 <!--  <h5>Opt-in Agreement</h5> -->
 
    	</div> 

  <div class="modal-body text-center">
    <h4 class="modal-title">{{ title }}</h4>
    <h4 class="modal-title pl-1 pr-1">{{ message }}</h4>
  </div>
  <div class="modal-footer text-center d-block border-0">
    <button type="button" class="btn btn-margo-action btn-round" appThrottleClick throttleTime="7000" (click)="accept()">{{ btnOkText }}</button>
    <button type="button" class="btn btn-margo-cancel btn-round" (click)="decline()">{{ btnCancelText }}</button>
  </div>
 </div>
</div>