import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { MasterService } from 'src/app/core/services/master/master.service';
import csc from 'country-state-city';
import { BaseComponent } from '../../../firstadmin/base.component';
import { Contact, getContactTypeSpec } from '../../../firstadmin/Models/Contact';
import { forkJoin, of } from 'rxjs';
import { DatePipe } from '@angular/common';
import { PhoneValidator } from '../../validators/phone.validator';
import { parseIncompletePhoneNumber } from 'libphonenumber-js/min';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HostService } from 'src/app/firstadmin/host/services/host.service';
import { environment } from '../../../../environments/environment';
import { MetaService } from 'src/app/services/meta/meta.service';
import { ReskinningService } from 'src/app/_services/reskinning.service';
import { ConfirmationDialogService } from 'src/app/_utils/confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-create-contact',
  templateUrl: './create-contact.component.html',
  styleUrls: ['./create-contact.component.css']
})
export class CreateContactComponent extends BaseComponent implements OnInit, OnDestroy {
  contactForm: UntypedFormGroup;
  objContact: Contact = new Contact();
  submitted = false;
  contacttypeid;
  contacttypename;
  stateData: any = [];
  cityData: any = [];
  state;
  contact_id;
  countriesData: any = [];
  errmsg;
  address1;
  agerange;
  maritalStatus;
  profession;
  sex: any = [];
  customerType: any = ['Regular (Non Wine Club Member)','Preferred Customer (Wine Club Member)'];
  custType:any;
  loyality;
  phonecode;
  hosttype;
  maxdate;
  mindate = new Date('Jan 1, 1920');
  contact_phone_other;
  contact_phone_type;
  contact_address_2;
  selectedCountryObj: any;
  formatPhone = '';
  heading = '';
  contactListByPhoneNo: any = [];
  contactListByEmail: any = [];
  emailCheck: boolean = true;
  smsCheck: boolean = false;
  disablePhone: boolean = false;
  showMessage: boolean;
  projectName = environment.projectName;
  @Input() public inputdata: any;
  @Output() messageToEmit = new EventEmitter<any>();

  userProfileDetails: any;
  metaVisibility: boolean = false;
  metafields = [];
  metaForm: UntypedFormGroup;
  uid = 0;
  dsIdForHost = 0;
  rskmodellogoFlag = this.ReskinningService.rskModelLogoFlag;
  rskmodellogo = this.ReskinningService.rskModelLogo;

  guestList: any = [];
  phoneCodeList: any;

  constructor(private formBuilder: UntypedFormBuilder,
    private master: MasterService,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private hostService: HostService,
    private metaService: MetaService,
    private ReskinningService: ReskinningService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    super();
  }

  ngOnInit() {
    this.userProfileDetails = Object.assign({}, this.loginUserData);

    /*  For Host User GET DS ID */
    if (this.userProfileDetails.role_id === 77) {

      this.hostService.getDSIdByHostId(this.userProfileDetails.user_customer_id, this.userProfileDetails.user_ds_id).subscribe(data => {
        if (data.status) {
          this.dsIdForHost = data.data.ds_id;
        }
        else {
          this.toastrService.error(data.message);
          this.router.navigateByUrl(this.inputdata.navurl);
        }
      }, error => {
        this.router.navigateByUrl(this.inputdata.navurl);
        console.log(error);
      });

    }

    this.metaForm = this.formBuilder.group({
      metaFields: new UntypedFormArray([])
    });
    /*  For Host User GET DS ID */
    if (this.userProfileDetails.role_id === 77) {

      this.hostService.getDSIdByHostId(this.userProfileDetails.user_customer_id, this.userProfileDetails.user_ds_id).subscribe(data => {
        if (data.status) {
          this.dsIdForHost = data.data.ds_id;
        }
        else {
          this.toastrService.error(data.message);
          this.router.navigateByUrl(this.inputdata.navurl);
        }
      }, error => {
        this.router.navigateByUrl(this.inputdata.navurl);
        console.log(error);
      });

    }
    this.getCurrentCountry();
    // this.getPhoneCode()
    this.contacttypeid = this.inputdata.id;
    this.contacttypename = this.inputdata.name;
    this.heading = this.inputdata.text;
    this.hosttype = this.inputdata.hosttype;

    var year = new Date().getFullYear() - 21;
    this.maxdate = this.datePipe.transform(new Date(new Date(new Date().setFullYear(year)).setHours(0, 0, 0, 0)), 'yyyy-MM-dd');
    this.objContact.contact_type_id = this.contacttypeid;
    //  this.objContact.contact_country = 'US';
    this.contactForm = this.formBuilder.group({

      fname: ['', [Validators.required, Validators.pattern("^[a-zA-Z!@# \$%\^\&?\'\*\)\(+=._-]+$")]],
      lname: ['', [Validators.required, Validators.pattern("^[a-zA-Z!@# \$%\^\&?\'\*\)\(+=._-]+$")]],
      pemail: ['', [Validators.required, Validators.pattern(AppConstants.regex.email)]],
      address1: [],
      birthdate: [''],
      city: [''],
      state: [''],
      country: [''],
      zipcode: [''],
      mphone: [''],
      personalnotes: [''],
      fblink: ['', [Validators.pattern(AppConstants.regex.fbLink)]],
      Twitterlink: ['', [Validators.pattern(AppConstants.regex.twitter)]],
      instagramlink: ['', [Validators.pattern(AppConstants.regex.instagram)]],
      linkedInlink: ['', [Validators.pattern(AppConstants.regex.linkedIn)]],
      instagrambiz: ['', [Validators.pattern(AppConstants.regex.instagram)]],
      youtubelink: ['', [Validators.pattern(AppConstants.regex.youtube)]],

      googlelink: ['', [Validators.pattern(AppConstants.regex.googleBizLink)]],
      // Sinalink: ['', [Validators.pattern(AppConstants.regex.sinaWeibo)]],
      // wechatlink: ['', [Validators.pattern(AppConstants.regex.number)]],

      pinterestLink: ['', [Validators.pattern(AppConstants.regex.pinterest)]],
      whatsapplink: ['', [Validators.minLength(6), Validators.maxLength(14), Validators.pattern(AppConstants.regex.number)]],

      contact_phone_other: [''],
      // contact_phone_other: ['', [Validators.minLength(10), Validators.maxLength(14), Validators.pattern(AppConstants.regex.number)]],

      contact_phone_type: [],
      contact_address_2: [],
      phonecode: [],
      custType :['']
    }, { validator: [PhoneValidator.validCountryPhone('mphone', 'phonecode'), PhoneValidator.validCountryPhone('contact_phone_other', 'phonecode')] });

    let self = this;
    self.contactForm.get('birthdate').valueChanges.subscribe(value => {
      if (value) {
        this.contactForm.get('birthdate').setValidators(this.dateValidator())
      } else {
        this.contactForm.get('birthdate').clearValidators();
      }
      this.contactForm.updateValueAndValidity();
    });
    if (sessionStorage.getItem("contactid")) {
      self.contact_id = sessionStorage.getItem("contactid");
      self.objContact.contact_id = self.contact_id;
    }
    var objprop = [];
    /*  objprop.push({contactnameprop:"agerange"});
     objprop.push({contactnameprop:"maritalStatus"});
     objprop.push({contactnameprop:"profession"}); */
    objprop.push({ contactnameprop: "sex" });
    objprop.push({ contactnameprop: "loyality" });
    var obj = [
      /*  this.master.getAgerange(),
       this.master.getMaritalStatus(),
       this.master.getProfession(), */
      this.master.getSex(),
      this.master.getLoyalityType()
    ];
    this.ngxSpinnerService.show();
    forkJoin(obj).subscribe((result) => {
      result.forEach((data: any, index: any) => {
        if (data) {
          this[objprop[index].contactnameprop] = data;
          this.ngxSpinnerService.hide();
        }
      });
    });
    // this.countriesData = csc.getAllCountries();
    // this.stateData = csc.getStatesOfCountry(this.objContact.contact_country);
    // this.selectedCountryObj=csc.getCountryById(this.objContact.contact_country);
    // this.phonecode = this.selectedCountryObj.phonecode;



    this.metaService.getMetaFieldsForUpdate(this.userProfileDetails.user_customer_id, 1, this.uid).subscribe(data => {
      if (data.status) {
       // this.metaVisibility = true;
        this.metaVisibility = false;
        this.metafields = data.data;
        this.createMetaForm();
      }
      else {
        this.metaVisibility = false;
        this.metafields = [];
      }
    }, error => {
      this.metaVisibility = false;
      this.metafields = [];
      console.log(error);
    });

    this.email.valueChanges.pipe(
      map((query) => query),
      debounceTime(1000),
      distinctUntilChanged()
    )
      .subscribe(data => {
        this.contactListByEmail = [];
        this.guestList = [];
        if (this.f.pemail.errors === null) {
          this.ngxSpinnerService.show();
          let self = this;
          var body = {
            contact_email: data
          }
          if (this.userProfileDetails.role_id === 77) {
            body['parent_user_id'] = this.dsIdForHost;
          }
          this.master
            .getContactListByEmail(body)
            .subscribe((data) => {
              if (data.status) {
                self.contactListByEmail = data.data;
                if(sessionStorage.getItem('add-guest')) {
                  let addGuest = sessionStorage.getItem('add-guest');
                  if(addGuest == '1'|| addGuest == '2') {
                  self.getExistingGuest(self.contactListByEmail[0]);
                  }
                }
              }
              else {
                self.contactListByEmail = [];
              }
              self.ngxSpinnerService.hide();
            });
        }
        if ((this.inputdata.page == 'addinvitee' || this.inputdata.page == 'add-contact') && this.email.value) {
          this.smsCheck =false;
          this.phone.clearValidators();
          this.phone.updateValueAndValidity();
        }
      })

    this.phone.valueChanges.pipe(
      map((query) => query),
      debounceTime(1000),
      distinctUntilChanged()
    )
      .subscribe(data => {
        this.contactListByPhoneNo = [];
        if (this.f.mphone.errors === null) {
          this.ngxSpinnerService.show();
          let self = this;
          var body = {
            contact_phone: parseIncompletePhoneNumber(data)
          }
          if (this.userProfileDetails.role_id === 77) {
            body['parent_user_id'] = this.dsIdForHost;
          }
          this.master
            .getContactListByPhoneNo(body)
            .subscribe(function (data) {
              if (data.status) {
                self.contactListByPhoneNo = data.data;
              }
              else {
                self.contactListByPhoneNo = [];
              }
              self.ngxSpinnerService.hide();
            });
        }
        if ((this.inputdata.page == 'addinvitee' || this.inputdata.page == 'add-contact') && this.phone.value) {
          this.email.clearValidators();
          this.email.updateValueAndValidity();
        }
      })

  }

  checkSpaces(event, field) {
    this.contactForm.get(field).setValue(event.target.value.replace(/\s+/g, ' ').trim(), { emitEvent: false });
  }

  get g() { return this.metaForm.controls; }
  get t() { return this.g.metaFields as UntypedFormArray; }

  //  ##############  META FORM #####################  //

  createMetaForm() {
    if (this.metafields?.length > 0) {
      for (let mt of this.metafields) {
        let vl = mt.field_value;
        if (vl === null || vl === undefined) {
          vl = '';
        }
        if (mt.field_type === 'INT' ) {
          this.t.push(this.formBuilder.group({
            name: [mt.field_name],
            metafname: [vl, [Validators.pattern(/^[0-9]\d*$/), Validators.maxLength(mt.field_size)]],
            lname: [mt.label_name],
            ftyp: 'Integer'
          }));
        } else {

          if (mt.field_type === 'NUMERIC') {
            this.t.push(this.formBuilder.group({
              name: [mt.field_name],

              metafname: [vl, [Validators.pattern(/^[0-9]\d*[.][0-9]\d*$/),Validators.maxLength(mt.field_size)]],

              lname: [mt.label_name],
              ftyp: 'Float'
            }));
          } else {
            if (mt.field_type === 'BOOLEAN') {
              this.t.push(this.formBuilder.group({
                name: [mt.field_name],
                metafname: [vl,  this.checkBool],
                lname: [mt.label_name],
                ftyp: 'Boolean'
              }));

            } else {

              if ( mt.field_type === 'timestamp') {
                this.t.push(this.formBuilder.group({
                  name: [mt.field_name],
                  metafname: [vl, [Validators.pattern(/^[1-9]([0-9]{3,3})[-][0-1][0-9]-[0-3][0-9][T][0-2][0-9][:][0-5][0-9][:][0-5][0-9][.]([0-9]{3,3})([Z])$/), Validators.maxLength(mt.field_size)]],
                  lname: [mt.label_name],
                  ftyp: 'Tmstamp'
                }));
              } else {
                this.t.push(this.formBuilder.group({
                  name: [mt.field_name],
                  metafname: [vl, Validators.maxLength(mt.field_size)],
                  lname: [mt.label_name],
                  ftyp: 'String'
                }));
              }
            }
          }
        }
      }
    }
  }

  /* Check for Boolean Validator Input */

  checkBool(control: UntypedFormControl) {
    let val = control.value;
    if (val === 'true' || val === 'false' || val === '') {
      return null;
    }
    return {
      boolCheck: {
        val: false
      }
    }
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  get f() { return this.contactForm.controls; }

  get email() {
    return this.contactForm.controls.pemail;
  }

  get phone() {
    return this.contactForm.controls.mphone;
  }

  dateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dateStr = control.value;
      if (dateStr) {
        var year = new Date().getFullYear() - 16;
        const now = new Date(new Date(new Date().setFullYear(year)).setHours(0, 0, 0, 0));
        const invalidObj = { 'date': true };
        const date = new Date(new Date(dateStr).setHours(0, 0, 0, 0));
        if (date > now) {
          return invalidObj;
        }
        else {
          return null;
        }
      }
      return null;
    };
  }


  states() {

    //this.stateData = csc.getStatesOfCountry(this.objContact.contact_country);
    this.getStatesOfCountry(this.objContact.contact_country);
    this.objContact.contact_state = '';
    this.objContact.contact_city = '';
    this.cityData = [];
    //this.selectedCountryObj=csc.getCountryById(this.objContact.contact_country);
    this.getCountryById(this.objContact.contact_country);
    //this.phonecode = this.selectedCountryObj.phonecode;
    this.objContact.contact_phone = '';
    this.objContact.contact_phone_other = '';
  }

  getCities() {
    this.state = this.objContact.contact_state;
    //this.cityData = csc.getCitiesOfState(this.objContact.contact_state);
    this.getCitiesOfState(this.objContact.contact_state, this.objContact.contact_country);
    if (this.cityData.length > 0) {
      this.objContact.contact_city = '';
    }
    else {
      this.cityData = [csc.getStateById(this.objContact.contact_state)];
    }

  }
  addNewContact() {
    this.isUrlValid(this.f.fblink.value)
    this.submitted = true;

    this.objContact.contact_phone = parseIncompletePhoneNumber(this.objContact.contact_phone);



    // this.objContact.custType = "Regular";



    this.ngxSpinnerService.show();
    if (this.inputdata.name === 'host') {
      this.objContact.agreetohost = true;
    }
    this.objContact.optin_email = this.emailCheck;
    this.objContact.optin_sms = this.smsCheck;
    // this.objContact.phone_code=this.phonecode;
    /* Below property Added for contacts being added from Events flow (ON-446) */
    if (sessionStorage.getItem('add-guest')) {
      this.objContact.is_trans_optin = true;
    }
    let fnarr = [];
    for (let fd of this.t.controls) {
      let fn = fd['controls'].name.value;
      let mfnm =  fd['controls'].metafname.value;
      if (fd['controls'].metafname.value !== null || fd['controls'].metafname.value !== undefined) {
        if (typeof mfnm === 'string') {
          mfnm = mfnm.trim();
        }
      }
      let obj = {
        field_name: fn,
        field_value: mfnm
      }
      fnarr.push(obj);
    }
    this.objContact['meta'] = fnarr;

    /* ON-710 => DOB Issue Fix */

    /* For ON-1011 */

    if (this.userProfileDetails.role_id === 77) {
      this.objContact['parent_user_id'] = this.dsIdForHost;
    }

    /* ON_1011 END */

    let finobj = Object.assign({}, this.objContact);

    if (finobj.contact_birthdate) {
      let tzOffset = new Date().getTimezoneOffset();
      finobj.contact_birthdate = new Date(new Date(finobj.contact_birthdate).getTime() - tzOffset*60*1000).toISOString();

    }

    // this.master.addingContact(this.objContact).subscribe(data => {

    /* END 710 */

    // console.log("finobj", finobj);
    // return false;

     this.master.addingContact(finobj).subscribe(data => {

      if (data.status) {
        // For add guest in event functionality - Condition
        if (sessionStorage.getItem('add-guest')) {
          let addGuest = sessionStorage.getItem('add-guest');
          if(addGuest == '1' || addGuest  == '2') {
            this.getGuestInfoForEvent(data.id);
         }
        }
        // For add guest in event functionality - Condition - END


        sessionStorage.setItem("contactid", data.id);
        this.objContact.contact_id = data.id;
        var output = { objContact: this.objContact, outputdata: data }
        this.messageToEmit.emit(output);
      }
      else {
        if (data.message && data.message.indexOf("Email already exists") >= 0) {
          this.contactListByEmail = data.data;
        }
        var output = { objContact: this.objContact, outputdata: data }
        this.messageToEmit.emit(output);
      }
    });
  }

  addNewInvitee() {

    this.isUrlValid(this.f.fblink.value)
    this.submitted = true;

    this.objContact.contact_phone = parseIncompletePhoneNumber(this.objContact.contact_phone);

    // this.objContact.custType = "Regular";

    this.ngxSpinnerService.show();
    if (this.inputdata.name === 'host') {
      this.objContact.agreetohost = true;
    }
    this.objContact.optin_email = this.emailCheck;
    this.objContact.optin_sms = this.smsCheck;
    /* Below property Added for contacts being added from Events flow (ON-446) */
    if (sessionStorage.getItem('add-guest')) {
      this.objContact.is_trans_optin = true;
    }
    let fnarr = [];
    for (let fd of this.t.controls) {
      let fn = fd['controls'].name.value;
      let mfnm =  fd['controls'].metafname.value;
      if (fd['controls'].metafname.value !== null || fd['controls'].metafname.value !== undefined) {
        if (typeof mfnm === 'string') {
          mfnm = mfnm.trim();
        }
      }
      let obj = {
        field_name: fn,
        field_value: mfnm
      }
      fnarr.push(obj);
    }
    this.objContact['meta'] = fnarr;

    /* ON-710 => DOB Issue Fix */


    /* For ON-1011 */

    if (this.userProfileDetails.role_id === 77) {
      this.objContact['parent_user_id'] = this.dsIdForHost;
    }

    let finobj = Object.assign({}, this.objContact);

    if (finobj.contact_birthdate) {
      let tzOffset = new Date().getTimezoneOffset();
      finobj.contact_birthdate = new Date(new Date(finobj.contact_birthdate).getTime() - tzOffset*60*1000).toISOString();

    }

    // this.master.addingContact(this.objContact).subscribe(data => {

    /* END 710 */

     let email = this.email.value;
     var body = {
      contact_email: email
     }
     if (this.userProfileDetails.role_id === 77) {
      body['parent_user_id'] = this.dsIdForHost;
      }

     this.master.getContactListByEmail(body).subscribe(data => {

      if (data.status) {
        data.id = data.data[0].contact_id;
        // For add only guest in event functionality - Condition
        if (sessionStorage.getItem('add-guest')) {
          this.getGuestInfoForEvent(data.id);
        }
        // For add guest in event functionality - Condition - END


        sessionStorage.setItem("contactid", data.id);
        this.objContact.contact_id = data.id;
        var output = { objContact: this.objContact, outputdata: data }
        this.messageToEmit.emit(output);
      }
      else {
        if (data.message && data.message.indexOf("Email already exists") >= 0) {
          this.contactListByEmail = data.data;
        }
        var output = { objContact: this.objContact, outputdata: data }
        this.messageToEmit.emit(output);
      }
    });
  }

  // for add guest to event
  getGuestInfoForEvent(guestId) {
    var UserData = this.authserviceService.UserData.value;
    let obj = {
      event_id: sessionStorage.getItem("hostEventId"),
      invitee_email: this.objContact.contact_email,
    };
      this.master.getExistingInvitee(obj).subscribe(data => {
      if(data.status) {
         this.guestList = data.data;
      } else {
        let body = [{
          event_id: sessionStorage.getItem("hostEventId"),
          contact_id: guestId,
          // ds_id: UserData.role_id === 77 ? UserData.parent_ds_id : UserData.user_ds_id,
          ds_id: UserData.role_id === 77 ? this.dsIdForHost : UserData.user_ds_id,
          invitee_name: this.objContact.contact_fname + ' ' + this.objContact.contact_lname,
          invitee_email: this.objContact.contact_email,
          host_id: UserData.user_ds_id,
          customer_company_logo: UserData.customer_company_logo
        }];

        this.hostService.addingInvitee({ inviteeData: body }).subscribe(data => {
          if(data.status) {
            this.toastrService.success("Guest information added successfully");
            if(sessionStorage.getItem('add-guest')) {
              let addGuest = sessionStorage.getItem('add-guest');
              if(addGuest == '1') {
                 this.router.navigateByUrl(this.basedsurl + "myevent/search");
               }  else if(addGuest == '2') {
                this.router.navigateByUrl("/host/viewguestlist");
             }
            }
          }
         // this.router.navigateByUrl(this.basedsurl + "myevent/search");
        }, error => {

          console.log(error);
        });
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
    });



  }


  goToContactInformationForm(contactid, contacttypeid) {
    sessionStorage.setItem("contactid", contactid);
    contacttypeid = contacttypeid === 0 ? 3 : contacttypeid;
    var contacttyperoute = getContactTypeSpec(contacttypeid).route + "/";
    this.inputdata.navurl = this.inputdata.navurl.replace('customer/', contacttyperoute).replace('prospect/', contacttyperoute);
    this.router.navigateByUrl(this.inputdata.navurl);
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

  /* opt in popup */
  showConfirm(modalName) {
    if (this.metaForm.invalid) {
      this.toastrService.error('Please provide correct Meta Field Values');
      return;
    }
    this.isUrlValid(this.f.fblink.value)
    this.submitted = true;
    if (this.phone.value) {
      this.disablePhone = true;
      this.smsCheck = true;
    } else if (!this.phone.value) {
      this.disablePhone = false;
      this.smsCheck = false;
    }
    if (this.userProfileDetails.role_id!= 77) {
      if (this.contactListByEmail.length > 0) {
        return;
      }
    }

    if (!this.phone.value && !this.email.value && this.inputdata.page == 'addinvitee') {
      this.email.setValidators([Validators.required, Validators.pattern(AppConstants.regex.email)]);
      this.phone.setValidators([Validators.required]);
      this.email.updateValueAndValidity();
      this.phone.updateValueAndValidity();
    }
    if (this.contactForm.invalid) {
      return;
    }

    /* Below If Condition Added to Skip OPT-IN Popup while adding Contact from Event Flow => (ON-446) */
    // if (sessionStorage.getItem('add-guest')) {
    //   if(this.contactListByEmail.length > 0){
    //      this.addNewInvitee();
    //   }else{
    //     this.addNewContact();
    //   }

    // } else {
      this.modalService.open(modalName).result.then((result) => {
      }, (reason) => {
        if (reason == 'open') {
          if(this.contactListByEmail.length > 0){
            this.addNewInvitee();
         }else{
           this.addNewContact();
         }
        }
      })
    // }

  }

  checkedEmail(e) {

    this.emailCheck = e.target.checked;
  }

  smsChecked(e) {
    this.smsCheck = e.target.checked;
    if (this.phone.value && e.target.checked) {
      this.disablePhone = true;
    } else if (!this.phone.value) {
      this.disablePhone = false;
    }
  }

  agree() {

    this.modalService.dismissAll('open');
      this.showMessage = false;

    // if (!(this.emailCheck || this.smsCheck)) {
    //   this.showMessage = true;
    // } else {
    //   this.modalService.dismissAll('open');
    //   this.showMessage = false;
    // }
  }

  /******Location Functions******/
  getCitiesOfState(stateId: string, countryId: string) {
    this.master.getCitiesOfState(countryId.trim(), stateId.trim()).subscribe((objArr: any[]) => {
      this.cityData = objArr;

    });
  }

  getCountryById(countryId: string) {

    this.master.getCountryByCode(countryId).subscribe((objArr: any) => {

      this.selectedCountryObj = objArr[0];
      this.phonecode = this.selectedCountryObj.phonecode;
    });
  }

  getStatesOfCountry(countryId: any) {
    if (!isNaN(countryId)) {
      // if number
      countryId = this.getCountryISOfromNPMPack(countryId);
    }
    this.master.getStatesOfCountry(countryId).subscribe(objArr => {
      this.stateData = objArr;
    });
  }

  getCountryISOfromNPMPack(countryId) {
    let cObj = csc.getCountryById(countryId);
    return cObj.sortname;
  }

  getAllCountries() {
    this.master.getAllCountries().subscribe(objArr => {
      this.countriesData = objArr;

      console.log(this.countriesData);
    });
  }

  getCurrentCountry() {
    this.master.getCurrentCountrie().subscribe(objArr => {

      this.selectedCountryObj = objArr.data;
      this.objContact.contact_type_id = this.contacttypeid;
      this.objContact.contact_country = this.selectedCountryObj.id;
      this.phonecode = this.selectedCountryObj.phonecode;
      console.log("ddddd",this.objContact.contact_country)
      this.getAllCountries();
      this.getStatesOfCountry(this.objContact.contact_country);
      this.getCountryById(this.objContact.contact_country);
    });

  }
  /*** ***Location Functions - END******/

  getExistingGuest(contactdata) {
    let obj = {
      event_id: sessionStorage.getItem("hostEventId"),
      invitee_email: contactdata.contact_email,
    };
    this.ngxSpinnerService.show();
    this.master.getExistingInvitee(obj).subscribe(data => {
      if(data.status) {
         this.guestList = data.data;
      } else {
       this.addNewGuest(contactdata);
      }
      this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
    });
  }

  addNewGuest(contactdata) {
    let UserData = this.authserviceService.UserData.value;
    this.confirmationDialogService.confirm('', 'WOULD YOU LIKE TO ADD THIS CONTACT AS A GUEST?', 'YES', 'NO').then((confirmed) => {
      if (confirmed) {
        if (sessionStorage.getItem('add-guest')) {
          let body = [{
            event_id: sessionStorage.getItem("hostEventId"),
            contact_id: contactdata.contact_id,
            ds_id: UserData.role_id === 77 ? this.dsIdForHost : UserData.user_ds_id,
            invitee_name: contactdata.contact_fname + ' ' + contactdata.contact_lname,
            invitee_email: contactdata.contact_email,
            host_id: UserData.user_ds_id,
            customer_company_logo: UserData.customer_company_logo
          }];
          this.ngxSpinnerService.show();
          this.hostService.addingInvitee({ inviteeData: body }).subscribe(data => {
            if(data.status) {
              this.toastrService.success("Guest information added successfully");
              if(sessionStorage.getItem('add-guest')) {
                let addGuest = sessionStorage.getItem('add-guest');
                if(addGuest == '1') {
                  this.router.navigateByUrl(this.basedsurl + "myevent/search");
                }  else if(addGuest == '2') {
                  this.router.navigateByUrl("/host/viewguestlist");
                }
              }
            } else {
              this.toastrService.error(data.message);
            }
            this.ngxSpinnerService.hide();
          }, error => {
            console.log(error);
            this.ngxSpinnerService.hide();
          });
        }

      }
     }).catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  }

  getPhoneCode(){
    this.master.getCoutryPhoneCode().subscribe(objArr => {
      this.phoneCodeList = objArr?.data;
    });
  }
  ngOnDestroy() {
    this.modalService.dismissAll();
  }
  rmvPlus(str:any){
    return str.replace('+','')
  }

}
