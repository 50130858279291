import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../firstadmin/base.component';

@Component({
  selector: 'app-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: []
})
export class PageNotFoundComponent extends BaseComponent implements OnInit {

  constructor() {
    super();
    var name = "404 Page View by " +  this.loginUserData.user_fname + " " + this.loginUserData.user_lname;
    this.addLogData("404 Page View",name);

   }
   goBack() {
    this.location.back();
  } 
  ngOnInit() {
  }

}
