<nav class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-shadow"
  [ngClass]="selectedHeaderNavBarClass" [ngStyle]="{'background-color': rskHeaderBackgroundColor}  ">
  <div class="navbar-wrapper">
    <div id="navbar-header" class="navbar-header" [ngClass]="selectedNavBarHeaderClass"
      (mouseenter)="mouseEnter($event)" (mouseleave)="mouseLeave($event)"
      [style.background-color]="rskHeaderBackgroundColor + '!important'">
      <ul class="nav navbar-nav flex-row">
        <!-- Remove position relative in responsive -->


        <li class="nav-item mobile-menu d-lg-none mr-auto" *ngIf="!hideMenu">
          <a class="nav-link nav-menu-main menu-toggle hidden-xs11" (click)="toggleNavigation($event); toggleChatPanelInMobile()"
            [hidden]="hidden">
            <i class="feather ft-menu font-large-1">

            </i>

          </a>
        </li>

        
        <!-- <li class="nav-item mr-auto"  *ngIf="this.userProfileDetails?.role_id!=77">
          <div class="mainLogo" userProfileDetails *ngIf="rskMargoLogoStatus">
            <a routerLink="/directseller/home" class="secLogoMain">
              <div class="secLogo" *ngIf="rskLogo">
                <img src="{{rskLogo}}">
              </div>
            </a>
          </div>
        </li> -->

        <!-- <li class="nav-item mr-auto"  *ngIf="this.userProfileDetails?.role_id!=77">
          <div class="mainLogo" userProfileDetails *ngIf="rskMargoLogoStatus">
            <a routerLink="/directseller/home" class="secLogoMain">
              <div class="secLogo" *ngIf="rskLogo">
                <img src="{{rskLogo}}">
              </div>
            </a>
          </div>
        </li> -->

        <li class="nav-item mr-auto">
          <div class="mainLogo" userProfileDetails *ngIf="rskMargoLogoStatus">
            <a routerLink="/directseller/home" class="secLogoMain">
              <div class="secLogo" *ngIf="rskLogo">
                <img src="{{rskLogo}}" [ngStyle]="{'max-height.px': rskLogoHeight}">
              </div>
            </a>
          </div>
        </li>


        <!-- <li class="nav-item mr-auto"  *ngIf="this.userProfileDetails?.role_id===77">
          <div class="mainLogo" userProfileDetails>
            <a routerLink="/host" class="secLogoMain">
              <div class="secLogo" *ngIf="rskLogo">
                <img src="{{rskLogo}}">
              </div>
            </a>
            <div class="mrgLogoMain" *ngIf="rskMargoLogoStatus">
              <span class="poweredby">Powered by</span>
              <a class="navbar-brand" routerLink="/host">
                <img class="brand-logo" alt="modern admin logo " src="assets/custom/images/margo50.png" height="50px"
                  width="auto">
              </a>
            </div>
          </div>
        </li> -->

        <!-- <div class="mrgLogoMain" *ngIf="rskMargoLogoStatus">
              <a class="navbar-brand" *ngIf="this.userProfileDetails?.role_id!==77" routerLink="/directseller/home">
                <img class="brand-logo" alt="modern admin logo " src="assets/custom/images/margo50.png" height="50px"
                  width="auto">
              </a>
              <a class="navbar-brand" *ngIf="this.userProfileDetails?.role_id===77" routerLink="/host">
                <img class="brand-logo" alt="modern admin logo " src="assets/custom/images/margo50.png" height="50px"
                  width="auto">
              </a>
            </div> -->





        <li class="nav-item d-none d-md-block nav-toggle">


          <p class="nav-link modern-nav-toggle pr-0" data-toggle="collapse" (click)="toggleFixMenu($event)">
            <i class="feather toggle-icon font-medium-3 white" style="display: none;"
              [ngClass]="{'ft-toggle-left': _themeSettingsConfig.menu == 'collapse','ft-toggle-right': _themeSettingsConfig.menu == 'expand'}"></i>
          </p>
        </li>
        <!-- <li class="nav-item d-lg-none"><a class="nav-link open-navbar-container" data-toggle="collapse"
            data-target="#navbar-mobile" (click)="toggleNavbar($event)"><i class="la la-ellipsis-v"></i></a></li> -->
      </ul>
    </div>
    <!-- New-->
    <!-- <div class="navbar-container content rightNav" [hidden]="isMobile && !showNavbar"> -->
    <div class="navbar-container content rightNav">
      <!-- <div class="collapse navbar-collapse" id="navbar-mobile"> -->
      <div class="navbar-collapse" id="navbar-mobile">
        <ul class="nav navbar-nav mr-auto float-left">

          <!-- <li class="nav-item d-none d-md-block"><a [routerLink]="" class="nav-link nav-link-expand"
              (click)="toggleFullScreen()"><i class="ficon feather ft-maximize"></i></a></li> -->
          <!-- <li class="nav-item nav-search"><a [routerLink]="" class="nav-link nav-link-search" (click)="clickSearch()"><i
                class="ficon feather ft-search"></i></a>  -->
          <!-- <div class="search-input" [ngClass]="{'open': isHeaderSearchOpen}">
              <input class="input" type="text" placeholder="Explore Modern...">
            </div>
          </li> -->
        </ul>

        <ul class="nav navbar-nav float-right">

          <li class="dropdown-user nav-item zindex-999" ngbDropdown [placement]="placement">
            <a class="nav-link dropdown-user-link customerService">
              <!-- <img src="/assets/images/icons/customer-service.png" alt="supportIcon"> -->
              <!-- <span class="supportTxt" ngbDropdownToggle (click)='getKnowledgeArticleByMargoAppURL(articlespinner)'>Page Support</span> -->
              <span *ngIf="currentUser.is_bu" class="supportTxt" ngbDropdownToggle (click)='getKnowledgeArticleByMargoAppURL(articlespinner)' ><i
                  class="fa fa-question-circle-o" aria-hidden="true"
                  [style.color]="rskIconColor + '!important'"></i></span>
              <span *ngIf="!currentUser.is_bu" class="supportTxt"  (click)="routeForIndependent()"><i
                  class="fa fa-question-circle-o" aria-hidden="true"
                  [style.color]="rskIconColor + '!important'"></i></span>
            </a>
            <div ngbDropdownMenu class="customTooglePageSupport" aria-labelledby="articlePanel">
              <ngx-spinner [name]="articlespinner" [fullScreen]="false">
              </ngx-spinner>
              <div class="container mb-2">
                <div class="row">
                  <div class="close-icon closePostn">
                    <span class="fa fa-close" ngbDropdownToggle></span>
                    <!-- <span class="clearboth" ngbDropdownToggle>Close</span> -->
                  </div>
                </div>

                <div class="row mt-2">
                </div>
              </div>
              <div class="scrlV popScroll filtMain">

                <article class="tipsArt vertProTitle1 border-0 ml-0">
                  <div class="row popRightTopMns">
                    <div class="col-10">
                      <h6 class="font-weight-bold text-muted text-lineHeight-Normal"
                        *ngIf="knowledgeArticleLayoutDetail?.title && knowledgeArticleLayoutDetail?.title !== 'NA'">
                        {{knowledgeArticleLayoutDetail.title}}</h6>
                      <!-- <small class="d-block mb-1 text-lineHeight-Normal"
                        *ngIf="knowledgeArticleLayoutDetail?.summary && knowledgeArticleLayoutDetail?.summary !== 'NA'"><strong>Summary</strong>:
                        {{knowledgeArticleLayoutDetail.summary}}</small> -->
                    </div>
                  </div>
                  <div class="vertical_articals" *ngIf="knowledgeArticleLayoutDetail.answer && noarticle==true ">
                    <video style="text-align: center;float: left;" frameborder="0" scrolling="auto"
                      allowfullscreen="300" height="360" class="library" controls preload="auto"
                      data-config="some-js-object-here" id = "myVideo" #myVideo>
                      <source src="{{knowledgeArticleLayoutDetail.answer}}" type='video/mp4'>
                    </video>

                    <p *ngIf="knowledgeArticleLayoutDetail.post" class="multiline-ellipsis1 scrlInn1"
                      [innerHtml]="knowledgeArticleLayoutDetail.post">
                    </p>
                  </div>

                  <!-- <p class="multiline-ellipsis scrlInn"
                    [innerHtml]="knowledgeArticleLayoutDetail.answer==null ? 'NA' : knowledgeArticleLayoutDetail.answer | safeHTMLtags: 'html'">
                  </p> -->
                </article>
                <a ngbDropdownToggle class="ml-1 mb-1 popUpBtnViewAll btn btn-teal" *ngIf='noarticle'
                  (click)="getKnowledgeArticleById($event)">View
                  All</a>
              </div>
              <div class="container bg-gp-white mt-1">
                <div class="row theme-fa-icon">
                  <div class="col-md-12" *ngIf="this.userProfileDetails?.role_id!=77">
                    <button class="cbtn btn-outline-accent-1 badge-pill text-center"
                      routerLink="/directseller/help/tips" ngbDropdownToggle>
                      <i class="fa fa-headphones" aria-hidden="true"></i>Contact Support
                    </button>
                  </div>
                  <div class="col-md-12" *ngIf="this.userProfileDetails?.role_id===77">
                    <button class="cbtn btn-outline-accent-1 badge-pill text-center"
                      routerLink="/directseller/help/support" ngbDropdownToggle>
                      <i class="fa fa-headphones" aria-hidden="true"></i>Contact Support
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="dropdown-user nav-item" ngbDropdown [placement]="placement">
            <!-- <a class="nav-link dropdown-user-link" ngbDropdownToggle>

              <span class="fa fa-user-circle-o">

              </span>
            </a> -->
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownProfileMenu"
              style="top: 70px; left: -258px;">

              <div class="container mb-1 mb-md-2">
                <div class="row">
                  <div class="close-icon closePostn">
                    <span class="fa fa-close" ngbDropdownToggle></span>
                    <!-- <span class="clearboth">Close</span> -->
                  </div>
                </div>

                <div class="row mt-1 mt-md-2">
                  <div class="col-12 text-center">
                    <div class="imgProMain">
                      <div class="imgSlidPro">
                        <img *ngIf="userProfileDetails" src="{{userProfileDetails.user_photo}}" class="img-fluid"
                          height="" (error)="setValue($event)">
                      </div>
                    </div>
                    <div *ngIf="status && userProfileDetails" class="mt-1 vertProTitle">
                      {{userProfileDetails.user_fname + ' '+ userProfileDetails.user_lname | titlecase}}</div>
                  </div>

                  <!-- <div class="col-4" style="justify-content: right;">
                      <span class="fa fa-close close">Close</span>
               </div> -->
                </div>
              </div>
              <div class="scrlV">
                <div class="scrlInn" *ngIf="this.userProfileDetails?.role_id!==77">


                  <a *ngIf="menu_profile" class="dropdown-item" [routerLink]="['/directseller/myaccount/myprofile']">My
                    Profile</a>
                  <a class="dropdown-item" [routerLink]="['/directseller/mypayment']"
                    *ngIf="!userProfileDetails.support_user && menu_payment">My Payment Info </a>
                  <a *ngIf="menu_contacts" class="dropdown-item" [routerLink]="['/directseller/mycontact']">My
                    Contacts</a>
                  <a *ngIf="menu_events" class="dropdown-item" [routerLink]="['/directseller/myevent']">My Events</a>
                  <a *ngIf="menu_team" class="dropdown-item" [routerLink]="['/directseller/myteam']">My Team</a>
                  <a *ngIf="menu_hosts" class="dropdown-item" [routerLink]="['/directseller/myhost']">My Hosts</a>

                  <a *ngIf="menu_campaigns" class="dropdown-item" [routerLink]="['/directseller/mycampaign']">My
                    Campaigns</a>

                  <a class="dropdown-item" [routerLink]="['/directseller/help']">Help & Tips</a>
                  <a class="dropdown-item logout-align" (click)="logout()">Logout</a>


                </div>
                <div class="scrlInn" *ngIf="this.userProfileDetails?.role_id===77">


                  <a *ngIf="menu_profile" class="dropdown-item" [routerLink]="['/directseller/myaccount/myprofile']">My
                    Profile</a>

                  <a class="dropdown-item" [routerLink]="['/directseller/help']">Help & Tips</a>
                  <a class="dropdown-item logout-align" (click)="logout()">Logout</a>


                </div>
              </div>
              <!-- <div class="dropdown-divider"></div> -->
              <!-- <a class="dropdown-item" [routerLink]=""> Logout</a> -->
            </div>
          </li>
        </ul>

      </div>

    </div>
    <!-- New-->
  </div>

  <div ngbDropdown class="d-inline-block scrlV">
    <button class="btn btn-outline-primary buttonTemp" id="dropdownMenuButton3" ngbDropdownToggle
      [style.background-color]="rskHeaderBackgroundColor + '!important'"><span class="fa fa-user-circle-o"
        (click)="toggleChatPanelInMobile(); toggleRightMenu()"
        [style.color]="rskIconColor + '!important'"></span></button>

    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton3" class="smlrightNavWh">
      <!-- <button ngbDropdownItem [routerLink]="['/directseller/mycontact']">My Contacts</button>
       <button *ngIf="menu_profile" class="dropdown-item" ngbDropdownItem [routerLink]="['/directseller/myaccount/myprofile']">My Profile</button>
       <button *ngIf="menu_profile"  ngbDropdownItem [routerLink]="['/directseller/myaccount/myprofile']">My Profile</button>
       <a class="dropdown-item" [routerLink]="['/directseller/mypayment']" *ngIf="!userProfileDetails.support_user && menu_payment">My Payment</a>
       <a *ngIf="menu_contacts" class="dropdown-item" [routerLink]="['/directseller/mycontact']">My Contacts</a>
       <button ngbDropdownItem [routerLink]="['/directseller/mycontact']">My Contacts</button>
       <button ngbDropdownItem [routerLink]="['/directseller/help']">Help & Tips</button> -->
      <div class="row">
        <div class="close-icon closePostn">
          <span class="fa fa-close" ngbDropdownToggle></span>
          <!-- <span class="clearboth">Close</span> -->
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center profile-top">
          <div class="imgProMain">
            <div class="imgSlidPro rskoutlinecolor" bindCssVariable="rskoutlinecolor"
              [bindCssVariableValue]="rskPictureOutlineColor">
              <img *ngIf="userProfileDetails" src="{{userProfileDetails.user_photo}}" class="img-fluid" height=""
                (error)="setValue($event)">
            </div>
          </div>
          <div *ngIf="status && userProfileDetails" class="mt-1 vertProTitle">

            {{userProfileDetails.user_title}}
            {{userProfileDetails.user_fname + ' '+ userProfileDetails.user_lname}}

            <p class="user-id mb-0"><a
                href="mailto:{{userProfileDetails.user_email_primary}}">{{userProfileDetails.user_email_primary}}</a>
            </p>
          </div>
        </div>
        <!-- <div class="col-4" style="justify-content: right;">
            <span class="fa fa-close close">Close</span>
     </div> -->
      </div>
      <ul bindCssVariable="rskscrollbgcolor" [bindCssVariableValue]="rskscrollbgcolor" class="scrlInn ui-select-menu"
        *ngIf="this.userProfileDetails?.role_id!==77">
        <li *ngIf="menu_profile" ngbDropdownToggle [routerLink]="['/directseller/myaccount/myprofile']"><img
            src="{{leftSideMenuImgPath}}/profile.svg" alt="My Profile" class="img-fluid"> My Profile</li>
        <li *ngIf="!userProfileDetails.support_user && menu_payment && hmItemsHidden" ngbDropdownToggle
          [routerLink]="['/directseller/mypayment']"><img src="{{leftSideMenuImgPath}}/payment-info.svg"
            alt="My Payment Info" class="img-fluid"> My Payment Info </li>
        <!-- <li *ngIf="menu_contacts" ngbDropdownToggle [routerLink]="['/directseller/mycontact']"><img
          (click)="setActivePage('Other','mycontact')" src="{{leftSideMenuImgPath}}/contacts.svg" alt="My Contacts" class="img-fluid"> My Contacts</li> -->

        <li *ngIf="menu_contacts && hmItemsHidden" ngbDropdownToggle [routerLink]="['/directseller/mycontact']" (click)="setActivePage('Other','mycontact')"><img
             src="{{leftSideMenuImgPath}}/contacts.svg" alt="My Contacts" class="img-fluid"> My Contacts</li>
        <li *ngIf="menu_contact_segment && hmItemsHidden" ngbDropdownToggle [routerLink]="['/directseller/create-segments/list']" (click)="setActivePage('Other','mycontactsegment')"><img
              src="{{leftSideMenuImgPath}}/segment.svg" alt="My Segment" class="img-fluid my-segment"> My Segment</li>
        <li *ngIf="menu_events && hmItemsHidden" ngbDropdownToggle [routerLink]="['/directseller/myevent']"
          (click)="setActivePage('Other','myevent')"><img src="{{leftSideMenuImgPath}}/events.svg" alt="My Events"
            class="img-fluid"> My Events</li>

        <li *ngIf="menu_team && hmItemsHidden" ngbDropdownToggle [routerLink]="['/directseller/myteam']"> <img
            src="{{leftSideMenuImgPath}}/team.svg" alt="My Team" class="img-fluid"> My Team</li>
        <li *ngIf="menu_hosts && hmItemsHidden" ngbDropdownToggle [routerLink]="['/directseller/myhost']"> <img
            src="{{leftSideMenuImgPath}}/host.svg" alt="My Host" class="img-fluid"> My Hosts</li>

        <li *ngIf="menu_campaigns && hmItemsHidden" ngbDropdownToggle [routerLink]="['/directseller/mycampaign']"
          (click)="setActivePage('Other','mycampaign')"> <img src="{{leftSideMenuImgPath}}/campaigns.svg"
            alt="My campaigns" class="img-fluid"> My Campaigns</li>



        <li *ngIf="menu_purchase && hmItemsHidden" ngbDropdownToggle [routerLink]="['/directseller/mypurchases']"
          (click)="setActivePage('Other','mypurchases')"> <img src="{{leftSideMenuImgPath}}/purchases.svg"
            alt="My purchases" class="img-fluid"> Purchases</li>

        <li *ngIf="currentUser?.is_independent_ds==true && hmItemsHidden" ngbDropdownToggle [routerLink]="['/directseller/productCatalogue']"

            >
            <i class="feather ft-box font-size">

            </i>
             <!-- <img src="{{leftSideMenuImgPath}}/purchases.svg"
             alt="My purchases" class="img-fluid"> -->
             Product Catalog</li>

        <li *ngIf="currentUser?.is_independent_ds==true && hmItemsHidden" ngbDropdownToggle [routerLink]="['/directseller/reskinning']"

            >
            <i class="feather ft-filter font-size">

            </i>
              Reskinning</li>


        <li ngbDropdownToggle [routerLink]="['/directseller/help']" *ngIf="hmItemsHidden"><img
            src="{{leftSideMenuImgPath}}/question-circle-help.svg" alt="My Help" class="img-fluid"> Help & Tips</li>
        <li class=" logout-align" (click)="logout()"> <img src="{{leftSideMenuImgPath}}/logout.svg" alt="My Logout"
            class="img-fluid"> Logout</li>
      </ul>

      <ul class="scrlInn ui-select-menu" *ngIf="this.userProfileDetails?.role_id===77">
        <li ngbDropdownToggle *ngIf="menu_profile" [routerLink]="['/directseller/myaccount/myprofile']"><img
            src="{{leftSideMenuImgPath}}/profile.svg" alt="My Profile" class="img-fluid"> My Profile</li>
        <li ngbDropdownToggle *ngIf="hmItemsHidden" [routerLink]="['/directseller/help/support']"> <img src="{{leftSideMenuImgPath}}/question-circle-help.svg"
            alt="My Help" class="img-fluid customizer-close" >Help & Tips</li>
        <li class=" logout-align" (click)="logout()"><img src="{{leftSideMenuImgPath}}/logout.svg" alt="My Logout"
            class="img-fluid"> Logout</li>
      </ul>

    </div>

  </div>
</nav>
