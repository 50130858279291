import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MasterService } from '../../../core/services/master/master.service';
import { MbService } from 'src/app/services/mbservice/mb.service';
import { UntypedFormControl } from '@angular/forms';
import { BookmarkService } from '../../../firstadmin/services/bookmark.service';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BaseComponent } from '../../../firstadmin/base.component';
import { getContactTypeSpec } from 'src/app/firstadmin/Models/Contact';
import { AppConstants } from 'src/app/_helpers/app.constants';
import { ContactService } from '../../../services/contact.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HostService } from 'src/app/firstadmin/host/services/host.service';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { isAndroid, isMobile, isTablet } from 'mobile-device-detect';
import { DeviceDetectorService } from "ngx-device-detector";
import { ConfirmationDialogService } from 'src/app/_utils/confirmation-dialog/confirmation-dialog.service';
import { ReskinningService } from 'src/app/_services/reskinning.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { constants } from 'buffer';
import { ViewChild } from '@angular/core';
import { ContactSegmentService } from 'src/app/services/contact-segment/contactSegment.service';

@Component({
  selector: 'app-search-contact',
  templateUrl: './search-contact.component.html',
  styleUrls: ['./search-contact.component.css']
})


export class SearchContactComponent extends BaseComponent implements OnInit, OnDestroy {
  rskPageContentColor = this.ReskinningService.rskPageContentColor;
  rskscrollbgcolor = this.ReskinningService.rskscrollbgcolor;
  rskmodellogoFlag = this.ReskinningService.rskModelLogoFlag;
  rskmodellogo = this.ReskinningService.rskModelLogo;
  heading: any;
  placeholder: any;
  placeholder1: any;
  addurl: any;
  addtext: any;
  isAllowed: boolean;
  recentHostItems;
  totalresult: any;
  guestsList = [];
  totalRows: number = 0;
  selectedCohost: any = [];
  currentSelectedCohost: number[] = [];
  selectedHost;
  displayNoResult: boolean = false;

  eventInviteeList: any = [];
  btnFlag: boolean = false;
  categForm: UntypedFormGroup;
  submitted: boolean = false;
  isSegmentForm: boolean = false;
  contacts = [];
  psids = [];
  ipsids = [];
  segmentFlag:boolean=false;
  segmentSelected:boolean=false;
  selectedValue:any;
  segmentContactFlag:boolean=true;
  @ViewChild('segmentContactCheckModal') myModal: any;
  tempSegmentContacts = [];
  typeofpost:any;



  constructor(private master: MasterService,
    private bookmarkService: BookmarkService,
    private contactService: ContactService,
    private sanitizer: DomSanitizer,
    private hostService: HostService,
    private route: Router,
    private deviceService: DeviceDetectorService,
    public confirmationdialouge: ConfirmationDialogService,
    private ReskinningService: ReskinningService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private mbService: MbService,
    private contactSegmentService : ContactSegmentService
  ) {
    super();
  }

  public model: string;
  public selectedProspect: boolean;
  results: any[] = [];
  queryContactField: UntypedFormControl = new UntypedFormControl();
  userDetails: any = [];
  prospectname;
  prospectid;
  contacttypeid;
  type: any;
  prospectdata;
  contacttypename;
  contacttypetext;
  phonecode;
  recentOrderContacts: any[] = [];
  showContactDetail: boolean = false;
  moreinfourl = 'teammember';
  hosttype;
  bookmarkresults;
  cohost: any = [];
  support_user;
  checkGo;
  page: any;

  /******** infinite scroll ******/
  limit = 12;
  skip = 0;
  numberOfRecordsFetched = 0;
  names = null;
  contactValue: any = 0;
  contactSearch;
  /********** end here *********/
  @Input() public inputdata: any;
  disableSelect: boolean = false;
  addGuestProp: boolean = false;
  disableContactsWithoutNumber: boolean = false;

  scrolled = false;
  recentSegment: any[] = [];
  smsConsentStatus = 'Accepted';
  ngOnInit() {


   
    this.ngxSpinnerService.show();
    this.disableSelect = false;
    let self = this;
    

    
    if(sessionStorage.getItem('OLDURL')==='/directseller/sms/mbevent'){
        this.router.navigate(['/directseller/sms/dashboard']);
    }

    if(localStorage.getItem('typeofpost')){
      this.typeofpost = localStorage.getItem('typeofpost');
    }
    /* Check for social sms contact  */
    if (localStorage.getItem('socialSms')) {
      // console.log("-- enter social sms --");
      this.btnFlag = true;
      this.limit = 300;
      sessionStorage.setItem('add-guest', '1');
      this.addGuestProp = true;
      this.inputdata.prevRoute = 'add-guest';
      // localStorage.removeItem('socialSms');
    }

    console.log("this.inputdata.prevRoute", this.inputdata.prevRoute);


    if (sessionStorage.getItem("morderdata")) {
      this.selectedHost = JSON.parse(sessionStorage.getItem("morderdata")).contact_id;

    }


    if (this.inputdata.prevRoute && this.inputdata.prevRoute === 'add-guest') {
      sessionStorage.setItem('add-guest', '1');
      this.addGuestProp = true;
    }

    this.categForm = this.formBuilder.group({
      categname: ['', [Validators.required]]
    });




    if (sessionStorage.getItem('add-guest')) {
      this.inputdata.prevRoute === 'add-guest';
      this.addGuestProp = true;
      if(sessionStorage.getItem("hostEventId"))  this.getInviteeList();
    }


    if (this.inputdata.page === 'quicktext') {
      // this.isAllowed = window.innerWidth < AppConstants.MOBILE_RESPONSIVE_WIDTH_HORIZONTAL;
      let isMobile = this.deviceService.isMobile();
      let isTablet = this.deviceService.isTablet();
      let deviceOs = this.deviceService.os;

      this.isAllowed = (isMobile || isTablet || deviceOs == 'Mac') ? true : false;

      this.disableContactsWithoutNumber = true;
      /*  if (navigator.appVersion.indexOf("Mac") != -1) {
          this.isAllowed = true;
        } */
    }
    else {
      this.isAllowed = true;
      this.disableContactsWithoutNumber = false;
    }

    if (this.isAllowed) {

      this.contacttypeid = this.inputdata.id;
      this.contacttypename = this.inputdata.name;
      this.contacttypetext = this.inputdata.text;
      this.heading = this.inputdata.heading;
      this.placeholder = this.inputdata.placeholder;
      this.addurl = this.inputdata.addurl;
      this.addtext = this.inputdata.addtext;
      this.placeholder1 = this.inputdata.placeholder1;

 

      sessionStorage.setItem("contacttypeid", this.contacttypeid);
      if (this.inputdata.hosttype) {
        this.hosttype = this.inputdata.hosttype;
      }
      if (sessionStorage.getItem("cohostdata")) {
        this.cohost = JSON.parse(sessionStorage.getItem("cohostdata"));
        this.selectedCohost = this.cohost.map((item) => { return item.contact_id });
        if (sessionStorage.getItem("hostEventId")) this.getInviteeList();


      }


      //alert(sessionStorage.getItem("contactdata"));
      if (sessionStorage.getItem("contactdata")) {
        this.prospectdata = JSON.parse(sessionStorage.getItem("contactdata"));
        //this.selectedProspect=true;
        this.showContactDetail = true;
      }

      if (sessionStorage.getItem("contactid")) {
        //id85 commented out selectedCohost push to avoid disable contactlist
        //this.selectedCohost.push(parseInt(sessionStorage.getItem("contactid")));
      }

      var params = {
        contactTypeIDs: this.contacttypeid
      }
      // this.queryContactField.value='host';
      //this.loaddata(this.queryContactField.value);
      // this.contacttypeid = 0;
      // this.loaddata(this.queryContactField.value);


      if (this.inputdata.page == 'mycontact') {
        //this.selectCustomerType(0,'');
      } else {
        //this.loaddata(this.queryContactField.value);
      }
      this.getrecentorderlist(params);
      this.getrecentSegmentlist(params);
      /* if (this.inputdata.page === 'quicktext') {
          this.recentContactList(params);
        }else{
          this.getrecentorderlist(params);
        } */
      self.queryContactField.valueChanges
        .pipe(
          map((query) => query),
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe(query => {


          this.SearchContact(query);
          this.contactService.contactserachtext.next(query);



        });
    } else {
      this.ngxSpinnerService.hide();

    }
    var UserData = this.authserviceService.UserData.value;
    this.support_user = UserData.support_user;



  }

  get f() { return this.categForm.controls; }

  stopProp(e) {
    e.stopPropagation();
  }

  selectInvitees(e, result) {

    var index = this.contacts.findIndex(x => x == result.contact_id);
    
    

    if (e.target.checked) {
      let out = this.guestsList.filter(elem => elem?.contact_id === result?.contact_id);
      this.contacts.push(result.contact_id);
      this.psids.push(result.psid);
      this.ipsids.push(result.ipsid);
      if (out?.length > 0) {
        return;
      }
      let body = {}
      // return;

      var UserData = this.authserviceService.UserData.value;
      body = {
        event_id: sessionStorage.getItem("hostEventId"),
        contact_id: result.contact_id,
        ds_id: UserData.role_id === 77 ? UserData.parent_ds_id : UserData.user_ds_id,
        invitee_name: result.contact_fname + ' ' + result.contact_lname,
        invitee_email: result.contact_email,
        host_id: UserData.user_ds_id,
        customer_company_logo: UserData.customer_company_logo
      }
      this.guestsList.push(body)
    } else if (!e.target.checked) {

      for (let i = 0; i < this.guestsList.length; i++) {
        if (this.guestsList[i].contact_id === result.contact_id) {
          this.guestsList.splice(i, 1);
          break;
        }
      }
      this.contacts.splice(index, 1);
      this.psids.splice(index, 1);
      this.ipsids.splice(index, 1);
    }

    if(this.contacts.length>0){
      this.segmentFlag = true;
      if(sessionStorage.getItem('segmentId')){
        sessionStorage.removeItem('segmentId');
      }
      let contactIds = JSON.stringify(this.contacts);
      sessionStorage.setItem("contacts", contactIds);

      let psids = JSON.stringify(this.psids);
      sessionStorage.setItem("psids", psids);

      let ipsids = JSON.stringify(this.ipsids);
      sessionStorage.setItem("ipsids", ipsids);

      // console.log("contacts", this.contacts);
      // console.log("psid", this.psids);

      if(this.contacts.length>4){
        this.toastrService.error('Limit for contacts Selection (5) reached');
        this.segmentSelected = true;
        return false;
      }else{
        this.segmentSelected = false;
      }

    }else{
      this.segmentFlag = false;
      this.getrecentSegmentlist('');

    }
    
  }

 
  checkInGuestList(result) {
    let out = this.guestsList.filter(elem => elem?.contact_id === result?.contact_id);
    
    if (out?.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  // checkInGuestList(result: any): boolean {
  //   return true;
  // }
  


  addGuest() {
    if (this.guestsList.length > 0) {

      if (this.guestsList.length > 100) {
        this.toastrService.error('Limit for Invite Selection (100) reached');
        return;
      }      
      this.ngxSpinnerService.show();
      this.hostService.addingInvitee({ inviteeData: this.guestsList }).subscribe(data => {
        this.ngxSpinnerService.hide();
        if (data.status) {
          sessionStorage.removeItem("isFromHostedEvent");
          sessionStorage.removeItem('add-invitee');
          this.router.navigate(['/directseller/myevent/search'])
          this.toastrService.success(data.message);
          if (sessionStorage.getItem('add-guest')) {
            sessionStorage.removeItem('add-guest');
          }
        }
        else {
          if (data.message) {
            this.toastrService.error(data.message);
          }
          else {
            this.toastrService.error("Something went wrong. Please try again.");
          }
        }
      }, error => {
        this.toastrService.error(error.message);
        this.ngxSpinnerService.hide();
        console.log(error);
      });
    }
  }

  skypeUrl(mobile) {
    let url = 'skype:' + mobile + '?call';
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  loaddata(name: any) {

    var params = {
      contactTypeIDs: this.contacttypeid
    }
    this.names = name;
    this.ngxSpinnerService.show();
    var body = {
      name: name, params: params, journeyId: 68, limit: this.limit, skip: this.limit * this.skip, type: this.type, agreetohost: true
    }

    switch (this.inputdata.name) {
      case 'teammember':
        //  this.getAllSubDsListByNameCount(body);
        this.getAllSubDsListByName(body);
        break;
      case 'host':

        if (this.hosttype && (this.hosttype === 'cohost' || this.hosttype === 'addcohost')) {
          params = {
            contactTypeIDs: 4
          }
          this.selectedProspect = false;
          this.showContactDetail = false;
          body.params = params;
          //this.getTotalcontact(body);
          this.getAllContactListByName(body);
        }
        else {
          // params = {
          //   contactTypeIDs: "2,3",

          // }
          // body.params=params;
          // body.journeyId=68;
          // body.type='host';
          // //this.getTotalcontact(body);
          // if(typeof this.inputdata.agreetohost !== 'undefined')
          //   body['agreetohost']= true;
          // this.getContactListForHostByName(body);

          this.contacttypeid = 4;
          this.selectCustomerType(this.contacttypeid, '');

        }
        break;
      case 'prospect':
        this.getFilterContacts(body);
        //this.getTotalcontact(body);
        break;
      case 'customer':
        //this.getTotalcontact(body);
        this.getFilterContacts(body);
        break;
      default:
        // this.getAllContactListByName(body) 
        this.contacttypeid = this.contactValue;
        this.selectCustomerType(this.contacttypeid, '');
        break;
    }
    //  this.getRecentList();

  }

  getAllSubDsListByName(body) {
    console.log("----getAllSubDsListByName----");
    this.displayNoResult = false;
    this.master.getAllSubDsListByName(body)
      .subscribe(result => {
        if (result.status) {
          if (result.data && result.data.length > 0 && result.full_count) {
            this.totalRows = result.full_count;
          }
          if (result.data && result.data.length > 0 && this.numberOfRecordsFetched < this.limit) {
            this.results.splice(this.limit * this.skip, this.numberOfRecordsFetched);
            this.results = [...this.results, ...result.data];
          } else if (result.data && result.data.length > 0) {
            this.results = [...this.results, ...result.data];
          }
        } else {
          if (this.results.length === 0) {
            this.displayNoResult = true;
            this.results = [];
            this.totalRows = 0;
          }

        }

        this.numberOfRecordsFetched = result.data ? result.data.length : 0;

        this.ngxSpinnerService.hide();

      }, error => {
        console.log(error);
        this.ngxSpinnerService.hide();
      });
  }



  /*getAllSubDsListByNameCount(body) {
    this.master.getAllSubDsListByNameCount(body)
    .subscribe(result => {
      if(result.full_count) {
        this.totalRows = result.full_count;
      }
      if(result.data && result.data.length>0 && this.numberOfRecordsFetched < this.limit){
        this.results.splice(this.limit*this.skip,this.numberOfRecordsFetched);
        this.results=[...this.results,...result.data];
      }else if(result.data && result.data.length>0){
        this.results=[...this.results,...result.data];
      }
      this.numberOfRecordsFetched=result.data?result.data.length:0;
        this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });
  }*/
  getContactTypeById(id) {
    var contacttype = getContactTypeSpec(id);
    return contacttype.name;
  }

  selectCustomerType(val, data) {
    if (data == 'dropdownChange') {
      if (this.btnFlag) {
        this.limit = 300;
      } else {
        this.limit = 12;
      }
      this.skip = 0;
      this.names = null;
      this.contactSearch = '';

      this.results = [];

    }



    this.ngxSpinnerService.show();
    let param;
    if (val == 4) {
      param = {
        "name": this.names,
        "contact_type_id": 3,
        "agreetohost": true,
        "limit": this.limit,
        "skip": this.limit * this.skip
      }
    } else if (val == 5) {
      param = {
        "name": this.names,
        "contact_type_id": 3,
        "agreetohost": true,
        "limit": this.limit,
        "skip": this.limit * this.skip
      }
    } else {
      param = {
        "name": this.names,
        "contact_type_id": JSON.parse(val),
        "limit": this.limit,
        "skip": this.limit * this.skip
      }
    }

    this.contacttypeid = param.contact_type_id;
console.log("ssssssss",param)
    this.master.getContactListForQuicktextByName(param)
      .subscribe(result => {
        if (result.status) {
          if (result.data && result.data.length > 0 && result.data[0].full_count) {
            this.totalRows = result.data[0].full_count;
          }

          if (result.data && result.data.length > 0) {
            this.displayNoResult = false;

            if (result.data && result.data.length > 0 || this.numberOfRecordsFetched < this.limit) {
              this.results.splice(this.limit * this.skip, this.numberOfRecordsFetched);
              
              // get list for contact segment
              this.results = [...this.results, ...result.data];
              const storedArrayString = localStorage.getItem("SegmentContacts");
              
              if(storedArrayString){
                this.results = JSON.parse(storedArrayString);
                this.totalRows = this.results.length;
                this.btnFlag = true;
                this.segmentContactFlag = false;
                
                for (let i = 0; i < this.results.length; i++) {
                    const body = {
                        contact_id: this.results[i].contact_id,
                    }
                    this.guestsList.push(body);
                    this.contacts.push(this.results[i].contact_id);
                    let contactIds = JSON.stringify(this.contacts)
                    sessionStorage.setItem("contacts", contactIds)
                }
              
              }
              // End for contact segment

            } else if (result.data && result.data.length > 0) {
              this.results = [...this.results, ...result.data];
            }
          }
        } else {
          if (this.results.length === 0) {
            this.displayNoResult = true;
            this.results = [];
            this.totalRows = 0;
          }
        }


        this.numberOfRecordsFetched = result.data ? result.data.length : 0;

        this.ngxSpinnerService.hide();


      }, error => {
        console.log(error);
        this.ngxSpinnerService.hide();
      });
  }
  getFilterContacts(body) {
    this.displayNoResult = false;
    this.master.getContactListByName(body)
      .subscribe(result => {
        if (result.status) {
          if (result.data && result.data.length > 0 && result.data[0].full_count) {
            this.totalRows = result.data[0].full_count;
          }
          if (result.data && result.data.length > 0 && this.numberOfRecordsFetched < this.limit) {
            this.results.splice(this.limit * this.skip, this.numberOfRecordsFetched);
            this.results = [...this.results, ...result.data];
          } else if (result.data && result.data.length > 0) {
            this.results = [...this.results, ...result.data];
          }
        } else {
          if (this.results.length === 0) {
            this.displayNoResult = true;
            this.results = [];
            this.totalRows = 0;
          }
        }


        this.numberOfRecordsFetched = result.data ? result.data.length : 0;

        this.ngxSpinnerService.hide();
      }, error => {
        console.log(error);
        this.ngxSpinnerService.hide();
      });
    /*  this.master.getFilteredContact({
       params: params, limit: 10
     }).subscribe(response => {
       this.results = response;
       this.ngxSpinnerService.hide();
     }, error => {
       console.log(error);
       this.ngxSpinnerService.hide();
     }); */

  }
  getrecentorderlist(params) {
    this.recentOrderContacts = [];
    this.master.getRecentOrderContactList({
      params: params
    }).subscribe(result => {
      if (result.length > 0) {
        this.recentOrderContacts = result;
      }
      else {
        this.recentOrderContacts = [];
      }
      //this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  getrecentSegmentlist(params) {

    console.log("--getrecentSegmentlist--");

    this.recentSegment = [];
    this.mbService.getRecentSegment().subscribe(result => {
      if (result.status == true && result.data.total !== 0 && result.data.rows !== null) {
        // this.recentSegment = result.data.rows;
        let arr = result.data.rows;
        for (let it of arr) {
          for (let con of it?.contacts){
            if (con?.sms_consent === 'Accepted') {
              this.recentSegment.push(it);
              break;
            }
          }
        }
         console.log("recentSegment", this.recentSegment);
      }
      else {
        this.recentSegment = [];
      }
      //this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });
  }


  recentContactList(params) {
    this.recentOrderContacts = [];
    this.master.recentContactList({
      params: params
    }).subscribe(result => {
      if (result.length > 0) {
        this.recentOrderContacts = result;
      }
      else {
        this.recentOrderContacts = [];
      }
      //this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
      this.ngxSpinnerService.hide();
    });
  }

  getContactListForHostByName(body) {
    this.displayNoResult = false;
    this.master.getContactListForHostByName(body)
      .subscribe(result => {
        if (result.status) {
          if (result.data && result.data.length > 0 && result.data[0].full_count) {
            this.totalRows = result.data[0].full_count;
          }

          if (result.data && result.data.length > 0 && this.numberOfRecordsFetched < this.limit) {
            this.results.splice(this.limit * this.skip, this.numberOfRecordsFetched);
            this.results = [...this.results, ...result.data];
          } else if (result.data && result.data.length > 0) {
            this.results = [...this.results, ...result.data];
          }
        } else {
          if (this.results.length === 0) {
            this.displayNoResult = true;
            this.results = [];
            this.totalRows = 0;
          }
        }

        this.numberOfRecordsFetched = result.data ? result.data.length : 0;

        this.ngxSpinnerService.hide();
      }, error => {
        console.log(error);
        this.ngxSpinnerService.hide();
      });
  }
  getAllContactListByName(body) {
    this.displayNoResult = false;
    this.master.getContactListForQuicktextByName(body)
      .subscribe(result => {
        if (result.status) {
          if (result.data && result.data.length > 0 && result.data[0].full_count) {
            this.totalRows = result.data[0].full_count;
          }

          if (result.data && result.data.length > 0) {

            if (result.data && result.data.length > 0 && this.numberOfRecordsFetched < this.limit) {
              this.results.splice(this.limit * this.skip, this.numberOfRecordsFetched);
              this.results = [...this.results, ...result.data];
            } else if (result.data && result.data.length > 0) {
              this.results = [...this.results, ...result.data];
            }
          }
        } else {
          if (this.results.length === 0) {
            this.displayNoResult = true;
            this.results = [];
            this.totalRows = 0;
          }
        }


        this.numberOfRecordsFetched = result.data ? result.data.length : 0;

        this.ngxSpinnerService.hide();
        //this.displayNoResult= false;

      }, error => {
        console.log(error);
        this.ngxSpinnerService.hide();
      });



  }

  getRecentList() {
    this.master.recentHostList({ name: '' }).subscribe(data => {

      //   alert(data);
      if (data.status) {
        this.recentHostItems = data.data;

      } else {
        this.recentHostItems = [];
      }
      // this.ngxSpinnerService.hide();
    }, error => {
      console.log(error);
      this.recentHostItems = [];
      this.ngxSpinnerService.hide();
    });
  }

  scroll2QuickView(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  selectContact(id, index, type) {


    if (this.hosttype) {
      var isexists = this.cohost.filter(contact => contact.contactid === id);
      let contact_id = parseInt(sessionStorage.getItem('contactid'), 10);
      let selectedInvitee;
      if (this.eventInviteeList.length > 0) selectedInvitee = this.eventInviteeList.find(contact => contact == id);

      if (selectedInvitee && selectedInvitee === id) {
        this.toastrService.error(`Can't select this contact as it is already a Invitee`);
        return;
      }



      if (contact_id === id) {
        this.toastrService.error(`Can't select this contact as it is already a Host`);
        return;
      }
      if (isexists.length > 0) {
        this.toastrService.error(`Can't select this contact as it is already a Co-Host`);
        return;
      }
    }
    if (this.inputdata.page === 'quicktext') {
      if (this.disableContactsWithoutNumber) {
        if (type && type === 'recent') {
          if (this.recentOrderContacts[index].contact_phone.trim() != '') {
            this.contactQuickView(id, index, type);
          } else {
            this.toastrService.error("Phone no. for this contact is not available.");
          }
        }
        else if (type && type === 'bookmark') {
          if (this.bookmarkresults[index].contact_phone.trim() != '') {
            this.contactQuickView(id, index, type);
          } else {
            this.toastrService.error("Phone no. for this contact is not available.");
          }
        }
        else if (this.results[index].contact_phone.trim() != '') {
          this.contactQuickView(id, index, type);
        }
      }
    } else if (this.selectedCohost.indexOf(id) == -1 || this.addGuestProp) {
      this.contactQuickView(id, index, type);
    }

  }

  contactQuickView(id, index, type) {


    this.disableSelect = false;
    if (isMobile) {
      window.scrollTo(0, 100);
    }
    this.prospectid = id;

    event.preventDefault()
    this.showContactDetail = true;
    this.selectedProspect = true;
    if (type && type === 'recent') {
      // alert(this.recentOrderContacts);
      this.prospectdata = this.recentOrderContacts[index];
    }
    else if (type && type === 'bookmark') {
      this.prospectdata = this.bookmarkresults[index];
    }
    else {
      this.prospectdata = this.results[index];
    }

    if (this.inputdata.hosttype === 'addcohost' || this.inputdata.hosttype === 'host' || this.inputdata.hosttype === 'cohost' || this.inputdata.page === 'socialhost') {

      this.confirmMessage(id, index, type);

    }
    else {
      sessionStorage.setItem("contactid", id);
      this.router.navigateByUrl(this.inputdata.moreinfourl);

    }
  }

  getCountryById(countryId: string) {
    this.master.getCountryByCode(countryId).subscribe((objArr: any) => {
      var selectedCountryObj = objArr[0];
      if (selectedCountryObj) {
        this.phonecode = selectedCountryObj.phonecode;
      }
    });
  }

  getContactsBookMarkList() {
    this.ngxSpinnerService.show();
    let body = {
      contact_type_id: this.inputdata.id
    }
    this.bookmarkresults = [];
    this.bookmarkService.getContactsBookmarkList(body)
      .subscribe(result => {
        if (result.status) {
          this.bookmarkresults = result.data;
        }
        else {
          this.bookmarkresults = [];
        }
        this.ngxSpinnerService.hide();
      }, error => {
        console.log(error);
        this.ngxSpinnerService.hide();
      });
  }

  SearchContact(query) {

    this.displayNoResult = false;
    this.results = [];
    this.skip = 0;

    this.loaddata(query);
  }

  onScroll() {

    if (this.inputdata.page == 'mycontact') {
      if (this.numberOfRecordsFetched === this.limit) {
        this.skip++;


        this.contacttypeid = this.contactValue;
        this.selectCustomerType(this.contacttypeid, '')
      } else {
        this.contacttypeid = this.contactValue;


        this.selectCustomerType(this.contacttypeid, '')
        // this.loaddata(this.queryContactField.value)
      }
    } else {
      if (this.numberOfRecordsFetched === this.limit) {
        this.skip++;
        this.loaddata(this.queryContactField.value)
      } else {
        this.loaddata(this.queryContactField.value)
      }
    }

  }
  getTotalcontact(body) {
    this.master.getTotalcontact(body)
      .subscribe(result => {
        // data = result.data;
        this.totalresult = result.data;
        // this.ngxSpinnerService.hide();
      }, error => {
        console.log(error);
        // this.ngxSpinnerService.hide();
      });
  }


  selHostCohostContact() {
    if (!this.disableSelect) {
      this.disableSelect = true;
      setTimeout(() => {
        this.disableSelect = false;
      }, 1000);

      let cohost;
      if (sessionStorage.getItem("cohostdata")) {
        cohost = JSON.parse(sessionStorage.getItem("cohostdata"));
      } else {
        cohost = [];
      }

      for (var i = 0; i < cohost.length; i++) {
        if (!cohost[i].finalAdded) {
          cohost[i].finalAdded = !cohost[i].finalAdded;
        }
      }
      this.cohost = cohost;
      sessionStorage.setItem("cohostdata", JSON.stringify(cohost));


      if (sessionStorage.getItem('From') && sessionStorage.getItem('From') == 'myevent/view') {
        if (this.hosttype && this.hosttype === 'host') {
          // Change Host
          // Need to change in 2 table
          // 1. public.event_data_tbl   /   event_primary_host_id
          // 2. public.event_host_cohost_rel_tbl   /   contact_id for host_flag = 1

          var oldHostId = sessionStorage.getItem('contactid');
          sessionStorage.setItem("contactid", sessionStorage.getItem("contactid2Replace"));
          sessionStorage.setItem("contactdata", sessionStorage.getItem("contactdata2Replace"));

          const eventId = sessionStorage.getItem('hostEventId');
          const newHostId = sessionStorage.getItem("contactid2Replace")
          const marketOrderId = sessionStorage.getItem("morderid")


          if (oldHostId != newHostId) {
            this.master.changeEventHost(eventId, newHostId, marketOrderId).subscribe(res => {
              if (res.status) {
                this.toastrService.success(res.message);
                if (sessionStorage.getItem("contactid2Replace")) {
                  sessionStorage.removeItem("contactid2Replace");
                  sessionStorage.removeItem("contactdata2Replace");
                }
                this.router.navigateByUrl(this.inputdata.selecturl);
              } else {
                this.toastrService.error(res.message);
              }
            });
          } else {
            this.toastrService.error("The selected contact is already an host");
          }

        } else if (this.hosttype && this.hosttype === 'addcohost') {
          // Add new cohost
          // public.event_host_cohost_rel_tbl   /   add with host_flag = 0

          const eventId = sessionStorage.getItem('hostEventId');

          var tempArr = [];
          this.cohost.forEach(element => {
            if (!element.ehcr_id) {
              tempArr.push({
                event_id: eventId,
                contact_id: element.contactid
              });
            }
          });
          let contact_id = tempArr.map((ele) => ele.contact_id);
          if (tempArr.length == 0 && !contact_id) {
            this.toastrService.error('No contact selected as Co-Host');
          } else {
            this.master.addEventCohost(tempArr).subscribe(res => {
              if (res.status) {
                this.toastrService.success(res.message);
                this.router.navigateByUrl(this.inputdata.selecturl);
              } else {
                this.toastrService.error(res.message);
              }
            });
          }

        } else if (this.hosttype && this.hosttype === 'cohost') {
          // Change existing cohost
          // public.event_host_cohost_rel_tbl   /   contact_id for host_flag = 0 with respect to the ehcr_id

          const ehcrId = sessionStorage.getItem('ehcrId');
          // const newContactId = this.cohost[sessionStorage.getItem('changeHostIndex')].contact_id
          const newContactId = sessionStorage.getItem('selcontactid');

          if (newContactId) {
            this.master.changeExistingCohost(ehcrId, newContactId).subscribe(res => {
              if (res.status) {
                this.toastrService.success(res.message);
                this.router.navigateByUrl(this.inputdata.selecturl);
              } else {
                this.toastrService.error(res.message);
              }
            });
          } else this.toastrService.error("No contact selected as Co-Host");
        }
      } else {
        if (this.hosttype && this.hosttype === 'cohost') {
          sessionStorage.removeItem('changeHostIndex');
          sessionStorage.removeItem('changeHost');
        } else if (this.hosttype && this.hosttype == 'host') {
          sessionStorage.setItem("contactid", sessionStorage.getItem("contactid2Replace"));
          sessionStorage.setItem("contactdata", sessionStorage.getItem("contactdata2Replace"));
        }
        this.router.navigateByUrl(this.inputdata.selecturl);
      }
    } else {
      if (this.hosttype && this.hosttype === 'cohost') {
        sessionStorage.removeItem('changeHostIndex');
        sessionStorage.removeItem('changeHost');
      } else if (this.hosttype && this.hosttype == 'host') {
        sessionStorage.setItem("contactid", sessionStorage.getItem("contactid2Replace"));
        sessionStorage.setItem("contactdata", sessionStorage.getItem("contactdata2Replace"));
      }
      this.router.navigateByUrl(this.inputdata.selecturl);
    }
    if (sessionStorage.getItem("contactid2Replace")) {
      sessionStorage.removeItem("contactid2Replace");
      sessionStorage.removeItem("contactdata2Replace");
    }
  }

  confirmMessage(id, index, type) {

    this.confirmationdialouge.confirm('', 'ARE YOU SURE WANT TO SELECT THE CONTACT?', 'YES', 'NO').then((confirmed) => {
      if (confirmed) {

        if (this.prospectid) {
          this.ngxSpinnerService.show();
          if (this.hosttype && this.hosttype === 'cohost') {
            var isexists = this.cohost.filter(contact => contact.contactid === this.prospectdata.contactid);
            let contact_id = sessionStorage.getItem('contactid');
            if (this.prospectdata.contact_id == contact_id) {
              this.toastrService.error("The selected contact is an host");
              this.ngxSpinnerService.hide();
              return false;
            }

            if (isexists.length > 0) {
              this.toastrService.error("This contact is already selected as a Co-Host.");
              this.ngxSpinnerService.hide();
              return false;
            }

            if (isexists.length === 0 && this.prospectdata.contact_id != contact_id) {
              this.prospectdata.contacttypename = getContactTypeSpec(this.prospectdata.contacttypeid).name;
              this.prospectdata.finalAdded = false;
              this.prospectdata.agreetohost = true;
              sessionStorage.setItem('changeHostIndex', this.inputdata.hosttypeindex);
              sessionStorage.setItem('changeHost', JSON.stringify(this.cohost[this.inputdata.hosttypeindex]));
              //sessionStorage.setItem("contactid", id);
              sessionStorage.setItem("selcontactid", id);
              this.cohost[this.inputdata.hosttypeindex] = this.prospectdata;
            }
            sessionStorage.setItem("cohostdata", JSON.stringify(this.cohost));
            //checkGo =1;
          }
          else if (this.hosttype && this.hosttype === 'addcohost') {
            var isexists = this.cohost.filter(contact => contact.contactid === this.prospectdata.contactid);

            let contact_id = sessionStorage.getItem('contactid');
            if (this.prospectdata.contact_id == contact_id) {
              this.toastrService.error("The selected contact is an host");
              this.ngxSpinnerService.hide();
              return false;
            }

            if (isexists.length > 0) {
              this.toastrService.error("This contact is already selected as a Co-Host.");
              this.ngxSpinnerService.hide();
              return false;
            }

            if (isexists.length === 0 && this.cohost.length < 3 && this.prospectdata.contact_id != contact_id) {
              this.prospectdata.contacttypename = getContactTypeSpec(this.prospectdata.contacttypeid).name;
              this.prospectdata.finalAdded = false;
              this.prospectdata.agreetohost = true;
              this.cohost.push(this.prospectdata);
              //this.currentSelectedCohost.push(this.prospectdata.contact_id);
            } else if (this.cohost.length > 3) {
              this.toastrService.error("Only 3 Co-Host are allowed");
              this.ngxSpinnerService.hide();
              return false;
            }
            sessionStorage.setItem("cohostdata", JSON.stringify(this.cohost));
            sessionStorage.setItem("selcontactid", id);
            //checkGo =1;

          }
          else {
            // check for contact selected is already a cohost/host?
            var isexists = this.cohost.filter(contact => contact.contactid === this.prospectdata.contactid);


            if (isexists.length > 0) {
              if (!sessionStorage.getItem('add-invitee'))
                this.toastrService.error("The selected contact is already a cohost.");
            }

            let contact_id = sessionStorage.getItem('contactid');

            if (sessionStorage.getItem('contactid') && this.prospectdata.contact_id == contact_id) {
              if (this.hosttype != undefined)
                this.toastrService.error("The selected contact is already an host");

              sessionStorage.setItem("contactid2Replace", id);
              sessionStorage.setItem("contactdata2Replace", JSON.stringify(this.prospectdata));
            } else {
              if (isexists.length === 0) {
                if (this.hosttype == 'host') {
                  sessionStorage.setItem("contactid2Replace", id);
                  sessionStorage.setItem("contactdata2Replace", JSON.stringify(this.prospectdata));
                } else {
                  sessionStorage.setItem("contactid", id);
                  sessionStorage.setItem("contactdata", JSON.stringify(this.prospectdata));
                }
              }
            }

          }
          if (this.contacttypeid !== 1) {

            let contacttypename = getContactTypeSpec(this.prospectdata.contacttypeid).name;
            this.contacttypetext = contacttypename;
            if (this.prospectdata.agreetohost) {
              this.contacttypetext += " / Host";
            }
            if (this.prospectdata.contact_country) {
              this.getCountryById(this.prospectdata.contact_country)
            }
            else {
              this.phonecode = 1;
            }
          }
          else {
            this.phonecode = 1;
          }
          // For navigation on Select
          if (sessionStorage.getItem('From') && sessionStorage.getItem('From') == 'myevent/view') {
            this.inputdata.selecturl = "/directseller/" + sessionStorage.getItem('From');

            this.checkGo = 1;
          } else if (sessionStorage.getItem('RelatedSourcePage') == 'market/host/contact') {
            //if(this.prospectdata.agreetohost) {
            this.inputdata.selecturl = '/directseller/market/host/type';
            sessionStorage.setItem("contacttypeid", '4');
            sessionStorage.setItem('market', JSON.stringify({ "id": 4, "name": "host", "text": "Host & Guest" }))
            // } else if(this.prospectdata.contacttypeid == 2){ //For Customer
            //   this.inputdata.selecturl = '/directseller/market/customer/type';
            //   sessionStorage.setItem("contacttypeid", '2');
            //   sessionStorage.setItem('market', JSON.stringify({"id":2,"name":"customer","text":"Customer"}))
            // } else if(this.prospectdata.contacttypeid == 3) { // For Prospects
            //   this.inputdata.selecturl = '/directseller/market/prospect/type';
            //   sessionStorage.setItem("contacttypeid", '3');
            //   sessionStorage.setItem('market', JSON.stringify({"id":3,"name":"prospect","text":"Prospect"}))
            // }

            this.checkGo = 1;
          }
          // For navigation on Select - ENd
          this.ngxSpinnerService.hide();

        }


        this.selHostCohostContact();

      }

    }

    ).
      catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));


  }

  getInviteeList() {
    let body = {
      event_id: parseInt(sessionStorage.getItem("hostEventId"))
    }
    this.master.getEventGusetList(body).subscribe(data => {

      if (data.status) {

        //this.eventInviteeList =data.data;
        data.data.forEach(element => {
          //let contact_id = {'contact_id':element.inviteeid}
          this.eventInviteeList.push(element.contact_id)

        });
      } else {
        this.eventInviteeList = [];
      }



    }, error => {


      console.log(error);
    });
  }

  /* Start Message Broadcast Contact code */
  viewSegmentation(modalName, eventBtn: any) {
    console.log("eventBtn", eventBtn);
    localStorage.setItem('contactEventBtn', eventBtn);
    
    // check Segment selection
    if(sessionStorage.getItem('segmentId')){
      this.router.navigate(['/directseller/sms/mbevent']);
      return;
    }

    this.confirmationdialouge.confirm('', 'DO YOU WANT TO ADD NEW SEGMENT WITH CONTACTS?', 'YES', 'NO')
      .then((confirmed) => {
        console.log("confirmed", confirmed);
        if (confirmed) {
          this.ngxSpinnerService.show();
          this.viewSegmentationModel(modalName);
        } else {
          console.log("--No--");
          this.router.navigate(['/directseller/sms/mbevent']);
        }
      })
      .catch(() =>
        // console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
        this.router.navigate(['/directseller/sms/mbevent'])
      );
  }


  viewSegmentationModel(modalName) {
    this.ngxSpinnerService.hide();
    let self = this;
    self.modalService.open(modalName, {
      ariaLabelledBy: 'modal-basic-title', backdrop: 'static',
      keyboard: false
    }).result.then((result) => {
    }, (reason) => {
    });
  }

  /*
  confirmSegmentModelOLD(segmentId:any) {
    this.confirmationdialouge.confirm('', 'DO YOU WANT TO CONTINUE WITH THIS SEGMENT?', 'YES', 'NO')
    .then((confirmed) => {
      console.log("confirmed", confirmed);
      if (confirmed) {
        this.ngxSpinnerService.show();
            this.saveSegmentAndGoToSchedule(segmentId);
      }else{
         console.log("--No--");
      }
  })
    .catch(() => 
      console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
    // this.router.navigate(['/directseller/sms/mbevent'])
    );
  }
  */

  confirmSegmentModel(segmentId: any) {
    this.segmentSelected = true;
    this.guestsList.push(segmentId);
    sessionStorage.removeItem("segmentName");
    sessionStorage.setItem('segmentId', segmentId);
    
  }

  openContactsConsentModal(event, segmentId: any) {
    event.preventDefault();
    let arr = this.recentSegment.filter(elem => elem?.segment_id === segmentId);
    this.tempSegmentContacts = [];
    if (arr && arr.length > 0) {
      this.tempSegmentContacts = arr[0].contacts;
      this.modalService.open(this.myModal, {
        ariaLabelledBy: 'modal-basic-title', backdrop: 'static', size: 'lg',
        keyboard: false
      }).result.then((result) => {
      }, (reason) => {
      });
    }
  }

  cancelSegmentSelection() {
   // this.reset();
   this.clearTempArr();
    this.modalService.dismissAll(this.myModal);
  }

  clearTempArr() {
    this.tempSegmentContacts = [];
  }


  closeSegmentationModel(modalName) {
    let self = this;
    self.modalService.dismissAll(modalName);
  }

  saveSegmentName() {
    sessionStorage.removeItem("segmentId");
    this.submitted = true;
    if (this.categForm.invalid) {
      return;
    }
    let { categname } = this.categForm.value;
    sessionStorage.setItem('segmentName', categname);
    this.router.navigate(['/directseller/sms/mbevent']);
  }

  saveSegmentAndGoToSchedule(segmentId: any) {
    sessionStorage.removeItem("segmentName");
    sessionStorage.setItem('segmentId', segmentId);
    this.router.navigate(['/directseller/sms/mbevent']);
  }

  /* End Message Broadcast Contact code */




  getRadioValue(e) {
    if (e.target.value == 'Yes') {
      this.isSegmentForm = true;
    } else {
      this.isSegmentForm = false;
    }
  }



  ngOnDestroy() {
    sessionStorage.removeItem('add-invitee');
    this.confirmationdialouge.close();
    localStorage.removeItem('socialSms');
    this.btnFlag = false;
    sessionStorage.removeItem('shoplive-add-invitee');
  }

  reset(){
      // reload component
      
      this.segmentSelected = false;
      this.guestsList =[];
      this.contacts = [];
      sessionStorage.removeItem("segmentName");
      sessionStorage.removeItem('segmentId');
      this.segmentSelected = false;
      this.selectedValue = '';
      this.segmentFlag=false;
      this.tempSegmentContacts = [];
}

checkForSMSConsent(result) {
  if (!this.btnFlag) { return true; }
  else {
    if (this.smsConsentStatus === '') return true;
    if (this.smsConsentStatus === result?.sms_consent) return true;
    return false;
  }
  
}

goToContactDetails(contactid, i) {
  this.confirmationdialouge.confirm('', 'DO YOU WANT TO NAVIGATE TO CONTACT DETAILS FROM SMS CAMPAIGN?', 'YES', 'NO')
      .then((confirmed) => {
        sessionStorage.clear();
        localStorage.removeItem('typeofpost');
        let contacttypid = '3';
        sessionStorage.setItem("RelatedSourcePage", "mycontact");
        sessionStorage.setItem("contacttypeid", contacttypid);
        sessionStorage.setItem("contactid", contactid);
        this.router.navigateByUrl('directseller/mycontact/view');
      })
      .catch(() =>
        {
          console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
        } 
    );
}
}