import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = localStorage.getItem("accesToken");
    let headers;
    if(req.url.indexOf('s3-accelerate.amazonaws.com')!==-1){
       headers=new HttpHeaders({
        'Content-Type': 'application/octet-stream'
      })
    }else{
       headers=new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    if (token) {
      headers =headers.set('x-auth', token);
    }
    const authReq = req.clone({headers: headers});
    return next.handle(authReq);
  }
}
