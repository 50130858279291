<div class="container mt-1 mt-md-0">
  <div class="row">
    <h4 class="mainHeading_1">Favorites</h4>
  </div>
</div>

<div class="container mt-1 mt-md-0">
  <div class="row">

    <div class="tblPrt">
      <table class="mt-1 bdrStCustom table table-borderless table-striped" width="100%" cellpadding="0" cellspacing="0" *ngIf="bookmarkresults">
        <tr>
          <th>Contact Name</th>
          <!-- <th>Contact Email</th> -->
          <th style="text-align:center;">Actions</th>
        </tr>

        <tr
          *ngFor="let obj of bookmarkresults | paginate:{itemsPerPage:10,currentPage:cp,totalItems:bookmarkresults.length  };let i=index">
          <td>
            <a (click)="selectContact(obj?.contactid,i)">{{obj.contactname}}</a>
          </td>
          <!-- <td>
            {{obj.contact_email}}
          </td> -->
          <td>
            <div class="actBtn">
              <span class="fa fa-trash" (click)="deleteContactFromBookMarkById(obj.bookmarkid)" placement="bottom" ngbTooltip="Delete"></span>

            </div>
          </td>
        </tr>

      </table>
      <div style="text-align: center;"  *ngIf="bookmarkresults && bookmarkresults.length===0" >
        <p>No results available.</p>
      </div>
      <pagination-controls *ngIf="bookmarkresults && bookmarkresults.length !== 0" class="my-pagination"
        (pageChange)="cp=$event">
      </pagination-controls>
    </div>
  </div>
</div>
