import { NgModule } from '@angular/core';
import { AlertService } from './services/alert.service';
import { AuthService } from './services/auth.service';
import { NavbarService } from './services/navbar.service';
import { AuthGuard } from './services/auth.guard';
import { AuthserviceService } from './services/authservice.service';
import {  MasterService} from './services/master/master.service';
import { AppInjector } from './services/app-injector.service';
@NgModule({
   providers: [AlertService,AuthService,NavbarService,AuthserviceService,AuthGuard, MasterService,AppInjector]
})
export class CoreModule { }
