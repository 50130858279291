<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-header row mb-1">
        </div>
        <div class="content-body">
            <section class="flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-md-4 col-10 box-shadow-0 p-0">

                        <div class="text-center col-12">
                            <img class="col-sm-12" src="assets/custom/images/margologo250.png"
                                alt="branding logo">
                        </div>

                        <div class="text-center col-12">
                              <h5>Forgot Password</h5>
                        </div>

                        <!-- <app-social-signin></app-social-signin> -->


                        <form class="form-horizontal text-center mt-1" [formGroup]="forgotForm"
                            (ngSubmit)="forgotPassword()">

                            <input type="text" formControlName="username" class="form-control formstyle"
                                [ngClass]="{ 'is-invalid': submitted && f.username.errors }" id="user-name"
                                placeholder="Please Enter Your Username">
                            <!-- <div class="form-control-position">
                            <i class="feather ft-user"></i>
                        </div> -->
                            <div style="text-align: left;"     *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                <div *ngIf="f.username.errors.required">username is Required</div>
                            </div>
                            <div class="login">
                                    <a [routerLink]="['/login']" class="card-link">Login Here!</a>
                                </div>
                                <div class="col-12 text-center">
                                    <button type="submit" class="custbtn mt-2">
                                        <!-- <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i> -->
                                        <!-- <i class="feather ft-unlock" *ngIf="!submitted"></i>  -->
                                        Submit
                                    </button>
                                    <button type="button" class="custbtn mt-2 ml-2" (click)="gotoLogin()">
                                        Cancel
                                    </button>
                                </div>


                           
                        </form>

                        <!-- <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1"><span>New to Modern ?</span></p>
                                <div class="card-body">
                                    <a [routerLink]="['/register']" class="btn btn-outline-danger btn-block"><i
                                            class="feather ft-user"></i> Register</a>
                                </div> -->




                    </div>
                </div>
            </section>

        </div>
    </div>
</div>
