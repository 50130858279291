import { Directive, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { AppInjector } from '../core/services/app-injector.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {BaseService} from './services/base.service';
import { LogsService } from '../services/logs/logs.service';
import { AuthserviceService } from 'src/app/services/auth/authservice.service';
@Directive()
export abstract class BaseComponent implements OnInit {
  protected router: Router;
  public location: Location;  
  protected ngxSpinnerService: NgxSpinnerService;
  protected toastrService:ToastrService;
  protected baseService:BaseService;
  protected activatedRoute: ActivatedRoute;
  protected logsService: LogsService;
  protected authserviceService:AuthserviceService;
  protected basedsurl="/directseller/";
  protected loginUserData;
  constructor() {
    const injector = AppInjector.getInjector();
    this.router = injector.get(Router);
    this.location = injector.get(Location);
    this.ngxSpinnerService = injector.get(NgxSpinnerService);
    this.toastrService=injector.get(ToastrService);
    this.baseService=injector.get(BaseService);
    this.activatedRoute=injector.get(ActivatedRoute);
    this.logsService=injector.get(LogsService);
    this.authserviceService=injector.get(AuthserviceService);
    this.baseService.changeValue(true);
    this.loginUserData=this.authserviceService.UserData.value
  }

  ngOnInit() {
   
  }

  addLogData(logname,logdescription ) {
    let value = {
      log_type: "web",
      log_name: logname,
      log_description:logdescription,
      moduleid: 94,
      moduleitemid: 113,
      per_id: 14
    }
    // this.logsService.addNewLogs(value).subscribe(data => {


    // }, error => {
    //   //console.log(error);
    // });
  }
}