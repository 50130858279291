import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private apiUrl = environment.apiUrl;
  constructor(private http:HttpClient) { }
  uploadImage(fileName:any,filePath:any,img:any,keyParam:any) {
    let path = filePath;
    let body = {
      fileName: keyParam,
      filePath: path,
      key: keyParam,
      image: img
    }
    let uri = this.apiUrl + 'api/image/uploadImage';
    return this.http.post<any>(uri, body);
  }

  uploadImage2(fileName:any,filePath:any,img:any,keyParam:any) {
    var key;
    if(filePath === 'contacts/working' && fileName=== 'contacts') {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours() + ":" + today.getMinutes();
      key='ContactImportTemplate'+date+time;
    } else {
      key = Date.now().toString(36);
    }

    let body = {
      fileName: key + '.' + keyParam,
      filePath: filePath,
      key:  key + '.' + keyParam,
      image: img
    }
    let uri = this.apiUrl + 'api/image/uploadImage';
    return this.http.post<any>(uri, body);
  }
 
}
