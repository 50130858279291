import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { throwError,Observable, BehaviorSubject, Subject, of, combineLatest } from 'rxjs';
import { catchError,map,mergeMap, switchMap } from 'rxjs/operators';
import * as io from 'socket.io-client';
import { environment } from './../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ChatService {

  socket:any;
  startTime:any;
  isSubscribed:boolean=false;
  sessionMessageCount=new Subject<number>();
  notification=new Subject<any>();

  subject=new Subject<boolean>();
  subject$:any;
  rooms=new Subject<any>();
  channels=new Subject<any>();
  joinRoom=new Subject<any>();
  messageIn=new Subject<any>();
  messageOut=new Subject<any>();
  moreMessages=new Subject<any>();
  fileOut=new Subject<any>();
  status=new Subject<any>();
  apiPath = environment.chatURI;
  messageNotification = new Subject<any>();
  disconnect=new Subject<any>();

  noticeBoard = new Subject<any>();

  socketCount=new Subject<any>();
  changeProfile=new Subject<any>();
  countSocket:number=0;

  updatedEmojies=new Subject<any>();
  deleteMessge=new Subject<any>();
  updateMessge=new Subject<any>();
  private messageSource = new BehaviorSubject("Default Message");
  currentMessage = this.messageSource.asObservable();
  stat = true;
  connection = new Subject();
  newInstantMessage =new Subject();
  newInstantMessageClicked =new Subject();
 // connectionMessage = this.connection.asObservable();

  constructor(private http: HttpClient) {
  }
  init(){
    this.socket = io.connect(environment.chatURI,{
      'reconnection': true,
      'reconnectionDelay': 1000,
      'reconnectionDelayMax' : 5000,
      'reconnectionAttempts': 999,
      transports: ['websocket'],
      upgrade: false
       });



    this.socket.on('connect', () => {
      this.socket
        .emit('authenticate', { token: localStorage.getItem('accesToken') }) //send the jwt
        // .on('authenticated', () => {
        //
        //   this.countSocket=0;
        //   // this.subscribe();
        //   this.subject.next(true);
        // })
        // .on('unauthorized', (msg) => {
        //   this.subject.next(false);
        // })
    });

  this.socket.on('authenticated', () => {

      this.countSocket=0;
        this.connection.next(true);

      // this.subscribe();
    //  this.subject.next(true);
    })
      this.socket.on('unauthorized', (msg) => {
     //   this.subject.next(false);
      })
    this.socket.on('disconnect', (reason) => {
      this.countSocket=0;
      //this.disconnect.next();
      //this.socket.close();
      /* this.disconnect.next();*/

      // if (reason === 'io server disconnect') {
      //   // the disconnection was initiated by the server, you need to reconnect manually
      //
      //   this.socket.connect();
      // }

    });
    this.socket.on('reconnect', function() {


     //this.subscribe();


     //this.subscribe();


     //this.subscribe();

    });
  //  return this.subject;
  }

  setSessionMessageCount(value){
    this.sessionMessageCount.next(value);
  }

  sendMessage(data){
    this.socket.emit("message", data);
  }

  sendFileOut(data){
    this.socket.emit("file-out", data);
  }

  preventTimeout(){
    this.socket.emit('prevent-timeout');
  }

  getRooms(obj){
    this.socket.emit('list-rooms',obj);
  }

  getChannels(obj){
    this.socket.emit('list-channels',obj);

  }

  getNoticeBoard(obj){
    this.socket.emit('list-noticeboard',obj);
  }
  resetJoinRoom(){
    this.socket.emit('reset-join-room');
  }

  join(data){
    this.startTime=new Date().getTime();
    this.socket.emit('join-room',data);
  }

  getMessages(message) {
    this.socket.emit('message',message);
  }
  updateNotification(){
    this.socket.emit('update-notification');
  }
  resetNotification(){
    this.socket.emit('reset-notification');
  }

  getNotification(){

    this.socket.emit('get-notification');
  }

  subscribe(){

    /* this.socket.on('list-rooms',(data)=>{
       if(data && data.output && data.output.data)
       this.rooms.next(data.output.data);
     });
     */



    this.socket.on('list-noticeboard',(data)=>{
      if(data && data.output && data.output.data)
        this.noticeBoard.next(data.output.data);
    })



    this.socket.on('list-channels',(data)=>{


      if(data && data.output && data.output.data)
      {

        var rooms = data.output.data.filter(function (result) {
          return result.room_type === 2;
        });

        var channels = data.output.data.filter(function (result) {
          return result.room_type != 2;
        });
      }

      this.channels.next(channels);
      this.rooms.next(rooms);
    })

    this.socket.on('message-in', (data) => {


      if (data && data.output && data.output.data) {
        this.messageIn.next(data.output.data);
      }
    })

    this.socket.on('join-room',(data)=>{
      if(data && data.output && data.output.data)
        this.joinRoom.next(data.output.data);
    })

    this.socket.on('file-out',(data)=>{
      if(data && data.output && data.output.data)
        this.fileOut.next(data.output.data);
    })

    this.socket.on('update-emoji',(data)=>{
        this.updatedEmojies.next(data.result);
    })
  
    this.socket.on('delete-message',(data)=>{

      if(data && data.output && data.output.data)
        this.deleteMessge.next(data.output.data);
  })
  this.socket.on('update-message',(data)=>{
    if(data && data.output && data.output.data)
      this.updateMessge.next(data.output.data);
})









    this.socket.on('message-out',(data)=>{

      if(data && data.output && data.output.data){
        this.messageOut.next(data.output.data);
        this.messageNotification.next(data.output.data);
      }

    })
    this.socket.on('more-messages',(data)=>{
      if(data && data.output && data.output.data)
        this.moreMessages.next(data.output.data);
    })


    this.socket.on('status',(data)=>{
      if(data && data.output && data.output.data)
        this.status.next(data.output.data);
    })

    this.socket.on('get-notification',(data)=>{
      if(data && data.output && data.output.data)
        this.notification.next({type:1,notification:data.output.data.notification});
    })



    this.socket.on('increment-notification',(data)=>{
      if(data && data.output && data.output.data)
        this.notification.next({type:2,notification:data.output.data.notification});
    })

    this.socket.on('decrement-notification',(data)=>{
      if(data && data.output && data.output.data)
        this.notification.next({type:3,notification:data.output.data.notification});
    })

    this.socket.on('inbound-message', (data) => {
      if (data)
        this.newInstantMessage.next(data);
    })

  }

  sendNotiOnInstantMessageRoomClicked(val) {
   this.newInstantMessageClicked.next(val); 
  }


  private httpHeadersOptions() {
    return this.socket.httpHeadersOptions();
  }

  //tohandle the error
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  };

  //KNowledge articles  apis
  getrooms(){
    var body = {};

    return this.http.get<any>( this.apiPath+"api/rooms")

      .pipe(
        map(data=>{
          if(!data.status)
          {
            if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
            {
              data.message="Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getMessagesByApi(room_id,limit,offset,flag){
    return this.http.get<any>( this.apiPath+"api/get-messages", {
      params: {
        room_id: room_id,
        limit:limit,
        offset: offset,
        flag:flag

      }});
  }
  getMessageByID(message_id,){
      return this.http.get<any>( this.apiPath+"api/getMessageByID/"+message_id)
      .pipe(
        map(data=>{
          if(!data.status)
          {
            if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
            {
              data.message="Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  editMessage(message_id,content,room_id) {

    var obj = {
      "message_id": message_id,
      "content": content,
      "socketId":this.socket.id,
      "room_id":room_id
    }

    return this.http.post<any>(this.apiPath + "api/editMessage",obj)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }


  getUser(name){
    var body = {};

    return this.http.get<any>(this.apiPath+"api/getUser/"+name)

      .pipe(
        map(data=>{
          if(!data.status)
          {
            if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
            {
              //console.error(data.message);
              data.message="Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  searchUserRooms(name,room_id){
    var body = {};

    return this.http.get<any>(this.apiPath+"api/searchUserRooms/"+name+"/"+room_id)

      .pipe(
        map(data=>{
          if(!data.status)
          {
            if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
            {
              //console.error(data.message);
              data.message="Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }



  findChat(roomId,textContent){
    var body = {
      'roomId':roomId,
      'content':textContent
    };

    return this.http.post<any>(this.apiPath+"api/message/searchMessageContent",body)

      .pipe(
        map(data=>{
          if(!data.status)
          {
            if(data.data && (data.indexOf("Error acquiring client")>=0 ||  data.indexOf("Error executing query")>=0))
            {
              //console.error(data);
              data.message="Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  updaterooms(obj){
    //var body = {};

    return this.http.post<any>( this.apiPath+"api/updateRoom",obj)

      .pipe(
        map(data=>{
          if(!data.status)
          {
            if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
            {
              //console.error(data.message);
              data.message="Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  exitroom(roomId){
    //var body = {};

    return this.http.put<any>(this.apiPath + "api/exitRoom", { 'room_id': roomId })

      .pipe(
        map(data=>{
          if(!data.status)
          {
            if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
            {
              //console.error(data.message);
              data.message="Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  updateUserStatus(userStatus){
    //var body = {};

    let obj = {
      "userStatus": userStatus,
      "socketId":this.socket.id,

    }
    return this.http.put<any>(this.apiPath + "api/updateUserStatus", obj)

      .pipe(
        map(data=>{
          if(!data.status)
          {
            if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
            {
              //console.error(data.message);
              data.message="Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  updateUserNotification(val){
    //var body = {};

    let obj = {
      "userNotification": val

    }
    return this.http.put<any>(this.apiPath + "api/updateUserNotification", obj)

      .pipe(
        map(data=>{
          if(!data.status)
          {
            if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
            {
              //console.error(data.message);
              data.message="Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }


  getNoticBoard(room_id) {

    return this.http.get<any>(this.apiPath + "api/message/"+room_id)

      .pipe(
        map(data=>{
          if(!data.status)
          {
            if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
            {
              //console.error(data.message);
              data.message="Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }

  getRoomUsers(room_id) {

    return this.http.get<any>(this.apiPath + "api/roomUsers/"+room_id)

      .pipe(
        map(data=>{
          if(!data.status)
          {
            if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
            {
              //console.error(data.message);
              data.message="Something went wrong.Please try again."
            }
          }
          return data;
        }),
        catchError(this.handleError));
  }
  getEmoji() {
    return this.http.get<any>(this.apiPath + "api/emoji")
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getUserInfo() {

    return this.http.get<any>(this.apiPath + "api/usersInfo")
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }
  deleteRoomMessage(message_id,room_id,index,date) {
    var obj = {
      "message_id": message_id,
      "socketId":this.socket.id,
      "room_id":room_id,
      "keyIndex":index,
      "date":date
    }

    return this.http.post<any>(this.apiPath + "api/deleteMessage",obj)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
         // console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  getRoomStatus() {

    return this.http.get<any>(this.apiPath + "api/getRoomStatus")
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  addEmoji(emojiId,messageId,room_id) {

    var obj = {
      "emoji_id": emojiId,
      "message_id": messageId,
      "socketId":this.socket.id,
      "room_id":room_id
    }

    return this.http.post<any>(this.apiPath + "api/emoji",obj)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));
  }

  deleteEmoji(emojiId,messageId,room_id) {

    var obj = {
      "emoji_id": emojiId,
      "message_id": messageId,
      "socketId":this.socket.id,
      "room_id":room_id
    }


    return this.http.post<any>(this.apiPath + "api/deleteEmoji",obj)
     .pipe(
      map(data=>{
      if(!data.status)
      {
        if(data.message && (data.message.indexOf("Error acquiring client")>=0 ||  data.message.indexOf("Error executing query")>=0))
        {
          //console.error(data.message);
          data.message="Something went wrong.Please try again."
        }
      }
      return data;
    }),
    catchError(this.handleError));

  }
  getSignedUrl(filename,file){
    return this.http.get(this.apiPath+'api/signed-url?filename='+filename);
  }
  uploadImageToS3(url,file){
    let headers = new HttpHeaders()
    headers.append('Content-Type', 'application/octet-stream')
    return this.http.request("PUT",url,{body:file,headers:headers});
  }

  getReadSignedURL(filename){
   return this.http.get(this.apiPath+'api/read-signed-url?filename='+filename);
  }

  getBlobData(url){
    return this.http.get(url);
  }



  unsubscribe(){
    this.socket.removeAllListeners();
    this.socket.disconnect();
    this.socket = undefined;
  }


  close(){

    try{
      this.socket.removeAllListeners();
      this.socket.disconnect();
      this.socket.close();
    }catch(e){
      //console.log('**************close*************',e);
    }
 
  }

  // ##### Toggle chat left panel from HM dropdown click  #############  //

  togglePanelfromHM(message: string) {
    this.messageSource.next(message);
  }
}

