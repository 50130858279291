import { Component, OnInit, Input } from '@angular/core';
import { MasterService } from 'src/app/core/services/master/master.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/firstadmin/base.component';
import { isMobile } from 'mobile-device-detect';
import { environment } from '../../../../environments/environment';
import { AuthserviceService } from 'src/app/services/auth/authservice.service';
import { AdminoneService } from 'src/app/services/adminone/adminone.service';
import {Location} from '@angular/common';
import { ReskinningService } from 'src/app/_services/reskinning.service';


@Component({
  selector: 'app-opt-agreement',
  templateUrl: './opt-agreement.component.html',
  styleUrls: ['./opt-agreement.component.css']
})
export class OptAgreementComponent extends BaseComponent implements OnInit {
  rskmodellogoFlag = this.ReskinningService.rskModelLogoFlag;
  rskmodellogo = this.ReskinningService.rskModelLogo;
  public userProfileDetails;
  status = false;
  image_awskey;
  emsg: "You Already Accepected Terms & Conditions";
  currentDate = new Date();
  contactdata;

  
  @Input() public inputdata: any;
  isMobileDevice = false;
  porjectName=environment.projectName;
   constructor(private master: MasterService,
    public modalService: NgbModal, public AuthserviceService: AuthserviceService,private adminone: AdminoneService,private _location: Location, private ReskinningService: ReskinningService) {
    super();
    this.baseService.changeValue(false);
    var name = "Contact Opt-in Page View by " +  this.loginUserData.user_fname + " " + this.loginUserData.user_lname;
    this.addLogData("Contact Opt-in Page View",name);    
  }

  ngOnInit() {
    this.currentDate = new Date('July 1, 2022') 
    this.userProfileDetails = this.AuthserviceService.UserData.value;
    if(isMobile) {
        this.isMobileDevice = true;
    }
    else {
        this.isMobileDevice = false;
    }
  }

  userDetails(){
    this.AuthserviceService.getUserprofileDetails().subscribe(data => {
      this.status = true;
      this.userProfileDetails = data.data;
    }, error => { });
  }

  setValue(event) {
    let self = this;
    let url = event.target.src;
    if (url.indexOf("master_images") > 0) {
      url = url.substring(url.indexOf("master_images"), url.length)
      if (!this.isUrlValid(url) ) {
        let awsUrl = self.adminone.getUrlfile(url);
        self.image_awskey = url;
        event.target.src = self.isUrlValid(awsUrl) ? awsUrl : 'assets/custom/images/profile.png';
      } else {
        event.target.src = 'assets/custom/images/profile.png';
      }
    } else {
      event.target.src = 'assets/custom/images/profile.png';
    }
    // event.target.src = 'assets/custom/images/profile.png';
    event.target.onerror = null;
  }

  isUrlValid(userInput) {
    return /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/i.test(userInput);
  }

 goBack()
 {
  if(sessionStorage.getItem("optin")) {
    this.router.navigateByUrl("directseller/myevent/optin");
  }
  else {
    //this.location.back();
    this.router.navigateByUrl(this.inputdata.backurl);
  }
 }

  optinAgreedTerms(){ 
    this.ngxSpinnerService.show();
    var body={
      contactid:sessionStorage.getItem("contactid"),
      optin:true,
      log_type:'OPT IN',
      log_name:'OPT IN Option Selected',
      log_description:"OPT IN Option Selected by " +  this.loginUserData.user_fname + " " + this.loginUserData.user_lname
    }
    this.master.updateUserOptinStatus(body).subscribe(response => {    
      this.ngxSpinnerService.hide();
      if(sessionStorage.getItem("optin"))
      {
        this.router.navigateByUrl(this.basedsurl+"myevent/optin");
      }
      else
      {
      //this.router.navigateByUrl(this.basedsurl+"mycontact/view");
      //this.location.back();
      this.router.navigateByUrl(this.inputdata.navurl);
      }
    });
  }

  backButtenClick() {
    this._location.back();
  }

}
